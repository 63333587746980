import axios from 'axios';

const makeRequest = async (url, method = 'get', headers = null, attempts = 3, sleepDuration = 2000) => {
  for (let attempt = 0; attempt < attempts; attempt++) {
    try {
      const config = {
        method,
        url,
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        }
      };

      const response = await axios(config);
      
      if (response.status === 200 || response.status === 204) {
        return { data: response.data || 'No Content' };
      } else {
        console.error(`Fejl ved ${method}-anmodning:`, response.status, response.data);
        return { error: response.data };
      }
    } catch (error) {
      console.error('Anmodningsfejl:', error.response?.data || error.message);
      
      if (attempt === attempts - 1) {
        return { 
          error: error.response?.data?.error?.message || 
                error.response?.data?.message || 
                error.message 
        };
      }
      
      await new Promise(resolve => setTimeout(resolve, sleepDuration));
    }
  }

  return { error: 'Max forsøg overskredet' };
};

export const deleteFacebookPost = async (pageId, postId, accessToken) => {
  try {
    // Først henter vi page access token
    const pageTokenUrl = `https://graph.facebook.com/${pageId}?fields=access_token&access_token=${accessToken}`;
    const pageTokenResponse = await makeRequest(pageTokenUrl);

    if (pageTokenResponse.error) {
      console.error('Fejl ved hentning af page access token:', pageTokenResponse.error);
      throw new Error(`Kunne ikke hente side-access-token: ${pageTokenResponse.error}`);
    }

    const pageAccessToken = pageTokenResponse.data.access_token;

    // Derefter sletter vi opslaget med det fulde ID
    const fullPostId = `${pageId}_${postId}`; // Sammensæt det fulde post ID
    const deleteUrl = `https://graph.facebook.com/v18.0/${fullPostId}?access_token=${pageAccessToken}`;
    
    const deleteResponse = await makeRequest(deleteUrl, 'delete');

    if (deleteResponse.error) {
      console.error('Fejl ved sletning af opslag:', deleteResponse.error);
      throw new Error(`Kunne ikke slette Facebook-opslag: ${deleteResponse.error}`);
    }

    return { success: true, message: 'Opslag slettet fra Facebook' };
  } catch (error) {
    console.error('Fejl ved sletning af Facebook-opslag:', error);
    return { 
      success: false, 
      error: error.message || 'Ukendt fejl ved sletning af Facebook-opslag' 
    };
  }
}; 