// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAx_5ZnR__8sizS5_1k3uP-gilviDiGO6Q",
  authDomain: "toft-d4f39.firebaseapp.com",
  projectId: "toft-d4f39",
  storageBucket: "toft-d4f39.appspot.com",
  messagingSenderId: "277892110082",
  appId: "1:277892110082:web:4d4578f88e521c2fb3141b",
  measurementId: "G-L976F1ZSTY"
};

// Initialiser Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialiser basis services
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);

// Tilslut til emulatorer kun hvis vi er i development og FIREBASE_EMULATOR er sat
const useEmulator = process.env.NODE_ENV === 'development' && process.env.VUE_APP_USE_FIREBASE_EMULATOR === 'true';

if (useEmulator) {
  try {
    console.log('Connecting to Firebase emulators...');
    connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
    connectFirestoreEmulator(db, 'localhost', 8080);
    connectStorageEmulator(storage, 'localhost', 9199);
    console.log('Successfully connected to Firebase emulators');
  } catch (error) {
    console.warn('Failed to connect to Firebase emulators:', error);
  }
}

// API konfiguration
export const API_CONFIG = {
  baseUrl: useEmulator
    ? 'http://localhost:5001/toft-d4f39/europe-west1'
    : 'https://europe-west1-toft-d4f39.cloudfunctions.net',
  apiKey: 'pC1VIstdkBVuQ6NBkLwJ3Qh59x5HUjvo'
};

// Konfigurer logging
if (process.env.NODE_ENV === 'production') {
  const noop = () => {};
  ['log', 'error', 'debug', 'warn'].forEach(method => {
    window.console[method] = noop;
  });
}

// Eksporter services
export { firebaseApp as app, auth, db, storage };
