import { getFirestore, doc, getDoc } from 'firebase/firestore';

const defaultTheme = {
  backgroundColor: '#16181b',
  sidenavColor: 'linear-gradient(310deg,#1568db,#05c2ed)',
  cardColor: '#202124',
  navWrapperColor: '#2c2c3b',
  textColor: 'rgba(255, 255, 255, 0.8)'
};

class WhiteLabelService {
  constructor() {
    this.db = getFirestore();
    this.cache = new Map();
    
    // Tilføj en timestamp til cache entries
    this.cacheTimeout = 5 * 60 * 1000; // 5 minutter
  }

  async getAdminSettings(adminId) {
    // Tjek først om vi har en gyldig adminId
    if (!adminId) {
      console.error('Ingen adminId angivet');
      return { theme: defaultTheme };
    }

    // Tjek cache først
    if (this.cache.has(adminId)) {
      const cached = this.cache.get(adminId);
      if (Date.now() - cached.timestamp < this.cacheTimeout) {
        return cached.data;
      }
      this.cache.delete(adminId);
    }

    try {
      const userDoc = await getDoc(doc(this.db, "users", adminId));
      if (!userDoc.exists()) {
        console.error('Bruger ikke fundet:', adminId);
        return { theme: defaultTheme };
      }

      const userData = userDoc.data();
      
      // Hvis det er en almindelig bruger (rolle 3), hent indstillinger fra deres createdBy admin
      if (userData.role === 3 && userData.createdBy) {
        const creatorDoc = await getDoc(doc(this.db, "users", userData.createdBy));
        if (creatorDoc.exists()) {
          const creatorData = creatorDoc.data();
          
          // Hvis oprettet af SuperAdmin, brug standard tema
          if (creatorData.role === 1) {
            const defaultSettings = {
              theme: defaultTheme,
              browserSettings: {
                title: 'AutoFamily',
                favicon: null
              },
              companyInfo: {
                name: 'AutoFamily',
                website: 'autofamily.dk'
              }
            };
            
            this.cache.set(adminId, {
              data: defaultSettings,
              timestamp: Date.now()
            });
            
            return defaultSettings;
          }
          
          // Hvis oprettet af Admin (rolle 2), brug deres indstillinger
          const creatorSettings = {
            theme: {
              backgroundColor: creatorData.adminSettings?.whiteLabel?.theme?.backgroundColor || defaultTheme.backgroundColor,
              sidenavColor: creatorData.adminSettings?.whiteLabel?.theme?.sidenavColor || defaultTheme.sidenavColor,
              cardColor: creatorData.adminSettings?.whiteLabel?.theme?.cardColor || defaultTheme.cardColor,
              navWrapperColor: creatorData.adminSettings?.whiteLabel?.theme?.navWrapperColor || defaultTheme.navWrapperColor,
              textColor: creatorData.adminSettings?.whiteLabel?.theme?.textColor || defaultTheme.textColor
            },
            browserSettings: creatorData.adminSettings?.whiteLabel?.browserSettings || {
              title: '',
              favicon: null
            },
            companyInfo: {
              name: creatorData.adminSettings?.InvoiceSettings?.companyName || '',
              website: creatorData.adminSettings?.InvoiceSettings?.website || ''
            }
          };
          
          this.cache.set(adminId, {
            data: creatorSettings,
            timestamp: Date.now()
          });
          
          return creatorSettings;
        }
      }
      
      // Hvis det er SuperAdmin, returner system defaults
      if (userData.role === 1) {
        const defaultSettings = {
          theme: defaultTheme,
          browserSettings: {
            title: 'AutoFamily',
            favicon: null
          },
          companyInfo: {
            name: 'AutoFamily',
            website: 'autofamily.dk'
          }
        };
        
        this.cache.set(adminId, {
          data: defaultSettings,
          timestamp: Date.now()
        });
        
        return defaultSettings;
      }

      // For admin (rolle 2), brug deres egne indstillinger eller defaults
      const whiteLabelSettings = {
        logo: userData.adminSettings?.profileImage || null,
        headerImage: userData.adminSettings?.headerImage || null,
        
        companyInfo: {
          name: userData.adminSettings?.InvoiceSettings?.companyName || '',
          website: userData.adminSettings?.InvoiceSettings?.website || '',
        },

        theme: {
          backgroundColor: userData.adminSettings?.whiteLabel?.theme?.backgroundColor || defaultTheme.backgroundColor,
          sidenavColor: userData.adminSettings?.whiteLabel?.theme?.sidenavColor || defaultTheme.sidenavColor,
          cardColor: userData.adminSettings?.whiteLabel?.theme?.cardColor || defaultTheme.cardColor,
          navWrapperColor: userData.adminSettings?.whiteLabel?.theme?.navWrapperColor || defaultTheme.navWrapperColor,
          textColor: userData.adminSettings?.whiteLabel?.theme?.textColor || defaultTheme.textColor
        },

        browserSettings: {
          title: userData.adminSettings?.whiteLabel?.browserSettings?.title || '',
          favicon: userData.adminSettings?.whiteLabel?.browserSettings?.favicon || null
        },

        layout: {
          sidebarPosition: userData.adminSettings?.whiteLabel?.layout?.sidebarPosition || 'left',
          headerStyle: userData.adminSettings?.whiteLabel?.layout?.headerStyle || 'default',
          navType: userData.adminSettings?.whiteLabel?.layout?.navType || 'sidebar'
        }
      };

      this.cache.set(adminId, {
        data: whiteLabelSettings,
        timestamp: Date.now()
      });
      
      return whiteLabelSettings;
    } catch (error) {
      console.error('Fejl ved hentning af admin indstillinger:', error);
      return { theme: defaultTheme };
    }
  }

  clearCache(adminId = null) {
    if (adminId) {
      this.cache.delete(adminId);
    } else {
      this.cache.clear();
    }
  }
}

export const whiteLabelService = new WhiteLabelService(); 