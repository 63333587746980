import { getFirestore, collection, query, getDocs, where, onSnapshot } from 'firebase/firestore';
import moment from 'moment';

// Initialiser Firestore
const db = getFirestore();

export const fetchPlaceholderEvents = async (userId, simulatedInterval = null) => {
  if (!userId) {
    console.log('Ingen bruger ID fundet');
    return [];
  }
  
  try {
    // Hent placeholders fra brugerens posts collection
    const postsQuery = query(
      collection(db, `users/${userId}/posts`),
      where("isPlaceholder", "==", true)
    );

    const querySnapshot = await getDocs(postsQuery);
    console.log(`Fandt ${querySnapshot.size} placeholders for bruger ${userId}`);
    
    if (simulatedInterval !== null) {
      // Hvis vi har et simuleret interval, generer nye placeholders med dette interval
      const dealerCarsQuery = query(
        collection(db, `users/${userId}/dealerCars`)
      );
      const dealerCarsSnapshot = await getDocs(dealerCarsQuery);
      
      const cars = dealerCarsSnapshot.docs.map(doc => ({
        ...doc.data(),
        docId: doc.id
      }));
      
      return generateSimulatedPlaceholders(cars, simulatedInterval);
    }
    
    return querySnapshot.docs.map(doc => {
      const data = doc.data();
      return formatPlaceholderEvent(doc.id, data);
    });
  } catch (error) {
    console.error('Fejl ved hentning af placeholders:', error);
    return [];
  }
};

// Ny funktion til at generere simulerede placeholders
const generateSimulatedPlaceholders = (cars, interval) => {
  const placeholders = {};
  const today = moment().startOf('day');
  const DAYS_TO_FORECAST = 14;
  
  // For hver bil
  for (const car of cars) {
    const createdDate = moment(car.createdDate).startOf('day');
    
    // For hver dag i forecast perioden
    for (let i = 1; i <= DAYS_TO_FORECAST; i++) {
      const futureDate = moment(today).add(i, 'days');
      const dateKey = futureDate.format('YYYY-MM-DD');
      
      let rotationDate = moment(createdDate);
      let shouldShow = false;

      // Find næste rotation dato
      while (rotationDate.isSameOrBefore(futureDate)) {
        if (rotationDate.isSame(futureDate, 'day')) {
          shouldShow = true;
          break;
        }
        rotationDate.add(interval, 'days');
      }

      if (shouldShow) {
        if (!placeholders[dateKey]) {
          placeholders[dateKey] = [];
        }

        const daysSinceCreated = futureDate.diff(createdDate, 'days');
        const rotationNumber = Math.floor(daysSinceCreated / interval) + 1;

        placeholders[dateKey].push({
          ...car,
          daysForSale: daysSinceCreated,
          originalCreatedDate: createdDate.toISOString(),
          rotationDate: futureDate.toISOString(),
          rotationNumber: rotationNumber,
          usedInterval: interval
        });
      }
    }
  }
  
  // Konverter placeholders til events
  return Object.entries(placeholders).map(([date, cars]) => {
    const eventId = `simulated-${date}`;
    const eventData = {
      publishDate: date,
      children: cars,
      isPlaceholder: true,
      type: 'daysForSaleVehicle'
    };
    return formatPlaceholderEvent(eventId, eventData);
  });
};

// Ny funktion til at sætte op live lytter på placeholders
export const setupPlaceholderListener = (userId, calendar) => {
  if (!userId || !calendar) {
    console.log('Mangler userId eller calendar reference');
    return null;
  }

  const db = getFirestore();
  
  // Opret query for placeholders
  const placeholderQuery = query(
    collection(db, `users/${userId}/posts`),
    where("isPlaceholder", "==", true)
  );

  // Opret listener med error handling
  const unsubscribe = onSnapshot(placeholderQuery, {
    next: (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const docData = change.doc.data();
        const eventId = change.doc.id;

        // Formater event data
        const eventData = formatPlaceholderEvent(eventId, docData);

        if (change.type === "added" || change.type === "modified") {
          // Fjern eksisterende event hvis det findes
          const existingEvent = calendar.getEventById(eventId);
          if (existingEvent) {
            existingEvent.remove();
          }
          // Tilføj den nye/opdaterede event
          calendar.addEvent(eventData);
        } 
        else if (change.type === "removed") {
          // Fjern event hvis det findes
          const existingEvent = calendar.getEventById(eventId);
          if (existingEvent) {
            existingEvent.remove();
          }
        }
      });
    },
    error: (error) => {
      console.error('Fejl i placeholder listener:', error);
    }
  });

  return unsubscribe;
};

export const formatPlaceholderEvent = (docId, data) => {
  return {
    id: docId,
    title: `Lagerbiler (${data.children?.length || 0})`,
    start: data.publishDate,
    isPlaceholderGroup: true,
    extendedProps: {
      platform: null,
      time: data.publishTime?.HH && data.publishTime?.MM 
        ? `${data.publishTime.HH}:${data.publishTime.MM}`
        : "12:00",
      icon: "lightning",
      children: data.children || [],
      status: "Kommende events",
      text: data.text || "Lagerbiler til rotation",
      collection: "posts",
      isPlaceholder: true,
      data: {
        ...data,
        cars: data.children || []
      },
      type: data.type,
      postFB: data.postFB,
      postInst: data.postInst
    },
    className: "bg-gradient-placeholder placeholder-group"
  };
};

// Hjælpefunktioner til at håndtere placeholder data
export const getPlaceholderStats = (children) => {
  const totalDaysForSale = children.reduce((sum, car) => sum + (car.daysForSale || 0), 0);
  const averageDaysForSale = children.length ? Math.round(totalDaysForSale / children.length) : 0;
  
  return {
    totalDaysForSale,
    averageDaysForSale,
    totalCars: children.length
  };
};

export const sortPlaceholderCars = (cars) => {
  return [...cars].sort((a, b) => (b.daysForSale || 0) - (a.daysForSale || 0));
};

export const getStatusClass = (daysForSale) => {
  if (daysForSale >= 60) return 'status-critical';
  if (daysForSale >= 30) return 'status-warning';
  return 'status-normal';
};

export const formatPlaceholderDate = (date) => {
  return moment(date).format('D. MMMM YYYY');
};

// HTTP version af generatePlaceholdersManually
export const generatePlaceholdersManually = async (userId) => {
  if (!userId) {
    throw new Error('Ingen bruger ID fundet');
  }

  try {
    const response = await fetch('https://us-central1-toft-d4f39.cloudfunctions.net/generatePlaceholdersHttp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId })
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      throw new Error(errorData?.error || 'Fejl ved generering af placeholders');
    }

    const result = await response.json();
    console.log('Resultat fra generatePlaceholders:', result);
    return result;
  } catch (error) {
    console.error('Fejl ved manuel generering af placeholders:', error);
    throw error;
  }
}; 