import { db } from '@/firebase';
import { collection, addDoc, updateDoc, deleteDoc, doc, getDocs, query, where } from 'firebase/firestore';

const COLLECTION_NAME = 'adSets';

class AdSetService {
  // Opret nyt ad set
  async createAdSet(adSetData) {
    try {
      const docRef = await addDoc(collection(db, COLLECTION_NAME), {
        ...adSetData,
        createdAt: new Date(),
        updatedAt: new Date()
      });
      return docRef.id;
    } catch (error) {
      console.error('Fejl ved oprettelse af ad set:', error);
      throw error;
    }
  }

  // Hent ad sets for en specifik bruger
  async getAdSets(userId) {
    try {
      const q = query(
        collection(db, COLLECTION_NAME),
        where('userId', '==', userId)
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error('Fejl ved hentning af ad sets:', error);
      throw error;
    }
  }

  // Opdater et ad set
  async updateAdSet(adSetId, updateData) {
    try {
      const adSetRef = doc(db, COLLECTION_NAME, adSetId);
      await updateDoc(adSetRef, {
        ...updateData,
        updatedAt: new Date()
      });
      return true;
    } catch (error) {
      console.error('Fejl ved opdatering af ad set:', error);
      throw error;
    }
  }

  // Slet et ad set
  async deleteAdSet(adSetId) {
    try {
      const adSetRef = doc(db, COLLECTION_NAME, adSetId);
      await deleteDoc(adSetRef);
      return true;
    } catch (error) {
      console.error('Fejl ved sletning af ad set:', error);
      throw error;
    }
  }
}

export default new AdSetService(); 