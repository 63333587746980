export const introSentences = {
    'Nyhed': [
      "{emoji} Eksklusiv premiere: Oplev den imponerende {maerke} {model} i vores showroom {emoji}",
      "Breaking News: En helt særlig {maerke} {model} er netop ankommet til vores eksklusive udstilling {emoji}",
      "{emoji} Velkommen til fremtiden: Den innovative {maerke} {model} er landet {emoji}",
      "Præsenterer stolt: En enestående {maerke} {model} - nu i vores showroom {emoji}",
      "Nyankommet mesterværk: {maerke} {model} sætter nye standarder for excellence {emoji}",
      "{emoji} Oplev det extraordinære: En spektakulær {maerke} {model} venter på dig",
      "Automotive excellence: Den eftertragtede {maerke} {model} er nu tilgængelig {emoji}",
      "Velkommen til en ny æra af køreglæde: {maerke} {model} {emoji}",
      "{emoji} Exceptionel elegance møder kraftfuld performance: {maerke} {model}",
      "Når kun det bedste er godt nok: Oplev den nye {maerke} {model} {emoji}"
    ],
    'Ny bil på lager': [
      "{emoji} Netop ankommet til vores eksklusive collection: En enestående {maerke} {model} {emoji}",
      "Velkommen til vores nyeste stjerne: En særligt udvalgt {maerke} {model} {emoji}",
      "{emoji} Håndplukket til perfektion: En exceptionel {maerke} {model} fra {aar}",
      "Nyt flagskib i vores premium selection: {maerke} {model} {emoji}",
      "En særlig levering er netop ankommet: {maerke} {model} i {farve} {emoji}",
      "{emoji} Kvalitet møder innovation: Oplev vores nye {maerke} {model}",
      "Eksklusivt til vores kræsne kunder: En håndplukket {maerke} {model} {emoji}",
      "Premium selection: En unik {maerke} {model} venter på sin nye ejer",
      "{emoji} Når kun det bedste er godt nok: {maerke} {model} i særklasse",
      "Automotive excellence: En håndselekteret {maerke} {model} {emoji}"
    ],
    'Tilbud': [
      "{emoji} Eksklusiv mulighed: En enestående {maerke} {model} til en særlig attraktiv pris",
      "Limited time offer: Oplev luksuriøse {maerke} {model} til en fordelagtig pris {emoji}",
      "{emoji} Premium deal: En exceptionel {maerke} {model} med særlig værdi",
      "Unik chance: Erhverv denne prestigefyldte {maerke} {model} nu {emoji}",
      "Særligt tilbud på en håndplukket {maerke} {model} i topklasse {emoji}",
      "{emoji} Exceptionel værdi: En premium {maerke} {model} til en attraktiv pris",
      "Tidsbegrænset premium tilbud: {maerke} {model} {emoji}",
      "Oplev ægte luksus: {maerke} {model} til en særlig fordelagtig pris {emoji}",
      "{emoji} En unik investeringsmulighed: {maerke} {model} med særlig værdi",
      "Premium opportunity: {maerke} {model} med ekstraordinær værdi {emoji}"
    ],
    'Prisjustering': [
      "{emoji} Ny attraktiv værdisætning på denne eksklusive {maerke} {model}",
      "Optimeret premium-pris på vores eksklusive {maerke} {model} {emoji}",
      "{emoji} Særlig værditilpasning på denne enestående {maerke} {model}",
      "Ny fordelagtig prisposition på vores {maerke} {model} {emoji}",
      "Premium value: {maerke} {model} med opdateret prissætning {emoji}",
      "{emoji} Øget værdi: Oplev denne {maerke} {model} til ny attraktiv pris",
      "Exceptionel mulighed: {maerke} {model} med justeret værdisætning {emoji}",
      "Optimeret investering: {maerke} {model} til ny attraktiv pris {emoji}",
      "{emoji} Øget tilgængelighed: Premium {maerke} {model} med ny prissætning",
      "Særlig prisposition på denne unikke {maerke} {model} {emoji}"
    ],
    'Dagens bil': [
      "{emoji} Spotlight: En exceptionel {maerke} {model} i særklasse",
      "Today's Excellence: En håndplukket {maerke} {model} {emoji}",
      "{emoji} Dagens premium selection: En enestående {maerke} {model}",
      "Featured masterpiece: En unik {maerke} {model} {emoji}",
      "Dagens automotive highlight: {maerke} {model} i topklasse {emoji}",
      "{emoji} Premium feature: En særligt udvalgt {maerke} {model}",
      "Spotlight on Excellence: {maerke} {model} i særklasse {emoji}",
      "Today's Finest: En exceptionel {maerke} {model} {emoji}",
      "{emoji} Dagens eksklusive valg: En unik {maerke} {model}",
      "Featured Excellence: Premium {maerke} {model} {emoji}"
    ],
    'Netop solgt': [
      "{emoji} Succeshistorie: Denne eksklusive {maerke} {model} har fundet sin nye ejer",
      "Endnu en tilfreds connoisseur: {maerke} {model} er nu solgt {emoji}",
      "{emoji} Premium match: Denne {maerke} {model} kører nu mod nye eventyr",
      "Tak for tilliden: En exceptionel {maerke} {model} har fundet sit nye hjem {emoji}",
      "Perfekt match: Denne unikke {maerke} {model} er nu solgt {emoji}",
      "{emoji} Automotive excellence finder vej: {maerke} {model} har fundet sin nye ejer",
      "Succesfuld formidling: Premium {maerke} {model} er nu solgt {emoji}",
      "En ny chapter begynder: {maerke} {model} har fundet sin rette ejer {emoji}",
      "{emoji} Når kvalitet møder sin match: {maerke} {model} er nu solgt",
      "Excellence delivered: {maerke} {model} kører nu mod nye horisonter {emoji}"
    ],
    'Eftertragtet model': [
      "{emoji} Exclusive arrival: Den højt efterspurgte {maerke} {model} er nu tilgængelig",
      "Premium opportunity: Den eftertragtede {maerke} {model} kan nu blive din {emoji}",
      "{emoji} Limited availability: En exceptionel {maerke} {model} venter",
      "Særlig chance: Den populære {maerke} {model} er klar til fremvisning {emoji}",
      "High demand meets excellence: {maerke} {model} nu i vores showroom {emoji}",
      "{emoji} Efterspurgt eksklusivitet: {maerke} {model} i særklasse",
      "Premium selection: Den populære {maerke} {model} er nu tilgængelig {emoji}",
      "Most wanted: En enestående {maerke} {model} venter på dig {emoji}",
      "{emoji} Eftertragtet excellence: {maerke} {model} i topklasse",
      "Limited edition: Den eksklusive {maerke} {model} er nu her {emoji}"
    ],
    'Skarp pris': [
      "{emoji} Optimal value proposition: {maerke} {model} til en attraktiv pris",
      "Premium quality meets exceptional value: {maerke} {model} {emoji}",
      "{emoji} Særlig investeringsmulighed: En unik {maerke} {model}",
      "Exceptionel værdi: Premium {maerke} {model} til fordelagtig pris {emoji}",
      "Smart investment: {maerke} {model} med optimal værdisætning {emoji}",
      "{emoji} Value meets luxury: En særlig {maerke} {model} til skarp pris",
      "Premium deal: Oplev denne {maerke} {model} til en attraktiv pris {emoji}",
      "Optimal prissætning på denne eksklusive {maerke} {model} {emoji}",
      "{emoji} Særlig værdi: En premium {maerke} {model} til skarp pris",
      "Intelligent investering: {maerke} {model} med optimal prissætning {emoji}"
    ]
  };
  
  export const midSectionSentences = {
    'Nyhed': [
      "En perfekt fusion af innovativt design og banebrydende teknologi.",
      "Oplev en ny dimension af køreglæde med state-of-the-art features.",
      "Exceptionel komfort møder avanceret teknologi i denne mesterlige creation.",
      "Fra det sofistikerede eksteriør til det luksuriøse interiør - en sand nydelse.",
      "Hver detalje er nøje udvalgt for at skabe den ultimative køreoplevelse.",
      "En harmonisk balance mellem kraftfuld performance og raffineret elegance.",
      "Innovative løsninger og tidløs design forenes i denne særlige model.",
      "Oplev en ny standard for automotive excellence og kørekomfort.",
      "Et mesterværk af ingeniørkunst og æstetisk perfektion.",
      "Når passion for detaljen møder visionær innovation."
    ],
    'Ny bil på lager': [
      "Håndselekteret til vores eksklusive collection af premium køretøjer.",
      "En særligt udvalgt model der repræsenterer det ypperste inden for sin klasse.",
      "Nøje udvalgt for at imødekomme selv de mest kræsne bilentusiasters forventninger.",
      "En exceptionel kombination af kraftfuld performance og sofistikeret elegance.",
      "Denne særlige model sætter nye standarder for kvalitet og køreglæde.",
      "Et mesterværk af tysk ingeniørkunst, klar til at blive opdaget.",
      "Perfekt balance mellem luksuriøs komfort og dynamisk køreegenskaber.",
      "En unik mulighed for at erhverve en bil i særklasse.",
      "Exceptionel kvalitet møder innovativ teknologi i denne unikke model.",
      "En sjælden perle i vores eksklusive sortiment."
    ],
    'Tilbud': [
      "En enestående mulighed for at erhverve automotive excellence til særlig værdi.",
      "Oplev premium kvalitet og eksklusive features til en fordelagtig pris.",
      "Et unikt tilbud på en bil der overgår alle forventninger.",
      "Exceptionel værdi møder uovertruffen kvalitet i dette særlige tilbud.",
      "En sjælden chance for at opleve ægte luksus til en attraktiv pris.",
      "Premium features og sofistikeret design til en fordelagtig værdi.",
      "En intelligent investering i kvalitet og køreglæde.",
      "Tidsbegrænset mulighed for at erhverve denne særlige model.",
      "Oplev den perfekte balance mellem værdi og excellence.",
      "En unik chance for at træde ind i premium-segmentet."
    ],
    'Prisjustering': [
      "Nu med en endnu mere attraktiv værdisætning på denne premium model.",
      "Optimeret prisposition på en bil der stadig overgår alle forventninger.",
      "En exceptionel mulighed for at erhverve automotive excellence.",
      "Justeret værdisætning på denne enestående kvalitetsbil.",
      "Forbedret value proposition på en bil i særklasse.",
      "En unik investeringsmulighed i tidløs kvalitet og stil.",
      "Optimeret prissætning på denne særligt udvalgte model.",
      "En sjælden chance for at erhverve premium kvalitet.",
      "Attraktiv ny værdisætning på denne eksklusive model.",
      "En fordelagtig mulighed for at opleve ægte luksus."
    ],
    'Dagens bil': [
      "Et mesterværk af ingeniørkunst og innovativt design.",
      "Exceptionel kvalitet møder tidløs elegance i denne særlige model.",
      "En perfekt fusion af kraftfuld performance og raffineret komfort.",
      "Oplev en ny dimension af automotive excellence.",
      "Når kun det bedste er godt nok - en bil i særklasse.",
      "Et symbol på perfektion og køreglæde.",
      "Innovativ teknologi møder klassisk elegance.",
      "En særligt udvalgt perle i vores premium sortiment.",
      "Kompromisløs kvalitet og enestående køreegenskaber.",
      "Et statement af sofistikeret design og kraftfuld performance."
    ],
    'Netop solgt': [
      "En perfekt match mellem bil og connoisseur.",
      "Endnu en succesfuld formidling af automotive excellence.",
      "Vi takker for tilliden og ønsker god rejse med denne enestående bil.",
      "Et perfekt eksempel på når kvalitet finder sin rette ejer.",
      "En exceptionel bil har fundet sit nye hjem.",
      "Succesfuld overlevering af denne premium model.",
      "En særlig bil fortjener en særlig ejer - tillykke med købet.",
      "Vi fejrer endnu en vellykket premium-handel.",
      "Tak for muligheden for at formidle denne unikke bil.",
      "En perfekt afslutning på en exceptionel handelsoplevelse."
    ],
    'Eftertragtet model': [
      "En af vores mest efterspurgte premium modeller.",
      "Et mesterværk der fortsætter med at imponere og inspirere.",
      "En tidløs klassiker der aldrig går af mode.",
      "Exceptionel kvalitet der taler for sig selv.",
      "En model der konstant sætter nye standarder i sin klasse.",
      "Efterspurgt for sin unikke kombination af stil og ydeevne.",
      "En bil der fortsætter med at overgå alle forventninger.",
      "Et symbol på tidløs elegance og kraftfuld performance.",
      "En model der har vundet hjerter verden over.",
      "Eftertragtet for sin kompromisløse tilgang til kvalitet."
    ],
    'Skarp pris': [
      "Exceptionel værdi møder kompromisløs kvalitet.",
      "En sjælden mulighed for at erhverve premium kvalitet.",
      "Optimal værdisætning på en bil i særklasse.",
      "En intelligent investering i automotive excellence.",
      "Premium features til en særdeles attraktiv pris.",
      "En unik chance for at opleve ægte luksus.",
      "Fordelagtig værdi på en enestående kvalitetsbil.",
      "En perfekt balance mellem pris og premium kvalitet.",
      "Særlig værdisætning på denne eksklusive model.",
      "En attraktiv mulighed for at træde ind i premium-segmentet."
    ]
  };
  
  export const outroSentences = {
    'Nyhed': [
      "Book en eksklusiv fremvisning og oplev denne exceptionelle bil i vores premium showroom.",
      "Kontakt vores dedikerede salgsteam for en personlig præsentation af denne unikke model.",
      "Lad os arrangere en privat fremvisning, hvor du kan opleve denne særlige bil.",
      "Vi inviterer til en eksklusiv præsentation af denne enestående model.",
      "Tag det første skridt mod en exceptional køreoplevelse - kontakt os i dag.",
      "Oplev denne mesterlige creation ved en personlig fremvisning i vores showroom.",
      "Lad os introducere dig for din næste premium-bil - book en fremvisning.",
      "Vi står klar til at give dig en eksklusiv introduktion til denne særlige model.",
      "Kontakt os for en personlig dialog om denne enestående bil.",
      "Book din private præsentation og oplev ægte automotive excellence."
    ],
    'Ny bil på lager': [
      "Velkommen til en eksklusiv fremvisning af denne særlige model i vores showroom.",
      "Vi inviterer til en personlig præsentation af dette automotive mesterværk.",
      "Lad os arrangere en privat demonstration af denne enestående bil.",
      "Book en eksklusiv fremvisning og oplev denne premium model.",
      "Vi står klar til at give dig en særlig introduktion til denne unikke bil.",
      "Kontakt os for en personlig dialog om denne exceptionelle model.",
      "Tag det første skridt mod en unik køroplevelse - book en fremvisning.",
      "Lad os præsentere denne særlige bil for dig i vores premium showroom.",
      "Vi tilbyder en eksklusiv introduktion til denne enestående model.",
      "Oplev denne særlige bil ved en personlig præsentation."
    ],
    'Tilbud': [
      "Kontakt os for en fortrolig dialog om denne attraktive mulighed.",
      "Book en privat fremvisning og hør mere om denne særlige value proposition.",
      "Vi står klar til at præsentere denne exceptionelle bil og dens værdi.",
      "Tag første skridt mod en premium-oplevelse til en fordelagtig værdi.",
      "Lad os arrangere en eksklusiv fremvisning af denne unikke mulighed.",
      "Oplev denne særlige model og dens attraktive værdi ved en personlig præsentation.",
      "Vi inviterer til en fortrolig dialog om denne enestående mulighed.",
      "Book en privat fremvisning og udforsk denne særlige value proposition.",
      "Kontakt os for en personlig introduktion til denne exceptionelle mulighed.",
      "Lad os præsentere denne unikke bil og dens attraktive værdi for dig."
    ],
    'Prisjustering': [
      "Kontakt os for en fortrolig dialog om den nye værdisætning.",
      "Book en privat fremvisning og oplev denne særlige model til ny værdi.",
      "Vi inviterer til en personlig præsentation af denne attraktive mulighed.",
      "Lad os arrangere en eksklusiv fremvisning med fokus på den nye værdi.",
      "Tag første skridt mod en premium-oplevelse til optimeret værdi.",
      "Vi står klar til at præsentere denne særlige bil og dens nye position.",
      "Oplev denne enestående model og dens attraktive nye værdi.",
      "Kontakt os for en personlig dialog om denne exceptionelle mulighed.",
      "Book en privat fremvisning og udforsk den nye value proposition.",
      "Lad os introducere dig for denne premium mulighed."
    ],
    'Dagens bil': [
      "Book en eksklusiv fremvisning af dagens særligt udvalgte model.",
      "Vi inviterer til en personlig præsentation af denne enestående bil.",
      "Lad os arrangere en privat demonstration af denne exceptionelle model.",
      "Kontakt os for en fortrolig dialog om denne særlige mulighed.",
      "Tag første skridt mod en unik køroplevelse - book en fremvisning.",
      "Vi står klar til at præsentere denne premium model for dig.",
      "Oplev denne særlige bil ved en personlig præsentation.",
      "Book en privat fremvisning i vores eksklusive showroom.",
      "Lad os introducere dig for denne enestående model.",
      "Vi tilbyder en eksklusiv præsentation af denne særlige bil."
    ],
    'Netop solgt': [
      "Kontakt os for en fortrolig dialog om salg af din premium bil.",
      "Vi søger løbende eksklusive biler til vores kræsne kunder.",
      "Lad os vurdere din bil - vi har købere klar til særlige modeller.",
      "Overvejer du at sælge? Vi tilbyder en professionel og diskret proces.",
      "Vi har stor efterspørgsel på premium biler - kontakt os for en vurdering.",
      "Lad os hjælpe med at formidle din bil til den rette køber.",
      "Vi specialiserer os i formidling af særlige biler - kontakt os i dag.",
      "Erfaring med eksklusive handler - lad os vurdere din bil.",
      "Professional og diskret formidling af premium biler.",
      "Vi har netværket til at finde den rette køber til din bil."
    ],
    'Eftertragtet model': [
      "Book en eksklusiv fremvisning af denne højt efterspurgte model.",
      "Vi inviterer til en personlig præsentation af denne særlige bil.",
      "Kontakt os for en fortrolig dialog om denne eftertragtede model.",
      "Lad os arrangere en privat demonstration mens muligheden er der.",
      "Tag første skridt mod at sikre dig denne eksklusive model.",
      "Vi står klar til at præsentere denne enestående bil for dig.",
      "Book en privat fremvisning mens denne særlige model er tilgængelig.",
      "Oplev denne eftertragtede bil ved en eksklusiv præsentation.",
      "Kontakt os for en personlig introduktion til denne unikke model.",
      "Lad os introducere dig for denne særlige mulighed."
    ],
    'Skarp pris': [
      "Kontakt os for en fortrolig dialog om denne attraktive mulighed.",
      "Book en privat fremvisning og oplev denne særlige value proposition.",
      "Vi inviterer til en personlig præsentation af denne unikke mulighed.",
      "Lad os arrangere en eksklusiv fremvisning af denne premium model.",
      "Tag første skridt mod en exceptionel bil til attraktiv værdi.",
      "Vi står klar til at præsentere denne særlige mulighed for dig.",
      "Oplev denne enestående bil ved en personlig præsentation.",
      "Book en privat fremvisning og udforsk denne unikke mulighed.",
      "Kontakt os for en fortrolig dialog om denne særlige værdi.",
      "Lad os introducere dig for denne attraktive premium mulighed."
    ]
  };
  
  export const linkSentences = [
    "Se mere til bilen her: {url} 📲",
    "Få alle detaljerne her: {url} 💻",
    "Læs mere om bilen på vores hjemmeside: {url} 🌐",
    "Klik her for at se flere billeder og info: {url} 🖥️",
    "Besøg linket for mere information: {url} 🔗",
    "Se alle specifikationer her: {url} 📋",
    "Oplev bilen online her: {url} 🚗",
    "Find flere detaljer via linket: {url} 🔍",
    "Tjek bilen ud på vores side: {url} 🏁",
    "Din næste bil er kun ét klik væk: {url} 🖱️",
  ];

  export const karruselIntroSentences = {
    'Nyheder': [
      "{emoji} Se vores seneste nyheder! Vi har fået {antal} spændende biler på lager",
      "Friske nyheder! {antal} nye biler er netop ankommet {emoji}",
      "{emoji} Spændende nyt! Tjek vores {antal} seneste tilføjelser",
      "Nyhed x{antal}! Se vores seneste biler {emoji}",
      "Vi har travlt! {antal} nye biler er landet hos os {emoji}"
    ],
    'Nye priser': [
      "{emoji} Vi har justeret priserne på {antal} af vores biler!",
      "Nye priser på {antal} udvalgte biler {emoji}",
      "Prisopdatering! Se de nye priser på {antal} biler {emoji}",
      "{emoji} Prisændringer på {antal} populære modeller",
      "Vi har sænket priserne! Se {antal} gode tilbud {emoji}"
    ],
    'Populære biler': [
      "Se vores top {antal} mest populære biler lige nu {emoji}",
      "{emoji} {antal} eftertragtede biler venter på dig!",
      "Kundefavoritter! Her er {antal} af vores mest populære biler {emoji}",
      "Tjek disse {antal} populære modeller ud {emoji}",
      "{emoji} {antal} biler der fanger alles opmærksomhed"
    ]
  };

  export const karruselMidSectionSentences = {
    'Nyheder': [
      "Swipe for at se alle de spændende nyheder!",
      "Der er noget for enhver smag i denne omgang.",
      "Vi er stolte af at præsentere disse nye tilføjelser.",
      "Kvalitet og variation i alle prisklasser.",
      "Kom forbi og se alle nyhederne med egne øjne!"
    ],
    // ... tilføj flere kategorier efter behov ...
  };

  export const karruselOutroSentences = {
    'Nyheder': [
      "Book en fremvisning af dine favoritter i dag!",
      "Vi står klar til at vise dig alle bilerne.",
      "Kom forbi og lad os finde den perfekte bil til dig.",
      "Ring til os for mere information om bilerne.",
      "Vi hjælper dig med at vælge den rigtige bil blandt nyhederne."
    ],
    // ... tilføj flere kategorier efter behov ...
  };