<template>
  <main>
    <div class="container-fluid">
      <!-- Header and navigation pills -->
      <div
        class="page-header min-height-200"
        :style="{
          backgroundImage: 'url(' + defaultHeaderImage + ')',
          marginRight: '-24px',
          marginLeft: '-24px',
          marginTop: '-16px'
        }"
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
      <div class="card shadow-lg custom-margin">
        <div class="card-body p-3">
          <div class="row gx-4">
            <!-- Vis kun avatar hvis der er et profilbillede -->
            <template v-if="userData.userSettings?.profileImage">
              <div class="col-auto">
                <div class="avatar avatar-xl position-relative">
                  <img
                    :src="userData.userSettings.profileImage"
                    alt="profile_image"
                    class="shadow-sm w-100 border-radius-lg"
                  />
                </div>
              </div>
            </template>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">{{ userData.name }}</h5>
              </div>
            </div>
            <div class="col d-flex align-items-center justify-content-end">
              <argon-button
                color="dark"
                size="sm"
                v-if="activeUser && (activeUser.role === 1 || activeUser.role === 2)"
                variant="gradient"
                @click="gobackToTable"
                class="me-3"
              >
                Gå tilbage
              </argon-button>
              <div class="nav-wrapper position-relative end-0">
                <!-- Navigation pills -->
                <ul class="nav nav-pills nav-fill p-1" role="tablist">
                  <li class="nav-item" v-for="tab in visibleTabs" :key="tab.value">
                    <template v-if="tab.subTabs">
                      <div class="dropdown">
                        <a
                          class="nav-link custom-nav-link dropdown-toggle"
                          :class="{ 'active-tab': activeTab === tab.value || tab.subTabs.some(st => activeTab === st.value) }"
                          href="#"
                          role="button"
                          @click.prevent="toggleDropdown"
                        >
                          {{ tab.label }}
                        </a>
                        <ul class="dropdown-menu" :class="{ 'show': isDropdownOpen }">
                          <li v-for="subTab in tab.subTabs" :key="subTab.value">
                            <a
                              class="dropdown-item"
                              href="#"
                              @click.prevent="selectSubTab(subTab.value)"
                            >
                              {{ subTab.label }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </template>
                    <a
                      v-else
                      class="nav-link custom-nav-link"
                      :class="{ 'active-tab': activeTab === tab.value }"
                      href="#"
                      role="tab"
                      @click.prevent="$event => changeTab(tab.value)"
                    >
                      {{ tab.label }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-4 container-fluid">
      <div class="row">
        <!-- Venstre kolonne med navigation -->
        <div class="col-md-3" v-if="activeTab === 'profile'">
          <div class="card">
            <div class="card-body p-3">
              <ul class="nav flex-column bg-white border-radius-lg">
                <li class="nav-item" v-for="item in menuItems" :key="item.id">
                  <a 
                    class="nav-link text-body d-flex align-items-center" 
                    :class="{ 'active': activeSection === item.id }"
                    href="#" 
                    @click.prevent="activeSection = item.id"
                  >
                    <i :class="item.icon + ' me-2 text-dark opacity-6'"></i>
                    <span class="text-sm">{{ item.label }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        
        <!-- Midterste kolonne med indhold -->
        <div :class="{'col-md-6': activeTab === 'profile', 'col-md-12': activeTab !== 'profile'}">
          <div v-if="activeTab === 'profile'">
            <div class="card">
              <div class="card-body p-3">
                <!-- Indholdssektion -->
                <div class="tab-content">
                  <!-- Brugerinformation -->
                  <div v-if="activeSection === 'basicInfo'">
                    <div class="card-header pb-0 p-3">
                      <h6 class="mb-1">Brugerinformation</h6>
                      <p class="text-sm mb-0">Opdater dine brugeroplysninger her.
                        <br>
                        <br>
                      </p>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-control-label">Firmanavn</label>
                            <argon-input v-model="userData.name" type="text" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-control-label">Email</label>
                            <argon-input v-model="userData.email" type="email" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-control-label">CVR</label>
                            <argon-input v-model="userData.cvr" type="text" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-control-label">Telefon</label>
                            <argon-input v-model="userData.phone" type="text" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="form-control-label">Adresse</label>
                            <argon-input v-model="userData.address" type="text" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Hjemmesider -->
                  <div v-if="activeSection === 'websites'">
                    <div class="card-header pb-0 p-3">
                      <h6 class="mb-1">Linkoplysninger</h6>
                      <p class="text-sm mb-0">Opdater dine linkoplysninger her.
                        <br>
                        <br> 
                      </p>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-control-label">Hovedside</label>
                            <argon-input v-model="userData.userLinks.mainWebsite" type="url" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-control-label">Booking</label>
                            <argon-input v-model="userData.userLinks.bookingWebsite" type="url" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-control-label">Webshop</label>
                            <argon-input v-model="userData.userLinks.webshopWebsite" type="url" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-control-label">Website</label>
                            <argon-input v-model="userData.userLinks.website" type="url" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Rediger profil sektion -->
                  <div v-if="activeSection === 'editProfile'">
                    <div class="card-header pb-0 p-3">
                      <h6 class="mb-1">Rediger profil</h6>
                      <p class="text-sm mb-0">Opdater dit profilbillede her.
                        <br>
                        <br> 
                      </p>
                    </div>
                    <div class="card-body">
                      <div class="row mb-4">
                        <div class="col-12 d-flex align-items-center">
                          <div class="me-4">
                            <div class="image-upload-container profile-container mb-2">
                              <input type="file" id="profileImageUpload" @change="handleProfileImageUpload" accept="image/*" class="image-upload-input">
                              <label for="profileImageUpload" class="image-upload-label">
                                <img v-if="profileImagePreview" :src="profileImagePreview" alt="Profilbillede" class="preview-image">
                                <img v-else-if="userData.userSettings?.profileImage" :src="userData.userSettings.profileImage" alt="Profilbillede" class="preview-image">
                                <div v-else class="upload-icon-wrapper">
                                  <i class="fas fa-image"></i>
                                </div>
                              </label>
                            </div>
                            <label class="form-label text-center d-block small">Profilbillede</label>
                          </div>
                        </div>
                      </div>
                      
                      <!-- Facebook import knap -->
                      <div class="d-flex justify-content-between align-items-center mb-4">
                        <argon-button @click="fetchAndUploadImages" color="dark" size="sm" class="d-flex align-items-center">
                          <i class="fab fa-facebook me-2"></i>
                          Hent fra Facebook
                        </argon-button>
                      </div>
                    </div>
                  </div>

                  <!-- Skift adgangskode og email sektion -->
                  <div v-if="activeSection === 'changePassword'">
                    <div class="card-header pb-0 p-3">
                      <h6 class="mb-1">Nulstil adgangskode</h6>
                      <p class="text-sm mb-0">Nulstil din adgangskode her på denne mail: {{ auth.currentUser?.email }}.
                        <br>
                        <br> 
                      </p>
                    </div>
                    <div class="card-body">
                      <div class="form-group">
                        <argon-input 
                          v-model="resetEmail" 
                          id="reset-email" 
                          type="email" 
                          placeholder="Din e-mailadresse" 
                          label="E-mail"
                        />
                      </div>
                      <argon-button @click="resetPassword" color="info" fullWidth>Send nulstillingslink</argon-button>
                      
                      <h6 class="mb-3 mt-4">Ændre login-email</h6>
                      <p class="text-sm mb-3">
                        Din nuværende email er: {{ auth.currentUser?.email }}
                      </p>
                      <div class="form-group">
                        <argon-input 
                          v-model="newEmail" 
                          id="new-email" 
                          type="email" 
                          placeholder="Indtast en ny e-mailadresse" 
                          label="Ny E-mail"
                        />
                      </div>
                      <argon-button @click="updateEmail" color="primary" fullWidth>Opdater email</argon-button>

                      <p v-if="errorMessage" class="text-danger mt-3">{{ errorMessage }}</p>
                      <p v-if="successMessage" class="text-success mt-3">{{ successMessage }}</p>
                    </div>
                  </div>

                  <!-- Fakturaer sektion -->
                  <div v-if="activeSection === 'invoices'">
                    <invoice-overview 
                      :userId="getCurrentUserId"
                      :key="getCurrentUserId"
                    />
                  </div>

                  <!-- Design Indstillinger -->
                  <div v-if="activeSection === 'designSettings'" class="design-settings">
                    <div class="card-header pb-0 p-3">
                      <h6 class="mb-1">Design Indstillinger</h6>
                      <p class="text-sm mb-0">Opdater dine design indstillinger her.
                        <br>
                        <br> 
                      </p>
                    </div>
                    <div class="card-body">
                      <div class="settings-grid">
                        <!-- Tema Farve -->
                        <div class="setting-item">
                          <label class="setting-label">Tema Farve</label>
                          <div class="color-picker-wrapper">
                            <input type="color" v-model="themeColor" class="color-picker" />
                            <span class="color-value">{{ themeColor }}</span>
                          </div>
                        </div>
                        
                        <!-- Tekst Farve -->
                        <div class="setting-item">
                          <label class="setting-label">Tekst Farve</label>
                          <div class="color-picker-wrapper">
                            <input type="color" v-model="textColor" class="color-picker" />
                            <span class="color-value">{{ textColor }}</span>
                          </div>
                        </div>
                        
                        <!-- Logo -->
                        <div class="setting-item">
                          <label class="setting-label">Logo</label>
                          <div class="logo-upload-container">
                            <input type="file" id="logoUpload" @change="handleLogoUpload" accept="image/*" class="logo-upload-input">
                            <label for="logoUpload" class="logo-upload-label">
                              <img v-if="logoPreview" :src="logoPreview" alt="Logo" class="logo-preview">
                              <img v-else-if="userData.userSettings?.logo" :src="userData.userSettings.logo" alt="Logo" class="logo-preview">
                              <div v-else class="upload-placeholder">
                                <i class="fas fa-cloud-upload-alt"></i>
                                <span>Upload logo</span>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      
                      <!-- HashTags -->
                      <div class="setting-item mt-4">
                        <label class="setting-label">HashTags (max 17)</label>
                        <div class="hashtag-input-wrapper">
                          <input 
                            type="text" 
                            v-model="newHashTag" 
                            @keyup.enter="addHashTag" 
                            placeholder="Tilføj hashtag og tryk enter" 
                            class="form-control"
                          >
                        </div>
                        <div class="hashtag-list">
                          <span v-for="(tag, index) in hashTags" :key="index" class="hashtag-item">
                            #{{ tag }}
                            <button @click="removeHashTag(index)" class="remove-hashtag">&times;</button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Handlinger -->
                  <div v-if="activeSection !== 'changePassword' && activeSection !== 'invoices'" class="d-flex justify-content-end mt-4">
                    <argon-button 
                      @click="updateUserData" 
                      color="success"
                      size="sm"
                      variant="gradient"
                    >
                      Gem ændringer
                    </argon-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Print Materials Tab Content -->
          <div v-else-if="activeTab === 'printMaterials'" class="content-offset">
            <print-materials />
          </div>

          <!-- Autopost Manager Tab Content -->
          <div v-else-if="activeTab === 'autopostManager'" class="content-offset">
            <auto-post-manager />
          </div>

          <!-- Calendar Tab Content -->
          <div v-else-if="activeTab === 'calendar'">
            <Calendar />
          </div>

          <!-- Dealer Cars Tab Content -->
          <div v-else-if="activeTab === 'dealerCars'">
            <get-dealer-cars />
          </div>

          <!-- Posts Content -->
          <div v-else-if="activeTab === 'posts'" class="content-offset">
            <post-manager />
          </div>
        </div>

        <!-- Højre kolonne med Profile Card -->
        <div class="col-md-3" v-if="activeTab === 'profile'">
          <profile-card :user-data="userData" />
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeMount, onBeforeUnmount, watch, inject } from "vue";
import { useStore } from "vuex";
import ProfileCard from "./components/ProfileCard.vue";
import NewProject from "./components/NewProject.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { doc, getDoc, updateDoc, deleteField } from "firebase/firestore";
import { db, auth } from "../../firebase.js";
import { uploadFile } from "../../services/uploadeService.js";
import Calendar from "./Calendar.vue";
import { useRouter, useRoute } from "vue-router";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { usePasswordManagement } from './components/usePasswordManagement';
import { updateEmail as firebaseUpdateEmail, sendEmailVerification, sendSignInLinkToEmail } from "firebase/auth";
import GetDealerCars from "./GetDealerCars.vue";
import CarPost from "./components/postCars.vue";
import { calendarService } from '@/services/calendarService';

import PrintMaterials from "./components/PrintMaterials.vue"
import AutoPostManager from './components/AutoPostManager.vue';
import { signOut } from "firebase/auth";
import axios from 'axios';
import { collection, query, where, getDocs, orderBy, addDoc } from 'firebase/firestore';
import InvoiceOverview from './components/InvoiceOverview.vue';
import PostManager from './components/PostManager.vue';

const store = useStore();
const router = useRouter();
const route = useRoute();
const activeUser = computed(() => store.getters.activeUser);

// Active tab state
const activeTab = ref('profile');

const tabs = [
  //{ value: 'printMaterials', label: 'Printmaterialer' },
  { value: 'autopostManager', label: 'Autopilot' },
  { value: 'posts', label: 'Opslag' },
  { value: 'calendar', label: 'Kalender' },
  { value: 'dealerCars', label: 'Mine biler' },
  { value: 'profile', label: 'Profil' },
  { value: 'logout', label: 'Log ud', icon: 'fas fa-right-from-bracket' }
];

const visibleTabs = computed(() => {
  if (userData.value.companyType === 'auto') {
    return tabs;
  } else {
    return tabs.filter(tab => tab.value !== 'dealerCars');
  }
});

const userData = ref({
  address: "",
  companyId: "",
  companyType: "",
  createdBy: "",
  cvr: 0,
  email: "",
  id: "",
  isOnline: false,
  name: "",
  phone: "",
  role: 0,
  userSettings: {
    headerImage: "",
    profileImage: ""
  },
  userLinks: {
    bookingWebsite: "",
    mainWebsite: "",
    webshopWebsite: "",
    website: ""
  },
  MetaSettings: {},
  settings: {}
});

const activeSection = ref('basicInfo');

const menuItems = computed(() => {
  const items = [
    { id: 'basicInfo', label: 'Information', icon: 'fas fa-user' },
    { id: 'websites', label: 'Linkoplysninger', icon: 'fas fa-globe' },
    { id: 'editProfile', label: 'Profil & Cover billede', icon: 'fas fa-edit' },
    { id: 'changePassword', label: 'Nulstil adgangskode', icon: 'fas fa-key' },
    { id: 'invoices', label: 'Fakturaer', icon: 'fas fa-file-invoice' },
  ];
  
  if (userData.value.companyType === 'auto') {
    items.splice(3, 0, { id: 'designSettings', label: 'Design Indstillinger', icon: 'fas fa-paint-brush' });
  }

  return items;
});

const profileImageFile = ref(null);
const headerImageFile = ref(null);
const profileImagePreview = ref(null);
const headerImagePreview = ref(null);

const {
  resetEmail,
  errorMessage,
  successMessage,
  resetPassword,
  loadUserEmail
} = usePasswordManagement();

const newEmail = ref("");

const logoFile = ref(null);
const logoPreview = ref(null);
const themeColor = ref(userData.value.userSettings.themeColor || '#ffffff');
const textColor = ref(userData.value.userSettings.textColor || '#000000');

const activeNewProjectSubTab = ref("manual");
const isDropdownOpen = ref(false);

const newProjectSubTabs = [
  { value: 'car', label: 'Bil opslag' },
  { value: 'manual', label: 'Standard opslag' }
];

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

const selectNewProjectSubTab = (value) => {
  activeNewProjectSubTab.value = value;
  activeTab.value = 'newProject';
  isDropdownOpen.value = false;
};

const updateEmail = async () => {
  errorMessage.value = '';
  successMessage.value = '';

  if (!newEmail.value) {
    errorMessage.value = "Indtast venligst en ny e-mailadresse.";
    return;
  }

  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('Ingen bruger er logget ind');
    }

    await firebaseUpdateEmail(user, newEmail.value);
    await sendEmailVerification(user);
    
    successMessage.value = "En verifikationsmail er sendt til din nye e-mailadresse. Tjek venligst din indbakke og følg instruktionerne.";
    newEmail.value = "";
  } catch (error) {
    console.error("Fejl ved opdatering af email:", error);
    
    switch (error.code) {
      case 'auth/requires-recent-login':
        errorMessage.value = "For din sikkerhed skal du logge ind igen før du kan ændre din email.";
        break;
      case 'auth/invalid-email':
        errorMessage.value = "Ugyldig e-mailadresse format.";
        break;
      case 'auth/email-already-in-use':
        errorMessage.value = "Denne e-mailadresse er allerede i brug.";
        break;
      default:
        errorMessage.value = `Der opstod en fejl: ${error.message}`;
    }
  }
};

const generateManualLink = () => {
  try {
    const actionCodeSettings = {
      url: 'https://toft-d4f39.firebaseapp.com/finalize-email-update',
      handleCodeInApp: true,
    };

    // Generer en unik identifikator
    const uniqueId = Math.random().toString(36).substring(2, 15);

    // Konstruer linket manuelt
    const baseUrl = 'https://toft-d4f39.firebaseapp.com/__/auth/action';
    const params = new URLSearchParams({
      mode: 'signIn',
      oobCode: uniqueId,
      apiKey: auth.app.options.apiKey,
      continueUrl: actionCodeSettings.url,
    });

    const manualLink = `${baseUrl}?${params.toString()}`;
    console.log("Manuelt genereret email-link:", manualLink);
    
    successMessage.value = "Et manuelt link er blevet genereret. Tjek konsollen for detaljer.";
    return manualLink;
  } catch (error) {
    console.error("Fejl ved generering af manuelt link:", error);
    errorMessage.value = `Fejl ved generering af manuelt link: ${error.message}`;
    return null;
  }
};

const fetchUserData = async (id) => {
  if (id) {
    try {
      const userDocRef = doc(db, "users", id);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const data = userDoc.data();
        const { userLinks, userSettings, ...restData } = data;
        
        // Fjern linkoplysninger fra rodniveauet
        delete restData.bookingWebsite;
        delete restData.mainWebsite;
        delete restData.webshopWebsite;
        delete restData.website;

        userData.value = { 
          ...restData, 
          id,
          userSettings: {
            ...userSettings,
            themeColor: userSettings?.themeColor || '#ffffff',
            textColor: userSettings?.textColor || '#000000',
            logo: userSettings?.logo || ''
          },
          userLinks: userLinks || {
            bookingWebsite: "",
            mainWebsite: "",
            webshopWebsite: "",
            website: ""
          }
        };
        console.log("Fetched user data:", userData.value);

        // Pre-load kalender data hvis det er en almindelig bruger (role 3)
        // og vi er en admin der tilgår deres profil
        if (userData.value.role === 3 && activeUser.value && (activeUser.value.role === 1 || activeUser.value.role === 2)) {
          await calendarService.preloadCalendarData(id);
        }
      } else {
        console.log("Ingen brugerdata fundet!");
      }
    } catch (error) {
      console.error("Fejl ved hentning af brugerdata:", error);
    }
  } else {
    console.log("Bruger-ID mangler.");
  }
};

const handleProfileImageUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    if (file.size > 5 * 1024 * 1024) { // 5 MB grænse
      alert("Filen er for stor. Maksimal størrelse er 5 MB.");
      return;
    }
    if (!file.type.startsWith('image/')) {
      alert("Kun billedfiler er tilladt.");
      return;
    }
    profileImageFile.value = file;
    profileImagePreview.value = URL.createObjectURL(file);
  }
};

const handleHeaderImageUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    if (file.size > 10 * 1024 * 1024) { // 10 MB grænse
      alert("Filen er for stor. Maksimal størrelse er 10 MB.");
      return;
    }
    if (!file.type.startsWith('image/')) {
      alert("Kun billedfiler er tilladt.");
      return;
    }
    headerImageFile.value = file;
    headerImagePreview.value = URL.createObjectURL(file);
  }
};

const handleLogoUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    if (file.size > 5 * 1024 * 1024) { // 5 MB grænse
      alert("Filen er for stor. Maksimal størrelse er 5 MB.");
      return;
    }
    if (!file.type.startsWith('image/')) {
      alert("Kun billedfiler er tilladt.");
      return;
    }
    logoFile.value = file;
    logoPreview.value = URL.createObjectURL(file);
  }
};

const onFileChange = async (event) => {
  const file = event.target.files[0];
  if (file && userData.value && userData.value.id) {
    try {
      await uploadProfileImage(file, userData.value.id);
    } catch (error) {
      console.error("Fejl ved upload af profilbillede:", error);
      alert(`Der opstod en fejl ved upload af profilbillede: ${error.message}`);
    }
  } else {
    console.log("Ingen fil valgt eller bruger er ikke logget ind.");
  }
};

const uploadProfileImage = async (file, userId) => {
  try {
    const downloadURL = await uploadFile(file, "profileImages");
    if (downloadURL) {
      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, { 
        userSettings: {
          ...userData.value.userSettings,
          profileImage: downloadURL
        }
      });
      userData.value.userSettings.profileImage = downloadURL;
      alert("Profilbillede opdateret!");
    } else {
      throw new Error("Download URL er undefined.");
    }
  } catch (error) {
    console.error("Fejl ved upload af profilbillede:", error);
    alert(`Der opstod en fejl ved upload af profilbillede: ${error.message}`);
  }
};

const updateUserData = async () => {
  const user = auth.currentUser;
  if (!user) {
    console.error("Ingen bruger er logget ind");
    alert("Du skal være logget ind for at opdatere din profil");
    return;
  }

  console.log("Logged in user ID:", user.uid);
  console.log("userData ID:", userData.value.id);

  if (userData.value && userData.value.id) {
    try {
      const userDocRef = doc(db, "users", userData.value.id);
      const { userLinks, userSettings, ...restUserData } = userData.value;
      
      // Fjern linkoplysninger fra rodniveauet
      delete restUserData.bookingWebsite;
      delete restUserData.mainWebsite;
      delete restUserData.webshopWebsite;
      delete restUserData.website;

      const updateData = {
        ...restUserData,
        userSettings: {
          ...userSettings,
          hashTags: hashTags.value
        },
        userLinks: {
          ...userLinks
        }
      };

      const storage = getStorage();

      if (profileImageFile.value) {
        console.log("Forsøger at uploade profilbillede");
        const imageRef = storageRef(storage, `profileImages/${userData.value.id}/${profileImageFile.value.name}`);
        await uploadBytes(imageRef, profileImageFile.value);
        console.log("Profilbillede uploadet");
        const downloadURL = await getDownloadURL(imageRef);
        console.log("Profilbillede download URL:", downloadURL);
        updateData.userSettings.profileImage = downloadURL;
      }

      if (headerImageFile.value) {
        console.log("Forsøger at uploade headerbillede");
        const imageRef = storageRef(storage, `headerImages/${userData.value.id}/${headerImageFile.value.name}`);
        await uploadBytes(imageRef, headerImageFile.value);
        console.log("Headerbillede uploadet");
        const downloadURL = await getDownloadURL(imageRef);
        console.log("Headerbillede download URL:", downloadURL);
        updateData.userSettings.headerImage = downloadURL;
      }

      if (userData.value.companyType === 'auto') {
        updateData.userSettings.themeColor = themeColor.value;
        updateData.userSettings.textColor = textColor.value;

        if (logoFile.value) {
          console.log("Forsøger at uploade logo");
          const imageRef = storageRef(storage, `logos/${userData.value.id}/${logoFile.value.name}`);
          await uploadBytes(imageRef, logoFile.value);
          console.log("Logo uploadet");
          const downloadURL = await getDownloadURL(imageRef);
          console.log("Logo download URL:", downloadURL);
          updateData.userSettings.logo = downloadURL;
        }
      }

      await updateDoc(userDocRef, updateData);
      
      // Fjern linkoplysninger fra rodniveauet i Firebase
      await updateDoc(userDocRef, {
        bookingWebsite: deleteField(),
        mainWebsite: deleteField(),
        webshopWebsite: deleteField(),
        website: deleteField()
      });

      alert("Profil opdateret!");
    } catch (error) {
      console.error("Detaljeret fejl ved opdatering af profil: ", error);
      if (error.code === "storage/unauthorized") {
        console.error("Unauthorized error details:", error);
        alert("Du har ikke tilladelse til at uploade billeder. Kontakt venligst support. Fejlkode: " + error.code);
      } else {
        alert(`Fejl ved opdatering af profil: ${error.message}. Fejlkode: ${error.code}`);
      }
    }
  } else {
    console.log("Bruger-ID mangler.");
  }
};

const profileImageUrl = computed(() => {
  return profileImagePreview.value || userData.value.userSettings?.profileImage || '';
});

watch(profileImageUrl, (newValue) => {
  console.log("Profilbillede URL ændret:", newValue);
});

// Bevar bruger ID mellem refreshes
const userId = ref(route.params.id || store.state.activeUser?.id);

const gobackToTable = () => {
  router.push({ name: "Tables" });
}

const changeTab = (tabValue) => {
  if (tabValue === 'logout') {
    handleLogout();
    return;
  }
  activeTab.value = tabValue;
  console.log('Active tab changed to:', activeTab.value);
};

// Gør changeTab tilgængelig for template'en
defineExpose({ changeTab });

const hashTags = ref(userData.value.userSettings?.hashTags || []);
const newHashTag = ref('');

const addHashTag = () => {
  if (newHashTag.value && !hashTags.value.includes(newHashTag.value) && hashTags.value.length < 17) {
    hashTags.value.push(newHashTag.value);
    newHashTag.value = '';
  }
};

const removeHashTag = (index) => {
  hashTags.value.splice(index, 1);
};

// Tilføj unsubscribeListeners array
let unsubscribeListeners = [];

const handleLogout = async () => {
  try {
    // Fjern alle aktive listeners før logout
    const authComponent = inject('authComponent');
    if (authComponent && authComponent.value?.removeAllListeners) {
      authComponent.value.removeAllListeners();
    }

    // Nulstil eventuelle lokale listeners
    if (unsubscribeListeners) {
      unsubscribeListeners.forEach(unsubscribe => {
        if (typeof unsubscribe === 'function') {
          try {
            unsubscribe();
          } catch (error) {
            console.warn('Fejl ved unsubscribe:', error);
          }
        }
      });
      unsubscribeListeners = [];
    }

    // Log ud
    await auth.signOut();
    await store.dispatch('clearCurrentUser');
    await router.push('/'); // Redirect til Home.vue
  } catch (error) {
    console.error('Fejl ved logout:', error);
  }
};

const fetchAndUploadImages = async () => {
  const pageId = userData.value.MetaSettings.facebookPageId;
  const accessToken = userData.value.MetaSettings.page_access_token;

  if (!pageId || !accessToken) {
    alert("Facebook Page ID eller Access Token mangler.");
    return;
  }

  try {
    const profileImageUrl = await getProfilePictureUrl(pageId, accessToken);

    if (profileImageUrl) {
      userData.value.userSettings.profileImage = profileImageUrl;
    }

    await updateUserData(); // Opdater brugerdata i databasen
    alert("Profilbillede opdateret fra Facebook!");
  } catch (error) {
    console.error("Fejl ved hentning af billeder:", error);
    alert(`Der opstod en fejl ved hentning af billeder fra Facebook: ${error.message}`);
  }
};

const getProfilePictureUrl = async (pageId, accessToken) => {
  try {
    // Først henter vi billedets URL
    const url = `https://graph.facebook.com/v17.0/${pageId}?fields=picture.type(large)&access_token=${accessToken}`;
    const response = await axios.get(url);
    
    if (response.status === 200 && response.data.picture?.data?.url) {
      return response.data.picture.data.url;
    } else {
      console.error("Fejl ved hentning af profilbillede:", response);
      throw new Error("Kunne ikke hente profilbillede URL");
    }
  } catch (error) {
    console.error("Fejl i getProfilePictureUrl:", error);
    throw error;
  }
};

// Hjælpefunktion til at validere email
const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// Opdater onMounted funktionen
onMounted(async () => {
  if (route.params.id) {
    await fetchUserData(route.params.id);
  } else {
    await fetchUserData(store.state.activeUser?.id);
  }
  // Hent fakturaer hvis vi er på fakturaer sektionen
  if (activeSection.value === 'invoices') {
    await fetchInvoices();
  }
});

const invoices = ref([]);

const fetchInvoices = async () => {
  const userId = store.state.activeUser?.id;
  console.log('Fetching invoices for user:', userId); // Debug log
  if (!userId) return;

  try {
    const q = query(
      collection(db, 'invoices'),
      where('userId', '==', userId),
      where('type', '==', 'system'),
      orderBy('createdAt', 'desc')
    );
    
    const querySnapshot = await getDocs(q);
    console.log('Found invoices:', querySnapshot.size); // Debug log
    invoices.value = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    console.log('Processed invoices:', invoices.value); // Debug log
  } catch (error) {
    console.error('Fejl ved hentning af fakturaer:', error);
  }
};

watch(activeSection, async (newValue) => {
  if (newValue === 'invoices') {
    await fetchInvoices();
    if (sortedInvoices.value.length > 0) {
      selectedInvoice.value = sortedInvoices.value[0];
    }
  }
});

const downloadInvoice = (invoice) => {
  // Her kan du implementere PDF download funktionalitet
  console.log('Download invoice:', invoice);
  // TODO: Implementer PDF generering og download
};

const formatPeriod = (timestamp) => {
  if (!timestamp) return '';
  const date = new Date(timestamp.seconds * 1000);
  const month = date.toLocaleString('da-DK', { month: 'long' });
  const year = date.getFullYear();
  return `${month} ${year}`;
};

const formatDate = (date) => {
  const formattedDate = new Date(date.seconds * 1000);
  return formattedDate.toLocaleDateString('da-DK');
};

const formatAmount = (amount) => {
  return new Intl.NumberFormat('da-DK', { style: 'currency', currency: 'DKK' }).format(amount);
};

const formatStatus = (status) => {
  const statusMap = {
    'betalt': 'Betalt',
    'udstedt': 'Afventer betaling',
    'forfalden': 'Forfalden'
  };
  return statusMap[status] || status;
};

const sortedInvoices = computed(() => {
  return [...invoices.value].sort((a, b) => {
    return b.createdAt.seconds - a.createdAt.seconds;
  });
});

// Tilføj disse nye refs og konstanter
const selectedInvoice = ref(null);

const companyInfo = {
  name: 'Toft Digital',
  address: 'Firmaadresse',
  cvr: '12345678',
  phone: '+45 12345678',
  email: 'kontakt@toftdigital.dk'
};

// Tilføj denne nye funktion
const showInvoice = (invoice) => {
  if (!invoice) {
    console.error('Ingen faktura valgt');
    return;
  }
  selectedInvoice.value = invoice;
  console.log('Viser faktura:', invoice); // For debugging
};

const downloadPDF = () => {
  window.print();
};

const handleShowInvoice = async () => {
  if (sortedInvoices.value.length > 0) {
    showInvoice(sortedInvoices.value[0]);
  } else {
    // Hvis der ingen fakturaer er, opret en test faktura
    await createTestInvoice();
  }
};

// Lad os også tilføje en test faktura hvis der ingen er
const createTestInvoice = async () => {
  const userId = store.state.activeUser?.id;
  if (!userId) return;

  try {
    const invoiceData = {
      userId,
      type: 'system',
      createdAt: new Date(),
      dueDate: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000), // 14 dage fra nu
      invoiceNumber: 'TEST-001',
      status: 'udstedt',
      items: [
        {
          description: 'System abonnement - månedlig licens',
          quantity: 1,
          rate: 2995,
          amount: 2995
        }
      ],
      subtotal: 2995,
      tax: 2995 * 0.25,
      total: 2995 * 1.25,
      customerInfo: {
        name: userData.value.name,
        address: userData.value.address,
        phone: userData.value.phone,
        email: userData.value.email,
        cvr: userData.value.cvr
      }
    };

    await addDoc(collection(db, 'invoices'), invoiceData);
    console.log('Test faktura oprettet');
    await fetchInvoices(); // Genindlæs fakturaer
  } catch (error) {
    console.error('Fejl ved oprettelse af test faktura:', error);
  }
};

// Computed property til at bestemme hvilken brugers fakturaer der skal vises
const getCurrentUserId = computed(() => {
  // Hvis vi er på en specifik brugers profil
  if (route.params.id) {
    return route.params.id;
  }
  // Ellers vis den aktive brugers fakturaer
  return store.state.activeUser?.id;
});

// Tilføj watch på activeSection
watch(activeSection, async (newSection) => {
  if (newSection === 'invoices') {
    await fetchUserData(getCurrentUserId.value);
  }
});

const selectSubTab = (value) => {
  activeTab.value = value;
  isDropdownOpen.value = false;
};

const DEFAULT_HEADER_IMAGE = 'https://firebasestorage.googleapis.com/v0/b/toft-d4f39.appspot.com/o/headerImages%2FM8CzwvR9d9Sf3tCcTqMJ3VCObrS2%2Fshubham-dhage-9-o7jBKBGPI-unsplash.jpg?alt=media&token=b57e0c57-52a6-44ec-8c5f-c7279dcd2edd';

const defaultHeaderImage = computed(() => {
  return userData.value?.userSettings?.headerImage || DEFAULT_HEADER_IMAGE;
});
</script>
<style lang="scss" scoped>
.card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 
    0 8px 32px 0 rgba(31, 38, 135, 0.37),
    0 0 0 1px rgba(255, 255, 255, 0.05);
  transform: translateZ(0);
  will-change: transform;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
}

.card:hover {
  transform: translateY(-2px) translateZ(0);
}

.nav-item {
  margin-bottom: 0.5rem;
}

.nav-link {
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  transition: all 0.15s ease;
  color: #344767;
  font-weight: 600;
  font-size: 0.875rem;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.05);
  color: #5e72e4;
}

.nav-link.active {
  background: linear-gradient(122deg,#1568db,#05c2ed);
  color: white !important;
}

.nav-link.active i,
.nav-link.active span {
  color: white !important;
}

.card-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background: transparent;
}

.form-control {
  background: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  border-radius: 12px;
  padding: 12px 20px;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-control:focus {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
  outline: none;
  box-shadow: 
    inset 0 2px 4px rgba(0, 0, 0, 0.1),
    0 0 0 3px rgba(255, 255, 255, 0.1);
}

.form-label {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.text-sm {
  color: rgba(255, 255, 255, 0.7);
}

h6 {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 600;
  letter-spacing: 0.5px;
}

.nav.bg-white {
  background-color: transparent !important;
  box-shadow: none !important;
}

.nav.flex-column.bg-white.border-radius-lg {
  box-shadow: none !important;
  background: transparent !important;
}

/* Image upload styling */
.image-upload-container {
  width: 200px;
  height: 200px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(8px);
  border: 2px dashed rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;

  &:hover {
    border-color: rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.08);
  }

  .image-upload-input {
    display: none;
  }

  .image-upload-label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .upload-icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.7);
    
    i {
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }
  }
}

.profile-container {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  
  .preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/* Tilføj styling for Facebook knap */
.d-flex.justify-content-between {
  margin-top: 2rem;
  
  .argon-button {
    background: rgba(59, 89, 152, 0.1);
    backdrop-filter: blur(8px);
    border: 1px solid rgba(59, 89, 152, 0.2);
    padding: 0.75rem 1.5rem;
    border-radius: 12px;
    transition: all 0.3s ease;
    
    &:hover {
      background: rgba(59, 89, 152, 0.2);
      transform: translateY(-1px);
    }
    
    i {
      color: #4267B2;
    }
  }
}

/* Button styling */
:deep(.argon-button) {
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 8px 16px;
  color: white;
  font-weight: 500;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  transform: translateZ(0);
  will-change: transform, background-color;
  box-shadow: 
    0 2px 5px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(255, 255, 255, 0.05);
}

:deep(.argon-button:hover) {
  transform: translateY(-1px) translateZ(0);
  box-shadow: 
    0 4px 12px rgba(0, 0, 0, 0.15),
    0 0 0 1px rgba(255, 255, 255, 0.1);
}

/* Success button variant */
:deep(.argon-button.btn-success) {
  background: linear-gradient(
    135deg, 
    rgba(66, 186, 150, 0.15), 
    rgba(66, 186, 150, 0.25)
  );
  border-color: rgba(66, 186, 150, 0.3);
}

/* Info button variant */
:deep(.argon-button.btn-info) {
  background: linear-gradient(
    135deg, 
    rgba(33, 150, 243, 0.15), 
    rgba(33, 150, 243, 0.25)
  );
  border-color: rgba(33, 150, 243, 0.3);
}

/* Hashtag styling */
.hashtag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.hashtag-item {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 4px 12px;
  border-radius: 15px;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.9);
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.remove-hashtag {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  padding: 0;
  font-size: 1.2rem;
  line-height: 1;
  transition: color 0.2s ease;
}

.remove-hashtag:hover {
  color: rgba(255, 255, 255, 0.9);
}

/* Animation keyframes */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
}

/* Add animation to cards */
.card {
  animation: slideUp 0.3s cubic-bezier(0.2, 0, 0.2, 1);
}

/* Container spacing */
.container-fluid {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.card-body {
  padding: 2rem !important;
}

.form-group {
  margin-bottom: 1.5rem;
}

/* Profile specific styles */
.profile-container {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Design settings specific styles */
.design-settings {
  .settings-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 1.5rem;
  }

  .setting-item {
    background: rgba(255, 255, 255, 0.03);
    border-radius: 12px;
    padding: 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .setting-label {
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 1rem;
  }
}

/* Tilføj/opdater page-header styling */
.page-header {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: 50%;
  border-radius: 0;
  margin-bottom: 24px;
}

.mask {
  position: absolute;
  background-size: cover;
  background-position: center center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

.bg-gradient-success {
  background-image: linear-gradient(122deg,#1568db,#05c2ed);;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
}

.mt-n6 {
  margin-top: -4rem !important;
}

.avatar-xl {
  width: 65px !important;
  height: 65px !important;
}

.border-radius-lg {
  border-radius: 0.75rem;
}

/* Opdater card styling for header card */
.card.shadow-lg {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  
  .card-body {
    padding: 1rem 1.5rem !important;
  }
}

/* Tilpas avatar styling */
.avatar {
  overflow: hidden;
  
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

/* Tilpas navigation pills */
.nav-pills {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 0.75rem;
  padding: 0.5rem;
}

.nav-link {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: all 0.15s ease;
  
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  
  &.active {
    background: linear-gradient(122deg,#1568db,#05c2ed);
    color: white !important;
  }
}

/* Tilpas tilbage-knap */
.argon-button {
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Opdater navigation pills styling */
.nav-wrapper {
  background-color: var(--card-color) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 0.75rem;
  padding: 0.5rem;
}

.nav-pills {
  background-color: var(--card-color) !important;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.nav-item {
  margin: 0 0.25rem;
}

.custom-nav-link {
  padding: 0.5rem 1rem !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  color: #344767 !important;
  transition: all 0.2s ease !important;
  display: flex !important;
  align-items: center !important;
  white-space: nowrap !important;
  text-decoration: none !important;
  border-radius: 0.5rem !important;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: var(--primary-color) !important;
  }
  
  &.active-tab {
    background: linear-gradient(122deg,#1568db,#05c2ed) !important;
    color: #ffffff !important;
  }
}

.dropdown {
  position: relative;
  
  .dropdown-menu {
    background-color: var(--card-color);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin-top: 0.5rem;
    
    .dropdown-item {
      padding: 0.5rem 1rem;
      font-size: 0.875rem;
      color: #344767;
      transition: all 0.2s ease;
      border-radius: 0.375rem;
      
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        color: var(--primary-color);
      }
    }
  }
}

@media (max-width: 768px) {
  .custom-nav-link {
    padding: 0.5rem !important;
    
    span {
      display: none;
    }
    
    i {
      margin: 0;
      font-size: 1rem;
    }
  }
}

@media (max-width: 992px) {
  .nav-pills {
    min-width: auto;
    width: 100%;
  }
}

.custom-margin {
  margin-top: -4.5rem !important;
}
</style>
