<template>
  <div class="container-fluid">
    <!-- Navigation Tabs -->
    <div v-show="showNavTabs" class="row align-items-center" style="margin-top: -30px; margin-bottom: 20px;">
      <div class="col-lg-6 col-sm-12">
        <div class="nav-wrapper">
          <ul class="nav nav-pills nav-fill p-1" role="tablist" style="width: 50%;">
            <li class="nav-item">
              <a 
                role="tab" 
                class="nav-link mb-0 px-0 py-1" 
                :class="{ 'active': activeTab === 'car' }"
                @click="handleTabClick('car')"
              >
                Bilopslag
              </a>
            </li>
            <li class="nav-item">
              <a 
                role="tab" 
                class="nav-link mb-0 px-0 py-1" 
                :class="{ 'active': activeTab === 'wall' }"
                @click="handleTabClick('wall')"
              >
                Vægopslag
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Content Area -->
    <div class="content-area">
      <!-- Car Post Content -->
      <car-post v-if="activeTab === 'car'" />

      <!-- Wall Post Content -->
      <new-project v-if="activeTab === 'wall'" />
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import CarPost from './postCars.vue';
import NewProject from './NewProject.vue';

const activeTab = ref('car');

// Computed property til at styre synligheden af navigation tabs
const showNavTabs = computed(() => {
  return true; // Vi vil altid vise tabs i denne komponent
});

// Handler for tab clicks
const handleTabClick = (tab) => {
  activeTab.value = tab;
};
</script>

<style scoped>
.nav-wrapper {
  background: transparent !important;
}

.nav-pills {
  background: #212121 !important;
  border-radius: 0.5rem;
  padding: 3px !important;
  position: relative;
  overflow: hidden;
  max-height: 40px;
}

.nav-item {
  z-index: 1;
  position: relative;
}

.nav-link {
  cursor: pointer;
  color: #ffffff;
  font-weight: 500;
  transition: all 0.2s ease;
  padding: 5px 15px !important;
  font-size: 0.875rem;
  border-radius: 0.5rem;
  background: transparent;
  white-space: nowrap;
}

.nav-link.active {
  background: linear-gradient(310deg, #141727 0%, #3A416F 100%) !important;
  color: #ffffff !important;
  font-weight: 600;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

@media (max-width: 768px) {
  .nav-link {
    padding: 5px 10px !important;
    font-size: 0.75rem;
  }
}

.content-area {
  margin-top: 50px;
  padding: 20px;
}
</style> 