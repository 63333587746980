<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import moment from 'moment';
import 'moment/dist/locale/da';
import { 
  getPlaceholderStats, 
  sortPlaceholderCars, 
  getStatusClass, 
  formatPlaceholderDate 
} from '@/services/placeholderService';
import { getFirestore, doc, deleteDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Swal from 'sweetalert2';

const db = getFirestore();
const auth = getAuth();

const props = defineProps({
  event: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['close']);

// Beregn værdier fra event data
const children = props.event.extendedProps.children || [];
const date = formatPlaceholderDate(props.event.start);
const publishTime = props.event.extendedProps.time;
const platforms = [];
if (props.event.extendedProps.postFB) platforms.push('Facebook');
if (props.event.extendedProps.postInst) platforms.push('Instagram');

// Brug hjælpefunktioner fra service
const stats = computed(() => getPlaceholderStats(children));
const sortedCars = computed(() => sortPlaceholderCars(children));

// Funktion til at slette post
const deletePost = async () => {
  try {
    // Bekræft sletning
    const result = await Swal.fire({
      title: 'Er du sikker?',
      text: "Dette kan ikke fortrydes!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Ja, slet det!',
      cancelButtonText: 'Annuller'
    });

    if (result.isConfirmed) {
      // Få bruger ID fra event data eller brug den indloggede brugers ID
      const targetUserId = props.event.extendedProps?.data?.id;
      const currentUserId = auth.currentUser?.uid;

      if (!targetUserId || !currentUserId) {
        throw new Error('Mangler bruger ID');
      }

      // Log mere detaljeret information til debugging
      console.log('Event objekt:', props.event);
      console.log('Event extendedProps:', props.event.extendedProps);
      
      // Få det korrekte dokument ID fra event objektet
      const docId = props.event._def.publicId;
      
      console.log('Forsøger at slette placeholder:', {
        targetUserId,
        currentUserId,
        docId,
        eventData: props.event.extendedProps?.data
      });

      if (!docId) {
        throw new Error('Kunne ikke finde dokument ID');
      }

      // Slet dokumentet fra den korrekte collection sti - brug targetUserId
      const docRef = doc(db, 'users', targetUserId, 'posts', docId);
      console.log('Sletter dokument fra:', docRef.path);
      await deleteDoc(docRef);
      
      await Swal.fire({
        icon: 'success',
        title: 'Slettet!',
        text: 'Opslaget er blevet slettet.',
        timer: 2000,
        showConfirmButton: false
      });

      // Luk modal
      emit('close');
    }
  } catch (error) {
    console.error('Fejl ved sletning af placeholder:', error);
    Swal.fire({
      icon: 'error',
      title: 'Fejl',
      text: 'Kunne ikke slette opslaget: ' + error.message
    });
  }
};
</script>

<template>
  <div class="placeholder-details">
    <div class="publish-info">
      <div class="header-actions">
        <button @click="deletePost" class="delete-btn">
          <i class="fas fa-trash"></i> Slet
        </button>
      </div>
      <p>Planlagt: {{ date }} kl. {{ publishTime }}</p>
      <p>Antal biler: {{ stats.totalCars }}</p>
      <p v-if="platforms.length">Platforme: {{ platforms.join(', ') }}</p>
    </div>
    <div v-for="car in sortedCars" :key="car.caseId" class="car-item">
      <div class="car-header">
        <h4>{{ car.headline || 'Lagerbil' }}</h4>
        <span :class="['days-badge', getStatusClass(car.daysForSale)]">
          {{ car.daysForSale || 0 }} dage
        </span>
      </div>
      <div class="car-details">
        <p v-if="car.originalCreatedDate">
          Oprettet: {{ formatPlaceholderDate(car.originalCreatedDate) }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.placeholder-details {
  padding: 20px;
}

.publish-info {
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 16px;
}

.publish-info p {
  margin: 4px 0;
  color: #2c3e50;
  font-size: 14px;
}

.stats {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #eee;
}

.stat-item {
  text-align: center;
}

.stat-label {
  display: block;
  font-size: 12px;
  color: #666;
}

.stat-value {
  font-size: 16px;
  font-weight: bold;
  color: #2c3e50;
}

.car-item {
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.car-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.car-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.car-header h4 {
  margin: 0;
  color: #2c3e50;
  font-size: 16px;
  font-weight: 600;
}

.days-badge {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
}

.car-details {
  padding: 8px 0;
}

.car-details p {
  margin: 4px 0;
  color: #666;
  font-size: 14px;
}

.car-actions {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #eee;
}

.car-link {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #007bff;
  text-decoration: none;
  border-radius: 4px;
  font-size: 14px;
  transition: all 0.2s ease;
}

.car-link:hover {
  background: #0056b3;
  transform: translateY(-1px);
}

.car-link i {
  font-size: 12px;
}

/* Tilføj placeholder group styling */
:deep(.placeholder-group) {
  background: linear-gradient(45deg, #6c6c6c, #6c6c6c) !important;
  border: none !important;
  border-radius: 4px !important;
  margin-bottom: 2px !important;
  cursor: pointer !important;
  opacity: 0.5 !important;
}

:deep(.placeholder-group .fc-event-title) {
  font-weight: bold !important;
  color: white !important;
  padding: 4px !important;
}

:deep(.placeholder-group .fc-event-time) {
  padding: 4px !important;
  color: rgba(255, 255, 255, 0.9) !important;
}

:deep(.placeholder-group:hover) {
  filter: brightness(1.1);
  transform: translateY(-1px);
  transition: all 0.2s ease;
}

.header-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}

.delete-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.2s;
}

.delete-btn:hover {
  background-color: #c82333;
}

.delete-btn i {
  font-size: 14px;
}
</style> 