<template>
  <section class="cloneable">
    <div ref="wrapper" data-tabs="wrapper" class="tab-layout">
      <div class="tab-layout-col">
        <div ref="visualWrap" data-tabs="visual-wrap" class="tab-visual-wrap">
          <div data-tabs="visual-item" class="tab-visual-item active">
            <img src="https://firebasestorage.googleapis.com/v0/b/toft-d4f39.appspot.com/o/Autopilot.png?alt=media&token=facec999-3ea7-419e-8632-12021430ce2f" loading="lazy" alt="Automatisering visualisering med computer og grafer" class="tab-image">
          </div>
          <div data-tabs="visual-item" class="tab-visual-item">
            <img src="https://firebasestorage.googleapis.com/v0/b/toft-d4f39.appspot.com/o/posts.png?alt=media&token=0a6d481f-0d83-4f04-a81b-7d22462a05ec" loading="lazy" alt="Kontrol dashboard med datavisualisering" class="tab-image">
          </div>
          <div data-tabs="visual-item" class="tab-visual-item">
            <img src="https://firebasestorage.googleapis.com/v0/b/toft-d4f39.appspot.com/o/Print.png?alt=media&token=bc22383d-aa3e-46c1-8ebc-9fc804445d57" loading="lazy" alt="Analyse og statistik visualisering" class="tab-image">
          </div>
        </div>
      </div>
      <div class="tab-layout-col">
        <div class="tab-layout-container">
          <div class="tab-container">
            <div class="tab-container-top">
              <h1 v-auto-translate class="tab-layout-heading">Et system der gør det nemt for dig</h1>
              <div ref="nav" data-flip-button="wrap" data-tabs="nav" class="filter-bar">
                <button data-tabs="button" data-flip-button="button" class="filter-button active" @click="switchTab(0)">
                  <div v-auto-translate class="filter-button__p">Autopilot</div>
                  <div data-flip-button="bg" class="tab-button__bg"></div>
                </button>
                <button data-tabs="button" data-flip-button="button" class="filter-button" @click="switchTab(1)">
                  <div v-auto-translate class="filter-button__p">Opslag</div>
                </button>
                <button data-tabs="button" data-flip-button="button" class="filter-button" @click="switchTab(2)">
                  <div v-auto-translate class="filter-button__p">Printmateriale</div>
                </button>
              </div>
            </div>
            <div class="tab-container-bottom">
              <div ref="contentWrap" data-tabs="content-wrap" class="tab-content-wrap">
                <div data-tabs="content-item" class="tab-content-item active">
                  <h2 v-auto-translate data-tabs-fade="" class="tab-content__heading">Autopilot</h2>
                  <p v-auto-translate data-tabs-fade="" class="content-p opacity--80">Opsæt en autopilot på din lagerliste med 4-klik. Når den er opsat holder Auto family øje med alle ændringer og opretter dine opslag automatisk. alt fra Nyhed, prisændringer, til alle dine biler i lagerlisten.</p>
                </div>
                <div data-tabs="content-item" class="tab-content-item">
                  <h2 v-auto-translate data-tabs-fade="" class="tab-content__heading">Opslag</h2>
                  <p v-auto-translate data-tabs-fade="" class="content-p opacity--80">Ønsker du kontrol over dine opslag? Auto Family giver dig muligheden for vælge specifikke biler fra din lagerliste, vælge unikke designs og vi laver dine opslag for dig.</p>
                </div>
                <div data-tabs="content-item" class="tab-content-item">
                  <h2 v-auto-translate data-tabs-fade="" class="tab-content__heading">Printmateriale</h2>
                  <p v-auto-translate data-tabs-fade="" class="content-p opacity--80">Med vores live editor, kan du redigere dine printmateriale live, og se ændringerne i real-time. før du printer dem ud.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { Flip } from 'gsap/Flip';

gsap.registerPlugin(CustomEase, Flip);

const wrapper = ref(null);
const nav = ref(null);
const contentWrap = ref(null);
const visualWrap = ref(null);

let current = 0;
let previous = 0;

const switchTab = (index) => {
  if (!wrapper.value || !nav.value || !contentWrap.value || !visualWrap.value) return;

  previous = current;
  current = index;

  const buttons = [...nav.value.querySelectorAll('[data-tabs="button"]')];
  const contentItems = [...contentWrap.value.querySelectorAll('[data-tabs="content-item"]')];
  const visualItems = [...visualWrap.value.querySelectorAll('[data-tabs="visual-item"]')];

  buttons[previous].classList.remove('active');
  buttons[current].classList.add('active');

  // Fade ud tidligere content
  gsap.to(contentItems[previous], {
    opacity: 0,
    duration: 0.3,
    ease: 'power2.in',
    onComplete: () => {
      contentItems[previous].classList.remove('active');
      // Fade ind nyt content
      contentItems[current].classList.add('active');
      gsap.fromTo(contentItems[current],
        { opacity: 0, y: 20 },
        { 
          opacity: 1, 
          y: 0,
          duration: 0.3,
          ease: 'power2.out'
        }
      );
    }
  });

  // Håndter visuelle elementer
  visualItems[previous].classList.remove('active');
  visualItems[current].classList.add('active');
};

const initFlipButtons = () => {
  if (!nav.value) return;

  const buttons = nav.value.querySelectorAll('[data-flip-button="button"]');
  const bg = nav.value.querySelector('[data-flip-button="bg"]');
    
  if (!bg) return;

  const state = Flip.getState(bg);
  buttons[0].appendChild(bg);
  Flip.from(state, { duration: 0.5, ease: 'power2.inOut' });

  buttons.forEach(button => {
    button.addEventListener('click', () => {
      const state = Flip.getState(bg);
      button.appendChild(bg);
      Flip.from(state, { duration: 0.5, ease: 'power2.inOut' });
    });
  });
};

onMounted(() => {
  initFlipButtons();
});
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.cloneable {
  padding: 8rem 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  margin-top: 0;
  margin-bottom: 4rem;
  background: #16181b;
}

.tab-container {
  grid-column-gap: 3em;
  grid-row-gap: 3em;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 80%;
  padding-right: 2.5em;
  display: flex;
  position: relative;
  margin-top: -2rem;
}

.tab-layout-container {
  width: 100%;
  max-width: 42em;
  height: 100%;
  margin: 0 auto;
  padding-top: 1em;
  padding-bottom: 2em;
}

.tab-container-top {
  grid-column-gap: 2em;
  grid-row-gap: 1em;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  width: 100%;
  position: relative;
  z-index: 2;
}

.tab-layout-col {
  width: 50%;
}

.tab-container-bottom {
  position: relative;
  width: 100%;
  margin-top: 0;
  min-height: 250px;
}

.tab-content-wrap {
  width: 100%;
  min-width: 24em;
  position: relative;
  min-height: 200px;
  margin-bottom: 0;
  height: auto;
  overflow: visible;
}

.content-p {
  margin: 0;
  font-size: 1.25em;
  line-height: 1.6;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  background: linear-gradient(0deg,#86868b 0%, #bdc2c9 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 1em;
  margin-bottom: 1rem;
}

.tab-button__bg {
  z-index: 0;
  background-color: #efeeec0f;
  border: 1px solid #efeeec14;
  border-radius: .25em;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0%;
}

.tab-content-item {
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  transition: opacity 0.3s ease;
  padding: 0 0 2rem 0;
  min-height: 150px;
}

.tab-content-item.active {
  visibility: visible;
  opacity: 1;
  position: relative;
}

.tab-layout {
  z-index: 1;
  grid-row-gap: 3em;
  flex-flow: wrap;
  width: 100%;
  min-height: 37em;
  display: flex;
  position: relative;
}

.filter-bar {
  background-color: #efeeec0f;
  border: 1px solid #efeeec14;
  border-radius: .5em;
  padding: .5em;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 0;
}

.filter-button {
  background-color: transparent;
  border: 1px solid transparent;
  padding: 1.125em 1.5em;
  transition: border-color .2s;
  position: relative;
  cursor: pointer;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
}

.filter-button.active {
  border-color: rgba(239, 238, 236, 0.3);
  border-radius: .25em;
}

.filter-button__p {
  z-index: 1;
  font-size: 1.125em;
  position: relative;
  font-family: Poppins, sans-serif;
  background: linear-gradient(90deg, #fff, #e7dfd6);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
  text-align: center;
  padding: 0 0.5rem;
}

.tab-visual-wrap {
  border-radius: .5em;
  width: 100%;
  height: 42em;
  max-height: 80vh;
  position: relative;
  overflow: hidden;
}

.tab-visual-item {
  visibility: hidden;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.tab-visual-item.active {
  visibility: visible;
}

.tab-image {
  object-fit: cover;
  border-radius: .5em;
  width: 100%;
  max-width: none;
  height: 100%;
}

.tab-content__heading {
  letter-spacing: -.02em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.75em;
  font-weight: 500;
  line-height: 1.2 !important;
  font-family: Poppins, sans-serif;
  background: linear-gradient(90deg, #fff, #e7dfd6);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tab-layout-heading {
  margin: 0 0 1rem 0;
  font-size: 3.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #fff;
  font-family: Poppins, sans-serif;
  background: linear-gradient(90deg, #fff, #e7dfd6);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 0;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .cloneable {
    padding: 6rem 2rem;
  }
}

@media (max-width: 992px) {
  .cloneable {
    padding: 5rem 2rem;
  }
}

@media (max-width: 768px) {
  .cloneable {
    padding: 4rem 1.5rem;
  }

  .filter-button {
    min-width: 100px;
    padding: 1em 1.25em;
  }

  .filter-button__p {
    font-size: 1em;
  }

  .tab-layout-heading {
    font-size: 2.5rem;
  }

  .content-p {
    font-size: 1.1em;
  }
}

@media (max-width: 480px) {
  .cloneable {
    padding: 3rem 1rem;
  }

  .filter-button {
    min-width: 80px;
    padding: 0.875em 1em;
  }

  .filter-button__p {
    font-size: 0.9em;
  }

  .tab-layout-heading {
    font-size: 2rem;
  }

  .content-p {
    font-size: 1em;
  }
}

@media (max-height: 600px) {
  .cloneable {
    padding: 3rem 1rem;
    min-height: auto;
  }

  .tab-visual-wrap {
    height: 24em;
  }

  .tab-layout {
    gap: 2rem;
  }
}
</style> 