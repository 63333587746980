import axios from 'axios';

const API_URL = "https://us-central1-toft-d4f39.cloudfunctions.net/generateAIText";

export const generateAIText = async (messages, options = {}) => {
  try {
    const response = await axios.post(API_URL, {
      messages,
      options,
      model: "claude-3-sonnet-20240229",
      maxTokens: 1000
    }, {
      headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    if (response.data && response.data.text) {
      return { correctedText: response.data.text };
    }
    
    throw new Error('Uventet svarformat fra AI servicen');
  } catch (error) {
    console.error('Fejl under generering af AI tekst:', error);
    throw error;
  }
}; 