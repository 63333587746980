import { getFirestore, doc, getDoc, collection, getDocs } from 'firebase/firestore';

const db = getFirestore();

/**
 * Henter et specifikt billogo fra Firebase
 * @param {string} brand - Bilmærket
 * @returns {Promise<Object|null>} Logo data eller null hvis ikke fundet
 */
export const getCarLogo = async (brand) => {
  try {
    const slug = brand.toLowerCase().trim().replace(/[\s-]+/g, '-');
    const logoDoc = await getDoc(doc(db, 'carlogos', slug));
    
    if (logoDoc.exists()) {
      return logoDoc.data();
    }
    return null;
  } catch (error) {
    console.error('Fejl ved hentning af logo:', error);
    return null;
  }
};

/**
 * Henter alle billogoer fra Firebase
 * @returns {Promise<Array>} Array af logo data
 */
export const getAllCarLogos = async () => {
  try {
    const logosSnapshot = await getDocs(collection(db, 'carlogos'));
    return logosSnapshot.docs.map(doc => doc.data());
  } catch (error) {
    console.error('Fejl ved hentning af alle logoer:', error);
    return [];
  }
};

/**
 * Henter URL for et billogo med fallback
 * @param {string} brand - Bilmærket
 * @param {string} [fallbackUrl=''] - Fallback URL hvis logo ikke findes
 * @returns {Promise<string>} Logo URL eller fallback URL
 */
export const getCarLogoUrl = async (brand, fallbackUrl = '') => {
  try {
    const logoData = await getCarLogo(brand);
    return logoData?.logoUrl || fallbackUrl;
  } catch (error) {
    console.error('Fejl ved hentning af logo URL:', error);
    return fallbackUrl;
  }
}; 