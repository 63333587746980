import { introSentences, midSectionSentences, outroSentences, linkSentences } from './postSentences';

const emojis = {
  default: ["⭐️", "🚘", "✨", "🔥", "🤩", "💥"],
  electric: ["🔋", "⚡️", "🌱", "🚗💨", "🔌"],
  diesel: ["🛢️", "💨", "🔧", "🚛"],
  petrol: ["⛽️", "🏎️", "🔥", "🚗"]
};

const themes = ['Nyhed', 'Ny bil på lager', 'Tilbud', 'Prisjustering', 'Dagens bil',
                'Ugens bil', 'Netop solgt', 'Eftertragtet model', 'Skarp pris'];

const udstyrsEmojis = ["☑️", "🔸", "★", "⭐️", "✔️", "⚡️"];

const udstyrsIntros = [
  "Bilen har udstyr som:",
  "Se noget af det fede udstyr:",
  "Tjek dette lækre udstyr:",
  "Bilen er udstyret med bl.a.:",
  "Nogle af de fede features:",
  "Udpluk af bilens udstyr:",
  "Bilen byder på følgende:",
  "Få et kig på noget af udstyret:",
  "Udstyrslisten inkluderer:",
  "Bilen er spækket med godt gear, f.eks.:"
];

const udstyrsOutros = [
  "Samt MEGET mere! 🤩",
  "Og det er bare begyndelsen! 😍",
  "Plus en masse andet lækkert udstyr! 🚀",
  "Og listen fortsætter! 🔥",
  "Dette er kun toppen af isbjerget! ❄️",
  "Og der er meget mere at opdage! 🕵️",
  "Resten må du selv komme og se! 👀",
  "Og det er bare nogle af højdepunkterne! 🏆",
  "Der er meget mere at fortælle! 📚",
  "Kom og oplev resten selv! 🎉"
];

function getRandomItem(array) {
  return array[Math.floor(Math.random() * array.length)];
}

export function generateSocialMediaPost(carData, platform = 'facebook', theme = 'Nyhed', type = 'singlePost') {
  if (type === 'karruselPost') {
    return generateKarruselPost(carData, platform, theme);
  }
  return generateSinglePost(carData, platform, theme);
}

function generateSinglePost(carData, platform, theme) {
  const carFields = carData.fields || {};
  const fuelType = (carFields.Brændstoftype || '').toLowerCase();
  
  let selectedEmojis;
  if (fuelType.includes('el')) {
    selectedEmojis = emojis.electric;
  } else if (fuelType.includes('diesel') || fuelType.includes('bluetec')) {
    selectedEmojis = emojis.diesel;
  } else if (fuelType.includes('benzin') || fuelType.includes('petrol')) {
    selectedEmojis = emojis.petrol;
  } else {
    selectedEmojis = emojis.default;
  }

  const standardizedTheme = themes.includes(theme) ? theme : 'Nyhed';

  const maerke = carFields.Mærke || 'ukendt mærke';
  const model = carFields.Model || '';
  const biltype = carFields.Biltype || 'bil';
  const farve = carFields.Farve || '';
  const aar = carFields.Årgang || '';
  const pris = carData.price || 'ukendt pris';
  const url = carData.url || '';

  const intro = getRandomItem(introSentences[standardizedTheme])
    .replace('{maerke}', maerke)
    .replace('{model}', model)
    .replace('{biltype}', biltype)
    .replace('{farve}', farve)
    .replace('{aar}', aar)
    .replace('{emoji}', getRandomItem(selectedEmojis));

  let midSection;
  if (standardizedTheme !== 'Netop solgt') {
    const udstyrsliste = carFields.Udstyr || '';
    const udstyrItems = udstyrsliste.split(/[,.]/).map(item => item.trim()).filter(Boolean);
    
    if (udstyrItems.length > 0) {
      const selectedUdstyr = udstyrItems.sort(() => 0.5 - Math.random()).slice(0, 5);
      const udstyrsEmoji = getRandomItem(udstyrsEmojis);
      const intro = getRandomItem(udstyrsIntros);
      const outro = getRandomItem(udstyrsOutros);
      
      midSection = `${intro}\n\n`;
      midSection += selectedUdstyr.map(item => `${udstyrsEmoji} ${item}`).join('\n');
      midSection += `\n\n${outro}\n`;
    } else {
      midSection = "Kontakt os for mere information om udstyret!\n";
    }
  } else {
    midSection = getRandomItem(midSectionSentences[standardizedTheme]);
  }

  const outro = getRandomItem(outroSentences[standardizedTheme]);

  let postText = `${intro}\n\n${midSection}\n${outro}\n\n`;

  if (platform === 'facebook' && url) {
    const linkSentence = getRandomItem(linkSentences).replace('{url}', url);
    postText += `${linkSentence}\n\n`;
  }

  // Tilføj hashtags baseret på brændstoftype
  const hashtags = [];
  if (maerke) hashtags.push(`#${maerke.replace(/\s+/g, '')}`);
  if (model) hashtags.push(`#${model.replace(/\s+/g, '')}`);
  postText += hashtags.join(' ');

  return { text: postText };
}

function generateKarruselPost(carsData, platform, theme) {
  const antal = carsData.length;
  const selectedEmojis = emojis.default;

  const intro = getRandomItem(karruselIntroSentences[theme])
    .replace('{antal}', antal)
    .replace('{emoji}', getRandomItem(selectedEmojis));

  const midSection = getRandomItem(karruselMidSectionSentences[theme]);
  const outro = getRandomItem(karruselOutroSentences[theme]);

  // Generer kort oversigt over bilerne
  const carsList = carsData.map(car => {
    const maerke = car.fields?.Mærke || 'ukendt mærke';
    const model = car.fields?.Model || '';
    const pris = car.price || 'Ring for pris';
    return `${getRandomItem(udstyrsEmojis)} ${maerke} ${model} - ${pris}`;
  }).join('\n');

  let postText = `${intro}\n\n${carsList}\n\n${midSection}\n\n${outro}`;

  if (platform === 'facebook') {
    postText += '\n\nSe alle bilerne på vores hjemmeside 🌐';
  }

  // Tilføj relevante hashtags
  const hashtags = ['#nybil', '#biler', '#kvalitet'];
  postText += `\n\n${hashtags.join(' ')}`;

  return { text: postText };
}