<template>
  <slot v-if="isSuperAdmin"></slot>
  
  <div v-else :class="[containerClasses, themeClasses]" :style="rootStyles">
    <slot></slot>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { whiteLabelService } from '@/services/whiteLabelService';

const store = useStore();
const auth = getAuth();
const db = getFirestore();
const settings = ref(null);
const adminId = ref(null);
const initialized = ref(false);
const isLoading = ref(true);
const isSuperAdmin = ref(false);

// Tilføj defaultTheme konstant i starten af filen
const defaultTheme = {
  backgroundColor: '#16181b',
  sidenavColor: 'linear-gradient(122deg, #1568db, #05c2ed)',
  cardColor: '#202124',
  navWrapperColor: '#2c2c3b'
};

// Beregn styles baseret på indstillinger
const rootStyles = computed(() => {
  const theme = settings.value?.theme || defaultTheme;
  
  // Tilføj en check for at sikre at vi ikke overskriver systemets standard farver for superadmin
  if (isSuperAdmin.value) {
    return {
      '--background-color': defaultTheme.backgroundColor,
      '--sidenav-color': defaultTheme.sidenavColor,
      '--card-color': defaultTheme.cardColor,
      '--nav-wrapper-color': defaultTheme.navWrapperColor,
      '--company-name': '"AutoFamily"',
      '--company-website': '"autofamily.dk"'
    };
  }
  
  return {
    '--background-color': theme.backgroundColor || defaultTheme.backgroundColor,
    '--sidenav-color': theme.sidenavColor || defaultTheme.sidenavColor,
    '--card-color': theme.cardColor || defaultTheme.cardColor,
    '--nav-wrapper-color': theme.navWrapperColor || defaultTheme.navWrapperColor,
    '--company-name': `"${settings.value?.companyInfo?.name || ''}"`,
    '--company-website': `"${settings.value?.companyInfo?.website || ''}"`,
  };
});

// Beregn layout klasser
const containerClasses = computed(() => ({
  'layout-right-sidebar': settings.value?.layout?.sidebarPosition === 'right',
  [`header-${settings.value?.layout?.headerStyle || 'default'}`]: true,
}));

// Hent brugerens admin ID
const getUserAdminId = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return userData.adminId; // ID'et på den admin som brugeren tilhører
    }
    return null;
  } catch (error) {
    console.error('Fejl ved hentning af admin ID:', error);
    return null;
  }
};

// Tjek om brugeren er SuperAdmin
const checkUserRole = async (user) => {
  if (!user) return false;
  
  try {
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    if (!userDoc.exists()) return false;
    
    const userData = userDoc.data();
    isSuperAdmin.value = userData.role === 1;
    return userData.role === 1;
  } catch (error) {
    console.error('Fejl ved tjek af brugerrolle:', error);
    return false;
  }
};

// Tilføj ref til unsubscribe funktion
const unsubscribeWhiteLabel = ref(null);

// Modificer applyWhiteLabel til kun at køre hvis vi har en authenticated user
const applyWhiteLabel = async () => {
  const user = auth.currentUser;
  if (!user) {
    console.log('Ingen bruger - springer white label over');
    isLoading.value = false;
    return;
  }

  try {
    isLoading.value = true;
    
    // Reducer ventetid og øg antal forsøg
    let attempts = 0;
    while (!store.state.user && attempts < 40) { // 2 sekunder ventetid
      await new Promise(resolve => setTimeout(resolve, 50));
      attempts++;
    }
    
    if (!store.state.user) {
      console.error('Kunne ikke få brugerdata fra store');
      return;
    }
    
    // Tjek først om det er SuperAdmin
    isSuperAdmin.value = await checkUserRole(user);
    
    if (isSuperAdmin.value) {
      console.log('SuperAdmin detekteret - bruger system defaults');
      settings.value = {
        theme: defaultTheme,
        browserSettings: {
          title: 'AutoFamily',
          favicon: null
        },
        companyInfo: {
          name: 'AutoFamily',
          website: 'autofamily.dk'
        }
      };
      isLoading.value = false;
      return;
    }

    const userDoc = await getDoc(doc(db, 'users', user.uid));
    if (!userDoc.exists()) return;

    const userData = userDoc.data();
    console.log('User data:', userData);
    
    // Cleanup eksisterende listener hvis den findes
    if (unsubscribeWhiteLabel.value) {
      unsubscribeWhiteLabel.value();
    }

    // Funktion til at opsætte real-time listener på admin dokumentet
    const listenToWhiteLabel = async (adminId) => {
      console.log('Setting up white label listener for admin:', adminId);
      const adminDoc = doc(db, 'users', adminId);
      
      try {
        // Hent først de aktuelle indstillinger
        const adminSnapshot = await getDoc(adminDoc);
        if (adminSnapshot.exists()) {
          const adminData = adminSnapshot.data();
          console.log('Initial admin settings:', adminData.adminSettings?.whiteLabel);
          
          settings.value = {
            theme: adminData.adminSettings?.whiteLabel?.theme || defaultTheme,
            logo: adminData.adminSettings?.whiteLabel?.logo,
            headerImage: adminData.adminSettings?.whiteLabel?.headerImage,
            companyInfo: adminData.adminSettings?.whiteLabel?.companyInfo || {},
            layout: adminData.adminSettings?.whiteLabel?.layout || {}
          };
        }

        // Opsæt derefter real-time listener
        unsubscribeWhiteLabel.value = onSnapshot(adminDoc, (snapshot) => {
          if (snapshot.exists()) {
            const adminData = snapshot.data();
            console.log('White label ændring detekteret:', adminData.adminSettings?.whiteLabel);
            
            settings.value = {
              theme: adminData.adminSettings?.whiteLabel?.theme || defaultTheme,
              logo: adminData.adminSettings?.whiteLabel?.logo,
              headerImage: adminData.adminSettings?.whiteLabel?.headerImage,
              companyInfo: adminData.adminSettings?.whiteLabel?.companyInfo || {},
              layout: adminData.adminSettings?.whiteLabel?.layout || {}
            };

            updateUIElements();
          }
        }, (error) => {
          console.error('Fejl ved lytning til white label ændringer:', error);
        });
      } catch (error) {
        console.error('Fejl ved initial hentning af admin settings:', error);
      }
    };

    // Bestem hvilken admin's white label der skal bruges
    if (userData.role === 2) {
      // Hvis brugeren er admin, brug deres egne indstillinger
      await listenToWhiteLabel(user.uid);
    } else if (userData.role === 3 && userData.createdBy) {
      // Hvis brugeren er normal bruger, brug deres admin's indstillinger
      console.log('Normal bruger, bruger admin settings fra:', userData.createdBy);
      await listenToWhiteLabel(userData.createdBy);
    } else {
      console.log('Kunne ikke bestemme admin ID, bruger standard tema');
      settings.value = {
        theme: defaultTheme
      };
    }

  } catch (error) {
    console.error('Fejl ved anvendelse af white label:', error);
    settings.value = {
      theme: defaultTheme
    };
  } finally {
    isLoading.value = false;
  }
};

// Tilføj onUnmounted cleanup
onUnmounted(() => {
  if (unsubscribeWhiteLabel.value) {
    unsubscribeWhiteLabel.value();
  }
});

// Opdater updateUIElements til at være mere effektiv
const updateUIElements = async () => {
  if (!settings.value) return;
  
  // Brug requestAnimationFrame for bedre performance
  requestAnimationFrame(() => {
    // Opdater tema farver
    if (settings.value.theme) {
      const theme = settings.value.theme;
      const root = document.documentElement;
      
      root.style.setProperty('--background-color', theme.backgroundColor);
      root.style.setProperty('--sidenav-color', theme.sidenavColor);
      root.style.setProperty('--card-color', theme.cardColor);
      root.style.setProperty('--nav-wrapper-color', theme.navWrapperColor);
    }

    // Opdater browser titel og favicon kun hvis de er ændret
    if (settings.value.browserSettings?.title && document.title !== settings.value.browserSettings.title) {
      document.title = settings.value.browserSettings.title;
    }

    const favicon = document.querySelector('link[rel="icon"]');
    const faviconUrl = settings.value.browserSettings?.favicon || null;
    
    if (faviconUrl && (!favicon || favicon.href !== faviconUrl)) {
      if (!favicon) {
        const newFavicon = document.createElement('link');
        newFavicon.rel = 'icon';
        newFavicon.type = 'image/png';
        newFavicon.href = faviconUrl;
        document.head.appendChild(newFavicon);
      } else {
        favicon.href = faviconUrl;
      }
    }
  });
};

// Kun initialiser når komponenten mountes og vi har en authenticated user
onMounted(async () => {
  console.log('WhiteLabelProvider mounted');
  if (auth.currentUser) {
    await applyWhiteLabel();
  }
});

// Fjern den immediate watch på auth.currentUser, da vi nu håndterer det gennem App.vue
watch(() => auth.currentUser, async (newUser) => {
  if (newUser) {
    console.log('Auth ændret, kalder applyWhiteLabel');
    await new Promise(resolve => setTimeout(resolve, 100)); // Lille forsinkelse for at sikre store er opdateret
    await applyWhiteLabel();
    initialized.value = true;
  }
}, { immediate: true });

// Opdater watch på settings til at være mere effektiv
watch(() => settings.value?.theme, (newTheme, oldTheme) => {
  if (newTheme && JSON.stringify(newTheme) !== JSON.stringify(oldTheme)) {
    console.log('Theme changed:', newTheme);
    // Kør updateThemeColors med en lille forsinkelse for at sikre DOM er klar
    setTimeout(() => {
      updateThemeColors(newTheme);
    }, 0);
    
    // Opdater også via requestAnimationFrame for at sikre smooth transition
    requestAnimationFrame(() => {
      updateThemeColors(newTheme);
    });
  }
}, { deep: true, immediate: true });

// Opdater updateThemeColors funktion
const updateThemeColors = (theme) => {
  console.log('Updating theme colors:', theme);
  if (!theme || isSuperAdmin.value) return;
  
  const root = document.documentElement;
  
  // Opdater CSS variabler
  root.style.setProperty('--background-color', theme.backgroundColor);
  root.style.setProperty('--sidenav-color', theme.sidenavColor);
  root.style.setProperty('--card-color', theme.cardColor);
  root.style.setProperty('--nav-wrapper-color', theme.navWrapperColor);
  
  // Opdater også direkte på body og main-content for at sikre det virker
  document.body.style.backgroundColor = theme.backgroundColor;
  const mainContent = document.querySelector('.main-content');
  if (mainContent) {
    mainContent.style.backgroundColor = theme.backgroundColor;
  }
  const app = document.querySelector('#app');
  if (app) {
    app.style.backgroundColor = theme.backgroundColor;
  }
};
</script>
<style>
:root {
  --primary-color: #1568db;
  --secondary-color: #05c2ed;
  --accent-color: #2dce89;
}
/* Layout classes */
.layout-right-sidebar .sidebar {
  right: 0;
  left: auto;
}

/* Header styles */
.header-minimal {
  padding: 0.5rem;
}

.header-modern {
  backdrop-filter: blur(10px);
}

/* Apply branding */
.navbar-brand img {
  max-height: 40px;
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: var(--primary-color);
}

.btn-secondary {
  background-color: var(--secondary-color);
}

.accent-color {
  color: var(--accent-color);
}

/* Company info */
.company-name::before {
  content: var(--company-name);
}

.company-website::before {
  content: var(--company-website);
}

/* Opdater eksisterende komponenter til at bruge variablerne */
.navbar-main {
  background-color: var(--primary-color) !important;
}

.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.btn-secondary {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.bg-gradient-primary {
  background-image: linear-gradient(122deg, var(--primary-color) var(--accent-color) 100%) !important;
}

/* Opdater de eksisterende styles til at bruge de nye variabler */
:root {
  --background-color: #16181b;
  --sidenav-color: #4b4b4b;
  --card-color: #202124;
  --nav-wrapper-color: #2c2c3b;
}

/* Tilføj nye styles der bruger variablerne */
body {
  background-color: var(--background-color) !important;
  transition: background-color 0.3s ease;
}

.main-content {
  background-color: var(--background-color) !important;
}

#app {
  background-color: var(--background-color) !important;
}

.sidenav {
  background-color: var(--sidenav-color) !important;
  transition: background-color 0.3s ease;
}

.card {
  background-color: var(--card-color) !important;
  transition: background-color 0.3s ease;
}

/* Tilføj transitions til alle elementer der skal animeres */
.card,
.sidenav,
body,
.navbar,
.main-content,
#app {
  transition: all 0.3s ease !important;
}

.loading-state {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
</style>