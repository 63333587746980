<script setup>
import { computed, ref, onMounted, onUnmounted, onBeforeUnmount, nextTick, watch, provide } from "vue";
import { useStore } from "vuex";
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '@/firebase';
import { useRouter } from 'vue-router';
import { useOnlineStatus } from '@/composables/useOnlineStatus';
import { doc, updateDoc, collection, query, where, onSnapshot, getDocs } from 'firebase/firestore';
import { db } from '@/firebase';

import Configurator from "@/examples/Configurator.vue";
import AppFooter from "@/examples/Footer.vue";
import WhiteLabelProvider from "@/components/WhiteLabelProvider.vue";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import SignIn from '@/views/auth/signin.vue';

const router = useRouter();
const store = useStore();

// Auth state
const authReady = ref(false);

// Layout computed properties
const layout = computed(() => store.state.layout);
const showFooter = computed(() => store.state.showFooter);
const showConfig = computed(() => store.state.showConfig);
const hideConfigButton = computed(() => store.state.hideConfigButton);

// Watch authReady changes
watch(authReady, (newValue) => {
  console.log('Auth ready state changed:', newValue);
});

// Global search state
const globalSearchQuery = ref("");
const showSearchResults = ref(false);
const users = ref([]);
const searchTimeout = ref(null);

// Current user computed property
const currentUser = computed(() => store.state.user);

// Filtered users based on search query
const filteredUsers = computed(() => {
  if (!globalSearchQuery.value) return [];
  
  const searchTerm = globalSearchQuery.value.toLowerCase();
  return users.value
    .filter(user => 
      user.role === 3 && // Kun vis almindelige brugere
      (user.name?.toLowerCase().includes(searchTerm) ||
       user.email?.toLowerCase().includes(searchTerm) ||
       user.company?.toLowerCase().includes(searchTerm))
    )
    .slice(0, 5); // Begræns til 5 resultater
});

// Watch search query med debounce
watch(globalSearchQuery, (newValue) => {
  if (searchTimeout.value) {
    clearTimeout(searchTimeout.value);
  }
  
  if (newValue) {
    searchTimeout.value = setTimeout(() => {
      showSearchResults.value = true;
    }, 300);
  } else {
    showSearchResults.value = false;
  }
});

// Håndter klik på bruger
const handleUserClick = (user) => {
  router.push(`/profile/${user.id}`);
  showSearchResults.value = false;
  globalSearchQuery.value = "";
};

// Luk søgeresultater når der klikkes udenfor
const handleClickOutside = (event) => {
  const searchContainer = document.querySelector('.search-container');
  if (searchContainer && !searchContainer.contains(event.target)) {
    showSearchResults.value = false;
  }
};

// Hent brugere baseret på den aktuelle brugers rolle
const fetchUsers = async () => {
  const usersRef = collection(db, "users");
  let q;
  
  if (currentUser.value?.role === 1) {
    // For superAdmin (rolle 1) - vis alle undtagen rolle 1
    q = query(usersRef, where("role", "!=", 1));
  }

  if (q) {
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const usersArray = [];
      snapshot.forEach((doc) => {
        usersArray.push({ id: doc.id, ...doc.data() });
      });
      users.value = usersArray;
    });

    // Cleanup listener når komponenten unmountes
    onBeforeUnmount(() => {
      unsubscribe();
    });
  }
};

// Initialiser søgefunktionalitet når brugeren er logget ind
watch(() => currentUser.value, (newUser) => {
  if (newUser && newUser.role === 1) {
    fetchUsers();
  } else {
    users.value = [];
  }
}, { immediate: true });

onMounted(() => {
  document.addEventListener('click', handleClickOutside);

  // Provide auth component reference
  provide('authComponent', authComponent.value);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});

// Brug online status composable
useOnlineStatus();

// Implementer en computed property for at tjekke om vi er på login siden
const isAuthPage = computed(() => {
  return router.currentRoute.value.path === '/authentication/signin' || 
         router.currentRoute.value.path === '/authentication/signup';
});

// Tilføj en computed property for at tjekke om vi er på home siden
const isHomePage = computed(() => {
  return router.currentRoute.value.path === '/' || 
         router.currentRoute.value.path === '/home';
});

// Opdater route handling
router.beforeEach(async (to, from, next) => {
  // Simpel auth check
  const currentUser = store.state.user;
  
  // Hvis bruger prøver at tilgå login siden mens de er logget ind
  if (to.path === '/authentication/signin' && currentUser) {
    return next(currentUser.role <= 2 ? '/pages/tables' : '/profile');
  }

  // Hvis ruten kræver auth og bruger ikke er logget ind
  if (to.meta.requiresAuth && !currentUser) {
    return next({
      path: '/authentication/signin',
      query: { redirect: to.fullPath }
    });
  }

  next();
});

const handleAuthStateChange = async (user) => {
  try {
    if (user) {
      await store.dispatch("initializeAuthUser", user);
    } else {
      store.commit('CLEAR_USER');
    }
  } catch (error) {
    console.error('Auth state handling error:', error);
    store.commit('CLEAR_USER');
  } finally {
    if (authReady && typeof authReady.value !== 'undefined') {
      authReady.value = true;
    }
  }
};

const updateOnlineStatus = async (isOnline) => {
  const user = auth.currentUser;
  if (!user) return;

  const userRef = doc(db, 'users', user.uid);
  try {
    await updateDoc(userRef, {
      isOnline: isOnline,
      lastSeen: new Date()
    });
  } catch (error) {
    console.error('Fejl ved opdatering af online status:', error);
  }
};

// Håndter browser lukning og tab lukning
const handleTabClose = async () => {
  await updateOnlineStatus(false);
};

// Håndter visibility change
const handleVisibilityChange = async () => {
  if (document.visibilityState === 'hidden') {
    await updateOnlineStatus(false);
  } else if (document.visibilityState === 'visible') {
    await updateOnlineStatus(true);
  }
};

onMounted(async () => {
  const unsubscribe = onAuthStateChanged(auth, handleAuthStateChange);

  // Mere robust error handling
  const errorHandler = (event) => {
    const errorPatterns = [
      'background.js',
      'The item cache',
      'Could not collect',
      'Unable to inject',
      'PortManager'
    ];
    
    const errorString = event.filename || event.reason?.stack || event.message || '';
    
    if (errorPatterns.some(pattern => errorString.includes(pattern))) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  };

  window.addEventListener('error', errorHandler, true);
  window.addEventListener('unhandledrejection', errorHandler, true);

  // Sæt initial online status
  updateOnlineStatus(true);

  // Tilføj event listeners
  window.addEventListener('beforeunload', handleTabClose);
  document.addEventListener('visibilitychange', handleVisibilityChange);

  // Tilføj listener til auth state changes
  auth.onAuthStateChanged((user) => {
    if (user) {
      updateOnlineStatus(true);
    } else {
      updateOnlineStatus(false);
    }
  });

  return () => {
    window.removeEventListener('error', errorHandler, true);
    window.removeEventListener('unhandledrejection', errorHandler, true);
  };
});

// Cleanup i onBeforeUnmount
onBeforeUnmount(async () => {
  await updateOnlineStatus(false);
  window.removeEventListener('beforeunload', handleTabClose);
  document.removeEventListener('visibilitychange', handleVisibilityChange);
});

const authComponent = ref(null);
</script>

<template>
  <div class="app-container">
    <!-- Global Language Switcher -->
    <div class="global-language-switcher" v-if="!isHomePage">
      <LanguageSwitcher />
    </div>

    <!-- Global Search Bar -->
    <div class="global-search-bar" v-if="!isHomePage && !isAuthPage && currentUser?.role === 1">
      <div class="search-container">
        <input
          type="text"
          v-model="globalSearchQuery"
          :placeholder="$t('common.search_user')"
          class="search-input"
        />
        <div v-if="showSearchResults && filteredUsers.length > 0" class="search-results">
          <div 
            v-for="user in filteredUsers" 
            :key="user.id"
            class="search-result-item"
            @click="handleUserClick(user)"
          >
            <div class="user-info">
              <div class="user-name">{{ user.name }}</div>
              <div class="user-email">{{ user.email }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <router-view v-slot="{ Component }">
      <component
        :is="Component"
        v-if="isHomePage || isAuthPage"
        :key="$route.fullPath"
      >
        <SignIn ref="authComponent" />
      </component>

      <white-label-provider v-else>
        <div
          v-show="layout === 'landing'"
          class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
        ></div>
        <main class="main-content position-relative max-height-vh-100 h-100">
          <component 
            :is="Component" 
            v-if="authReady" 
            :key="$route.fullPath"
          />
          <app-footer v-show="showFooter" />
          <configurator v-show="showConfig" />
        </main>
      </white-label-provider>
    </router-view>
  </div>
</template>

<style>
:root {
  --background-color: #1a2035;
  --card-color: #202940;
  --sidenav-color: linear-gradient(122deg, #1568db, #05c2ed);
}

[data-firebase-log] {
  display: none !important;
}

/* Base hardware acceleration og instant visibility */
.main-content,
.router-view-wrapper,
[v-cloak] {
  backface-visibility: hidden !important;
  -webkit-backface-visibility: hidden !important;
  transform: translate3d(0,0,0) !important;
  -webkit-transform: translate3d(0,0,0) !important;
  perspective: 1000 !important;
  -webkit-perspective: 1000 !important;
  visibility: visible !important;
  opacity: 1 !important;
  will-change: transform, opacity;
  min-height: 100vh !important;
  height: auto !important;
  overflow-y: auto !important;
  position: relative !important;
}

/* Tillad transitions på UI elementer */
.dropdown-menu,
.nav-item,
.nav-link,
.navbar-nav,
.collapse,
.navbar-collapse {
  transition: all 0.15s ease !important;
  animation: unset !important;
  transform: none !important;
  backface-visibility: unset !important;
  -webkit-backface-visibility: unset !important;
  perspective: none !important;
  will-change: auto;
}

/* Disable Vue transitions */
.v-enter-active,
.v-leave-active,
.v-enter-from,
.v-leave-to {
  transition: none !important;
  animation: none !important;
  opacity: 1 !important;
  visibility: visible !important;
}

/* Tilføj disse nye styles */
html, body {
  height: 100% !important;
  overflow-y: auto !important;
}

.max-height-vh-100 {
  max-height: none !important;
  height: auto !important;
  min-height: 100vh !important;
}

/* Tilføj styles for global language switcher */
.global-language-switcher {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 9999;
  padding: 0.5rem;
  transition: all 0.3s ease;
}

/* Responsive styles */
@media (max-width: 768px) {
  .global-language-switcher {
    top: 0.5rem;
    right: 0.5rem;
  }
}

/* Global Search Bar Styles */
.global-search-bar {
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 9999;
  padding: 0.5rem;
  transition: all 0.3s ease;
}

.search-container {
  position: relative;
}

.search-input {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  padding: 8px 16px;
  color: white;
  width: 150px;
  transition: all 0.3s ease;
}

.search-input::placeholder {
  color: rgba(255, 255, 255, 0.8);
  opacity: 1;
}

.search-input:focus {
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.3);
  outline: none;
  width: 300px;
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 0.5rem;
  background: rgba(30, 41, 59, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow-y: auto;
  backdrop-filter: blur(10px);
}

.search-result-item {
  padding: 12px 16px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.search-result-item:hover {
  background: rgba(255, 255, 255, 0.1);
}

.search-result-item:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.user-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.user-name {
  color: white;
  font-size: 0.9rem;
  font-weight: 500;
}

.user-email {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.8rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .global-search-bar {
    top: 0.5rem;
    left: 0.5rem;
  }

  .search-input {
    width: 200px;
  }

  .search-input:focus {
    width: 240px;
  }
}
</style>

