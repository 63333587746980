<script setup>
import { ref, onMounted } from "vue";

// Her kan du tilføje din data og logik for projekttabellen
const projects = ref([]);

onMounted(() => {
  // Initialiser data når komponenten monteres
});
</script>

<style scoped>
.table {
  min-width: 850px;
}
</style> 