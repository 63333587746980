<template>
  <div class="post-cars-component card-body border-radius-lg p-3" :class="{ 'invisible-card': isInvisible }">
    <div v-if="currentStep === 1" class="content-container step-one-container">
      <div class="row align-items-center">
        <div class="col-auto">
          <div class="gradient-text me-3">lav et opslag med en:</div>
        </div>
        <div class="col">
          <div class="form-container">
            <div class="select-button-group">
              <DropdownMenu 
                :options="statusOptions.map(status => ({
                  label: status
                }))"
                @select="handleStatusSelect"
              >
                {{ selectedStatus || 'Vælg status' }}
              </DropdownMenu>
              <button 
                @click="handleNext" 
                :disabled="!selectedStatus"
                class="btn bg-gradient-success btn-md next-button"
              >
                Næste
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="currentStep === 2" class="content-container">
      <div class="d-flex justify-content-between align-items-center w-100 mb-4">
        <button @click="handleBack" class="btn btn-outline-secondary btn-md">
          <i class="fas fa-arrow-left mr-2"></i> Tilbage
        </button>
        <div style="width: 80px;"></div>
      </div>
      <SelectCar @car-selected="selectCar" />
    </div>

    <div v-else-if="currentStep === 3" class="content-container">
      <div class="d-flex justify-content-between align-items-center w-100 mb-4">
        <button @click="handleBack" class="btn btn-outline-secondary btn-md">
          <i class="fas fa-arrow-left mr-2"></i> Tilbage
        </button>
        <button @click="handleNext" class="btn btn-outline-secondary btn-md">
          Næste <i class="fas fa-arrow-right ml-2"></i>
        </button>
      </div>
      
      <div class="channel-type-container">
        <div class="channel-section">
          <h6 class="section-title">Vælg Kanal</h6>
          <div class="options-row">
            <div v-for="channel in channels" :key="channel.id" 
                 @click="toggleSelection(channel)"
                 class="option-item channel-option" 
                 :class="{ 'selected': channel.selected }">
              <div class="avatar">
                <i :class="channel.id === 'facebook' ? 'fab fa-facebook' : 'fab fa-instagram'" class="social-icon"></i>
                <span class="icon-label">{{ channel.id === 'facebook' ? 'Facebook' : 'Instagram' }}</span>
              </div>
            </div>
          </div>
        </div>
        
        <div class="type-section">
          <h6 class="section-title">Vælg Type</h6>
          <div class="options-row">
            <div v-for="type in types" :key="type.id" 
                 @click="toggleSelection(type)"
                 class="option-item media-type-option" 
                 :class="{ 'selected': type.selected }">
              <div class="avatar">
                <i :class="type.id === 'image' ? 'far fa-image' : 'fas fa-video'" class="media-icon"></i>
                <span class="icon-label">{{ type.id === 'image' ? 'Billede' : 'Video' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="currentStep === 4" class="content-container">
      <GetDesign
        :design-types="designTypes"
        :design-images="designImages"
        :selected-status="selectedStatus"
        :selected-design="selectedDesign"
        :selected-types="selectedTypes"
        :selected-channels="selectedChannels"
        @update:selected-design="selectedDesign = $event"
        @back="handleBack"
        @next="handleNext"
      />
    </div>

    <div v-else-if="currentStep === 5" class="content-container">
      <div class="row align-items-center">
        <div class="col-auto">
          <div class="gradient-text me-3">Planlæg opslag</div>
        </div>
        <div class="col">
          <div class="form-container">
            <div class="action-controls mb-4">
              <div class="posting-types-minimal-container" v-if="showPostingTypeOptions">
                <div class="posting-type-header" @click="togglePostingTypes">
                  <div class="header-content">
                    <span class="header-title">Skift opslags type</span>
                    <span class="selected-type" v-if="selectedPostingType">
                      {{ getSelectedTypeName }}
                    </span>
                  </div>
                  <i class="fas fa-chevron-down" :class="{ 'rotated': showPostingTypesExpanded }"></i>
                </div>
                
                <div class="posting-types-content" v-if="showPostingTypesExpanded">
                  <div class="posting-types-list">
                    <div v-for="type in availablePostingTypes" 
                         :key="type.id"
                         @click="selectPostingType(type)"
                         class="posting-type-option"
                         :class="{ 'selected': selectedPostingType === type.id }">
                      <span class="type-name">{{ type.name }}</span>
                      <span class="type-description">{{ type.description }}</span>
                    </div>
                  </div>
                  <button v-if="selectedPostingType !== defaultPostingType"
                          @click="resetPostingType" 
                          class="reset-button">
                    Nulstil til standard opslag
                  </button>
                </div>
              </div>
            </div>
            
            <div class="scheduling-section">
              <input type="datetime-local" v-model="scheduledDateTime" class="form-control">
            </div>
            
            <div class="action-buttons mt-4">
              <button @click="handleBack" type="button" class="btn btn-outline-secondary btn-md">Tilbage</button>
              <button @click="createPost" type="button" class="btn bg-gradient-success btn-md" :disabled="!isFormValid || isLoading">
                <span v-if="!isLoading">Opret opslag</span>
                <span v-else class="loading-spinner"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Opdateret preview modal -->
    <PreviewModal
      v-model:show="showPreview"
      v-model:preview-text="previewText"
      :is-loading="isPreviewLoading"
      :is-generating-video="isGeneratingVideo"
      :generation-status-message="generationStatusMessage"
      :video-url="videoUrl"
      :preview-image-url="previewImageUrl"
      @shuffle-text="shuffleText"
      @ai-assisted-text="aiAssistedText"
      @save-preview="savePreview"
      @video-error="handleVideoError"
    />

    <Notifications 
      :message="notificationMessage" 
      :show="showNotification"
      :title="notificationTitle"
      :color="notificationColor"
      @update:show="showNotification = $event"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import SelectCar from './SelectCar.vue';
import GetDesign from './getDesign.vue';
import { getFirestore, collection, getDocs, doc, getDoc, addDoc, updateDoc } from 'firebase/firestore';
import { initializeApp, getApps } from 'firebase/app';
import { generateImage } from '@/utils/imageGenerator';
import { generateSocialMediaPost } from '@/services/socialMediaPostGenerator';
import axios from 'axios';
import { generatePostData } from '@/utils/postDataGenerator';
import Notifications from './Notifications.vue';
import { useUserContext } from '@/composables/useUserContext';
import { generateVideo } from '@/utils/videoGenerator';
import { generateAIText } from '@/services/aiTextService';
import { getAuth } from 'firebase/auth';
import DropdownMenu from '@/components/ui/DropdownMenu.vue';
import PreviewModal from '@/components/modals/PreviewModal.vue';

// Firebase konfiguration
const firebaseConfig = {
  // Din Firebase konfiguration her
};

// Tjek om appen allerede er initialiseret
let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0]; // Brug den eksisterende app
}

const db = getFirestore(app);

const statusOptions = ref([
  'Nyhed', 'Ny bil på lager', 'Tilbud', 'Prisjustering', 'Dagens bil',
  'Ugens bil', 'Netop solgt', 'Eftertragtet model', 'Skarp pris'
]);

const selectedStatus = ref('');
const currentStep = ref(1);
const channels = ref([
  { id: 'facebook', image: 'https://firebasestorage.googleapis.com/v0/b/estate-f5e0f.appspot.com/o/designs%2Ffilters%2Fnew%2Ffacebook.png?alt=media', selected: false },
  { id: 'instagram', image: 'https://firebasestorage.googleapis.com/v0/b/estate-f5e0f.appspot.com/o/designs%2Ffilters%2Fnew%2Finstagram.png?alt=media', selected: false }
]);
const types = ref([
  { id: 'image', image: 'https://firebasestorage.googleapis.com/v0/b/toft-d4f39.appspot.com/o/car-image.png?alt=media&token=d365475f-1ad6-49d1-8ef3-97ebe4de5997', selected: false },
  { id: 'video', image: 'https://firebasestorage.googleapis.com/v0/b/toft-d4f39.appspot.com/o/car_video.png?alt=media&token=558137e2-2dd6-42c0-9b62-70eadd0a72a2', selected: false }
]);
const selectedCar = ref(null);

const designImages = ref([]);
const designTypes = ref([]);
const activeDesignType = ref('');

const selectedFormat = ref('');

const selectedDesign = ref(null);
const publishLater = ref(false);

const isFormValid = computed(() => {
  return scheduledDateTime.value &&
         selectedStatus.value &&
         selectedCar.value &&
         selectedDesign.value &&
         channels.value.some(channel => channel.selected) &&
         types.value.some(type => type.selected);
});

const fetchDesignImages = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'designImages'));
    designImages.value = querySnapshot.docs.map(doc => doc.data());
    console.log('Design Images:', designImages.value);
    
    // Udtræk unikke designtyper fra 'groups' feltet i de hentede designs
    const types = new Set(designImages.value.flatMap(design => design.groups || []).filter(Boolean));
    designTypes.value = Array.from(types);
    
    // Sæt den aktive designtype til den første i listen, hvis der er nogen
    if (designTypes.value.length > 0) {
      activeDesignType.value = designTypes.value[0];
    }
  } catch (error) {
    console.error('Fejl ved hentning af design billeder:', error);
  }
};

const logSelections = () => {
  console.log(`Status: ${selectedStatus.value} (choices - step 1)`);
  console.log(`Car: ${selectedCar.value ? selectedCar.value.id : 'Ingen bil valgt'} (id - step 2)`);
  const selectedChannels = channels.value.filter(channel => channel.selected).map(channel => channel.id).join(', ');
  console.log(`Kanal: ${selectedChannels} (kanal - step 3)`);
  const selectedTypes = types.value.filter(type => type.selected).map(type => type.id).join(', ');
  console.log(`Type: ${selectedTypes} (type - step 3)`);
};

const statusToGroupMap = {
  'Nyhed': 'Ny bil',
  'Ny bil på lager': 'Ny bil',
  'Tilbud': 'Tilbud',
  'Prisjustering': 'Prisjustering',
  'Dagens bil': 'Dagens bil',
  'Ugens bil': 'Ugens bil',
  'Netop solgt': 'Netop solgt',
  'Eftertragtet model': 'Eftertragtet model',
  'Skarp pris': 'Skarp pris'
};

const filteredDesignImages = computed(() => {
  logSelections(); // Log valg når designbilleder filtreres
  return designImages.value.filter(design => {
    if (!design?.file) return false;

    // Tjek om filen er en video eller et billede
    const isVideoFile = /\.mp4$/i.test(design.file.split('?')[0]);
    const isImageFile = /\.(jpg|jpeg|png)$/i.test(design.file.split('?')[0]);

    // Hent de valgte medietyper
    const selectedTypes = types.value
      .filter(type => type.selected)
      .map(type => type.id);

    // Tjek om filtypen matcher de valgte typer
    const matchesMediaType = (
      (selectedTypes.includes('video') && isVideoFile) ||
      (selectedTypes.includes('image') && isImageFile) ||
      selectedTypes.length === 0 // Vis alle hvis ingen type er valgt
    );

    const matchesDesignType = design.groups && design.groups.includes(activeDesignType.value);
    const matchesStatus = Array.isArray(design.emner) && design.emner.includes(selectedStatus.value);
    const matchesChannel = Array.isArray(design.channels) && 
      channels.value.some(channel => {
        const matches = channel.selected && 
          design.channels.some(dc => dc.toLowerCase() === channel.id.toLowerCase());
        console.log(`Design ${design.uuid} - Channel ${channel.id}:`, {
          channelSelected: channel.selected,
          designChannels: design.channels,
          matches
        });
        return matches;
      });

    console.log(`Design ${design.uuid}:`, {
      matchesMediaType,
      matchesDesignType,
      matchesStatus,
      matchesChannel,
      fileType: isVideoFile ? 'video' : 'image',
      selectedTypes
    });

    return matchesMediaType && matchesDesignType && matchesStatus && matchesChannel;
  });
});

const handleNext = () => {
  if (currentStep.value === 1 && selectedStatus.value) {
    currentStep.value = 2;
  } else if (currentStep.value === 2) {
    currentStep.value = 3;
  } else if (currentStep.value === 3) {
    currentStep.value = 4;
  } else if (currentStep.value === 4 && selectedDesign.value) {
    currentStep.value = 5;
  } else {
    alert('Vælg venligst de nødvendige muligheder før du fortsætter.');
  }
};

const handleBack = () => {
  if (currentStep.value > 1) {
    currentStep.value--;
  }
};

const selectCar = (car) => {
  selectedCar.value = car;
  console.log('Valgt bil:', car);
  console.log('Valgt status:', selectedStatus.value);
  // Gå automatisk til næste trin efter at en bil er valgt
  currentStep.value = 3;
};

const handleCarSelected = (car) => {
  console.log('Valgt bil:', car);
  // Implementer logik for hvad der skal ske når en bil er valgt
  // F.eks. gå til næste trin eller gemme den valgte bil
};

const toggleSelection = (item) => {
  // Håndter medietyper (video eller billede)
  if (item.id === 'video' || item.id === 'image') {
    // Hvis det er video, tjek først om der er nok billeder
    if (item.id === 'video') {
      if (!hasEnoughImages(selectedCar.value)) {
        showToast(
          'Der skal være mindst 10 billeder for at generere en video. Denne bil har ikke nok billeder.',
          'Advarsel',
          'warning'
        );
        return;
      }
    }
    
    // Fjern selection fra alle medietyper
    types.value.forEach(type => {
      if (type.id === 'video' || type.id === 'image') {
        type.selected = false;
      }
    });
    
    // Sæt den valgte type til true
    item.selected = true;
    
    // Hvis vi skifter væk fra video, nulstil video relaterede værdier
    if (item.id !== 'video') {
      videoUrl.value = null;
      isGeneratingVideo.value = false;
      generationStatusMessage.value = '';
    }
  } 
  // Håndter kanaler (facebook eller instagram)
  else if (item.id === 'facebook' || item.id === 'instagram') {
    // Fjern selection fra alle kanaler
    channels.value.forEach(channel => {
      if (channel.id === 'facebook' || channel.id === 'instagram') {
        channel.selected = false;
      }
    });
    
    // Sæt den valgte kanal til true
    item.selected = true;
  }
};

const isInvisible = ref(false); // Sæt denne til true for at gøre card'et usynligt

const prepareStepData = (step) => {
  switch (step) {
    case 1:
      // Forbered data til step 2
      console.log('Forbereder data til step 2');
      // Her kan du tilføje logik til at hente eller forberede data til step 2
      break;
    case 2:
      // Forbered data til step 3
      console.log('Forbereder data til step 3');
      // Her kan du tilføje logik til at hente eller forberede data til step 3
      break;
    default:
      break;
  }
};

const selectDesignType = (designType) => {
  activeDesignType.value = designType;
};

const selectDesign = (design) => {
  selectedDesign.value = design;
};

const showPreview = ref(false);
const previewImageUrl = ref('');
const previewText = ref('');
const isLoading = ref(false);
const loadingMessage = ref('');

const generatePreviewContent = async () => {
  isLoading.value = true;
  loadingMessage.value = 'Forbereder preview...';
  
  try {
    const isVideoDesign = types.value.find(t => t.selected)?.id === 'video';
    console.log('Er video design?', isVideoDesign);

    // Log det valgte design for at debugge
    console.log('Valgt design:', selectedDesign.value);

    // Sikr at vi har den korrekte bruger ID
    const currentUserId = userId.value;
    const currentDealerId = dealerId.value;
    
    if (!currentUserId && !currentDealerId) {
      throw new Error('Ingen bruger ID eller dealer ID fundet');
    }

    console.log('Bruger information:', {
      userId: currentUserId,
      dealerId: currentDealerId,
      role: userRole.value
    });

    // Generer tekst først
    loadingMessage.value = 'Genererer tekst...';
    const postContent = await generateSocialMediaPost(
      {
        ...selectedCar.value,
        dealerId: currentDealerId,
        userId: currentUserId,
        role: userRole.value
      },
      selectedChannels.value[0] || 'facebook',
      selectedStatus.value
    );

    previewText.value = postContent.text;
    console.log('Genereret tekst:', postContent.text);

    // Generer derefter medie (video eller billede)
    if (isVideoDesign) {
      await handleVideoGeneration();
    } else {
      loadingMessage.value = 'Genererer billede...';
      const selectedDesignObj = selectedDesign.value?.[0];
      const templateId = selectedDesignObj?.templateId || selectedDesignObj?.uuid;
      
      console.log('Valgt design objekt:', selectedDesignObj);
      console.log('Template ID der bruges:', templateId);
      
      if (!templateId) {
        throw new Error('Intet template ID fundet - vælg venligst et design først');
      }

      // Hent brugerdata hvis det ikke allerede er tilgængeligt
      let userData = null;
      try {
        const userDocRef = doc(db, 'users', currentUserId);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          userData = userDoc.data();
          console.log('Hentet brugerdata:', userData);
        }
      } catch (error) {
        console.warn('Kunne ikke hente brugerdata:', error);
      }

      // Konstruer det komplette bil-objekt
      const completeCarData = {
        ...selectedCar.value,
        dealerId: currentDealerId,
        userId: currentUserId,
        role: userRole.value,
        userData: {
          ...selectedCar.value?.userData,
          ...userData,
          name: userData?.name || selectedCar.value?.userData?.name,
          address: userData?.address || selectedCar.value?.userData?.address,
          phone: userData?.phone || selectedCar.value?.userData?.phone,
          email: userData?.email || selectedCar.value?.userData?.email
        }
      };

      console.log('Sender følgende data til generateImage:', completeCarData);

      const imageUrl = await generateImage(completeCarData, templateId);
      
      previewImageUrl.value = imageUrl;
      selectedVideoUrl.value = null;
    }

    // Forbedre teksten med AI
    try {
      loadingMessage.value = 'Forbedrer tekst...';
      const messages = constructMessages('correct');
      const response = await correctText(messages);
      if (response?.correctedText) {
        previewText.value = response.correctedText;
      }
    } catch (error) {
      console.warn('Kunne ikke forbedre teksten:', error);
    }

  } catch (error) {
    console.error('Fejl ved preview generering:', error);
    showToast(
      `Fejl ved generering af preview: ${error.message}`, 
      'Fejl', 
      'danger'
    );
  } finally {
    isLoading.value = false;
    loadingMessage.value = '';
  }
};

// Tilføj ny ref for preview loading
const isPreviewLoading = ref(false);

// Opdater preview modal template delen
const previewPost = async () => {
  if (!selectedCar.value || !selectedDesign.value) {
    showToast('Vælg venligst en bil og et design før forhåndsvisning.', 'Advarsel', 'warning');
    return;
  }

  showPreview.value = true;
  isPreviewLoading.value = true;
  
  try {
    // Generer først standard tekst og gem den som fallback
    const postContent = await generateSocialMediaPost(
      selectedCar.value, 
      selectedChannels.value[0] || 'facebook',
      selectedStatus.value
    );
    const standardText = postContent.text; // Gem standard teksten
    previewText.value = standardText;

    // Tjek om video er valgt
    const isVideoSelected = types.value.find(t => t.selected)?.id === 'video';
    if (isVideoSelected) {
      await handleVideoGeneration();
    } else {
      await generatePreviewContent();
    }

    // Prøv at forbedre teksten med AI
    try {
      const messages = constructMessages('correct');
      const response = await correctText(messages);
      if (response && response.correctedText) {
        previewText.value = response.correctedText;
      }
    } catch (error) {
      console.warn('Kunne ikke forbedre teksten automatisk - bruger standard tekst:', error);
      previewText.value = standardText; // Brug standard teksten som fallback
    }

  } catch (error) {
    console.error('Fejl ved preview:', error);
    showToast('Fejl ved generering af preview', 'Fejl', 'danger');
  } finally {
    isPreviewLoading.value = false;
  }
};

const shuffleText = async () => {
  try {
    const selectedChannel = channels.value.find(c => c.selected)?.id || 'facebook';
    const postContent = await generateSocialMediaPost(
      selectedCar.value, 
      selectedChannel, 
      selectedStatus.value
    );
    
    if (typeof postContent === 'string') {
      previewText.value = postContent;
    } else if (postContent?.text) {
      previewText.value = postContent.text;
    }
  } catch (error) {
    console.error('Fejl ved generering af ny tekst:', error);
    showToast('Kunne ikke generere ny tekst', 'Fejl', 'danger');
  }
};

const aiAssistedText = async () => {
  isLoading.value = true;
  try {
    const messages = constructMessages('correct');
    const response = await correctText(messages);
    if (response && response.correctedText) {
      previewText.value = response.correctedText;
    } else {
      throw new Error('Uventet svarformat fra serveren');
    }
  } catch (error) {
    console.error('Fejl under AI-assisteret tekstgenerering:', error);
    alert(`Der opstod en fejl: ${error.message}`);
  } finally {
    isLoading.value = false;
  }
};

const correctText = async (messages) => {
  try {
    // Sikrer vi har den korrekte bruger ID
    const currentUserId = userId.value || dealerId.value;
    if (!currentUserId) {
      console.warn('Ingen bruger ID fundet, kan ikke fortsætte med tekstgenerering');
      return { correctedText: previewText.value };
    }

    // Prøv først med AI service
    const aiResponse = await generateAIText(messages);
    if (aiResponse?.correctedText) {
      return { correctedText: aiResponse.correctedText };
    }
  } catch (error) {
    console.warn('AI service fejlede, bruger fallback:', error);
  }

  // Fallback til cloud function
  try {
    const functionUrl = 'https://us-central1-toft-d4f39.cloudfunctions.net/correctText';
    
    const requestData = {
      userId: userId.value || dealerId.value, // Brug den korrekte bruger ID
      carId: selectedCar.value.id,
      emne: `${selectedCar.value.fields?.Mærke || ''} ${selectedCar.value.fields?.Model || ''}`,
      platform: channels.value.find(c => c.selected)?.id || 'facebook',
      messages: messages,
      carData: {
        ...selectedCar.value,
        maerke: selectedCar.value.fields?.Mærke || '',
        model: selectedCar.value.fields?.Model || '',
        description: selectedCar.value.description || '',
        headline: selectedCar.value.headline || '',
        price: selectedCar.value.price || '',
        fields: selectedCar.value.fields || {},
        dealerId: dealerId.value, // Tilføj dealerId specifikt
        userId: userId.value // Tilføj userId specifikt
      }
    };

    console.log('Sender request med følgende data:', {
      userId: requestData.userId,
      dealerId: requestData.carData.dealerId,
      carId: requestData.carId
    });

    const response = await axios.post(functionUrl, requestData, {
      headers: { 'Content-Type': 'application/json' }
    });
    
    if (response.data?.correctedText) {
      console.log('Tekst genereret med fallback');
      return { correctedText: response.data.correctedText };
    }
    
    throw new Error('Ingen gyldig tekst modtaget fra serveren');
  } catch (error) {
    console.error('Fejl i tekstgenerering:', error);
    // Returner den oprindelige tekst hvis begge metoder fejler
    return { correctedText: previewText.value };
  }
};

const constructMessages = (action) => {
  let messages = [
    {
      role: "system",
      content: `Du er en erfaren bilsælger og social media manager. Din opgave er at skabe fængende og salgsorienterede opslag til sociale medier om biler. 
                Regler:
                - Brug emojis naturligt og moderat
                - Hold teksten kort og fængende, max 280 tegn
                - Tilpas tonen og indholdet specifikt til den pågældende bil
                - Skab et opslag, der opfordrer til interaktion og klik
                - Undgå at bruge formateringsmærker som **, [], eller overskrifter
                - Skriv teksten, så den er klar til at blive kopieret direkte ind i et socialt medie opslag
                - Inkluder en kort, fængende beskrivelse af bilen og dens vigtigste features
                - Afslut med en opfordring til handling (CTA)`
    }
  ];

  if (action === 'correct') {
    const carFields = Object.entries(selectedCar.value.fields || {})
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');

    messages.push({
      role: "user",
      content: `Lav et fængende socialt medie opslag baseret på følgende information:
                Mærke og model: ${selectedCar.value.fields?.Mærke || ''} ${selectedCar.value.fields?.Model || ''}
                Overskrift: ${selectedCar.value.headline || ''}
                Beskrivelse: ${selectedCar.value.description || ''}
                År: ${selectedCar.value.fields?.Årgang || ''}
                Pris: ${selectedCar.value.price || ''}
                Status: Nyhed
                
                Yderligere detaljer:
                ${carFields}
                
                Nuværende tekst (skal forbedres):
                ${previewText.value}
                
                Husk at følge reglerne i system-prompten, og lav en tekst der er klar til at blive postet direkte.`
    });
  }

  return messages;
};

const closePreview = () => {
  showPreview.value = false;
};

const savedPreviewImage = ref('');
const savedPreviewText = ref('');
const hasPreviewData = ref(false);

const savePreview = async () => {
  isLoading.value = true;
  try {
    // Tjek om den valgte tid stadig er i fremtiden
    const selectedTime = new Date(scheduledDateTime.value);
    const now = new Date();
    if (selectedTime < now) {
      showToast('Tidspunktet skal være i fremtiden. Vælg venligst et nyt tidspunkt.', 'Advarsel', 'warning');
      return;
    }

    const postData = generatePostData({
      selectedCar: selectedCar.value,
      previewImageUrl: previewImageUrl.value || videoUrl.value,
      previewText: previewText.value,
      selectedStatus: selectedStatus.value,
      channels: channels.value,
      userMetaSettings: userMetaSettings.value,
      userId: userId.value,
      dealerId: dealerId.value,
      publishLater: true, // Altid true da vi bruger scheduledDateTime
      scheduledDateTime: scheduledDateTime.value,
      scheduledTime: selectedTime.getTime(), // Tilføj Unix timestamp
      isVideo: types.value.find(t => t.selected)?.id === 'video',
      postingType: selectedPostingType.value || defaultPostingType.value,
      status: 'scheduled' // Ændret fra 'ready' til 'scheduled'
    });

    await addDoc(collection(db, 'posts'), postData);
    showToast('Opslag er planlagt til: ' + selectedTime.toLocaleString('da-DK'), 'Success', 'success');
    showPreview.value = false;
    resetForm();
  } catch (error) {
    console.error('Fejl ved oprettelse af opslag:', error);
    showToast(
      `Fejl ved oprettelse af opslag: ${error.message}`, 
      'Fejl', 
      'danger'
    );
  } finally {
    isLoading.value = false;
  }
};

const userMetaSettings = ref(null);

// Tilfj dealerId ref
const dealerId = ref(null);

// Hent bruger context
const { contextUserId } = useUserContext();

// Tilføj userId computed property
const userId = computed(() => {
  // Hvis vi har en contextUserId, brug den
  if (contextUserId.value) {
    return contextUserId.value;
  }
  // Ellers tjek om vi har en aktiv auth session
  const auth = getAuth();
  return auth.currentUser?.uid || null;
});

// Tilføj ny computed property for at tjekke brugerrolle
const userRole = ref(null);

// Tilføj funktion til at hente brugerrolle
const fetchUserRole = async () => {
  if (!userId.value) return;
  
  try {
    const userDocRef = doc(db, 'users', userId.value);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      userRole.value = userDoc.data().role;
    }
  } catch (error) {
    console.error('Fejl ved hentning af brugerrolle:', error);
  }
};

// Opdater fetchUserMetaSettings
const fetchUserMetaSettings = async () => {
  if (!userId.value) {
    console.error('Ingen bruger ID tilgængelig');
    return;
  }

  try {
    const userDocRef = doc(db, 'users', userId.value);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      userMetaSettings.value = userDoc.data().MetaSettings;
      dealerId.value = userDoc.data().dealerId;
      userRole.value = userDoc.data().role;
      
      console.log('Bruger data hentet:', {
        userId: userId.value,
        dealerId: dealerId.value,
        role: userRole.value
      });
    } else {
      console.error('Ingen brugerdata fundet');
      showToast('Kunne ikke finde brugerdata', 'Fejl', 'danger');
    }
  } catch (error) {
    console.error('Fejl ved hentning af brugerdata:', error);
  }
};

const notificationMessage = ref('');
const showNotification = ref(false);
const notificationTitle = ref('Notifikation');
const notificationColor = ref('success');

const showToast = (message, title = 'Notifikation', color = 'success') => {
  notificationMessage.value = message;
  notificationTitle.value = title;
  notificationColor.value = color;
  showNotification.value = true;
  // Skjul notifikationen efter 5 sekunder
  setTimeout(() => {
    showNotification.value = false;
  }, 5000);
};

const createPost = async () => {
  if (!isFormValid.value) {
    showToast('Venligst udfyld alle nødvendige felter.', 'Advarsel', 'warning');
    return;
  }

  if (!selectedCar.value || !selectedDesign.value) {
    showToast('Vælg venligst en bil og et design før forhåndsvisning.', 'Advarsel', 'warning');
    return;
  }

  // Valider at der er valgt en dato/tid
  if (!scheduledDateTime.value) {
    showToast('Vælg venligst hvornår opslaget skal publiceres', 'Advarsel', 'warning');
    return;
  }

  // Tjek om den valgte tid er i fremtiden
  const selectedTime = new Date(scheduledDateTime.value);
  const now = new Date();
  if (selectedTime < now) {
    showToast('Tidspunktet skal være i fremtiden', 'Advarsel', 'warning');
    return;
  }

  showPreview.value = true;
  isPreviewLoading.value = true;
  
  try {
    // Generer først standard tekst og gem den som fallback
    const postContent = await generateSocialMediaPost(
      selectedCar.value, 
      selectedChannels.value[0] || 'facebook',
      selectedStatus.value
    );
    const standardText = postContent.text;
    previewText.value = standardText;

    // Tjek om video er valgt
    const isVideoSelected = types.value.find(t => t.selected)?.id === 'video';
    if (isVideoSelected) {
      await handleVideoGeneration();
    } else {
      await generatePreviewContent();
    }

    // Prøv at forbedre teksten med AI
    try {
      const messages = constructMessages('correct');
      const response = await correctText(messages);
      if (response && response.correctedText) {
        previewText.value = response.correctedText;
      }
    } catch (error) {
      console.warn('Kunne ikke forbedre teksten automatisk - bruger standard tekst:', error);
      previewText.value = standardText;
    }

  } catch (error) {
    console.error('Fejl ved preview:', error);
    showToast('Fejl ved generering af preview', 'Fejl', 'danger');
  } finally {
    isPreviewLoading.value = false;
  }
};

// Opdateret generateContent funktion
const generateContent = async () => {
  try {
    // Start med at generere tekst
    const postContent = await generateSocialMediaPost(
      selectedCar.value, 
      channels.value.find(c => c.selected)?.id || 'facebook',
      selectedStatus.value
    );

    let mediaUrl = null;
    let thumbnailUrl = null;

    // Generer video eller billede baseret på type
    const isVideoSelected = types.value.find(t => t.selected)?.id === 'video';
    
    if (isVideoSelected) {
      const videoResult = await generateVideo(
        selectedCar.value,
        selectedDesign.value?.uuid || "df6477a8-2ece-4cf1-a4d9-b548d9a6ad83==29bbd140-695d-4304-989e-e190ba03f149",
        (status) => {
          // Opdater status i UI
          if (status.message) {
            showToast(status.message, 'Info', 'info');
          }
        }
      );
      mediaUrl = videoResult.videoUrl;
      thumbnailUrl = videoResult.thumbnailUrl;
    } else {
      mediaUrl = await generateImage(
        selectedCar.value,
        selectedDesign.value?.uuid
      );
      thumbnailUrl = mediaUrl;
    }

    // Prøv at forbedre teksten med AI
    let finalText = postContent.text;
    try {
      const messages = constructMessages('correct');
      const response = await correctText(messages);
      if (response?.correctedText) {
        finalText = response.correctedText;
      }
    } catch (error) {
      console.warn('Kunne ikke forbedre teksten med AI:', error);
    }

    // Nu hvor vi har både medie og tekst, opret opslaget
    const postData = generatePostData({
      selectedCar: selectedCar.value,
      previewImageUrl: mediaUrl,
      previewText: finalText,
      selectedStatus: selectedStatus.value,
      channels: channels.value,
      userMetaSettings: userMetaSettings.value,
      userId: userId.value,
      dealerId: dealerId.value,
      publishLater: publishLater.value,
      scheduledDateTime: scheduledDateTime.value,
      isVideo: isVideoSelected,
      postingType: selectedPostingType.value || defaultPostingType.value,
      status: 'ready'
    });

    // Gem opslaget i Firebase
    await addDoc(collection(db, 'posts'), postData);
    showToast('Opslag er genereret og planlagt', 'Success', 'success');

  } catch (error) {
    console.error('Fejl ved generering af indhold:', error);
    showToast('Fejl ved generering af indhold: ' + error.message, 'Fejl', 'danger');
    throw error;
  }
};

const resetForm = () => {
  selectedStatus.value = '';
  selectedCar.value = null;
  selectedDesign.value = null;
  channels.value.forEach(channel => channel.selected = false);
  types.value.forEach(type => type.selected = false);
  publishLater.value = false;
  scheduledDateTime.value = getDefaultScheduledDateTime(); // Opdater til ny default værdi
  savedPreviewImage.value = '';
  savedPreviewText.value = '';
  hasPreviewData.value = false;
  currentStep.value = 1;
  selectedPostingType.value = '';
};

// Kald prepareStepData, når komponenten monteres
onMounted(async () => {
  await fetchUserMetaSettings();
  await fetchDesignImages();
});

// Overvåg currentStep og forbered data til det næste trin
watch(currentStep, (newStep) => {
  logSelections();
  prepareStepData(newStep);
  console.log('Current step changed to:', newStep);
  if (newStep === 3) {
    console.log('Step 3 data:', {
      designTypes: designTypes.value,
      filteredDesigns: filteredDesignImages.value,
      selectedStatus: selectedStatus.value
    });
  }
});

const selectedChannel = ref('');
const selectedMediaType = ref('');

const selectChannel = (channel) => {
  selectedChannel.value = channel;
};

const selectMediaType = (type) => {
  selectedMediaType.value = type;
};

// Tilføj computed properties for at få de valgte typer og kanaler
const selectedTypes = computed(() => {
  return types.value
    .filter(type => type.selected)
    .map(type => type.id);
});

const selectedChannels = computed(() => {
  return channels.value
    .filter(channel => channel.selected)
    .map(channel => channel.id.toLowerCase());
});

// Tilføj ref for video URL
const selectedVideoUrl = ref(null);

// Tilføj en watch for at se når design type ændres
watch(selectedTypes, (newTypes) => {
  console.log('Design type ændret:', newTypes);
}, { deep: true });

// Tilføj watch for debugging
watch(() => selectedTypes.value, (newTypes) => {
  console.log('Selected Types ændret:', newTypes);
}, { deep: true });

watch(() => types.value, (newTypes) => {
  console.log('Types ændret:', newTypes);
}, { deep: true });

const isGeneratingVideo = ref(false);
const generationStatusMessage = ref('');
const videoUrl = ref('');

const hasEnoughImages = (car) => {
  if (!car || !car.attachments) return false;
  
  // Tæl antallet af billeder i attachments
  const imageCount = Object.keys(car.attachments)
    .filter(key => key.startsWith('image_'))
    .length;
    
  return imageCount >= 6;
};

// Opdater handleVideoGeneration funktionen
const handleVideoGeneration = async () => {
  // Tjek først om der er nok billeder
  if (!hasEnoughImages(selectedCar.value)) {
    showToast(
      'Der skal være mindst 6 billeder for at generere en video. Denne bil har ikke nok billeder.',
      'Advarsel',
      'warning'
    );
    return;
  }

  isGeneratingVideo.value = true;
  videoUrl.value = null;
  
  try {
    // Hent brugerdata hvis det ikke allerede er tilgængeligt
    let userData = null;
    try {
      const userDocRef = doc(db, 'users', userId.value);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        userData = userDoc.data();
        console.log('Hentet brugerdata:', userData);
      }
    } catch (error) {
      console.warn('Kunne ikke hente brugerdata:', error);
    }

    // Konstruer det komplette bil-objekt med brugerdata
    const completeCarData = {
      ...selectedCar.value,
      dealerId: dealerId.value,
      userId: userId.value,
      role: userRole.value,
      userData: {
        ...selectedCar.value?.userData,
        ...userData,
        name: userData?.name || selectedCar.value?.userData?.name,
        companyName: userData?.companyName || selectedCar.value?.userData?.companyName,
        address: userData?.address || selectedCar.value?.userData?.address,
        phone: userData?.phone || selectedCar.value?.userData?.phone,
        email: userData?.email || selectedCar.value?.userData?.email
      }
    };

    // Konverter attachments til images array
    if (completeCarData.attachments) {
      completeCarData.images = Object.entries(completeCarData.attachments)
        .filter(([key]) => key.startsWith('image_'))
        .map(([_, value]) => value)
        .filter(Boolean);
    }

    console.log('Sender følgende data til generateVideo:', completeCarData);

    const result = await generateVideo(
      completeCarData,
      selectedDesign.value?.uuid || "df6477a8-2ece-4cf1-a4d9-b548d9a6ad83==29bbd140-695d-4304-989e-e190ba03f149",
      (status) => {
        switch(status.status) {
          case 'INITIATING':
            generationStatusMessage.value = status.message;
            break;
            
          case 'PROCESSING':
            generationStatusMessage.value = status.message;
            break;
            
          case 'COMPLETED':
            generationStatusMessage.value = status.message;
            // Vent kort før vi skifter visning
            setTimeout(() => {
              if (status.videoUrl) {
                videoUrl.value = status.videoUrl;
                previewImageUrl.value = status.thumbnailUrl || status.videoUrl;
                isGeneratingVideo.value = false;
              }
            }, 500);
            break;
            
          case 'ERROR':
            generationStatusMessage.value = status.message;
            showToast(status.message, 'Fejl', 'danger');
            isGeneratingVideo.value = false;
            break;
        }
      }
    );

    // Hvis vi får et direkte resultat
    if (result?.videoUrl) {
      videoUrl.value = result.videoUrl;
      previewImageUrl.value = result.thumbnailUrl || result.videoUrl;
      isGeneratingVideo.value = false;
    }
  } catch (error) {
    console.error('Fejl ved video generering:', error);
    generationStatusMessage.value = 'Der opstod en fejl under video generering';
    showToast('Fejl ved generering af video', 'Fejl', 'danger');
    isGeneratingVideo.value = false;
  }
};

const handleVideoError = (event) => {
  console.error('Fejl ved afspilning af video:', event);
  showToast('Kunne ikke afspille videoen', 'Fejl', 'danger');
};

// Tilføj nye refs og computed properties
const selectedPostingType = ref('');
const defaultPostingType = computed(() => {
  const isVideo = types.value.find(t => t.selected)?.id === 'video';
  const isFacebook = channels.value.find(c => c.selected)?.id === 'facebook';
  
  return isFacebook
    ? (isVideo ? "facebookVideoLink" : "facebookLinkImage")
    : (isVideo ? "instagramVideo" : "InstagramPost");
});

// Computed property for tilgængelige posting types
const availablePostingTypes = computed(() => {
  const isVideo = types.value.find(t => t.selected)?.id === 'video';
  const isFacebook = channels.value.find(c => c.selected)?.id === 'facebook';
  
  if (isFacebook) {
    return isVideo ? [
      {
        id: 'facebookStory',
        name: 'Facebook Story',
        description: 'Del som en Facebook Story'
      },
      {
        id: 'facebookReels',
        name: 'Facebook Reels',
        description: 'Del som Facebook Reels'
      }
    ] : [
      {
        id: 'facebookStory',
        name: 'Facebook Story',
        description: 'Del som en Facebook Story'
      },
      {
        id: 'facebookCarousel',
        name: 'Carousel',
        description: 'Del som et billedkarrusel'
      },
      {
        id: 'facebookGalleryPost',
        name: 'Galleri',
        description: 'Del som et billedgalleri'
      }
    ];
  } else {
    return isVideo ? [
      {
        id: 'instagramReels',
        name: 'Instagram Reels',
        description: 'Del som Instagram Reels'
      },
      {
        id: 'instagramStoryVideo',
        name: 'Instagram Story',
        description: 'Del som Instagram Story'
      }
    ] : [
      {
        id: 'instagramCarousel',
        name: 'Carousel',
        description: 'Del som et billedkarrusel'
      },
      {
        id: 'instagramStoryImage',
        name: 'Instagram Story',
        description: 'Del som Instagram Story'
      }
    ];
  }
});

// Computed property for at vise posting type muligheder
const showPostingTypeOptions = computed(() => {
  return currentStep.value === 5 && 
         channels.value.some(c => c.selected) && 
         types.value.some(t => t.selected);
});

// Metoder til håndtering af posting type
const selectPostingType = (type) => {
  selectedPostingType.value = type.id;
  showPostingTypesExpanded.value = false; // Luk efter valg
};

const resetPostingType = () => {
  selectedPostingType.value = defaultPostingType.value;
};

// Tilføj nye refs og computed properties
const showPostingTypesExpanded = ref(false);

const getSelectedTypeName = computed(() => {
  const selected = availablePostingTypes.value.find(
    type => type.id === selectedPostingType.value
  );
  return selected ? selected.name : 'Standard opslag';
});

// Tilføj nye metoder
const togglePostingTypes = () => {
  showPostingTypesExpanded.value = !showPostingTypesExpanded.value;
};

// Tilføj denne funktion til at generere default datetime
const getDefaultScheduledDateTime = () => {
  const now = new Date();
  now.setMinutes(now.getMinutes() + 30); // Tilføj 30 minutter
  
  // Format til YYYY-MM-DDThh:mm
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

// Opdater scheduledDateTime ref med default værdi
const scheduledDateTime = ref(getDefaultScheduledDateTime());

const handleStatusSelect = (option) => {
  selectedStatus.value = option.label;
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

.card-body {
  padding: 0 !important;
  min-height: 500px;
  display: flex;
  align-items: flex-start;
  overflow: visible !important; /* Tillad indhold at gå ud over containeren */
  max-height: none !important;
  height: auto !important;
  position: relative !important; /* Tilføj position relative */
}

.invisible-card {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
}

.invisible-card::before {
  content: none !important;
}

.invisible-card::after {
  content: none !important;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: auto;
  padding: 0;
  overflow: visible !important; /* Tillad indhold at gå ud over containeren */
  position: relative !important; /* Tilføj position relative */
}

.gradient-text {
  background-image: linear-gradient(0deg, hsl(0, 0%, 100%), hsl(0, 0%, 100%)), linear-gradient(90.1deg, #ad4f20 .08%, #ffffff 98.91%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: clamp(38px, 7vw, 46px); /* Øget skriftstørrelse her */
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
}

.form-container {
  width: 100%;
  max-width: 400px;
  position: relative !important; /* Tilføj position relative */
  z-index: 10 !important; /* Højere z-index for at være over andet indhold */
}

.form-label {
  font-weight: bolder;
  font-size: 16px;
}

.select-button-group {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
  max-width: 600px;
  position: relative !important; /* Tilføj position relative */
  z-index: 10 !important; /* Højere z-index for at være over andet indhold */
}

.form-control {
  flex: 1 1 200px;
}

.btn {
  white-space: nowrap;
  flex: 0 0 auto;
}

@media (max-width: 768px) {
  .content-container {
    flex-direction: column;
    gap: 1rem;
  }

  .form-container {
    width: 100%;
    max-width: none;
  }
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.fas {
  font-family: "Font Awesome 5 Free";
}

.mr-2 {
  margin-right: 0.5rem;
}

.row.align-items-center {
  flex-wrap: nowrap;
}

.gradient-text {
  white-space: nowrap;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .row.align-items-center {
    flex-direction: column;
    align-items: flex-start !important;
  }
  
  .gradient-text {
    margin-bottom: 1rem;
  }
}

.hoveredImg {
  transition: all 0.3s ease;
}

.hoveredImg:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.hoveredImg.selected {
  box-shadow: 0 0 15px rgba(0, 123, 255, 0.5);
  border: 2px solid #007bff;
}

.mainCardClass {
  margin-bottom: 20px;
}

.card-body {
  padding: 1rem;
}

.form-check-input {
  width: 3rem;
  height: 1.5rem;
}

.btn-md {
  padding: 0.65rem 1.25rem;
}

.gradient-text {
  font-size: clamp(24px, 5vw, 38px);
}

.card {
  background-color: #303030;
  border-radius: 0;
  margin-bottom: 0;
  border: none;
}

.card-header {
  background-color: #303030;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.card-body {
  padding: 1rem;
}

.designGroup {
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.designGroup.active {
  background-color: rgba(255, 255, 255, 0.1);
}

.form-control {
  background-color: #424242;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 3.5rem !important;
}

.btn {
  margin: 0 5px;
}

.custom-switch .form-check-input {
  height: 1.5rem;
  width: 3rem;
}

.hoveredImg {
  transition: all 0.3s ease;
}

.hoveredImg:hover {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.1);
}

.mainCardClass {
  margin-bottom: 20px;
}

.card {
  border-radius: 15px;
  overflow: hidden;
}

.card-body {
  padding: 0 !important;
}

.card img {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.card .d-flex.justify-content-between {
  padding: 15px;
}

.card p {
  font-size: 14px;
  margin-bottom: 0;
}

.btn-md {
  font-size: 0.875rem;
  padding: 0.65rem 1.25rem;
}

.row_gap_5 {
  row-gap: 1.25rem;
}

.card-body img {
  object-fit: cover;
  border-radius: 8px;
}

.button {
  font-size: 0.65rem !important;
  line-height: 1.2 !important;
  padding: 0.15rem 0.3rem !important;
}

.designs-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
  height: auto;
  overflow: visible;
}


.design-card.selected,
.option-container.selected {
  border: 2px solid #2dce89;
  box-shadow: 0 0 10px rgba(45, 206, 137, 0.5);
}

.option-container {
  display: flex;
  align-items: center;
  padding: 10px 60px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.option-container:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.form-label {
  margin-bottom: 0;
  font-weight: 500;
}

.design-card {
  background-color: #2c2c2c;
  border-radius: 8px;
  overflow: hidden;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;/* 250px for billedet + 10px padding (5px på hver side) */
  height: auto; /* Lad højden tilpasse sig indholdet */
  margin: 0 auto; /* Centrerer kortet hvis det er i et grid */
}

.design-image {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}

.designGroup {
  cursor: pointer;
  transition: color 0.3s ease;
}

.designGroup:hover {
  color: #5e72e4;
}

.designGroup.active {
  color: #5e72e4;
  font-weight: bold;
}

.btn {
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.btn-md {
  font-size: 0.875rem;
  padding: 0.65rem 1.25rem;
}

.gradient-text {
  font-size: clamp(24px, 5vw, 38px);
}

.card-header h4 {
  font-size: 1.25rem;
}

.form-control {
  font-size: 0.875rem;
}

/* Tilføj disse styles for at ensrette knapperne */
.btn-outline-secondary,
.bg-gradient-success,
.bg-gradient-dark {
  border-radius: 0.5rem;
  transition: all 0.15s ease-in-out;
}

.btn-outline-secondary:hover,
.bg-gradient-success:hover,
.bg-gradient-dark:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

/* Ensret mellemrum mellem knapper */
.btn + .btn {
  margin-left: 0.5rem;
}

/* Juster alignmentet af knapper i header */
.d-flex.justify-content-between {
  padding: 1rem 0;
}

/* Ensret styling af kort */
.card {
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.card-header {
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.design-card.selected {
  border: 2px solid #5e72e4;
  box-shadow: 0 0 10px rgba(94, 114, 228, 0.5);
}

.custom-switch {
  width: 3rem;
  height: 1.5rem;
}

.form-container {
  width: 100%;
  max-width: 450px;
}



.option-container {
  display: flex;
  align-items: center;
  padding: 10px 60px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.option-container:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.form-label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-weight: 500;
  cursor: pointer;
}

.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #2dce89;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
}

.custom-checkbox:checked {
  background-color: #2dce89;
}

.custom-checkbox:checked::after {
  content: '\2714';
  font-size: 14px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  height: 30px;
}

.btn-md {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.bg-gradient-primary {
  background-image: linear-gradient(310deg, #7928CA 0%, #FF0080 100%);
}

.bg-gradient-success {
  background-image: linear-gradient(310deg, #2dce89 0%, #26c6da 100%);
}

.btn-outline-secondary {
  border-width: 2px;
}

.btn-outline-secondary:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.d-flex.justify-content-between {
  margin-top: 2rem;
}

.design-card.selected,
.option-container.selected,
.channel-option.selected,
.media-type-option.selected {
  border: 2px solid #5e72e4;
  box-shadow: 0 0 10px rgba(94, 114, 228, 0.5);
}

/* Tilføj disse nye styles hvis de ikke allerede eksisterer */
.channel-option,
.media-type-option {
  border-radius: 1rem;
  height: 27.6rem; /* Øget fra 23rem (20% større) */
  width: 20.4rem; /* Øget fra 17rem (20% større) */
  max-width: 20.4rem; /* Øget fra 17rem (20% større) */
  margin: 0.5rem;
  padding: 0;
  background: #303030;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.125);
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.channel-type-container .channel-option,
.channel-type-container .media-type-option {
  border-radius: 1rem !important;
  height: 27.6rem !important;
  width: 20.4rem !important;
  max-width: 20.4rem !important;
  margin: 0.5rem !important;
  padding: 0 !important;
  background: #303030 !important;
  cursor: pointer !important;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  transition: all 0.3s ease !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.channel-type-container .channel-option.selected,
.channel-type-container .media-type-option.selected {
  border: 2px solid #5e72e4 !important;
  box-shadow: 0 0 15px rgba(94, 114, 228, 0.7) !important;
}

.avatar {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.avatar img {
  height: 22.8rem; /* Øget fra 19rem (20% større) */
  width: 15.6rem; /* Øget fra 13rem (20% større) */
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.option-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 60px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.option-container:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.option-container .form-label {
  margin-bottom: 0;
  font-weight: 500;
  color: white; /* Gør teksten hvid */
}

.option-container.selected {
  border: 2px solid #5e72e4;
  box-shadow: 0 0 15px rgba(94, 114, 228, 0.7);
}

.option-wrapper {
  margin-top: 5rem; /* Positiv værdi for at flytte indholdet ned */
}

.option-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 60px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px; /* Matcher max-width af billedet */
}

.shuffle-button,
.ai-assisted-button,
.close-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  color: white;
}

.shuffle-button {
  background-color: #4CAF50;
}

.ai-assisted-button {
  background-color: #2196F3;
}

.close-button {
  background-color: #f44336;
}

.shuffle-button:hover,
.ai-assisted-button:hover,
.close-button:hover {
  opacity: 0.8;
}

.option-wrapper {
  display: flex;
  flex-direction: column;
}

.option-wrapper .d-flex {
  gap: 1rem;
}

.option-container {
  flex: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  min-width: 180px; /* Juster denne værdi efter behov */
  height: 60px; /* Fast højde for at sikre ensartethed */
}

.form-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 0.9rem; /* Juster skriftstørrelsen efter behov */
}

.channel-type-container {
  display: flex !important;
  justify-content: center !important;
  gap: 60px !important; /* Reduceret fra 120px */
  max-width: 900px !important; /* Reduceret fra 1200px */
  margin: 0 auto !important;
  padding: 1.5rem 0 !important; /* Reduceret padding */
  height: auto !important;
  overflow: visible !important;
}

.channel-type-container .channel-section,
.channel-type-container .type-section {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.section-title {
  font-size: 0.9rem !important;
  letter-spacing: 0.5px !important;
  text-transform: uppercase !important;
  color: rgba(255, 255, 255, 0.7) !important;
  margin-bottom: 1.5rem !important;
  font-weight: 500 !important;
}

.channel-type-container .channel-option,
.channel-type-container .media-type-option {
  border-radius: 12px !important; /* Reduceret fra 1rem */
  height: 16rem !important; /* Reduceret fra 20.6rem */
  width: 12rem !important; /* Reduceret fra 14.4rem */
  background: rgba(255, 255, 255, 0.03) !important; /* Reduceret fra 0.05 */
  cursor: pointer !important;
  border: 1px solid rgba(255, 255, 255, 0.08) !important; /* Reduceret fra 0.1 */
  transition: all 0.2s ease-in-out !important; /* Reduceret fra 0.3s */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: relative !important; /* Tilføjet */
  overflow: hidden !important; /* Tilføjet */
}

.channel-type-container .channel-option:hover,
.channel-type-container .media-type-option:hover {
  background: rgba(255, 255, 255, 0.05) !important; /* Reduceret fra 0.1 */
  transform: translateY(-2px) !important; /* Reduceret fra 2px */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important; /* Reduceret fra 4px 12px 0.2 */
}

.channel-type-container .channel-option.selected,
.channel-type-container .media-type-option.selected {
  background: rgba(94, 114, 228, 0.1) !important; /* Reduceret fra 0.05 */
  border: 2px solid rgba(94, 114, 228, 0.5) !important; /* Reduceret fra 2px solid 0.5 */
  box-shadow: 0 0 15px rgba(94, 114, 228, 0.2) !important; /* Reduceret fra 4px 12px 0.2 */
}

.channel-type-container .channel-option .avatar,
.channel-type-container .media-type-option .avatar {
  height: 14rem !important; /* Reduceret fra 19rem */
  width: 10rem !important; /* Reduceret fra 13rem */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 1rem !important; /* Tilføjet */
}

.channel-type-container .channel-option .avatar img,
.channel-type-container .media-type-option .avatar img {
  max-height: 100% !important;
  max-width: 100% !important;
  object-fit: contain !important;
  opacity: 0.9 !important; /* Reduceret fra 1 */
  transition: opacity 0.2s ease !important; /* Reduceret fra 0.3s */
}

.channel-type-container .channel-option:hover .avatar img,
.channel-type-container .media-type-option:hover .avatar img {
  opacity: 1 !important; /* Reduceret fra 1 */
}

.options-row {
  display: flex;
  justify-content: center;
  gap: 1.5rem; /* Reduceret fra 20px */
  margin-top: 0.5rem;
}

/* Tilføj responsivt design */
@media (max-width: 768px) {
  .channel-type-container {
    flex-direction: column !important;
    gap: 2rem !important;
  }

  .channel-type-container .channel-option,
  .channel-type-container .media-type-option {
    height: 14rem !important;
    width: 10rem !important;
  }

  .channel-type-container .channel-option .avatar,
  .channel-type-container .media-type-option .avatar {
    height: 12rem !important;
    width: 8rem !important;
  }
}

.avatar {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.avatar img {
  height: 22.8rem; /* Øget fra 19rem (20% større) */
  width: 15.6rem; /* Øget fra 13rem (20% større) */
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.option-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 60px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.option-container:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.option-container .form-label {
  margin-bottom: 0;
  font-weight: 500;
  color: white; /* Gør teksten hvid */
}

.option-container.selected {
  border: 2px solid #5e72e4;
  box-shadow: 0 0 15px rgba(94, 114, 228, 0.7);
}

.option-wrapper {
  margin-top: 5rem; /* Positiv værdi for at flytte indholdet ned */
}

.option-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 60px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.posting-types-minimal-container {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.posting-type-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  background: transparent;
  transition: background-color 0.2s ease;
}

.posting-type-header:hover {
  background: rgba(255, 255, 255, 0.03);
}

.header-content {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.header-title {
  font-size: 0.9rem;
  color: #999;
  font-weight: 500;
}

.selected-type {
  font-size: 0.95rem;
  color: #fff;
  font-weight: 500;
}

.fa-chevron-down {
  color: #999;
  transition: transform 0.3s ease;
}

.fa-chevron-down.rotated {
  transform: rotate(180deg);
}

.posting-types-content {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.2);
}

.posting-types-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.posting-type-option {
  padding: 0.50rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.posting-type-option:hover {
  background: rgba(255, 255, 255, 0.03);
}

.posting-type-option.selected {
  border-color: #5e72e4;
  background: rgba(94, 114, 228, 0.05);
}

.type-name {
  display: block;
  font-size: 0.9rem;
  color: #fff;
  margin-bottom: 0.25rem;
}

.type-description {
  display: block;
  font-size: 0.8rem;
  color: #999;
}

.reset-button {
  background: transparent;
  border: none;
  color: #999;
  font-size: 0.8rem;
  padding: 0.5rem 0;
  margin-top: 1rem;
  cursor: pointer;
  transition: color 0.2s ease;
  width: 100%;
  text-align: center;
}

.reset-button:hover {
  color: #fff;
}



.post-cars-component {
  padding: 0 !important;
  border-radius: 0 !important;
  min-height: 370px;
  display: flex;
  align-items: center;
  overflow: visible !important; /* Tillad indhold at gå ud over containeren */
  position: relative !important; /* Tilføj position relative */
}

.scheduling-section {
  text-align: center;
  margin-top: 2rem;
}

.scheduling-title {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  opacity: 0.9;
}

.form-control {
  margin-top: 0.5rem !important;
}

.action-controls {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  overflow: visible; /* Sikrer at indhold ikke bliver afskåret */
  position: relative; /* Tilføj position relative */
  z-index: 1; /* Giv den en z-index */
  padding: 2px; /* Tilføj lidt padding for at undgå afskæring */
}

.posting-types-minimal-container {
  flex: 1;
  min-width: 0;
  position: relative; /* Tilføj position relative */
  z-index: 1; /* Samme z-index som parent */
}


/* Tilføj nye styles for ikoner */
.social-icon {
  font-size: 3rem !important;
  margin-bottom: 1rem !important;
  color: rgba(255, 255, 255, 0.9) !important;
  transition: all 0.3s ease !important;
}

.media-icon {
  font-size: 3rem !important;
  margin-bottom: 1rem !important;
  color: rgba(255, 255, 255, 0.9) !important;
  transition: all 0.3s ease !important;
}

.icon-label {
  font-size: 1rem !important;
  color: rgba(255, 255, 255, 0.7) !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
  margin-top: 0.5rem !important;
  font-weight: 500 !important;
}

/* Opdater hover effekter */
.channel-option:hover .social-icon,
.media-type-option:hover .media-icon {
  transform: scale(1.1) !important;
  color: #fff !important;
}

.channel-option.selected .social-icon,
.media-type-option.selected .media-icon {
  color: #5e72e4 !important;
  transform: scale(1.1) !important;
}

/* Opdater container styles */
.channel-type-container .channel-option,
.channel-type-container .media-type-option {
  height: 12rem !important; /* Reduceret højde */
  width: 10rem !important; /* Reduceret bredde */
  background: rgba(255, 255, 255, 0.03) !important;
  border: 1px solid rgba(255, 255, 255, 0.08) !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  transition: all 0.2s ease-in-out !important;
  cursor: pointer !important;
  padding: 2rem !important;
}

.channel-type-container .channel-option:hover,
.channel-type-container .media-type-option:hover {
  background: rgba(255, 255, 255, 0.05) !important;
  transform: translateY(-2px) !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
}

.channel-type-container .channel-option.selected,
.channel-type-container .media-type-option.selected {
  background: rgba(94, 114, 228, 0.1) !important;
  border: 2px solid rgba(94, 114, 228, 0.5) !important;
  box-shadow: 0 0 15px rgba(94, 114, 228, 0.2) !important;
}

/* Opdater avatar container */
.channel-type-container .channel-option .avatar,
.channel-type-container .media-type-option .avatar {
  height: auto !important;
  width: auto !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
}

/* Responsivt design */
@media (max-width: 768px) {
  .channel-type-container .channel-option,
  .channel-type-container .media-type-option {
    height: 10rem !important;
    width: 8rem !important;
    padding: 1.5rem !important;
  }

  .social-icon,
  .media-icon {
    font-size: 2.5rem !important;
  }

  .icon-label {
    font-size: 0.9rem !important;
  }
}

.next-button {
  white-space: nowrap;
  min-width: 100px;
  transition: all 0.3s ease;
}

.next-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .select-button-group {
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .next-button {
    width: 100%;
  }
}
</style>