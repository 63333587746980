export function generatePostData({
  selectedCar,
  previewImageUrl,
  previewText,
  selectedStatus,
  channels,
  userMetaSettings,
  userId,
  dealerId,
  publishLater,
  scheduledDateTime,
  isVideo,
  postingType,
  status = 'ready'
}) {
  const currentDate = new Date();
  
  const selectedChannel = channels.find(c => c.selected);
  const isFacebook = selectedChannel?.id === 'facebook';
  const caseId = `${selectedChannel?.id}-${selectedCar.id}_${currentDate.toISOString().split('T')[0].replace(/-/g, '_')}`;

  if (status !== 'pending' && (!previewImageUrl || !previewText)) {
    throw new Error('Billede og tekst er påkrævet');
  }

  const defaultPostingType = isFacebook
    ? (isVideo ? "facebookVideoLink" : "facebookLinkImage")
    : (isVideo ? "instagramVideo" : "InstagramPost");

  return {
    facebook: userMetaSettings?.facebookPageId,
    instagram: userMetaSettings?.instagramBusinessAccountId,
    pageAccessToken: userMetaSettings?.page_access_token,
    subject: selectedStatus,
    images: previewImageUrl ? [previewImageUrl] : [],
    text: previewText || '',
    caseId: caseId,
    emne: selectedStatus,
    dealerId: dealerId,
    id: userId,
    postInst: !isFacebook,
    postFB: isFacebook,
    caseUrl: selectedCar.url,
    adDays: null,
    adSpend: null,
    plannedPost: publishLater,
    postingType: postingType || defaultPostingType,
    isActive: true,
    run: false,
    status: status,
    publishDate: publishLater ? scheduledDateTime.split('T')[0] : currentDate.toISOString().split('T')[0],
    publishTime: publishLater ? {
      HH: scheduledDateTime.split('T')[1].split(':')[0],
      MM: scheduledDateTime.split('T')[1].split(':')[1]
    } : {
      HH: currentDate.getHours().toString().padStart(2, '0'),
      MM: currentDate.getMinutes().toString().padStart(2, '0')
    },
    children: [{
      images: previewImageUrl ? [previewImageUrl] : [],
      headline: selectedCar.headline || '',
      caseUrl: selectedCar.url,
      caseId: caseId
    }],
    imageUrl: previewImageUrl ? [previewImageUrl] : []
  };
}

const createPost = async () => {
  try {
    await Promise.all([
      waitForPreviewImage(),
      waitForPreviewText()
    ]);

    const postData = generatePostData({
      selectedCar: selectedCar.value,
      previewImageUrl: previewImageUrl.value,
      previewText: previewText.value,
      selectedStatus: selectedStatus.value,
      channels: channels.value,
      userMetaSettings: userMetaSettings.value,
      userId: getAuth().currentUser?.uid,
      dealerId: "46964",
      publishLater: publishLater.value,
      scheduledDateTime: scheduledDateTime.value,
      isVideo: isVideo.value,
      postingType: postingType.value
    });

    const docRef = await addDoc(collection(db, 'posts'), postData);
    
    showToast('Opslag oprettet succesfuldt', 'Success', 'success');
    resetForm();
    
  } catch (error) {
    console.error('Fejl ved oprettelse af opslag:', error);
    showToast(
      `Fejl ved oprettelse af opslag: ${error.message}`, 
      'Fejl', 
      'danger'
    );
  }
};

const waitForPreviewImage = () => {
  return new Promise((resolve, reject) => {
    if (previewImageUrl.value) {
      resolve();
    } else {
      reject(new Error('Billede mangler'));
    }
  });
};

const waitForPreviewText = () => {
  return new Promise((resolve, reject) => {
    if (previewText.value) {
      resolve();
    } else {
      reject(new Error('Tekst mangler'));
    }
  });
};