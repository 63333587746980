<template>
  <div class="dropdown-container" ref="dropdownRef">
    <button 
      @click="toggleDropdown" 
      class="dropdown-button"
      :class="{ 'active': isOpen }"
    >
      <slot>Menu</slot>
      <i 
        class="fas fa-chevron-down dropdown-icon"
        :class="{ 'rotated': isOpen }"
      ></i>
    </button>

    <div 
      v-show="isOpen" 
      class="dropdown-content"
      :class="{ 'is-open': isOpen }"
      ref="dropdownContentRef"
    >
      <template v-if="options && options.length">
        <button
          v-for="(option, index) in options"
          :key="option.label"
          @click="handleOptionClick(option)"
          class="dropdown-item"
          :style="{ '--delay': `${index * 0.05}s` }"
        >
          {{ option.label }}
        </button>
      </template>
      <div v-else class="dropdown-item no-options">
        Ingen muligheder
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const props = defineProps({
  options: {
    type: Array,
    default: () => []
  }
});

const emit = defineEmits(['select']);
const isOpen = ref(false);
const dropdownRef = ref(null);
const dropdownContentRef = ref(null);

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const handleOptionClick = (option) => {
  emit('select', option);
  isOpen.value = false;
};

const handleClickOutside = (event) => {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
    isOpen.value = false;
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<style scoped>
.dropdown-container {
  position: relative;
  width: 100%;
  z-index: 1000;
}

.dropdown-button {
  width: 100%;
  padding: 0.75rem 1rem;
  background: rgba(17, 17, 17, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.2s ease;
  backdrop-filter: blur(8px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dropdown-button:hover {
  background: rgba(17, 17, 17, 0.8);
  transform: translateY(-1px);
}

.dropdown-button.active {
  border-color: rgba(94, 114, 228, 0.5);
  box-shadow: 0 0 0 2px rgba(94, 114, 228, 0.2);
}

.dropdown-icon {
  font-size: 0.875rem;
  margin-left: 0.5rem;
  transition: transform 0.2s ease;
}

.dropdown-icon.rotated {
  transform: rotate(180deg);
}

.dropdown-content {
  position: fixed !important;
  min-width: 200px;
  width: auto !important;
  max-width: 90vw;
  background: rgba(17, 17, 17, 0.95);
  border-radius: 12px;
  padding: 0.5rem;
  backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(-8px);
  transition: all 0.2s ease;
  pointer-events: none;
  visibility: hidden;
}

.dropdown-content.is-open {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
  visibility: visible;
}

.dropdown-item {
  width: 100%;
  padding: 0.75rem 1rem;
  background: transparent;
  border: none;
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.875rem;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
  opacity: 0;
  animation: fadeIn 0.2s ease forwards;
  animation-delay: var(--delay);
}

.dropdown-item:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateX(4px);
}

.no-options {
  color: rgba(255, 255, 255, 0.5);
  cursor: default;
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style> 