<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "@/firebase";
import { useRouter } from "vue-router";
import { whiteLabelService } from '@/services/whiteLabelService';
import { calendarService } from '@/services/calendarService';
import { collection, getDocs, query, where } from "firebase/firestore";

// Argon components
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";

const ROLE_ADMIN = 1;
const ROLE_USER = 3;
const ROLE_MANAGER = 2;

const email = ref("");
const password = ref("");
const rememberMe = ref(false);
const errorMessage = ref("");
const isLoading = ref(false);
const router = useRouter();
const store = useStore();

// Tilføj unsubscribe collection
let unsubscribes = [];

// Funktion til at tilføje unsubscribe handlers
const addUnsubscribe = (unsubscribe) => {
  unsubscribes.push(unsubscribe);
};

// Funktion til at fjerne alle listeners
const removeAllListeners = () => {
  unsubscribes.forEach(unsubscribe => {
    if (typeof unsubscribe === 'function') {
      try {
        unsubscribe();
      } catch (error) {
        console.warn('Fejl ved unsubscribe:', error);
      }
    }
  });
  unsubscribes = [];
};

// Ny funktion til at anvende white label indstillinger
const applyWhiteLabelSettings = async (userData) => {
  try {
    let settings;
    if (userData.role === 2) {
      // Admin bruger - hent egne indstillinger
      settings = await whiteLabelService.getAdminSettings(userData.id);
    } else if (userData.role === 3 && userData.createdBy) {
      // Normal bruger - hent admin's indstillinger
      settings = await whiteLabelService.getAdminSettings(userData.createdBy);
    }

    if (settings) {
      // Opdater favicon
      const favicon = document.querySelector('link[rel="icon"]');
      if (!favicon) {
        const newFavicon = document.createElement('link');
        newFavicon.rel = 'icon';
        newFavicon.type = 'image/png';
        if (settings.logo) {
          newFavicon.href = settings.logo;
        }
        document.head.appendChild(newFavicon);
      } else if (settings.logo) {
        favicon.href = settings.logo;
      }

      // Opdater document title
      if (settings.companyInfo?.name) {
        document.title = settings.companyInfo.name;
      }

      // Opdater CSS variabler
      document.documentElement.style.setProperty('--primary-color', settings.theme?.primaryColor || '#5e72e4');
      document.documentElement.style.setProperty('--secondary-color', settings.theme?.secondaryColor || '#8392ab');
      document.documentElement.style.setProperty('--accent-color', settings.theme?.accentColor || '#2dce89');
    }
  } catch (error) {
    console.error('Fejl ved anvendelse af white label indstillinger:', error);
  }
};

const signIn = async () => {
  isLoading.value = true;
  errorMessage.value = "";
  
  try {
    // Fjern eventuelle eksisterende listeners først
    removeAllListeners();

    const persistenceType = rememberMe.value
      ? browserLocalPersistence
      : browserSessionPersistence;
    await setPersistence(auth, persistenceType);

    // Login med Firebase
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email.value,
      password.value
    );
    
    // Hent brugerdata fra Firestore
    const userDocRef = doc(db, "users", userCredential.user.uid);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      throw new Error("Brugerdata ikke fundet");
    }

    const userData = userDoc.data();
    console.log('User role:', userData.role);

    // Start pre-loading af kalender data
    if (userData.role === 3) {
      // For almindelige brugere, pre-load deres egen kalender
      calendarService.preloadCalendarData(userCredential.user.uid);
    } else if (userData.role <= 2) {
      // For admin og superadmin, pre-load deres brugeres kalendere
      const usersQuery = query(
        collection(db, "users"),
        where("createdBy", "==", userCredential.user.uid)
      );
      const usersSnapshot = await getDocs(usersQuery);
      usersSnapshot.docs.forEach(doc => {
        calendarService.preloadCalendarData(doc.id);
      });
    }

    // Opdater brugerdata i store først
    const essentialUserData = {
      id: userCredential.user.uid,
      role: userData.role,
      isOnline: true,
      name: userData.name,
      email: userData.email,
      createdBy: userData.createdBy,
      adminSettings: userData.adminSettings
    };

    // Gem bruger i store
    await store.dispatch("setCurrentUser", essentialUserData);
    console.log('Setting user data:', essentialUserData);

    // Opdater online status
    await updateDoc(userDocRef, { isOnline: true });

    // Anvend white label indstillinger
    await applyWhiteLabelSettings(essentialUserData);

    // Redirect baseret på rolle
    if (userData.role <= 2) {
      await router.push("/pages/tables");
    } else {
      await router.push("/profile");
    }

  } catch (error) {
    console.error('Login fejl:', error);
    errorMessage.value = "Forkert email eller adgangskode";
  } finally {
    isLoading.value = false;
  }
};

// Eksporter funktionerne så de kan bruges i andre komponenter
defineExpose({
  addUnsubscribe,
  removeAllListeners
});
</script>

<template>
  <main class="signin-container">
    <div class="login-form">
      <form role="form" @submit.prevent="signIn">
        <div class="form-group">
          <argon-input
            id="email"
            v-model="email"
            type="email"
            placeholder="Email"
            name="email"
            size="lg"
            aria-label="Email"
          />
        </div>
        <div class="form-group">
          <argon-input
            id="password"
            v-model="password"
            type="password"
            placeholder="Adgangskode"
            name="password"
            size="lg"
            aria-label="Adgangskode"
          />
        </div>

        <div v-if="errorMessage" class="error-message">
          {{ errorMessage }}
        </div>

        <div class="login-actions">
          <argon-button
            :disabled="isLoading"
            type="submit"
            variant="gradient"
            color="success"
            full-width
            size="lg"
          >
            <span v-if="!isLoading">Log ind</span>
            <span v-else>Logger ind...</span>
          </argon-button>
        </div>
      </form>
    </div>
  </main>
</template>

<style scoped>
.signin-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #16181b;
}

.login-form {
  background: rgba(22, 24, 27, 0.95);
  border-radius: 12px;
  padding: 2rem;
  width: 100%;
  max-width: 320px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  margin: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group:last-of-type {
  margin-bottom: 1.5rem;
}

:deep(.form-control) {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #fff;
  transition: all 0.3s ease;
}

:deep(.form-control:focus) {
  background: rgba(255, 255, 255, 0.08);
  border-color: #2dce89;
  box-shadow: none;
  color: #fff;
}

:deep(.form-control::placeholder) {
  color: rgba(255, 255, 255, 0.5);
}

.error-message {
  color: #ff4444;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  text-align: center;
}

.login-actions {
  margin-top: 0.5rem;
}

:deep(.btn) {
  border-radius: 8px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

/* Responsive Adjustments */
@media (max-width: 480px) {
  .login-form {
    margin: 1rem;
    max-width: calc(100% - 2rem);
  }
}
</style>
