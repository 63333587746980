import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/firebase';
import store from '@/store';

// Cache til white label indstillinger
let whitelabelCache = null;
let lastFetchTimestamp = null;
let initializationPromise = null;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutter
const STORAGE_KEY = 'whitelabel_settings';

// Standard indstillinger - bruges KUN hvis brugeren er admin
const ADMIN_DEFAULT_SETTINGS = {
  browserSettings: {
    title: 'Auto Family',
    favicon: 'https://firebasestorage.googleapis.com/v0/b/toft-d4f39.appspot.com/o/favAF.png?alt=media&token=b88527bc-b903-47fb-8461-2cb378d39f9b'
  },
  theme: {
    backgroundColor: '#16181b',
    sidenavColor: 'linear-gradient(122deg, #1568db, #05c2ed)',
    cardColor: '#202124',
    textColor: 'rgba(255, 255, 255, 0.8)',
    navWrapperColor: '#2c2c3b',
  }
};

// Funktion til at gemme indstillinger i localStorage
function saveToLocalStorage(settings) {
  try {
    localStorage.setItem(STORAGE_KEY, JSON.stringify({
      settings,
      timestamp: Date.now()
    }));
  } catch (error) {
    console.error('Fejl ved gem i localStorage:', error);
  }
}

// Funktion til at hente indstillinger fra localStorage
function getFromLocalStorage() {
  try {
    const stored = localStorage.getItem(STORAGE_KEY);
    if (stored) {
      const { settings, timestamp } = JSON.parse(stored);
      // Tjek om indstillingerne er for gamle (24 timer)
      if (Date.now() - timestamp < 24 * 60 * 60 * 1000) {
        return settings;
      }
    }
  } catch (error) {
    console.error('Fejl ved læsning fra localStorage:', error);
  }
  return null;
}

// Funktion til at tjekke om brugeren er admin
async function isAdminUser(userId) {
  try {
    // Først tjek store
    if (store.state.user) {
      return store.state.user.role <= 2;
    }

    // Hvis ikke i store, hent fra Firestore
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return userData.role <= 2;
    }
    return false;
  } catch (error) {
    console.error('Fejl ved tjek af admin status:', error);
    return false;
  }
}

// Funktion til at hente white label indstillinger fra Firestore
async function fetchWhiteLabelSettings() {
  const auth = getAuth();
  const user = auth.currentUser;
  
  if (!user) {
    console.log('Ingen bruger logget ind');
    return null;
  }

  // Tjek om brugeren er admin
  const isAdmin = await isAdminUser(user.uid);
  if (!isAdmin) {
    console.log('Bruger er ikke admin, ingen white label indstillinger anvendes');
    return null;
  }

  try {
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    if (!userDoc.exists()) {
      console.log('Ingen brugerdata fundet, bruger admin standard indstillinger');
      return ADMIN_DEFAULT_SETTINGS;
    }

    const userData = userDoc.data();
    const settings = userData.adminSettings?.whiteLabel;
    
    // Hvis ingen indstillinger er sat, brug admin standard
    if (!settings) {
      return ADMIN_DEFAULT_SETTINGS;
    }
    
    // Sikr at alle nødvendige properties eksisterer
    const mergedSettings = {
      browserSettings: {
        title: settings.browserSettings?.title || ADMIN_DEFAULT_SETTINGS.browserSettings.title,
        favicon: settings.browserSettings?.favicon || ADMIN_DEFAULT_SETTINGS.browserSettings.favicon
      },
      theme: {
        backgroundColor: settings.theme?.backgroundColor || ADMIN_DEFAULT_SETTINGS.theme.backgroundColor,
        sidenavColor: settings.theme?.sidenavColor || ADMIN_DEFAULT_SETTINGS.theme.sidenavColor,
        cardColor: settings.theme?.cardColor || ADMIN_DEFAULT_SETTINGS.theme.cardColor,
        textColor: settings.theme?.textColor || ADMIN_DEFAULT_SETTINGS.theme.textColor,
        navWrapperColor: settings.theme?.navWrapperColor || ADMIN_DEFAULT_SETTINGS.theme.navWrapperColor,
        textTheme: settings.theme?.textTheme || ADMIN_DEFAULT_SETTINGS.theme.textTheme
      }
    };

    // Gem i localStorage for hurtig adgang ved næste page load
    saveToLocalStorage(mergedSettings);
    
    return mergedSettings;
  } catch (error) {
    console.error('Fejl ved hentning af white label indstillinger:', error);
    return null;
  }
}

// Funktion til at opdatere browser titel og favicon
function updateBrowserElements(settings) {
  if (!settings) return;

  // Opdater browser titel
  document.title = settings.browserSettings?.title || ADMIN_DEFAULT_SETTINGS.browserSettings.title;

  // Opdater favicon
  const faviconUrl = settings.browserSettings?.favicon || ADMIN_DEFAULT_SETTINGS.browserSettings.favicon;
  const favicon = document.getElementById('favicon');
  if (favicon) {
    favicon.href = faviconUrl;
  }

  // Opdater tema variabler hvis de findes
  const theme = settings.theme || ADMIN_DEFAULT_SETTINGS.theme;
  document.documentElement.style.setProperty('--background-color', theme.backgroundColor);
  document.documentElement.style.setProperty('--sidenav-color', theme.sidenavColor);
  document.documentElement.style.setProperty('--card-color', theme.cardColor);
  document.documentElement.style.setProperty('--nav-wrapper-color', theme.navWrapperColor);
  
  // Opdater text theme
  if (theme.textTheme === 'light') {
    document.body.classList.remove('dark-text');
    document.body.classList.add('light-text');
  } else {
    document.body.classList.remove('light-text');
    document.body.classList.add('dark-text');
  }
}

// Funktion til at håndtere loading state
function handleLoadingState(isLoading = false) {
  const appLoading = document.getElementById('app-loading');
  const app = document.getElementById('app');
  
  if (!isLoading) {
    // Fade ud loading screen
    if (appLoading) {
      appLoading.classList.add('fade-out');
      setTimeout(() => {
        if (appLoading.parentNode) {
          appLoading.parentNode.removeChild(appLoading);
        }
      }, 300);
    }
    // Fade ind app
    if (app) {
      app.classList.add('loaded');
    }
  }
}

// Funktion til at initialisere white label ved app start
export async function initializeWhiteLabel() {
  // Hvis vi allerede er ved at initialisere, returner den eksisterende promise
  if (initializationPromise) {
    return initializationPromise;
  }

  // Anvend gemte indstillinger med det samme hvis de findes
  const storedSettings = getFromLocalStorage();
  if (storedSettings) {
    updateBrowserElements(storedSettings);
  }

  initializationPromise = (async () => {
    try {
      // Vent på at auth er klar
      const auth = getAuth();
      const user = await new Promise((resolve) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          unsubscribe();
          resolve(user);
        });
      });

      // Hvis der er en bruger, vent på at brugerdata er tilgængelig i store
      if (user) {
        // Vent på at store er initialiseret
        while (!store.state.user) {
          await new Promise(resolve => setTimeout(resolve, 50));
        }

        // Kun hent indstillinger hvis brugeren er admin
        if (store.state.user.role <= 2) {
          const settings = await fetchWhiteLabelSettings();
          if (settings) {
            whitelabelCache = settings;
            lastFetchTimestamp = Date.now();
            updateBrowserElements(settings);
          }
        }
      }

      // Fjern loading screen
      handleLoadingState(false);
    } catch (error) {
      console.error('Fejl ved initialisering af white label:', error);
      handleLoadingState(false);
    }
  })();

  return initializationPromise;
}

// Funktion til at opdatere white label indstillinger
export async function updateWhiteLabel(force = false) {
  // Hvis vi ikke er initialiseret endnu, gør det først
  if (!initializationPromise) {
    await initializeWhiteLabel();
  }

  // Tjek om brugeren er admin
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user || !(await isAdminUser(user.uid))) {
    return null;
  }

  // Tjek om vi skal bruge cache
  if (!force && whitelabelCache && lastFetchTimestamp && 
      Date.now() - lastFetchTimestamp < CACHE_DURATION) {
    updateBrowserElements(whitelabelCache);
    return whitelabelCache;
  }

  // Hent nye indstillinger
  const settings = await fetchWhiteLabelSettings();
  if (settings) {
    whitelabelCache = settings;
    lastFetchTimestamp = Date.now();
    updateBrowserElements(settings);
    // Gem i localStorage
    saveToLocalStorage(settings);
  }
  return settings;
}

// Funktion til at rydde cache
export function clearWhiteLabelCache() {
  whitelabelCache = null;
  lastFetchTimestamp = null;
  localStorage.removeItem(STORAGE_KEY);
}

// Observer til at håndtere auth ændringer
getAuth().onAuthStateChanged(async (user) => {
  if (user) {
    // Vent på at store er initialiseret
    while (!store.state.user) {
      await new Promise(resolve => setTimeout(resolve, 50));
    }
    
    // Kun opdater hvis brugeren er admin
    if (store.state.user.role <= 2) {
      await updateWhiteLabel(true);
    }
  } else {
    // Ryd cache ved logout
    clearWhiteLabelCache();
  }
}); 