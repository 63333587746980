import i18n from '@/i18n';

export const autoTranslate = (text) => {
  if (!text) return '';

  try {
    // Brug det aktuelle sprog
    const currentLocale = i18n.global.locale.value;
    const otherLocale = currentLocale === 'da' ? 'en' : 'da';
    
    const translations = i18n?.global?.messages?.value?.[currentLocale] || {};
    const otherTranslations = i18n?.global?.messages?.value?.[otherLocale] || {};
    
    // Find eksisterende oversættelse i det aktuelle sprog
    for (const [key, value] of Object.entries(translations)) {
      if (value === text) {
        return key;
      }
      // Tjek også nested objekter
      if (typeof value === 'object' && value !== null) {
        for (const [nestedKey, nestedValue] of Object.entries(value)) {
          if (nestedValue === text) {
            return `${key}.${nestedKey}`;
          }
        }
      }
    }

    // Hvis ikke fundet i det aktuelle sprog, tjek det andet sprog
    for (const [key, value] of Object.entries(otherTranslations)) {
      if (value === text && translations[key]) {
        return key;
      }
      // Tjek også nested objekter i det andet sprog
      if (typeof value === 'object' && value !== null) {
        for (const [nestedKey, nestedValue] of Object.entries(value)) {
          const fullKey = `${key}.${nestedKey}`;
          if (nestedValue === text) {
            // Tjek om oversættelsen findes i det aktuelle sprog
            const currentTranslation = translations[key]?.[nestedKey];
            if (currentTranslation) {
              return fullKey;
            }
          }
        }
      }
    }

    // Hvis ingen oversættelse findes, returner nøglen hvis den findes, ellers teksten
    const existingKey = Object.entries(translations).find(([_, value]) => value === text)?.[0];
    return existingKey || text;
  } catch (error) {
    console.error('Fejl i autoTranslate:', error);
    return text;
  }
};  