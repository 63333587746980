import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import ArgonDashboard from "./argon-dashboard";
import logger from './utils/logger';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Tooltip } from 'bootstrap';
import { app as firebaseApp } from './firebase';
import { setupConsoleOverride } from './utils/consoleOverride';
import { initializeWhiteLabel } from './services/whiteLabel';
import autoTranslate from './directives/autoTranslate';
import i18n from './i18n';

library.add(fas);

// Importer Font Awesome
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

// Kald dette før app initialization
setupConsoleOverride();

// Opret Vue app instance
const app = createApp(App);

// Initialiser white label indstillinger før app mount
await initializeWhiteLabel();

// Tilføj i18n FØR andre plugins og direktiver
app.use(i18n);

// Tilføj auto-translate direktiv EFTER i18n er initialiseret
app.directive('auto-translate', autoTranslate);

// Resten af dine plugins
app.use(store);
app.use(router);
app.use(VueTilt);
app.use(VueSweetalert2);
app.use(ArgonDashboard);
app.component('font-awesome-icon', FontAwesomeIcon);

// Tilføj tooltip direktiv
app.directive('tooltip', {
  mounted(el, binding) {
    new Tooltip(el, {
      title: binding.value,
      placement: binding.arg || 'top',
      trigger: 'hover'
    });
  },
  unmounted(el) {
    const tooltip = Tooltip.getInstance(el);
    if (tooltip) {
      tooltip.dispose();
    }
  }
});

// Mount app
app.mount("#app");

// Global error handlers
window.onerror = function(message, source, lineno, colno, error) {
  console.error('Global error:', { message, source, lineno, colno, error });
  return false;
};

window.addEventListener('unhandledrejection', event => {
  console.error('Unhandled promise rejection:', event.reason);
});

// Vue error handler
app.config.errorHandler = (err, vm, info) => {
  console.error('Vue error:', err);
};

// Optimerer rendering
app.config.performance = true;

// Deaktiver development warnings i production
if (process.env.NODE_ENV === 'production') {
  app.config.warnHandler = () => null;
}

