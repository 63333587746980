// src/store/index.js
import { createStore } from "vuex";
import createPersistedState from 'vuex-persistedstate'
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '@/firebase';

export default createStore({
  plugins: [
    createPersistedState({
      paths: ['user', 'isAuthLoading']
    })
  ],
  state: {
    // UI-relateret state
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-white",
    isRTL: false,
    mcolor: "",
    darkMode: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: "default",
    setImpersonatedUser: null,
    // Brugerrelateret state
    activeUser: null,
    // Cache-relateret state
    carCaches: {},
    cacheTimestamps: {},
    isAuthLoading: true,
    cachedData: new Map(),
    authInitialized: false,
    cacheInitialized: false,
    user: null,
  },
  mutations: {
    // UI-relaterede mutationer
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");

      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      state.isNavFixed = !state.isNavFixed;
    },

    // Brugerrelaterede mutationer
    SET_CURRENT_USER(state, user) {
      console.log('Mutation: Setting user data:', user);
      state.user = user;
      state.activeUser = user;
      
      // Initialiser cache for brugeren hvis den ikke findes
      if (!state.carCaches[user.id]) {
        state.carCaches[user.id] = [];
        state.cacheTimestamps[user.id] = null;
      }
      
      localStorage.setItem('currentUser', JSON.stringify(user));
    },
    CLEAR_CURRENT_USER(state) {
      state.activeUser = null;
      localStorage.removeItem('currentUser');
    },

    // Nye cache-relaterede mutations
    SET_USER_CARS_CACHE(state, { userId, cars }) {
      state.carCaches[userId] = cars;
      state.cacheTimestamps[userId] = Date.now();
      
      // Gem i localStorage med bruger-specifik nøgle
      try {
        localStorage.setItem(`carCache_${userId}`, JSON.stringify(cars));
        localStorage.setItem(`cacheTimestamp_${userId}`, state.cacheTimestamps[userId].toString());
      } catch (e) {
        console.error('Kunne ikke gemme i localStorage:', e);
      }
    },

    LOAD_USER_CARS_CACHE(state, userId) {
      try {
        const cachedCars = localStorage.getItem(`carCache_${userId}`);
        const timestamp = localStorage.getItem(`cacheTimestamp_${userId}`);
        
        if (cachedCars && timestamp) {
          state.carCaches[userId] = JSON.parse(cachedCars);
          state.cacheTimestamps[userId] = parseInt(timestamp);
        }
      } catch (e) {
        console.error('Fejl ved indlæsning af cache:', e);
        state.carCaches[userId] = [];
        state.cacheTimestamps[userId] = null;
      }
    },

    CLEAR_USER_CARS_CACHE(state, userId) {
      state.carCaches[userId] = [];
      state.cacheTimestamps[userId] = null;
      localStorage.removeItem(`carCache_${userId}`);
      localStorage.removeItem(`cacheTimestamp_${userId}`);
    },

    SET_AUTH_LOADING(state, isLoading) {
      state.isAuthLoading = isLoading;
    },

    setCachedData(state, { key, data }) {
      state.cachedData.set(key, {
        timestamp: Date.now(),
        data
      });
    },

    SET_AUTH_INITIALIZED(state, value) {
      state.authInitialized = value;
    },

    CLEAR_USER(state) {
      state.user = null;
      state.authInitialized = true;
    },

    SET_CACHE_INITIALIZED(state, value) {
      state.cacheInitialized = value;
    },

    SET_USER(state, user) {
      state.user = user;
      state.authInitialized = true;
    },
  },
  actions: {
    // UI-relaterede handlinger
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },

    // Brugerrelaterede handlinger
    setCurrentUser({ commit, dispatch }, user) {
      console.log('Setting user data:', user);
      commit("SET_CURRENT_USER", user);
      
      // Load existing cache hvis den findes
      dispatch('loadUserCarsCache', user.id);
    },
    clearCurrentUser({ commit }) {
      console.log("Action clearCurrentUser called");
      commit("CLEAR_CURRENT_USER");
    },

    async fetchWithCache({ state, commit }, { key, fetchFn, maxAge = 300000 }) {
      const cached = state.cachedData.get(key);
      if (cached && Date.now() - cached.timestamp < maxAge) {
        return cached.data;
      }
      const data = await fetchFn();
      commit('setCachedData', { key, data });
      return data;
    },

    async initializeAuthUser({ commit, dispatch }, user) {
      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          
          // Opdater brugerens online status
          await updateDoc(doc(db, 'users', user.uid), { 
            isOnline: true 
          });
          
          // Gem brugerdata i store
          commit('SET_USER', { 
            ...userData, 
            id: user.uid,
            isOnline: true 
          });
          
          return userData;
        }
      } catch (error) {
        console.error('Error initializing auth user:', error);
        throw error;
      }
    },

    async initializeCache({ commit }) {
      // Initialiser cache her
      commit('SET_CACHE_INITIALIZED', true);
    },

    // Nye cache-relaterede actions
    loadUserCarsCache({ commit }, userId) {
      commit('LOAD_USER_CARS_CACHE', userId);
    },

    clearUserCarsCache({ commit }, userId) {
      commit('CLEAR_USER_CARS_CACHE', userId);
    },
  },
  getters: {
    // UI-relaterede getters kan tilføjes her

    // Brugerrelaterede getters
    activeUser: (state) => state.activeUser,
    isAuthenticated: (state) => !!state.user,
    getUserCarsCache: (state) => (userId) => {
      return state.carCaches[userId] || [];
    },
    isCacheValid: (state) => (userId) => {
      const timestamp = state.cacheTimestamps[userId];
      if (!timestamp) return false;
      
      const now = new Date();
      const lastFetchDate = new Date(timestamp);
      
      // Hvis sidste fetch var før kl 05:00 i dag, og klokken er over 05:00 nu,
      // så er cachen invalid
      const fiveAM = new Date(now);
      fiveAM.setHours(5, 0, 0, 0);
      
      if (lastFetchDate < fiveAM && now >= fiveAM) {
        return false;
      }
      
      return true;
    },
    shouldRefetchUserCars: (state, getters) => (userId) => {
      // Alle brugertyper bruger cache på samme måde, da data kun opdateres om natten
      return !getters.isCacheValid(userId);
    },
    userRole: (state) => state.user?.role,
    isSuperAdmin: (state) => state.user?.role === 1,
    isAdmin: (state) => state.user?.role <= 2
  },
  modules: {
    // Fjern linkedin herfra
    // linkedin,
  }
});
