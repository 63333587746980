import axios from 'axios';
import { getFirestore, doc, getDoc, collection, collectionGroup } from 'firebase/firestore';

// Konstant for API URL
const API_URL = "https://us-central1-toft-d4f39.cloudfunctions.net/correctText";

// Funktion til at hente bildata fra dealerCars subcollection
const getCarData = async (caseId, contextUserId, activeUserId, canAccessOtherUsers) => {
  try {
    console.log('Forsøger at hente bildata med caseId:', caseId, 'og contextUserId:', contextUserId);

    const db = getFirestore();
    let carRef;

    if (canAccessOtherUsers) {
      if (contextUserId !== activeUserId) {
        const userDocRef = doc(db, 'users', contextUserId);
        carRef = doc(userDocRef, 'dealerCars', caseId.toString());
      } else {
        carRef = doc(collectionGroup(db, 'dealerCars'), caseId.toString());
      }
    } else {
      const userDocRef = doc(db, 'users', contextUserId);
      carRef = doc(userDocRef, 'dealerCars', caseId.toString());
    }

    const carDoc = await getDoc(carRef);
    
    if (carDoc.exists()) {
      const carData = carDoc.data();
      console.log('Fundet bildata:', carData);
      return carData;
    }
    
    console.log('Bil dokument ikke fundet');
    return null;
  } catch (error) {
    console.error('Fejl ved hentning af bildata:', error);
    return null;
  }
};

// Generel tekst korrektion
export const correctEventText = async (messages, postData) => {
  try {
    console.log('PostData modtaget:', postData);

    // Hvis det er en blog-post
    if (postData?.platform === 'blog') {
      const response = await axios.post(API_URL, {
        action: 'generateBlogPost',
        messages: messages,
        maxLength: 10000,
        format: 'html',
        complete: true
      }, {
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });
      
      if (response.data && response.data.correctedText) {
        // Simplificér HTML output og fjern komplekse strukturer
        let cleanText = response.data.correctedText
          // Fjern DOCTYPE, html, head og body tags
          .replace(/<!DOCTYPE[^>]*>/i, '')
          .replace(/<\/?html[^>]*>/gi, '')
          .replace(/<\/?head>|<\/?meta[^>]*>|<\/?title[^>]*>/gi, '')
          .replace(/<\/?body[^>]*>/gi, '')
          // Fjern alle class og style attributter
          .replace(/\s+class="[^"]*"/g, '')
          .replace(/\s+style="[^"]*"/g, '')
          // Konvertér komplekse elementer til simple p-tags
          .replace(/<div[^>]*>/g, '<p>')
          .replace(/<\/div>/g, '</p>')
          // Behold kun basale HTML tags
          .replace(/<(?!\/?(p|br|h[1-6]|ul|li|ol|strong|em|a|blockquote)(?=>|\s.*?>))\/?[^>]*>/g, '')
          // Fjern tomme linjer og ekstra whitespace
          .replace(/>\s+</g, '><')
          .replace(/\n\s*\n/g, '\n')
          .trim();

        // Sikr at teksten starter og slutter korrekt
        if (!cleanText.startsWith('<p>')) {
          cleanText = '<p>' + cleanText;
        }
        if (!cleanText.endsWith('</p>')) {
          cleanText += '</p>';
        }

        // Tilføj simple linjeskift mellem afsnit for bedre læsbarhed
        cleanText = cleanText
          .replace(/<\/p><p>/g, '</p>\n<p>')
          .replace(/<\/h([1-6])><p>/g, '</h$1>\n<p>')
          .replace(/<\/p><h([1-6])>/g, '</p>\n<h$1>');

        return { correctedText: cleanText };
      }
      throw new Error('Ingen gyldig tekst modtaget fra AI servicen');
    }

    // Hvis det er en karrusel-post
    if (postData?.type === 'karruselPost') {
      const carouselMessages = constructCarouselMessages(
        messages[1].content,
        postData.postFB || false,
        postData.postInst || false,
        postData
      );
      console.log('Konstruerede karrusel-beskeder:', carouselMessages);
      
      const response = await axios.post(API_URL, {
        action: 'correct',
        messages: carouselMessages
      }, {
        headers: { 'Content-Type': 'application/json' }
      });
      
      return { correctedText: response.data.correctedText };
    }

    // Hvis det er en bil-post, hent bildata først
    let carData = null;
    if (postData?.type === 'singlePost' && postData?.caseId) {
      const carDataResponse = await axios.get(`https://us-central1-toft-d4f39.cloudfunctions.net/getSpecificCarData`, {
        params: {
          userId: postData.id,
          carId: postData.caseId
        }
      });

      if (carDataResponse.data.success) {
        carData = carDataResponse.data.data;
        console.log('Hentet bildata:', carData);
      } else {
        console.error('Fejl ved hentning af specifik bildata:', carDataResponse.data.error);
        return null;
      }
    }

    // Hvis vi har bildata, brug constructCarMessages med postStatus fra postData
    if (carData) {
      const carMessages = constructCarMessages(carData, messages[1].content, postData.postStatus || 'active');
      console.log('Konstruerede bil-beskeder:', carMessages);
      
      const response = await axios.post(API_URL, {
        action: 'correct',
        messages: carMessages,
        carData: carData
      }, {
        headers: { 'Content-Type': 'application/json' }
      });
      
      return { correctedText: response.data.correctedText };
    }

    // Hvis ikke bil-data, fortsæt med almindelig korrektion
    const response = await axios.post(API_URL, {
      action: 'correct',
      messages: messages
    }, {
      headers: { 'Content-Type': 'application/json' }
    });
    
    if (response.data && response.data.correctedText) {
      return { correctedText: response.data.correctedText };
    } else if (response.data && response.data.error) {
      throw new Error(response.data.error);
    } else {
      throw new Error('Uventet svarformat fra serveren');
    }
  } catch (error) {
    console.error('Fejl under processering af tekst:', error);
    throw error;
  }
};

// Bil-specifikte beskeder
const constructCarMessages = (carData, currentText, status) => {
  console.log('Konstruerer bil-beskeder med:', { carData, currentText, status }); // Debug log
  
  const messages = [
    {
      role: "system",
      content: `Du er en erfaren bilsælger og social media manager. Din opgave er at skabe fængende og salgsorienterede opslag til sociale medier om biler. 
                Regler:
                - Brug emojis naturligt og moderat
                - Hold teksten kort og fængende, max 280 tegn
                - Tilpas tonen og indholdet specifikt til den pågældende bil
                - Skab et opslag, der opfordrer til interaktion og klik
                - Undgå at bruge formateringsmærker som **, [], eller overskrifter
                - Skriv teksten, så den er klar til at blive kopieret direkte ind i et socialt medie opslag
                - Inkluder en kort, fængende beskrivelse af bilen og dens vigtigste features
                - Afslut med en opfordring til handling (CTA)`
    }
  ];

  // Konverter bilens felter til læsbar tekst
  const carFields = Object.entries(carData.fields)
    .map(([key, value]) => `${key}: ${value}`)
    .join('\n');

  // Tjek for eksisterende link fra selink.dk
  const existingLink = currentText.match(/https:\/\/selink\.dk\S*/);
  const linkToUse = existingLink ? existingLink[0] : '[]';

  messages.push({
    role: "user",
    content: `Lav et fængende socialt medie opslag baseret på følgende information:
              Mærke og model: ${carData.fields.Mærke} ${carData.fields.Model}
              Overskrift: ${carData.headline}
              Beskrivelse: ${carData.description}
              År: ${carData.fields.Årgang}
              Pris: ${carData.price}
              Status: ${status}
              
              Yderligere detaljer:
              ${carFields}
              
              Nuværende tekst (skal forbedres):
              ${currentText}
              
              Link: ${linkToUse}
              
              Husk at følge reglerne i system-prompten, og lav en tekst der er klar til at blive postet direkte.`
  });

  return messages;
};

// Karrusel-specifikke beskeder
const constructCarouselMessages = (currentText, postFB = false, postInst = false, postData = null) => {
  console.log('Konstruerer karrusel-beskeder med tekst:', currentText);
  
  const platform = postFB ? 'Facebook' : postInst ? 'Instagram' : 'sociale medier';
  const maxLength = postFB ? 400 : 2200; // Instagram tillader op til 2200 tegn
  
  // Hent bil information fra children array
  const carInfo = postData?.children?.map(car => ({
    headline: car.headline,
    caseUrl: car.caseUrl,
    caseId: car.caseId,
    description: car.description || '',
    price: car.price || ''
  })) || [];
  
  const messages = [
    {
      role: "system",
      content: `Du er en erfaren bilsælger og social media manager. Din opgave er at skabe fængende og salgsorienterede karrusel-opslag til ${platform}, der præsenterer flere biler på én gang.

                Regler for karrusel-opslag på ${platform}:
                - Brug emojis naturligt og moderat ${postInst ? '(brug gerne flere emojis på Instagram)' : ''}
                - Hold teksten kort og fængende, max ${maxLength} tegn
                - Brug KUN faktisk information fra de tilgængelige biler - ingen fiktive detaljer
                - Fremhæv fællestræk eller temaer på tværs af bilerne baseret på de faktiske modeller
                - Skab nysgerrighed der får folk til at swipe gennem alle billeder
                - Undgå at tilføje nye links eller URL'er
                ${postInst ? '- Brug hashtags strategisk (max 20-25 relevante hashtags)' : ''}
                ${postFB ? '- Nævn kun finansieringsmuligheder hvis det er bekræftet tilgængeligt' : ''}
                - Brug engagerende spørgsmål baseret på de faktiske biler i karrusellen
                - Undgå at bruge formateringsmærker som **, [], eller overskrifter
                - Skriv teksten, så den er klar til at blive kopieret direkte ind i et ${platform} opslag
                - Afslut med en stærk opfordring til handling (CTA) der relaterer til hele samlingen
                ${postInst ? '- Placer hashtags i en separat blok efter hovedteksten' : ''}`
    },
    {
      role: "user",
      content: `Forbedr denne karrusel-tekst til et ${platform} opslag.
                
                VIGTIGT: tilføj IKKE links til teksterne.

                Tilgængelige biler i karrusellen:
                ${carInfo.map((car, index) => `
                ${index + 1}. ${car.headline}
                   URL: ${car.caseUrl}
                   ${car.description ? `Beskrivelse: ${car.description}` : ''}
                   ${car.price ? `Pris: ${car.price}` : ''}
                `).join('\n')}

                Nuværende tekst:
                ${currentText}
                
                Specifikke krav til ${platform} opslag:
                ${postFB ? `
                - Fokuser på de faktiske specifikationer og priser fra bilerne
                - Nævn kun bekræftede detaljer og tilbud
                - Brug en professionel og salgsorienteret tone
                - Inkluder relevante priser og kontaktinformation
                - Opfordr til at kontakte for mere information eller fremvisning` : ''}
                ${postInst ? `
                - Brug en mere afslappet og visuel beskrivende tone
                - Fokuser på bilernes faktiske features og design
                - Brug relevante hashtags for at øge rækkevidden
                - Opfordr til at gemme opslaget til senere
                - Fremhæv det unikke ved hver faktisk bil i karrusellen` : ''}
                
                Husk:
                - Brug KUN faktisk information fra bilerne
                - Undgå generiske påstande om finansiering eller tilbud
                - Vær specifik omkring de faktiske modeller og deres features
                - Lav teksten klar til at blive postet direkte`
    }
  ];

  return messages;
};
