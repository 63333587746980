<template>
  <div class="preview-demo-section">
    <div class="preview-demo-container">
      <div class="section-header">
        <h2 v-auto-translate>Prøv en live preview for dig selv!</h2>
        <p v-auto-translate>1. Vælg en bil, 2. vælg et ønsket design og 3. tryk på se preview.</p>
      </div>

      <div class="preview-layout">
        <!-- Left Column: Car Selection -->
        <div class="car-selection-side">
          <div class="car-selection-container">
            <DemoSelectCar @car-selected="selectCar" :user-id="'H3Ael2hkOQVlfFIRssVnM0BTB1H2'" />
          </div>
        </div>

        <!-- Right Column: Preview -->
        <div class="preview-side">
          <div v-if="!selectedCar" class="preview-placeholder">
            <p v-auto-translate>Vælg en bil fra listen til venstre for at se preview</p>
          </div>
          <div v-else class="preview-content">
            <!-- Vis designs og preview knap KUN når der ikke loades og ingen fejl -->
            <template v-if="!isPreviewLoading && !previewImageUrl && !previewText">
              <h3>{{ generateTitle }}</h3>
              
              <!-- Design Selection -->
              <div class="designs-section">
                <div class="designs-container">
                  <div v-for="design in paginatedDesigns" 
                       :key="design.uuid"
                       class="design-card"
                       @click="selectDesign(design)"
                       :class="{ 'selected': selectedDesign?.uuid === design.uuid }">
                    <div class="image-container">
                      <video v-if="isVideo(design.file)"
                             :src="design.file"
                             class="design-video"
                             loop
                             muted
                             @mouseover="playVideo"
                             @mouseleave="pauseVideo">
                      </video>
                      <img v-else
                           :src="design.file" 
                           :alt="design.name"
                           class="design-image">
                      <div v-if="isVideo(design.file)" class="video-indicator">
                        <i class="fas fa-play"></i>
                      </div>
                    </div>
                  </div>
                </div>
                
                <!-- Navigation Controls -->
                <div class="design-navigation" v-if="filteredDesigns.length > 2">
                  <button 
                    @click="previousPage" 
                    :disabled="!hasPreviousPage"
                    class="nav-button"
                  >
                    <i class="fas fa-chevron-left"></i>
                  </button>
                  <span class="page-info">
                    {{ currentPage + 1 }} / {{ Math.ceil(filteredDesigns.length / 2) }}
                  </span>
                  <button 
                    @click="nextPage" 
                    :disabled="!hasNextPage"
                    class="nav-button"
                  >
                    <i class="fas fa-chevron-right"></i>
                  </button>
                </div>
              </div>

              <div class="preview-button-container">
                <button 
                  @click="previewPost" 
                  class="preview-button"
                  :disabled="!selectedDesign"
                >
                  <span v-auto-translate>Se preview</span>
                </button>
              </div>
            </template>

            <!-- Loading eller Preview Content -->
            <template v-else>
              <!-- Video Generation Loading -->
              <template v-if="isPreviewLoading">
                <div class="video-generation-overlay">
                  <div class="generation-content">
                    <div class="generation-status">
                      <div class="loading-spinner"></div>
                      <h3 class="status-title">{{ loadingStatus }}</h3>
                      <div class="progress-container">
                        <div class="progress-bar">
                          <div class="progress-pulse"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <!-- Preview Content -->
              <div v-else-if="previewImageUrl || previewText" class="preview-content-wrapper">
                <div class="preview-container">
                  <div class="preview-image-container">
                    <template v-if="isVideo(selectedDesign?.file)">
                      <div v-if="isPreviewLoading" class="video-generation-overlay">
                        <div class="generation-content">
                          <div class="generation-status">
                            <div class="loading-spinner"></div>
                            <h3 class="status-title">{{ loadingStatus }}</h3>
                            <div class="progress-container">
                              <div class="progress-bar">
                                <div class="progress-fill" :style="{ width: loadingProgress + '%' }"></div>
                              </div>
                              <span class="progress-text">{{ loadingProgress }}%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else class="video-container" :style="videoContainerStyle">
                        <video 
                          ref="videoPlayer"
                          :src="previewImageUrl" 
                          controls
                          class="preview-video"
                          @contextmenu.prevent
                          draggable="false"
                          @mousedown.prevent
                        >
                          Din browser understøtter ikke video afspilning.
                        </video>
                      </div>
                    </template>
                    <template v-else>
                      <img 
                        :src="previewImageUrl" 
                        alt="Preview" 
                        class="preview-image"
                        @contextmenu.prevent
                        draggable="false"
                        @mousedown.prevent
                      >
                      <div class="hover-indicator" :class="{ 'is-loading': !isAITextReady }">
                        <template v-if="!isAITextReady">
                          <div class="loading-spinner small"></div>
                          <span>Teksten genereres...</span>
                        </template>
                        <template v-else>
                          <i class="fas fa-eye"></i>
                          <span>Hover for at se tekst</span>
                        </template>
                      </div>
                      <div class="preview-text-overlay" :class="{ 'can-hover': isAITextReady }">
                        <div class="preview-text-content">
                          {{ previewText }}
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal for expanded image -->
    <div v-if="expandedImage" class="modal" @click="closeExpandedImage">
      <div class="modal-content">
        <img :src="expandedImage" alt="Expanded design" class="expanded-image">
        <button class="close-modal" @click="closeExpandedImage">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>

    <!-- Tilføj ny sektion med ekstra tekst -->
    <div class="bottom-text-section">
      <p v-auto-translate>En af vores store fordele er, at vi kan implementere skræddersyede designs fra dag til dag, præcis efter dine ønsker, så du får en unik løsning, der kun er tilpasset dig</p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue';
import DemoSelectCar from './DemoSelectCar.vue';
import { generateImage } from '@/utils/imageGenerator';
import { generateVideo } from '@/utils/videoGenerator';
import { generateSocialMediaPost } from '@/services/socialMediaPostGenerator';
import { generateAIText } from '@/services/aiTextService';
import axios from 'axios';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { db } from '@/firebase';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { checkPreviewLimit, PREVIEW_LIMIT } from '@/utils/rateLimiter';

const selectedCar = ref(null);
const showPreview = ref(false);
const previewImageUrl = ref('');
const previewText = ref('');
const isPreviewLoading = ref(false);
const loadingStatus = ref('');
const loadingProgress = ref(0);
const isLoading = ref(false);
const designImages = ref([]);
const selectedDesign = ref(null);
const expandedImage = ref(null);
const isGeneratingText = ref(false);
const isAITextReady = ref(false);
const currentPage = ref(0);
const videoPlayer = ref(null);
const videoAspectRatio = ref('square'); // 'square' eller 'portrait'

// Computed property til at filtrere designs
const filteredDesigns = computed(() => {
  return designImages.value.filter(design => {
    // Tjek om designet har emner og om 'Nyhed' er blandt dem
    const matchesEmne = Array.isArray(design.emner) && 
                       design.emner.some(emne => 
                         emne.toLowerCase() === 'nyhed'
                       );

    // Tjek om designet har platforms og om 'facebook' er blandt dem
    const matchesPlatform = Array.isArray(design.channels) && 
                           design.channels.some(channel => 
                             channel.toLowerCase() === 'facebook'
                           );

    // Tjek om filen er et billede eller video
    const isValidFile = /\.(jpg|jpeg|png|mp4|mov|webm)$/i.test(design.file?.split('?')[0] || '');

    return matchesEmne && matchesPlatform && isValidFile;
  });
});

// Tilføj computed property for at få det aktuelle sæt af designs
const paginatedDesigns = computed(() => {
  const start = currentPage.value * 2;
  return filteredDesigns.value.slice(start, start + 2);
});

// Tilføj computed property for at tjekke om der er flere sider
const hasNextPage = computed(() => {
  return (currentPage.value + 1) * 2 < filteredDesigns.value.length;
});

const hasPreviousPage = computed(() => {
  return currentPage.value > 0;
});

// Tilføj metoder til at navigere mellem sider
const nextPage = () => {
  if (hasNextPage.value) {
    currentPage.value++;
    selectedDesign.value = null;
  }
};

const previousPage = () => {
  if (hasPreviousPage.value) {
    currentPage.value--;
    selectedDesign.value = null;
  }
};

// Hent designs fra Firestore
const fetchDesigns = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'designImages'));
    designImages.value = querySnapshot.docs.map(doc => ({
      uuid: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Fejl ved hentning af designs:', error);
  }
};

// Formatér pris med punktummer som tusindtalsseparator
const formatPrice = (price) => {
  return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") || '0';
};

// Generer titel
const generateTitle = computed(() => {
  if (!selectedCar.value) return '';
  return `${selectedCar.value.fields?.Mærke || ''} ${selectedCar.value.fields?.Model || ''} ${selectedCar.value.fields?.Variant || ''}`;
});

const selectDesign = (design) => {
  selectedDesign.value = design;
};

const selectCar = (car) => {
  selectedCar.value = car;
  showPreview.value = false;
  previewImageUrl.value = '';
  previewText.value = '';
  isPreviewLoading.value = false;
  isLoading.value = false;
  selectedDesign.value = null;
  currentPage.value = 0;
};

// Tilføj ny hjælpefunktion til billedkomprimering
const compressImage = async (blob) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      // Beregn nye dimensioner (max 1200px bredde eller højde)
      const maxSize = 1200;
      let width = img.width;
      let height = img.height;
      
      if (width > height && width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      } else if (height > maxSize) {
        width *= maxSize / height;
        height = maxSize;
      }
      
      canvas.width = width;
      canvas.height = height;
      
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);
      
      // Konverter til blob med 0.8 kvalitet
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg', 0.8);
    };
    img.src = URL.createObjectURL(blob);
  });
};

// Funktion til at konvertere URL til blob med Image objekt
const convertUrlToBlob = async (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      
      canvas.toBlob((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        resolve(blobUrl);
      }, 'image/jpeg', 1.0);
    };
    
    img.onerror = () => {
      console.error('Fejl ved indlæsning af billede');
      resolve(url); // Fallback til original URL
    };
    
    // Brug proxy for at undgå CORS
    const proxyUrl = `https://api.allorigins.win/raw?url=${encodeURIComponent(url)}`;
    img.src = proxyUrl;
  });
};

// Tilføj ny metode til at checke video dimensioner
const checkVideoFormat = (video) => {
  const width = video.videoWidth;
  const height = video.videoHeight;
  
  // Check om det er 4:5 format (1080x1350) eller 1:1 format (1080x1080)
  if (width && height) {
    const ratio = height / width;
    videoAspectRatio.value = ratio > 1.1 ? 'portrait' : 'square';
  }
};

// Opdater video container styling
const videoContainerStyle = computed(() => {
  if (videoAspectRatio.value === 'portrait') {
    return {
      width: '80%',
      maxWidth: '1080px',
      aspectRatio: '4/5',
      margin: '0 auto'
    };
  }
  return {
    width: '80%',
    maxWidth: '1080px',
    aspectRatio: '1/1',
    margin: '0 auto'
  };
});

// Forenklet previewPost funktion
const previewPost = async () => {
  if (!selectedCar.value || !selectedDesign.value) return;

  try {
    // Tjek rate limit før preview generering
    const userIP = await fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => data.ip);
    
    const limitCheck = await checkPreviewLimit(userIP);
    
    if (!limitCheck.allowed) {
      const hours = Math.floor(limitCheck.timeUntilReset / 60);
      const minutes = limitCheck.timeUntilReset % 60;
      const timeText = hours > 0 
        ? `${hours} timer og ${minutes} minutter` 
        : `${minutes} minutter`;
      
      loadingStatus.value = `Din gratis demo-adgang er opbrugt for i dag! 🕒 Du har brugt alle ${PREVIEW_LIMIT} preview-forsøg. Prøv igen om ${timeText}, eller kontakt os for at høre mere om vores løsning.`;
      return;
    }

    showPreview.value = true;
    isPreviewLoading.value = true;
    previewImageUrl.value = '';
    
    const isVideoDesign = isVideo(selectedDesign.value.file);
    loadingStatus.value = isVideoDesign ? 'Starter video generering...' : 'Genererer billede...';
    loadingProgress.value = 0;

    try {
      const carWithLogo = {
        ...selectedCar.value,
        fields: {
          ...selectedCar.value.fields,
          Mærke: selectedCar.value.fields?.Mærke || ''
        },
        userData: selectedCar.value.userData || {},
        userId: selectedCar.value.dealerId
      };
      
      if (isVideoDesign) {
        const videoResult = await generateVideo(
          carWithLogo, 
          selectedDesign.value.uuid,
          (status) => {
            switch(status.status) {
              case 'QUEUED':
                loadingStatus.value = 'Video er i kø...';
                loadingProgress.value = 10;
                break;
              case 'PROCESSING':
                loadingStatus.value = status.message || 'Genererer video...';
                if (status.elapsedTime && status.startTime) {
                  const progress = Math.min(70, (status.elapsedTime / (3 * 60 * 1000)) * 70);
                  loadingProgress.value = 20 + progress;
                }
                break;
              case 'COMPLETED':
                loadingStatus.value = '';
                loadingProgress.value = 100;
                // Tilføj event listener når videoen er loadet
                nextTick(() => {
                  if (videoPlayer.value) {
                    videoPlayer.value.addEventListener('loadedmetadata', () => {
                      checkVideoFormat(videoPlayer.value);
                    });
                    videoPlayer.value.play().catch(err => {
                      console.warn('Automatisk afspilning fejlede:', err);
                    });
                  }
                });
                break;
              case 'ERROR':
                loadingStatus.value = `Fejl: ${status.message}`;
                break;
            }
          }
        );
        
        previewImageUrl.value = videoResult.videoUrl;
        previewText.value = ''; // Nulstil tekst for videoer
        isAITextReady.value = true;
        
        nextTick(() => {
          if (videoPlayer.value) {
            videoPlayer.value.play().catch(err => {
              console.warn('Automatisk afspilning fejlede:', err);
            });
          }
        });
      } else {
        // For billeddesigns
        loadingStatus.value = 'Genererer billede...';
        const robollyUrl = await generateImage(carWithLogo, selectedDesign.value.uuid);
        previewImageUrl.value = robollyUrl;
        
        // Start tekstgenerering
        loadingStatus.value = 'Genererer tekst...';
        const postContent = await generateSocialMediaPost(carWithLogo, selectedDesign.value.uuid);
        previewText.value = postContent.text;

        // Forbedre teksten med AI
        try {
          loadingStatus.value = 'Forbedrer tekst...';
          const messages = constructMessages('correct');
          const response = await correctText(messages);
          if (response?.correctedText) {
            previewText.value = response.correctedText;
          }
        } catch (error) {
          console.warn('Kunne ikke forbedre teksten:', error);
        }
        loadingStatus.value = '';
        isAITextReady.value = true;
      }

    } catch (error) {
      console.error('Fejl ved generering af preview:', error);
      const errorType = isVideoDesign ? 'video' : 'billede';
      loadingStatus.value = `Der opstod en fejl ved generering af ${errorType}`;
      previewText.value = `Der opstod en fejl ved generering af ${errorType}`;
    } finally {
      isPreviewLoading.value = false;
    }
  } catch (error) {
    console.error('Fejl ved rate limiting:', error);
    loadingStatus.value = 'Der opstod en fejl ved rate limiting';
  }
};

const constructMessages = (action) => {
  let messages = [
    {
      role: "system",
      content: `Du er en erfaren bilsælger og social media manager. Din opgave er at skabe fængende og salgsorienterede opslag til sociale medier om biler. 
                Regler:
                - Brug emojis naturligt og moderat
                - Hold teksten kort og fængende, max 280 tegn
                - Tilpas tonen og indholdet specifikt til den pågældende bil
                - Skab et opslag, der opfordrer til interaktion og klik
                - Undgå at bruge formateringsmærker som **, [], eller overskrifter
                - Skriv teksten, så den er klar til at blive kopieret direkte ind i et socialt medie opslag
                - Inkluder en kort, fængende beskrivelse af bilen og dens vigtigste features
                - Afslut med en opfordring til handling (CTA)`
    }
  ];

  if (action === 'correct') {
    const carFields = Object.entries(selectedCar.value.fields || {})
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');

    messages.push({
      role: "user",
      content: `Lav et fængende socialt medie opslag baseret på følgende information:
                Mærke og model: ${selectedCar.value.fields?.Mærke || ''} ${selectedCar.value.fields?.Model || ''}
                Overskrift: ${selectedCar.value.headline || ''}
                Beskrivelse: ${selectedCar.value.description || ''}
                År: ${selectedCar.value.fields?.Årgang || ''}
                Pris: ${selectedCar.value.price || ''}
                Status: Nyhed
                
                Yderligere detaljer:
                ${carFields}
                
                Nuværende tekst (skal forbedres):
                ${previewText.value}
                
                Husk at følge reglerne i system-prompten, og lav en tekst der er klar til at blive postet direkte.`
    });
  }

  return messages;
};

const correctText = async (messages) => {
  try {
    const response = await generateAIText(messages);
    if (response?.correctedText) {
      return { correctedText: response.correctedText };
    }
    throw new Error('Uventet svarformat fra AI servicen');
  } catch (error) {
    console.error('Fejl under processering af tekst:', error);
    // Fallback til original implementation hvis AI service fejler
    try {
      const functionUrl = 'https://us-central1-toft-d4f39.cloudfunctions.net/correctText';
      
      const requestData = {
        userId: 'H3Ael2hkOQVlfFIRssVnM0BTB1H2',
        carId: selectedCar.value.id,
        emne: `${selectedCar.value.fields?.Mærke || ''} ${selectedCar.value.fields?.Model || ''}`,
        platform: 'facebook',
        messages: messages,
        carData: {
          ...selectedCar.value,
          maerke: selectedCar.value.fields?.Mærke || '',
          model: selectedCar.value.fields?.Model || '',
          description: selectedCar.value.description || '',
          headline: selectedCar.value.headline || '',
          price: selectedCar.value.price || '',
          fields: selectedCar.value.fields || {}
        }
      };

      const response = await axios.post(functionUrl, requestData, {
        headers: { 'Content-Type': 'application/json' }
      });
      
      if (response.data && response.data.correctedText) {
        console.log('Tekst genereret med fallback');
        return { correctedText: response.data.correctedText };
      }
      throw new Error('Uventet svarformat fra serveren');
    } catch (fallbackError) {
      console.error('Fejl i fallback:', fallbackError);
      throw fallbackError;
    }
  }
};

const closePreview = () => {
  showPreview.value = false;
};

const expandImage = (event, imageUrl) => {
  event.stopPropagation();
  expandedImage.value = imageUrl;
};

const closeExpandedImage = () => {
  expandedImage.value = null;
};

const isVideo = (file) => {
  return /\.(mp4|mov|webm)$/i.test(file?.split('?')[0] || '');
};

const playVideo = (event) => {
  const video = event.target;
  video.play();
};

const pauseVideo = (event) => {
  const video = event.target;
  video.pause();
  video.currentTime = 0;
};

onMounted(() => {
  fetchDesigns();
  
  // Tilføj event listener for at forhindre tastatur-genveje
  document.addEventListener('keydown', (e) => {
    // Forhindre Ctrl+S, Ctrl+U, Ctrl+P, og PrtScn
    if (
      (e.ctrlKey && (e.key === 's' || e.key === 'u' || e.key === 'p')) ||
      e.key === 'PrintScreen'
    ) {
      e.preventDefault();
    }
  });
});
</script>

<style scoped>
.preview-demo-section {
  padding: 2rem;
  background: #1d2025;
  position: relative;
  font-family: 'Poppins', sans-serif;
  height: 100%;
  overflow: hidden;
}

.preview-demo-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(45, 154, 206, 0.03) 0%, transparent 100%);
  z-index: 0;
}

.preview-demo-container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  height: calc(85vh - 200px);
  min-height: 600px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.section-header {
  text-align: center;
  margin-bottom: 2rem;
}

.section-header h2 {
  font-size: 2.5rem;
  background: linear-gradient(90deg, #fff, #e7dfd6);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 0 0.5rem;
  font-weight: 600;
}

.section-header p {
  color: #86868b;
  font-size: 1.1rem;
  max-width: 600px;
  margin: 0 auto;
}

.preview-layout {
  display: grid;
  grid-template-columns: minmax(300px, 2fr) 3fr;
  gap: 2rem;
  margin-top: 2rem;
  flex: 1;
  min-height: 0;
}

.car-selection-side {
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.car-selection-container {
  flex: 1;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 0;
  overflow: hidden;
}

.demo-car-selection {
  height: 100%;
}

.main-content {
  height: 100%;
  overflow-y: auto;
  padding-right: 4px;
}

/* Custom scrollbar styling */
.main-content::-webkit-scrollbar {
  width: 8px;
}

.main-content::-webkit-scrollbar-track {
  background: transparent;
}

.main-content::-webkit-scrollbar-thumb {
  background-color: rgba(45, 154, 206, 0.5);
  border-radius: 4px;
}

.main-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(45, 154, 206, 0.7);
}

.preview-side {
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  padding: 2rem;
}

.preview-placeholder {
  color: #86868b;
  text-align: center;
  font-size: 1.1rem;
  margin: auto;
}

.preview-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
}

.preview-content h3 {
  color: white;
  text-align: center;
  margin: 0;
  font-size: 1.5rem;
}

.preview-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.preview-button {
  padding: 1rem 2rem;
  background: linear-gradient(135deg, #2d9ace 0%, #2d5bce 100%);
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(45, 154, 206, 0.25);
}

.preview-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.preview-content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow-y: auto;
  max-height: calc(100vh - 300px);
  padding: 1rem;
  border-radius: 12px;
}

.preview-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preview-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  -webkit-user-select: none;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all 0.3s ease;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  pointer-events: none;
}

.preview-text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  cursor: default;
  pointer-events: none;
  overflow: auto;
  background: rgba(0, 0, 0, 0.85);
}

.preview-text-overlay.can-hover {
  cursor: pointer;
  pointer-events: auto;
}

.preview-image-container:hover .preview-text-overlay {
  opacity: 1;
}

.preview-text-content {
  color: white;
  font-size: 1rem;
  line-height: 1.4;
  text-align: left;
  width: 100%;
  max-width: 800px;
  transition: all 0.3s ease;
  white-space: pre-wrap;
  padding: 1.5rem;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin: 2rem;
  overflow-y: auto;
  max-height: none;
  height: auto;
  min-height: 0;
}

.hover-indicator {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.85);
  padding: 8px 16px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
  font-size: 0.9rem;
  opacity: 0.8;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  z-index: 2;
}

.preview-image-container:hover .hover-indicator:not(.is-loading) {
  opacity: 0;
  transform: translateY(10px);
}

.hover-indicator.is-loading {
  background: rgba(0, 0, 0, 0.85);
  animation: none;
  opacity: 1 !important;
}

.loading-spinner.small {
  width: 16px;
  height: 16px;
  border-width: 1.5px;
}

.loading-spinner {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 2px solid white;
  animation: spin 1s linear infinite;
}

.preview-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin: auto;
}

.preview-loading .loading-spinner {
  width: 40px;
  height: 40px;
}

.loading-text {
  color: white;
  font-size: 1.1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 1024px) {
  .preview-layout {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .car-selection-side {
    height: 400px;
  }
}

@media (max-width: 768px) {
  .preview-demo-section {
    padding: 1rem;
  }

  .section-header h2 {
    font-size: 2rem;
  }

  .preview-button {
    width: 100%;
  }
}

/* Design Selection Styles */
.designs-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.designs-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(250px, 1fr));
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  min-height: 300px;
}

/* Navigation Styles */
.design-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.nav-button {
  background: rgba(45, 154, 206, 0.1);
  border: 1px solid rgba(45, 154, 206, 0.2);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-button:hover:not(:disabled) {
  background: rgba(45, 154, 206, 0.2);
  border-color: rgba(45, 154, 206, 0.3);
}

.nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-info {
  color: #86868b;
  font-size: 0.9rem;
  min-width: 60px;
  text-align: center;
}

.design-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px solid transparent;
  height: 100%;
  min-height: 200px;
}

.design-card:hover {
  transform: translateY(-5px);
  border-color: rgba(45, 154, 206, 0.5);
  box-shadow: 0 5px 15px rgba(45, 154, 206, 0.2);
}

.design-card.selected {
  border-color: #2d9ace;
  box-shadow: 0 5px 15px rgba(45, 154, 206, 0.3);
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.design-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.design-card:hover .design-image {
  transform: scale(1.05);
}

/* Fjern expand-knap og relaterede styles */

/* Scrollbar styles */
.designs-container::-webkit-scrollbar {
  width: 8px;
}

.designs-container::-webkit-scrollbar-track {
  background: transparent;
}

.designs-container::-webkit-scrollbar-thumb {
  background-color: rgba(45, 154, 206, 0.5);
  border-radius: 4px;
}

.designs-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(45, 154, 206, 0.7);
}

/* Disable preview button when no design is selected */
.preview-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: linear-gradient(135deg, #2d9ace80 0%, #2d5bce80 100%);
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90vh;
}

.expanded-image {
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
}

.close-modal {
  position: absolute;
  top: -40px;
  right: 0;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.design-card.selected {
  border: 2px solid #2d9ace;
}

.hover-indicator {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.85);
  padding: 8px 16px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
  font-size: 0.9rem;
  opacity: 0.8;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
}

.hover-indicator.is-loading {
  background: rgba(0, 0, 0, 0.85);
  animation: none;
}

.loading-spinner.small {
  width: 16px;
  height: 16px;
  border-width: 1.5px;
}

.preview-image-container:hover .hover-indicator {
  opacity: 0;
  transform: translateY(10px);
}

/* Kun vis hover effekt når teksten er klar */
.preview-image-container:hover .preview-text-overlay {
  opacity: 1;
}

.preview-text-overlay {
  opacity: 0;
  pointer-events: none;
}

.preview-text-overlay.can-hover {
  cursor: pointer;
  pointer-events: auto;
}

.preview-image-container:hover .preview-text-overlay.can-hover {
  opacity: 1;
}

.preview-image-container:hover .preview-text-overlay:not(.can-hover) {
  opacity: 0;
}

.preview-image-container:hover .hover-indicator {
  opacity: 0;
  transform: translateY(10px);
}

.hover-indicator {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.85);
  padding: 8px 16px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
  font-size: 0.9rem;
  opacity: 0.8;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
}

.hover-indicator.is-loading {
  background: rgba(0, 0, 0, 0.85);
  animation: none;
}

@keyframes pulse {
  0% { opacity: 0.8; }
  50% { opacity: 0.8; }
  100% { opacity: 0.8; }
}

@media (max-width: 768px) {
  .designs-container {
    grid-template-columns: 1fr;
  }
  
  .design-card {
    min-height: 180px;
  }
}

.bottom-text-section {
  text-align: center;
  margin-top: 2rem;
  padding: 1rem;
  color: #86868b;
  font-size: 1.1rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .bottom-text-section {
    font-size: 1rem;
    padding: 0.5rem;
    margin-top: 1rem;
  }
}

.steps-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  color: #86868b;
}

.steps-list li {
  margin: 0.5rem 0;
}

.design-video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.video-indicator {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.design-card:hover .design-video {
  transform: scale(1.05);
}

.video-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}

.loading-content {
  width: 80%;
  max-width: 400px;
  text-align: center;
}

.progress-bar {
  width: 100%;
  height: 4px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 2px;
  overflow: hidden;
  position: relative;
}

.progress-pulse {
  position: absolute;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(45, 154, 206, 0.8),
    rgba(45, 91, 206, 0.8),
    transparent
  );
  animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(200%);
  }
}

.loading-text {
  color: white;
  font-size: 1rem;
  margin-top: 1rem;
}

.video-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 12px;
}

.preview-video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 12px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 3px solid #2d9ace;
  animation: spin 1s linear infinite;
  margin: 0 auto 1rem;
}

.loading-content {
  text-align: center;
  padding: 2rem;
}

.loading-text {
  color: white;
  font-size: 1.1rem;
  margin-top: 1rem;
  font-weight: 500;
}

.video-generation-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(29, 32, 37, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  backdrop-filter: blur(10px);
}

.generation-content {
  width: 90%;
  max-width: 500px;
  text-align: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.generation-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.status-title {
  color: white;
  font-size: 1.5rem;
  margin: 0;
  font-weight: 500;
}

.progress-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.progress-bar {
  width: 100%;
  height: 6px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: linear-gradient(90deg, #2d9ace, #2d5bce);
  transition: width 0.5s ease;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(45, 154, 206, 0.5);
}

.progress-text {
  color: #86868b;
  font-size: 0.9rem;
  text-align: center;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid rgba(45, 154, 206, 0.2);
  border-radius: 50%;
  border-top: 3px solid #2d9ace;
  animation: spin 1s linear infinite;
}

.loading-spinner.small {
  width: 20px;
  height: 20px;
  border-width: 2px;
  margin-right: 8px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.ml-2 {
  margin-left: 0.5rem;
}

.error-message {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 12px;
  color: white;
  text-align: center;
  margin: 1rem 0;
  font-size: 1.1rem;
  line-height: 1.5;
  max-width: 600px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style> 