<template>
  <div class="row">
    <!-- Profile Card -->
    <div class="col-12">
      <div class="card card-profile h-100">
        <div class="card-body text-center">
          <h5 class="mt-3">{{ localUserData.MetaSettings?.name }}</h5>
          
          <!-- Connect Facebook Button -->
          <argon-button
            color="primary"
            class="mt-3 btn btn-facebook"
            @click="connectFacebook"
          >
            <i class="fab fa-facebook-f"></i> {{ localUserData.MetaSettings?.facebookPageId ? 'Reconnect' : 'Connect' }} Facebook
          </argon-button>
          
          <!-- Tilføj ikoner for Facebook og Instagram -->
          <div class="mt-4 social-icons">
            <a 
              v-if="localUserData.MetaSettings?.facebookPageId" 
              :href="`https://facebook.com/${localUserData.MetaSettings.facebookPageId}`"
              target="_blank"
              rel="noopener noreferrer"
              class="icon-wrapper facebook"
            >
              <i class="fab fa-facebook-f"></i>
            </a>
            <a 
              v-if="localUserData.MetaSettings?.facebookPageId" 
              @click.prevent="openInstagramProfile"
              href="#"
              class="icon-wrapper instagram"
            >
              <i class="fab fa-instagram"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Platform Settings Card below Profile Card -->
    <div class="col-12 mt-4">
      <div class="card card-profile h-100">
        <div class="p-3 pb-0 card-header text-center">
          <h6 class="mb-0">Platform Settings</h6>
        </div>
        <div class="p-3 card-body">
          <h6 class="text-xs text-uppercase text-body font-weight-bolder">
            Account
          </h6>
          <ul class="list-group">
            <li v-if="localUserData.companyType === 'auto'" class="px-0 border-0 list-group-item">
              <argon-switch
                id="flexSwitchCheckDefault"
                name="futurePosts"
                label-class="mb-0 text-body ms-3 text-truncate w-80"
                :checked="localUserData.settings.futurePosts"
                @change="toggleSetting('futurePosts', $event)"
              >
                Planlæg 3 uger frem i tiden autopilot
              </argon-switch>
            </li>
            <li class="px-0 border-0 list-group-item">
              <argon-switch
                id="flexSwitchCheckDefault1"
                name="emailWhenAnswered"
                label-class="mb-0 text-body ms-3 text-truncate w-80"
                :checked="localUserData.settings.emailWhenAnswered"
                @change="toggleSetting('emailWhenAnswered', $event)"
              >
                Email me when someone answers on my post
              </argon-switch>
            </li>

            <li class="px-0 border-0 list-group-item">
              <argon-switch
                id="flexSwitchCheckDefault2"
                name="emailWhenMentioned"
                label-class="mb-0 text-body ms-3 text-truncate w-80"
                :checked="localUserData.settings.emailWhenMentioned"
                @change="toggleSetting('emailWhenMentioned', $event)"
              >
                Email me when someone mentions me
              </argon-switch>
            </li>
          </ul>
          <h6 class="mt-4 text-xs text-uppercase text-body font-weight-bolder">
            Application
          </h6>
          <ul class="list-group">
            <li class="px-0 border-0 list-group-item">
              <argon-switch
                id="flexSwitchCheckDefault3"
                name="newLaunchesAndProjects"
                label-class="mb-0 text-body ms-3 text-truncate w-80"
                :checked="localUserData.settings.newLaunchesAndProjects"
                @change="toggleSetting('newLaunchesAndProjects', $event)"
              >
                New launches and projects
              </argon-switch>
            </li>
            <li class="px-0 border-0 list-group-item">
              <argon-switch
                id="flexSwitchCheckDefault4"
                name="monthlyProductUpdates"
                label-class="mb-0 text-body ms-3 text-truncate w-80"
                :checked="localUserData.settings.monthlyProductUpdates"
                @change="toggleSetting('monthlyProductUpdates', $event)"
              >
                Monthly product updates
              </argon-switch>
            </li>
            <li class="px-0 pb-0 border-0 list-group-item">
              <argon-switch
                id="flexSwitchCheckDefault5"
                name="subscribeToNewsletter"
                label-class="mb-0 text-body ms-3 text-truncate w-80"
                :checked="localUserData.settings.subscribeToNewsletter"
                @change="toggleSetting('subscribeToNewsletter', $event)"
              >
                Subscribe to newsletter
              </argon-switch>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, watch, computed, ref, onMounted } from "vue";
import { defineProps } from "vue";
import { db } from "../../../firebase.js";
import { doc, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useStore } from "vuex";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import config from '../../../../functions/config.cjs';

const auth = getAuth();
const store = useStore();

const props = defineProps({
  userData: {
    type: Object,
    required: true,
  },
});

// Computed property to get the correct user ID
const userId = computed(() => props.userData.id);

// Opret en lokal reaktiv kopi af userData
const localUserData = reactive({ ...props.userData });

// Sørg for at settings eksisterer i localUserData
if (!localUserData.settings) {
  localUserData.settings = {
    futurePosts: false,
    emailWhenAnswered: false,
    emailWhenMentioned: false,
    newLaunchesAndProjects: false,
    monthlyProductUpdates: false,
    subscribeToNewsletter: false,
  };
}

// Overvåg ændringer i props.userData og opdater localUserData
watch(
  () => props.userData,
  (newVal) => {
    Object.assign(localUserData, newVal);
    if (!localUserData.settings) {
      localUserData.settings = {
        futurePosts: false,
        emailWhenAnswered: false,
        emailWhenMentioned: false,
        newLaunchesAndProjects: false,
        monthlyProductUpdates: false,
        subscribeToNewsletter: false,
      };
    }
  },
  { deep: true, immediate: true },
);

// Toggle Setting metode
const toggleSetting = async (settingKey, event) => {
  const settingValue = event.target.checked;
  console.log(`Toggling ${settingKey} to ${settingValue}`);

  // Opdater lokal tilstand
  if (!localUserData.settings) {
    localUserData.settings = {};
  }
  localUserData.settings[settingKey] = settingValue;

  // Opdater Firestore
  const userDocRef = doc(db, "users", userId.value);
  const updateData = {};
  updateData[`settings.${settingKey}`] = settingValue;

  try {
    await updateDoc(userDocRef, updateData);
    console.log(`Updated ${settingKey} to ${settingValue} in Firestore for user ${userId.value}.`);
  } catch (error) {
    console.error("Error updating settings in Firestore:", error);
    // Revert local state if update fails
    localUserData.settings[settingKey] = !settingValue;
  }
};

// Tilføj disse nye variabler
const facebookPageId = ref('');
const instagramBusinessAccountId = ref('');

// Initialiser Facebook SDK
const isFacebookInitialized = ref(false);

const FACEBOOK_APP_ID = config.meta.appId;

const initFacebookSdk = () => {
  return new Promise((resolve) => {
    if (window.FB) {
      isFacebookInitialized.value = true;
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://connect.facebook.net/en_US/sdk.js';
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    script.onload = () => {
      if (!FACEBOOK_APP_ID) {
        console.error('Facebook App ID er ikke konfigureret. Facebook-login vil ikke fungere.');
      }
      window.FB.init({
        appId: FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v16.0'
      });
      isFacebookInitialized.value = true;
      resolve();
    };
  });
};

onMounted(() => {
  initFacebookSdk();
});

const connectFacebook = async () => {
  try {
    if (!isFacebookInitialized.value) {
      await initFacebookSdk();
    }

    if (!FACEBOOK_APP_ID) {
      alert('Facebook App ID er ikke konfigureret. Facebook-login er ikke tilgængelig.');
      return;
    }

    const response = await new Promise((resolve, reject) => {
      window.FB.login(resolve, { 
        scope: 'public_profile,email,pages_show_list,instagram_basic,pages_read_engagement',
        return_scopes: true
      });
    });

    if (response.authResponse) {
      console.log("Login response:", response);
      if (response.authResponse.grantedScopes) {
        console.log("Tildelte tilladelser:", response.authResponse.grantedScopes);
      }
      await fetchFacebookData(response.authResponse.accessToken);
    } else {
      console.log("Bruger afbrød Facebook login eller login mislykkedes.");
      alert("Facebook-login blev afbrudt eller mislykkedes. Prøv venligst igen.");
    }
  } catch (error) {
    console.error('Fejl ved Facebook-login:', error);
    alert(`Der opstod en fejl under Facebook-login: ${error.message}. Prøv venligst igen senere eller opdater din profil manuelt.`);
  }
};

// Hent Facebook Data
const fetchFacebookData = async (accessToken) => {
  try {
    console.log("Starter fetchFacebookData med accessToken:", accessToken);

    // Hent brugerens Facebook-ID og e-mail
    const userResponse = await new Promise((resolve, reject) => {
      FB.api('/me', { fields: 'id,name,email', access_token: accessToken }, resolve);
    });

    console.log("Bruger response:", userResponse);

    if (userResponse.error) {
      throw new Error(userResponse.error.message);
    }

    const userId = userResponse.id;
    const userEmail = userResponse.email;
    console.log("Bruger ID:", userId);
    console.log("Bruger e-mail:", userEmail);

    // Hent brugerens sider
    const pagesResponse = await new Promise((resolve, reject) => {
      FB.api(`/${userId}/accounts`, { 
        access_token: accessToken,
        fields: 'id,name,access_token,category,tasks,emails'
      }, resolve);
    });

    console.log("Sider response:", pagesResponse);

    if (pagesResponse.error) {
      throw new Error(pagesResponse.error.message);
    }

    if (pagesResponse.data.length === 0) {
      console.log("Ingen sider fundet. Fuld respons:", pagesResponse);
      alert("Ingen Facebook-sider fundet. Sørg for, at du har adgang til mindst én Facebook-side og at du har givet de nødvendige tilladelser.");
      return;
    }

    // Find den matchende side baseret på brugerens e-mail
    let matchedPage = pagesResponse.data.find(page => page.emails && page.emails.includes(userEmail));

    // Hvis ingen match blev fundet, brug den første side som standard
    if (!matchedPage) {
      console.log("Ingen matchende side fundet. Bruger den første side som standard.");
      matchedPage = pagesResponse.data[0];
    }

    console.log("Valgt side:", matchedPage);

    // Brug den matchende side
    facebookPageId.value = matchedPage.id;
    const pageAccessToken = matchedPage.access_token;
    
    // Hent yderligere information om den valgte side
    const pageDetailsResponse = await new Promise((resolve, reject) => {
      FB.api(
        `/${matchedPage.id}`,
        { 
          access_token: pageAccessToken,
          fields: 'name,about,phone,location,website,instagram_business_account'
        },
        resolve
      );
    });

    console.log("Side detaljer:", pageDetailsResponse);

    if (pageDetailsResponse.instagram_business_account) {
      instagramBusinessAccountId.value = pageDetailsResponse.instagram_business_account.id;
    }

    // Opdater brugerdata med page_access_token
    updateUserDataFromFacebook(pageDetailsResponse, pageAccessToken);

  } catch (error) {
    console.error("Fejl ved hentning af Facebook-data:", error);
    alert(`Der opstod en fejl ved hentning af dine Facebook-data: ${error.message}. Prøv venligst igen.`);
  }
};

// Opdater brugerdata fra Facebook
const updateUserDataFromFacebook = async (pageData, pageAccessToken) => {
  const user = auth.currentUser;
  if (user) {
    const userDocRef = doc(db, "users", user.uid);

    const metaSettings = {
      name: pageData.name || null,
      about: pageData.about || null,
      phone: pageData.phone || null,
      address: pageData.location ? `${pageData.location.street}, ${pageData.location.city}, ${pageData.location.country}` : null,
      website: pageData.website || null,
      facebookPageId: facebookPageId.value || null,
      instagramBusinessAccountId: instagramBusinessAccountId.value || null,
      page_access_token: pageAccessToken || null,
    };

    const updatedData = {
      MetaSettings: metaSettings,
    };

    try {
      await updateDoc(userDocRef, updatedData);

      // Opdater lokal userData
      localUserData.MetaSettings = metaSettings;
      
      console.log("Brugerdata opdateret fra Facebook og Instagram:", metaSettings);
      alert("Brugerdata opdateret fra Facebook og Instagram!");
    } catch (error) {
      console.error("Fejl ved opdatering af brugerdata fra Facebook og Instagram:", error);
      alert(`Fejl ved opdatering af brugerdata fra Facebook og Instagram: ${error.message}`);
    }
  }
};

// Define emits for update-user-data and update-profile-image
defineEmits(['update-user-data', 'update-profile-image']);

const updateProfileManually = () => {
  // Implementer logik for manuel profilopdatering her
  alert('Manuel profilopdatering er ikke implementeret endnu.');
};

const openInstagramProfile = async () => {
  try {
    if (!localUserData.MetaSettings?.facebookPageId || !localUserData.MetaSettings?.page_access_token) {
      console.error('Mangler Facebook Page ID eller access token');
      return;
    }

    const url = `https://graph.facebook.com/v20.0/${localUserData.MetaSettings.facebookPageId}`;
    const params = new URLSearchParams({
      fields: 'instagram_business_account{username}',
      access_token: localUserData.MetaSettings.page_access_token
    });

    const response = await fetch(`${url}?${params}`);
    const data = await response.json();

    const username = data?.instagram_business_account?.username;

    if (username) {
      const instagramUrl = `https://www.instagram.com/${username}`;
      window.open(instagramUrl, '_blank', 'noopener,noreferrer');
    } else {
      console.error('Ingen Instagram Business Account er tilknyttet denne Facebook-side');
      alert('Kunne ikke finde den tilknyttede Instagram-profil. Kontroller at din Instagram-konto er korrekt forbundet med din Facebook-side.');
    }
  } catch (error) {
    console.error('Fejl ved åbning af Instagram profil:', error);
    alert('Der opstod en fejl ved åbning af Instagram profilen. Prøv venligst igen senere.');
  }
};
</script>

<style lang="scss" scoped>
.card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 
    0 8px 32px 0 rgba(31, 38, 135, 0.37),
    0 0 0 1px rgba(255, 255, 255, 0.05);
  transform: translateZ(0);
  will-change: transform;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);

  &:hover {
    transform: translateY(-2px) translateZ(0);
  }

  .card-body {
    padding: 2rem !important;
  }

  .card-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background: transparent;
    padding: 1.5rem 2rem !important;

    h6 {
      color: rgba(255, 255, 255, 0.9);
      font-weight: 600;
      letter-spacing: 0.5px;
      margin: 0;
    }
  }

  h5 {
    color: rgba(255, 255, 255, 0.9);
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  .text-xs {
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.7);
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 1rem;
  }
}

/* Button styling */
.btn-facebook {
  background: linear-gradient(122deg, #1568db, #05c2ed);
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  padding: 0.75rem 1.5rem;
  border-radius: 12px;
  border: none;
  
  &:hover {
    background: linear-gradient(122deg, #1568db, #05c2ed);
    opacity: 0.9;
    transform: translateY(-1px);
    box-shadow: 
      0 4px 12px rgba(21, 104, 219, 0.2);
  }

  i {
    margin-right: 0.5rem;
  }
}

/* Social icons styling */
.social-icons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.5rem;

  .icon-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
    backdrop-filter: blur(8px);
    cursor: pointer;

    i {
      font-size: 1.25rem;
      color: rgba(255, 255, 255, 0.9);
    }

    &.facebook {
      background: rgba(59, 89, 152, 0.15);
      border: 1px solid rgba(59, 89, 152, 0.3);

      &:hover {
        background: rgba(59, 89, 152, 0.25);
        transform: translateY(-2px);
        box-shadow: 
          0 4px 12px rgba(59, 89, 152, 0.2),
          0 0 0 1px rgba(59, 89, 152, 0.3);
      }
    }

    &.instagram {
      background: linear-gradient(
        45deg,
        rgba(64, 93, 230, 0.15),
        rgba(88, 81, 219, 0.15),
        rgba(131, 58, 180, 0.15),
        rgba(193, 53, 132, 0.15),
        rgba(225, 48, 108, 0.15),
        rgba(253, 29, 29, 0.15)
      );
      border: 1px solid rgba(225, 48, 108, 0.3);

      &:hover {
        background: linear-gradient(
          45deg,
          rgba(64, 93, 230, 0.25),
          rgba(88, 81, 219, 0.25),
          rgba(131, 58, 180, 0.25),
          rgba(193, 53, 132, 0.25),
          rgba(225, 48, 108, 0.25),
          rgba(253, 29, 29, 0.25)
        );
        transform: translateY(-2px);
        box-shadow: 
          0 4px 12px rgba(225, 48, 108, 0.2),
          0 0 0 1px rgba(225, 48, 108, 0.3);
      }
    }
  }
}

/* List group styling */
.list-group {
  background: transparent;
  margin-bottom: 1.5rem;

  .list-group-item {
    background: transparent;
    padding: 0.75rem 0;
    border: none;
    
    &:last-child {
      padding-bottom: 0;
    }
  }
}

/* Switch styling */
:deep(.custom-control) {
  padding-left: 3rem;
  min-height: 1.5rem;
  display: flex;
  align-items: center;

  .custom-control-label {
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.875rem;
    cursor: pointer;

    &::before {
      background-color: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.2);
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    }

    &::after {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background: linear-gradient(
      135deg,
      rgba(94, 114, 228, 0.8),
      rgba(130, 94, 228, 0.8)
    );
    border-color: rgba(94, 114, 228, 0.3);
  }
}

/* Animation */
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
}

.card {
  animation: slideUp 0.3s cubic-bezier(0.2, 0, 0.2, 1);
}

/* Spacing */
.mt-4 {
  margin-top: 2rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.text-center {
  text-align: center;
}

/* Text colors */
.text-body {
  color: rgba(255, 255, 255, 0.9) !important;
}

.text-muted {
  color: rgba(255, 255, 255, 0.6) !important;
}

/* Tilføj header styling */
.page-header {
  background-color: var(--sidenav-color);
  transition: background-color 0.3s ease;
}

.mask {
  background-color: inherit;
  opacity: 0.9;
}
</style>
