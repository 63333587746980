export default {
  common: {
    settings: 'Settings',
    profile: 'Profile',
    save: 'Save',
    cancel: 'Cancel',
    delete: 'Delete',
    edit: 'Edit',
    loading: 'Loading...',
    error: 'An error occurred',
    success: 'Action completed successfully',
    confirm: 'Confirm',
    language: 'Language',
    yes: 'Yes',
    no: 'No',
    upload: 'Upload',
    download: 'Download',
    close: 'Close',
    search_user: 'Search user...',
    vis_alle: 'Show all',
    vis_kun_admins: 'Show only Admins',
    vis_kun_brugere: 'Show only Users',
    administrer: 'Manage',
    opret_faktura: 'Create invoice',
    oprettet_af: 'Created by {name}',
    brugere_antal: 'Users ({count})',
    online: 'Online',
    offline: 'Offline',
    omdirigerer_til_login: 'Redirecting to login...',
    opslag: 'Posts'
  },

  // Auto-generated translations
  auto: {
    log_ind: 'Sign in',
    markedets_mest_intelligente: 'The market\'s most intelligent',
    markedsføringssystem_til_bilbranchen: 'marketing system for the automotive industry!',
    en_fremtidssikret_løsning_for_bilbranchen_med_vores_intelligente_markedsføringssystem_kan_du_let_automatisere_dine_salgsemner_til_sociale_medier_nemt_hurtigt_og_helt_uden_at_røre_en_finger: 'A future-proof solution for the automotive industry. With our intelligent marketing system, you can easily automate your sales leads to social media - easily, quickly, and completely hands-free.',
    book_en_demo: 'Book a demo',
    logger_ind: 'Signing in...',
    automatis_r_din_markedsføring: 'Automate your marketing',
    slip_for_tidskrævende_manuelt_arbejde_og_lad_vores_system_håndtere_alt_det_tekniske_med_vores_løsning_kan_du_fokusere_på_det_vigtigste_at_drive_din_forretning: 'Avoid time-consuming manual work and let our system handle all the technical aspects. With our solution, you can focus on what\'s most important - running your business.',
    udforsk_mulighederne_med_auto_family: 'Explore the possibilities with Auto Family',
    automatisering: 'Automation',
    kontrol: 'Control',
    analyse: 'Analysis',
    intelligent_automatisering: 'Intelligent Automation',
    lad_vores_system_håndtere_alt_det_tekniske_mens_du_fokuserer_på_at_drive_din_forretning: 'Let our system handle all the technical aspects while you focus on running your business.',
    professionelt_design: 'Professional Design',
    skab_flotte_og_professionelle_opslag_med_vores_omfattende_samling_af_skabeloner: 'Create beautiful and professional posts with our extensive collection of templates.',
    detaljeret_indsigt: 'Detailed Insight',
    få_værdifuld_indsigt_i_dine_kampagners_performance_og_optimer_din_markedsføring: 'Gain valuable insights into your campaign performance and optimize your marketing.',
    det_får_du_blandt_andet: 'Here\'s what you get',
    med_auto_family: 'With Auto Family',
    fuldautomatisk: 'Fully automatic',
    publicering: 'Publishing',
    med_auto_family_kan_du_let_automatisere_dine_biler_og_lagerlister_til_sociale_medier_nemt_hurtigt_og_100_automatisk_helt_uden_din_indflydelse: 'With Auto Family, you can easily automate your cars and inventory lists to social media - easily, quickly, and 100% automatically. Completely without your involvement.',
    billede_skabeloner: 'Image templates',
    grafisk_design: 'Graphic design',
    få_din_virksomhed_til_at_se_mere_professionel_ud_med_vores_templates_alle_designs_tilpasser_sig_automatisk_din_forretning_og_emner: 'Make your business look more professional with our templates. All designs automatically adapt to your business and topics.',
    dit_eget_dashboard: 'Your own dashboard',
    statistik: 'Statistics',
    få_en_bedre_forståelse_af_hvordan_dit_publikum_interagerer_med_dit_indhold_vi_holder_styr_på_dine_resultater_og_samler_dem_i_et_nemt_og_overskueligt_overblik: 'Get a better understanding of how your audience interacts with your content. We keep track of your results and compile them in an easy and clear overview.',
    video_skabeloner: 'Video templates',
    videoindhold: 'Video content',
    er_statiske_opslag_ikke_nok_kan_du_vælge_at_gøre_brug_af_vores_build_in_video_templates_vi_laver_dine_videoer_fuldt_automatiserede_og_professionelt: 'If static posts aren\'t enough, you can choose to use our built-in video templates. We create your videos fully automated and professionally.',
    maskingenererede: 'Machine-generated',
    tekster: 'Texts',
    i_auto_family_behøver_du_aldrig_skrive_en_tekst_mere_vi_sørger_for_at_alt_indhold_skrives_automatisk_for_dig_ved_hjælp_af_vores_tekstkombinationer_kombineret_med_vores_ai: 'In Auto Family, you\'ll never have to write a text again. We ensure that all content is written automatically for you using our text combinations combined with our AI.',
    multifunktionelle: 'Multifunctional',
    kalender: 'Calendar',
    vores_multifunktionelle_kalender_samler_alt_i_et_og_giver_dig_1_click_indstillinger_til_at_styre_alt_et_sted_fra_så_du_kan_fokusere_på_at_drive_din_forretning: 'Our multifunctional calendar brings everything together and gives you 1-click settings to control everything from one place, so you can focus on running your business.',
    fuldautomatiske_funktioner_er_inkluderet_til_planlægning_og_publicering_af_billeder_videoer_og_tekster_derudover_tilbyder_vi_ai_assisterede_tekstmoduler_som_kan_hjælpe_dig_med_at_skrive_eller_redigere_indhold_vi_tilbyder_også_semi_automatisk_publicering_af_alle_dine_biler_via_vores_indbyggede_publiceringsværktøj_dette_værktøj_forstår_konteksten_af_både_videoer_og_billeder_og_leverer_en_hjælp_du_aldrig_har_oplevet_før: 'Fully automatic features are included for planning and publishing images, videos, and texts. Additionally, we offer AI-assisted text modules that can help you write or edit content. We also offer semi-automatic publishing of all your cars through our built-in publishing tool. This tool understands the context of both videos and images and delivers assistance you\'ve never experienced before.',
    effektivitet_skræddersyede_løsninger: 'Efficiency & tailored solutions',
    auto_family_er_det_eneste_system_der_automatiserer_gennem_programmer_og_algoritmer_for_at_spare_tid_uden_at_gå_ned_på_kvaliteten: 'Auto Family is the only system that automates through programs and algorithms to save time without compromising quality.',
    book_en_30_minutters_demo: 'Book a 30-minute demo',
    oplev_hvordan_vores_system_kan_hjælpe_din_forretning_og_markedsføring_og_spare_dig_for_værdifuld_tid: 'Experience how our system can help your business and marketing, saving you valuable time!',
    kontroller_hvilke_enheder_der_har_adgang_til_din_konto: 'Control which devices have access to your account',
    find_min_enhed_sørg_for_at_din_enhed_kan_findes_hvis_den_bliver_væk: 'Find my device, ensure your device can be found if lost',
    lås_din_enhed_med_en_pin_mønster_eller_adgangskode: 'Lock your device with a PIN, pattern, or password',
    administrer_hvilke_apps_der_har_adgang_til_app_brugsdata_på_din_enhed: 'Manage which apps have access to app usage data on your device',
    gem_ændringer: 'Save changes',
    send_nulstillingslink: 'Send reset link',
    opdater_dit_profilbillede_coverbillede_og_navn_her: 'Update your profile picture, cover image, and name here.',
    indtast_dine_meta_indstillinger_her: 'Enter your Meta settings here.',
    indtast_dine_faktureringsoplysninger_her: 'Enter your billing information here.',
    firmanavn: 'Company name',
    cvr_nummer: 'Company registration number',
    adresse: 'Address',
    postnummer: 'Postal code',
    by: 'City',
    telefon: 'Phone',
    email: 'Email',
    reg_nr: 'Registration no.',
    kontonummer: 'Account number',
    website: 'Website',
    faktura_footer_tekst: 'Invoice footer text',
    white_label_indstillinger: 'White Label Settings',
    tilpas_platformens_udseende_til_dit_brand_alle_ændringer_sker_live: 'Customize the platform\'s appearance to your brand, all changes happen live.',
    definer_dit_brands_farveidentitet_her: 'Define your brand\'s color identity here.',
    nulstil: 'Reset',
    baggrund: 'Background',
    header: 'Header',
    gradient: 'Gradient',
    fra: 'From',
    til: 'To',
    kort: 'Card',
    browser_tilpasning: 'Browser Customization',
    tilpas_hvordan_din_applikation_vises_i_browseren: 'Customize how your application appears in the browser',
    titlen_der_vises_i_browser_fanen: 'The title shown in the browser tab',
    det_lille_ikon_der_vises_i_browser_fanen: 'The small icon shown in the browser tab',
    anbefalet_størrelse_32x32_pixels_format_png: 'Recommended size: 32x32 pixels, format: PNG',
    domæne_opsætning: 'Domain Setup',
    subdomæne: 'Subdomain',
    vælg_et_unikt_subdomæne_for_din_applikation_minimum_6_tegn: 'Choose a unique subdomain for your application (minimum 6 characters).',
    hjem: 'Home',
    admin_panel: 'Admin Panel',
    indstillinger: 'Settings',
    opret_designs: 'Create Designs',
    blog: 'Blog',
    økonomi: 'Economy',
    log_ud: 'Sign out',
    vis_alle_brugere: 'Show all users',
    tilføj_virksomhed: 'Add company',
    on_off: 'On/Off',
    bruger: 'User',
    actions: 'Actions',
    quick_setup: 'Quick Setup',
    tilknyt_meta: 'Link Meta',
    prøveperiode_14_dage: 'Trial period (14 days)',
    fakturering: 'Billing',
    sikkerhedshandlinger: 'Security actions',
    opsæt_autoposts: 'Set up AutoPosts',
    autoposts_opsat: 'AutoPosts set up',
    tilknyt_facebook: 'Link Facebook',
    mangler_dealer_id: 'Missing dealer ID',
    tilknytter: 'Linking...',
    tilknyttet: 'Linked',
    manager: 'Manager',
    sender: 'Sending...',
    nulstil_kode: 'Reset code',
    sletter: 'Deleting...',
    slet: 'Delete',
    forrige: 'Previous',
    næste: 'Next',
    feature: '{{ feature }}',
    det_med_småt: 'The fine print',
    term: '{{ term }}',
    kontakt: 'Contact',
    tlf: 'Phone',
    hurtig_link: 'Quick link',
    andet: 'Other',
    privatlivspolitik: 'Privacy policy',
    vis: 'Show',
    skjul: 'Hide',
    opslag: 'Posts'
  }
}; 
