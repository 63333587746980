const BASE_URL = 'https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized';

export const brandLogoMapping = {
  // Almindelige varianter
  'vw': { slug: 'volkswagen', logoUrl: `${BASE_URL}/volkswagen.png` },
  'volkswagen': { slug: 'volkswagen', logoUrl: `${BASE_URL}/volkswagen.png` },
  'citroën': { slug: 'citroen', logoUrl: `${BASE_URL}/citroen.png` },
  'citroen': { slug: 'citroen', logoUrl: `${BASE_URL}/citroen.png` },
  'citröen': { slug: 'citroen', logoUrl: `${BASE_URL}/citroen.png` },
  'citro': { slug: 'citroen', logoUrl: `${BASE_URL}/citroen.png` },
  'mercedes': { slug: 'mercedes-benz', logoUrl: `${BASE_URL}/mercedes-benz.png` },
  'mercedesbenz': { slug: 'mercedes-benz', logoUrl: `${BASE_URL}/mercedes-benz.png` },
  'mercedes-benz': { slug: 'mercedes-benz', logoUrl: `${BASE_URL}/mercedes-benz.png` },

  // A
  'abarth': { slug: 'abarth', logoUrl: `${BASE_URL}/abarth.png` },
  'ac': { slug: 'ac', logoUrl: `${BASE_URL}/ac.png` },
  'adria': { slug: 'adria', logoUrl: `${BASE_URL}/adria.png` },
  'ahorn-camp': { slug: 'ahorn-camp', logoUrl: `${BASE_URL}/ahorn-camp.png` },
  'aiways': { slug: 'aiways', logoUrl: `${BASE_URL}/aiways.png` },
  'alfa-romeo': { slug: 'alfa-romeo', logoUrl: `${BASE_URL}/alfa-romeo.png` },
  'alfaromeo': { slug: 'alfa-romeo', logoUrl: `${BASE_URL}/alfa-romeo.png` },
  'alpina': { slug: 'alpina', logoUrl: `${BASE_URL}/alpina.png` },
  'armstrong-siddeley': { slug: 'armstrong-siddeley', logoUrl: `${BASE_URL}/armstrong-siddeley.png` },
  'aston-martin': { slug: 'aston-martin', logoUrl: `${BASE_URL}/aston-martin.png` },
  'astonmartin': { slug: 'aston-martin', logoUrl: `${BASE_URL}/aston-martin.png` },
  'auburn': { slug: 'auburn', logoUrl: `${BASE_URL}/auburn.png` },
  'audi': { slug: 'audi', logoUrl: `${BASE_URL}/audi.png` },
  'austin': { slug: 'austin', logoUrl: `${BASE_URL}/austin.png` },
  'austin-healey': { slug: 'austin-healey', logoUrl: `${BASE_URL}/austin-healey.png` },
  'auto-union': { slug: 'auto-union', logoUrl: `${BASE_URL}/auto-union.png` },

  // B
  'benimar': { slug: 'benimar', logoUrl: `${BASE_URL}/benimar.png` },
  'bentley': { slug: 'bentley', logoUrl: `${BASE_URL}/bentley.png` },
  'bmw': { slug: 'bmw', logoUrl: `${BASE_URL}/bmw.png` },
  'buick': { slug: 'buick', logoUrl: `${BASE_URL}/buick.png` },
  'burstner': { slug: 'burstner', logoUrl: `${BASE_URL}/burstner.png` },
  'bürstner': { slug: 'burstner', logoUrl: `${BASE_URL}/burstner.png` },
  'byd': { slug: 'byd', logoUrl: `${BASE_URL}/byd.png` },

  // C
  'cadillac': { slug: 'cadillac', logoUrl: `${BASE_URL}/cadillac.png` },
  'carado': { slug: 'carado', logoUrl: `${BASE_URL}/carado.png` },
  'cf-moto': { slug: 'cf-moto', logoUrl: `${BASE_URL}/cf-moto.png` },
  'chevrolet': { slug: 'chevrolet', logoUrl: `${BASE_URL}/chevrolet.png` },
  'chrysler': { slug: 'chrysler', logoUrl: `${BASE_URL}/chrysler.png` },
  'corvette': { slug: 'chevrolet-corvette', logoUrl: `${BASE_URL}/chevrolet-corvette.png` },
  'cupra': { slug: 'cupra', logoUrl: `${BASE_URL}/cupra.png` },

  // D
  'dacia': { slug: 'dacia', logoUrl: `${BASE_URL}/dacia.png` },
  'daewoo': { slug: 'daewoo', logoUrl: `${BASE_URL}/daewoo.png` },
  'dallara': { slug: 'dallara', logoUrl: `${BASE_URL}/dallara.png` },
  'deloren': { slug: 'delorean', logoUrl: `${BASE_URL}/delorean.png` },
  'delorean': { slug: 'delorean', logoUrl: `${BASE_URL}/delorean.png` },
  'dkw': { slug: 'dkw', logoUrl: `${BASE_URL}/dkw.png` },
  'dodge': { slug: 'dodge', logoUrl: `${BASE_URL}/dodge.png` },
  'ds': { slug: 'ds', logoUrl: `${BASE_URL}/ds.png` },

  // E
  'ellert': { slug: 'ellert', logoUrl: `${BASE_URL}/ellert.png` },
  'etrusco': { slug: 'etrusco', logoUrl: `${BASE_URL}/etrusco.png` },

  // F
  'ferrari': { slug: 'ferrari', logoUrl: `${BASE_URL}/ferrari.png` },
  'fiat': { slug: 'fiat', logoUrl: `${BASE_URL}/fiat.png` },
  'fisker': { slug: 'fisker', logoUrl: `${BASE_URL}/fisker.png` },
  'ford': { slug: 'ford', logoUrl: `${BASE_URL}/ford.png` },
  'fuso': { slug: 'mitsubishi-fuso', logoUrl: `${BASE_URL}/mitsubishi-fuso.png` },

  // G
  'giotti-victoria': { slug: 'giotti-victoria', logoUrl: `${BASE_URL}/giotti-victoria.png` },

  // H
  'hobby': { slug: 'hobby', logoUrl: `${BASE_URL}/hobby.png` },
  'honda': { slug: 'honda', logoUrl: `${BASE_URL}/honda.png` },
  'hongqi': { slug: 'hongqi', logoUrl: `${BASE_URL}/hongqi.png` },
  'hummer': { slug: 'hummer', logoUrl: `${BASE_URL}/hummer.png` },
  'hymer': { slug: 'hymer', logoUrl: `${BASE_URL}/hymer.png` },
  'hyundai': { slug: 'hyundai', logoUrl: `${BASE_URL}/hyundai.png` },

  // I
  'isuzu': { slug: 'isuzu', logoUrl: `${BASE_URL}/isuzu.png` },
  'iveco': { slug: 'iveco', logoUrl: `${BASE_URL}/iveco.png` },

  // J
  'jac': { slug: 'jac', logoUrl: `${BASE_URL}/jac.png` },
  'jaguar': { slug: 'jaguar', logoUrl: `${BASE_URL}/jaguar.png` },
  'jeep': { slug: 'jeep', logoUrl: `${BASE_URL}/jeep.png` },

  // K
  'kalmar': { slug: 'kalmar', logoUrl: `${BASE_URL}/kalmar.png` },
  'kgm': { slug: 'kgm', logoUrl: `${BASE_URL}/kgm.png` },
  'kia': { slug: 'kia', logoUrl: `${BASE_URL}/kia.png` },
  'knaus': { slug: 'knaus', logoUrl: `${BASE_URL}/knaus.png` },
  'ktm': { slug: 'ktm', logoUrl: `${BASE_URL}/ktm.png` },

  // L
  'lada': { slug: 'lada', logoUrl: `${BASE_URL}/lada.png` },
  'lamborghini': { slug: 'lamborghini', logoUrl: `${BASE_URL}/lamborghini.png` },
  'lancia': { slug: 'lancia', logoUrl: `${BASE_URL}/lancia.png` },
  'land-rover': { slug: 'land-rover', logoUrl: `${BASE_URL}/land-rover.png` },
  'landrover': { slug: 'land-rover', logoUrl: `${BASE_URL}/land-rover.png` },
  'levc': { slug: 'levc', logoUrl: `${BASE_URL}/levc.png` },
  'lexus': { slug: 'lexus', logoUrl: `${BASE_URL}/lexus.png` },
  'lincoln': { slug: 'lincoln', logoUrl: `${BASE_URL}/lincoln.png` },
  'lloyd': { slug: 'lloyd', logoUrl: `${BASE_URL}/lloyd.png` },
  'lotus': { slug: 'lotus', logoUrl: `${BASE_URL}/lotus.png` },
  'lynk-co': { slug: 'lynk-&-co', logoUrl: `${BASE_URL}/lynk-&-co.png` },

  // M
  'man': { slug: 'man', logoUrl: `${BASE_URL}/man.png` },
  'maserati': { slug: 'maserati', logoUrl: `${BASE_URL}/maserati.png` },
  'maxus': { slug: 'maxus', logoUrl: `${BASE_URL}/maxus.png` },
  'mazda': { slug: 'mazda', logoUrl: `${BASE_URL}/mazda.png` },
  'mclaren': { slug: 'mclaren', logoUrl: `${BASE_URL}/mclaren.png` },
  'mclouis': { slug: 'mclouis', logoUrl: `${BASE_URL}/mclouis.png` },
  'messerschmitt': { slug: 'messerschmitt', logoUrl: `${BASE_URL}/messerschmitt.png` },
  'mg': { slug: 'mg', logoUrl: `${BASE_URL}/mg.png` },
  'mini': { slug: 'mini', logoUrl: `${BASE_URL}/mini.png` },
  'mitsubishi': { slug: 'mitsubishi', logoUrl: `${BASE_URL}/mitsubishi.png` },
  'mitsubishi-fuso': { slug: 'mitsubishi-fuso', logoUrl: `${BASE_URL}/mitsubishi-fuso.png` },
  'mobilvetta': { slug: 'mobilvetta', logoUrl: `${BASE_URL}/mobilvetta.png` },
  'mooveo': { slug: 'mooveo', logoUrl: `${BASE_URL}/mooveo.png` },
  'morgan': { slug: 'morgan', logoUrl: `${BASE_URL}/morgan.png` },
  'morris': { slug: 'morris', logoUrl: `${BASE_URL}/morris.png` },

  // N
  'nio': { slug: 'nio', logoUrl: `${BASE_URL}/nio.png` },
  'nissan': { slug: 'nissan', logoUrl: `${BASE_URL}/nissan.png` },
  'nsu': { slug: 'nsu', logoUrl: `${BASE_URL}/nsu.png` },

  // O
  'oldsmobile': { slug: 'oldsmobile', logoUrl: `${BASE_URL}/oldsmobile.png` },
  'opel': { slug: 'opel', logoUrl: `${BASE_URL}/opel.png` },

  // P
  'packard': { slug: 'packard', logoUrl: `${BASE_URL}/packard.png` },
  'peugeot': { slug: 'peugeot', logoUrl: `${BASE_URL}/peugeot.png` },
  'plymouth': { slug: 'plymouth', logoUrl: `${BASE_URL}/plymouth.png` },
  'polestar': { slug: 'polestar', logoUrl: `${BASE_URL}/polestar.png` },
  'pontiac': { slug: 'pontiac', logoUrl: `${BASE_URL}/pontiac.png` },
  'porsche': { slug: 'porsche', logoUrl: `${BASE_URL}/porsche.png` },

  // R
  'radical': { slug: 'radical', logoUrl: `${BASE_URL}/radical.png` },
  'renault': { slug: 'renault', logoUrl: `${BASE_URL}/renault.png` },
  'roller-team': { slug: 'roller-team', logoUrl: `${BASE_URL}/roller-team.png` },
  'rolls-royce': { slug: 'rolls-royce', logoUrl: `${BASE_URL}/rolls-royce.png` },
  'rollsroyce': { slug: 'rolls-royce', logoUrl: `${BASE_URL}/rolls-royce.png` },
  'rover': { slug: 'rover', logoUrl: `${BASE_URL}/rover.png` },

  // S
  'saab': { slug: 'saab', logoUrl: `${BASE_URL}/saab.png` },
  'scania': { slug: 'scania', logoUrl: `${BASE_URL}/scania.png` },
  'seat': { slug: 'seat', logoUrl: `${BASE_URL}/seat.png` },
  'seres': { slug: 'seres', logoUrl: `${BASE_URL}/seres.png` },
  'singer': { slug: 'singer', logoUrl: `${BASE_URL}/singer.png` },
  'skoda': { slug: 'skoda', logoUrl: `${BASE_URL}/skoda.png` },
  'smart': { slug: 'smart', logoUrl: `${BASE_URL}/smart.png` },
  'ssangyong': { slug: 'ssangyong', logoUrl: `${BASE_URL}/ssangyong.png` },
  'studebaker': { slug: 'studebaker', logoUrl: `${BASE_URL}/studebaker.png` },
  'subaru': { slug: 'subaru', logoUrl: `${BASE_URL}/subaru.png` },
  'suzuki': { slug: 'suzuki', logoUrl: `${BASE_URL}/suzuki.png` },

  // T
  'tesla': { slug: 'tesla', logoUrl: `${BASE_URL}/tesla.png` },
  'toyota': { slug: 'toyota', logoUrl: `${BASE_URL}/toyota.png` },
  'triumph': { slug: 'triumph', logoUrl: `${BASE_URL}/triumph.png` },
  'tvr': { slug: 'tvr', logoUrl: `${BASE_URL}/tvr.png` },

  // V
  'vauxhall': { slug: 'vauxhall', logoUrl: `${BASE_URL}/vauxhall.png` },
  'volvo': { slug: 'volvo', logoUrl: `${BASE_URL}/volvo.png` },
  'voyah': { slug: 'voyah', logoUrl: `${BASE_URL}/voyah.png` },

  // W
  'wolseley': { slug: 'wolseley', logoUrl: `${BASE_URL}/wolseley.png` },

  // X
  'xpeng': { slug: 'xpeng', logoUrl: `${BASE_URL}/xpeng.png` },

  // Y
  'yugo': { slug: 'yugo', logoUrl: `${BASE_URL}/yugo.png` }
}; 