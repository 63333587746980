<template>
  <div class="container-fluid py-4">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-8">
        <div class="d-flex align-items-center justify-content-between mb-4">
          <button
            v-if="currentStep > 1"
            class="btn btn-sm bg-gradient-light"
            type="button"
            title="Forrige"
            @click="prevStep"
          >
            Forrige
          </button>
          <div v-else class="w-100px"></div>
          
          <ProjectSteps
            v-model:currentStep="currentStep"
            :totalSteps="totalSteps"
          />
          
          <button
            v-if="currentStep < totalSteps"
            class="btn btn-sm bg-gradient-dark"
            type="button"
            title="Næste"
            @click="nextStep"
          >
            Næste
          </button>
          <button
            v-else
            class="btn btn-sm bg-gradient-dark"
            type="button"
            title="Opret"
            @click.prevent="createProject"
          >
            Opret & Gem
          </button>
        </div>
        
        <div class="content-wrapper">
          <form class="mb-8 multisteps-form__form" @submit.prevent @keydown.enter="handleEnterKey">
            <!-- Trin 1: Emne -->
            <div
              class="p-3 multisteps-form__panel"
              :class="{ 'js-active': currentStep === 1 }"
              data-animation="FadeIn"
            >
              <div class="multisteps-form__content">
                <div class="mt-3 row">
                  <div class="col-12">
                    <label for="projectName">Emne</label>
                    <input
                      id="projectName"
                      v-model="projectData.title"
                      maxlength="20"
                      @input="limitTitleLength"
                      placeholder="Indtast emne"
                      class="multisteps-form__input form-control"
                    />
                    <span class="character-count">{{ projectData.title.length }}/20</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Trin 2: Overskrift -->
            <div
              class="p-3 multisteps-form__panel"
              :class="{ 'js-active': currentStep === 2 }"
              data-animation="FadeIn"
            >
              <div class="multisteps-form__content">
                <div class="mt-3 row">
                  <div class="col-12">
                    <label for="postHeadline">Overskrift</label>
                    <input
                      id="postHeadline"
                      v-model="projectData.headline"
                      maxlength="100"
                      @input="limitHeadlineLength"
                      placeholder="Indtast overskrift"
                      class="multisteps-form__input form-control"
                    />
                    <span class="character-count">{{ projectData.headline.length }}/100</span>
                  </div>
                </div>
                <div class="mt-3 row">
                  <div class="col-12">
                    <div class="d-flex align-items-center">
                      <label class="switch">
                        <input
                          type="checkbox"
                          v-model="projectData.hasProductLink"
                          id="hasProductLink"
                        />
                        <span class="slider"></span>
                      </label>
                      <span class="switch-label">Dette opslag omhandler et produkt</span>
                    </div>
                  </div>
                </div>
                <div v-if="projectData.hasProductLink" class="mt-3 row">
                  <div class="col-12">
                    <label>Produktlink</label>
                    <input
                      type="url"
                      v-model="projectData.productLink"
                      placeholder="Indtast produktlink"
                      class="multisteps-form__input form-control"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- Trin 3: Platforme -->
            <div
              class="p-3 multisteps-form__panel"
              :class="{ 'js-active': currentStep === 3 }"
              data-animation="FadeIn"
            >
              <div class="multisteps-form__content">
                <div class="mt-3 row">
                  <div class="col-12">
                    <label>Vælg platforme</label>
                    <div class="platform-selector">
                      <button
                        v-for="platform in availablePlatforms"
                        :key="platform"
                        @click.prevent="togglePlatform(platform)"
                        :class="{ 'selected': projectData.tags.includes(platform) }"
                        class="btn"
                        type="button"
                      >
                        {{ platform }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Trin 4: Upload -->
            <div
              class="p-3 multisteps-form__panel"
              :class="{ 'js-active': currentStep === 4 }"
              data-animation="FadeIn"
            >
              <div class="multisteps-form__content">
                <div class="mt-3 row">
                  <div class="col-12">
                    <div
                      id="dropzone"
                      ref="dropzoneElement"
                      class="dropzone"
                    >
                      <div class="dz-message" data-dz-message>
                        <span>Træk filer hertil eller klik for at vælge</span>
                      </div>
                    </div>
                    <div v-for="(progress, filename) in uploadProgress" :key="filename" class="upload-progress">
                      <span>{{ filename }}: {{ progress.toFixed(2) }}%</span>
                      <div class="progress-bar" :style="{ width: `${progress}%` }"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Trin 5: Beskrivelse -->
            <div
              class="p-3 multisteps-form__panel"
              :class="{ 'js-active': currentStep === 5 }"
              data-animation="FadeIn"
            >
              <div class="multisteps-form__content">
                <div class="mt-3 row">
                  <div class="col-12">
                    <label for="projectDescription">Beskrivelse</label>
                    <textarea
                      id="projectDescription"
                      v-model="projectData.description"
                      @input="adjustTextareaHeight"
                      placeholder="Indtast beskrivelse"
                      class="multisteps-form__textarea form-control"
                    ></textarea>
                  </div>
                </div>
                <div class="button-group">
                  <button 
                    @click="handleGenerateText" 
                    class="generate-button" 
                    :disabled="isLoading"
                  >
                    <span v-if="!isLoading">Generer tekst</span>
                    <div v-else class="button-loader">
                      <div class="spinner"></div>
                      <span>Genererer...</span>
                    </div>
                  </button>
                  <button 
                    @click="handleCorrectText" 
                    class="correct-button"
                    :disabled="isLoading"
                  >
                    <span v-if="!isLoading">Ret tekst</span>
                    <div v-else class="button-loader">
                      <div class="spinner"></div>
                      <span>Retter...</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>

            <!-- Trin 6: Opslagstype -->
            <div
              class="p-3 multisteps-form__panel"
              :class="{ 'js-active': currentStep === 6 }"
              data-animation="FadeIn"
            >
              <div class="multisteps-form__content">
                <div class="mt-3 row">
                  <div class="col-12">
                    <div v-for="platform in projectData.tags" :key="platform" class="post-type-selector">
                      <label>{{ platform }}</label>
                      <select v-model="projectData.postTypes[platform]" class="form-control">
                        <option value="">Vælg en opslagstype for {{ platform }}</option>
                        <option v-for="option in getAvailablePostTypes(platform)" :key="option.value" :value="option.value">
                          {{ option.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Trin 7: Dato og tid -->
            <div
              class="p-3 multisteps-form__panel"
              :class="{ 'js-active': currentStep === 7 }"
              data-animation="FadeIn"
            >
              <div class="multisteps-form__content">
                <div class="mt-3 row">
                  <div class="col-6">
                    <label>Start Dato</label>
                    <flat-pickr
                      v-model="projectData.startDate"
                      :config="dateConfig"
                      placeholder="Vælg startdato"
                      class="multisteps-form__input form-control"
                    ></flat-pickr>
                  </div>
                  <div class="col-6">
                    <label>Start Tid</label>
                    <flat-pickr
                      v-model="projectData.startTime"
                      :config="timeConfig"
                      placeholder="Vælg starttid"
                      class="multisteps-form__input form-control"
                    ></flat-pickr>
                  </div>
                </div>
                <div class="mt-3 row">
                  <div class="col-12">
                    <button @click.prevent="showAdSetModal" class="btn btn-warning" type="button">Opret Ad Set</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed, watch } from "vue";
import ProjectSteps from "./ProjectSteps.vue";
import flatPickr from "vue-flatpickr-component";
import Dropzone from "dropzone";
import { getAuth } from "firebase/auth";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../../firebase.js";
import { uploadFile } from "../../../services/uploadeService.js";
import axios from "axios";
import Swal from "sweetalert2";
import { createApp } from "vue";
import AdSetModal from "@/views/pages/components/AdSetModal.vue";

const projectData = ref({
  title: "",
  headline: "",
  description: "",
  isPrivate: false,
  tags: [],
  startDate: "",
  startTime: "",
  files: [],
  postTypes: {},
  links: [],
  hasProductLink: false,
  productLink: ""
});

const isLoading = ref(false);
const errorMessage = ref("");
const dropzoneElement = ref(null);
let dropzoneInstance = null;
const uploadProgress = ref({});
const currentStep = ref(1);
const totalSteps = 7;

const availablePlatforms = ['Facebook', 'Instagram', 'LinkedIn', 'YouTube'];

const hasUploadedFiles = computed(() => projectData.value.files.length > 0);

const dateConfig = {
  dateFormat: "Y-m-d",
  allowInput: true,
};

const timeConfig = {
  enableTime: true,
  noCalendar: true,
  dateFormat: "H:i",
  time_24hr: true,
  allowInput: true,
};

onMounted(() => {
  initializeDropzone();
});

onBeforeUnmount(() => {
  if (dropzoneInstance) {
    dropzoneInstance.destroy();
  }
});

const initializeDropzone = () => {
  if (dropzoneElement.value) {
    dropzoneInstance = new Dropzone(dropzoneElement.value, {
      url: "about:blank",
      addRemoveLinks: true,
      autoProcessQueue: false,
      parallelUploads: 5,
      maxFiles: 10,
      acceptedFiles: "image/*,video/*",
      dictRemoveFile: "Slet",
      dictFileTooBig: "Filen er for stor",
      dictInvalidFileType: "Denne filtype er ikke tilladt",
      dictCancelUpload: "Annuller upload",
      dictUploadCanceled: "Upload annulleret",
      dictRemoveFileConfirmation: "Er du sikker på at du vil slette denne fil?",
      previewTemplate: `
        <div class="dz-preview dz-file-preview">
          <div class="dz-image">
            <img data-dz-thumbnail />
          </div>
          <div class="dz-details">
            <div class="dz-filename"><span data-dz-name></span></div>
            <div class="dz-size" data-dz-size></div>
          </div>
          <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
          <div class="dz-success-mark"><span>✔</span></div>
          <div class="dz-error-mark"><span>✘</span></div>
          <div class="dz-error-message"><span data-dz-errormessage></span></div>
        </div>
      `,
      init: function() {
        this.on("addedfile", handleFileAdded);
        this.on("removedfile", handleFileRemoved);
      }
    });
  }
};

const handleFileAdded = async (file) => {
  try {
    const downloadURL = await uploadFile(file, "projectFiles", (progress) => {
      uploadProgress.value[file.name] = progress;
    });
    
    projectData.value.files.push({
      url: downloadURL,
      type: file.type.startsWith('image/') ? 'image' : 'video'
    });
    
    file.downloadURL = downloadURL;
  } catch (error) {
    console.error(`Fejl ved upload af fil ${file.name}:`, error);
    dropzoneInstance.removeFile(file);
    alert(`Fejl ved upload af fil ${file.name}: ${error.message}`);
  } finally {
    delete uploadProgress.value[file.name];
  }
};

const handleFileRemoved = (file) => {
  if (file.downloadURL) {
    projectData.value.files = projectData.value.files.filter(f => f.url !== file.downloadURL);
  }
};

const createProject = async () => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      if (!validateProjectData()) return;

      const newProject = {
        ...projectData.value,
        startDateTime: combineDateTime(projectData.value.startDate, projectData.value.startTime),
        userId: user.uid,
        createdAt: new Date(),
      };

      const projectsCollection = collection(db, "projects");
      await addDoc(projectsCollection, newProject);

      alert("Projekt oprettet!");
      resetForm();
    } else {
      alert("Du skal være logget ind for at oprette et projekt.");
    }
  } catch (error) {
    console.error("Fejl ved oprettelse af projekt:", error);
    alert(`Fejl ved oprettelse af projekt: ${error.message}`);
  }
};

const validateProjectData = () => {
  if (!projectData.value.title) {
    alert("Projekt navn er påkrævet.");
    return false;
  }
  if (!projectData.value.headline) {
    alert("Overskrift er påkrævet.");
    return false;
  }
  if (!projectData.value.description) {
    alert("Projekt beskrivelse er påkrævet.");
    return false;
  }
  if (!projectData.value.startDate || !projectData.value.startTime) {
    alert("Start dato og tid er påkrævet.");
    return false;
  }
  return true;
};

const resetForm = () => {
  projectData.value = {
    title: "",
    headline: "",
    description: "",
    isPrivate: false,
    tags: [],
    startDate: "",
    startTime: "",
    files: [],
    postTypes: {},
    links: [],
    hasProductLink: false,
    productLink: ""
  };
  if (dropzoneInstance) dropzoneInstance.removeAllFiles(true);
  currentStep.value = 1;
};

const combineDateTime = (date, time) => {
  return date && time ? `${date} ${time}` : "";
};

const limitTitleLength = () => {
  if (projectData.value.title.length > 20) {
    projectData.value.title = projectData.value.title.slice(0, 20);
  }
};

const limitHeadlineLength = () => {
  if (projectData.value.headline.length > 100) {
    projectData.value.headline = projectData.value.headline.slice(0, 100);
  }
};

const handleGenerateText = async () => {
  await processText('generate');
};

const handleCorrectText = async () => {
  await processText('correct');
};

const processText = async (action) => {
  isLoading.value = true;
  errorMessage.value = "";

  try {
    const functionUrl = "https://us-central1-toft-d4f39.cloudfunctions.net/correctText";
    const requestData = {
      action: action,
      text: projectData.value.description,
      emne: projectData.value.title,
      platform: projectData.value.tags.join(', '),
      messages: constructMessages(action)
    };

    const response = await axios.post(functionUrl, requestData, {
      headers: { 'Content-Type': 'application/json' }
    });
    
    if (response.data && response.data.correctedText) {
      projectData.value.description = response.data.correctedText;
    } else if (response.data && response.data.error) {
      throw new Error(response.data.error);
    } else {
      throw new Error('Uventet svarformat fra serveren');
    }
  } catch (error) {
    console.error('Fejl under processering af tekst:', error);
    errorMessage.value = `Der opstod en fejl: ${error.message}`;
  } finally {
    isLoading.value = false;
  }
};

const constructMessages = (action) => {
  let messages = [
    {
      role: "system",
      content: `Du skal være min hjælper til at læse korrektur på min tekst og forbedre den. 
                Regler: 
                - Du må gerne bruge emojis`
    }
  ];

  if (action === 'generate') {
    messages.push({
      role: "user",
      content: [
        {
          type: "text",
          text: `Baseret på følgende billeder eller videobeskrivelser, generer venligst en passende tekst til et opslag på ${projectData.value.tags.join(', ') || 'sociale medier'}. Emnet er: ${projectData.value.title}`
        }
      ]
    });

    projectData.value.files.forEach(file => {
      if (file.type === 'video') {
        messages[1].content.push({
          type: "video_url",
          video_url: file.url
        });
      } else {
        messages[1].content.push({
          type: "image_url",
          image_url: {
            url: file.url
          }
        });
      }
    });
  } else if (action === 'correct') {
    messages.push({
      role: "user",
      content: `Korrigér og forbedre følgende tekst til et opslag på ${projectData.value.tags.join(', ') || 'sociale medier'}. Emnet er: ${projectData.value.title}\n\n${projectData.value.description}`
    });
  }

  return messages;
};

const showAdSetModal = async () => {
  const { value: formValues } = await Swal.fire({
    title: "Opret Ad Set",
    html: '<div id="adset-modal-container"></div>',
    showConfirmButton: false,
    showCancelButton: true,
    cancelButtonText: "Luk",
    width: "80%",
    didOpen: () => {
      const app = createApp(AdSetModal, {
        campaignName: projectData.value.title,
        startDate: projectData.value.startDate,
        endDate: projectData.value.stopTime,
      });
      app.mount("#adset-modal-container");
    },
    willClose: () => {
      const container = document.getElementById("adset-modal-container");
      if (container && container.__vue_app__) {
        container.__vue_app__.unmount();
      }
    }
  });

  if (formValues) {
    console.log("Ad Set oprettet:", formValues);
    // Håndter de returnerede data her
  }
};

const togglePlatform = (platform) => {
  const index = projectData.value.tags.indexOf(platform);
  if (index > -1) {
    projectData.value.tags.splice(index, 1);
  } else {
    projectData.value.tags.push(platform);
  }
};

const getAvailablePostTypes = (platform) => {
  const types = [];
  const hasVideo = projectData.value.files.some(file => file.type === 'video');
  const hasImage = projectData.value.files.some(file => file.type === 'image');
  const hasLink = projectData.value.hasProductLink && projectData.value.productLink;
  
  console.log('Platform:', platform);
  console.log('Has Video:', hasVideo);
  console.log('Has Image:', hasImage);
  console.log('Has Link:', hasLink);
  console.log('Files:', projectData.value.files);
  
  if (platform === 'Facebook') {
    if (hasImage) {
      types.push({ value: 'facebookPost', label: 'Facebook Post' });
      if (hasLink) {
        types.push({ value: 'facebookLinkImage', label: 'Facebook Link med billede' });
      }
    }
    
    if (hasVideo) {
      types.push({ value: 'facebookVideo', label: 'Facebook Video' });
      if (hasLink) {
        types.push({ value: 'facebookVideoLink', label: 'Facebook Video Link' });
      }
    }
  } 
  else if (platform === 'Instagram') {
    if (hasVideo) {
      types.push({ value: 'instagramReels', label: 'Instagram Reels' });
    }
    if (hasImage) {
      types.push({ value: 'instagramPost', label: 'Instagram Post' });
    }
  }
  
  console.log('Available Types:', types);
  return types;
};

const nextStep = () => {
  if (currentStep.value < totalSteps) {
    currentStep.value++;
  }
};

const prevStep = () => {
  if (currentStep.value > 1) {
    currentStep.value--;
  }
};

const handleEnterKey = (event) => {
  if (event.target.tagName.toLowerCase() === 'textarea') return;
  if (event.target.type === 'url') return;

  event.preventDefault();

  if (currentStep.value < totalSteps) {
    nextStep();
  } else if (currentStep.value === totalSteps) {
    createProject();
  }
};

watch(() => projectData.value.tags, (newPlatforms) => {
  const newPostTypes = {};
  newPlatforms.forEach(platform => {
    if (!projectData.value.postTypes[platform]) {
      newPostTypes[platform] = '';
    } else {
      newPostTypes[platform] = projectData.value.postTypes[platform];
    }
  });
  projectData.value.postTypes = newPostTypes;
}, { deep: true });

const adjustTextareaHeight = (e) => {
  const textarea = e.target;
  textarea.style.height = 'auto';
  textarea.style.height = Math.max(400, textarea.scrollHeight) + 'px';
};
</script>

<style scoped>
.multisteps-form__panel {
  height: auto;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
  padding: 1.5rem;
  border-radius: 0.75rem;
  background: transparent;
}

.multisteps-form__panel.js-active {
  opacity: 1;
  visibility: visible;
  animation: fadeIn 0.5s ease-in-out;
}

.form-control {
  border: 1px solid #e9ecef;
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 400;
  color: #344767;
  transition: all 0.2s ease-in-out;
  background-color: #fff;
}

.form-control:focus {
  border-color: #5e72e4;
  box-shadow: 0 0 0 2px rgba(94, 114, 228, 0.2);
  outline: none;
}

label {
  font-size: 0.875rem;
  font-weight: 600;
  color: #344767;
  margin-bottom: 0.5rem;
  display: block;
}

.character-count {
  font-size: 0.75rem;
  color: #8392ab;
  margin-top: 0.25rem;
  display: block;
  text-align: right;
}

.platform-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-top: 0.5rem;
}

.platform-selector button {
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  border: 1px solid #e9ecef;
  background-color: #fff;
  color: #344767;
}

.platform-selector button:hover {
  background-color: #f8f9fa;
  transform: translateY(-1px);
}

.platform-selector button.selected {
  background: linear-gradient(310deg, #5e72e4 0%, #825ee4 100%);
  color: white;
  border: none;
  box-shadow: 0 3px 5px -1px rgba(94, 114, 228, 0.2), 0 6px 10px 0 rgba(94, 114, 228, 0.14), 0 1px 18px 0 rgba(94, 114, 228, 0.12);
}

.dropzone {
  border: 2px dashed #5e72e4;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(94, 114, 228, 0.03);
  position: relative;
  overflow: hidden;
}

.dropzone:hover {
  border-color: #5e72e4;
  background-color: rgba(94, 114, 228, 0.08);
  transform: translateY(-1px);
  box-shadow: 0 3px 6px rgba(94, 114, 228, 0.1);
}

.dropzone .dz-message {
  font-size: 1.1rem;
  color: #5e72e4;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.dropzone .dz-message:before {
  content: "📁";
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.dropzone:not(:hover) .dz-message:before {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.upload-progress {
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: #f8f9fa;
  border-radius: 0.5rem;
}

.progress-bar {
  height: 6px;
  background: linear-gradient(310deg, #5e72e4 0%, #825ee4 100%);
  border-radius: 3px;
  transition: width 0.3s ease;
}

.button-row {
  padding: 1rem 0;
  margin-top: 2rem;
  background: transparent;
  width: 100%;
  position: sticky;
  bottom: 0;
  z-index: 10;
}

.button-row button {
  min-width: 120px;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  border-radius: 0.5rem;
  transition: all 0.2s ease;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn {
  text-transform: none;
  font-weight: 600;
  letter-spacing: 0.025em;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.bg-gradient-dark {
  background: linear-gradient(310deg, #141727 0%, #3a416f 100%);
  color: white;
  border: 0;
}

.bg-gradient-light {
  background: linear-gradient(310deg, #f0f2f5 0%, #e9ecef 100%);
  color: #344767;
  border: 0;
}

.button-group {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.generate-button, .correct-button {
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  flex: 1;
}

.generate-button {
  background: linear-gradient(122deg,#1568db,#05c2ed);;
  color: white;
  border: none;
}

.correct-button {
  background: linear-gradient(310deg, #fb6340 0%, #fbb140 100%);
  color: white;
  border: none;
}

.form-check {
  padding: 0.75rem 1rem;
  background-color: rgba(248, 249, 250, 0.8);
  border-radius: 0.5rem;
  margin: 0.5rem 0;
  border: 1px solid #e9ecef;
  transition: all 0.2s ease;
  cursor: pointer;
}

.form-check:hover {
  background-color: rgba(248, 249, 250, 1);
  transform: translateY(-1px);
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.05);
}

.form-check-input {
  margin-right: 0.75rem;
  cursor: pointer;
}

.form-check-label {
  font-weight: 500;
  color: #344767;
  cursor: pointer;
  user-select: none;
}

.form-check-input:checked ~ .form-check-label {
  color: #5e72e4;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin-right: 1rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e9ecef;
  transition: .4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

input:checked + .slider {
  background: linear-gradient(310deg, #5e72e4 0%, #825ee4 100%);
}

input:focus + .slider {
  box-shadow: 0 0 1px #5e72e4;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.switch-label {
  font-weight: 500;
  color: #344767;
  cursor: pointer;
  user-select: none;
}

input:checked ~ .switch-label {
  color: #5e72e4;
}

/* Dropzone Preview Styling */
.dropzone .dz-preview {
  position: relative;
  display: inline-block;
  margin: 0.5rem;
  min-height: 100px;
  border-radius: 0.5rem;
  background: rgba(52, 71, 103, 0.1);
  padding: 0.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.dropzone .dz-preview .dz-details {
  position: relative;
  opacity: 1;
  background: transparent;
  padding: 0.5rem;
  text-align: center;
}

.dropzone .dz-preview .dz-filename {
  font-size: 0.75rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
  word-break: break-all;
  color: #344767;
}

.dropzone .dz-preview .dz-size {
  font-size: 0.75rem;
  color: #344767;
  opacity: 0.8;
}

.dropzone .dz-preview .dz-image {
  width: 120px;
  height: 120px;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  background: #fff;
  margin-bottom: 0.5rem;
}

.dropzone .dz-preview .dz-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dropzone .dz-preview .dz-remove {
  position: absolute;
  top: -8px;
  right: -8px;
  background: #fb6340;
  color: white !important;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  opacity: 1;
  transition: all 0.2s ease;
  text-decoration: none !important;
  z-index: 10;
}

.dropzone .dz-preview .dz-remove:hover {
  background: #fa3a1d;
  transform: scale(1.1);
}

.dropzone .dz-preview:hover .dz-remove {
  opacity: 1;
}

.dropzone .dz-preview .dz-progress {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  background: rgba(0,0,0,0.1);
  border-radius: 0 0 0.5rem 0.5rem;
  overflow: hidden;
}

.dropzone .dz-preview .dz-progress .dz-upload {
  display: block;
  height: 100%;
  width: 0;
  background: linear-gradient(310deg, #5e72e4 0%, #825ee4 100%);
  transition: width 0.3s ease;
}

.dropzone .dz-preview .dz-success-mark,
.dropzone .dz-preview .dz-error-mark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

.dropzone .dz-preview .dz-success-mark {
  color: #2dce89;
}

.dropzone .dz-preview .dz-error-mark {
  color: #fb6340;
}

.dropzone .dz-preview.dz-success .dz-success-mark {
  display: block;
}

.dropzone .dz-preview.dz-error .dz-error-mark {
  display: block;
}

.dropzone .dz-preview .dz-error-message {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fb6340;
  color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  display: none;
}

.dropzone .dz-preview.dz-error .dz-error-message {
  display: block;
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  background: linear-gradient(310deg, #fb6340 0%, #fbb140 100%);
}

.dark-version a:not(.dropdown-item):not(.choices__item):not(.leaflet-control-zoom-in):not(.leaflet-control-zoom-out):not(.btn):not(.nav-link):not(.fixed-plugin-button) {
  color: #ff0000;
}

.dropzone .dz-preview.dz-image-preview {
  background: #00000000;
}

.dropzone .dz-preview .dz-details .dz-filename span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.5rem;
  border-radius: 3px;
  color: #344767;
  font-size: 0.75rem;
  display: inline-block;
}

.button-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.spinner {
  width: 18px;
  height: 18px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.generate-button:disabled,
.correct-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.generate-button,
.correct-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 42px;
}

/* Flatpickr styling */
:deep(.flatpickr-calendar) {
  background: #fff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 0.5rem;
  border: 1px solid #e9ecef;
}

:deep(.flatpickr-day) {
  color: #344767;
  border-radius: 0.5rem;
}

:deep(.flatpickr-day.selected) {
  background: linear-gradient(310deg, #5e72e4 0%, #825ee4 100%);
  border-color: transparent;
  color: white;
}

:deep(.flatpickr-day:hover) {
  background: #f6f9fc;
}

:deep(.flatpickr-day.today) {
  border-color: #5e72e4;
}

:deep(.flatpickr-months) {
  color: #344767;
}

:deep(.flatpickr-current-month) {
  color: #344767;
  font-weight: 600;
}

:deep(.flatpickr-monthDropdown-months) {
  color: #344767;
}

:deep(.flatpickr-weekday) {
  color: #8392ab;
}

:deep(.flatpickr-time) {
  border-top: 1px solid #e9ecef;
}

:deep(.flatpickr-time input) {
  color: #ffffff;
}

:deep(.flatpickr-time .flatpickr-am-pm) {
  color: #344767;
}

:deep(.numInput) {
  color: #344767 !important;
}

.content-wrapper {
  min-height: 400px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.multisteps-form__form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.button-row {
  margin-top: 1.5rem;
  display: flex;
  width: 100%;
}

.button-row button {
  min-width: 120px;
}

.bg-gradient-dark {
  background: linear-gradient(310deg, #141727 0%, #3a416f 100%);
  color: white;
  border: 0;
}

.bg-gradient-light {
  background: linear-gradient(310deg, #f0f2f5 0%, #e9ecef 100%);
  color: #344767;
  border: 0;
}

.w-100px {
  width: 100px;
}

.btn-sm {
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  min-width: 100px;
  margin-top: 0,2rem;
}

.d-flex.align-items-center {
  align-items: flex-start !important;
}

.multisteps-form__textarea {
  min-height: 400px;
  max-height: 800px;
  resize: none;
  overflow-y: auto;
  line-height: 1.5;
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #e9ecef;
  border-radius: 0.5rem;
  transition: all 0.2s ease;
}

.multisteps-form__textarea:focus {
  border-color: #5e72e4;
  box-shadow: 0 0 0 2px rgba(94, 114, 228, 0.2);
}
</style>
