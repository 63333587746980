import axios from 'axios';
import { getAuth } from 'firebase/auth';

class DomainService {
  constructor() {
    // Brug altid produktions URL'en
    this.baseUrl = 'https://us-central1-toft-d4f39.cloudfunctions.net';
  }

  async setupSubdomain(subdomain, isTestDomain = false) {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      
      if (!user) {
        throw new Error('Bruger ikke logget ind');
      }

      const idToken = await user.getIdToken();
      
      const response = await axios({
        method: 'POST',
        url: `${this.baseUrl}/setupFirebaseHosting`,
        data: { 
          subdomain,
          isTestDomain 
        },
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.data.success) {
        return {
          success: true,
          domain: response.data.domain,
          message: response.data.message
        };
      } 

      throw new Error(response.data.error || 'Ukendt fejl ved oprettelse af subdomæne');

    } catch (error) {
      console.error('Domain setup error:', error);
      
      if (error.response?.status === 401) {
        throw new Error('Ikke autoriseret - log ind igen');
      }

      if (error.response?.status === 403) {
        throw new Error('Ingen tilladelse til at oprette subdomæner');
      }

      throw new Error(error.message || 'Netværksfejl - prøv igen senere');
    }
  }

  validateSubdomain(subdomain, isTestDomain = false) {
    // Hvis det er et test domæne, tilføj prefix i valideringen
    const finalSubdomain = isTestDomain ? `fir-${subdomain}` : subdomain;
    
    // Tjek længde (mellem 6 og 63 tegn)
    if (finalSubdomain.length < 6 || finalSubdomain.length > 63) {
      return {
        isValid: false,
        message: 'Subdomænet skal være mellem 6 og 63 tegn'
      };
    }

    // Tjek format (kun små bogstaver, tal og bindestreger)
    const subdomainRegex = /^[a-z0-9][a-z0-9-]{4,61}[a-z0-9]$/;
    if (!subdomainRegex.test(finalSubdomain)) {
      return {
        isValid: false,
        message: 'Subdomænet må kun indeholde små bogstaver, tal og bindestreger'
      };
    }

    return {
      isValid: true,
      message: 'Subdomænet er gyldigt'
    };
  }
}

export const domainService = new DomainService(); 