<template>
  <div class="opret-designs">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-6 col-xl-3">
          <div class="card">
            <div class="card-header p-3 pb-0">
              <h4 style="font-weight: bolder !important;">Design Grupper</h4>
            </div>
            <div class="p-3">
              <div v-for="gruppe in designGrupper" :key="gruppe.id" class="designGroup" :class="{ 'active': valgtGruppe?.id === gruppe.id }">
                <div class="gruppe-navn" @click="vælgGruppe(gruppe)">{{ gruppe.name }}</div>
                <div class="gruppe-actions">
                  <argon-button @click.stop="redigerGruppe(gruppe)" color="primary" size="sm" class="gruppe-button">
                    <i class="fas fa-edit"></i>
                  </argon-button>
                  <argon-button @click.stop="sletGruppe(gruppe.id)" color="danger" size="sm" variant="outline" class="gruppe-button">
                    <i class="fas fa-trash"></i>
                  </argon-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col-2">
                  <h4 style="font-weight: bolder !important;">Post Designs</h4>
                </div>
                <div class="col-6">
                  <div class="row" style="column-gap: 1rem;">
                    <div class="col" style="padding: unset; text-align: center; width: 6rem;">
                      <select v-model="filters.type" class="form-control" name="type-filter">
                        <option value="">Vælg type</option>
                        <option value="statisk">Statisk</option>
                        <option value="dynamisk">Dynamisk</option>
                      </select>
                    </div>
                    <div class="col" style="padding: unset; text-align: center; width: 6rem;">
                      <select v-model="filters.channel" class="form-control" name="kanal-filter">
                        <option value="">Vælg kanal</option>
                        <option v-for="channel in uniqueChannels" :key="channel" :value="channel">{{ channel }}</option>
                      </select>
                    </div>
                    <div class="col" style="padding: unset; text-align: center; width: 6rem;">
                      <select v-model="filters.format" class="form-control" name="format-filter">
                        <option value="">Vælg format</option>
                        <option v-for="format in uniqueFormats" :key="format" :value="format">{{ format }}</option>
                      </select>
                    </div>
                    <div class="col" style="padding: unset; text-align: center; width: 6rem;">
                      <select v-model="filters.group" class="form-control" name="group-filter">
                        <option value="">Vælg gruppe</option>
                        <option v-for="group in designGrupper" :key="group.id" :value="group.name">{{ group.name }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <argon-button @click="visOpretGruppeModal" color="success" size="md" class="me-2">OPRET GRUPPE</argon-button>
                  <argon-button @click="visOpretDesignModal" color="success" size="md">OPRET DESIGN</argon-button>
                </div>
              </div>
            </div>
          </div>
          
          <h4 style="font-weight: bolder !important; margin-top: 1rem;">{{ valgtGruppe ? valgtGruppe.name : 'Alle designs' }}</h4>
          <div class="designs-container">
            <div v-for="design in filteredDesigns" :key="design.id" class="design-card">
              <div class="image-container">
                <template v-if="isVideo(design.file)">
                  <video controls class="design-video">
                    <source :src="design.file" type="video/mp4">
                    Din browser understøtter ikke videoafspilning.
                  </video>
                </template>
                <template v-else>
                  <img 
                    :src="design.file" 
                    alt="" 
                    class="design-image"
                    @load="adjustAspectRatio($event)"
                  >
                </template>
              </div>
              
              <div class="design-info">
                <p class="design-uuid">UUID: <b>{{ design.uuid }}</b></p>
                <div class="button-group">
                  <argon-button @click="redigerDesign(design)" color="primary" size="sm" class="button">
                    <i class="fas fa-edit me-1"></i> Rediger
                  </argon-button>
                  <argon-button @click="sletDesign(design.id)" color="danger" size="sm" variant="outline" class="button">
                    <i class="fas fa-trash me-1"></i> Slet
                  </argon-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Opret/Rediger Gruppe Modal -->
    <div v-if="visGruppeModal" class="modal-overlay">
      <div class="modal-container">
        <div class="modal-header">
          <h3>{{ redigeringstilstand ? 'Rediger Gruppe' : 'Opret Ny Gruppe' }}</h3>
          <argon-button @click="lukGruppeModal" class="close-button" color="transparent" size="sm">&times;</argon-button>
        </div>
        <form @submit.prevent="gemGruppe" class="modal-form">
          <div class="form-group">
            <label for="gruppeNavn">Gruppenavn</label>
            <input 
              type="text" 
              id="gruppeNavn" 
              v-model="aktivGruppe.navn" 
              required
              placeholder="Indtast gruppenavn"
            >
          </div>
          <div class="button-group">
            <argon-button @click="lukGruppeModal" color="light" size="md" class="me-2">ANNULLER</argon-button>
            <argon-button type="submit" color="success" size="md">{{ redigeringstilstand ? 'GEM ÆNDRINGER' : 'GEM GRUPPE' }}</argon-button>
          </div>
        </form>
      </div>
    </div>
    <!-- Tilføj DesignModal komponenten her -->
    <DesignModal 
      :isVisible="visDesignModal"
      :designToEdit="designToEdit"
      @close="lukDesignModal"
      @save="gemDesign"
      @click-outside="lukDesignModal"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { collection, doc, setDoc, updateDoc, deleteDoc, serverTimestamp, getDocs, getDoc, addDoc } from 'firebase/firestore';
import { db } from '@/firebase';
import ArgonButton from "@/components/ArgonButton.vue";
import DesignModal from "./components/DesignModal.vue";
import { deleteDesignAndReferences } from '@/services/designDeletionService';
import { useUserContext } from '@/composables/useUserContext';
import { createTemplateReader } from '@/utils/readTemplate';

const designGrupper = ref([]);
const designs = ref([]);
const visGruppeModal = ref(false);
const redigeringstilstand = ref(false);
const aktivGruppe = ref({
  id: null,
  navn: ''
});
const visDesignModal = ref(false);
const designToEdit = ref(null);
const valgtGruppe = ref(null);
const filters = ref({
  type: '',
  channel: '',
  format: '',
  group: ''
});

const { contextUserId, contextUserRole, contextUserAdminId } = useUserContext();
const templateReader = ref(null);

const uniqueChannels = computed(() => {
  return [...new Set(designs.value.flatMap(design => design.channels))];
});

const uniqueFormats = computed(() => {
  return [...new Set(designs.value.flatMap(design => design.format))];
});

const filteredDesigns = computed(() => {
  if (!templateReader.value) return [];

  const accessibleDesigns = designs.value.filter(design => 
    templateReader.value.checkDesignAccess(design)
  );

  return accessibleDesigns.filter(design => {
    return (!filters.value.type || design.type === filters.value.type) &&
           (!filters.value.channel || design.channels.includes(filters.value.channel)) &&
           (!filters.value.format || design.format.includes(filters.value.format)) &&
           (!valgtGruppe.value || design.groups.includes(valgtGruppe.value.name));
  });
});

const visOpretGruppeModal = () => {
  redigeringstilstand.value = false;
  aktivGruppe.value = { id: null, navn: '' };
  visGruppeModal.value = true;
};

const redigerGruppe = (gruppe) => {
  redigeringstilstand.value = true;
  aktivGruppe.value = { id: gruppe.id, navn: gruppe.name };
  visGruppeModal.value = true;
};

const lukGruppeModal = () => {
  visGruppeModal.value = false;
  aktivGruppe.value = { id: null, navn: '' };
};

const gemGruppe = async () => {
  try {
    if (redigeringstilstand.value) {
      await updateDoc(doc(db, 'designGroup', aktivGruppe.value.id), {
        name: aktivGruppe.value.navn,
        updatedAt: serverTimestamp()
      });
      console.log('Gruppe opdateret med ID: ', aktivGruppe.value.id);
    } else {
      const gruppeRef = doc(collection(db, 'designGroup'));
      const gruppeData = {
        id: gruppeRef.id,
        name: aktivGruppe.value.navn,
        createdAt: serverTimestamp()
      };
      await setDoc(gruppeRef, gruppeData);
      console.log('Gruppe oprettet med ID: ', gruppeRef.id);
    }
    lukGruppeModal();
    await hentDesignGrupper();
  } catch (error) {
    console.error('Fejl ved gem/opdater gruppe: ', error);
  }
};

const sletGruppe = async (gruppeId) => {
  if (confirm('Er du sikker på, at du vil slette denne gruppe?')) {
    try {
      await deleteDoc(doc(db, 'designGroup', gruppeId));
      console.log('Gruppe slettet med ID: ', gruppeId);
      await hentDesignGrupper();
    } catch (error) {
      console.error('Fejl ved sletning af gruppe: ', error);
    }
  }
};

const hentDesignGrupper = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'designGroup'));
    designGrupper.value = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Fejl ved hentning af designgrupper: ', error);
  }
};

const visOpretDesignModal = () => {
  designToEdit.value = null;
  visDesignModal.value = true;
};

const lukDesignModal = () => {
  visDesignModal.value = false;
  designToEdit.value = null;
};

const gemDesign = async (designData) => {
  try {
    const designToSave = {
      ...designData,
      emner: designData.type === 'statisk' ? designData.emner : [], // Kun gem emner hvis typen er statisk
      updatedAt: serverTimestamp()
    };

    if (designToEdit.value) {
      await updateDoc(doc(db, 'designImages', designToEdit.value.id), designToSave);
      console.log('Design opdateret:', designToEdit.value.id);
    } else {
      const docRef = await addDoc(collection(db, 'designImages'), {
        ...designToSave,
        createdAt: serverTimestamp(),
      });
      console.log('Nyt design oprettet med ID:', docRef.id);
    }
    await hentDesigns();
    lukDesignModal();
  } catch (error) {
    console.error('Fejl ved gem/opdater design: ', error);
  }
};

const hentDesigns = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'designImages'));
    designs.value = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Fejl ved hentning af designs: ', error);
  }
};

const redigerDesign = async (design) => {
  try {
    const designDoc = await getDoc(doc(db, 'designImages', design.id));
    if (designDoc.exists()) {
      designToEdit.value = { id: designDoc.id, ...designDoc.data() };
      visDesignModal.value = true;
    } else {
      console.error('Design ikke fundet');
    }
  } catch (error) {
    console.error('Fejl ved hentning af design: ', error);
  }
};

const sletDesign = async (designId) => {
  if (!confirm('Er du sikker på, at du vil slette dette design? Det vil også slette alle referencer til designet i hele systemet.')) {
    return;
  }

  try {
    const designToDelete = designs.value.find(d => d.id === designId);
    
    if (!designToDelete) {
      throw new Error('Design ikke fundet');
    }

    await deleteDesignAndReferences(designId, designToDelete.uuid);
    
    await hentDesigns(); // Genindlæs designs
    
  } catch (error) {
    console.error('Fejl ved sletning af design:', error);
    alert('Der opstod en fejl ved sletning af designet. Prøv venligst igen.');
  }
};

const vælgGruppe = (gruppe) => {
  valgtGruppe.value = gruppe;
  filters.value.group = gruppe.name;
};

// Funktion til at tjekke om filen er en video
const isVideo = (fileUrl) => {
  return fileUrl.includes('.mp4');
};

const adjustAspectRatio = (event) => {
  const img = event.target;
  const card = img.closest('.design-card');
  if (card && img.naturalWidth && img.naturalHeight) {
    const aspectRatio = (img.naturalHeight / img.naturalWidth) * 100;
    card.style.setProperty('--aspect-ratio', `${aspectRatio}%`);
  }
};

onMounted(async () => {
  if (contextUserId.value && contextUserRole.value) {
    const userData = {
      uid: contextUserId.value,
      role: contextUserRole.value,
      ...(contextUserRole.value === 3 && { adminId: contextUserAdminId.value })
    };
    
    if (contextUserRole.value === 3 && !contextUserAdminId.value) {
      console.warn('Bruger med rolle 3 mangler adminId');
    }
    
    templateReader.value = createTemplateReader(userData);
  }
  
  await hentDesignGrupper();
  await hentDesigns();
});
</script>

<style scoped>
/* ... eksisterende styles ... */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: #393939;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 400px;
  padding: 20px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h3 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.modal-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.button-group {
  display: flex;
  justify-content: flex-start;
  gap: 0.75rem;
  margin-top: 1rem;
  width: 100%;
}

.row_gap_5 {
  row-gap: 1.25rem;
}

.mini-button {
  font-size: 0.65rem !important;
  line-height: 1.2 !important;
  padding: 0.15rem 0.3rem !important;
}

.align-items-center {
  align-items: center !important;
}

.designGroup {
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0.75rem 1rem;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.designGroup:hover {
  background: rgba(94, 114, 228, 0.1);
  border-color: rgba(94, 114, 228, 0.3);
  transform: translateY(-1px);
}

.designGroup.active {
  background: rgba(94, 114, 228, 0.15);
  border-color: rgba(94, 114, 228, 0.5);
}

.gruppe-actions {
  display: flex;
  gap: 0.5rem;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.designGroup:hover .gruppe-actions {
  opacity: 1;
}

.gruppe-button {
  padding: 0.35rem 0.5rem !important;
  font-size: 0.75rem !important;
  border-radius: 8px !important;
  display: flex !important;
  align-items: center !important;
  gap: 0.25rem !important;
  transition: all 0.2s ease !important;
  backdrop-filter: blur(8px) !important;
}

.gruppe-button:hover {
  transform: translateY(-1px) !important;
}

.gruppe-button i {
  font-size: 0.8rem !important;
}

.design-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.filters {
  display: flex;
  gap: 1rem;
}

.designs-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 1.5rem;
  width: 100%;
}

@media (max-width: 1400px) {
  .designs-container {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
  
  .design-card {
    height: 200px;
  }
}

@media (max-width: 768px) {
  .designs-container {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
    padding: 1rem;
  }
  
  .design-card {
    height: 180px;
  }
  
  .design-info {
    transform: translateY(0);
    position: relative;
    background: rgba(22, 22, 22, 1);
  }
}

.design-card {
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.design-card::after {
  content: "";
  display: block;
  padding-bottom: var(--aspect-ratio, 56.25%); /* Brug custom property med fallback */
}

.image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1a1a1a;
}

.design-image, .design-video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 0.5rem;
}

.design-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(22, 22, 22, 0.95);
  backdrop-filter: blur(10px);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0.75rem;
  transform: translateY(100%);
  transition: transform 0.1s ease;
}

.design-card:hover .design-info {
  transform: translateY(0);
}

.design-uuid {
  font-size: 0.75rem;
  color: #a0aec0;
  margin-bottom: 0.5rem;
  word-break: break-all;
}

.button-group {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.button {
  flex: 1;
  padding: 0.4rem 0.75rem;
  font-size: 0.75rem;
  border-radius: 0.4rem;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.35rem;
}

.button i {
  font-size: 0.9rem;
}

.button:hover {
  transform: translateY(-2px);
}

/* Tilføj disse nye styles */
.design-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(to bottom, 
    rgba(0,0,0,0.4) 0%,
    rgba(0,0,0,0) 100%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  z-index: 1;
}

.design-card:hover::before {
  opacity: 1;
}

/* Opdater card styling */
.card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transform: translateZ(0);
  will-change: transform;
  overflow: hidden;
}

.card-body {
  padding: 1.5rem 2rem !important;
}

.card-header {
  background: rgba(255, 255, 255, 0.02);
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  padding: 1rem 2rem !important;
}

/* Input fields */
.form-control {
  background: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  border-radius: 12px;
  padding: 12px 20px;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-control:focus {
  border-color: #5e72e4;
  box-shadow: 0 0 0 2px rgba(94, 114, 228, 0.2);
  outline: none;
}

.form-control:hover {
  border-color: rgba(94, 114, 228, 0.5);
}

/* Modal styling */
.modal-overlay {
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
}

.modal-container {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  box-shadow: 
    0 15px 35px rgba(0, 0, 0, 0.2),
    0 0 0 1px rgba(255, 255, 255, 0.1);
}

/* Optimized animations */
@keyframes modalShow {
  from {
    opacity: 0;
    transform: translateY(20px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
}

.modal-container {
  animation: modalShow 0.3s cubic-bezier(0.2, 0, 0.2, 1);
}

/* Accessibility improvements */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* Dark mode optimizations */
@media (prefers-color-scheme: dark) {
  .design-card {
    background: rgba(255, 255, 255, 0.03);
  }
  
  .design-info {
    background: rgba(18, 18, 18, 0.95);
  }
}
</style>