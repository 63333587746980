<template>
  <div class="container-fluid py-4">
    <!-- Global Loading Overlay -->
    <div v-if="isLoading || isAnalyzingColor" class="global-loading-overlay">
      <div class="loading-content">
        <div class="loading-text">
          {{ isAnalyzingColor ? 'Analyserer bilens farve...' : 'Indlæser billeder...' }}
        </div>
        <div class="container">
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
        </div>
      </div>
    </div>

    <!-- Step 1: Vælg materialetype -->
    <div v-if="currentStep === 1" class="col" style="align-items: center; padding: 0rem 4rem;">
      <div class="row" style="margin-top: 2rem; justify-content: center; gap: 2rem;">
        <!-- Kampagnematerialer -->
        <div class="col-3 text-center" style="height: 24rem; width: 19rem;">
          <div class="gradient-text mb-0">Kampagnematerialer</div>
          <div class="row" style="width: 100%; height: 100%; margin-top: 2rem; margin-right: unset; margin-bottom: unset; margin-left: unset; justify-content: center;">
            <div class="col hoveredImg" 
                 style="border-radius: 1rem; width: 100%; height: 100%; margin: unset; padding: unset; border: 1px solid rgba(0, 0, 0, 0.125); background: #202124;"
                 @click="handleMaterialSelect('kampagne')">
              <div class="avatar" style="height: 100%; width: 100%; flex-direction: column;">
                <img 
                  src="https://firebasestorage.googleapis.com/v0/b/toft-d4f39.appspot.com/o/car-image.png?alt=media&token=d365475f-1ad6-49d1-8ef3-97ebe4de5997"
                  id="Social Media"
                  name="Social Media"
                  style="height: 22rem; width: 15rem; border: 1px solid rgba(0, 0, 0, 0.125);"
                >
              </div>
            </div>
          </div>
        </div>

        <!-- Bilprospekt -->
        <div class="col-3 text-center" style="height: 24rem; width: 19rem;">
          <div class="gradient-text mb-0">Bilprospekt</div>
          <div class="row" style="width: 100%; height: 100%; margin-top: 2rem; margin-right: unset; margin-bottom: unset; margin-left: unset; justify-content: center;">
            <div class="col hoveredImg" 
                 style="border-radius: 1rem; width: 100%; height: 100%; margin: unset; padding: unset; border: 1px solid rgba(0, 0, 0, 0.125); background: #202124;"
                 @click="handleMaterialSelect('prospekt')">
              <div class="avatar" style="height: 100%; width: 100%; flex-direction: column;">
                <img 
                  src="https://firebasestorage.googleapis.com/v0/b/toft-d4f39.appspot.com/o/car_video.png?alt=media&token=558137e2-2dd6-42c0-9b62-70eadd0a72a2"
                  id="Printmatriale"
                  name="Printmatriale"
                  style="height: 22rem; width: 15rem; border: 1px solid rgba(0, 0, 0, 0.125);"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- NEW Step 2: Vælg PDF design -->
    <div v-else-if="currentStep === 2" class="w-100">
      <div class="d-flex justify-content-between align-items-center w-100 mb-4">
        <button @click="handleBack" class="btn btn-outline-secondary btn-md">
          <i class="fas fa-arrow-left mr-2"></i> Tilbage
        </button>
        <button @click="handleNext" class="btn btn-outline-secondary btn-md">
          Næste <i class="fas fa-arrow-right ml-2"></i>
        </button>
      </div>
      
      <GetDesign
        :design-types="designTypes"
        :design-images="pdfDesigns"
        :selected-design="selectedDesign"
        :selected-types="[]"
        :selected-channels="[]"
        :hide-header="true"
        :no-padding="true"
        v-model:selected-design="selectedDesign"
        @back="handleBack"
        @next="handleNext"
      />
    </div>

    <!-- Existing Step 2 becomes Step 3 -->
    <div v-else-if="currentStep === 3" class="w-100">
      <div class="d-flex justify-content-between align-items-center w-100 mb-4">
        <button @click="handleBack" class="btn btn-outline-secondary btn-md">
          <i class="fas fa-arrow-left mr-2"></i> Tilbage
        </button>
        <div v-if="isAnalyzingColor" class="color-analysis-status">
          <div class="spinner-border spinner-border-sm text-primary" role="status">
            <span class="visually-hidden">Analyserer bilens farve...</span>
          </div>
          <span class="ms-2">Analyserer bilens farve...</span>
        </div>
        <div v-else style="width: 80px;"></div>
      </div>
      <SelectCar @car-selected="handleCarSelected" />
    </div>

    <!-- Existing Step 3 becomes Step 4 -->
    <div v-else-if="currentStep === 4" class="w-100">
      <div class="d-flex justify-content-between align-items-center w-100 mb-4">
        <button @click="handleBack" class="btn btn-outline-secondary btn-md">
          <i class="fas fa-arrow-left mr-2"></i> Tilbage
        </button>
        <div class="gradient-text mb-0">Vælg vinkel</div>
        <button 
          @click="handleImageSelect" 
          class="btn btn-outline-primary btn-md"
          :disabled="!generatedImageUrl">
          Næste <i class="fas fa-arrow-right ml-2"></i>
        </button>
      </div>

      <div class="controls-container mb-4 d-flex flex-column align-items-center">
        <!-- Vinkel vælger -->
        <div class="control-group text-center w-100" style="max-width: 1000px;">
          <label class="d-block mb-3">Vælg vinkel:</label>
          <div class="angle-preview-grid">
            <div 
              v-for="angle in ANGLES" 
              :key="angle"
              :class="['angle-preview-item', { active: selectedAngle === angle }]"
              @click="updateImage({ angle }); selectedAngle = angle;"
            >
              <img 
                :src="generatePreviewUrl(angle)"
                :alt="`Vinkel ${angle}`"
                class="angle-preview-image"
              >
              <div class="angle-label">{{ getCustomAngleLabel(angle) }}</div>
            </div>
          </div>
        </div>

        <!-- Lifestyle toggle button -->
        <button 
          class="lifestyle-toggle-btn w-100"
          style="max-width: 1000px;"
          @click="toggleLifestyleSection"
        >
          {{ showLifestyleOptions ? 'Skjul lifestyle baggrunde' : 'Vis lifestyle baggrunde' }}
          <i :class="['fas', showLifestyleOptions ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
        </button>

        <!-- Lifestyle sektion -->
        <transition name="slide">
          <div v-show="showLifestyleOptions" class="lifestyle-section w-100" style="max-width: 1000px;">
            <div class="lifestyle-grid">
              <!-- Standard option -->
              <div 
                :class="['lifestyle-card', { active: !useLifestyle }]"
                @click="disableLifestyle"
              >
                <div class="card-image">
                  <img :src="generatedImageUrl" alt="Standard">
                </div>
                <div class="card-label">Standard</div>
              </div>

              <!-- Lifestyle options -->
              <div 
                v-for="sur in SURROUNDINGS" 
                :key="sur.id"
                :class="['lifestyle-card', { active: useLifestyle && selectedSurrounding === sur.id }]"
                @click="selectLifestyle(sur.id)"
              >
                <div class="card-image">
                  <img 
                    :src="getLifestylePreviewUrl(sur.id)" 
                    :alt="sur.name"
                  >
                </div>
                <div class="card-label">{{ sur.name }}</div>
              </div>
            </div>
          </div>
        </transition>

        <!-- Hovedbillede -->
        <div class="main-image-container w-100" style="max-width: 1000px;">
          <div v-if="isLoading" class="loading-overlay">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Indlæser...</span>
            </div>
          </div>
          <img :src="generatedImageUrl" alt="Valgt bil" class="main-image">
          <div class="image-label" v-if="useLifestyle">{{ selectedSurrounding }}</div>
        </div>
      </div>
    </div>

    <!-- New Step 5: Live Editor -->
    <div v-else-if="currentStep === 5" class="w-100">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <button @click="handleBack" class="btn btn-outline-secondary btn-md">
          <i class="fas fa-arrow-left mr-2"></i> Tilbage
        </button>
        <div style="width: 80px;"></div>
      </div>

      <!-- Bare render liveEditor direkte -->
      <liveEditor
        v-if="selectedImage && selectedCar"
        :template-id="'6718bfc6bc4b170e562be47f'"
        :car-data="selectedCar"
        :selected-image="selectedImage"
        @cancel="handleBack"
        @save="handleSaveDesign"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { generateImaginUrl, ANGLES } from '@/services/imageGenerationService';
import SelectCar from './SelectCar.vue';
import axios from 'axios';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import GetDesign from './getDesign.vue';
import liveEditor from './liveEditor.vue';

const API_BASE_URL = 'https://us-central1-toft-d4f39.cloudfunctions.net';

// States
const currentStep = ref(1);
const selectedMaterialType = ref(null);
const selectedDesign = ref(null);
const pdfDesigns = ref([]);
const designTypes = ref([]);
const selectedCar = ref(null);
const generatedImageUrl = ref(null);
const isLoading = ref(false);
const errorMessage = ref(null);
const selectedAngle = ref('01');
const isAnalyzingColor = ref(false);
const useLifestyle = ref(false);
const selectedSurrounding = ref('sur1');
const showLifestyleOptions = ref(false);
const selectedImage = ref(null);
const showLiveEditor = ref(false);

// Lifestyle options uden sur6
const SURROUNDINGS = [
  { id: 'sur1', name: 'Mediterranean Village Square' },
  { id: 'sur2', name: 'White Hall' },
  { id: 'sur3', name: 'Sunset House' },
  { id: 'sur4', name: 'Desert Rock' },
  { id: 'sur5', name: 'Warehouse' },
  { id: 'sur21', name: 'Brick House' }
];

// Image cache og preload system
const imageCache = new Map();
const CACHE_TIMEOUT = 1000 * 60 * 60; // 1 time

// Process preload queue funktion
const processPreloadQueue = async () => {
  if (isPreloading || preloadQueue.length === 0) return;
  
  isPreloading = true;
  while (preloadQueue.length > 0) {
    const loadImage = preloadQueue.shift();
    await loadImage();
    // Lille pause mellem hver preload for at undgå overbelastning
    await new Promise(resolve => setTimeout(resolve, 100));
  }
  isPreloading = false;
};

// Opdateret preloadImage funktion
const preloadImage = async (url, priority = false) => {
  const now = Date.now();
  const cached = imageCache.get(url);
  
  if (cached && (now - cached.timestamp) < CACHE_TIMEOUT) {
    return cached.url;
  }
  
  const loadImage = () => new Promise((resolve, reject) => {
    const img = new Image();
    const timer = setTimeout(() => reject(new Error('Timeout')), 10000);
    
    img.onload = () => {
      clearTimeout(timer);
      imageCache.set(url, { url, timestamp: now });
      resolve(url);
    };
    
    img.onerror = () => {
      clearTimeout(timer);
      reject(new Error('Billedfejl'));
    };
    
    img.src = url;
  });
  
  try {
    return await loadImage();
  } catch (error) {
    console.error('Fejl ved billedindlæsning:', error);
    return null;
  }
};

// Opdateret preloadNextAngles funktion
const preloadNextAngles = async () => {
  const currentIndex = ANGLES.indexOf(selectedAngle.value);
  const nextAngles = ANGLES.slice(currentIndex + 1, currentIndex + 3);
  
  for (const angle of nextAngles) {
    const url = generatePreviewUrl(angle);
    await preloadImage(url, false);
  }
};

// Opdateret preloadLifestyleImages funktion
const preloadLifestyleImages = async () => {
  if (!selectedCar.value) return;

  for (const sur of SURROUNDINGS) {
    const url = getLifestylePreviewUrl(sur.id);
    await preloadImage(url, false);
  }
};

// Tilføj nye refs til at gemme forhåndsvisningsbilleder
const anglePreviewUrls = ref(new Map());
const lifestylePreviewUrls = ref(new Map());

// Opdater generatePreviewUrl funktionen
const generatePreviewUrl = (angle) => {
  if (!selectedCar.value) return '';
  
  const cacheKey = `angle_${angle}`;
  if (anglePreviewUrls.value.has(cacheKey)) {
    return anglePreviewUrls.value.get(cacheKey);
  }

  const baseParams = new URLSearchParams({
    customer: 'n4c',
    make: selectedCar.value.fields?.Mærke?.toLowerCase() || '',
    modelFamily: selectedCar.value.fields?.Model?.toLowerCase() || '',
    angle,
    paintId: selectedCar.value.customPaintId || 'pspc0004',
    zoomType: 'fullscreen', // Ændret til fullscreen for at genbruge til hovedbilledet
    width: 1920,
    forceColor: 'true'
  });
  
  const url = `https://cdn.imagin.studio/getImage?${baseParams}`;
  anglePreviewUrls.value.set(cacheKey, url);
  return url;
};

// Opdater getLifestylePreviewUrl funktionen
const getLifestylePreviewUrl = (surroundingId) => {
  if (!selectedCar.value) return '';
  
  const cacheKey = `lifestyle_${surroundingId}_${selectedAngle.value}`;
  if (lifestylePreviewUrls.value.has(cacheKey)) {
    return lifestylePreviewUrls.value.get(cacheKey);
  }

  const baseParams = new URLSearchParams({
    customer: 'n4c',
    make: selectedCar.value.fields?.Mærke?.toLowerCase() || '',
    modelFamily: selectedCar.value.fields?.Model?.toLowerCase() || '',
    angle: selectedAngle.value,
    surrounding: surroundingId,
    viewPoint: '1',
    aspectRatio: '16:10',
    overlayArea: 'right',
    paintId: selectedCar.value.customPaintId || 'pspc0004',
    zoomType: 'fullscreen', // Ændret til fullscreen for at genbruge til hovedbilledet
    width: 1920,
    forceColor: 'true'
  });
  
  const url = `https://cdn.imagin.studio/getImage?${baseParams}`;
  lifestylePreviewUrls.value.set(cacheKey, url);
  return url;
};

// Opdater updateImage funktionen
const updateImage = async (options = {}) => {
  if (!selectedCar.value) return;
  
  try {
    isLoading.value = true;
    errorMessage.value = null;
    
    if (useLifestyle.value) {
      // Brug det eksisterende lifestyle preview billede
      generatedImageUrl.value = getLifestylePreviewUrl(selectedSurrounding.value);
    } else {
      // Brug det eksisterende vinkel preview billede
      generatedImageUrl.value = generatePreviewUrl(options.angle || selectedAngle.value);
    }
    
    // Preload næste vinkler i baggrunden
    preloadNextAngles();
  } catch (error) {
    console.error('Fejl ved opdatering af billede:', error);
    errorMessage.value = 'Der opstod en fejl ved opdatering af billedet';
  } finally {
    isLoading.value = false;
  }
};

// Initialiser når komponenten mountes
onMounted(async () => {
  if (selectedCar.value) {
    await updateImage();
    preloadLifestyleImages();
  }
});

// Watch for ændringer i selectedCar
watch(selectedCar, async (newCar) => {
  if (newCar) {
    anglePreviewUrls.value.clear(); // Ryd cache ved ny bil
    lifestylePreviewUrls.value.clear();
    await updateImage();
    preloadLifestyleImages();
  }
});

// Methods
const handleMaterialSelect = (type) => {
  selectedMaterialType.value = type;
  fetchPdfDesigns(); // Hent PDF designs når materialetype vælges
  handleNext();
};

const handleBack = () => {
  if (currentStep.value > 1) {
    currentStep.value--;
    if (currentStep.value === 1) {
      selectedMaterialType.value = null;
      selectedCar.value = null;
      generatedImageUrl.value = null;
      errorMessage.value = null;
      selectedAngle.value = '01';
    }
    if (currentStep.value === 2) {
      generatedImageUrl.value = null;
      errorMessage.value = null;
      selectedAngle.value = '01';
      selectedCar.value = null;
    }
  }
};

const handleCarSelected = async (car) => {
  try {
    selectedCar.value = car;
    isLoading.value = true;
    errorMessage.value = null;
    isAnalyzingColor.value = true;

    // Sikrer at vi har de nødvendige data
    if (!car.fields || !car.attachments?.image_1) {
      throw new Error('Manglende bil data');
    }

    // Opdateret farve mapping
    const colorMapping = {
      'Sort': 'imaginblack',
      'Sortmetal': 'imaginblack',
      'Hvid': 'imaginwhite',
      'Hvidmetal': 'imaginwhite',
      'Grå': 'imagingrey',
      'Gråmetal': 'imagingrey',
      'Rød': 'imaginred',
      'Rødmetal': 'imaginred'
    };

    // Check om bilen har en af de definerede farver
    if (car.fields.Farve && colorMapping[car.fields.Farve]) {
      selectedCar.value = {
        ...car,
        customPaintId: colorMapping[car.fields.Farve]
      };
    } else {
      // Hvis farven ikke er i mapping, hent paintId
      const response = await axios.post(`${API_BASE_URL}/getPaintId`, {
        fields: {
          Mærke: car.fields.Mærke,
          Model: car.fields.Model,
          Årgang: car.fields.Årgang
        },
        attachments: {
          image_1: car.attachments.image_1
        }
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.data.success && response.data.paintId) {
        selectedCar.value = {
          ...car,
          customPaintId: response.data.paintId
        };
      } else {
        console.warn('Ingen paintId fundet:', response.data.message);
        selectedCar.value = car;
      }
    }

    // Fase 1: Preload alle vinkler
    console.log('Starter preloading af vinkler...');
    const anglePromises = ANGLES.map(angle => 
      preloadImage(generatePreviewUrl(angle), true)
    );
    
    // Vent på at ALLE vinkler er loaded
    await Promise.all(anglePromises);
    console.log('Alle vinkler er loaded');

    // Opdater det viste billede med den første vinkel
    generatedImageUrl.value = generatePreviewUrl('01');

    // Fase 2: Start preloading af lifestyle billeder i baggrunden
    console.log('Starter preloading af lifestyle billeder...');
    const lifestylePromises = SURROUNDINGS.map(sur => 
      preloadImage(getLifestylePreviewUrl(sur.id), false)
    );

    // Vi venter ikke på lifestyle billeder før vi går videre
    Promise.allSettled(lifestylePromises).then(() => {
      console.log('Alle lifestyle billeder er loaded');
    });

    // Nu kan vi skifte til step 4 (i stedet for step 3), da vi har tilføjet PDF-design step
    currentStep.value = 4;

  } catch (error) {
    console.error('Fejl ved håndtering af bil:', error);
    if (error.response) {
      console.error('Fejl detaljer:', error.response.data);
    }
    errorMessage.value = 'Der opstod en fejl ved behandling af bilen';
  } finally {
    isLoading.value = false;
    isAnalyzingColor.value = false;
  }
};

const retryGeneration = () => {
  if (selectedCar.value) {
    updateImage();
  }
};

const handleNext = () => {
  if (currentStep.value === 1) {
    if (!selectedMaterialType.value) {
      alert('Vælg venligst en materialetype før du fortsætter.');
      return;
    }
    currentStep.value = 2;
  } else if (currentStep.value === 2) {
    if (!selectedDesign.value) {
      alert('Vælg venligst et PDF design før du fortsætter.');
      return;
    }
    currentStep.value = 3;
  } else if (currentStep.value === 3) {
    // ... existing step 2 logic ...
    currentStep.value = 4;
  }
};

// Tilføj denne funktion til at mappe mellem backend og frontend vinkel-labels
const getCustomAngleLabel = (angle) => {
  const angleMap = {
    '01': 'M1',
    '05': 'M2',
    '09': 'M3',
    '17': 'M4',
    '23': 'M5',
    '25': 'M6',
    '28': 'M7',
    '29': 'M8',
    // Tilføj flere mappings efter behov
  };
  return angleMap[angle] || angle;
};

// Nye metoder
const disableLifestyle = async () => {
  useLifestyle.value = false;
  await updateImage();
};

const selectLifestyle = async (surroundingId) => {
  useLifestyle.value = true;
  selectedSurrounding.value = surroundingId;
  await updateImage();
};

// Ny toggle funktion for lifestyle sektion
const toggleLifestyleSection = () => {
  showLifestyleOptions.value = !showLifestyleOptions.value;
};

// Ny funktion til at hente PDF designs
const fetchPdfDesigns = async () => {
  try {
    const db = getFirestore();
    const designsRef = collection(db, 'designImages');
    const q = query(designsRef, where('isPDF', '==', true));
    
    const querySnapshot = await getDocs(q);
    pdfDesigns.value = querySnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        ...data,
        uuid: doc.id
      };
    });

    // Udtræk unikke designtyper
    const types = new Set(pdfDesigns.value.flatMap(design => design.groups || []).filter(Boolean));
    designTypes.value = Array.from(types);
    
    console.log('Hentede PDF designs:', pdfDesigns.value);
  } catch (error) {
    console.error('Fejl ved hentning af PDF designs:', error);
  }
};

const handleImageSelect = () => {
  selectedImage.value = useLifestyle.value ? 
    getLifestylePreviewUrl(selectedSurrounding.value) : 
    generatePreviewUrl(selectedAngle.value);
    
  currentStep.value = 5;
};

const toggleLiveEditor = () => {
  showLiveEditor.value = !showLiveEditor.value;
};

const handleImageUpdated = (updatedImageUrl) => {
  selectedImage.value = updatedImageUrl;
};

const handleSaveDesign = async (updatedNodes) => {
  try {
    // Her kan du implementere din gem logik
    console.log('Gemmer design med nodes:', updatedNodes);
    // Redirect eller vis success message
  } catch (error) {
    console.error('Fejl ved gemning af design:', error);
  }
};
</script>

<style scoped>
.gradient-text {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.9),
    rgb(255, 255, 255)
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-size: clamp(8px, 7vw, 26px);
  font-weight: 600;
  letter-spacing: 1px;
}

.content-box {
  height: 24rem;
  width: 19rem;
}

.content-title {
  font-size: 25px;
  font-weight: bolder;
}

.content-container {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 
    0 8px 32px 0 rgba(31, 38, 135, 0.37),
    0 0 0 1px rgba(255, 255, 255, 0.05);
  transform: translateZ(0);
  will-change: transform;
  padding: 2rem;
}

.content-wrapper {
  border-radius: 1rem;
  width: 100%;
  height: 100%;
  margin: unset;
  padding: unset;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background: #202124;
}

.avatar {
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.content-image {
  height: 22rem;
  width: 15rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.hoveredImg:hover {
  transform: scale(1.02);
  transition: transform 0.3s ease;
  cursor: pointer;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.fas {
  font-family: "Font Awesome 5 Free";
}

.mr-2 {
  margin-right: 0.5rem;
}

.content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.generated-image-container {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  text-align: center;
}

.loading-spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.image-preview {
  width: 100%;
}

.generated-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.error-message {
  color: #dc3545;
  padding: 2rem;
}

.image-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.controls-container {
  width: 100%;
  padding: 1rem;
}

.control-group {
  margin-bottom: 1rem;
}

.angle-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
  justify-content: center;
}

.angle-btn {
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.angle-btn:hover {
  background: #f8f9fa;
  border-color: #0056b3;
}

.angle-btn.active {
  background: #007bff;
  color: white;
  border-color: #0056b3;
}

select {
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.spinner-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.angle-slider {
  width: 100%;
  margin: 1rem 0;
}

.images-container {
  position: relative;
  aspect-ratio: 16/9;
}

.spinner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  object-fit: contain;
}

.spinner-image.current {
  visibility: visible;
}

.angle-preview-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 1rem;
  width: 100%;
  padding: 1rem;
}

.angle-preview-item {
  position: relative;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.2s ease;
  aspect-ratio: 1;
  background: #202124;
}

.angle-preview-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.angle-preview-item.active {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0,123,255,0.5);
}

.angle-preview-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #202124;
  padding: 4px;
}

.angle-label {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.6);
  color: white;
  padding: 0.25rem;
  font-size: 0.8rem;
  text-align: center;
}

.color-analysis-status {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #6c757d;
  padding: 0.5rem;
  background: rgba(0,0,0,0.05);
  border-radius: 4px;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  animation: fadeIn 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.loading-text {
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.container {
  width: 200px;
  height: 100px;
  margin: 0 auto;
}

.ball {
  width: 10px;
  height: 10px;
  margin: 10px auto;
  border-radius: 50px;
}

.ball:nth-child(1) {
  background: #ffffff;
  animation: right 1s infinite ease-in-out;
}

.ball:nth-child(2) {
  background: #ffffff;
  animation: left 1.1s infinite ease-in-out;
}

.ball:nth-child(3) {
  background: #ffffff;
  animation: right 1.05s infinite ease-in-out;
}

.ball:nth-child(4) {
  background: #ffffff;
  animation: left 1.15s infinite ease-in-out;
}

.ball:nth-child(5) {
  background: #ffffff;
  animation: right 1.1s infinite ease-in-out;
}

.ball:nth-child(6) {
  background: #ffffff;
  animation: left 1.05s infinite ease-in-out;
}

.ball:nth-child(7) {
  background: #ffffff;
  animation: right 1s infinite ease-in-out;
}

@keyframes right {
  0% {
    transform: translate(-15px);
  }
  50% {
    transform: translate(15px);
  }
  100% {
    transform: translate(-15px);
  }
}

@keyframes left {
  0% {
    transform: translate(15px);
  }
  50% {
    transform: translate(-15px);
  }
  100% {
    transform: translate(15px);
  }
}

.lifestyle-controls {
  background: rgba(255, 255, 255, 0.05);
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
}

.lifestyle-options {
  padding: 1rem;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 6px;
}

.lifestyle-preview-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  padding: 1rem;
  background: #202124;
  border-radius: 8px;
}

.lifestyle-preview-item {
  background: #202124;
  border: 2px solid transparent;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
}

.lifestyle-preview-item:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.lifestyle-preview-item.active {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0,123,255,0.5);
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview-label {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.8);
  color: white;
  padding: 0.5rem;
  font-size: 0.9rem;
  text-align: center;
}

.section-header {
  padding: 1rem;
  border-bottom: 1px solid rgba(255,255,255,0.1);
}

.section-header h4 {
  margin: 0;
  color: #fff;
  font-size: 1.1rem;
}

.preview-image-wrapper {
  width: 100%;
  height: 100%;
  background: #202124;
  overflow: hidden;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.lifestyle-section {
  background: #202124;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1rem;
}

.lifestyle-title {
  color: #fff;
  font-size: 1.1rem;
  margin-bottom: 1rem;
  padding-left: 0.5rem;
}

.lifestyle-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  width: 100%;
}

.lifestyle-card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 
    0 8px 32px 0 rgba(31, 38, 135, 0.37);
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 0.2s ease;
  aspect-ratio: 16/10;
}

.lifestyle-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.15);
}

.lifestyle-card.active {
  border-color: var(--theme-color);
  box-shadow: 
    0 8px 32px 0 rgba(31, 38, 135, 0.37),
    0 0 0 2px rgba(255, 255, 255, 0.2);
}

.card-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-label {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.8);
  color: white;
  padding: 0.5rem;
  font-size: 0.9rem;
  text-align: center;
}

.main-image-container {
  margin-top: 2rem;
  border-radius: 8px;
  overflow: hidden;
  background: #202124;
  width: 100%;
}

.main-image {
  width: 100%;
  height: auto;
  display: block;
}

.image-label {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0,0,0,0.8);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.lifestyle-toggle-btn {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  width: 100%;
  padding: 1rem;
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.lifestyle-toggle-btn:hover {
  background: rgba(255, 255, 255, 0.15);
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

.global-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(5px);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.loading-text {
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 20px;
  font-weight: 500;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
}
</style>