<template>
  <div class="invoice-overview">
    <!-- Årsoverblik -->
    <div class="overview-header">
      <h3>Overblik {{ selectedYear }}</h3>
      <div class="year-selector">
        <select v-model="selectedYear" class="form-select">
          <option v-for="year in availableYears" :key="year" :value="year">
            {{ year }}
          </option>
        </select>
      </div>
    </div>

    <!-- Hovedtal -->
    <div class="main-stats">
      <div class="stat-card">
        <div class="p-3 position-relative">
          <div class="row">
            <div class="col-7 text-start">
              <p class="text-sm mb-1 text-uppercase font-weight-bold">Omsætning</p>
              <h5 class="mb-0 font-weight-bolder positive">{{ formatAmount(totalRevenue) }}</h5>
              <span class="text-success mt-2 d-block text-sm font-weight-bolder">
                +55% <span class="font-weight-normal text-secondary">siden sidste måned</span>
              </span>
            </div>
            <div class="col-5">
              <div class="dropdown text-end">
                <a href="#" class="cursor-pointer text-secondary">
                  <span class="text-xs text-secondary">{{ new Date().toLocaleDateString('da-DK', { month: 'long' }) }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="stat-card">
        <div class="p-3 position-relative">
          <div class="row">
            <div class="col-7 text-start">
              <p class="text-sm mb-1 text-uppercase font-weight-bold">Udgifter</p>
              <h5 class="mb-0 font-weight-bolder negative">{{ formatAmount(totalExpenses) }}</h5>
              <span class="text-danger mt-2 d-block text-sm font-weight-bolder">
                -23% <span class="font-weight-normal text-secondary">siden sidste måned</span>
              </span>
            </div>
            <div class="col-5">
              <div class="dropdown text-end">
                <a href="#" class="cursor-pointer text-secondary">
                  <span class="text-xs text-secondary">{{ new Date().toLocaleDateString('da-DK', { month: 'long' }) }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="stat-card">
        <div class="p-3 position-relative">
          <div class="row">
            <div class="col-7 text-start">
              <p class="text-sm mb-1 text-uppercase font-weight-bold">Resultat</p>
              <h5 class="mb-0 font-weight-bolder" :class="{ positive: netResult >= 0, negative: netResult < 0 }">
                {{ formatAmount(netResult) }}
              </h5>
              <span class="mt-2 d-block text-sm font-weight-bolder" 
                    :class="{ 'text-success': netResult >= 0, 'text-danger': netResult < 0 }">
                {{ netResult >= 0 ? '+' : '' }}32% 
                <span class="font-weight-normal text-secondary">siden sidste måned</span>
              </span>
            </div>
            <div class="col-5">
              <div class="dropdown text-end">
                <a href="#" class="cursor-pointer text-secondary">
                  <span class="text-xs text-secondary">{{ new Date().toLocaleDateString('da-DK', { month: 'long' }) }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Månedsoversigt -->
    <thin-bar-chart
      :chart="{
        labels: monthLabels,
        datasets: {
          revenue: monthlyRevenue,
          expenses: monthlyExpenses
        }
      }"
    />

    <!-- Momsberegning og Fakturastatus -->
    <div class="overview-section">
      <div class="overview-grid">
        <!-- Venstre side - Momsberegning -->
        <div class="vat-overview">
          <div class="card">
            <div class="card-header pb-0">
              <h6>Momsberegning</h6>
              <p class="text-sm">
                <span class="font-weight-bold">Aktuel periode </span>
                <span class="text-secondary">{{ new Date().toLocaleDateString('da-DK', { month: 'long', year: 'numeric' }) }}</span>
              </p>
            </div>
            <div class="card-body p-3">
              <div class="timeline">
                <!-- Samlet omsætning -->
                <div class="timeline-item">
                  <div class="timeline-content">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h6 class="text-dark text-sm font-weight-bold mb-0">Samlet omsætning</h6>
                        <p class="text-secondary text-xs mt-1 mb-0">
                          Periodens omsætning
                        </p>
                      </div>
                      <div class="d-flex align-items-center text-sm font-weight-bold">
                        <i class="ni ni-bold-up text-success me-2"></i>
                        {{ formatAmount(totalRevenue) }}
                      </div>
                    </div>
                  </div>
                </div>
                
                <!-- Udgående moms -->
                <div class="timeline-item">
                  <div class="timeline-content">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h6 class="text-dark text-sm font-weight-bold mb-0">Udgående moms (salgsmoms)</h6>
                        <p class="text-secondary text-xs mt-1 mb-0">
                          Beregnet af omsætning
                        </p>
                      </div>
                      <div class="d-flex align-items-center text-sm font-weight-bold">
                        <i class="ni ni-bold-up text-success me-2"></i>
                        {{ formatAmount(outputVAT) }}
                      </div>
                    </div>
                  </div>
                </div>
                
                <!-- Indgående moms -->
                <div class="timeline-item">
                  <div class="timeline-content">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h6 class="text-dark text-sm font-weight-bold mb-0">Indgående moms (købsmoms)</h6>
                        <p class="text-secondary text-xs mt-1 mb-0">
                          Beregnet af udgifter
                        </p>
                      </div>
                      <div class="d-flex align-items-center text-sm font-weight-bold">
                        <i class="ni ni-bold-down text-danger me-2"></i>
                        {{ formatAmount(inputVAT) }}
                      </div>
                    </div>
                  </div>
                </div>
                
                <!-- Moms til betaling -->
                <div class="timeline-item">
                  <div class="timeline-content total">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h6 class="text-dark text-sm font-weight-bold mb-0">Moms til betaling</h6>
                        <p class="text-secondary text-xs mt-1 mb-0">
                          Samlet momsafregning
                        </p>
                      </div>
                      <div class="d-flex align-items-center text-sm font-weight-bold">
                        {{ formatAmount(vatToPay) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Højre side - Fakturastatus -->
        <div class="status-overview">
          <div class="card">
            <div class="card-header pb-0">
              <h6>Fakturastatus</h6>
              <p class="text-sm">
                <span class="font-weight-bold">Aktuel periode </span>
                <span class="text-secondary">{{ new Date().toLocaleDateString('da-DK', { month: 'long', year: 'numeric' }) }}</span>
              </p>
            </div>
            <div class="card-body p-3">
              <div class="timeline">
                <!-- Udstedte fakturaer -->
                <div class="timeline-item">
                  <div class="timeline-content">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h6 class="text-dark text-sm font-weight-bold mb-0">Udstedte fakturaer</h6>
                        <p class="text-secondary text-xs mt-1 mb-0">
                          {{ invoiceStats.issued.count }} fakturaer
                        </p>
                      </div>
                      <div class="d-flex align-items-center text-sm font-weight-bold">
                        <i class="ni ni-bold-up text-success me-2"></i>
                        {{ formatAmount(invoiceStats.issued.amount) }}
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Betalte fakturaer -->
                <div class="timeline-item">
                  <div class="timeline-content">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h6 class="text-dark text-sm font-weight-bold mb-0">Betalte fakturaer</h6>
                        <p class="text-secondary text-xs mt-1 mb-0">
                          {{ invoiceStats.paid.count }} fakturaer
                        </p>
                      </div>
                      <div class="d-flex align-items-center text-sm font-weight-bold">
                        <i class="ni ni-bold-up text-success me-2"></i>
                        {{ formatAmount(invoiceStats.paid.amount) }}
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Forfaldne fakturaer -->
                <div class="timeline-item">
                  <div class="timeline-content">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h6 class="text-dark text-sm font-weight-bold mb-0">Forfaldne fakturaer</h6>
                        <p class="text-secondary text-xs mt-1 mb-0">
                          {{ invoiceStats.overdue.count }} fakturaer
                        </p>
                      </div>
                      <div class="d-flex align-items-center text-sm font-weight-bold">
                        <i class="ni ni-bold-down text-danger me-2"></i>
                        {{ formatAmount(invoiceStats.overdue.amount) }}
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Krediterede fakturaer -->
                <div class="timeline-item">
                  <div class="timeline-content total">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h6 class="text-dark text-sm font-weight-bold mb-0">Krediterede fakturaer</h6>
                        <p class="text-secondary text-xs mt-1 mb-0">
                          {{ invoiceStats.credited.count }} fakturaer
                        </p>
                      </div>
                      <div class="d-flex align-items-center text-sm font-weight-bold">
                        {{ formatAmount(invoiceStats.credited.amount) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { collection, query, where, getDocs, Timestamp, doc, getDoc } from 'firebase/firestore';
import { db, auth } from '@/firebase';
import { useStore } from 'vuex';
import ThinBarChart from '@/components/Charts/ThinBarChart.vue';

const store = useStore();
const invoices = ref([]);
const selectedYear = ref(new Date().getFullYear());
const isLoading = ref(true);

// Beregn tilgængelige år
const availableYears = computed(() => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: 5 }, (_, i) => currentYear - i);
});

// Hent fakturaer baseret på brugerrolle
const fetchInvoices = async () => {
  try {
    isLoading.value = true;
    const currentUser = auth.currentUser;
    
    if (!currentUser) {
      console.error('Ingen bruger er logget ind');
      return;
    }

    // Hent brugerens rolle
    const userDocRef = doc(db, 'users', currentUser.uid);
    const userDocSnap = await getDoc(userDocRef);
    
    if (!userDocSnap.exists()) {
      console.error('Bruger findes ikke i databasen');
      return;
    }

    const userRole = userDocSnap.data().role;

    if (userRole === 1) { // SuperAdmin
      // Hent alle brugere
      const usersRef = collection(db, 'users');
      const usersSnapshot = await getDocs(usersRef);
      let allInvoices = [];

      // For hver bruger, hent deres fakturaer
      for (const userDoc of usersSnapshot.docs) {
        const userData = userDoc.data();
        const userInvoicesRef = collection(db, 'users', userDoc.id, 'invoices');
        
        // Filtrer efter det valgte år
        const startDate = new Date(selectedYear.value, 0, 1);
        const endDate = new Date(selectedYear.value, 11, 31);
        
        const q = query(
          userInvoicesRef,
          where('createdAt', '>=', startDate),
          where('createdAt', '<=', endDate)
        );

        const invoicesSnapshot = await getDocs(q);
        const userInvoices = invoicesSnapshot.docs.map(doc => ({
          id: doc.id,
          userId: userDoc.id,
          userName: userData.name,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate()
        }));

        allInvoices = [...allInvoices, ...userInvoices];
      }

      invoices.value = allInvoices;
      console.log('Hentede fakturaer:', allInvoices);
    }
  } catch (error) {
    console.error('Fejl ved hentning af fakturaer:', error);
  } finally {
    isLoading.value = false;
  }
};

// Beregn total omsætning
const totalRevenue = computed(() => {
  return invoices.value.reduce((sum, invoice) => {
    // Medregn kun ikke-kreditnotaer og fakturaer der ikke er krediteret
    if (invoice.type !== 'credit' && !invoice.hasCreditNote) {
      return sum + (invoice.total || 0);
    }
    return sum;
  }, 0);
});

// Beregn total udgifter (kreditnotaer)
const totalExpenses = computed(() => {
  return invoices.value.reduce((sum, invoice) => {
    if (invoice.type === 'credit') {
      return sum + Math.abs(invoice.total || 0);
    }
    return sum;
  }, 0);
});

// Beregn netto resultat
const netResult = computed(() => {
  return totalRevenue.value - totalExpenses.value;
});

// Beregn månedlig data
const monthLabels = computed(() => [
  'Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun',
  'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'
]);

const monthlyRevenue = computed(() => {
  return monthLabels.value.map((_, index) => {
    return invoices.value
      .filter(invoice => {
        const invoiceDate = invoice.createdAt;
        return invoiceDate && 
               invoiceDate.getMonth() === index && 
               invoice.type !== 'credit' && 
               !invoice.hasCreditNote;
      })
      .reduce((sum, invoice) => sum + (invoice.total || 0), 0);
  });
});

const monthlyExpenses = computed(() => {
  return monthLabels.value.map((_, index) => {
    return invoices.value
      .filter(invoice => {
        const invoiceDate = invoice.createdAt;
        return invoiceDate && 
               invoiceDate.getMonth() === index && 
               invoice.type === 'credit';
      })
      .reduce((sum, invoice) => sum + Math.abs(invoice.total || 0), 0);
  });
});

// Beregn faktura statistik
const invoiceStats = computed(() => ({
  issued: {
    count: invoices.value.filter(i => i.type !== 'credit').length,
    amount: invoices.value.reduce((sum, i) => i.type !== 'credit' ? sum + (i.total || 0) : sum, 0)
  },
  paid: {
    count: invoices.value.filter(i => i.status === 'betalt').length,
    amount: invoices.value.reduce((sum, i) => i.status === 'betalt' ? sum + (i.total || 0) : sum, 0)
  },
  overdue: {
    count: invoices.value.filter(i => {
      const dueDate = i.dueDate?.toDate();
      return dueDate && dueDate < new Date() && i.status !== 'betalt';
    }).length,
    amount: invoices.value.reduce((sum, i) => {
      const dueDate = i.dueDate?.toDate();
      return (dueDate && dueDate < new Date() && i.status !== 'betalt') ? sum + (i.total || 0) : sum;
    }, 0)
  },
  credited: {
    count: invoices.value.filter(i => i.type === 'credit').length,
    amount: invoices.value.reduce((sum, i) => i.type === 'credit' ? sum + Math.abs(i.total || 0) : sum, 0)
  }
}));

// Beregn moms
const outputVAT = computed(() => totalRevenue.value * 0.25);
const inputVAT = computed(() => totalExpenses.value * 0.25);
const vatToPay = computed(() => outputVAT.value - inputVAT.value);

// Formater beløb
const formatAmount = (amount) => {
  return new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK',
    minimumFractionDigits: 2
  }).format(amount || 0);
};

// Watch for ændringer i det valgte år
watch(selectedYear, () => {
  fetchInvoices();
});

// Hent fakturaer når komponenten monteres
onMounted(() => {
  fetchInvoices();
});

// Hjælpefunktion til at beregne søjlehøjde i grafen
const calculateBarHeight = (value) => {
  const maxValue = Math.max(
    ...monthlyData.value.map(m => Math.max(m.revenue, m.expenses))
  );
  const heightPercentage = maxValue ? (value / maxValue) * 100 : 0;
  return `${heightPercentage}%`;
};
</script>

<style scoped>
/* Card base styling */
.invoice-overview {
  padding: 20px;
  color: white;
  width: 100%;
  height: 100%;
}

/* Overview header */
.overview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.overview-header h3 {
  color: white;
  font-weight: 600;
}

/* Year selector styling */
.year-selector select {
  background: rgba(255, 255, 255, 0.07);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  border-radius: 12px;
  padding: 8px 16px;
  transition: all 0.3s ease;
}

.year-selector select:focus {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
}

/* Main stats cards */
.main-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.stat-card {
  background: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  transition: transform 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.1);
}

.text-uppercase {
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.font-weight-bold {
  font-weight: 600;
}

.font-weight-bolder {
  font-weight: 700;
}

.text-sm {
  font-size: 0.875rem;
}

.text-xs {
  font-size: 0.75rem;
}

.cursor-pointer {
  cursor: pointer;
}

.positive {
  color: #2ecc71;
}

.negative {
  color: #e74c3c;
}

.text-success {
  color: #2ecc71 !important;
}

.text-danger {
  color: #e74c3c !important;
}

.text-secondary {
  color: rgba(255, 255, 255, 0.6) !important;
}

.dropdown a {
  text-decoration: none;
  color: inherit;
}

.mt-2 {
  margin-top: 0.5rem;
}

.d-block {
  display: block;
}

/* Monthly chart styling */
.monthly-chart {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 15px;
  padding: 20px;
  margin: 30px 0;
}

.chart-legend {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(255, 255, 255, 0.8);
}

.legend-color {
  width: 16px;
  height: 16px;
  border-radius: 4px;
}

.legend-color.revenue {
  background: #2ecc71;
}

.legend-color.expenses {
  background: #e74c3c;
}

/* Chart bars */
.chart {
  display: flex;
  height: 250px;
  align-items: flex-start;
  gap: 10px;
  position: relative;
  padding: 20px 0;
}

.month-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.bars {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  gap: 4px;
  position: relative;
  transform: scaleY(-1);
}

.bar {
  width: 20px;
  transition: height 0.3s ease;
  border-radius: 4px 4px 0 0;
  position: relative;
  cursor: pointer;
  transform-origin: bottom;
}

/* Tilføj hover tooltip styling */
.bar::before {
  content: attr(data-tooltip);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-100%) scaleY(-1);
  padding: 8px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s;
  z-index: 10;
}

.bar:hover::before {
  visibility: visible;
  opacity: 1;
}

.revenue-bar {
  background: linear-gradient(to bottom, #27ae60, #2ecc71);
  top: 0;
}

.expense-bar {
  background: linear-gradient(to bottom, #c0392b, #e74c3c);
  top: 0;
}

.month-label {
  margin-top: 8px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  transform: scaleY(-1);
}

/* VAT overview styling */
.vat-overview {
  margin: 30px 0;
}

.vat-overview .card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.card-header {
  padding: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.card-header h6 {
  color: white;
  font-size: 1rem;
  margin: 0;
}

/* Opdater timeline styling */
.timeline {
  position: relative;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.timeline::before {
  display: none;
}

.timeline-item {
  flex: 1;
  padding: 0.75rem;
  position: relative;
  width: 100%;
  left: -2rem;
}

.timeline-content {
  background: rgba(255, 255, 255, 0.03);
  padding: 1.5rem;
  border-radius: 10px;
  transition: all 0.3s ease;
  height: 100%;
  width: 90%;
  left: 2rem;
  position: relative;
}

.timeline-content:hover {
  background: rgba(255, 255, 255, 0.05);
  transform: translateX(5px);
}

.timeline-content.total {
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.text-secondary {
  color: rgba(255, 255, 255, 0.6) !important;
}

.text-success {
  color: #2ecc71 !important;
}

.text-danger {
  color: #e74c3c !important;
}

.me-2 {
  margin-right: 0.5rem;
}

.text-sm {
  font-size: 0.875rem;
}

.text-xs {
  font-size: 0.75rem;
}

.font-weight-bold {
  font-weight: 600;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mb-0 {
  margin-bottom: 0;
}

/* Invoice status styling */
.invoice-status {
  margin-top: 30px;
}

.status-title {
  color: white;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.status-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.status-card {
  background: rgba(32, 33, 36, 0.9);
  border-radius: 10px;
  transition: all 0.3s ease;
}

.status-card:hover {
  background: rgba(38, 39, 42, 0.9);
  transform: translateY(-2px);
}

.status-content {
  padding: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.status-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.status-count {
  font-size: 1.5rem;
  font-weight: 700;
  color: white;
}

.status-label {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.status-amount {
  font-size: 1rem;
  font-weight: 600;
  margin-top: auto;
}

.status-amount.positive {
  color: #66BB6A;
}

.status-amount.negative {
  color: #EF5350;
}

.status-amount.warning {
  color: #FFA726;
}

.status-icon {
  display: none;
}

.status-grid {
  gap: 1.25rem;
  padding: 0.75rem;
}

.vat-overview,
.status-overview {
  margin: 0;
}

.card {
  height: 100%;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.overview-section {
  margin: 30px 0;
}

.overview-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

/* Responsive design */
@media (max-width: 1200px) {
  .overview-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .status-grid {
    grid-template-columns: 1fr;
  }
}

/* Fjern eventuelle margin/padding fra flex containere */
.d-flex {
  width: 100%;
  justify-content: flex-end;
}

/* Sikrer at card-body ikke begrænser bredden */
.card-body {
  padding: 1rem;
  width: 100%;
}

/* Opdater d-flex styling */
.d-flex {
  width: 100%;
  justify-content: flex-end;
}

/* Opdater timeline-content styling */
.timeline-content {
  background: rgba(255, 255, 255, 0.03);
  padding: 1.5rem;
  border-radius: 10px;
  transition: all 0.3s ease;
  height: 100%;
  width: 90%;
  left: 2rem;
  position: relative;
}

/* Tilføj specifik styling for justify-content-between */
.d-flex.justify-content-between {
  justify-content: space-between !important;
}

/* Opdater højre side med beløb */
.d-flex > div:last-child {
  text-align: right;
  white-space: nowrap;
  min-width: 120px;
}

/* Base timeline styling */
.timeline-content {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 10px;
  transition: all 0.3s ease;
  height: 100%;
  width: 90%;
  left: 2rem;
  position: relative;
}

/* Specifik styling for momsberegning */
.vat-overview .timeline-content {
  padding: 1rem; /* Mindre padding for momsberegning */
}

/* Specifik styling for fakturastatus */
.status-overview .timeline-content {
  padding: 1.5rem; /* Behold større padding for fakturastatus */
}

/* Hover effekt */
.timeline-content:hover {
  background: rgba(255, 255, 255, 0.05);
  transform: translateX(5px);
}

/* Total sektion styling */
.timeline-content.total {
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

/* Opdater timeline-item spacing */
.vat-overview .timeline-item {
  padding: 0.5rem 0.75rem; /* Mindre padding for momsberegning */
}

.status-overview .timeline-item {
  padding: 0.75rem; /* Behold original padding for fakturastatus */
}

/* Opdater text styling */
.text-dark {
  white-space: nowrap;
  margin-right: 0;
}

.text-secondary {
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.6) !important;
  font-size: 0.75rem;
}
</style>

