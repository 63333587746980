import { onMounted, onUnmounted, ref } from 'vue';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db, auth } from '@/firebase';
import { onAuthStateChanged } from 'firebase/auth';

export function useOnlineStatus() {
  const unsubscribeAuth = ref(null);

  // Opdater online status i Firestore
  const updateOnlineStatus = async (status) => {
    const user = auth.currentUser;
    if (!user) return;

    try {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, { 
        isOnline: status,
        lastSeen: status ? null : serverTimestamp()
      });
    } catch (error) {
      console.error('Fejl ved opdatering af online status:', error);
    }
  };

  // Håndter tab/browser lukning
  const handleVisibilityChange = async () => {
    if (!auth.currentUser) return;
    
    if (document.visibilityState === 'hidden') {
      await updateOnlineStatus(false);
    } else if (document.visibilityState === 'visible') {
      await updateOnlineStatus(true);
    }
  };

  onMounted(() => {
    // Lyt på auth state changes
    unsubscribeAuth.value = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Bruger logger ind - sæt online
        updateOnlineStatus(true);
        // Tilføj event listener for tab/browser lukning
        document.addEventListener('visibilitychange', handleVisibilityChange);
      } else {
        // Bruger logger ud - sæt offline
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      }
    });

    // Tilføj event listener for browser lukning
    window.addEventListener('beforeunload', () => {
      updateOnlineStatus(false);
    });
  });

  onUnmounted(() => {
    // Ryd op når komponenten unmountes
    if (unsubscribeAuth.value) {
      unsubscribeAuth.value();
    }
    document.removeEventListener('visibilitychange', handleVisibilityChange);
    updateOnlineStatus(false);
  });

  return {
    updateOnlineStatus
  };
} 