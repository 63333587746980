import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

export function useUserContext() {
  const route = useRoute()
  const store = useStore()
  
  const contextUserId = computed(() => {
    const currentUserId = store.state.activeUser?.id
    const routeId = route.params.id
    const userRole = store.state.activeUser?.role

    // Hvis brugeren er admin (1) eller manager (2), og der er et route ID
    if (routeId && (userRole === 1 || userRole === 2)) {
      return routeId
    }
    
    // Hvis det er brugerens egen profil eller ingen route ID
    if (!routeId || routeId === currentUserId) {
      return currentUserId
    }

    // Fallback til brugerens eget ID
    return currentUserId
  })

  const contextUserRole = computed(() => {
    return store.state.activeUser?.role
  })

  const canAccessOtherUsers = computed(() => {
    const userRole = store.state.activeUser?.role
    return userRole === 1 || userRole === 2
  })

  const isOwnProfile = computed(() => {
    return contextUserId.value === store.state.activeUser?.id
  })

  const contextUserAdminId = ref(null);

  return {
    contextUserId,
    contextUserRole,
    canAccessOtherUsers,
    isOwnProfile,
    contextUserAdminId
  }
}

export default useUserContext