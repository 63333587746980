<template>
  <div class="invoice-admin-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h5>
          <i class="fas fa-file-invoice me-2"></i>
          {{ userData?.name }}
        </h5>
        <button class="close-btn" @click="$emit('close')">
          <i class="fas fa-times"></i>
        </button>
      </div>

      <div class="modal-body">
        <div v-if="!invoices.length" class="empty-state">
          <p>Ingen fakturaer</p>
          <argon-button
            v-if="canCreateInvoice"
            color="success"
            size="sm"
            @click="createNewInvoice"
          >
            <i class="fas fa-plus me-2"></i>Opret faktura
          </argon-button>
        </div>

        <div v-else class="invoice-table-container">
          <table class="invoice-table">
            <thead>
              <tr>
                <th>FAKTURA</th>
                <th>DATO</th>
                <th>BELØB</th>
                <th>STATUS</th>
                <th>HANDLINGER</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="invoice in invoices" :key="invoice.id">
                <td>{{ invoice.invoiceNumber }}</td>
                <td>{{ formatDate(invoice.createdAt) }}</td>
                <td>{{ formatAmount(invoice.total) }}</td>
                <td>
                  <span class="status-badge" :class="getStatusClass(invoice.status)">
                    {{ formatStatus(invoice.status) }}
                  </span>
                </td>
                <td>
                  <div class="action-buttons">
                    <button 
                      v-if="invoice.status === 'udstedt'"
                      class="action-btn success"
                      @click="markAsPaid(invoice)"
                      v-tooltip="'Marker som betalt'"
                    >
                      <i class="fas fa-check"></i>
                    </button>

                    <button 
                      v-if="invoice.status !== 'krediteret'"
                      class="action-btn edit"
                      @click="editInvoice(invoice)"
                      v-tooltip="'Rediger faktura'"
                    >
                      <i class="fas fa-edit"></i>
                    </button>

                    <button 
                      v-if="invoice.status !== 'krediteret'"
                      class="action-btn credit"
                      @click="openCreditModal(invoice)"
                      v-tooltip="'Krediter faktura'"
                    >
                      <i class="fas fa-undo"></i>
                    </button>

                    <button 
                      class="action-btn delete"
                      @click="deleteInvoice(invoice)"
                      v-tooltip="'Slet faktura'"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showEditModal" class="modal-overlay">
    <div class="edit-modal">
      <h6>Rediger Faktura #{{ selectedInvoice?.invoiceNumber }}</h6>
      
      <div class="form-group">
        <label>Subtotal</label>
        <input 
          type="number" 
          v-model="editForm.subtotal" 
          class="form-control"
          @input="calculateTotal"
        >
      </div>

      <div class="form-group">
        <label>Rabat (%)</label>
        <input 
          type="number" 
          v-model="editForm.discount" 
          class="form-control"
          @input="calculateTotal"
        >
      </div>

      <div class="form-group">
        <label>Moms (25%)</label>
        <input 
          type="number" 
          v-model="editForm.tax" 
          class="form-control" 
          disabled
        >
      </div>

      <div class="form-group">
        <label>Total</label>
        <input 
          type="number" 
          v-model="editForm.total" 
          class="form-control" 
          disabled
        >
      </div>

      <div class="form-group">
        <label>Status</label>
        <select v-model="editForm.status" class="form-control">
          <option value="udstedt">Udstedt</option>
          <option value="betalt">Betalt</option>
          <option value="forfalden">Forfalden</option>
        </select>
      </div>

      <div class="modal-footer">
        <button class="btn btn-secondary" @click="showEditModal = false">
          Annuller
        </button>
        <button class="btn btn-success" @click="saveInvoiceChanges">
          Gem ændringer
        </button>
      </div>
    </div>
  </div>

  <div v-if="showCreditModal" class="modal-overlay">
    <div class="credit-modal">
      <h6>Opret Kreditnota</h6>
      
      <div class="original-invoice-info">
        <p><strong>Original Faktura:</strong> #{{ creditForm.originalInvoice?.invoiceNumber }}</p>
        <p><strong>Beløb:</strong> {{ formatAmount(creditForm.originalInvoice?.total) }}</p>
      </div>

      <div class="form-group">
        <label>Type af kreditering</label>
        <select v-model="creditForm.type" class="form-control">
          <option value="full">Fuld kreditering</option>
          <option value="partial">Delvis kreditering</option>
        </select>
      </div>

      <div class="form-group" v-if="creditForm.type === 'partial'">
        <label>Beløb at kreditere</label>
        <input 
          type="number" 
          v-model="creditForm.amount" 
          class="form-control"
          :max="creditForm.originalInvoice?.total"
        >
      </div>

      <div class="form-group">
        <label>Begrundelse *</label>
        <select v-model="creditForm.reasonType" class="form-control mb-2">
          <option value="">Vælg årsag</option>
          <option value="fejlkøb">Kundens fejlkøb</option>
          <option value="fakturafejl">Fejl i fakturaen</option>
          <option value="annullering">Handel tilbagetrækkes</option>
          <option value="antal">Forkert antal faktureret</option>
          <option value="fejl">Fejl eller mangler i levering</option>
          <option value="pris">Forkert pris faktureret</option>
          <option value="prisafslag">Prisafslag</option>
          <option value="andet">Andet</option>
        </select>
        <textarea 
          v-model="creditForm.reason" 
          class="form-control"
          rows="3"
          placeholder="Uddybende beskrivelse"
          required
        ></textarea>
      </div>

      <div class="modal-footer">
        <button class="btn btn-secondary" @click="showCreditModal = false">
          Annuller
        </button>
        <button 
          class="btn btn-success" 
          @click="createCreditNote"
          :disabled="!creditForm.reason || !creditForm.reasonType"
        >
          Opret kreditnota
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { 
  collection, 
  query, 
  getDocs, 
  doc, 
  updateDoc, 
  addDoc, 
  getDoc, 
  deleteDoc
} from 'firebase/firestore';
import { db } from '@/firebase';
import ArgonButton from "@/components/ArgonButton.vue";
import { useStore } from 'vuex';

const store = useStore();
const invoiceSettings = ref(null);

const props = defineProps({
  userId: {
    type: String,
    required: true
  },
  userData: {
    type: Object,
    required: true
  },
  users: {
    type: Array,
    required: true
  }
});

const invoices = ref([]);
const showEditModal = ref(false);
const selectedInvoice = ref(null);
const editForm = ref({
  subtotal: 0,
  discount: 0,
  tax: 0,
  total: 0,
  status: ''
});

const showCreditModal = ref(false);
const creditForm = ref({
  originalInvoice: null,
  amount: 0,
  reason: '',
  type: 'full', // 'full' eller 'partial'
});

// Tilføj disse konstanter øverst i script-delen
const ADMIN_LICENSE_PRICE = 14995;
const USER_LICENSE_PRICE = 2995;
const PRICE_PER_USER = 495;

// Hent fakturaer
const fetchInvoices = async () => {
  try {
    const invoicesRef = collection(db, 'users', props.userId, 'invoices');
    const q = query(invoicesRef);
    const snapshot = await getDocs(q);
    
    invoices.value = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Fejl ved hentning af fakturaer:', error);
  }
};

// Marker faktura som betalt
const markAsPaid = async (invoice) => {
  try {
    const invoiceRef = doc(db, 'users', props.userId, 'invoices', invoice.id);
    await updateDoc(invoiceRef, {
      status: 'betalt',
      paidAt: new Date()
    });
    await fetchInvoices(); // Genindlæs fakturaer
  } catch (error) {
    console.error('Fejl ved markering af betaling:', error);
    alert('Der opstod en fejl ved markering af betaling');
  }
};

// Åbn redigering af faktura
const editInvoice = (invoice) => {
  selectedInvoice.value = invoice;
  editForm.value = {
    subtotal: invoice.subtotal,
    discount: invoice.discount || 0,
    tax: invoice.tax,
    total: invoice.total,
    status: invoice.status
  };
  showEditModal.value = true;
};

// Beregn total ved ændringer
const calculateTotal = () => {
  const subtotal = Number(editForm.value.subtotal);
  const discount = Number(editForm.value.discount);
  const discountAmount = subtotal * (discount / 100);
  const afterDiscount = subtotal - discountAmount;
  editForm.value.tax = afterDiscount * 0.25;
  editForm.value.total = afterDiscount + editForm.value.tax;
};

// Gem ændringer
const saveInvoiceChanges = async () => {
  try {
    const invoiceRef = doc(db, 'users', props.userId, 'invoices', selectedInvoice.value.id);
    await updateDoc(invoiceRef, {
      subtotal: Number(editForm.value.subtotal),
      discount: Number(editForm.value.discount),
      tax: Number(editForm.value.tax),
      total: Number(editForm.value.total),
      status: editForm.value.status,
      lastUpdated: new Date()
    });
    
    showEditModal.value = false;
    await fetchInvoices(); // Genindlæs fakturaer
  } catch (error) {
    console.error('Fejl ved gem af ændringer:', error);
    alert('Der opstod en fejl ved gem af ændringer');
  }
};

// Hjælpefunktioner
const formatDate = (timestamp) => {
  if (!timestamp) return '';
  const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
  return date.toLocaleDateString('da-DK');
};

const formatAmount = (amount) => {
  return new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK'
  }).format(amount);
};

const formatStatus = (status) => {
  const statusMap = {
    'betalt': 'Betalt',
    'udstedt': 'Udstedt',
    'forfalden': 'Forfalden'
  };
  return statusMap[status] || status;
};

const getStatusClass = (status) => {
  return {
    'bg-success': status === 'betalt',
    'bg-warning': status === 'udstedt',
    'bg-danger': status === 'forfalden'
  };
};

// Opdater calculateInvoiceItems computed property
const calculateInvoiceItems = computed(() => {
  const currentUser = store.state.activeUser;
  
  // Hvis brugeren er en admin (rolle 2)
  if (props.userData?.role === 2) {
    // Tæl antal brugere denne admin har
    const userCount = props.users.filter(user => 
      user.createdBy === props.userData.id && user.role === 3
    ).length;

    console.log('Admin bruger:', props.userData);
    console.log('Antal brugere:', userCount);
    console.log('Admin pris:', ADMIN_LICENSE_PRICE);
    console.log('Pris per bruger:', PRICE_PER_USER);

    return {
      items: [
        {
          description: 'System abonnement - månedlig administrator licens',
          quantity: 1,
          rate: ADMIN_LICENSE_PRICE,
          amount: ADMIN_LICENSE_PRICE
        },
        ...(userCount > 0 ? [{
          description: `Bruger licenser (${userCount} brugere)`,
          quantity: userCount,
          rate: PRICE_PER_USER,
          amount: userCount * PRICE_PER_USER
        }] : [])
      ],
      subtotal: ADMIN_LICENSE_PRICE + (userCount * PRICE_PER_USER)
    };
  }
  
  // For almindelige brugere (rolle 3)
  return {
    items: [
      {
        description: 'System abonnement - månedlig licens',
        quantity: 1,
        rate: USER_LICENSE_PRICE,
        amount: USER_LICENSE_PRICE
      }
    ],
    subtotal: USER_LICENSE_PRICE
  };
});

// Tilføj denne watch for at debugge
watch(() => props.userData?.role, (newRole) => {
  console.log('Bruger rolle ændret:', newRole);
  console.log('Bruger data:', props.userData);
  console.log('Tilgængelige brugere:', props.users);
});

// Tilføj denne computed property
const canCreateInvoice = computed(() => {
  const currentUser = store.state.activeUser;
  
  // Tjek om den aktuelle bruger har rettigheder til at oprette faktura
  if (currentUser.role === 1) {
    return props.userData?.createdBy === currentUser.id;
  }
  if (currentUser.role === 2) {
    return props.userData?.createdBy === currentUser.id;
  }
  return false;
});

// Opdater fetchInvoiceSettings funktionen
const fetchInvoiceSettings = async () => {
  try {
    const currentUser = store.state.activeUser;
    if (!currentUser) return;

    // Hent den bruger der opretter fakturaen (enten SuperAdmin eller Admin)
    const creatorDoc = await getDoc(doc(db, 'users', currentUser.id));
    if (creatorDoc.exists()) {
      const creatorData = creatorDoc.data();
      console.log('Creator data:', creatorData);
      
      if (creatorData.adminSettings?.InvoiceSettings) {
        invoiceSettings.value = {
          companyName: creatorData.adminSettings.InvoiceSettings.companyName,
          address: creatorData.adminSettings.InvoiceSettings.address,
          postalCode: creatorData.adminSettings.InvoiceSettings.postalCode,
          city: creatorData.adminSettings.InvoiceSettings.city,
          cvr: creatorData.adminSettings.InvoiceSettings.cvr,
          phone: creatorData.adminSettings.InvoiceSettings.phone,
          email: creatorData.adminSettings.InvoiceSettings.email,
          website: creatorData.adminSettings.InvoiceSettings.website,
          bankReg: creatorData.adminSettings.InvoiceSettings.bankReg,
          bankAccount: creatorData.adminSettings.InvoiceSettings.bankAccount,
          footerText: creatorData.adminSettings.InvoiceSettings.footerText
        };
        console.log('Indlæste faktura indstillinger:', invoiceSettings.value);
      } else {
        console.warn('Ingen faktura indstillinger fundet for creator:', currentUser.id);
      }
    }
  } catch (error) {
    console.error('Fejl ved hentning af faktura indstillinger:', error);
  }
};

// Opdater onMounted
onMounted(async () => {
  await fetchInvoiceSettings();
  await fetchInvoices();
});

// Opdater createNewInvoice funktionen
const createNewInvoice = async () => {
  try {
    if (!invoiceSettings.value) {
      console.error('Ingen faktura indstillinger tilgængelige');
      alert('Der mangler faktura indstillinger. Kontroller venligst dine indstillinger.');
      return;
    }

    console.log('Bruger faktura indstillinger:', invoiceSettings.value);
    
    const invoiceNumber = generateInvoiceNumber();
    const createdAt = new Date();
    const dueDate = new Date();
    dueDate.setDate(dueDate.getDate() + 8);
    
    const nextInvoiceDate = new Date();
    nextInvoiceDate.setMonth(nextInvoiceDate.getMonth() + 1);
    
    const { items, subtotal } = calculateInvoiceItems.value;
    const tax = subtotal * 0.25;
    const total = subtotal * 1.25;

    // Brug de indlæste indstillinger direkte
    const companyInfo = { ...invoiceSettings.value };
    console.log('Bruger company info:', companyInfo);
    
    const newInvoice = {
      invoiceNumber,
      createdAt,
      dueDate,
      nextInvoiceDate,
      status: 'udstedt',
      type: 'system',
      recurring: true,
      hasRecurringInvoice: true,
      billingCycle: 'monthly',
      companyInfo,
      customerInfo: {
        name: props.userData.name,
        address: props.userData.address,
        cvr: props.userData.cvr,
        email: props.userData.email
      },
      items,
      subtotal,
      tax,
      total,
      discount: 0,
      footerText: invoiceSettings.value.footerText
    };

    console.log('Opretter ny faktura:', newInvoice);

    // Gem fakturaen
    const invoicesRef = collection(db, 'users', props.userId, 'invoices');
    await addDoc(invoicesRef, newInvoice);
    
    // Opdater bruger
    const userRef = doc(db, 'users', props.userId);
    await updateDoc(userRef, {
      nextInvoiceDate: nextInvoiceDate,
      hasRecurringInvoice: true
    });
    
    await fetchInvoices();
    
  } catch (error) {
    console.error('Fejl ved oprettelse af faktura:', error);
    alert('Der opstod en fejl ved oprettelse af fakturaen');
  }
};

// Tilføj hjælpefunktion til at generere fakturanummer
const generateInvoiceNumber = () => {
  const date = new Date();
  const year = date.getFullYear().toString().substr(-2);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const random = Math.floor(Math.random() * 10000).toString().padStart(4, '0');
  return `INV-${year}${month}-${random}`;
};

const deleteInvoice = async (invoice) => {
  if (!confirm(`Er du sikker på at du vil slette faktura #${invoice.invoiceNumber}?`)) {
    return;
  }

  try {
    const invoiceRef = doc(db, 'users', props.userId, 'invoices', invoice.id);
    await deleteDoc(invoiceRef);
    await fetchInvoices();
  } catch (error) {
    console.error('Fejl ved sletning af faktura:', error);
    alert('Der opstod en fejl ved sletning af fakturaen');
  }
};

const openCreditModal = (invoice) => {
  creditForm.value = {
    originalInvoice: invoice,
    amount: invoice.total,
    reason: '',
    type: 'full'
  };
  showCreditModal.value = true;
};

const createCreditNote = async () => {
  try {
    const originalInvoice = creditForm.value.originalInvoice;
    const creditAmount = Number(creditForm.value.amount);
    
    if (creditAmount <= 0 || creditAmount > originalInvoice.total) {
      throw new Error('Ugyldigt kreditbeløb');
    }

    // Generer kreditnota nummer baseret på original faktura
    const creditNoteNumber = `KRED-${originalInvoice.invoiceNumber}`;
    
    const creditNote = {
      invoiceNumber: creditNoteNumber,
      type: 'credit',
      originalInvoiceId: originalInvoice.id,
      originalInvoiceNumber: originalInvoice.invoiceNumber,
      createdAt: new Date(),
      status: 'krediteret',
      reason: creditForm.value.reason,
      creditType: creditForm.value.type,
      referenceText: `Kreditnota til faktura ${originalInvoice.invoiceNumber}`,
      items: [{
        description: `Kreditering af faktura #${originalInvoice.invoiceNumber}`,
        details: creditForm.value.reason,
        quantity: 1,
        rate: -creditAmount,
        amount: -creditAmount
      }],
      subtotal: -creditAmount,
      tax: -(creditAmount * 0.25),
      total: -(creditAmount * 1.25),
      customerInfo: originalInvoice.customerInfo,
      companyInfo: originalInvoice.companyInfo,
      creditNoteType: creditForm.value.type === 'full' ? 'fuld' : 'delvis',
      originalAmount: originalInvoice.total,
      creditAmount: creditAmount,
      remainingAmount: creditForm.value.type === 'full' ? 0 : originalInvoice.total - creditAmount
    };

    // Gem kreditnotaen
    const invoicesRef = collection(db, 'users', props.userId, 'invoices');
    await addDoc(invoicesRef, creditNote);

    // Opdater original faktura
    const originalRef = doc(db, 'users', props.userId, 'invoices', originalInvoice.id);
    
    if (creditForm.value.type === 'full') {
      await updateDoc(originalRef, {
        status: 'krediteret',
        creditNoteNumber: creditNoteNumber,
        creditedAt: new Date(),
        creditReason: creditForm.value.reason,
        fullyCredited: true
      });
    } else {
      // Hent først den eksisterende faktura for at få den nuværende historik
      const originalDoc = await getDoc(originalRef);
      const currentHistory = originalDoc.data()?.partialCreditHistory || [];
      
      await updateDoc(originalRef, {
        partiallyCredited: true,
        creditedAmount: creditAmount,
        remainingAmount: originalInvoice.total - creditAmount,
        creditNoteNumber: creditNoteNumber,
        creditedAt: new Date(),
        creditReason: creditForm.value.reason,
        partialCreditHistory: [...currentHistory, {
          amount: creditAmount,
          date: new Date(),
          reason: creditForm.value.reason,
          creditNoteNumber: creditNoteNumber
        }]
      });
    }

    showCreditModal.value = false;
    await fetchInvoices();
    
  } catch (error) {
    console.error('Fejl ved oprettelse af kreditnota:', error);
    alert(`Fejl: ${error.message}`);
  }
};
</script>

<style scoped>
.invoice-admin-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #1a1f2d;
  border-radius: 12px;
  width: 90%;
  max-width: 1000px;
  max-height: 80vh;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.modal-header h5 {
  margin: 0;
  color: #fff;
  font-size: 1.1rem;
}

.close-btn {
  background: none;
  border: none;
  color: #fff;
  opacity: 0.7;
  cursor: pointer;
  padding: 0.5rem;
}

.close-btn:hover {
  opacity: 1;
}

.modal-body {
  padding: 1rem;
}

.empty-state {
  text-align: center;
  padding: 2rem;
  color: #fff;
}

.invoice-table-container {
  overflow-x: auto;
}

.invoice-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  color: #fff;
}

.invoice-table th {
  background: rgba(255, 255, 255, 0.05);
  padding: 0.75rem;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: left;
}

.invoice-table td {
  padding: 0.75rem;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.status-badge {
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  font-size: 0.75rem;
  font-weight: 500;
}

.status-badge.bg-success { background: rgba(46, 204, 113, 0.2); }
.status-badge.bg-warning { background: rgba(241, 196, 15, 0.2); }
.status-badge.bg-danger { background: rgba(231, 76, 60, 0.2); }

.action-buttons {
  display: flex;
  gap: 0.5rem;
}

.action-btn {
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  color: #fff;
}

.action-btn.success { background: rgba(46, 204, 113, 0.2); }
.action-btn.edit { background: rgba(52, 152, 219, 0.2); }
.action-btn.credit { background: rgba(241, 196, 15, 0.2); }
.action-btn.delete { background: rgba(231, 76, 60, 0.2); }

.action-btn:hover {
  transform: translateY(-1px);
  filter: brightness(1.2);
}

/* Tilføj responsivt design */
@media (max-width: 768px) {
  .invoice-table th:nth-child(2),
  .invoice-table td:nth-child(2) {
    display: none;
  }
  
  .action-buttons {
    flex-wrap: wrap;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.edit-modal,
.credit-modal {
  background: #1a1f2d;
  border-radius: 12px;
  padding: 1.5rem;
  width: 90%;
  max-width: 500px;
  color: #fff;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
}

.form-control {
  width: 100%;
  padding: 0.5rem;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  color: #fff;
}

.form-control:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
}

.original-invoice-info {
  background: rgba(255, 255, 255, 0.05);
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.btn {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s;
}

.btn-secondary {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.btn-success {
  background: rgba(46, 204, 113, 0.2);
  color: #fff;
}

.btn:hover {
  filter: brightness(1.2);
}

.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style> 