// src/router/index.js

import { createRouter, createWebHistory } from "vue-router";
import { auth, db } from "@/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import store from "@/store"; // Importer din Vuex store
import { getAuth } from 'firebase/auth';
import { calendarService } from '@/services/calendarService';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

// Importer dine komponenter
import SweetAlerts from "../views/pages/SweetAlerts.vue";
import Notifications from "../views/pages/Notifications.vue";
import Signin from "../views/auth/signin.vue";
import Tables from "../views/pages/Tables.vue";
import UserProfile from "../views/pages/UserProfile.vue";
import Profile from "../views/pages/Profile.vue";
import Calendar from "../views/pages/Calendar.vue";
import Settings from "../views/pages/Settings.vue";
import BlogOverview from '@/views/pages/BlogOverview.vue'
import BlogPost from '@/views/pages/BlogPost.vue'
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import Home from "@/views/Home.vue";
import BookDemo from "@/views/BookDemo.vue";

// Definer roller som konstanter for bedre vedligeholdelse
const ROLE_ADMIN = 1;
const ROLE_USER = 3;
const ROLE_MANAGER = 2

const requireAuth = async (to, from, next) => {
  const auth = getAuth();
  const user = auth.currentUser;
  
  if (!user) {
    next({ 
      path: '/login',
      query: { redirect: to.fullPath }
    });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: false }
  },
  {
    path: "/authentication/signin",
    name: "Signin",
    component: () => import("@/views/auth/signin.vue"),
    meta: { requiresAuth: false }
  },
  // Profilruter
  {
    path: "/profile/:id?",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true,
      allowAdminAccess: true
    }
  },
  {
    path: "/pages/sweet-alerts",
    name: "Sweet Alerts",
    component: SweetAlerts,
    meta: { requiresAuth: true },
  },
  {
    path: "/pages/notifications",
    name: "Notifications",
    component: Notifications,
    meta: { requiresAuth: true },
  },
  {
    path: "/pages/calendar",
    name: "Calendar",
    component: Calendar,
    meta: { requiresAuth: true },
  },
  {
    path: "/pages/tables",
    name: "Tables",
    component: () => import("../views/pages/Tables.vue"),
    meta: { 
      requiresAuth: true,
      requiresAdminAndSubAdmin: true  // Denne route tillader både admin og subadmin
    }
  },
  {
    path: "/pages/settings",
    name: "Settings",
    component: Settings,
    meta: { requiresAuth: true, requiresAdminAndSubAdmin: true }, // Kræver admin-rolle (role 1)
  },
  {
    path: "/user-profile/:userId",
    name: "UserProfile",
    component: UserProfile,
    meta: { 
      requiresAuth: true,
      requiresAdmin: true  // Denne route kræver superadmin
    }
  },
  {
    path: '/blog',
    name: 'BlogOverview',
    component: BlogOverview
  },
  {
    path: '/blog/:slug/:id',
    name: 'BlogPost',
    component: BlogPost,
    meta: { requiresAuth: false }
  },
  {
    path: '/saved-filters',
    name: 'SavedFilters',
    component: () => import('@/views/pages/SavedFilters.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/invoice/:id',
    name: 'Invoice',
    component: () => import('../views/pages/Invoice.vue')
  },
  {
    path: '/invoice/new',
    name: 'NewInvoice',
    component: () => import('../views/pages/Invoice.vue')
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/book-demo",
    name: "BookDemo",
    component: BookDemo,
  },
  // Wildcard route for 404 Not Found
  {
    path: "/:pathMatch(.*)*",
    redirect: "/authentication/signin",
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/pages/Dashboard.vue'),
    meta: { requiresAuth: true }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Opdater auth initialization
let authInitialized = false;
const authPromise = new Promise((resolve) => {
  onAuthStateChanged(auth, async (user) => {
    if (!authInitialized) {
      authInitialized = true;
      
      if (user) {
        try {
          const userDocRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userDocRef);
          const userData = userDoc.data();
          
          if (userData) {
            await store.dispatch("setCurrentUser", { 
              ...userData, 
              id: user.uid 
            });
          }
        } catch (error) {
          console.error("Fejl ved initial auth:", error);
        }
      }
      resolve(user);
    }
  });
});

// Opdater beforeEach guard
router.beforeEach(async (to, from, next) => {
  // Vent på at auth er initialiseret
  if (!authInitialized) {
    await authPromise;
  }

  const currentUser = store.state.user;
  
  // Hvis bruger prøver at tilgå login siden mens de er logget ind
  if (to.path === '/authentication/signin' && currentUser) {
    return next(currentUser.role <= 2 ? '/pages/tables' : '/profile');
  }

  // Hvis ruten kræver auth og bruger ikke er logget ind
  if (to.meta.requiresAuth && !currentUser) {
    return next({
      path: '/authentication/signin',
      query: { redirect: to.fullPath }
    });
  }

  // Pre-load kalender data hvis brugeren er logget ind
  if (currentUser) {
    if (currentUser.role === 3) {
      // For almindelige brugere, pre-load deres egen kalender
      calendarService.preloadCalendarData(currentUser.id);
    } else if (currentUser.role <= 2 && to.path === '/pages/tables') {
      // For admin og superadmin på tables siden, pre-load deres brugeres kalendere
      const db = getFirestore();
      const usersQuery = query(
        collection(db, "users"),
        where("createdBy", "==", currentUser.id)
      );
      const usersSnapshot = await getDocs(usersQuery);
      usersSnapshot.docs.forEach(doc => {
        calendarService.preloadCalendarData(doc.id);
      });
    }
  }

  // Hvis ruten kræver admin/subadmin rettigheder
  if (to.meta.requiresAdminAndSubAdmin && (!currentUser || currentUser.role > 2)) {
    return next('/profile');
  }

  next();
});

export default router;