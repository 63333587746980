<template>
    <div class="container-fluid">
      <!-- Navigation Tabs -->
      <div v-show="showNavTabs" class="row align-items-center" style="margin-top: -30px; margin-bottom: 20px;">
        <div class="col-lg-6 col-sm-12">
          <div class="nav-wrapper position-relative">
            <ul class="nav nav-pills nav-fill p-1" role="tablist" style="width: 50%;">
              <li class="nav-item">
                <a 
                  role="tab" 
                  class="nav-link mb-0 px-0 py-1" 
                  :class="{ 'active': activeTab === 'autoposts' }"
                  @click="handleTabClick('autoposts')"
                >
                  Autopilot
                </a>
              </li>
              <li class="nav-item">
                <a 
                  role="tab" 
                  class="nav-link mb-0 px-0 py-1" 
                  :class="{ 'active': activeTab === 'list' }"
                  @click="handleTabClick('list')"
                >
                  Redigere Autopilot
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
  
      <!-- Content Area -->
      <!-- Standard Post Content -->
      <new-project v-if="activeTab === 'standard'" />

      <!-- Car Post Content -->
      <car-post v-if="activeTab === 'car'" />

      <!-- AutoPilot Content -->
      <AutoPilot 
        v-if="activeTab === 'autoposts'"
        :editing-post="editingPost"
        @saved="handlePostSaved"
        @cancelled="activeTab = 'list'"
        @step-change="handleStepChange"
        ref="autoPilot"
      />
  
      <!-- AutoPosts List -->
      <div v-if="activeTab === 'list'">
        <h4 class="mb-4">Autopilot Opslag</h4>
        <div class="table-responsive">
          <table class="table align-items-center">
            <thead>
              <tr>
                <th class="text-uppercase text-xs font-weight-bolder">Status</th>
                <th class="text-uppercase text-xs font-weight-bolder">Oprettet den</th>
                <th class="text-uppercase text-xs font-weight-bolder">Destination</th>
                <th class="text-uppercase text-xs font-weight-bolder">Type</th>
                <th class="text-uppercase text-xs font-weight-bolder">Aktiv</th>
                <th class="text-uppercase text-xs font-weight-bolder">Handlinger</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="post in autoPosts" :key="post.id">
                <td>
                  <span class="text-sm">{{ post.status }}</span>
                </td>
                <td>
                  <span class="text-sm">{{ formatDate(post.createdAt) }}</span>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <i v-for="channel in post.channels" 
                       :key="channel"
                       :class="getChannelIcon(channel)"
                       class="fab me-2">
                    </i>
                  </div>
                </td>
                <td>
                  <span class="text-sm">{{ getPostType(post) }}</span>
                </td>
                <td>
                  <button 
                    class="btn btn-sm px-3"
                    :class="post.active ? 'bg-gradient-success' : 'bg-gradient-warning'"
                    @click="togglePostActive(post)"
                  >
                    {{ post.active ? 'Aktiv' : 'Inaktiv' }}
                  </button>
                </td>
                <td>
                  <button 
                    class="btn btn-danger btn-sm px-3"
                    @click="deletePost(post)"
                  >
                    Slet
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, computed, onUnmounted } from 'vue';
  import { getFirestore, collection, getDocs, doc, deleteDoc, updateDoc, getDoc, onSnapshot, query, where } from 'firebase/firestore';
  import { getAuth } from 'firebase/auth';
  import AutoPilot from './AutoPilot.vue';
  
  const db = getFirestore();
  const activeTab = ref('autoposts');
  const currentStep = ref(1);
  const autoPosts = ref([]);
  const autoPilotRef = ref(null);
  const unsubscribeMetaWatch = ref(null);
  const editingPost = ref(null);
  
  // Computed property til at styre synligheden af navigation tabs
  const showNavTabs = computed(() => {
    return activeTab.value === 'list' || 
           activeTab.value === 'standard' ||
           activeTab.value === 'car' ||
           (activeTab.value === 'autoposts' && currentStep.value === 1);
  });
  
  // Handler for tab clicks
  const handleTabClick = (tab) => {
    if (activeTab.value === 'autoposts' && currentStep.value > 1) {
      // Hvis vi er midt i oprettelsesprocessen, spørg om bekræftelse
      if (confirm('Er du sikker på at du vil forlade oprettelsen? Dine ændringer vil gå tabt.')) {
        activeTab.value = tab;
        currentStep.value = 1; // Reset step når vi skifter tab
      }
    } else {
      activeTab.value = tab;
    }
  };
  
  // Handler for step changes fra AutoPilot
  const handleStepChange = (step) => {
    currentStep.value = step;
  };
  
  // Hent autoposts
  const fetchAutoPosts = async () => {
    try {
      const auth = getAuth();
      const currentUserId = auth.currentUser?.uid;
      
      if (!currentUserId) {
        console.error('Ingen bruger er logget ind');
        return;
      }
  
      const userRole = await getUserRole(currentUserId);
      
      // For brugerrolle 3 (almindelig bruger), brug altid deres eget ID
      const targetUserId = userRole === 3 ? currentUserId : window.location.pathname.split('/').pop();
  
      if (!targetUserId) {
        console.error('Kunne ikke bestemme målbruger ID');
        return;
      }
  
      // Hent autoposts for den relevante bruger
      const autoPostsRef = collection(db, 'users', targetUserId, 'autoPosts');
      const querySnapshot = await getDocs(autoPostsRef);
      
      autoPosts.value = querySnapshot.docs.map(doc => ({
        id: doc.id,
        userId: targetUserId,
        ...doc.data()
      }));
  
    } catch (error) {
      console.error('Fejl ved hentning af autoposts:', error);
      autoPosts.value = [];
    }
  };
  
  // Toggle aktiv status
  const togglePostActive = async (post) => {
    try {
      const auth = getAuth();
      const currentUserId = auth.currentUser?.uid;
      
      if (!currentUserId) return;
  
      const userRole = await getUserRole(currentUserId);
      const targetUserId = post.userId || currentUserId;
  
      if (userRole === 1 || userRole === 2 || currentUserId === targetUserId) {
        const postRef = doc(db, 'users', targetUserId, 'autoPosts', post.id);
        await updateDoc(postRef, {
          active: !post.active
        });
        
        post.active = !post.active;
      } else {
        throw new Error('Ingen tilladelse til at ændre denne autopost');
      }
    } catch (error) {
      console.error('Fejl ved opdatering af status:', error);
    }
  };
  
  // Slet autopost
  const deletePost = async (post) => {
    if (!confirm('Er du sikker på, at du vil slette denne autopost?')) return;
    
    try {
      const auth = getAuth();
      const currentUserId = auth.currentUser?.uid;
      
      if (!currentUserId) return;
  
      const userRole = await getUserRole(currentUserId);
      const targetUserId = post.userId || currentUserId;
  
      if (userRole === 1 || userRole === 2 || currentUserId === targetUserId) {
        await deleteDoc(doc(db, 'users', targetUserId, 'autoPosts', post.id));
        autoPosts.value = autoPosts.value.filter(p => p.id !== post.id);
      } else {
        throw new Error('Ingen tilladelse til at slette denne autopost');
      }
    } catch (error) {
      console.error('Fejl ved sletning af autopost:', error);
    }
  };
  
  // Håndter gem af post
  const handlePostSaved = () => {
    fetchAutoPosts();
    activeTab.value = 'list';
    currentStep.value = 1; // Reset step efter gemning
  };
  
  // Helper functions
  const getChannelIcon = (channel) => {
    const icons = {
      facebook: 'fa-facebook',
      instagram: 'fa-instagram'
    };
    return icons[channel.toLowerCase()] || '';
  };
  
  const getPostType = (post) => {
    // Implementer logik for at bestemme post type
    return 'Billede'; // Eller 'Video' baseret på post data
  };
  
  // Inline formatDate funktion
  const formatDate = (date) => {
    if (!date) return '';
    const dateObj = date.toDate ? date.toDate() : new Date(date);
    return dateObj.toLocaleDateString('da-DK', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };
  
  // Tilføj getUserRole funktion
  const getUserRole = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, "users", userId));
      return userDoc.exists() ? userDoc.data().role : null;
    } catch (error) {
      console.error('Fejl ved hentning af brugerrolle:', error);
      return null;
    }
  };
  
  // Opdater watchUserMetaSettings funktionen til at returnere unsubscribe funktionen direkte
  const watchUserMetaSettings = async (userId) => {
    if (!userId) return () => {}; // Return dummy function hvis ingen userId
    
    const userDocRef = doc(db, 'users', userId);
    
    return onSnapshot(userDocRef, async (userDoc) => {
      const userData = userDoc.data();
      const facebookPageId = userData?.MetaSettings?.facebookPageId;
      
      if (facebookPageId) {
        const autoPostsRef = collection(db, 'users', userId, 'autoPosts');
        const inactivePostsQuery = query(autoPostsRef, where('active', '==', false));
        const inactivePosts = await getDocs(inactivePostsQuery);
        
        inactivePosts.forEach(async (postDoc) => {
          const postData = postDoc.data();
          
          if (!postData.settings?.facebookPageId) {
            await updateDoc(doc(autoPostsRef, postDoc.id), {
              active: true,
              'settings.facebookPageId': facebookPageId,
              lastUpdated: new Date()
            });
            
            console.log(`AutoPost ${postDoc.id} er nu aktiveret med Facebook Page ID`);
          }
        });
      }
    });
  };
  
  // Opdater onMounted og tilføj cleanup funktion
  onMounted(async () => {
    fetchAutoPosts();
    
    const auth = getAuth();
    const currentUserId = auth.currentUser?.uid;
    
    if (currentUserId) {
      // Gem unsubscribe funktionen i ref'en
      unsubscribeMetaWatch.value = await watchUserMetaSettings(currentUserId);
    }
  });
  
  // Opdater onUnmounted til at bruge ref værdien
  onUnmounted(() => {
    if (unsubscribeMetaWatch.value) {
      unsubscribeMetaWatch.value();
      unsubscribeMetaWatch.value = null;
    }
  });
  </script>
  
  <style scoped>
  .nav-wrapper[data-v-8737942c] {
    background: transparent;
  }
  
  .nav-pills {
    background: #212121 !important;
    border-radius: 0.5rem;
    padding: 3px !important;
    position: relative;
    overflow: hidden;
    max-height: 40px;
  }
  
  .nav-item {
    z-index: 1;
    position: relative;
  }
  
  .nav-link {
    cursor: pointer;
    color: #ffffff;
    font-weight: 500;
    transition: all 0.2s ease;
    padding: 5px 15px !important;
    font-size: 0.875rem;
    border-radius: 0.5rem;
    background: transparent;
    white-space: nowrap;
  }
  
  .nav-link.active {
    background: linear-gradient(310deg, #141727 0%, #3A416F 100%) !important;
    color: #ffffff !important;
    font-weight: 600;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  
  .table {
    color: #ffffff;
  }

  .table th {
    font-size: 0.75rem;
    font-weight: 700;
    padding: 12px 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .table td {
    padding: 12px 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    vertical-align: middle;
  }

  .text-sm {
    font-size: 0.875rem;
  }
  
  .btn-sm {
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    min-width: 80px;
  }
  
  .bg-gradient-success {
    background: linear-gradient(310deg, #17ad37 0%, #98ec2d 100%) !important;
    color: white !important;
    border: none;
  }
  
  .bg-gradient-warning {
    background: linear-gradient(310deg, #f53939 0%, #fbcf33 100%) !important;
    color: white !important;
    border: none;
  }

  .btn-danger {
    background: #dc3545;
    color: white !important;
    border: none;
  }
  
  .btn {
    text-transform: none;
    letter-spacing: -0.025rem;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    font-weight: 600;
  }
  
  .btn:hover {
    transform: translateY(-1px);
    transition: all 0.2s ease;
  }

  .fab {
    font-size: 1.25rem;
    color: #ffffff;
  }
  
  @media (max-width: 768px) {
    .nav-link {
      padding: 5px 10px !important;
      font-size: 0.75rem;
    }
  }
  </style>