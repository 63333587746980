<script setup>
import { createApp } from "vue";
import { ref, onMounted, onBeforeUnmount, computed, onUnmounted, watch, nextTick } from "vue";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import Swal from "sweetalert2";
import EventDetailsModal from "./components/EventDetailsModal.vue";
import PlaceholderEventModal from './components/PlaceholderEventModal.vue';
import IntervalControl from './components/IntervalControl.vue';
import { fetchPlaceholderEvents, setupPlaceholderListener, generatePlaceholdersManually } from '@/services/placeholderService';
import daLocale from '@fullcalendar/core/locales/da';
import moment from 'moment';
import 'moment/dist/locale/da';
import 'moment-timezone';

import {
  getFirestore,
  collection,
  query,
  getDocs,
  doc,
  updateDoc,
  onSnapshot,
  where,
  getDoc,
  enableIndexedDbPersistence,
} from "firebase/firestore";

// FontAwesome imports
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faBolt, faImage, faVideo, faCheckCircle, faImages } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";

// Tilføj FontAwesome ikoner til biblioteket
library.add(
  faFacebookF,
  faInstagram,
  faBolt,
  faImage,
  faVideo,
  faCheckCircle,
  faImages
);

// Registrer FontAwesome komponenten
const app = createApp({});
app.component('font-awesome-icon', FontAwesomeIcon);

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useUserContext } from '@/composables/useUserContext'
import { useStore } from 'vuex'
import useCurrentProfile from '@/composables/useCurrentProfile'
import { useRoute } from 'vue-router'
import GetLinks from '@/components/getLinks.vue'
import axios from 'axios';
import SocialMediaAnalytics from '@/components/SocialMediaAnalytics.vue';
import { calendarService } from '@/services/calendarService';

// Farver til ikoner
const iconColors = ref({
  facebook: "#007fff",
  instagram: "#ff0047",
  lightning: "#f39c12",
  image: "#ffffff",
  video: "#ffffff",
});

const calendarEl = ref(null);
let calendar;

const auth = getAuth();
const currentUser = ref(null);

onAuthStateChanged(auth, (user) => {
  currentUser.value = user;
});

// Opdater isSuperAdmin funktionen til at inkludere role 2
const isSuperAdmin = async (user) => {
  if (!user) return false;
  
  const db = getFirestore();
  try {
    const userDoc = await getDoc(doc(db, "users", user.uid));
    const userData = userDoc.data();
    return userData && (userData.role === 1 || userData.role === 2); // Tilføjet role 2
  } catch (error) {
    console.error("Fejl ved tjek af superAdmin status:", error);
    return false;
  }
};

// Funktion til at hente posts
const fetchPosts = async () => {
  if (!currentUser.value) return [];

  const targetUserId = route.params.id || store.state.activeUser?.id;
  
  if (!targetUserId) {
    console.log('Ingen target user ID fundet');
    return [];
  }
  
  return await fetchPlaceholderEvents(targetUserId);
};

// Funktion til at hente postSend
const fetchPostSend = async () => {
  if (!currentUser.value) return [];

  const db = getFirestore();
  let postSendQuery;
  
  // Hent brugerens rolle
  const userDoc = await getDoc(doc(db, "users", currentUser.value.uid));
  const userRole = userDoc.data()?.role;
  
  // Hvis bruger er rolle 3, hent kun deres egne posts
  if (userRole === 3) {
    postSendQuery = query(collection(db, "postSend"), 
      where("userId", "==", currentUser.value.uid));
  } else {
    // For admin og superadmin (rolle 1 og 2)
    postSendQuery = query(collection(db, "postSend"), 
      where("id", "==", route.params.id || store.state.activeUser?.id));
  }

  const querySnapshot = await getDocs(postSendQuery);
  const events = [];

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    
    // For rolle 3, tjek kun deres egne posts
    if (userRole === 3 ? data.userId === currentUser.value.uid : 
        (isSuperAdmin(currentUser.value) || data.id === currentUser.value?.uid)) {
      const platform = data.postFB
        ? "Facebook"
        : data.postInst
          ? "Instagram"
          : null;
      const icon =
        platform === "Facebook"
          ? "facebook"
          : platform === "Instagram"
            ? "instagram"
            : null;

      let eventClass = null;

      if (platform === "Facebook") {
        eventClass = "bg-gradient-facebook";
      } else if (platform === "Instagram") {
        eventClass = "bg-gradient-instagram";
      }

      const childrenAddresses = data.children
        ? data.children.map((child) => child.adressen)
        : [];
      const mediaFiles = data.children?.[0]?.images || [];

      // Håndter publishTime korrekt
      let time = "00:00";

      if (data.publishTime) {
        if (
          typeof data.publishTime === "object" &&
          data.publishTime.HH &&
          data.publishTime.MM
        ) {
          const hours = data.publishTime.HH.toString().padStart(2, "0");
          const minutes = data.publishTime.MM.toString().padStart(2, "0");
          time = `${hours}:${minutes}`;
        } else if (typeof data.publishTime === "string") {
          // Hvis publishTime er en streng, antag formatet "HH:mm"
          time = data.publishTime;
        } else if (data.publishTime instanceof Date) {
          const hours = data.publishTime.getHours().toString().padStart(2, "0");
          const minutes = data.publishTime
            .getMinutes()
            .toString()
            .padStart(2, "0");
          time = `${hours}:${minutes}`;
        } else if (typeof data.publishTime === "number") {
          // Hvis publishTime er et timestamp
          const date = new Date(data.publishTime);
          const hours = date.getHours().toString().padStart(2, "0");
          const minutes = date.getMinutes().toString().padStart(2, "0");
          time = `${hours}:${minutes}`;
        } else {
          console.warn("Uventet format for publishTime:", data.publishTime);
          // Behold default start og time
        }
      }

      events.push({
        id: doc.id,
        title: data.subject || "No Title",
        start: data.publishDate,
        extendedProps: {
          platform,
          time, // Sæt tid korrekt
          icon,
          addresses: childrenAddresses,
          mediaFiles,
          children: data.children || [],
          status: "Planlagt",
          text: data.text || "No description available",
          collection: "posts",
          data: data
        },
        className: eventClass,
      });
    }
  });

  return events;
};

// Funktion til at generere dynamiske ikoner
const getDynamicIcons = (children, status, data) => {
  // Definer farver for status
  const statusColors = {
    Opslået: '#5dc87a',  // Grøn
    Planlagt: '#f3d912'  // Gul
  };

  // Tjek om det er et automatisk opslag baseret på collectionName
  let iconsHtml = status === "Opslået" 
    ? `<i class="fa-solid fa-check-circle" style="font-size: 14px; color: ${statusColors.Opslået} !important; margin-right: 5px;"></i>`
    : data?.collectionName 
      ? `<i class="fa-solid fa-bolt" style="font-size: 14px; color: ${iconColors.value.lightning} !important; margin-right: 5px;"></i>`
      : '';

  let hasImage = false;
  let hasVideo = false;

  if (children && children.length > 0) {
    children.forEach((child) => {
      if (child.images && child.images.length > 0) {
        child.images.forEach((file) => {
          if (
            !hasImage &&
            (file.includes(".jpg") ||
              file.includes(".jpeg") ||
              file.includes(".png"))
          ) {
            const imageIcon = data?.type === 'karruselPost' 
              ? `<i class="fa-solid fa-images" style="font-size: 14px; color: ${iconColors.value.image} !important; margin-right: 0px;"></i>`
              : `<i class="fa-solid fa-image" style="font-size: 14px; color: ${iconColors.value.image} !important; margin-right: 0px;"></i>`;
            iconsHtml += ` ${imageIcon}`;
            hasImage = true;
          } else if (
            !hasVideo &&
            (file.includes(".mp4") || file.includes(".mov"))
          ) {
            iconsHtml += ` <i class="fa-solid fa-video" style="font-size: 14px; color: ${iconColors.value.video} !important; margin-right: 0px;"></i>`;
            hasVideo = true;
          }
        });
      }
    });
  }

  return iconsHtml;
};

// Funktion til at opdatere event i Firestore
const updateEventInFirestore = async (eventId, newDate, collectionName) => {
  const db = getFirestore();
  const eventRef = doc(db, collectionName, eventId);

  // Konstruer datoen baseret på lokale komponenter
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, "0");
  const day = String(newDate.getDate()).padStart(2, "0");
  const updatedPublishDate = `${year}-${month}-${day}`;

  try {
    await updateDoc(eventRef, { publishDate: updatedPublishDate });
    console.log(
      `Event ${eventId} updated in ${collectionName} with date ${updatedPublishDate}`,
    );
  } catch (error) {
    console.error(
      `Error updating event ${eventId} in ${collectionName}: `,
      error,
    );
    Swal.fire({
      icon: "error",
      title: "Fejl",
      text: "Der opstod en fejl under opdatering af eventet.",
    });
  }
};

// Funktion til at bestemme post type baseret på mediefiler
const getPostTypeFromMediaFiles = (children) => {
  let postType = "";

  if (children && children.length > 0) {
    children.forEach((child) => {
      if (child.images && child.images.length > 0) {
        child.images.forEach((file) => {
          if (
            file.includes(".jpg") ||
            file.includes(".jpeg") ||
            file.includes(".png")
          ) {
            postType = "Billedeopslag";
          } else if (file.includes(".mp4") || file.includes(".mov")) {
            postType = "Videoopslag";
          }
        });
      }
    });
  }

  return postType || "Ingen medieopslag";
};

// Tilføj denne ref efter andre refs
const scrollPosition = ref(0);

// Opdater showEventDetails funktionen
const showEventDetails = (event) => {
  const isPlaceholderGroup = event.extendedProps?.isPlaceholder;
  
  // Find den korrekte scroll container (main content container)
  const mainContent = document.querySelector('.main-content');
  scrollPosition.value = mainContent ? mainContent.scrollTop : 0;
  
  if (isPlaceholderGroup) {
    Swal.fire({
      title: `Lagerbiler til rotation ${moment(event.start).format('D. MMMM YYYY')}`,
      html: '<div id="placeholder-details-container"></div>',
      customClass: {
        container: 'placeholder-modal-container',
        popup: 'placeholder-modal',
        content: 'placeholder-content'
      },
      showCloseButton: true,
      showConfirmButton: false,
      width: '600px',
      didOpen: () => {
        const container = document.getElementById('placeholder-details-container');
        const modalApp = createApp(PlaceholderEventModal, {
          event
        });
        modalApp.mount(container);
      },
      willClose: () => {
        // Bevar scroll position ved at låse højden
        const mainContent = document.querySelector('.main-content');
        if (mainContent) {
          mainContent.style.height = `${mainContent.scrollHeight}px`;
          mainContent.style.overflow = 'hidden';
        }
      },
      didClose: () => {
        // Gendan scroll position og fjern låsning
        setTimeout(() => {
          const mainContent = document.querySelector('.main-content');
          if (mainContent) {
            mainContent.style.height = '';
            mainContent.style.overflow = '';
            mainContent.scrollTop = scrollPosition.value;
          }
        }, 0);
      }
    });
    return;
  }
  
  // Eksisterende eventDetails logik for ikke-placeholder events
  const children = event.extendedProps.children || [];
  const mediaFiles = event.extendedProps.mediaFiles || [];
  const addresses = children.map((child) => child.adressen).filter(Boolean);
  const description = event.extendedProps.text || "Ingen beskrivelse tilgængelig";
  const eventId = event.id;

  Swal.fire({
    title: event.title || "Event detaljer",
    html: '<div id="event-details-container"></div>',
    showCloseButton: true,
    width: "50%",
    padding: "1rem",
    showConfirmButton: false,
    backdrop: true,
    customClass: {
      popup: "custom-popup-class",
    },
    didOpen: () => {
      const container = document.getElementById("event-details-container");
      const modalApp = createApp(EventDetailsModal, {
        mediaFiles,
        addresses,
        description,
        eventId,
        collection: event.extendedProps.collection,
        eventData: event.extendedProps.data
      });
      modalApp.mount(container);
    },
    willClose: () => {
      // Bevar scroll position ved at låse højden
      const mainContent = document.querySelector('.main-content');
      if (mainContent) {
        mainContent.style.height = `${mainContent.scrollHeight}px`;
        mainContent.style.overflow = 'hidden';
      }
    },
    didClose: () => {
      // Gendan scroll position og fjern låsning
      setTimeout(() => {
        const mainContent = document.querySelector('.main-content');
        if (mainContent) {
          mainContent.style.height = '';
          mainContent.style.overflow = '';
          mainContent.scrollTop = scrollPosition.value;
        }
      }, 0);
    }
  });
};

// Funktion til at håndtere "Vis mere" klik
const handleShowMore = (event) => {
  event.preventDefault();
  event.stopPropagation(); // Stopper eventet fra at propagere til eventClick

  const showMoreLink = event.currentTarget;
  const childrenContainer = showMoreLink.closest(".children-container");
  const hiddenChildren = childrenContainer.querySelectorAll(".hidden-child");
  const showLessLink = childrenContainer.querySelector(".show-less-link");

  hiddenChildren.forEach((child) => {
    child.style.display = "block";
  });

  showMoreLink.style.display = "none";
  if (showLessLink) {
    showLessLink.style.display = "block";
  }
};

// Funktion til at håndtere "Vis mindre" klik
const handleShowLess = (event) => {
  event.preventDefault();
  event.stopPropagation(); // Stopper eventet fra at propagere til eventClick

  const showLessLink = event.currentTarget;
  const childrenContainer = showLessLink.closest(".children-container");
  const hiddenChildren = childrenContainer.querySelectorAll(".hidden-child");
  const showMoreLink = childrenContainer.querySelector(".show-more-link");

  hiddenChildren.forEach((child) => {
    child.style.display = "none";
  });

  showLessLink.style.display = "none";
  if (showMoreLink) {
    showMoreLink.style.display = "block";
  }
};

// Definér analytics ref
const analytics = ref({
  totalPosts: 0,
  totalImages: 0,
  totalVideos: 0,
  totalReach: 0,
  totalClicks: 0,
  totalAnimations: 0,
  facebookPosts: 0,
  instagramPosts: 0,
  scheduledPosts: 0,
  publishedPosts: 0
});

// Funktion til at tlle posts og media
const countPostsAndMedia = async () => {
  if (!currentUser.value) return;
  
  const db = getFirestore();
  
  try {
    const userDoc = await getDoc(doc(db, "users", currentUser.value.uid));
    const userRole = userDoc.data()?.role;
    
    let postsQuery;
    let postSendQuery;
    
    if (userRole === 3) {
      // Brug 'id' i stedet for 'userId' for at matche datastrukturen
      postsQuery = query(
        collection(db, "posts"),
        where("id", "==", currentUser.value.uid)  // Ændret fra userId til id
      );
      postSendQuery = query(
        collection(db, "postSend"),
        where("id", "==", currentUser.value.uid)  // Ændret fra userId til id
      );
    } else {
      const userId = route.params.id || store.state.activeUser?.id;
      postsQuery = query(
        collection(db, "posts"),
        where("id", "==", userId)
      );
      postSendQuery = query(
        collection(db, "postSend"),
        where("id", "==", userId)
      );
    }

    const [postsSnapshot, postSendSnapshot] = await Promise.all([
      getDocs(postsQuery),
      getDocs(postSendQuery)
    ]);

    let totalPosts = 0;
    let totalImages = 0;
    let totalAnimations = 0;

    const videoExtensions = [".mp4", ".mov", ".avi", ".mkv"];

    // Tæl fra posts
    postsSnapshot.forEach(doc => {
      const postData = doc.data();
      totalPosts++;
      
      // Kun tæl fra children.images
      if (postData.children && Array.isArray(postData.children)) {
        postData.children.forEach(child => {
          if (child.images && Array.isArray(child.images)) {
            child.images.forEach(url => {
              const urlLower = url.toLowerCase();
              if (videoExtensions.some(ext => urlLower.endsWith(ext))) {
                totalAnimations++;
              } else {
                totalImages++;
              }
            });
          }
        });
      }
    });

    // Tæl fra postSend
    postSendSnapshot.forEach(doc => {
      const postData = doc.data();
      totalPosts++;
      
      // Kun tæl fra children.images
      if (postData.children && Array.isArray(postData.children)) {
        postData.children.forEach(child => {
          if (child.images && Array.isArray(child.images)) {
            child.images.forEach(url => {
              const urlLower = url.toLowerCase();
              if (videoExtensions.some(ext => urlLower.endsWith(ext))) {
                totalAnimations++;
              } else {
                totalImages++;
              }
            });
          }
        });
      }
    });

    // Opdater analytics
    analytics.value = {
      ...analytics.value,
      totalPosts,
      totalImages,
      totalAnimations,
      totalReach: analytics.value.totalReach,
      totalClicks: analytics.value.totalClicks
    };
    
  } catch (error) {
    console.error("Fejl i countPostsAndMedia:", error);
    console.error("Fejlkode:", error.code);
    console.error("Fejlbesked:", error.message);
  }
};

// Opdater setupAnalyticsListener funktionen
const setupAnalyticsListener = async () => {
  if (!currentUser.value) return;
  
  const db = getFirestore();
  
  try {
    // Hent brugerens rolle først
    const userDoc = await getDoc(doc(db, "users", currentUser.value.uid));
    const userRole = userDoc.data()?.role;
    
    let analyticsRef;
    let metaAnalyticsRef;
    
    if (userRole === 3) {
      // For rolle 3 - kun egne analytics
      analyticsRef = doc(db, "users", currentUser.value.uid, "analytics", "stats");
      metaAnalyticsRef = doc(db, "users", currentUser.value.uid, "analytics", "meta");
    } else {
      // For admin og superadmin (rolle 1 og 2)
      const userId = route.params.id || store.state.activeUser?.id;
      analyticsRef = doc(db, "users", userId, "analytics", "stats");
      metaAnalyticsRef = doc(db, "users", userId, "analytics", "meta");
    }

    // Lyt til ændringer i analytics dokumentet
    const unsubscribe = onSnapshot(analyticsRef, {
      next: (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          analytics.value = {
            ...analytics.value,
            totalClicks: data.totalClicks || 0,
            totalPosts: analytics.value.totalPosts,
            totalImages: analytics.value.totalImages,
            totalVideos: analytics.value.totalVideos,
            totalAnimations: analytics.value.totalAnimations,
            facebookPosts: analytics.value.facebookPosts,
            instagramPosts: analytics.value.instagramPosts,
            scheduledPosts: analytics.value.scheduledPosts,
            publishedPosts: analytics.value.publishedPosts
          };
        }
      },
      error: (error) => {
        console.error('Fejl i analytics listener:', error);
      }
    });

    // Tilføj listener for Meta analytics
    const unsubscribeMeta = onSnapshot(metaAnalyticsRef, {
      next: (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          const fbReach = data.facebook?.post_impressions_unique || 0;
          const igReach = data.instagram?.reach || 0;
          
          // Opdater total reach i analytics
          analytics.value = {
            ...analytics.value,
            totalReach: fbReach + igReach
          };
        }
      },
      error: (error) => {
        console.error('Fejl i meta analytics listener:', error);
      }
    });

    // Cleanup ved component unmount
    onUnmounted(() => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
      if (typeof unsubscribeMeta === 'function') {
        unsubscribeMeta();
      }
    });
    
  } catch (error) {
    console.error('Fejl ved setup af analytics listener:', error);
  }
};

// Initialize route, store and user context at the top
const route = useRoute()
const store = useStore()
const { contextUserId, canAccessOtherUsers } = useUserContext()
const { profileUserId } = useCurrentProfile()

// Tilføj ref for at styre visning af interval control
const showIntervalControl = ref(false);

// Tilføj ref for brugerens settings
const userSettings = ref({
  futurePosts: false
});

// Funktion til at hente brugerens settings
const fetchUserSettings = async () => {
  if (!currentUser.value) return;
  
  const db = getFirestore();
  const targetUserId = route.params.id || store.state.activeUser?.id;
  
  try {
    const userDoc = await getDoc(doc(db, "users", targetUserId));
    if (userDoc.exists()) {
      const data = userDoc.data();
      userSettings.value = data.settings || { futurePosts: false };
    }
  } catch (error) {
    console.error('Fejl ved hentning af bruger settings:', error);
  }
};

// Kald fetchUserSettings når brugeren ændres
watch(() => currentUser.value, (newUser) => {
  if (newUser) {
    fetchUserSettings();
  }
}, { immediate: true });

// Tilføj denne funktion i starten af filen, efter imports
const suppressFirestoreLogging = () => {
  window.addEventListener('load', () => {
    // Deaktiver alle Firestore-relaterede logs
    console.debug = () => {};
    console.log = (...args) => {
      // Skip alle Firestore-relaterede logs
      if (typeof args[0] === 'string' && 
          (args[0].includes('Firestore') || 
           args[0].includes('Fetch finished'))) {
        return;
      }
    };
  });
};

// Kald funktionen tidligt i koden
suppressFirestoreLogging();

// Funktion til at initialisere kalenderen
const initializeCalendar = async () => {
  try {
    console.log('Starter initialisering af kalender');
    const userId = route.params.id || store.state.activeUser?.id;
    if (!userId) {
      console.error('Ingen bruger ID fundet');
      return;
    }
    console.log('Bruger ID fundet:', userId);

    // Hent cached kalender data
    console.log('Henter kalender data...');
    const calendarData = await calendarService.getCalendarData(userId);
    console.log('Kalender data hentet:', calendarData);
    let events = [];

    if (calendarData) {
      console.log('Bruger cached data');
      events = [...calendarData.posts, ...calendarData.postSend];
    } else {
      console.log('Ingen cached data, henter frisk data');
      // Fallback til direkte hentning hvis der ikke er cached data
      const posts = await fetchPosts();
      const postSend = await fetchPostSend();
      events = [...posts, ...postSend];
    }
    console.log('Events klar:', events.length);
    
    // Kun hent placeholders hvis futurePosts er aktiveret
    if (userSettings.value.futurePosts) {
      console.log('Henter placeholders...');
      const placeholders = await fetchPlaceholderEvents(userId);
      events = [...events, ...placeholders];
      console.log('Placeholders tilføjet, total events:', events.length);
    }
    
    // Kald countPostsAndMedia før kalenderen initialiseres
    console.log('Tæller posts og media...');
    await countPostsAndMedia();

    if (calendarEl.value) {
      console.log('Opretter kalender instance');
      calendar = new Calendar(calendarEl.value, {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        editable: true,
        eventStartEditable: true,
        eventDurationEditable: false,
        dragRevertDuration: 200,
        eventDragMinDistance: 10,
        dragScroll: true,
        snapDuration: { minutes: 30 },
        height: "auto",
        handleWindowResize: true,
        stickyHeaderDates: false,
        dayMaxEvents: true,
        eventDragStart: (info) => {
          info.el.style.zIndex = '1000';
          info.el.style.opacity = '0.8';
          info.el.style.transform = 'scale(1.02)';
        },
        eventDragStop: (info) => {
          info.el.style.zIndex = '';
          info.el.style.opacity = '';
          info.el.style.transform = '';
        },
        events,
        locale: daLocale,
        firstDay: 1,
        weekNumbers: true,
        weekText: "",
        dayHeaderFormat: { weekday: 'long' },
        eventClick: (info) => {
          showEventDetails(info.event);
        },
        dayCellDidMount: (arg) => {
          arg.el.style.padding = '0';
        },
        eventDidMount: (info) => {
          info.el.style.marginBottom = '3px';
          const showMoreLink = info.el.querySelector(".show-more-link");
          const showLessLink = info.el.querySelector(".show-less-link");
          const hiddenChildren = info.el.querySelectorAll(".child-element[style*='display: none']");

          if (showMoreLink) {
            showMoreLink.addEventListener("click", (e) => {
              e.preventDefault();
              e.stopPropagation();
              hiddenChildren.forEach(child => child.style.display = "block");
              showMoreLink.style.display = "none";
              showLessLink.style.display = "block";
            });
          }

          if (showLessLink) {
            showLessLink.addEventListener("click", (e) => {
              e.preventDefault();
              e.stopPropagation();
              hiddenChildren.forEach(child => child.style.display = "none");
              showMoreLink.style.display = "block";
              showLessLink.style.display = "none";
            });
          }
        },
        eventContent: (info) => {
          const { time, icon, children, status } = info.event.extendedProps;
          const eventData = info.event.extendedProps.data || {};
          
          // Definer farver for status
          const statusColors = {
            'Opslået': '#5dc87a',  // Grøn
            'Planlagt': '#f3d912'  // Gul
          };
          
          // Opdateret ikon klasser og styling
          const iconClass = icon === 'facebook' 
            ? 'fa-brands fa-facebook-f' 
            : 'fa-brands fa-instagram';
          
          const hasVideo = eventData.children?.some(child => 
            child.images?.some(image => 
              image.toLowerCase().endsWith('.mp4') || 
              image.toLowerCase().endsWith('.mov')
            )
          );
          
          let eventTitle = `${info.event.title}${
            eventData.type === 'karruselPost' 
              ? ' | <strong>Karrusel</strong>' 
              : hasVideo 
                ? ' | <strong>Video</strong>' 
                : ''
          }`;
          
          // Funktion til at forkorte tekst
          const truncateText = (text, maxLength) => {
            if (text.length <= maxLength) return text;
            return text.substring(0, maxLength) + '...';
          };

          // Generer HTML for alle headlines som en ul/li struktur
          const headlinesHTML = children?.length ? `
            <ul class="children-container" style="padding: 0; margin-bottom: 0; font-size: 12px;">
              ${children.map((child, index) => `
                <li class="child-element" style="list-style-type: none; margin-left: 0; text-align: left; ${index > 0 || (eventData.isPlaceholder && index === 0) ? 'display: none;' : ''}">
                  <div>${truncateText(child.headline || 'Ingen overskrift', 30)}</div>
                </li>
              `).join('')}
              ${children.length > 3 ? `
                <a class="show-more-link" href="#" style="display: block; font-size: 11px; color: #c5c5c5;">Vis ${eventData.isPlaceholder ? 'biler' : 'mere'} (${children.length})</a>
                <a class="show-less-link" href="#" style="display: none; font-size: 11px; color: #c5c5c5;">Vis mindre</a>
              ` : ''}
            </ul>
          ` : '';

          const dynamicIcons = getDynamicIcons(children, status, eventData);
          
          // Brug statusColors for konsistent farvning
          const statusColor = statusColors[status] || statusColors.Planlagt;

          return {
            html: `
                  <div style="position: relative; padding: 5px;">
                    <!-- Titel og ikon -->
                    <div style="display: flex; align-items: center; justify-content: space-between; font-size: 11px;">
                      <div class="fc-title" style="margin-right: 20px;">${eventTitle}</div>
                      ${!eventData.isPlaceholder 
                        ? `<i class="${iconClass}" style="font-size: 14px; color: #ffffff;"></i>` 
                        : ''}
                    </div>

                    <!-- Dynamiske ikoner nederst til højre -->
                    <div style="position: absolute; bottom: 4px; right: 8px; font-size: 12px;">
                      ${dynamicIcons}
                    </div>

                    <!-- Headlines HTML -->
                    ${headlinesHTML}

                    <!-- Status og tidspunkt -->
                    <div class="fc-post-text-row" style="font-size: 10px; margin-top: 5px; display: flex; align-items: center;">
                      <span class="fc-post-text" style="color: ${statusColor} !important; font-weight: bold;">${status}</span>
                      ${!eventData.isPlaceholder 
                        ? `<span class="fc-datetime-text" style="margin-left: 5px; color: #c5c5c5;">kl. ${time || "00:00"}</span>` 
                        : ''}
                    </div>
                  </div>
              `
          };
        },
        eventDrop: (info) => {
          const eventId = info.event.id;
          const newDate = info.event.start;
          const collectionName = info.event.extendedProps.collection;

          updateEventInFirestore(eventId, newDate, collectionName);
        },
      });

      console.log('Renderer kalender');
      calendar.render();
      
      console.log('Sætter op real-time listeners');
      await setupRealTimeListeners();
    } else {
      console.error('Calendar element ikke fundet');
    }

    console.log('Opdaterer analytics');
    updateAnalytics().catch(error => {
      console.error('Fejl ved opdatering af analytics:', error);
    });

  } catch (error) {
    console.error("Error initializing calendar: ", error);
    Swal.fire({
      icon: "error",
      title: "Fejl",
      text: "Der opstod en fejl under indlæsning af kalenderen.",
    });
  }
};

onMounted(() => {
  onAuthStateChanged(auth, (user) => {
    currentUser.value = user;
    if (user) {
      initializeCalendar();
      setupAnalyticsListener();
      countPostsAndMedia();
      fetchUserSettings(); // Tilføj dette kald
    }
  });
});

// Ødelæg kalenderen ved unmount
onBeforeUnmount(() => {
  console.log('Component unmounting, cleaning up...');
  const userId = route.params.id || store.state.activeUser?.id;
  if (calendar) {
    calendar.destroy();
    calendar = null;
  }
  if (userId) {
    calendarService.cleanup(userId);
  }
});

document.addEventListener('click', function(event) {
  if (event.target.matches('.nav-pill')) {
    // Håndter klik på nav-pill her
  }
});

// Funktion til at vise udvidet analytics
const showAnalytics = () => {
  const fbData = {
    postImpressionsUnique: analytics.value.facebookPosts,
    reach: analytics.value.totalReach // eller en anden relevant værdi
  };
  const igData = {
    postImpressionsUnique: analytics.value.instagramPosts,
    reach: analytics.value.totalReach // eller en anden relevant værdi
  };
  analyticsModalRef.value?.openModal(fbData, igData);
};

// Opdater setupRealTimeListeners funktionen
const setupRealTimeListeners = async () => {
  if (!currentUser.value) return;
  
  const userId = route.params.id || store.state.activeUser?.id;
  if (!userId) {
    console.error('Ingen bruger ID fundet');
    return;
  }

  try {
    const db = getFirestore();
    const userDoc = await getDoc(doc(db, "users", currentUser.value.uid));
    if (!userDoc.exists()) {
      console.error('Bruger dokument findes ikke');
      return;
    }

    // Brug calendarService til at sætte listeners op
    const cleanup = calendarService.setupRealTimeListeners(userId, (changeData) => {
      if (!calendar) return;

      const { type, id: eventId, data: docData } = changeData;

      if (type === "added" || type === "modified") {
        const eventData = {
          id: eventId,
          title: docData.subject || "No Title",
          start: docData.publishDate,
          extendedProps: {
            platform: docData.postFB ? "Facebook" : docData.postInst ? "Instagram" : null,
            time: formatPublishTime(docData.publishTime),
            icon: docData.postFB ? "facebook" : docData.postInst ? "instagram" : null,
            addresses: docData.children ? docData.children.map(child => child.adressen) : [],
            mediaFiles: docData.children?.[0]?.images || [],
            children: docData.children || [],
            status: changeData.collection === "postSend" ? "Opslået" : "Planlagt",
            text: docData.text || "No description available",
            collection: changeData.collection,
            data: docData
          },
          className: docData.postFB ? "bg-gradient-facebook" : docData.postInst ? "bg-gradient-instagram" : null,
        };

        const existingEvent = calendar.getEventById(eventId);
        if (existingEvent) {
          existingEvent.remove();
        }
        calendar.addEvent(eventData);
      } else if (type === "removed") {
        const existingEvent = calendar.getEventById(eventId);
        if (existingEvent) {
          existingEvent.remove();
        }
      }

      // Opdater analytics efter ændringer
      countPostsAndMedia();
    });

    // Kun tilføj placeholder listener hvis futurePosts er aktiveret
    let unsubscribePlaceholders;
    if (userSettings.value.futurePosts) {
      unsubscribePlaceholders = setupPlaceholderListener(userId, calendar);
    }

    // Cleanup ved unmount eller logout
    onUnmounted(() => {
      cleanup();
      if (unsubscribePlaceholders) {
        unsubscribePlaceholders();
      }
      calendarService.cleanup(userId);
    });

  } catch (error) {
    console.error('Fejl i setupRealTimeListeners:', error);
  }
};

const formatPublishTime = (publishTime) => {
  if (!publishTime) return "00:00";
  
  if (typeof publishTime === "object" && publishTime.HH && publishTime.MM) {
    return `${publishTime.HH.toString().padStart(2, "0")}:${publishTime.MM.toString().padStart(2, "0")}`;
  } else if (typeof publishTime === "string") {
    return publishTime;
  } else if (publishTime instanceof Date) {
    return `${publishTime.getHours().toString().padStart(2, "0")}:${publishTime.getMinutes().toString().padStart(2, "0")}`;
  } else if (typeof publishTime === "number") {
    const date = new Date(publishTime);
    return `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
  }
  
  return "00:00";
};

// Watch for route changes to update analytics
watch(
  () => route.params.id,
  () => {
    countPostsAndMedia();
  }
);

// Eksportér analytics hvis du skal bruge det i template
defineExpose({
  analytics
});

// Tilføj computed properties efter analytics ref
const totalPosts = computed(() => analytics.value.totalPosts)
const imageCount = computed(() => analytics.value.totalImages)
const animationCount = computed(() => analytics.value.totalAnimations)
const totalClicks = computed(() => analytics.value.totalClicks)
const totalReach = computed(() => analytics.value.totalReach)

// Tilføj denne watch
watch(() => currentUser.value, (newUser, oldUser) => {
  if (!newUser && oldUser) {
    // Bruger er logget ud
    if (calendar) {
      calendar.destroy();
    }
    // Fjern alle listeners
    if (typeof cleanup === 'function') {
      cleanup();
    }
  }
}, { immediate: true });

// Tilføj ref til GetLinks komponenten
const linksModalRef = ref(null)

// Opdater showLinksModal metoden
const showLinksModal = () => {
  linksModalRef.value?.openModal()
}

// Opdater updateAnalytics funktionen
const updateAnalytics = async () => {
  try {
    const userId = route.params.id || store.state.activeUser?.id;
    if (!userId) return;

    // Hent brugerens dubTagId
    const db = getFirestore();
    const userDoc = await getDoc(doc(db, "users", userId));
    const dubTagId = userDoc.data()?.dubTagId;

    if (!dubTagId) {
      console.log('Ingen dubTagId fundet for brugeren');
      return;
    }

    // Lav direkte HTTP kald til cloud function
    const response = await axios.get(`https://us-central1-toft-d4f39.cloudfunctions.net/getDubAnalytics`, {
      params: { userId }
    });

    console.log('Analytics opdateret:', response.data);
    return response.data;
  } catch (error) {
    console.error('Fejl ved opdatering af analytics:', error);
  }
};

// Tilføj ref til SocialMediaAnalytics komponenten
const analyticsModalRef = ref(null);

// Tilføj isLoading som en ref
const isLoading = ref(false);

// Tilføj ny handler for interval opdateringer
const handleIntervalUpdate = async (newInterval) => {
  if (!calendar) return;
  
  // Fjern eksisterende placeholder events
  const existingEvents = calendar.getEvents();
  existingEvents
    .filter(event => event.extendedProps?.isPlaceholder)
    .forEach(event => event.remove());
    
  // Hent nye placeholders med det simulerede interval
  const userId = route.params.id || store.state.activeUser?.id;
  if (!userId) return;
  
  try {
    const placeholders = await fetchPlaceholderEvents(userId, newInterval);
    placeholders.forEach(event => calendar.addEvent(event));
  } catch (error) {
    console.error('Fejl ved opdatering af placeholders:', error);
  }
};

// Tilføj denne funktion i script-delen
const generatePlaceholders = async () => {
  try {
    const userId = route.params.id || store.state.activeUser?.id;
    if (!userId) {
      Swal.fire({
        icon: 'error',
        title: 'Fejl',
        text: 'Kunne ikke finde bruger ID'
      });
      return;
    }

    // Vis loading indikator
    isLoading.value = true;

    await generatePlaceholdersManually(userId);
    
    // Opdater kalenderen
    await initializeCalendar();

    // Vis success besked
    Swal.fire({
      icon: 'success',
      title: 'Succes!',
      text: 'Placeholders er blevet genereret.',
      timer: 2000,
      showConfirmButton: false
    });
  } catch (error) {
    console.error('Fejl ved generering af placeholders:', error);
    Swal.fire({
      icon: 'error',
      title: 'Fejl',
      text: 'Der opstod en fejl ved generering af placeholders.'
    });
  } finally {
    isLoading.value = false;
  }
};

// Opdater watch til at håndtere route ændringer korrekt
watch(
  () => route.name,
  (newRouteName, oldRouteName) => {
    console.log('Route ændret fra:', oldRouteName, 'til:', newRouteName);
    
    // Når vi forlader calendar
    if (oldRouteName === 'calendar') {
      console.log('Forlader calendar, rydder op...');
      if (calendar) {
        calendar.destroy();
        calendar = null;
      }
      // Ryd op i listeners og cache
      const userId = route.params.id || store.state.activeUser?.id;
      if (userId) {
        calendarService.cleanup(userId);
      }
    }
    
    // Når vi kommer tilbage til calendar
    if (newRouteName === 'calendar' && currentUser.value) {
      console.log('Geninitialiserer kalender på route ændring');
      // Vent på næste tick og initialiser
      nextTick(() => {
        initializeCalendar();
      });
    }
  },
  { immediate: true } // Tilføj immediate: true for at køre ved mount
);
</script>
<template>
  <div>
    <div class="row mb-4">
      <div class="col-xl-2 col-sm-6 mb-xl-0 mb-4">
        <div class="card mb-3">
          <div class="card-body p-3">
            <div class="d-flex flex-row-reverse justify-content-between">
              <div class="text-center icon icon-shape bg-gradient-dark shadow">
                <i class="ni ni-calendar-grid-58 text-lg opacity-10" aria-hidden="true"></i>
              </div>
              <div class="">
                <div class="numbers">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold">Opslag</p>
                  <h5 class="mb-0 font-weight-bolder">{{ totalPosts }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-sm-6 mb-xl-0 mb-4">
        <div class="card mb-3">
          <div class="card-body p-3">
            <div class="d-flex flex-row-reverse justify-content-between">
              <div class="text-center icon icon-shape bg-gradient-dark shadow">
                <i class="ni ni-image text-lg opacity-10" aria-hidden="true"></i>
              </div>
              <div class="">
                <div class="numbers">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold">Billeder</p>
                  <h5 class="mb-0 font-weight-bolder">{{ imageCount }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-sm-6 mb-xl-0 mb-4">
        <div class="card mb-3">
          <div class="card-body p-3">
            <div class="d-flex flex-row-reverse justify-content-between">
              <div class="text-center icon icon-shape bg-gradient-dark shadow">
                <i class="ni ni-camera-compact text-lg opacity-10" aria-hidden="true"></i>
              </div>
              <div class="">
                <div class="numbers">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold">Videoer</p>
                  <h5 class="mb-0 font-weight-bolder">{{ animationCount }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-sm-6 mb-xl-0 mb-4">
        <div class="card mb-3 clickable-card" @click="showLinksModal">
          <div class="card-body p-3">
            <div class="d-flex flex-row-reverse justify-content-between">
              <div class="text-center icon icon-shape bg-gradient-dark shadow">
                <i class="ni ni-curved-next text-lg opacity-10" aria-hidden="true"></i>
              </div>
              <div class="">
                <div class="numbers">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold">Kliks</p>
                  <h5 class="mb-0 font-weight-bolder">{{ totalClicks }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-sm-6 mb-xl-0 mb-4">
        <div class="card mb-3">
          <div class="card-body p-3">
            <div class="d-flex flex-row-reverse justify-content-between">
              <div class="text-center icon icon-shape bg-gradient-dark shadow">
                <i class="ni ni-world text-lg opacity-10" aria-hidden="true"></i>
              </div>
              <div class="">
                <div class="numbers">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold">Rækkevidde</p>
                  <h5 class="mb-0 font-weight-bolder">{{ totalReach.toLocaleString('da-DK') }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-sm-6 mb-xl-0 mb-4">
        <div class="card mb-3 clickable-card" @click="showAnalytics">
          <div class="card-body p-3">
            <div class="d-flex flex-row-reverse justify-content-between">
              <div class="text-center icon icon-shape bg-gradient-dark shadow">
                <i class="ni ni-chart-bar-32 text-lg opacity-10" aria-hidden="true"></i>
              </div>
              <div class="">
                <div class="numbers">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold">Indsigt</p>
                  <small class="text-xs text-muted">Opdateres hver 24. time</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Interval kontrol -->
    <div v-if="userSettings.futurePosts" class="row mb-4">
      <div class="col-12">
        <div class="interval-control-header d-flex justify-content-end align-items-center">
          <button 
            class="btn btn-link p-0 text-sm d-flex align-items-center" 
            @click="showIntervalControl = !showIntervalControl"
          >
            <span class="me-1">{{ showIntervalControl ? 'Skjul Interval Kontrol' : 'Vis Interval Kontrol' }}</span>
            <i 
              class="fas fa-chevron-down"
              :class="{ 'fa-rotate-180': showIntervalControl }"
              style="transition: transform 0.3s ease"
            ></i>
          </button>
        </div>
        <transition name="slide-fade">
          <div v-show="showIntervalControl" class="mt-3">
            <IntervalControl 
              :default-interval="22"
              @update-interval="handleIntervalUpdate"
              @generate-placeholders="generatePlaceholders"
              :is-generating="isLoading"
            />
          </div>
        </transition>
      </div>
    </div>

    <!-- Kalender -->
    <div ref="calendarEl" class="calendar-container"></div>

    <!-- GetLinks komponent -->
    <GetLinks ref="linksModalRef" />
    <SocialMediaAnalytics ref="analyticsModalRef" />
  </div>
</template>
<style scoped>
/* Fjern .calendar-container styling */

.fc-daygrid-event-harness {
  margin-top: 0 !important;
}

.fc-event-main {
  padding: 5px;
  position: relative;
}

.fc-title {
  font-weight: bold;
  margin-bottom: 5px;
  padding-right: 20px;
}

.children-container {
  font-size: 0.9em;
  padding: 0;
  margin-bottom: 0;
}

.child-element {
  list-style-type: none;
  margin-left: 0;
  text-align: left;
}

.fc-post-text-row {
  font-size: 0.8em;
  margin-top: 5px;
}

.fc-post-text {
  font-weight: bold;
}

.fc-datetime-text {
  color: #c5c5c5;
  font-weight: bold;
}

.show-more-link,
.show-less-link {
  font-size: 0.8em;
  color: blue;
  text-decoration: none;
  cursor: pointer;
}

/* Tilføj disse klasser til din komponent eller App.vue for at påvirke FullCalendar's standardstil */
:deep(.fc-daygrid-event) {
  white-space: normal !important;
  --fc-event-text-color: #ffffff !important;
}

:deep(.bg-class-posted) {
  background-color: #4CAF50 !important;
}

:deep(.bg-class-instagram) {
  background-color: #C13584 !important;
}

:deep(.bg-class-facebook) {
  background-color: #3b5998 !important;
}

:deep(.fc-daygrid-day-events) {
  min-height: auto !important;
}

:deep(.fc-daygrid-day-bottom) {
  margin-top: 0 !important;
}

:deep(.fc-h-event) {
  border: none !important;
}

:deep(.fc-event-main) {
  padding: 5px !important;
}

:deep(.fc-event-title) {
  padding: 0 !important;
}

:deep(.event-inner-container) {
  background-color: inherit;
  height: 100%;
}

:deep(.fc-daygrid-event) {
  white-space: normal !important;
  overflow: hidden;
}

.child-element div {
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

.card:hover {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  transform: translateY(-1px);
}

:deep(.fc-event) {
  background: linear-gradient(310deg, #1171ef 0%, #4c619e 100%) !important;
}

/* Style for dagens dato */
:deep(.fc-day-today) {
  background: linear-gradient(310deg, rgba(65, 65, 65, 0.501) 0%, rgba(65, 65, 65, 0.1) 100%) !important;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-content {
  text-align: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 20px;
}

.loading-text {
  color: #666;
  font-size: 14px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading {
  opacity: 0.5;
  pointer-events: none;
}

:deep(.fc-daygrid-more-link) {
  color: #5e72e4 !important;
  background: transparent !important;
  padding: 2px !important;
  margin-top: 2px !important;
  font-weight: bold !important;
}

:deep(.fc-daygrid-day-bottom) {
  margin-top: 8px !important;
  padding: 4px 0 !important;
  
  .fc-daygrid-more-link {
    padding: 2px 4px !important;
    font-size: 11px !important;
    color: #5e72e4 !important;
    background: rgba(94, 114, 228, 0.1) !important;
    border-radius: 4px !important;
    
    &:hover {
      background: rgba(94, 114, 228, 0.2) !important;
    }
  }
}

:deep(.fc-daygrid-event) {
  transition: all 0.2s ease-in-out !important;
  border: none !important;
  background: linear-gradient(122deg, #2d9ace #2d5bce 100%) !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

/* Tilføj disse styles i style-sektionen */
.placeholder-modal-container {
  z-index: 9999 !important;
}

.placeholder-modal {
  background: #fff !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15) !important;
}

.placeholder-content {
  padding: 20px !important;
}

:deep(.placeholder-group) {
  background: linear-gradient(45deg, #6c6c6c, #6c6c6c) !important;
  border: none !important;
  border-radius: 4px !important;
  margin-bottom: 2px !important;
  cursor: pointer !important;
  opacity: 0.5 !important;
}

:deep(.placeholder-group .fc-event-title) {
  font-weight: bold !important;
  color: white !important;
  padding: 4px !important;
}

:deep(.placeholder-group .fc-event-time) {
  padding: 4px !important;
  color: rgba(255, 255, 255, 0.9) !important;
}

:deep(.placeholder-group:hover) {
  filter: brightness(1.1);
  transform: translateY(-1px);
  transition: all 0.2s ease;
}

/* Tilføj nye transitions */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  max-height: 500px;
  overflow: hidden;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-5px);
  opacity: 0;
  max-height: 0;
}

/* Tilføj nye styles for interval control */
.interval-control-header {
  background: linear-gradient(310deg, rgba(33, 33, 33, 0.05) 0%, rgba(33, 33, 33, 0.05) 100%);
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
}

.interval-control-header .btn-link {
  color: #344767;
  font-weight: 600;
  text-decoration: none;
  padding: 0;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

.interval-control-header .btn-link:hover {
  color: #5e72e4;
}

.interval-control-header .fa-chevron-down {
  font-size: 10px;
  color: #344767;
}

.main-content {
  position: relative;
  max-height: 100vh;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  will-change: scroll-position;
  -webkit-overflow-scrolling: touch;
}

/* Tilføj denne style for at forhindre body scroll når modal er åben */
:deep(.swal2-shown) {
  overflow: hidden !important;
  padding-right: 0 !important;
}

/* Sikre at modal container ikke påvirker scroll */
:deep(.swal2-container) {
  padding-right: 0 !important;
}

/* Tilføj smooth scroll behavior */
.main-content {
  scroll-behavior: smooth;
}
</style>