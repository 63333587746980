<template>
  <footer class="footer-section">
    <div class="footer-container">
      <!-- Logo og Kontakt Sektion -->
      <div class="footer-column">
        <a href="/" class="footer-logo">
          <img src="https://firebasestorage.googleapis.com/v0/b/toft-d4f39.appspot.com/o/autoFamily-white.png?alt=media&token=167b0d7c-13d2-43ff-bd48-ee932bff7f09" alt="Logo" class="footer-logo-img">
        </a>
        <div class="contact-info">
          <p>Birkevej 4, 5492 Vissenbjerg</p>
          <p v-auto-translate><strong>CVR</strong>: <strong>31393194</strong></p>
          <p v-auto-translate>Kontakt: <a href="mailto:support@estatefamily.ai">we@makemore.dk</a></p>
          <p v-auto-translate>Tlf: <a href="tel:+4589873606">+45 31 39 31 94</a></p>
        </div>
        <div class="social-icons">
          <a href="https://www.linkedin.com/company/we-makemore" target="_blank" class="social-icon linkedin">
            <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
              <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
            </svg>
          </a>
        </div>
      </div>

      <!-- Hurtig Links Sektion -->
      <div class="footer-column">
        <h3 v-auto-translate>Hurtig link</h3>
        <nav class="footer-nav">
          <ul>
            <li><a v-auto-translate href="/kontakt">Kontakt</a></li>
          </ul>
        </nav>
      </div>

      <!-- Andet Sektion -->
      <div class="footer-column">
        <h3 v-auto-translate>Andet</h3>
        <ul class="footer-links">
          <li><a v-auto-translate href="/privacy-policy">Privatlivspolitik</a></li>
          <li><a v-auto-translate href="/blog">Blog</a></li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<style scoped>
.footer-section {
  background-color: #1d2025;
  padding: 4rem 2rem;
  font-family: Poppins, sans-serif;
  border-top: 1px solid rgba(45, 154, 206, 0.1);
}

.footer-container {
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 4rem;
}

.footer-column {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.footer-logo {
  display: block;
  max-width: 200px;
}

.footer-logo-img {
  width: 100%;
  height: auto;
}

.contact-info {
  color: #86868b;
  font-size: 0.9rem;
  line-height: 1.6;
}

.contact-info p {
  margin: 0.5rem 0;
}

.contact-info a {
  color: #2dce89;
  text-decoration: none;
  transition: color 0.2s ease;
}

.contact-info a:hover {
  color: #25a870;
}

.social-icons {
  display: flex;
  gap: 1rem;
}

.social-icon {
  width: 40px;
  height: 40px;
  background: #0077b5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.social-icon:hover {
  transform: translateY(-2px);
  background: #0066a0;
}

.social-icon svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}

.footer-column h3 {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0 0 1rem 0;
}

.footer-nav ul,
.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-nav ul li,
.footer-links li {
  margin-bottom: 0.75rem;
}

.footer-nav ul li a,
.footer-links li a {
  color: #86868b;
  text-decoration: none;
  transition: color 0.2s ease;
  font-size: 0.9rem;
}

.footer-nav ul li a:hover,
.footer-links li a:hover {
  color: #fff;
}

@media (max-width: 992px) {
  .footer-container {
    grid-template-columns: 1fr 1fr;
  }

  .footer-column:first-child {
    grid-column: 1 / -1;
  }
}

@media (max-width: 576px) {
  .footer-section {
    padding: 3rem 1.5rem;
  }

  .footer-container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .footer-column {
    text-align: center;
  }

  .footer-logo {
    margin: 0 auto;
  }

  .social-icons {
    justify-content: center;
  }
}
</style> 