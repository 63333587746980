<template>
  <main class="blog-overview">
    <div class="container">
      <!-- Hero section med forbedret layout -->
      <header class="blog-hero">
        <span class="blog-badge">BLOG</span>
        <h1 class="blog-title">Creative Automation Ideas</h1>
        <p class="blog-description">
          Need inspiration? Browse through our ideas for creative automations to
          make your content shareable here!
        </p>
      </header>
      
      <!-- Blog posts grid med forbedret layout -->
      <div v-if="blogPosts.length > 0" class="blog-grid">
        <article 
          v-for="post in blogPosts" 
          :key="post.id" 
          class="blog-card"
          @mouseover="post.isHovered = true"
          @mouseleave="post.isHovered = false"
        >
          <div class="blog-card__image-wrapper">
            <img 
              :src="post.bannerImageUrl" 
              :alt="`Billede til artikel ${post.title}`" 
              loading="lazy"
              class="blog-card__image"
            >
            <div class="blog-card__overlay" :class="{ 'is-hovered': post.isHovered }">
              <router-link 
                :to="{ name: 'BlogPost', params: { slug: post.slug, id: post.id } }" 
                class="blog-card__link"
              >
                Læs mere
                <i class="fas fa-arrow-right"></i>
              </router-link>
            </div>
          </div>
          <div class="blog-card__content">
            <h2 class="blog-card__title">{{ post.title }}</h2>
            <p class="blog-card__excerpt">
              {{ stripHtmlAndTruncate(post.content, 120) }}
            </p>
            <div class="blog-card__meta">
              <span class="blog-card__date">
                {{ formatDate(post.createdAt) }}
              </span>
              <span class="blog-card__reading-time">
                {{ calculateReadingTime(post.content) }} min læsning
              </span>
            </div>
          </div>
        </article>
      </div>
      <div v-else class="blog-loader">
        <div class="loader"></div>
        <p>Indlæser blogindlæg...</p>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '@/firebase';

const blogPosts = ref([]);

// Tilføj formatDate funktion
function formatDate(timestamp) {
  if (!timestamp) return '';
  const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
  return new Intl.DateTimeFormat('da-DK', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  }).format(date);
}

// Tilføj læsetids beregning
function calculateReadingTime(content) {
  const wordsPerMinute = 200;
  const wordCount = content?.split(/\s+/)?.length || 0;
  return Math.ceil(wordCount / wordsPerMinute);
}

function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/[\s_]+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
}

onMounted(async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'blogPosts'));
    blogPosts.value = querySnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        slug: data.slug || slugify(data.title)
      };
    });
    console.log('All blog posts:', blogPosts.value);
  } catch (error) {
    console.error('Fejl ved hentning af blogindlæg:', error);
  }
});

function stripHtmlAndTruncate(html, maxLength) {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  const text = tmp.textContent || tmp.innerText || '';
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
}
</script>

<style scoped>
.blog-overview {
  background-color: #1a1a1a;
  color: #ffffff;
  min-height: 100vh;
  padding: 4rem 0;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
}

.blog-hero {
  text-align: center;
  margin-bottom: 5rem;
  padding: 3rem 0;
}

.blog-badge {
  display: inline-block;
  background: linear-gradient(122deg, #05c2ed, #1568db);
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 50px;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  letter-spacing: 1px;
}

.blog-title {
  font-size: 3.5rem;
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 1.5rem;
  line-height: 1.2;
}

.blog-description {
  font-size: 1.25rem;
  color: #cccccc;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.6;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 2.5rem;
  margin-top: 2rem;
}

.blog-card {
  background: #2a2a2a;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-card:hover {
  transform: translateY(-5px);
}

.blog-card__image-wrapper {
  position: relative;
  padding-top: 60%;
  overflow: hidden;
}

.blog-card__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.blog-card:hover .blog-card__image {
  transform: scale(1.05);
}

.blog-card__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(122deg, rgba(5, 194, 237, 0.85), rgba(21, 104, 219, 0.85));
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.blog-card:hover .blog-card__overlay {
  opacity: 1;
}

.blog-card__link {
  color: white;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.1rem;
  padding: 1rem 2rem;
  border: 2px solid white;
  border-radius: 50px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.blog-card__link:hover {
  background: white;
  color: #05c2ed;
}

.blog-card__content {
  padding: 2rem;
}

.blog-card__title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 1rem;
  line-height: 1.3;
}

.blog-card__excerpt {
  color: #cccccc;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.blog-card__meta {
  display: flex;
  justify-content: space-between;
  color: #888;
  font-size: 0.875rem;
  border-top: 1px solid #3a3a3a;
  padding-top: 1rem;
}

.blog-loader {
  text-align: center;
  padding: 4rem 0;
  color: #ffffff;
}

.loader {
  border: 3px solid #2a2a2a;
  border-radius: 50%;
  border-top: 3px solid #05c2ed;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .blog-grid {
    grid-template-columns: 1fr;
  }
  
  .blog-title {
    font-size: 2.5rem;
  }
  
  .container {
    padding: 0 1rem;
  }
}
</style>