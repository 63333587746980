<!-- ProfileHeader.vue -->
<template>
  <div class="container-fluid">
    <div
      class="page-header min-height-200"
      :style="{
        backgroundImage: 'url(' + computedHeaderImage + ')',
        marginRight: '-24px',
        marginLeft: '-34%',
      }"
    >
      <span class="mask"></span>
    </div>
    <div class="card shadow-lg custom-margin">
      <div class="card-body p-3">
        <div class="row gx-4 align-items-center justify-content-between">
          <div class="col-auto d-flex align-items-center">
            <div class="avatar avatar-xl position-relative me-3">
              <img
                :src="profileImage"
                alt="profile_image"
                class="shadow-sm w-100 border-radius-lg"
              />
            </div>
            <div class="h-100">
              <h5 class="mb-0">{{ userName }}</h5>
            </div>
          </div>
          <div class="col-auto nav-container">
            <NavHead @update:activeTab="handleActiveTabUpdate" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import NavHead from "./NavHead.vue";

const auth = getAuth();
const db = getFirestore();

const headerImage = ref('');
const profileImage = ref('');
const userName = ref('Unknown');
const userRole = ref('User');

const DEFAULT_HEADER_IMAGE = 'https://firebasestorage.googleapis.com/v0/b/toft-d4f39.appspot.com/o/headerImages%2FM8CzwvR9d9Sf3tCcTqMJ3VCObrS2%2Fshubham-dhage-9-o7jBKBGPI-unsplash.jpg?alt=media&token=b57e0c57-52a6-44ec-8c5f-c7279dcd2edd';

const computedHeaderImage = computed(() => {
  return headerImage.value || DEFAULT_HEADER_IMAGE;
});

const loadUserData = async () => {
  const user = auth.currentUser;
  if (user) {
    const userRef = doc(db, 'users', user.uid);
    const userSnap = await getDoc(userRef);
    
    if (userSnap.exists()) {
      const userData = userSnap.data();
      userName.value = userData.name || 'Unknown';
      userRole.value = userData.role || 'User';
      
      // Hent billeder fra adminSettings
      if (userData.adminSettings) {
        headerImage.value = userData.adminSettings.headerImage || '';
        profileImage.value = userData.adminSettings.profileImage || '../assets/img/default-profile.png';
      } else {
        // Fallback til gamle stier, hvis adminSettings ikke findes
        headerImage.value = userData.headerImage || '';
        profileImage.value = userData.profileImage || '../assets/img/default-profile.png';
      }
    }
  }
};

onMounted(() => {
  loadUserData();
});

const emit = defineEmits(['update:activeTab']);

const handleActiveTabUpdate = (newTab) => {
  emit('update:activeTab', newTab);
};
</script>

<style scoped>
.custom-margin {
  margin-top: -3rem !important;
}

.card-body {
  padding: 1rem 1.5rem !important;
}

.row {
  margin: 0;
  align-items: center;
}

.nav-container {
  width: auto;
  max-width: 800px; /* Juster denne værdi efter behov */
  flex: 0 1 auto;
}

.avatar {
  margin: 0;
}

h5 {
  white-space: nowrap;
  margin-right: 2rem;
}

/* Responsive design */
@media (max-width: 1200px) {
  .nav-container {
    max-width: 600px;
  }
}

@media (max-width: 992px) {
  .nav-container {
    max-width: 100%;
    margin-top: 1rem;
  }
  
  .row {
    flex-direction: column;
    align-items: flex-start;
  }
}

.mask {
  background: var(--sidenav-color) !important;
  opacity: 0.9;
}
</style>
