import { ref } from 'vue';
import { 
  getFirestore, 
  collection, 
  query, 
  where, 
  getDocs,
  onSnapshot,
  doc,
  getDoc 
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const calendarCache = ref(new Map());
const loadingPromises = new Map();
const activeListeners = new Map();

export const calendarService = {
  // Cache struktur: Map<userId, { posts: [], postSend: [], lastUpdated: Date }>
  cache: calendarCache,
  
  async preloadCalendarData(userId) {
    if (!userId) {
      console.error('Intet userId angivet til preloadCalendarData');
      return null;
    }

    if (loadingPromises.has(userId)) {
      return loadingPromises.get(userId);
    }
    
    const loadingPromise = this._fetchCalendarData(userId);
    loadingPromises.set(userId, loadingPromise);
    
    try {
      const result = await loadingPromise;
      return result;
    } finally {
      loadingPromises.delete(userId);
    }
  },
  
  async getCalendarData(userId) {
    if (!userId) {
      console.error('Intet userId angivet til getCalendarData');
      return null;
    }

    const cachedData = calendarCache.value.get(userId);
    if (cachedData && (Date.now() - cachedData.lastUpdated) < 5 * 60 * 1000) {
      return cachedData;
    }
    
    return this.preloadCalendarData(userId);
  },
  
  async _fetchCalendarData(userId) {
    if (!userId) {
      console.error('Intet userId angivet til _fetchCalendarData');
      return null;
    }

    const db = getFirestore();
    
    try {
      const [posts, postSend] = await Promise.all([
        this._fetchPosts(db, userId),
        this._fetchPostSend(db, userId)
      ]);
      
      const calendarData = {
        posts,
        postSend,
        lastUpdated: Date.now()
      };
      
      calendarCache.value.set(userId, calendarData);
      return calendarData;
    } catch (error) {
      console.error('Fejl ved hentning af kalender data:', error);
      throw error;
    }
  },
  
  async _fetchPosts(db, userId) {
    if (!userId) return [];

    const postsQuery = query(
      collection(db, "posts"),
      where("id", "==", userId)
    );
    
    const snapshot = await getDocs(postsQuery);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      collection: 'posts'
    }));
  },
  
  async _fetchPostSend(db, userId) {
    if (!userId) return [];

    const postSendQuery = query(
      collection(db, "postSend"),
      where("id", "==", userId)
    );
    
    const snapshot = await getDocs(postSendQuery);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      collection: 'postSend'
    }));
  },

  // Ny metode til at sætte op real-time listeners
  setupRealTimeListeners(userId, onDataChange) {
    if (!userId) {
      console.error('Intet userId angivet til setupRealTimeListeners');
      return null;
    }

    // Hvis der allerede er aktive listeners for denne bruger, returner cleanup funktionen
    if (activeListeners.has(userId)) {
      console.log('Genbruger eksisterende listeners for bruger:', userId);
      return activeListeners.get(userId);
    }

    console.log('Sætter nye listeners op for bruger:', userId);
    const db = getFirestore();
    const listeners = [];

    try {
      // Posts listener
      const postsQuery = query(
        collection(db, "posts"),
        where("id", "==", userId)
      );
      
      const postsUnsubscribe = onSnapshot(postsQuery, {
        next: (snapshot) => {
          console.log('Posts ændring registreret');
          snapshot.docChanges().forEach((change) => {
            const data = {
              type: change.type,
              collection: 'posts',
              id: change.doc.id,
              data: { ...change.doc.data(), collection: 'posts' }
            };
            onDataChange(data);
          });
          this._updateCache(userId);
        },
        error: (error) => {
          if (error.code !== 'permission-denied') {
            console.error('Fejl i posts listener:', error);
          }
        }
      });
      
      // PostSend listener
      const postSendQuery = query(
        collection(db, "postSend"),
        where("id", "==", userId)
      );
      
      const postSendUnsubscribe = onSnapshot(postSendQuery, {
        next: (snapshot) => {
          console.log('PostSend ændring registreret');
          snapshot.docChanges().forEach((change) => {
            const data = {
              type: change.type,
              collection: 'postSend',
              id: change.doc.id,
              data: { ...change.doc.data(), collection: 'postSend' }
            };
            onDataChange(data);
          });
          this._updateCache(userId);
        },
        error: (error) => {
          if (error.code !== 'permission-denied') {
            console.error('Fejl i postSend listener:', error);
          }
        }
      });

      listeners.push(postsUnsubscribe, postSendUnsubscribe);
      
      const cleanup = () => {
        console.log('Udfører cleanup for bruger:', userId);
        listeners.forEach(unsubscribe => {
          try {
            unsubscribe();
          } catch (error) {
            console.error('Fejl ved unsubscribe:', error);
          }
        });
        activeListeners.delete(userId);
      };

      activeListeners.set(userId, cleanup);
      return cleanup;
    } catch (error) {
      console.error('Fejl ved setup af real-time listeners:', error);
      // Hvis der sker en fejl, sikrer vi at alle listeners bliver fjernet
      listeners.forEach(unsubscribe => {
        try {
          unsubscribe();
        } catch (error) {
          console.error('Fejl ved unsubscribe efter fejl:', error);
        }
      });
      activeListeners.delete(userId);
      throw error;
    }
  },

  // Hjælpefunktion til at opdatere cache når der kommer ændringer
  async _updateCache(userId) {
    if (!userId) return;

    try {
      console.log('Opdaterer cache for bruger:', userId);
      const newData = await this._fetchCalendarData(userId);
      if (newData) {
        calendarCache.value.set(userId, newData);
      }
    } catch (error) {
      console.error('Fejl ved opdatering af cache:', error);
    }
  },
  
  clearCache(userId) {
    if (userId) {
      console.log('Rydder cache for specifik bruger:', userId);
      calendarCache.value.delete(userId);
    } else {
      console.log('Rydder al cache');
      calendarCache.value.clear();
    }
  },

  // Cleanup funktion der kan kaldes når komponenten unmountes
  cleanup(userId) {
    if (!userId) {
      console.warn('Intet userId angivet til cleanup');
      return;
    }

    console.log('Starter cleanup for bruger:', userId);
    const cleanup = activeListeners.get(userId);
    if (cleanup) {
      try {
        cleanup();
        console.log('Cleanup gennemført for bruger:', userId);
      } catch (error) {
        console.error('Fejl under cleanup:', error);
      }
    }
    this.clearCache(userId);
  }
}; 