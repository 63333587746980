<template>
  <section class="section-book">
    <div class="container-book-demo">
      <div class="book-demo">
        <!-- Title Section -->
        <div class="book-demo-title">
          <h1 v-auto-translate class="h1-book-demo">Book en 30-minutters demo</h1>
          <div class="book-demo-text-wrap">
            <p v-auto-translate>Oplev hvordan vores system kan hjælpe din forretning og markedsføring, og spare dig for værdifuld tid!</p>
          </div>
        </div>

        <!-- Form Section -->
        <div class="book-form">
          <div class="book-form-wrap">
            <form @submit.prevent="submitForm" class="demo-form">
              <input 
                v-model="form.firstName"
                class="input-field"
                type="text"
                placeholder="Fornavn"
                required
              >
              <input 
                v-model="form.lastName"
                class="input-field"
                type="text"
                placeholder="Efternavn"
                required
              >
              <input 
                v-model="form.email"
                class="input-field"
                type="email"
                placeholder="Arbejds-email"
                required
              >
              <input 
                v-model="form.phone"
                class="input-field"
                type="tel"
                placeholder="Telefon"
                required
              >
              <input 
                v-model="form.companyName"
                class="input-field"
                type="text"
                placeholder="Firmanavn"
                required
              >
              <input 
                v-model="form.jobTitle"
                class="input-field"
                type="text"
                placeholder="Jobtitel"
                required
              >
              <textarea 
                v-model="form.useCase"
                class="input-field textarea"
                placeholder="Fortæl os mere om dine behov"
                required
              ></textarea>
              
              <button type="submit" class="submit-button">
                <span v-if="!isSubmitting">Send anmodning</span>
                <span v-else>Sender...</span>
              </button>

              <div class="disclaimer">
                Ved at fortsætte accepterer du vores 
                <a href="/privacy">Privatlivspolitik</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue';
import { getFirestore, collection, addDoc } from 'firebase/firestore';

const isSubmitting = ref(false);
const form = ref({
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  companyName: '',
  jobTitle: '',
  useCase: '',
  submitDate: new Date().toISOString()
});

const emit = defineEmits(['demo-submitted']);

const submitForm = async () => {
  isSubmitting.value = true;
  try {
    const db = getFirestore();
    await addDoc(collection(db, 'demoRequests'), {
      ...form.value,
      submitDate: new Date().toISOString()
    });
    
    console.log('Anmodning sendt succesfuldt!');
    
    // Reset form efter success
    form.value = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      companyName: '',
      jobTitle: '',
      useCase: '',
      submitDate: new Date().toISOString()
    };
    emit('demo-submitted');
  } catch (error) {
    console.error('Error submitting form:', error);
  } finally {
    isSubmitting.value = false;
  }
};
</script>

<style scoped>
.section-book {
  position: relative;

  font-family: Poppins, sans-serif;
  overflow: hidden;
}

.container-book-demo {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.book-demo {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  max-width: 800px;
  margin: 0 auto;
}

.book-demo-title {
  text-align: center;
}

.h1-book-demo {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  background: linear-gradient(90deg, #fff, #e7dfd6);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.book-demo-text-wrap p {
  font-size: 1.25rem;
  color: #86868b;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
}

.accent-text {
  color: #2d9ace;
  font-size: 0.875rem;
  margin: 0;
}

.book-form {
  border-radius: 16px;
  padding: 2.5rem;
}

.heading-form {
  font-size: 2rem;
  margin-bottom: 2rem;
  background: linear-gradient(90deg, #fff, #e7dfd6);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.demo-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input-field {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 1rem;
  color: #fff;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.input-field:focus {
  outline: none;
  border-color: #2d9ace;
  background: rgba(255, 255, 255, 0.08);
}

.textarea {
  min-height: 120px;
  resize: vertical;
}

.submit-button {
  background: linear-gradient(122deg,#1568db,#05c2ed);
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(45, 154, 206, 0.25);
}

.submit-button:active {
  transform: translateY(0);
}

.disclaimer {
  text-align: center;
  font-size: 0.875rem;
  color: #86868b;
  margin-top: 1rem;
}

.disclaimer a {
  color: #2d9ace;
  text-decoration: none;
}

.disclaimer a:hover {
  text-decoration: underline;
}

.section-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.1;
}

.section-bg-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .section-book {
    padding: 4rem 1.5rem;
  }

  .h1-book-demo {
    font-size: 2.5rem;
  }

  .book-demo-text-wrap p {
    font-size: 1.1rem;
  }

  .book-form {
    padding: 2rem;
  }

  .heading-form {
    font-size: 1.75rem;
  }
}

@media (max-width: 480px) {
  .section-book {
    padding: 3rem 1rem;
  }

  .h1-book-demo {
    font-size: 2rem;
  }

  .book-demo-text-wrap p {
    font-size: 1rem;
  }

  .book-form {
    padding: 1.5rem;
  }

  .heading-form {
    font-size: 1.5rem;
  }

  .input-field {
    padding: 0.875rem;
  }
}
</style> 