<template>
  <div class="language-switcher">
    <button 
      @click="setLocale('da')" 
      :class="['flag-btn', { active: currentLocale === 'da' }]"
      aria-label="Skift til dansk">
      <svg width="513" height="343" viewBox="0 0 513 343" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_dk)">
          <path d="M0 0.892578H513V342.893H0V0.892578Z" fill="#C60C30"/>
          <path d="M190 0.892578H130V140.893H0V200.893H130V342.893H190V200.893H513V140.893H190V0.892578Z" fill="white"/>
        </g>
        <defs>
          <clipPath id="clip0_dk">
            <rect width="513" height="342" fill="white" transform="translate(0 0.892578)"/>
          </clipPath>
        </defs>
      </svg>
    </button>
    <button 
      @click="setLocale('en')" 
      :class="['flag-btn', { active: currentLocale === 'en' }]"
      aria-label="Switch to English">
      <svg width="513" height="343" viewBox="0 0 513 343" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_uk)">
          <path d="M0 0.926514H513V342.93H0V0.926514Z" fill="white"/>
          <path d="M288.562 0.923584H224.438V139.86H0V203.985H224.438V342.922H288.562V203.985H513V139.86H288.562V0.923584Z" fill="#D80027"/>
          <path d="M394.554 231.401L513 297.205V231.401H394.554ZM312.261 231.401L513 342.922V311.387L369.026 231.401H312.261ZM459.53 342.922L312.261 261.099V342.922H459.53Z" fill="#0052B4"/>
          <path d="M312.261 231.401L513 342.922V311.387L369.026 231.401H312.261Z" fill="white"/>
          <path d="M312.261 231.401L513 342.922V311.387L369.026 231.401H312.261Z" fill="#D80027"/>
          <path d="M90.5174 231.399L0 281.687V231.399H90.5174ZM200.739 245.58V342.921H25.5408L200.739 245.58Z" fill="#0052B4"/>
          <path d="M143.974 231.401L0 311.387V342.922L200.739 231.401H143.974Z" fill="#D80027"/>
          <path d="M118.446 112.445L0 46.6407V112.445H118.446ZM200.739 112.445L0 0.923584V32.4591L143.974 112.445H200.739ZM53.4702 0.923584L200.739 82.7471V0.923584H53.4702Z" fill="#0052B4"/>
          <path d="M200.739 112.445L0 0.923584V32.4591L143.974 112.445H200.739Z" fill="white"/>
          <path d="M200.739 112.445L0 0.923584V32.4591L143.974 112.445H200.739Z" fill="#D80027"/>
          <path d="M422.483 112.447L513 62.1589V112.447H422.483ZM312.261 98.2653V0.924561H487.459L312.261 98.2653Z" fill="#0052B4"/>
          <path d="M369.026 112.445L513 32.4591V0.923584L312.261 112.445H369.026Z" fill="#D80027"/>
        </g>
        <defs>
          <clipPath id="clip0_uk">
            <rect width="513" height="342" fill="white" transform="translate(0 0.926514)"/>
          </clipPath>
        </defs>
      </svg>
    </button>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import i18n from '@/i18n';

const currentLocale = ref(localStorage.getItem('userLocale') || 'da');

const setLocale = (locale) => {
  currentLocale.value = locale;
  i18n.global.locale.value = locale;
  localStorage.setItem('userLocale', locale);
  document.documentElement.setAttribute('lang', locale);
};

watch(() => i18n.global.locale.value, (newLocale) => {
  currentLocale.value = newLocale;
}, { immediate: true });

onMounted(() => {
  setLocale(currentLocale.value);
});
</script>

<style scoped>
.language-switcher {
  display: flex;
  gap: 12px;
  align-items: center;
}

.flag-btn {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 30px;
  height: 20px;
  transition: all 0.2s ease;
  opacity: 0.6;
}

.flag-btn:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

.flag-btn.active {
  opacity: 1;
  border-radius: 2px;
}

.flag-btn svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style> 