<template>
  <div v-if="show" class="preview-modal">
    <div class="preview-content">
      <div v-if="isLoading" class="preview-loading">
        <div class="loading-spinner-container">
          <div class="loading-spinner"></div>
          <div class="loading-text">Genererer opslag...</div>
        </div>
      </div>
      <template v-else>
        <div class="preview-image-container">
          <div v-if="isGeneratingVideo" class="media-preview">
            <div class="video-generation-status">
              <div class="status-text">{{ generationStatusMessage }}</div>
              <div class="progress-container">
                <div class="progress-bar" :style="{ width: '100%' }">
                  <div class="progress-animation"></div>
                </div>
              </div>
            </div>
          </div>
          <video v-else-if="videoUrl" 
                controls 
                class="preview-video"
                :src="videoUrl"
                @error="handleVideoError">
          </video>
          <img v-else 
               :src="previewImageUrl" 
               alt="Preview" 
               class="preview-image">
        </div>
        <div class="preview-text-container">
          <textarea 
            :value="previewText"
            @input="$emit('update:previewText', $event.target.value)"
            class="preview-textarea"
          ></textarea>
        </div>
        <div class="button-container">
          <button 
            @click="shuffleText" 
            class="shuffle-button"
            variant="gradient"
            size="sm">
            Ny tekst
          </button>
          <button 
            @click="aiAssistedText" 
            class="ai-assisted-button"
            variant="gradient"
            size="sm"
            :disabled="isLoading">
            <template v-if="!isLoading">
              Optimér tekst
            </template>
            <div v-else class="button-spinner">
              <div class="spinner"></div>
            </div>
          </button>
          <button 
            @click="savePreview" 
            class="save-button"
            variant="gradient"
            size="sm">
            Opret opslag
          </button>
          <button 
            @click="closePreview" 
            class="close-button"
            variant="gradient"
            size="sm">
            <i class="fas fa-times me-2"></i>
            Luk
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  show: {
    type: Boolean,
    required: true
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  isGeneratingVideo: {
    type: Boolean,
    default: false
  },
  generationStatusMessage: {
    type: String,
    default: ''
  },
  videoUrl: {
    type: String,
    default: ''
  },
  previewImageUrl: {
    type: String,
    default: ''
  },
  previewText: {
    type: String,
    default: ''
  }
});

const emit = defineEmits([
  'update:show',
  'update:previewText',
  'shuffle-text',
  'ai-assisted-text',
  'save-preview',
  'video-error'
]);

const closePreview = () => {
  emit('update:show', false);
};

const shuffleText = () => {
  emit('shuffle-text');
};

const aiAssistedText = () => {
  emit('ai-assisted-text');
};

const savePreview = () => {
  emit('save-preview');
};

const handleVideoError = (event) => {
  emit('video-error', event);
};
</script>

<style scoped>
.preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.preview-content {
  position: relative;
  background: rgba(40, 44, 52, 0.95);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  padding: 2rem;
  width: 95%;
  max-width: 1000px;
  height: 60vh;
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: 2rem;
  box-shadow: 
    0 20px 40px rgba(0, 0, 0, 0.4),
    0 0 0 1px rgba(255, 255, 255, 0.1);
  animation: modalFadeIn 0.3s ease-out;
}

.preview-image-container {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  padding: 1.5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.2);
}

.preview-image,
.preview-video {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.preview-text-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
  position: relative;
}

.preview-textarea {
  flex: 1;
  width: 100%;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  line-height: 1.6;
  resize: none;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  gap: 0.75rem;
  width: 100%;
  padding: 0 0.5rem;
}

.button-container button {
  height: 32px;
  padding: 0 1.2rem;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  border-radius: 0.5rem;
  transition: all 0.15s ease-in;
  background-position: center;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 90px;
}

.shuffle-button {
  color: #fff;
  background: linear-gradient(310deg, #141727, #3A416F);
}

.ai-assisted-button {
  color: #fff;
  background: linear-gradient(310deg, #627594, #A8B8D8);
}

.save-button {
  color: #fff;
  background: linear-gradient(122deg, #2d5bce 0%, #2d9ace 100%);
}

.close-button {
  color: #fff;
  background: linear-gradient(310deg, #D1293D, #F53939);
}

.button-container button:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
  filter: brightness(110%);
}

.button-container button:active {
  transform: translateY(1px);
  box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
}

.preview-loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 20px;
  padding: 2rem;
}

.loading-spinner-container {
  text-align: center;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top: 4px solid #ffffff;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  margin: 0 auto 1rem;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 1rem;
}

/* Responsive design */
@media (max-width: 1024px) {
  .preview-content {
    grid-template-columns: 1fr;
    height: 95vh;
  }
  
  .preview-image-container {
    height: 40vh;
  }
  
  .button-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5rem;
    padding: 0;
  }
  
  .button-container button {
    width: 100%;
    min-width: 0;
  }
}

@media (max-width: 640px) {
  .button-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.me-2 {
  margin-right: 0.5rem;
}

.fas {
  font-size: 0.875rem;
}

.button-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 0.8s linear infinite;
}

.ai-assisted-button:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}
</style> 