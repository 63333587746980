<template>
  <div v-if="loading" class="loading">
    <div class="loader"></div>
  </div>
  <article v-else-if="post" class="article-detail__main">
    <div class="top-gradient"></div>
    
    <button @click="goBack" class="back-button">
      <i class="fas fa-arrow-left"></i>
      <span>Tilbage til blog</span>
    </button>

    <div class="article-banner" :style="{ backgroundImage: `url(${post.bannerImage})` }">
      <div class="article-banner__overlay">
        <div class="article-banner__content">
          <div class="article-meta">
            <span class="article-banner__category">{{ post.category }}</span>
            <span class="article-banner__date">{{ formatDate(post.date) }}</span>
          </div>
          <h1 class="article-banner__title">{{ post.title }}</h1>
        </div>
      </div>
    </div>

    <div class="article-content">
      <nav class="article-breadcrumbs" aria-label="breadcrumb">
        <ol>
          <li><a href="/">Hjem</a></li>
          <li><a href="/blog">Blog</a></li>
          <li class="active">{{ post.title }}</li>
        </ol>
      </nav>

      <div class="article-body">
        <div class="article-body__intro" v-html="post.intro"></div>
        <div class="article-body__text" v-html="post.content"></div>
      </div>
    </div>
  </article>
  <div v-else-if="error" class="error">
    <i class="fas fa-exclamation-circle"></i>
    <p>{{ error }}</p>
    <button @click="goBack" class="error-button">Gå tilbage</button>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/firebase';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();
const post = ref(null);
const loading = ref(true);
const error = ref(null);

const formatDate = (date) => {
  if (!date) return '';
  return new Date(date).toLocaleDateString('da-DK', { 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric' 
  });
};

const goBack = () => {
  router.push('/blog');
};

onMounted(async () => {
  const id = route.params.id;
  console.log('Document ID:', id);
  
  try {
    const docRef = doc(db, 'blogPosts', id);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      post.value = { id: docSnap.id, ...docSnap.data() };
      console.log('Post data:', post.value);
    } else {
      console.log("Ingen sådan dokument!");
      error.value = "Blogindlægget blev ikke fundet.";
    }
  } catch (err) {
    console.error("Fejl ved hentning af dokument:", err);
    error.value = "Der opstod en fejl ved indlæsning af blogindlægget.";
  } finally {
    loading.value = false;
  }
});
</script>

<style scoped>
.article-detail__main {
  max-width: 100%;
  background-color: #1a1a1a;
  color: #ffffff;
  min-height: 100vh;
  position: relative;
}

.top-gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 200px;
  background: linear-gradient(to bottom, 
    rgba(5, 194, 237, 0.15),
    rgba(26, 26, 26, 0)
  );
  pointer-events: none;
  z-index: 1;
}

.back-button {
  position: fixed;
  top: 2rem;
  left: 2rem;
  z-index: 10;
  background: rgba(26, 26, 26, 0.8);
  border: 1px solid rgba(5, 194, 237, 0.4);
  color: #fff;
  padding: 0.8rem 1.5rem;
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2),
              0 0 0 1px rgba(5, 194, 237, 0.1);
  transition: all 0.3s ease;
}

.back-button:hover {
  background: linear-gradient(122deg, rgba(5, 194, 237, 0.3), rgba(21, 104, 219, 0.3));
  border-color: rgba(5, 194, 237, 0.6);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(5, 194, 237, 0.3),
              0 0 0 1px rgba(5, 194, 237, 0.2);
}

.article-banner {
  height: 600px;
  background-size: cover;
  background-position: center;
  position: relative;
  border-bottom: 1px solid rgba(138, 75, 175, 0.2);
}

.article-banner__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    165deg,
    rgba(26, 26, 26, 0.4) 0%,
    rgba(26, 26, 26, 0.8) 60%,
    rgba(26, 26, 26, 0.95) 100%
  );
  display: flex;
  align-items: flex-end;
}

.article-banner__content {
  padding: 4rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  position: relative;
}

.article-meta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.article-banner__category {
  font-size: 1rem;
  color: #fff;
  background: linear-gradient(122deg, rgba(5, 194, 237, 0.9), rgba(21, 104, 219, 0.9));
  padding: 0.5rem 1.5rem;
  border-radius: 50px;
  font-weight: 600;
  display: inline-block;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.article-banner__title {
  font-size: 3.5rem;
  color: #ffffff;
  margin-bottom: 1rem;
  font-weight: 700;
  line-height: 1.2;
  text-shadow: 0 2px 4px rgba(0,0,0,0.5);
}

.article-banner__date {
  color: #cccccc;
  font-size: 1rem;
}

.article-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 3rem 2rem;
  background-color: #1a1a1a;
  position: relative;
}

.article-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background: linear-gradient(
    to right,
    rgba(5, 194, 237, 0),
    rgba(21, 104, 219, 0.6),
    rgba(5, 194, 237, 0)
  );
}

.article-breadcrumbs {
  margin-bottom: 2rem;
  padding: 1rem 0;
  border-bottom: 1px solid rgba(138, 75, 175, 0.1);
}

.article-breadcrumbs ol {
  list-style: none;
  display: flex;
  gap: 0.5rem;
  padding: 0;
}

.article-breadcrumbs li:not(:last-child):after {
  content: '/';
  margin-left: 0.5rem;
  color: #444;
}

.article-breadcrumbs a {
  color: #05c2ed;
  text-decoration: none;
  transition: color 0.3s ease;
}

.article-breadcrumbs a:hover {
  color: #1568db;
}

.article-body__intro {
  font-size: 1.4rem;
  margin-bottom: 2rem;
  color: #cccccc;
  line-height: 1.6;
  font-weight: 500;
}

.article-body__text {
  line-height: 1.8;
  color: #ffffff;
  font-size: 1.1rem;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #05c2ed;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}

.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 1rem;
  text-align: center;
  background-color: #1a1a1a;
  color: #ffffff;
}

.error i {
  font-size: 3rem;
  color: #e74c3c;
}

.error-button {
  background: linear-gradient(122deg, #05c2ed, #1568db);
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.error-button:hover {
  background: linear-gradient(122deg, #1568db, #05c2ed);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .top-gradient {
    height: 150px;
  }

  .article-banner {
    height: 400px;
  }

  .article-banner__content {
    padding: 2rem;
  }

  .article-banner__title {
    font-size: 2rem;
  }

  .back-button {
    top: 1rem;
    left: 1rem;
  }

  .article-content {
    padding: 2rem 1rem;
  }
  
  .article-body__intro {
    font-size: 1.2rem;
  }
}
</style>