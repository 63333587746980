<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import PricingSection from "@/components/PricingSection.vue";
import FooterSection from "@/components/FooterSection.vue";
import PreviewDemo from "@/views/pages/components/PreviewDemo.vue";
import MirroredFeaturesTabs from "@/components/MirroredFeaturesTabs.vue";
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "@/firebase";
import { whiteLabelService } from '@/services/whiteLabelService';
import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { Flip } from 'gsap/Flip';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import BookDemo from "@/views/BookDemo.vue";
import FlickeringGrid from "@/components/ui/FlickeringGrid.vue";
gsap.registerPlugin(CustomEase, Flip);

const isLoading = ref(true);
const router = useRouter();
const store = useStore();

// Login form states
const email = ref("");
const password = ref("");
const rememberMe = ref(false);
const errorMessage = ref("");
const isLoginLoading = ref(false);
const showLoginForm = ref(false);
const showDemoForm = ref(false);

// Tab system states
const activeTab = ref(0);
const tabs = ref([
  {
    title: 'Automatisering',
    description: 'Automatiser din markedsføring og spar værdifuld tid. Vores system håndterer alt fra opslag til timing.',
    image: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?q=80&w=1744&auto=format&fit=crop'
  },
  {
    title: 'Intelligent AI',
    description: 'Udnyt kraften i kunstig intelligens til at optimere dine kampagner og nå de rigtige kunder.',
    image: 'https://images.unsplash.com/photo-1677442136019-21780ecad995?q=80&w=1932&auto=format&fit=crop'
  },
  {
    title: 'Analytics',
    description: 'Få detaljeret indsigt i dine kampagners performance med vores avancerede analyseværktøjer.',
    image: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?q=80&w=1740&auto=format&fit=crop'
  }
]);

const applyWhiteLabelSettings = async (userData) => {
  try {
    let settings;
    if (userData.role === 2) {
      settings = await whiteLabelService.getAdminSettings(userData.id);
    } else if (userData.role === 3 && userData.createdBy) {
      settings = await whiteLabelService.getAdminSettings(userData.createdBy);
    }

    if (settings) {
      const favicon = document.querySelector('link[rel="icon"]');
      if (!favicon) {
        const newFavicon = document.createElement('link');
        newFavicon.rel = 'icon';
        newFavicon.type = 'image/png';
        if (settings.logo) {
          newFavicon.href = settings.logo;
        }
        document.head.appendChild(newFavicon);
      } else if (settings.logo) {
        favicon.href = settings.logo;
      }

      if (settings.companyInfo?.name) {
        document.title = settings.companyInfo.name;
      }

      document.documentElement.style.setProperty('--primary-color', settings.theme?.primaryColor || '#5e72e4');
      document.documentElement.style.setProperty('--secondary-color', settings.theme?.secondaryColor || '#8392ab');
      document.documentElement.style.setProperty('--accent-color', settings.theme?.accentColor || '#2d9ace');
    }
  } catch (error) {
    console.error('Fejl ved anvendelse af white label indstillinger:', error);
  }
};

const signIn = async () => {
  isLoginLoading.value = true;
  errorMessage.value = "";
  
  try {
    const persistenceType = rememberMe.value
      ? browserLocalPersistence
      : browserSessionPersistence;
    await setPersistence(auth, persistenceType);

    const userCredential = await signInWithEmailAndPassword(
      auth,
      email.value,
      password.value
    );
    
    const userDocRef = doc(db, "users", userCredential.user.uid);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      throw new Error("Brugerdata ikke fundet");
    }

    const userData = userDoc.data();

    const essentialUserData = {
      id: userCredential.user.uid,
      role: userData.role,
      isOnline: true,
      name: userData.name,
      email: userData.email,
      createdBy: userData.createdBy,
      adminSettings: userData.adminSettings
    };

    await store.dispatch("setCurrentUser", essentialUserData);
    await updateDoc(userDocRef, { isOnline: true });
    await applyWhiteLabelSettings(essentialUserData);

    if (userData.role <= 2) {
      await router.push("/pages/tables");
    } else {
      await router.push("/profile");
    }

  } catch (error) {
    console.error('Login fejl:', error);
    errorMessage.value = "Forkert email eller adgangskode";
  } finally {
    isLoginLoading.value = false;
  }
};

const toggleLoginForm = () => {
  showLoginForm.value = !showLoginForm.value;
};

const toggleDemoForm = () => {
  showDemoForm.value = !showDemoForm.value;
};

const initTabSystem = () => {
  const wrapper = document.querySelector('[data-tabs="wrapper"]');
  if (!wrapper) return;

  const nav = wrapper.querySelector('[data-tabs="nav"]');
  const contentWrap = wrapper.querySelector('[data-tabs="content-wrap"]');
  const visualWrap = wrapper.querySelector('[data-tabs="visual-wrap"]');
  
  const buttons = [...nav.querySelectorAll('[data-tabs="button"]')];
  const contentItems = [...contentWrap.querySelectorAll('[data-tabs="content-item"]')];
  const visualItems = [...visualWrap.querySelectorAll('[data-tabs="visual-item"]')];

  let current = 0;
  let previous = 0;

  const switchTab = (index) => {
    previous = current;
    current = index;

    // Update buttons
    buttons[previous].classList.remove('active');
    buttons[current].classList.add('active');

    // Animate content
    const prevContent = contentItems[previous].querySelectorAll('[data-tabs-fade]');
    const currentContent = contentItems[current].querySelectorAll('[data-tabs-fade]');

    gsap.to(prevContent, {
      opacity: 0,
      y: 20,
      duration: 0.3,
      stagger: 0.1,
      ease: 'power2.in',
    });

    gsap.fromTo(
      currentContent,
      { opacity: 0, y: 20 },
      {
        opacity: 1,
        y: 0,
        duration: 0.3,
        stagger: 0.1,
        ease: 'power2.out',
        delay: 0.2,
      }
    );

    // Switch visibility
    contentItems[previous].classList.remove('active');
    contentItems[current].classList.add('active');
    visualItems[previous].classList.remove('active');
    visualItems[current].classList.add('active');
  };

  buttons.forEach((button, index) => {
    button.addEventListener('click', () => switchTab(index));
  });
};

const initFlipButtons = () => {
  const buttonWraps = document.querySelectorAll('[data-flip-button="wrap"]');
  
  buttonWraps.forEach(wrap => {
    const buttons = wrap.querySelectorAll('[data-flip-button="button"]');
    const bg = wrap.querySelector('[data-flip-button="bg"]');
    
    if (!bg) return;

    const state = Flip.getState(bg);
    buttons[0].appendChild(bg);
    Flip.from(state, { duration: 0.5, ease: 'power2.inOut' });

    buttons.forEach(button => {
      button.addEventListener('click', () => {
        const state = Flip.getState(bg);
        button.appendChild(bg);
        Flip.from(state, { duration: 0.5, ease: 'power2.inOut' });
      });
    });
  });
};

// Add scroll handler for header
const isScrolled = ref(false);

const handleScroll = () => {
  isScrolled.value = window.scrollY > 20;
};

onMounted(async () => {
  initTabSystem();
  initFlipButtons();
  
  try {
    if (store.state.isAuthenticated) {
      const userRole = store.state.user?.role;
      if (userRole <= 2) {
        await router.push("/pages/tables");
      } else {
        await router.push("/profile");
      }
    }
  } finally {
    isLoading.value = false;
  }

  window.addEventListener('scroll', handleScroll);
});
</script>

<template>
  <div class="home-page-container">
    <!-- Header Section -->
    <header class="site-header" :class="{ 'scrolled': isScrolled }">
      <div class="header-inner">
        <div class="header-left">
          <a href="/" class="logo-link">
            <img src="https://firebasestorage.googleapis.com/v0/b/toft-d4f39.appspot.com/o/autoFamily-white.png?alt=media&token=167b0d7c-13d2-43ff-bd48-ee932bff7f09" alt="Logo" class="header-logo">
          </a>
            </div>
        <div class="header-right">
          <LanguageSwitcher />
          <a v-auto-translate @click="toggleLoginForm" class="header-button login-btn">
            Log ind
          </a>
          </div>
      </div>
    </header>

    <!-- Hero Section med login -->
    <section class="landing-page">
      <div v-if="isLoading" class="loading-overlay">
        <div class="loader"></div>
      </div>
      
      <!-- Tilføj FlickeringGrid som baggrund -->
      <FlickeringGrid
        class="absolute inset-0 w-full h-full"
        :square-size="4"
        :grid-gap="6"
        color="#2d9ace"
        :max-opacity="0.15"
        :flicker-chance="0.1"
      />

      <div class="header-text" aria-hidden="true">
        <span v-auto-translate class="glow-filter" data-text="Illuminated Glow Text.">Markedets mest intelligente</span><br>
        <span v-auto-translate>markedsføringssystem til bilbranchen!</span><br>
      </div>
      <p v-auto-translate>
      En fremtidssikret løsning for bilbranchen. Med vores intelligente markedsføringssystem kan du let automatisere dine salgsemner til sociale medier nemt, hurtigt og helt uden at røre en finger.
      </p>

      <!-- Demo button -->
      <div class="demo-button">
        <ArgonButton
          class="bg-gradient-success btn-lg custom-demo-btn"
          @click="toggleDemoForm"
        >
          <span v-auto-translate>Book en demo</span>
        </ArgonButton>
            </div>

      <!-- Demo Form Popup -->
      <div v-if="showDemoForm" class="demo-form-container" @click.self="toggleDemoForm">
        <div class="demo-form">
          <button class="close-button" @click="toggleDemoForm">&times;</button>
          <BookDemo @demo-submitted="toggleDemoForm" />
          </div>
        </div>

      <!-- Login Form -->
      <div v-if="showLoginForm" class="login-form-container" @click.self="toggleLoginForm">
        <div class="login-form">
          <form @submit.prevent="signIn">
            <div class="form-group">
              <argon-input
                id="email"
                v-model="email"
                type="email"
                placeholder="Email"
                name="email"
                size="lg"
                aria-label="Email"
              />
            </div>
            <div class="form-group">
              <argon-input
                id="password"
                v-model="password"
                type="password"
                placeholder="Adgangskode"
                name="password"
                size="lg"
                aria-label="Adgangskode"
              />
          </div>

            <div v-if="errorMessage" class="error-message">
              {{ errorMessage }}
          </div>

            <div class="login-actions">
              <argon-button
                :disabled="isLoginLoading"
                type="submit"
                class="bg-gradient-success btn-lg custom-login-btn"
                full-width
              >
                <span v-auto-translate v-if="!isLoginLoading">Log ind</span>
                <span v-auto-translate v-else>Logger ind...</span>
              </argon-button>
            </div>
          </form>
          </div>
        </div>

      <svg class="filters" width='1440px' height='300px' viewBox='0 0 1440 300' xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
          <filter id="glow-4" color-interpolation-filters="sRGB" x="-50%" y="-200%" width="200%" Height="500%">
            <feGaussianBlur in="SourceGraphic" data-target-blur="4" stdDeviation="4" result="blur4"/>
            <feGaussianBlur in="SourceGraphic" data-target-blur="19" stdDeviation="19" result="blur19"/>
            <feGaussianBlur in="SourceGraphic" data-target-blur="9" stdDeviation="9" result="blur9"/>
            <feGaussianBlur in="SourceGraphic" data-target-blur="30" stdDeviation="30" result="blur30"/>
            <feColorMatrix in="blur4" result="color-0-blur" type="matrix" values="1 0 0 0 0
                      0 0.9803921568627451 0 0 0
                      0 0 0.9647058823529412 0 0
                      0 0 0 0.8 0"/>
            <feOffset in="color-0-blur" result="layer-0-offsetted" dx="0" dy="0" data-target-offset-y="0"/>
            <feColorMatrix in="blur19" result="color-1-blur" type="matrix" values="0.8156862745098039 0 0 0 0
                      0 0.49411764705882355 0 0 0
                      0 0 0.2627450980392157 0 0
                      0 0 0 1 0"/>
            <feOffset in="color-1-blur" result="layer-1-offsetted" dx="0" dy="2" data-target-offset-y="2"/>
            <feColorMatrix in="blur9" result="color-2-blur" type="matrix" values="1 0 0 0 0
                      0 0.6666666666666666 0 0 0
                      0 0 0.36470588235294116 0 0
                      0 0 0 0.65 0"/>
            <feOffset in="color-2-blur" result="layer-2-offsetted" dx="0" dy="2" data-target-offset-y="2"/>
            <feColorMatrix in="blur30" result="color-3-blur" type="matrix" values="1 0 0 0 0
                      0 0.611764705882353 0 0 0
                      0 0 0.39215686274509803 0 0
                      0 0 0 1 0"/>
            <feOffset in="color-3-blur" result="layer-3-offsetted" dx="0" dy="2" data-target-offset-y="2"/>
            <feColorMatrix in="blur30" result="color-4-blur" type="matrix" values="0.4549019607843137 0 0 0 0
                      0 0.16470588235294117 0 0 0
                      0 0 0 0 0
                      0 0 0 1 0"/>
            <feOffset in="color-4-blur" result="layer-4-offsetted" dx="0" dy="16" data-target-offset-y="16"/>
            <feColorMatrix in="blur30" result="color-5-blur" type="matrix" values="0.4235294117647059 0 0 0 0
                      0 0.19607843137254902 0 0 0
                      0 0 0.11372549019607843 0 0
                      0 0 0 1 0"/>
            <feOffset in="color-5-blur" result="layer-5-offsetted" dx="0" dy="64" data-target-offset-y="64"/>
            <feColorMatrix in="blur30" result="color-6-blur" type="matrix" values="0.21176470588235294 0 0 0 0
                      0 0.10980392156862745 0 0 0
                      0 0 0.07450980392156863 0 0
                      0 0 0 1 0"/>
            <feOffset in="color-6-blur" result="layer-6-offsetted" dx="0" dy="64" data-target-offset-y="64"/>
            <feColorMatrix in="blur30" result="color-7-blur" type="matrix" values="0 0 0 0 0
                      0 0 0 0 0
                      0 0 0 0 0
                      0 0 0 0.68 0"/>
            <feOffset in="color-7-blur" result="layer-7-offsetted" dx="0" dy="64" data-target-offset-y="64"/>
            <feMerge>
              <feMergeNode in="layer-0-offsetted"/>
              <feMergeNode in="layer-1-offsetted"/>
              <feMergeNode in="layer-2-offsetted"/>
              <feMergeNode in="layer-3-offsetted"/>
              <feMergeNode in="layer-4-offsetted"/>
              <feMergeNode in="layer-5-offsetted"/>
              <feMergeNode in="layer-6-offsetted"/>
              <feMergeNode in="layer-7-offsetted"/>
              <feMergeNode in="layer-0-offsetted"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
        </defs>
      </svg>
    </section>

      <!-- Ny mellemsektion -->
      <section class="mid-section">
      <div class="content-wrapper">
        <h2 v-auto-translate>Automatisér din markedsføring</h2>
        <p v-auto-translate>Slip for tidskrævende manuelt arbejde og lad vores system håndtere alt det tekniske. Med vores løsning kan du fokusere på det vigtigste - at drive din forretning.</p>
      </div>
    </section>

    <!-- Features Section med tabs -->
    <section class="cloneable">
    <div data-tabs="wrapper" class="tab-layout">
    <div class="tab-layout-col">
      <div class="tab-layout-container">
        <div class="tab-container">
          <div class="tab-container-top">
            <h1 v-auto-translate class="tab-layout-heading">Udforsk mulighederne med Auto Family</h1>
            <div data-flip-button="wrap" data-tabs="nav" class="filter-bar">
              <button data-tabs="button" data-flip-button="button" class="filter-button active">
                <div v-auto-translate class="filter-button__p">Kalender</div>
                <div data-flip-button="bg" class="tab-button__bg"></div>
              </button>
              <button data-tabs="button" data-flip-button="button" class="filter-button">
                <div v-auto-translate class="filter-button__p">Preview</div>
              </button>
              <button data-tabs="button" data-flip-button="button" class="filter-button">
                <div v-auto-translate class="filter-button__p">Biler</div>
              </button>
  </div>
      </div>
          <div class="tab-container-bottom">
            <div data-tabs="content-wrap" class="tab-content-wrap">
              <div data-tabs="content-item" class="tab-content-item active">
                <h2 v-auto-translate data-tabs-fade="" class="tab-content__heading">Kalender</h2>
                <p v-auto-translate data-tabs-fade="" class="content-p opacity--80">Få et overblik over dine kampagner og planlægning. Slet rediger eller ryk rundt på dine opslg med 1-klik.</p>
            </div>
              <div data-tabs="content-item" class="tab-content-item">
                <h2 v-auto-translate data-tabs-fade="" class="tab-content__heading">Preview</h2>
                <p v-auto-translate data-tabs-fade="" class="content-p opacity--80">Se dit indhold før det bliver offentliggjort. Få en forhåndsvisning af dit indhold, så du kan se, hvordan det vil se ud på dine sociale medier.</p>
          </div>
              <div data-tabs="content-item" class="tab-content-item">
                <h2 v-auto-translate data-tabs-fade="" class="tab-content__heading">Biler</h2>
                <p v-auto-translate data-tabs-fade="" class="content-p opacity--80">Fuld kontrol over dine biler og lagerlister i Auto Family. Ekskluder biler fra Auto Family, så de ikke bliver offentliggjort.</p>
            </div>
          </div>
            </div>
          </div>
        </div>
            </div>
    <div class="tab-layout-col">
      <div data-tabs="visual-wrap" class="tab-visual-wrap">
        <div data-tabs="visual-item" class="tab-visual-item active">
          <img src="https://firebasestorage.googleapis.com/v0/b/toft-d4f39.appspot.com/o/Kalender.png?alt=media&token=48277d38-aca7-451a-9e55-6e7df2dbd433" loading="lazy" alt="Automatisering visualisering med computer og grafer" class="tab-image">
          </div>
        <div data-tabs="visual-item" class="tab-visual-item">
          <img src="https://firebasestorage.googleapis.com/v0/b/toft-d4f39.appspot.com/o/Preview.png?alt=media&token=0cc5d332-0228-4211-acb6-215186317af0" loading="lazy" alt="Kontrol dashboard med datavisualisering" class="tab-image">
            </div>
        <div data-tabs="visual-item" class="tab-visual-item">
          <img src="https://firebasestorage.googleapis.com/v0/b/toft-d4f39.appspot.com/o/Biler.png?alt=media&token=6688a871-dbd9-4f29-9015-af181218b825" loading="lazy" alt="Analyse og statistik visualisering" class="tab-image">
          </div>
            </div>
        </div>
      </div>
    </section>

    <!-- PreviewDemo Section -->
    <PreviewDemo />

    <!-- Mirrored Features Section -->
    <MirroredFeaturesTabs />

    <!-- Features Grid Section -->
    <section class="features-grid">
      <div class="features-header">
        <p v-auto-translate class="features-subtitle">Det får du blandt andet</p>
        <h2 v-auto-translate class="features-title">Med Auto Family</h2>
      </div>
      
      <div class="features-container">
        <div class="features-grid-row">
          <!-- Feature 1 -->
          <div class="feature-card" data-aos="fade-up">
            <div class="feature-content">
              <span v-auto-translate class="feature-tag">Fuldautomatisk</span>
              <h3 v-auto-translate class="feature-heading">Publicering</h3>
              <p v-auto-translate class="feature-description">Med Auto Family kan du let automatisere dine biler og lagerlister til sociale medier – nemt, hurtigt og 100% automatisk. Helt uden din indflydelse.</p>
            </div>
          </div>

          <!-- Feature 2 -->
          <div class="feature-card" data-aos="fade-up" data-aos-delay="100">
            <div class="feature-content">
              <span v-auto-translate class="feature-tag">Billede skabeloner</span>
              <h3 v-auto-translate class="feature-heading">Grafisk design</h3>
              <p v-auto-translate class="feature-description">Få din virksomhed til at se mere professionel ud med vores templates. Alle designs tilpasser sig automatisk din forretning og emner.</p>
            </div>
          </div>

          <!-- Feature 3 -->
          <div class="feature-card" data-aos="fade-up" data-aos-delay="200">
            <div class="feature-content">
              <span v-auto-translate class="feature-tag">Dit eget dashboard</span>
              <h3 v-auto-translate class="feature-heading">Statistik</h3>
              <p v-auto-translate class="feature-description">Få en bedre forståelse af, hvordan dit publikum interagerer med dit indhold. Vi holder styr på dine resultater og samler dem i et nemt og overskueligt overblik.</p>
            </div>
          </div>
        </div>

        <div class="features-grid-row">
          <!-- Feature 4 -->
          <div class="feature-card" data-aos="fade-up">
            <div class="feature-content">
              <span v-auto-translate class="feature-tag">Video skabeloner</span>
              <h3 v-auto-translate class="feature-heading">Videoindhold</h3>
              <p v-auto-translate class="feature-description">Er statiske opslag ikke nok, kan du vælge at gøre brug af vores build-in video templates. Vi laver dine videoer fuldt automatiserede og professionelt.</p>
            </div>
          </div>

          <!-- Feature 5 -->
          <div class="feature-card" data-aos="fade-up" data-aos-delay="100">
            <div class="feature-content">
              <span v-auto-translate class="feature-tag">Maskingenererede</span>
              <h3 v-auto-translate class="feature-heading">Tekster</h3>
              <p v-auto-translate class="feature-description">I Auto Family behøver du aldrig skrive en tekst mere. Vi sørger for, at alt indhold skrives automatisk for dig, ved hjælp af vores tekstkombinationer, kombineret med vores ai.</p>
            </div>
          </div>

          <!-- Feature 6 -->
          <div class="feature-card" data-aos="fade-up" data-aos-delay="200">
            <div class="feature-content">
              <span v-auto-translate class="feature-tag">Multifunktionelle</span>
              <h3 v-auto-translate class="feature-heading">Kalender</h3>
              <p v-auto-translate class="feature-description">Vores multifunktionelle kalender, samler alt i et og giver dig 1-click indstillinger, til at styre alt et sted fra. så du kan fokusere på at drive din forretning.</p>
            </div>
          </div>
        </div>

        <div class="features-footer">
          <p v-auto-translate class="features-note">
            Fuldautomatiske funktioner er inkluderet til planlægning og publicering af billeder, videoer og tekster. Derudover tilbyder vi AI-assisterede tekstmoduler, som kan hjælpe dig med at skrive eller redigere indhold. Vi tilbyder også semi-automatisk publicering af alle dine biler via vores indbyggede publiceringsværktøj. Dette værktøj forstår konteksten af både videoer og billeder og leverer en hjælp, du aldrig har oplevet før.
          </p>
        </div>
      </div>
    </section>

      <!-- Ny mellemsektion -->
      <section class="mid-section">
      <div class="content-wrapper">
        <h2 v-auto-translate>Effektivitet & skræddersyede løsninger</h2>
        <p v-auto-translate>Auto Family er det eneste system, der automatiserer gennem programmer og algoritmer for at spare tid uden at gå ned på kvaliteten.</p>
      </div>
    </section>

    <!-- Footer Section -->
    <FooterSection />
  </div>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.home-page-container {
  min-height: 100vh;
  width: 100%;
  background-color: #16181b !important;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 0;
}

.home-page-container * { 
  box-sizing: border-box; 
  -webkit-font-smoothing: antialiased; 
  text-rendering: optimizeLegibility; 
  scroll-behavior: smooth;
}

.cloneable {
  padding: 8rem 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  margin-top: 0;
  margin-bottom: 4rem;
  background: #16181b;
}

.tab-container {
  grid-column-gap: 3em;
  grid-row-gap: 3em;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 80%;
  padding-right: 2.5em;
  display: flex;
}

.tab-layout-container {
  width: 100%;
  max-width: 42em;
  height: 100%;
  margin: 0 auto;
  padding-top: 1em;
  padding-bottom: 2em;
}

.tab-container-top {
  grid-column-gap: 2em;
  grid-row-gap: 2em;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.tab-layout-col {
  width: 50%;
}

.tab-content-wrap {
  width: 100%;
  min-width: 24em;
  position: relative;
}


.content-p {
  margin: 0;
  font-size: 1.25em;
  line-height: 1.4;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  background: linear-gradient(0deg,#86868b 0%, #bdc2c9 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tab-button__bg {
  z-index: 0;
  background-color: #efeeec0f;
  border: 1px solid #efeeec14;
  border-radius: .25em;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0%;
}

.tab-content-item {
  z-index: 1;
  grid-column-gap: 1.25em;
  grid-row-gap: 1.25em;
  visibility: hidden;
  flex-flow: column;
  display: flex;
  position: absolute;
  inset: auto 0% 0%;
}

.tab-content-item.active {
  visibility: visible;
}

.tab-layout {
  z-index: 1;
  grid-row-gap: 3em;
  flex-flow: wrap;
  width: 100%;
  min-height: 37em;
  display: flex;
  position: relative;
}

.filter-bar {
  background-color: #efeeec0f;
  border: 1px solid #efeeec14;
  border-radius: .5em;
  padding: .5em;
  display: flex;
}

.filter-button {
  background-color: transparent;
  border: 1px solid transparent;
  padding: 1.125em 1.5em;
  transition: border-color .2s;
  position: relative;
  cursor: pointer;
}

.filter-button.active {
  border-color: rgba(239, 238, 236, 0.3);
  border-radius: .25em;
}

.filter-button__p {
  z-index: 1;
  font-size: 1.125em;
  position: relative;
  font-family: Poppins, sans-serif;
  background: linear-gradient(90deg, #fff, #e7dfd6);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tab-visual-wrap {
  border-radius: .5em;
  width: 100%;
  height: 42em;
  max-height: 80vh;
  position: relative;
  overflow: hidden;
}

.tab-visual-item {
  visibility: hidden;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.tab-visual-item.active {
  visibility: visible;
}

.tab-image {
  object-fit: cover;
  border-radius: .5em;
  width: 100%;
  max-width: none;
  height: 100%;
}

.tab-content__heading {
  letter-spacing: -.02em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.75em;
  font-weight: 500;
  line-height: 1.2 !important;
  font-family: Poppins, sans-serif;
  background: linear-gradient(90deg, #fff, #e7dfd6);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tab-layout-heading {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 3.5rem;
  font-weight: 500;
  line-height: 1.1;
  color: #fff;
  font-family: Poppins, sans-serif;
  background: linear-gradient(90deg, #fff, #e7dfd6);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tab-content__button {
  color: #131313;
  justify-content: center;
  align-items: center;
  height: 4em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  text-decoration: none;
  display: flex;
  position: relative;
  font-family: Poppins, sans-serif;
}

.tab-content__button .content-p {
  color: #131313;
  -webkit-text-fill-color: #131313;
  background: none;
}

/* Login Form Styles */
.login-form-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(8px);
  z-index: 1000;
}

.login-form {
  background: rgba(22, 24, 27, 0.95);
  border-radius: 12px;
  padding: 2rem;
  width: 100%;
  max-width: 320px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 1rem;
}

.form-group:last-of-type {
  margin-bottom: 1.5rem;
}

:deep(.form-control) {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #fff;
  transition: all 0.3s ease;
}

:deep(.form-control:focus) {
  background: rgba(255, 255, 255, 0.08);
  border-color: #2d9ace;
  box-shadow: none;
}

:deep(.form-control::placeholder) {
  color: rgba(255, 255, 255, 0.5);
}

.error-message {
  color: #ff4444;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  text-align: center;
}

.login-actions {
  margin-top: 0.5rem;
}

:deep(.btn) {
  border-radius: 8px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .cloneable {
    padding: 6rem 2rem;
  }
  
  .mid-section {
    padding: 5rem 2rem;
  }
}

@media (max-width: 992px) {
  .cloneable {
    padding: 5rem 2rem;
  }
  
  .mid-section {
    padding: 4rem 2rem;
  }
}

@media (max-width: 768px) {
  .cloneable {
    padding: 4rem 1.5rem;
  }
  
  .mid-section {
    padding: 3rem 1.5rem;
  }
}

@media (max-width: 480px) {
  .cloneable {
    padding: 3rem 1rem;
  }
  
  .mid-section {
    padding: 2.5rem 1rem;
  }
}

@media (max-height: 600px) {
  .cloneable {
    padding: 3rem 1rem;
    min-height: auto;
  }

  .tab-visual-wrap {
    height: 24em;
  }

  .tab-layout {
    gap: 2rem;
  }
}

/* Loading overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #2d5bce;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.landing-page {
  min-height: 600px;
  height: 80vh;
  max-height: 800px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 4rem 2rem;
  font-family: Poppins !important;
  position: relative;
  font-size: calc(var(--_size) * 0.022);
  --_factor: min(1000px, 100vh);
  --_size: min(var(--_factor), 100vw);
  background: rgba(22, 24, 27, 0.97) !important;
  margin-bottom: -0vh;
  overflow: hidden;
}

/* Fjern de gamle baggrunds-styles */
.landing-page::before,
.landing-page::after {
  display: none;
}

/* Tilføj absolute positionering for FlickeringGrid */
.absolute {
  position: absolute;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

svg.filters { height:0; width: 0; position: absolute; z-index:-1; }

.header-text {
    color: #c8c2bd;
    font-size: 3em;
    text-align: center;
    line-height: 1.0625;
    font-weight: 600;
    letter-spacing: -0.009em;
    position: relative;
    z-index: 1;
    margin: 0;
}

.glow-filter{
    position: relative;
    display: inline-block;
    scale: 1;
    color: #fffaf6;
    text-shadow: 0 0 12px rgba(255, 250, 246, 0.6);
    animation: onloadscale 1s ease-out forwards;
}

.glow-filter::before{
    content: attr(data-text);
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    color: #fffaf6;
    background: linear-gradient(0deg, #dfe5ee 0%, #fffaf6 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: url(#glow-4);
    -moz-filter: url(#glow-4);
    -webkit-filter: url(#glow-4);
    opacity: 0;
    animation: 
        onloadopacity 1s ease-out forwards,
        glowPulse 2s ease-in-out infinite;
    text-shadow: 
        0 0 24px rgba(255, 250, 246, 0.9),
        0 0 48px rgba(255, 250, 246, 0.7),
        0 0 72px rgba(255, 250, 246, 0.5);
}

@keyframes onloadscale {
    24% { scale: 1; }
    100% { scale: 1.02; }
}

@keyframes onloadopacity {
    24% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes glowPulse {
    0% { opacity: 0.85; }
    50% { opacity: 1; }
    100% { opacity: 0.85; }
}

.landing-page p {
    color: #86868b;
    font-weight: 600;
    background: linear-gradient(0deg,#86868b 0%, #bdc2c9 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    max-width: 28em;
    text-align: center;
    margin: 0;
    position: relative;
    z-index: 1;
}

.landing-page p span {
    position: relative;
    display: inline-block;
    -webkit-text-fill-color:#e7dfd6;
    font-weight: 1000;
}

/* Login button styling */
.login-button {
    position: relative;
    z-index: 2;
    margin-top: 1rem;
}

:deep(.btn.bg-gradient-success) {
    border: none;
    color: #fff;
}

:deep(.btn.bg-gradient-success:hover) {
    box-shadow: 0 3px 5px -1px rgba(45, 206, 137, 0.2), 0 6px 10px 0 rgba(45, 206, 137, 0.14), 0 1px 18px 0 rgba(45, 206, 137, 0.12);
}

:deep(.custom-login-btn.btn.btn-lg) {
    padding: 0.875rem 2rem !important;
    font-size: 1rem !important;
    border-radius: 0.5rem !important;
    transition: all 0.15s ease-in !important;
}

:deep(.custom-login-btn.btn.btn-lg:hover) {
    transform: translateY(-2px);
}

:deep(.custom-login-btn.btn.btn-lg:active) {
    transform: translateY(0);
}

/* Responsive styles for landing page */
@media (max-width: 1200px) {
  .landing-page {
    font-size: calc(var(--_size) * 0.020);
    padding: 3rem 2rem;
  }

  .landing-page p {
    max-width: 32em;
  }
}

@media (max-width: 768px) {
  .landing-page {
    font-size: calc(var(--_size) * 0.018);
    padding: 2.5rem 1rem;
    gap: 1.5rem;
  }

  .header-text {
    font-size: 2.5em;
    line-height: 1.2;
  }

  .landing-page p {
    max-width: 100%;
    padding: 0 1.5rem;
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .landing-page {
    font-size: calc(var(--_size) * 0.016);
    padding: 2rem 1rem;
    gap: 1.25rem;
  }

  .header-text {
    font-size: 2em;
    line-height: 1.3;
  }

  .landing-page p {
    font-size: 0.85em;
    line-height: 1.6;
  }
}

/* Landscape orientation fix */
@media (max-height: 600px) and (orientation: landscape) {
  .landing-page {
    font-size: calc(var(--_size) * 0.014);
    padding: 1.5rem 1rem;
    gap: 1rem;
  }
}

/* Ensure text remains readable on very small devices */
@media (max-width: 320px) {
  .landing-page {
    font-size: calc(var(--_size) * 0.014);
  }

  .header-text {
    font-size: 1.8em;
  }

  .landing-page p {
    translate: 0 7em;
    font-size: 0.8em;
  }

  .login-button {
    translate: 0 9em;
  }
}

/* Ny mellemsektion styling */
.mid-section {
  background-color: #1d2025;
  padding: 6rem 2rem;
  position: relative;
  z-index: 1;
  border-top: 1px solid rgba(45, 154, 206, 0.1);
  border-bottom: 1px solid rgba(45, 154, 206, 0.1);
  font-family: Poppins, sans-serif;
  margin-top: 0;
}

.mid-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(45, 154, 206, 0.03) 0%, transparent 100%);
  z-index: -1;
}

.mid-section .content-wrapper {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.mid-section h2 {
  font-family: Poppins, sans-serif;
  font-size: 2.5em;
  margin-bottom: 1rem;
  font-weight: 600;
  background: linear-gradient(90deg, #fff, #e7dfd6);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  z-index: 1;
}

.mid-section p {
  font-family: Poppins, sans-serif;
  color: #86868b;
  font-size: 1.2em;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto;
  font-weight: 600;
  background: linear-gradient(0deg,#86868b 0%, #bdc2c9 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  z-index: 1;
}

/* Responsive styles for mid-section */
@media (max-width: 768px) {
  .mid-section {
    padding: 3rem 1.5rem;
  }

  .mid-section h2 {
    font-size: 2em;
  }

  .mid-section p {
    font-size: 1.1em;
  }
}

@media (max-width: 480px) {
  .mid-section {
    padding: 2.5rem 1rem;
  }

  .mid-section h2 {
    font-size: 1.8em;
  }

  .mid-section p {
    font-size: 1em;
  }
}

/* Header Styles */
.site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: transparent;
  font-family: Poppins, sans-serif;
  transition: all 0.3s ease;
}

.site-header.scrolled {
  background: rgba(22, 24, 27, 0.95);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(45, 154, 206, 0.1);
}

.header-inner {
  max-width: 1850px;
  margin: 0 auto;
  padding: 1.25rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: padding 0.3s ease;
}

.site-header.scrolled .header-inner {
  padding: 0.75rem 2rem;
}

.header-left {
  display: flex;
  align-items: center;
}

.logo-link {
  display: block;
}

.header-logo {
  height: 40px;
  width: auto;
}

.header-right {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.header-button {
  font-family: Poppins, sans-serif;
  padding: 0.5rem 1.25rem;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  text-decoration: none;
}

.login-btn {
  color: #fff;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.login-btn:hover {
  background: rgba(255, 255, 255, 0.1);
}

.demo-btn {
  color: #fff;
  background: linear-gradient(122deg,#1568db,#05c2ed);
  border: none;
}

.demo-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(45, 206, 137, 0.25);
}

@media (max-width: 768px) {
  .header-inner {
    padding: 0.75rem 1rem;
  }
  
  .header-logo {
    height: 32px;
  }
  
  .header-button {
    padding: 0.4rem 1rem;
    font-size: 0.8125rem;
  }
}

@media (max-width: 480px) {
  .header-button {
    padding: 0.35rem 0.875rem;
    font-size: 0.75rem;
  }
}

/* Features Grid Section Styles */
.features-grid {
  position: relative;
  font-family: Poppins, sans-serif;
  margin-top: 100px;
}

.features-header {
  text-align: center;
  margin-bottom: 4rem;
}

.features-subtitle {
  font-size: 1rem;
  color: #2d9ace;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.features-title {
  font-size: 2.5rem;
  background: linear-gradient(90deg, #fff, #e7dfd6);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  font-weight: 600;
}

.features-container {
  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: 100px;
}

.features-grid-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;
}

.feature-card {
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 2rem;
  transition: all 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.03);
  border-color: rgba(255, 255, 255, 0.08);
}

.feature-content {
  text-align: center;
}

.feature-tag {
  display: inline-block;
  font-size: 0.875rem;
  color: #2d9ace;
  margin-bottom: 1rem;
  font-weight: 500;
}

.feature-heading {
  font-size: 1.5rem;
  background: linear-gradient(90deg, #fff, #e7dfd6);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 0 1rem 0;
  font-weight: 600;
}

.feature-description {
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #86868b;
  margin: 0;
  font-weight: 400;
}

.features-footer {
  text-align: center;
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.features-note {
  font-size: 1rem;
  color: #86868b;
  line-height: 1.6;
  margin: 0;
}

.features-note strong {
  color: #fff;
  font-weight: 600;
}

@media (max-width: 1200px) {
  .features-grid-row {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .features-grid {
    padding: 4rem 1.5rem;
  }

  .features-grid-row {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .features-title {
    font-size: 2rem;
  }

  .feature-card {
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .features-grid {
    padding: 3rem 1rem;
  }

  .features-title {
    font-size: 1.75rem;
  }

  .feature-heading {
    font-size: 1.25rem;
  }

  .feature-description {
    font-size: 0.875rem;
  }
}

/* Demo button styling */
.demo-button {
    position: relative;
    z-index: 2;
    margin-top: 1rem;
}

:deep(.custom-demo-btn.btn.btn-lg) {
    padding: 0.875rem 2rem !important;
    font-size: 1rem !important;
    border-radius: 0.5rem !important;
    transition: all 0.15s ease-in !important;
    background: linear-gradient(135deg, #2d9ace 0%, #2d5bce 100%) !important;
}

:deep(.custom-demo-btn.btn.btn-lg:hover) {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(45, 154, 206, 0.25);
}

:deep(.custom-demo-btn.btn.btn-lg:active) {
    transform: translateY(0);
}

/* Demo Form Popup Styles */
.demo-form-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(8px);
  z-index: 1000;
  padding: 20px;
}

.demo-form {
  background: #1d2025;
  border: 1px solid rgba(45, 154, 206, 0.1);
  border-radius: 12px;
  padding: 2rem;
  width: 100%;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  color: #86868b;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  line-height: 1;
  transition: color 0.2s ease;
}

.close-button:hover {
  color: #fff;
}
</style>