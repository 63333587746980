<template>
  <div>
    <h6 class="mb-3">Fakturaer</h6>
    <p class="text-sm mb-3">
      Her kan du se eller downloade alle dine fakturaer.
    </p>


    <!-- Beløbsoversigt -->
    <div class="row mb-4">
      <div class="col-md-6">
        <div class="d-flex align-items-center">
          <div class="me-4">
            <h3 class="mb-1">UDESTÅENDE</h3>
            <h2 class="mb-0 text-danger">{{ formatAmount(getTotalUnpaid) }}</h2>
          </div>
          <div class="ms-4">
            <h3 class="mb-1">BETALT I ÅR</h3>
            <h2 class="mb-0 text-success">{{ formatAmount(getTotalPaidThisYear) }}</h2>
          </div>
          <div class="ms-4">
            <h3 class="mb-1">KREDITERET</h3>
            <h2 class="mb-0 text-info">{{ formatAmount(getTotalCredited) }}</h2>
          </div>
        </div>
      </div>
    </div>

    <!-- Fakturaliste -->
    <div class="table-responsive">
      <table class="table align-items-center">
        <thead>
          <tr>
            <th>FAKTURA NR.</th>
            <th>DATO</th>
            <th>FORFALD</th>
            <th>BELØB</th>
            <th>STATUS</th>
            <th>KREDITNOTA</th>
            <th>HANDLING</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="invoice in invoices" 
              :key="invoice.id"
              class="invoice-row"
          >
            <td>{{ invoice.invoiceNumber }}</td>
            <td>{{ formatPeriod(invoice.createdAt) }}</td>
            <td>{{ formatDate(invoice.dueDate) }}</td>
            <td>{{ formatAmount(invoice.total) }}</td>
            <td>
              <span class="badge" :class="getStatusClass(invoice.status)">
                {{ formatStatus(invoice.status) }}
              </span>
              <span v-if="invoice.type === 'credit'" class="badge bg-info ms-2">
                Kreditnota
              </span>
              <span v-if="invoice.partiallyCredited && !invoice.type" class="badge bg-warning ms-2">
                Delvist krediteret
              </span>
            </td>
            <td>
              <template v-if="invoice.creditNoteNumber">
                {{ invoice.creditNoteNumber }}
              </template>
              <template v-else-if="invoice.partiallyCredited">
                Delvist krediteret: {{ formatAmount(invoice.creditedAmount) }}
              </template>
            </td>
            <td>
              <argon-button
                color="info"
                size="sm"
                variant="gradient"
                @click="showInvoiceDetails(invoice)"
              >
                <i class="fas fa-eye me-2"></i>
                Se faktura
              </argon-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Faktura detaljevisning -->
    <div v-if="selectedInvoice" class="invoice-modal">
      <div class="invoice-modal-content">
        <!-- Vis forskellig header baseret på type -->
        <div class="invoice-header">
          <div class="d-flex justify-content-between align-items-center">
            <div class="company-logo">
              <h3 class="mb-0" :class="{ 'text-danger': selectedInvoice.type === 'credit' }">
                {{ selectedInvoice.type === 'credit' ? 'KREDITNOTA' : 'FAKTURA' }}
              </h3>
            </div>
            <div class="hide-in-pdf">
              <argon-button
                color="success"
                size="sm"
                variant="gradient"
                class="me-2"
                @click="downloadInvoice"
              >
                <i class="fas fa-download me-2"></i>
                Download PDF
              </argon-button>
              <button class="close-btn" @click="selectedInvoice = null">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        
        <div class="invoice-body">
          <!-- Kreditnota specifik information -->
          <template v-if="selectedInvoice.type === 'credit'">
            <div class="credit-note-info mb-4">
              <div class="alert alert-credit">
                <h6 class="mb-1">Kreditnota Information</h6>
                <p class="mb-1">Original Faktura: #{{ selectedInvoice.originalInvoiceNumber }}</p>
                <p class="mb-1">Kredittype: {{ selectedInvoice.creditNoteType === 'fuld' ? 'Fuld kreditering' : 'Delvis kreditering' }}</p>
                <p class="mb-0">Begrundelse: {{ selectedInvoice.reason }}</p>
              </div>
            </div>

            <!-- Faktura metadata -->
            <div class="invoice-metadata mb-4">
              <div class="row">
                <div class="col-6">
                  <h2 class="invoice-number">
                    <span class="text-credit">Kreditnota</span>
                    #{{ selectedInvoice.invoiceNumber }}
                  </h2>
                </div>
                <div class="col-6 text-end">
                  <div class="date-info">
                    <p><strong>Dato:</strong> {{ formatDate(selectedInvoice.createdAt) }}</p>
                  </div>
                </div>
              </div>
            </div>

            <!-- Firma og kunde information -->
            <div class="row invoice-parties mb-5">
              <div class="col-6">
                <div class="party-info from-company">
                  <h6 class="info-label">Fra:</h6>
                  <div class="company-details">
                    <h5>{{ selectedInvoice.companyInfo.companyName }}</h5>
                    <p>
                      {{ selectedInvoice.companyInfo.address }}<br>
                      {{ selectedInvoice.companyInfo.postalCode }} {{ selectedInvoice.companyInfo.city }}
                    </p>
                    <p class="mb-0">CVR: {{ selectedInvoice.companyInfo.cvr }}</p>
                    <p class="mb-0">Tlf: {{ selectedInvoice.companyInfo.phone }}</p>
                    <p>{{ selectedInvoice.companyInfo.email }}</p>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="party-info to-customer">
                  <h6 class="info-label">Til:</h6>
                  <div class="customer-details">
                    <h5>{{ selectedInvoice.customerInfo.name }}</h5>
                    <p>{{ selectedInvoice.customerInfo.address }}</p>
                    <p class="mb-0">CVR: {{ selectedInvoice.customerInfo.cvr }}</p>
                    <p>{{ selectedInvoice.customerInfo.email }}</p>
                  </div>
                </div>
              </div>
            </div>

            <!-- Kreditnota tabel -->
            <div class="invoice-items-table">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Beskrivelse</th>
                    <th class="text-end">Antal</th>
                    <th class="text-end">Pris</th>
                    <th class="text-end">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in selectedInvoice.items" :key="item.description">
                    <td>
                      {{ item.description }}
                      <div v-if="item.details" class="text-muted small">
                        {{ item.details }}
                      </div>
                    </td>
                    <td class="text-end">{{ item.quantity }}</td>
                    <td class="text-end">{{ formatAmount(item.rate) }}</td>
                    <td class="text-end">{{ formatAmount(item.amount) }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="3" class="text-end"><strong>Subtotal:</strong></td>
                    <td class="text-end">{{ formatAmount(selectedInvoice.subtotal) }}</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="text-end"><strong>Moms (25%):</strong></td>
                    <td class="text-end">{{ formatAmount(selectedInvoice.tax) }}</td>
                  </tr>
                  <tr class="total-row">
                    <td colspan="3" class="text-end">
                      <strong>Total krediteret:</strong>
                    </td>
                    <td class="text-end">
                      <strong>{{ formatAmount(selectedInvoice.total) }}</strong>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <!-- Footer -->
            <div class="invoice-footer mt-4">
              <div class="row">
                <div class="col-12 text-center">
                  <p class="mb-0">{{ selectedInvoice.footerText }}</p>
                </div>
              </div>
            </div>
          </template>

          <!-- Faktura specifik information -->
          <template v-else>
            <!-- Faktura metadata -->
            <div class="invoice-metadata mb-4">
              <div class="row">
                <div class="col-6">
                  <h2 class="invoice-number">Faktura #{{ selectedInvoice.invoiceNumber }}</h2>
                </div>
                <div class="col-6 text-end">
                  <div class="status-badge hide-in-pdf" :class="getStatusClass(selectedInvoice.status)">
                    {{ formatStatus(selectedInvoice.status) }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Firma og kunde information -->
            <div class="row invoice-parties mb-5">
              <div class="col-6">
                <div class="party-info from-company">
                  <h6 class="info-label">Fra:</h6>
                  <div class="company-details">
                    <h5>{{ companyInfo.companyName }}</h5>
                    <p>
                      {{ companyInfo.address }}<br>
                      {{ companyInfo.postalCode }} {{ companyInfo.city }}
                    </p>
                    <p class="mb-0">CVR: {{ companyInfo.cvr }}</p>
                    <p class="mb-0">Tlf: {{ companyInfo.phone }}</p>
                    <p>{{ companyInfo.email }}</p>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="party-info to-customer">
                  <h6 class="info-label">Til:</h6>
                  <div class="customer-details">
                    <h5>{{ selectedInvoice.customerInfo.name }}</h5>
                    <p>{{ selectedInvoice.customerInfo.address }}</p>
                    <p class="mb-0">CVR: {{ selectedInvoice.customerInfo.cvr }}</p>
                    <p>{{ selectedInvoice.customerInfo.email }}</p>
                  </div>
                </div>
              </div>
            </div>

            <!-- Faktura datoer -->
            <div class="row invoice-dates mb-4">
              <div class="col-6">
                <div class="date-info">
                  <p><strong>Fakturadato:</strong> {{ formatDate(selectedInvoice.createdAt) }}</p>
                  <p><strong>Forfaldsdato:</strong> {{ formatDate(selectedInvoice.dueDate) }}</p>
                </div>
              </div>
            </div>

            <!-- Faktura tabel -->
            <div class="invoice-items-table">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Beskrivelse</th>
                    <th class="text-end">Antal</th>
                    <th class="text-end">Pris</th>
                    <th class="text-end">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in selectedInvoice.items" :key="item.description">
                    <td>{{ item.description }}</td>
                    <td class="text-end">{{ item.quantity }}</td>
                    <td class="text-end">{{ formatAmount(item.rate) }}</td>
                    <td class="text-end">{{ formatAmount(item.amount) }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="3" class="text-end"><strong>Subtotal:</strong></td>
                    <td class="text-end">{{ formatAmount(selectedInvoice.subtotal) }}</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="text-end"><strong>Moms (25%):</strong></td>
                    <td class="text-end">{{ formatAmount(selectedInvoice.tax) }}</td>
                  </tr>
                  <tr class="total-row">
                    <td colspan="3" class="text-end"><strong>Total:</strong></td>
                    <td class="text-end"><strong>{{ formatAmount(selectedInvoice.total) }}</strong></td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <!-- Betalingsinformation -->
            <div class="payment-info mt-4">
              <div class="row">
                <div class="col-12">
                  <div class="payment-details">
                    <h6>Betalingsinformation</h6>
                    <p class="mb-2">Betalingsbetingelser: Netto 8 dage</p>
                    <p class="mb-2">Beløbet indbetales på bankkonto:</p>
                    <p class="mb-2">
                      Reg.nr. <strong>{{ companyInfo.bankReg }}</strong> / 
                      Kontonr. <strong>{{ companyInfo.bankAccount }}</strong>
                    </p>
                    <p class="mb-3">Fakturanr. <strong>{{ selectedInvoice.invoiceNumber }}</strong> bedes angivet ved bankoverførsel</p>
                    <p class="small">Ved betaling efter forfald tilskrives der renter på 0,93%, pr. påbegyndt måned, samt et gebyr på 100,00 DKK</p>
                  </div>
                </div>
              </div>
            </div>

            <!-- Footer -->
            <div class="invoice-footer mt-4">
              <div class="row">
                <div class="col-12 text-center">
                  <p class="mb-0">{{ footerText }}</p>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { collection, query, where, getDocs, orderBy, addDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '@/firebase';
import { useStore } from 'vuex';
import ArgonButton from "@/components/ArgonButton.vue";
import html2pdf from 'html2pdf.js';

const props = defineProps({
  userId: {
    type: String,
    required: true
  }
});

const store = useStore();
const invoices = ref([]);
const selectedInvoice = ref(null);
const userData = ref(null);

// Computed properties for roles
const isSuperAdmin = computed(() => store.state.activeUser?.role === 1);
const isAdmin = computed(() => store.state.activeUser?.role === 2);
const isUser = computed(() => store.state.activeUser?.role === 3);

// Fetch user data først
const fetchUserData = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      userData.value = {
        id: userDoc.id,
        ...userDoc.data()
      };
    }
  } catch (error) {
    console.error('Fejl ved hentning af brugerdata:', error);
  }
};

// Opdateret fetchInvoices til at bruge subcollection
const fetchInvoices = async () => {
  if (!props.userId) {
    console.error('Intet bruger-ID angivet');
    return;
  }

  try {
    const invoicesRef = collection(db, 'users', props.userId, 'invoices');
    const q = query(
      invoicesRef,
      orderBy('createdAt', 'desc')
    );

    const querySnapshot = await getDocs(q);
    invoices.value = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

  } catch (error) {
    console.error('Fejl ved hentning af fakturaer:', error);
  }
};

// Kald begge funktioner når komponenten mountes
onMounted(async () => {
  await fetchUserData(props.userId);
  await fetchInvoices();
});

// Watch på userId
watch(() => props.userId, async (newUserId) => {
  if (newUserId) {
    await fetchUserData(newUserId);
    await fetchInvoices();
  }
}, { immediate: true });

// Computed properties for totaler
const getTotalUnpaid = computed(() => {
  return invoices.value
    .filter(inv => {
      // Ekskluder kreditnotaer og fuldt krediterede fakturaer
      if (inv.type === 'credit' || inv.status === 'krediteret') {
        return false;
      }
      // For delvist krediterede fakturaer, brug det resterende beløb
      if (inv.partiallyCredited) {
        return inv.status !== 'betalt' && inv.remainingAmount > 0;
      }
      return inv.status !== 'betalt';
    })
    .reduce((sum, inv) => {
      if (inv.partiallyCredited) {
        return sum + inv.remainingAmount;
      }
      return sum + (inv.total || 0);
    }, 0);
});

const getTotalPaidThisYear = computed(() => {
  const currentYear = new Date().getFullYear();
  return invoices.value
    .filter(inv => {
      const invDate = inv.createdAt?.toDate?.() || new Date(inv.createdAt);
      return inv.status === 'betalt' && invDate.getFullYear() === currentYear;
    })
    .reduce((sum, inv) => sum + (inv.total || 0), 0);
});

const getTotalCredited = computed(() => {
  return invoices.value
    .filter(inv => inv.type === 'credit' || inv.status === 'krediteret')
    .reduce((sum, inv) => {
      if (inv.type === 'credit') {
        return sum + Math.abs(inv.total);
      } else if (inv.partiallyCredited) {
        return sum + inv.creditedAmount;
      }
      return sum + (inv.total || 0);
    }, 0);
});

// Helper functions
const getStatusClass = (status) => {
  return {
    'bg-success': status === 'betalt',
    'bg-warning': status === 'udstedt',
    'bg-danger': status === 'forfalden',
    'bg-info': status === 'credit',
    'bg-secondary': status === 'krediteret',
    'bg-warning': status === 'delvist_krediteret'
  };
};

const formatPeriod = (timestamp) => {
  if (!timestamp) return '';
  const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
  return date.toLocaleString('da-DK', { 
    month: 'short',
    year: 'numeric'
  });
};

const formatDate = (timestamp) => {
  if (!timestamp) return '';
  const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
  return date.toLocaleDateString('da-DK', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });
};

const formatAmount = (amount) => {
  if (!amount) return '0,00 kr';
  return new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

const formatStatus = (status) => {
  const statusMap = {
    'betalt': 'Betalt',
    'udstedt': 'Udstedt',
    'forfalden': 'Forfalden',
    'krediteret': 'Krediteret',
    'credit': 'Kreditnota',
    'delvist_krediteret': 'Delvist Krediteret'
  };
  return statusMap[status] || status;
};

// Download funktion
const downloadInvoice = async () => {
  try {
    const element = document.querySelector('.invoice-modal-content');
    
    // Gem de oprindelige styles
    const originalPosition = element.style.position;
    const originalMargin = element.style.margin;
    
    // Find alle elementer der skal skjules
    const hideElements = element.querySelectorAll('.hide-in-pdf');
    hideElements.forEach(el => el.style.display = 'none');
    
    // Juster styles midlertidigt for PDF generering
    element.style.position = 'relative';
    element.style.margin = '0';

    const options = {
      filename: `faktura-${selectedInvoice.value.invoiceNumber}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { 
        scale: 2,
        useCORS: true,
        letterRendering: true
      },
      jsPDF: { 
        unit: 'mm', 
        format: 'a4', 
        orientation: 'portrait'
      }
    };

    await html2pdf().set(options).from(element).save();
    
    // Gendan synlighed af skjulte elementer
    hideElements.forEach(el => el.style.display = '');
    
    // Gendan oprindelige styles
    element.style.position = originalPosition;
    element.style.margin = originalMargin;
    
  } catch (error) {
    console.error('Fejl ved generering af PDF:', error);
    alert('Der opstod en fejl ved generering af PDF. Prøv venligst igen.');
  }
};

// Vis faktura detaljer
const showInvoiceDetails = (invoice) => {
  selectedInvoice.value = invoice;
};

// Opdater computed properties
const companyInfo = computed(() => {
  if (!selectedInvoice.value?.companyInfo) {
    return {
      companyName: 'MakeMore ApS',
      address: 'Birkevej 4',
      postalCode: '5492',
      city: 'Vissenbjerg',
      cvr: '45454545',
      phone: '+45 31 39 31 94',
      email: 'we@makemore.dk',
      bankReg: '9277',
      bankAccount: '1910555738'
    };
  }
  return selectedInvoice.value.companyInfo;
});

const footerText = computed(() => {
  return selectedInvoice.value?.footerText || 
    `${companyInfo.value.companyName} / ${companyInfo.value.address} / ${companyInfo.value.postalCode} ${companyInfo.value.city}`;
});

// Tilføj denne computed property for at formatere beløb korrekt i fakturavisningen
const displayAmount = computed(() => {
  if (!selectedInvoice.value) return '0,00';
  
  let amount = selectedInvoice.value.total;
  
  // Hvis det er en kreditnota, vis beløbet som positivt
  if (selectedInvoice.value.type === 'credit') {
    amount = Math.abs(amount);
  }
  
  // Hvis fakturaen er delvist krediteret, vis det resterende beløb
  if (selectedInvoice.value.partiallyCredited && !selectedInvoice.value.type) {
    amount = selectedInvoice.value.remainingAmount;
  }
  
  return formatAmount(amount);
});
</script>

<style scoped>
.card-stats {
  border-radius: 10px;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, .15);
}

.icon-shape {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table th {
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #8898aa;
  padding: 12px 15px;
}

.table td {
  padding: 12px 15px;
  vertical-align: middle;
  color: #ffffff !important;
  font-size: 0.8125rem;
}

h3 {
  font-size: 0.875rem;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 0.25rem;
}

h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #ffffff;
}

.text-danger {
  color: #f5365c !important;
}

.text-success {
  color: #2dce89 !important;
}

.alert-info {
  background: linear-gradient(87deg, #11cdef 0, #1171ef 100%) !important;
  border: none;
  color: white;
}

.badge {
  padding: 5px 10px;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
}

.bg-success {
  background-color: #2dce89 !important;
}

.bg-warning {
  background-color: #fb6340 !important;
}

.bg-danger {
  background-color: #f5365c !important;
}

.invoice-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  padding: 20px;
}

.invoice-modal-content {
  background: white;
  width: 210mm; /* A4 bredde */
  min-height: 297mm; /* A4 højde */
  padding: 0;
  margin: 20px auto;
  box-shadow: 0 0 20px rgba(0,0,0,0.15);
  color: #ffffff !important;
}

.invoice-header {
  background: #f8f9fa;
  padding: 20px 40px;
  border-bottom: 2px solid #eee;
}

.invoice-body {
  padding: 40px;
  background: white;
  color: #ffffff !important;
}

.invoice-metadata {
  margin-bottom: 30px;
}

.invoice-number {
  font-size: 24px;
  color: #ffffff !important;
  font-weight: 600;
}

.party-info {
  margin-bottom: 30px;
}

.info-label {
  color: #ffffff !important;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 10px;
}

.company-details,
.customer-details {
  color: #ffffff !important;
  
  h5 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #ffffff !important;
  }
  
  p {
    margin-bottom: 5px;
    font-size: 14px;
    color: #ffffff !important;
  }
}

.invoice-items-table {
  margin: 30px 0;
  
  .table {
    border: 1px solid #eee;
    
    thead th {
      background: #344767;
      color: white !important;
      font-size: 14px;
      padding: 12px;
    }
    
    tbody td {
      padding: 12px;
      vertical-align: middle;
      color: #ffffff !important;
    }
    
    tfoot {
      tr:last-child {
        background: #f8f9fa;
        font-weight: 600;
        td {
          color: #ffffff !important;
        }
      }
    }
  }
}

.status-badge {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  
  &.status-paid {
    background: #dcf5e8;
    color: #2dce89;
  }
  
  &.status-pending {
    background: #ffecd9;
    color: #fb6340;
  }
  
  &.status-overdue {
    background: #ffdde4;
    color: #f5365c;
  }
}

.payment-info {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  
  h6 {
    color: #ffffff !important;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 13px;
  }
  
  p {
    margin-bottom: 5px;
    font-size: 12px;
    color: #ffffff !important;
    line-height: 1.4;
  }

  .small {
    font-size: 11px;
    color: #666666 !important;
  }
}

.invoice-footer {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  
  p {
    font-size: 12px;
    color: #ffffff !important;
    line-height: 1.4;
  }
}

/* Print styles */
@media print {
  .invoice-modal-content {
    width: 210mm;
    height: 297mm;
    margin: 0;
    padding: 0;
    box-shadow: none;
  }
  
  .close-btn,
  .download-btn {
    display: none;
  }
}

/* Opdater/tilføj disse styles i style section */

/* Grundlæggende tekst farver */
.invoice-modal-content {
  * {
    color: #ffffff !important;
  }
}

/* Header styles */
.invoice-header {
  h3, h4, h5, h6 {
    color: #ffffff !important;
  }
}

/* Table styles */
.table {
  th {
    color: white !important;
  }
  
  td, 
  tfoot td {
    color: #ffffff !important;
  }

  .text-end {
    color: #ffffff !important;
  }
}

/* Status badge undtagelser */
.status-badge {
  &.status-paid {
    color: #2dce89 !important;
  }
  
  &.status-pending {
    color: #fb6340 !important;
  }
  
  &.status-overdue {
    color: #f5365c !important;
  }
}

/* Metadata og datoer */
.date-info {
  color: #ffffff !important;
  
  strong, p {
    color: #ffffff !important;
  }
}

/* Betalingsinfo */
.payment-details {
  * {
    color: #ffffff !important;
  }
}

/* Faktura totaler */
.invoice-items-table {
  tfoot {
    td, 
    strong,
    .text-end {
      color: #ffffff !important;
    }
  }
}

/* Faktura header */
.company-logo {
  h3 {
    color: #ffffff !important;
  }
}

/* Generelle text styles */
.text-sm,
.text-muted,
strong,
p,
h1, h2, h3, h4, h5, h6 {
  color: #ffffff !important;
}

/* Undtagelser hvor vi vil beholde specifikke farver */
.close-btn {
  color: #344767 !important;
}

.table thead th {
  color: white !important;
  background: #344767;
}

/* Sikre at alle amounts er sorte */
[class*="text-end"] {
  color: #ffffff !important;
}

/* Override for specifik tekst i fakturaen */
.invoice-body {
  .text-end,
  .text-center,
  .text-start {
    color: #ffffff !important;
  }
}

/* Betalingsinformation sektion */
.payment-info {
  background: #f8f9fa;
  
  h6, p {
    color: #ffffff !important;
  }
}

/* Footer tekst */
.invoice-footer {
  p {
    color: #ffffff !important;
  }
}

/* Opdater table styles */

/* Styling for den ydre tabel (fakturalisten) */
.table-responsive .table {
  td, 
  tfoot td {
    color: #ffffff !important;
  }
}

/* Styling for fakturamodalen */
.invoice-modal-content .table {
  td, 
  tfoot td {
    color: #ffffff !important;
  }
}

/* Eller endnu mere specifikt */
.invoice-items-table .table {
  td, 
  tfoot td {
    color: #ffffff !important;
  }
}

/* Behold resten af dine styles som de er, men tilføj denne specificity */
.table-responsive {
  .table {
    td {
      color: #ffffff !important;
    }
    
    th {
      color: #ffffff !important;
    }
  }
}

/* Og vær specifik med invoice tabellen */
.invoice-modal {
  .table {
    td, th {
      color: #ffffff !important;
    }
    
    thead th {
      color: #ffffff !important;
    }
  }
}

/* PDF print styles */
@media print {
  .invoice-modal-content {
    margin: 0;
    padding: 0;
    width: 210mm;
    min-height: 297mm;
    page-break-after: always;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .invoice-header {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .table thead th {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    background-color: #344767 !important;
    color: white !important;
  }

  .close-btn,
  .download-btn {
    display: none !important;
  }
}

/* Tilføj denne class for at sikre god PDF rendering */
.invoice-modal-content {
  background: white;
  box-sizing: border-box;
}

/* PDF print styles */
@media print {
  .hide-in-pdf {
    display: none !important;
  }
  
  /* ... resten af dine print styles ... */
}

/* Tilføj denne style for at sikre at elementer kan skjules korrekt */
.hide-in-pdf {
  display: block;
}

/* Tilføj styling for nye status badges */
.badge.bg-info {
  background-color: #11cdef !important;
  color: white;
}

.badge.bg-secondary {
  background-color: #8898aa !important;
  color: white;
}

/* Tilføj styling for krediteret beløb */
.credited-amount {
  color: #11cdef;
  font-weight: 600;
}

/* Styling for kreditnota alerts */
.alert {
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.alert-info {
  background-color: rgba(17, 205, 239, 0.1);
  border: 1px solid rgba(17, 205, 239, 0.2);
  color: #11cdef;
}

.alert-warning {
  background-color: rgba(251, 99, 64, 0.1);
  border: 1px solid rgba(251, 99, 64, 0.2);
  color: #fb6340;
}

.alert h6 {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

/* Badge spacing */
.badge + .badge {
  margin-left: 0.5rem;
}

/* Kreditnota badge */
.badge.bg-info {
  background-color: #11cdef !important;
}

/* Delvist krediteret badge */
.badge.bg-warning {
  background-color: #fb6340 !important;
}

.credit-note-info {
  border: 1px solid #6c757d;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.credit-note-info h6 {
  color: #6c757d;
  font-weight: 600;
}

.invoice-number {
  font-size: 1.5rem;
  font-weight: 600;
  color: #000000;
}

.text-danger {
  color: #dc3545 !important;
}

/* Tilføj styling for detaljer under beskrivelse */
.text-muted.small {
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

/* Kreditnota specifik styling */
.invoice-modal-content[data-type="credit"] {
  border: 2px solid #6c757d;
}

.alert-danger {
  background-color: rgba(220, 53, 69, 0.1);
  border: 1px solid rgba(220, 53, 69, 0.2);
  color: #dc3545;
  border-radius: 8px;
  padding: 1rem;
}

.invoice-metadata,
.invoice-parties,
.invoice-items-table,
.payment-info {
  margin-bottom: 2rem;
}

/* Forbedret tabel styling */
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}

.table th {
  background-color: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
}

.total-row {
  font-weight: 600;
  background-color: #f8f9fa;
}

/* Kreditnota specifik styling */
.text-credit {
  color: #dc3545 !important;
}

.alert-credit {
  background-color: rgba(220, 53, 69, 0.1);
  border: 1px solid rgba(220, 53, 69, 0.2);
  color: #dc3545;
  border-radius: 8px;
  padding: 1rem;
}

.credit-note-info {
  margin-bottom: 2rem;
}

.credit-note-info h6 {
  color: #dc3545;
  font-weight: 600;
}
</style> 