<template>
  <div v-if="isVisible" class="modal-overlay" @click="$emit('click-outside')">
    <div class="modal-container" @click.stop>
      <div class="modal-header">
        <h3 class="modal-title">{{ isEditing ? 'Rediger Design' : 'Opret Design' }}</h3>
        <argon-button @click="closeModal" class="close-button" variant="text" color="secondary">
          <i class="fas fa-times"></i>
        </argon-button>
      </div>
      
      <form @submit.prevent="saveDesign" class="modal-form">
        <!-- UUID Sektion -->
        <div class="form-section">
          <div class="form-group">
            <label for="uuid" class="required">UUID</label>
            <input 
              type="text" 
              id="uuid" 
              v-model="localDesign.uuid" 
              placeholder="Indtast UUID"
              class="form-input"
              required
            >
          </div>
        </div>

        <!-- Primære Indstillinger -->
        <div class="form-section">
          <h4 class="section-title">Primære Indstillinger</h4>
          
          <div class="form-group switch-container">
            <div class="switch-item">
              <label>Kan redigeres</label>
              <argon-switch 
                :checked="localDesign.canEdit"
                @change="toggleSetting('canEdit', $event)"
              />
            </div>
            
            <div class="switch-item">
              <label>Er en PDF</label>
              <argon-switch 
                :checked="localDesign.isPDF"
                @change="toggleSetting('isPDF', $event)"
              />
            </div>
          </div>
        </div>

        <!-- Kanaler og Format -->
        <div class="form-section grid-section">
          <div class="form-group">
            <label>Kanaler</label>
            <div class="tag-group">
              <label 
                v-for="channel in channels" 
                :key="channel"
                :class="['tag-checkbox', { active: localDesign.channels.includes(channel) }]"
              >
                <input 
                  type="checkbox" 
                  :value="channel" 
                  v-model="localDesign.channels"
                >
                {{ channel }}
              </label>
            </div>
          </div>
          
          <div class="form-group">
            <label>Format</label>
            <div class="tag-group">
              <label 
                v-for="formatOption in formats" 
                :key="formatOption"
                :class="['tag-checkbox', { active: localDesign.format.includes(formatOption) }]"
              >
                <input 
                  type="checkbox" 
                  :value="formatOption" 
                  v-model="localDesign.format"
                >
                {{ formatOption }}
              </label>
            </div>
          </div>
        </div>

        <!-- Grupper -->
        <div class="form-section">
          <div class="form-group">
            <label>Grupper</label>
            <div class="tag-group">
              <label 
                v-for="group in designGroups" 
                :key="group.id"
                :class="['tag-checkbox', { active: localDesign.groups.includes(group.name) }]"
              >
                <input 
                  type="checkbox" 
                  :value="group.name" 
                  v-model="localDesign.groups"
                >
                {{ group.name }}
              </label>
            </div>
          </div>
        </div>

        <!-- Upload Sektion -->
        <div class="form-section">
          <div class="form-group upload-container" 
               @dragover.prevent="handleDragOver"
               @dragleave.prevent="handleDragLeave"
               @drop.prevent="handleDrop"
               :class="{ 'dragging': isDragging }"
          >
            <label for="fileUpload" class="upload-label">
              <i class="fas fa-cloud-upload-alt"></i>
              <span>Upload Design</span>
              <small>Træk filer hertil eller klik for at vælge</small>
            </label>
            <input 
              type="file" 
              id="fileUpload" 
              @change="handleFileUpload" 
              accept="image/*,video/*"
              class="file-input"
            >
            
            <div v-if="uploadProgress" class="upload-progress">
              <div class="progress-info">
                <span>Upload status</span>
                <span>{{ uploadProgress.toFixed(0) }}%</span>
              </div>
              <div class="progress-bar-container">
                <div class="progress-bar" :style="{ width: `${uploadProgress}%` }"></div>
              </div>
            </div>

            <div v-if="localDesign.file" class="file-preview">
              <div class="file-info">
                <i class="fas fa-file"></i>
                <span>{{ getFileName(localDesign.file) }}</span>
                <button @click.prevent="removeFile" class="remove-file">
                  <i class="fas fa-times"></i>
                </button>
              </div>
              <img v-if="isImageFile(localDesign.file)" :src="localDesign.file" alt="Preview" class="preview-image">
              <video v-else-if="isVideoFile(localDesign.file)" controls class="preview-video">
                <source :src="localDesign.file" type="video/mp4">
                Din browser understøtter ikke video-elementet.
              </video>
            </div>
          </div>
        </div>

        <!-- Emner -->
        <div class="form-section">
          <div class="form-group">
            <label>Emner</label>
            <div class="tag-group">
              <label 
                v-for="emne in emner" 
                :key="emne"
                :class="['tag-checkbox', { active: localDesign.emner.includes(emne) }]"
              >
                <input 
                  type="checkbox" 
                  :value="emne" 
                  v-model="localDesign.emner"
                >
                {{ emne }}
              </label>
            </div>
          </div>
        </div>

        <!-- Tildeling -->
        <div class="form-section">
          <h4 class="section-title">Tildeling af Design</h4>
          <div class="assignment-options">
            <div class="radio-group">
              <label class="radio-label">
                <input 
                  type="radio" 
                  v-model="assignmentType" 
                  value="default"
                  @change="updateAssignment"
                >
                <span class="radio-text">System Default (Tilgængelig for alle)</span>
              </label>
              
              <label class="radio-label">
                <input 
                  type="radio" 
                  v-model="assignmentType" 
                  value="admin"
                  @change="updateAssignment"
                >
                <span class="radio-text">Tildel til Admin</span>
              </label>
              
              <label class="radio-label">
                <input 
                  type="radio" 
                  v-model="assignmentType" 
                  value="user"
                  @change="updateAssignment"
                >
                <span class="radio-text">Tildel til Specifik Bruger</span>
              </label>
            </div>

            <div v-if="assignmentType === 'admin'" class="select-container">
              <select 
                v-model="selectedAssigneeId"
                @change="updateAssignment"
                class="form-select"
              >
                <option value="" disabled selected style="color: rgba(255, 255, 255, 0.7);">Vælg Admin</option>
                <option v-for="admin in admins" :key="admin.id" :value="admin.id">
                  {{ admin.name || admin.email }}
                </option>
              </select>
            </div>

            <div v-if="assignmentType === 'user'" class="select-container">
              <select 
                v-model="selectedAssigneeId"
                @change="updateAssignment"
                class="form-select"
              >
                <option value="" disabled selected style="color: rgba(255, 255, 255, 0.7);">Vælg Bruger</option>
                <option v-for="user in users" :key="user.id" :value="user.id">
                  {{ user.name || user.email }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- Knapper -->
        <div class="form-actions">
          <argon-button @click="closeModal" color="secondary" class="action-button">
            <i class="fas fa-times"></i> Annuller
          </argon-button>
          <argon-button type="submit" color="success" class="action-button">
            <i class="fas fa-save"></i> {{ isEditing ? 'Opdater design' : 'Opret design' }}
          </argon-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, reactive } from 'vue';
import { collection, addDoc, updateDoc, doc, getDocs } from 'firebase/firestore';
import { db } from '@/firebase';
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import { uploadFile } from "../../../services/uploadeService.js";

const props = defineProps({
  isVisible: Boolean,
  designToEdit: Object
});

const emit = defineEmits(['close', 'save', 'click-outside']);

const isEditing = ref(false);
const localDesign = reactive({
  uuid: '',
  canEdit: false,
  isPDF: false,
  type: 'statisk',
  channels: [],
  format: [],
  file: null,
  groups: [],
  createdAt: null,
  updatedAt: null,
  emner: [],
  isDefault: true,
  assignedTo: {
    type: null,
    id: null
  }
});

const designGroups = ref([]);
const users = ref([]);
const admins = ref([]);
const assignmentType = ref('default'); // 'default', 'admin', eller 'user'
const selectedAssigneeId = ref('');

const channels = ['Facebook', 'Instagram', 'LinkedIn', 'Stories', 'Print'];
const formats = ['1:1', '16:9', '4:3', '4:5', '2:3', 'A4', 'A5'];

const uploadProgress = ref(null);

const emner = [
  'Nyhed', 'Ny bil på lager', 'Tilbud', 'Prisjustering', 'Dagens bil',
  'Ugens bil', 'Netop solgt', 'Eftertragtet model', 'Skarp pris'
];

const isDragging = ref(false);

onMounted(() => {
  fetchDesignGroups();
  fetchUsersAndAdmins();
});

const fetchDesignGroups = async () => {
  const querySnapshot = await getDocs(collection(db, 'designGroup'));
  designGroups.value = querySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }));
};

const fetchUsersAndAdmins = async () => {
  try {
    const usersSnapshot = await getDocs(collection(db, 'users'));
    users.value = usersSnapshot.docs
      .filter(doc => doc.data().role === 3) // Kun rolle 3 (users)
      .map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    
    admins.value = usersSnapshot.docs
      .filter(doc => doc.data().role === 2) // Kun rolle 2 (admins)
      .map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
  } catch (error) {
    console.error('Fejl ved hentning af brugere:', error);
  }
};

const toggleSetting = async (settingKey, event) => {
  const settingValue = event.target.checked;
  console.log(`Toggling ${settingKey} to ${settingValue}`);

  localDesign[settingKey] = settingValue;
};

watch(() => props.designToEdit, (newValue) => {
  if (newValue) {
    Object.assign(localDesign, JSON.parse(JSON.stringify(newValue)));
    isEditing.value = true;
    
    if (newValue.isDefault) {
      assignmentType.value = 'default';
    } else if (newValue.assignedTo) {
      assignmentType.value = newValue.assignedTo.type;
      selectedAssigneeId.value = newValue.assignedTo.id;
    }
  } else {
    Object.assign(localDesign, {
      uuid: '',
      canEdit: false,
      isPDF: false,
      type: 'statisk',
      channels: [],
      format: [],
      file: null,
      groups: [],
      createdAt: null,
      updatedAt: null,
      emner: [],
      isDefault: true,
      assignedTo: {
        type: null,
        id: null
      }
    });
    isEditing.value = false;
    
    assignmentType.value = 'default';
    selectedAssigneeId.value = null;
  }
}, { immediate: true, deep: true });

const isImageFile = (url) => {
  return url && /\.(jpg|jpeg|png|gif|webp)$/i.test(url);
};

const isVideoFile = (url) => {
  return url && /\.(mp4|webm|ogg|mov)$/i.test(url);
};

const handleFileUpload = async (event) => {
  const file = event.target.files[0];
  if (!file) return;

  // Tjek filtype
  const fileType = file.type;
  if (!fileType.startsWith('image/') && !fileType.startsWith('video/')) {
    alert('Kun billed- og videofiler er tilladt');
    return;
  }

  try {
    uploadProgress.value = 0;
    const downloadURL = await uploadFile(file, "designFiles", (progress) => {
      uploadProgress.value = progress;
    });
    localDesign.file = downloadURL;
  } catch (error) {
    console.error(`Fejl ved upload af fil ${file.name}:`, error);
    alert(`Fejl ved upload af fil ${file.name}: ${error.message}`);
  } finally {
    uploadProgress.value = null;
  }
};

const saveDesign = async () => {
  console.log('Design data før gemning:', localDesign);
  try {
    const designData = { ...localDesign };
    
    if (!isEditing.value) {
      designData.createdAt = new Date();
    }
    designData.updatedAt = new Date();

    Object.keys(designData).forEach(key => 
      designData[key] === undefined && delete designData[key]
    );

    emit('save', designData);
  } catch (error) {
    console.error('Fejl ved gemning af design:', error);
    alert('Der opstod en fejl ved gemning af designet. Prøv venligst igen.');
  }
};

const closeModal = () => {
  emit('close');
};

// Opdater watch for at nulstille emner, når typen ændres
watch(() => localDesign.type, (newType) => {
  if (newType !== 'statisk') {
    localDesign.emner = [];
  }
});

const updateAssignment = () => {
  if (assignmentType.value === 'default') {
    localDesign.isDefault = true;
    localDesign.assignedTo = {
      type: null,
      id: null
    };
  } else {
    localDesign.isDefault = false;
    localDesign.assignedTo = {
      type: assignmentType.value,
      id: selectedAssigneeId.value
    };
  }
};

const handleDragOver = (event) => {
  isDragging.value = true;
  event.dataTransfer.dropEffect = 'copy';
};

const handleDragLeave = () => {
  isDragging.value = false;
};

const handleDrop = (event) => {
  isDragging.value = false;
  const files = event.dataTransfer.files;
  if (files.length > 0) {
    handleFileUpload({ target: { files: [files[0]] } });
  }
};

const getFileName = (url) => {
  if (!url) return '';
  return url.split('/').pop().split('?')[0];
};

const removeFile = () => {
  localDesign.file = null;
  uploadProgress.value = null;
};

</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: #1a1a1a;
  border-radius: 12px;
  padding: 24px;
  width: 95%;
  max-width: 700px;
  max-height: 90vh;
  overflow-y: auto;
  color: white;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
}

.form-section {
  margin-bottom: 24px;
  padding: 16px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}

.section-title {
  font-size: 1.1rem;
  margin-bottom: 16px;
  color: #5e72e4;
}

.form-group {
  margin-bottom: 16px;
}

.form-input {
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #2c2c2c;
  color: white;
  transition: all 0.3s ease;
}

.form-input:focus {
  border-color: #5e72e4;
  box-shadow: 0 0 0 2px rgba(94, 114, 228, 0.2);
}

.switch-container {
  display: flex;
  gap: 24px;
}

.switch-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.tag-group {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag-checkbox {
  padding: 8px 16px;
  border-radius: 20px;
  background-color: #2c2c2c;
  cursor: pointer;
  transition: all 0.3s ease;
  user-select: none;
}

.tag-checkbox input {
  display: none;
}

.tag-checkbox.active {
  background-color: #5e72e4;
  color: white;
}

.upload-container {
  border: 2px dashed rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 24px;
  text-align: center;
  transition: all 0.3s ease;
}

.upload-container.dragging {
  border-color: #5e72e4;
  background-color: rgba(94, 114, 228, 0.1);
}

.upload-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.upload-label i {
  font-size: 2rem;
  color: #5e72e4;
}

.file-input {
  display: none;
}

.progress-bar-container {
  width: 100%;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  overflow: hidden;
  margin-top: 8px;
}

.progress-bar {
  height: 100%;
  background-color: #5e72e4;
  transition: width 0.3s ease;
}

.file-preview {
  margin-top: 16px;
  border-radius: 8px;
  overflow: hidden;
}

.preview-image,
.preview-video {
  max-width: 100%;
  border-radius: 8px;
}

.form-actions {
  display: flex;
  gap: 16px;
  margin-top: 24px;
}

.action-button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.radio-label {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  cursor: pointer;
}

.select-container {
  margin-top: 12px;
  position: relative;
}

.select-container::after {
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #5e72e4;
  pointer-events: none;
}

.form-select {
  width: 100%;
  padding: 12px;
  padding-right: 32px;
  border-radius: 6px;
  background-color: #2c2c2c;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.2);
  appearance: none;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
}

.form-select option {
  background-color: #2c2c2c;
  color: white;
  padding: 12px;
}

.form-select option:first-child {
  color: rgba(255, 255, 255, 0.7);
}

/* Når en værdi er valgt */
.form-select:not([value=""]) {
  color: white;
}

.required::after {
  content: '*';
  color: #ff5252;
  margin-left: 4px;
}

/* Responsive Grid */
.grid-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
}

/* Scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #1a1a1a;
}

::-webkit-scrollbar-thumb {
  background: #5e72e4;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .modal-container {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    border-radius: 0;
  }
  
  .switch-container {
    flex-direction: column;
    gap: 16px;
  }
  
  .form-actions {
    flex-direction: column;
  }
}

.file-info {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  margin-bottom: 8px;
}

.file-info i {
  color: #5e72e4;
}

.remove-file {
  margin-left: auto;
  background: none;
  border: none;
  color: #ff5252;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.remove-file:hover {
  background-color: rgba(255, 82, 82, 0.1);
}
</style>
