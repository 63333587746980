<template>
  <div class="modal-content">
    <div class="main-content">
      <div class="left-side">
        <div class="image-gallery">
          <!-- Display the main image -->
          <div v-if="selectedImage" class="main-image-container">
            <video 
              v-if="hasVideo"
              controls 
              :src="selectedImage"
              class="main-image"
            >
              Din browser understøtter ikke video-afspilning.
            </video>
            <img
              v-else
              :src="selectedImage"
              alt="Main Event Image"
              class="main-image"
            />
            <button class="expand-btn" @click.stop="openModal(selectedImage)">
              <i class="fas fa-expand"></i>
            </button>
          </div>

          <!-- Gallery for thumbnails with addresses -->
          <div v-if="mediaFilesState.length > 1" class="gallery-container">
            <div class="thumbnails-wrapper">
              <!-- Display first row of thumbnails (always 5 slots) -->
              <template v-for="index in 5" :key="'first-row-' + index">
                <div
                  v-if="mediaFilesState[index - 1]"
                  class="thumbnail-container"
                  :class="{ selected: mediaFilesState[index - 1] === selectedImage }"
                >
                  <div
                    class="thumbnail-overlay"
                    @click="selectImage(mediaFilesState[index - 1])"
                  ></div>
                  <img
                    :src="mediaFilesState[index - 1]"
                    alt="Thumbnail Image"
                    class="thumbnail-image"
                    @click="selectImage(mediaFilesState[index - 1])"
                  />
                  <!-- Delete Icon -->
                  <button
                    class="delete-icon"
                    @click.prevent="deleteThumbnail(index - 1)"
                    :disabled="mediaFilesState.length <= 3"
                    :title="
                      mediaFilesState.length <= 3
                        ? 'Kan ikke slette. Minimum 3 thumbnails påkrævet.'
                        : 'Slet Thumbnail'
                    "
                    aria-label="Slet Thumbnail"
                  >
                    ✕
                  </button>
                </div>
                <div v-else class="thumbnail-container empty"></div>
              </template>
            </div>
            
            <!-- Additional thumbnails if more than 5 -->
            <div v-if="mediaFilesState.length > 5" class="thumbnails-wrapper">
              <template v-for="index in 5" :key="'second-row-' + index">
                <div
                  v-if="mediaFilesState[index + 4]"
                  class="thumbnail-container"
                  :class="{ selected: mediaFilesState[index + 4] === selectedImage }"
                >
                  <div
                    class="thumbnail-overlay"
                    @click="selectImage(mediaFilesState[index + 4])"
                  ></div>
                  <img
                    :src="mediaFilesState[index + 4]"
                    alt="Thumbnail Image"
                    class="thumbnail-image"
                    @click="selectImage(mediaFilesState[index + 4])"
                  />
                  <!-- Delete Icon -->
                  <button
                    class="delete-icon"
                    @click.prevent="deleteThumbnail(index + 4)"
                    :disabled="mediaFilesState.length <= 3"
                    :title="
                      mediaFilesState.length <= 3
                        ? 'Kan ikke slette. Minimum 3 thumbnails påkrævet.'
                        : 'Slet Thumbnail'
                    "
                    aria-label="Slet Thumbnail"
                  >
                    ✕
                  </button>
                </div>
                <div v-else class="thumbnail-container empty"></div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="description-container">
        <textarea
          v-model="descriptionText"
          class="description-textarea"
          placeholder="Skriv beskrivelse her..."
        ></textarea>
      </div>
    </div>

    <!-- Text action buttons lige under main-content -->
    <div v-if="!isPostSend" class="text-action-buttons">
      <button 
        @click="fetchPreview" 
        class="btn btn-outline-primary"
      >
        <i class="fas fa-eye"></i>
        <strong>Preview</strong>
      </button>
      <button 
        @click="optimizeText"
        class="btn btn-outline-primary"
        :disabled="isLoading"
      >
        <i class="fas fa-magic"></i>
        <span v-if="!isLoading">Optimér tekst</span>
        <span v-else class="loading-spinner"></span>
      </button>
    </div>

    <!-- Eksisterende bottom controls uden preview knap -->
    <div class="bottom-controls">
      <div v-if="!isPostSend" class="datetime-picker-container">
        <div class="picker">
          <label for="publish-date-picker" class="picker-label">Udgivelsesdato:</label>
          <input
            type="text"
            id="publish-date-picker"
            class="datepicker-input"
            :value="publishDate ? publishDate.toISOString().split('T')[0] : ''"
            placeholder="Vælg dato"
          />
        </div>

        <div class="picker">
          <label for="publish-time-picker" class="picker-label">Udgivelsestid:</label>
          <input
            type="text"
            id="publish-time-picker"
            class="timepicker-input"
            :value="publishTime ? `${publishTime.getHours().toString().padStart(2, '0')}:${publishTime.getMinutes().toString().padStart(2, '0')}` : ''"
            placeholder="Vælg tid"
          />
        </div>
      </div>
      <div class="button-container">
        <template v-if="!isPostSend">
          <button @click="showRepostModal" class="btn btn-outline-info">
            <strong>Re-post</strong>
          </button>
          <button @click="savePost" class="btn btn-outline-success">
            <strong>Gem</strong>
          </button>
          <button @click="deletePost" class="btn btn-outline-danger">
            <strong>Slet</strong>
          </button>
        </template>
        
        <template v-else>
          <button 
            v-if="eventData.facebookLink" 
            class="btn btn-outline-primary" 
            @click="openSocialLink(eventData.facebookLink)"
          >
            <i class="fab fa-facebook-f mr-2"></i>
            <strong style="color: #ffffff;">Se Facebook opslag</strong>
          </button>
          
          <button 
            v-if="eventData.instagramLink" 
            class="btn btn-outline-info" 
            @click="openSocialLink(eventData.instagramLink)"
          >
            <i class="fab fa-instagram mr-2"></i>
            <strong>Se Instagram opslag</strong>
          </button>
          
          <button class="btn btn-outline-danger" @click="deletePostSend">
            <strong>Slet</strong>
          </button>
        </template>
      </div>
    </div>
  </div>
  <Notifications 
    :message="notificationMessage" 
    :show="showNotification"
    :title="notificationTitle"
    :color="notificationColor"
    @update:show="showNotification = $event"
  />
  <div v-if="showModal" class="modal-overlay" @click="closeModal">
    <div class="modal-content-preview" @click.stop>
      <button class="modal-close" @click="closeModal">×</button>
      <div class="modal-media-container">
        <video 
          v-if="isVideoFile(selectedModalImage)"
          controls 
          :src="selectedModalImage"
          class="modal-media video"
          autoplay
        >
          Din browser understøtter ikke video-afspilning.
        </video>
        <img
          v-else
          :src="selectedModalImage"
          alt=""
          class="modal-media image"
        >
      </div>
    </div>
  </div>

  <!-- Tilføj placeholder information sektion -->
  <div v-if="eventData?.isPlaceholder" class="placeholder-info">
    <h4 class="placeholder-title">Placeholder Information</h4>
    <div class="info-grid">
      <div class="info-item">
        <span class="info-label">Rotation nummer:</span>
        <span class="info-value">{{ eventData.rotationNumber }}</span>
      </div>
      <div class="info-item">
        <span class="info-label">Dage til salg:</span>
        <span class="info-value">{{ eventData.daysForSale }} dage</span>
      </div>
      <div class="info-item">
        <span class="info-label">Rotations interval:</span>
        <span class="info-value">{{ eventData.usedInterval }} dage</span>
      </div>
      <div class="info-item">
        <span class="info-label">Original oprettet:</span>
        <span class="info-value">{{ formatDate(eventData.originalCreatedDate) }}</span>
      </div>
      <div class="info-item">
        <span class="info-label">Rotations dato:</span>
        <span class="info-value">{{ formatDate(eventData.rotationDate) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, nextTick } from "vue";
import axios from "axios";
import Swal from "sweetalert2";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  addDoc,
  collection,
  writeBatch,
} from "firebase/firestore";
import config from "../../../../functions/config.cjs";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { getAuth } from 'firebase/auth';
import { useStore } from 'vuex';
import Notifications from './Notifications.vue';
import { correctEventText } from '@/services/correctText';
import { deleteFacebookPost } from '@/services/facebookService';

export default {
  name: "EventDetailsModal",
  components: {
    Notifications
  },
  props: {
    mediaFiles: {
      type: Array,
      default: () => [],
    },
    addresses: {
      type: Array,
      default: () => [],
    },
    description: {
      type: String,
      default: "No description available",
    },
    eventId: {
      type: String,
      required: true,
    },
    collection: {
      type: String,
      default: 'posts'
    },
    eventData: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['postDeleted'], // Deklarer den event, du vil emitte
  setup(props, { emit }) { // Tilføj emit til setup konteksten
    const selectedImage = ref(props.mediaFiles[0] || null);
    const descriptionText = ref(props.description);
    const showModal = ref(false);
    const selectedModalImage = ref('');

    // Flyt isVideoFile til en computed property
    const isVideoFile = (filename) => {
      if (!filename) return false;
      return filename.toLowerCase().endsWith('.mp4') || 
             filename.toLowerCase().endsWith('.mov');
    };

    const hasVideo = computed(() => {
      return isVideoFile(selectedImage.value);
    });

    const openModal = (image) => {
      selectedModalImage.value = image;
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
      selectedModalImage.value = '';
    };

    // Reactive kopier af arrays for at tillade opdateringer
    const mediaFilesState = ref([...props.mediaFiles]);
    const addressesState = ref([...props.addresses]);

    // Initialize reactive arrays for children
    const children = ref([]);

    const store = useStore();
    
    const userData = ref({
      name: 'Din Virksomhed',
      userSettings: {
        profileImage: 'https://via.placeholder.com/40'
      }
    });

    const selectImage = (image) => {
      selectedImage.value = image;
    };

    const splitAddress = (address) => {
      return address ? address.split(",")[0] : "";
    };

    // Function to delete a thumbnail
    const deleteThumbnail = async (index) => {
      if (mediaFilesState.value.length <= 3) {
        alert("Du skal have mindst 3 thumbnails.");
        return;
      }

      const confirmDelete = confirm(
        "Er du sikker på, at du vil slette denne thumbnail?",
      );
      if (!confirmDelete) return;

      try {
        const db = getFirestore();
        const docRef = doc(db, "posts", props.eventId);

        // Fjern elementerne ved den givne index fra mediaFilesState og addressesState
        mediaFilesState.value.splice(index, 1);
        addressesState.value.splice(index, 1);

        // Også opdatér children array
        // Forudsætter at hvert barn svarer til en thumbnail
        children.value.splice(index, 1);

        // Opdater dokumentet i Firebase
        await updateDoc(docRef, {
          children: children.value,
        });

        // Justér thumbnailStartIndex hvis nødvendigt
        if (
          thumbnailStartIndex.value >= mediaFilesState.value.length &&
          thumbnailStartIndex.value > 0
        ) {
          thumbnailStartIndex.value -= thumbnailsPerPage;
        }

        // Opdatér selected image hvis nødvendigt
        if (!mediaFilesState.value.includes(selectedImage.value)) {
          selectedImage.value = mediaFilesState.value[0] || null;
        }

        alert("Thumbnail slettet succesfuldt.");
      } catch (error) {
        console.error("Fejl ved sletning af thumbnail:", error);
        alert("Kunne ikke slette thumbnail.");
      }
    };

    // Function to delete the entire post
    const deletePost = async () => {
      const confirmDelete = await Swal.fire({
        title: 'Er du sikker?',
        text: "Du kan ikke fortryde denne handling!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ja, slet det!',
        cancelButtonText: 'Annuller'
      });

      if (confirmDelete.isConfirmed) {
        try {
          const db = getFirestore();
          const docRef = doc(db, "posts", props.eventId);

          // Slet dokumentet fra Firebase
          await deleteDoc(docRef);

          // Vis en kort succesmeddelelse og luk derefter automatisk
          await Swal.fire({
            icon: 'success',
            title: 'Slettet!',
            text: 'Dit opslag er blevet slettet.',
            timer: 1500,
            showConfirmButton: false
          });

          // Emit en event for at informere parent komponenten om sletningen
          emit('postDeleted', props.eventId);
        } catch (error) {
          console.error("Fejl ved sletning af post:", error);
          Swal.fire({
            icon: 'error',
            title: 'Fejl',
            text: 'Der opstod en fejl under sletning af opslaget.',
          });
        }
      }
    };

    // Function to save the post (update description)
    const savePost = async () => {
      try {
        const db = getFirestore();
        const docRef = doc(db, "posts", props.eventId);

        // Formater dato og tid til passende formater
        const formattedDate = publishDate.value.toISOString().split("T")[0]; // "YYYY-MM-DD"
        const formattedTime = `${publishTime.value.getHours().toString().padStart(2, "0")}:${publishTime.value.getMinutes().toString().padStart(2, "0")}`; // "HH:mm"

        // Opdater dokumentet i Firebase
        await updateDoc(docRef, {
          text: descriptionText.value,
          publishDate: formattedDate,
          publishTime: formattedTime,
        });

        alert("Opslag opdateret.");
      } catch (error) {
        console.error("Fejl ved opdatering af opslag:", error);
        alert("Kunne ikke opdatere opslaget.");
      }
    };

    // Preview functionality
    const creativeId = ref("");
    const previewIframe = ref(null);
    const previewType = ref("facebook");

    const currentImageIndex = ref(0);

    // Opdater simulatedPreviewHTML computed property med null checks
    const simulatedPreviewHTML = computed(() => {
      console.log('Current userData:', userData.value); // Debug log
      
      // Sikr at userData er tilgængelig
      const user = userData.value || {
        name: 'Din Virksomhed',
        userSettings: {
          profileImage: 'https://via.placeholder.com/40'
        }
      };
      
      console.log('User being used in preview:', user); // Debug log
      
      const images = mediaFilesState.value;
      const postText = descriptionText.value || "Ingen beskrivelse tilgængelig";
      const truncatedText = postText.length > 200 ? postText.slice(0, 200) : postText;
      const fullText = postText.length > 200 ? postText.slice(200) : "";
      
      // Brug userData computed property direkte
      const companyName = user.name;
      const profileImage = user.userSettings?.profileImage;
      
      console.log('Company name being used:', companyName); // Debug log
      console.log('Profile image being used:', profileImage); // Debug log
      
      const formatText = (text) => {
        return text.replace(/\n/g, '<br>');
      };

      if (previewType.value === "facebook") {
        let galleryHTML = '';
        if (images.length > 1) {
          galleryHTML = `
            <div style="display: flex; flex-wrap: wrap;">
              ${images.slice(0, 3).map((img, index) => `
                <div style="flex: 1 0 ${index === 0 ? '100%' : '50%'}; height: ${index === 0 ? '300px' : '150px'}; padding: 2px;">
                  <img src="${img}" style="width: 100%; height: 100%; object-fit: cover;">
                </div>
              `).join('')}
              ${images.length > 3 ? `
                <div style="position: absolute; bottom: 10px; right: 10px; background: rgba(0,0,0,0.7); color: white; padding: 5px 10px; border-radius: 5px;">
                  +${images.length - 3}
                </div>
              ` : ''}
            </div>
          `;
        } else if (images.length === 1) {
          galleryHTML = `<img src="${images[0]}" style="width: 100%; max-height: 500px; object-fit: cover;">`;
        }

        return `
          <div style="max-width: 500px; background-color: #fff; border-radius: 8px; box-shadow: 0 2px 4px rgba(0,0,0,0.1); font-family: Arial, sans-serif;">
            <div style="padding: 12px;">
              <div style="display: flex; align-items: center;">
                <img src="${profileImage}" style="width: 40px; height: 40px; border-radius: 50%; margin-right: 8px; object-fit: cover;">
                <div>
                  <div style="font-weight: bold; color: #1c1e21; font-size: 14px;">${companyName}</div>
                  <div style="font-size: 12px; color: #65676b;">Preview · ${new Date().toLocaleString()}</div>
                </div>
              </div>
              <p style="margin-top: 12px; color: #1c1e21 !important; font-size: 13px; line-height: 1.4;">
                ${formatText(truncatedText)}
                ${fullText ? `<span id="readMoreFB" style="color: #65676b; cursor: pointer; font-size: 13px;">... Læs mere</span>` : ''}
                <span id="fullTextFB" style="display: none;">
                  ${formatText(fullText)}
                  <span id="readLessFB" style="color: #65676b; cursor: pointer; font-size: 13px;"> Læs mindre</span>
                </span>
              </p>
            </div>
            ${galleryHTML}
            <div style="padding: 12px; border-top: 1px solid #e4e6eb;">
              <div style="display: flex; justify-content: space-between; color: #65676b; font-size: 13px;">
                <span>Synes godt om</span>
                <span>Kommenter</span>
                <span>Del</span>
              </div>
            </div>
          </div>
        `;
      } else if (previewType.value === "instagram") {
        return `
          <div style="max-width: 500px; background-color: #fff; border-radius: 8px; box-shadow: 0 2px 4px rgba(0,0,0,0.1); font-family: Arial, sans-serif;">
            <div style="padding: 12px; display: flex; align-items: center;">
              <img src="${profileImage}" style="width: 32px; height: 32px; border-radius: 50%; margin-right: 8px; object-fit: cover;">
              <div style="font-weight: bold; color: #262626; font-size: 14px;">${companyName}</div>
            </div>
            <div style="position: relative;">
              <img src="${images[currentImageIndex.value]}" style="width: 100%; max-height: 500px; object-fit: cover;">
              ${images.length > 1 ? `
                <div style="position: absolute; top: 50%; transform: translateY(-50%); width: 100%; display: flex; justify-content: space-between; padding: 0 10px;">
                  <button onclick="changeImage(-1)" style="background: rgba(0,0,0,0.5); color: white; border: none; padding: 10px; border-radius: 50%; cursor: pointer;">❮</button>
                  <button onclick="changeImage(1)" style="background: rgba(0,0,0,0.5); color: white; border: none; padding: 10px; border-radius: 50%; cursor: pointer;">❯</button>
                </div>
                <div style="position: absolute; bottom: 10px; left: 50%; transform: translateX(-50%);">
                  ${images.map((_, index) => `
                    <span style="height: 6px; width: 6px; margin: 0 2px; background-color: ${index === currentImageIndex.value ? '#3897f0' : '#a8a8a8'}; border-radius: 50%; display: inline-block;"></span>
                  `).join('')}
                </div>
              ` : ''}
            </div>
            <div style="padding: 12px;">
              <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
                <div>
                  <img src="https://firebasestorage.googleapis.com/v0/b/toft-d4f39.appspot.com/o/like.png?alt=media&token=333024e8-c314-4309-84e2-9bd05d48aac1" alt="Like" style="width: 24px; height: 24px; margin-right: 16px;">
                  <img src="https://firebasestorage.googleapis.com/v0/b/toft-d4f39.appspot.com/o/comment.png?alt=media&token=4ad3665a-3bf6-463f-9634-5defa0436d1b" alt="Comment" style="width: 24px; height: 24px; margin-right: 16px;">
                  <svg aria-label="Del opslag" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24">
                    <line fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2" x1="22" x2="9.218" y1="3" y2="10.083"></line>
                    <polygon fill="none" points="11.698 20.334 22 3.001 2 3.001 9.218 10.084 11.698 20.334" stroke="currentColor" stroke-linejoin="round" stroke-width="2"></polygon>
                  </svg>
                </div>
                <img src="https://firebasestorage.googleapis.com/v0/b/toft-d4f39.appspot.com/o/save.png?alt=media&token=3c69f1c3-0255-481f-a731-42d41411967c" alt="Save" style="width: 24px; height: 24px;">
              </div>
              <div style="font-weight: bold; margin-bottom: 8px; color: #262626; font-size: 14px;">89 Synes godt om</div>
              <div style="color: #000000; font-size: 13px; line-height: 1.4;">
                <span style="font-weight: bold; margin-right: 4px;">${companyName}</span>
                <span>
                  ${formatText(truncatedText)}
                  ${fullText ? `<span id="readMoreIG" style="color: #8e8e8e; cursor: pointer; font-size: 13px;">... mere</span>` : ''}
                  <span id="fullTextIG" style="display: none;">
                    ${formatText(fullText)}
                    <span id="readLessIG" style="color: #8e8e8e; cursor: pointer; font-size: 13px;"> Læs mindre</span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        `;
      }
    });

    function changeImage(direction) {
      const newIndex = currentImageIndex.value + direction;
      if (newIndex >= 0 && newIndex < mediaFilesState.value.length) {
        currentImageIndex.value = newIndex;
        previewIframe.value = simulatedPreviewHTML.value;
        Swal.update({ html: getModalHTML() });
      }
    }

    function getModalHTML() {
      const showToggleButtons = true;
      
      return `
        <div class="preview-wrapper">
          <div class="preview-container">
            <div class="iphone-x">
              <div class="mockup-elements">
                <i>Speaker</i>
                <b>Camera</b>
              </div>
              <div class="iframe-container">
                <div class="iframe-scroll-container">
                  ${previewIframe.value}
                </div>
              </div>
            </div>
          </div>

          <div class="control-buttons" style="position: relative; display: flex; justify-content: center; gap: 15px; margin-top: 20px;">
            ${showToggleButtons ? `
              <button id="togglePreviewType" class="control-button toggle-preview-type" style="display: flex; flex-direction: column; align-items: center;">
                ${previewType.value === "facebook" ? 
                  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="24" height="24"><path fill="#3b5998" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>' : 
                  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="24" height="24"><path fill="#E1306C" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>'
                }
                <span class="button-text" style="color: #ffffff;">Skift view</span>
              </button>
              ${creativeId.value ? `
                <button id="toggleIframePreview" class="control-button toggle-preview-type" style="display: flex; flex-direction: column; align-items: center;">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24">
                    <path fill="#4267B2" d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/>
                  </svg>
                  <span class="button-text" style="color: #ffffff;">Meta Preview</span>
                </button>
              ` : ''}
            ` : ''}
            <button id="closePreview" class="control-button close-preview" style="display: flex; flex-direction: column; align-items: center;">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="24" height="24"><path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
              <span class="button-text">Luk</span>
            </button>
          </div>
        </div>
      `;
    }

    // Tilføj denne nye ref
    const fbIframePreview = ref(null);

    // Modificer showPreviewModal funktionen
    const showPreviewModal = () => {
      Swal.fire({
        html: getModalHTML(),
        showConfirmButton: false,
        customClass: {
          popup: 'preview-modal'
        },
        didRender: () => {
          // Event listeners for toggle buttons
          const togglePreviewTypeBtn = document.getElementById('togglePreviewType');
          const toggleIframePreview = document.getElementById('toggleIframePreview');
          const closePreview = document.getElementById('closePreview');
          
          if (togglePreviewTypeBtn) {
            togglePreviewTypeBtn.addEventListener('click', () => {
              togglePreviewType(); // Nu kalder vi den definerede funktion
              Swal.update({ html: getModalHTML() });
            });
          }
          
          if (toggleIframePreview) {
            toggleIframePreview.addEventListener('click', () => {
              previewIframe.value = previewIframe.value === fbIframePreview.value ? 
                simulatedPreviewHTML.value : fbIframePreview.value;
              Swal.update({ html: getModalHTML() });
            });
          }
          
          if (closePreview) {
            closePreview.addEventListener('click', () => {
              Swal.close();
            });
          }
          
          addReadMoreListeners();
        }
      });
    };

    function addReadMoreListeners() {
      ['FB', 'IG'].forEach(platform => {
        const readMore = document.getElementById(`readMore${platform}`);
        const readLess = document.getElementById(`readLess${platform}`);
        const fullText = document.getElementById(`fullText${platform}`);
        
        if (readMore && fullText && readLess) {
          readMore.addEventListener('click', () => {
            readMore.style.display = 'none';
            fullText.style.display = 'inline';
          });
          
          readLess.addEventListener('click', () => {
            fullText.style.display = 'none';
            readMore.style.display = 'inline';
          });
        }
      });
    }

    // Publish Date og Time
    const publishDate = ref(null);
    const publishTime = ref(null);

    // Opdater denne funktion for at håndtere det nye format korrekt
    const initializePublishDateTime = (data) => {
      if (data.publishDate) {
        publishDate.value = new Date(data.publishDate);
      } else {
        publishDate.value = new Date();
      }

      if (data.publishTime) {
        if (typeof data.publishTime === "object" && data.publishTime.HH && data.publishTime.MM) {
          const date = new Date();
          date.setHours(parseInt(data.publishTime.HH), parseInt(data.publishTime.MM));
          publishTime.value = date;
        } else if (typeof data.publishTime === "string") {
          const [hours, minutes] = data.publishTime.split(":");
          const date = new Date();
          date.setHours(parseInt(hours), parseInt(minutes));
          publishTime.value = date;
        } else {
          console.warn("Uventet format for publishTime:", data.publishTime);
          publishTime.value = new Date();
        }
      } else {
        publishTime.value = new Date();
      }
    };

    // Opdater onMounted hook
    onMounted(async () => {
      try {
        const db = getFirestore();
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
          throw new Error("Ingen bruger logget ind");
        }

        // Initialiser flatpickr for datepicker
        flatpickr("#publish-date-picker", {
          dateFormat: "Y-m-d",
          defaultDate: publishDate.value,
          onChange: (selectedDates) => {
            publishDate.value = selectedDates[0];
          },
          locale: {
            firstDayOfWeek: 1,
            weekdays: {
              shorthand: ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"],
              longhand: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"]
            },
            months: {
              shorthand: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
              longhand: ["Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "Oktober", "November", "December"]
            }
          }
        });

        // Initialiser flatpickr for timepicker
        flatpickr("#publish-time-picker", {
          enableTime: true,
          noCalendar: true,
          dateFormat: "H:i",
          defaultDate: publishTime.value,
          onChange: (selectedDates) => {
            publishTime.value = selectedDates[0];
          },
          locale: {
            time_24hr: true
          }
        });

        const docRef = doc(db, props.collection, props.eventId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          
          // Initialiser publishDate
          if (data.publishDate) {
            publishDate.value = new Date(data.publishDate);
          } else {
            publishDate.value = new Date();
          }
          
          // Initialiser publishTime med forbedret type-checking
          if (data.publishTime) {
            let hours = 0;
            let minutes = 0;

            if (typeof data.publishTime === 'string') {
              // Hvis det er en string i format "HH:MM"
              const timeParts = data.publishTime.split(':');
              if (timeParts.length === 2) {
                hours = parseInt(timeParts[0]);
                minutes = parseInt(timeParts[1]);
              }
            } else if (typeof data.publishTime === 'object') {
              // Hvis det er et objekt med HH og MM properties
              if (data.publishTime.HH !== undefined && data.publishTime.MM !== undefined) {
                hours = parseInt(data.publishTime.HH);
                minutes = parseInt(data.publishTime.MM);
              }
            }

            // Opret ny dato med de parsede værdier
            const date = new Date();
            date.setHours(hours);
            date.setMinutes(minutes);
            publishTime.value = date;
          } else {
            // Fallback til nuværende tid hvis ingen publishTime findes
            publishTime.value = new Date();
          }

          // Hent brugerdata hvis nødvendigt
          if (data.id) {
            const userDocRef = doc(db, "users", data.id);
            const userDocSnap = await getDoc(userDocRef);
            
            if (userDocSnap.exists()) {
              userData.value = {
                name: userDocSnap.data().name || userDocSnap.data().companyName || 'Din Virksomhed',
                userSettings: {
                  profileImage: userDocSnap.data().userSettings?.profileImage || userDocSnap.data().profileImage || 'https://via.placeholder.com/40'
                }
              };
            }
          }
        }
      } catch (error) {
        console.error("Fejl ved indlæsning af data:", error);
        showToast(
          "Kunne ikke indlæse data korrekt",
          "Fejl",
          "danger"
        );
      }
    });

    // Tilføj denne funktion for at skifte mellem Facebook og Instagram preview
    const togglePreviewType = () => {
      previewType.value = previewType.value === "facebook" ? "instagram" : "facebook";
      previewIframe.value = simulatedPreviewHTML.value;
    };

    // Flyt fetchPreview definitionen ind i setup-funktionen
    const fetchPreview = async () => {
      try {
        previewIframe.value = simulatedPreviewHTML.value;
        await nextTick();
        showPreviewModal();
      } catch (error) {
        console.error("Fejl ved hentning af preview:", error);
      }
    };

    const showRepostModal = () => {
      Swal.fire({
        title: 'Re-post indstillinger',
        html: `
          <div class="repost-config">
            <div class="repost-input">
              <label for="interval">Interval mellem opslag (dage):</label>
              <input type="number" id="interval" min="1" max="30" value="7">
            </div>
            <div class="repost-input">
              <label for="period">Periode (måneder):</label>
              <input type="number" id="period" min="1" max="6" value="1">
            </div>
          </div>
        `,
        showCancelButton: true,
        confirmButtonText: 'Re-post',
        cancelButtonText: 'Annuller',
        customClass: {
          container: 'repost-modal-container',
          popup: 'repost-modal-popup',
          title: 'repost-modal-title',
          confirmButton: 'repost-modal-confirm',
          cancelButton: 'repost-modal-cancel'
        },
        preConfirm: () => {
          const interval = document.getElementById('interval').value;
          const period = document.getElementById('period').value;
          return { interval: parseInt(interval), period: parseInt(period) };
        }
      }).then((result) => {
        if (result.isConfirmed) {
          createRepostEvents(result.value.interval, result.value.period);
        }
      });
    };

    const createRepostEvents = async (interval, period) => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        
        if (!user) {
          throw new Error("Du skal være logget ind for at oprette re-posts");
        }

        const db = getFirestore();
        const batch = writeBatch(db);
        
        const docRef = doc(db, "posts", props.eventId);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          throw new Error("Det originale opslag findes ikke");
        }

        const originalData = docSnap.data();

        // Verificer at brugeren har rettigheder
        if (originalData.id !== user.uid && !await isSuperAdmin()) {
          throw new Error("Du har ikke rettigheder til at re-poste dette opslag");
        }

        const newEvents = [];

        // Start fra den oprindelige dato + interval
        const startDate = new Date(originalData.publishDate);
        startDate.setDate(startDate.getDate() + parseInt(interval)); // Første repost er efter interval

        // Slut dato er baseret på periode i måneder fra start datoen
        const endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + parseInt(period));

        // Opret reposts med det specificerede interval
        for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + parseInt(interval))) {
          const newDocRef = doc(collection(db, "posts"));
          const newEventData = {
            ...originalData,
            publishDate: date.toISOString().split('T')[0],
            id: user.uid,
            createdAt: new Date().toISOString(),
            isRepost: true,
            originalPostId: props.eventId
          };
          
          batch.set(newDocRef, newEventData);
          newEvents.push(newEventData);
        }

        await batch.commit();

        await Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: `${newEvents.length} nye opslag er blevet oprettet`,
          timer: 2000,
          showConfirmButton: false
        });

      } catch (error) {
        console.error("Fejl ved oprettelse af re-post events:", error);
        await Swal.fire({
          icon: 'error',
          title: 'Fejl',
          text: error.message || 'Der opstod en fejl ved oprettelse af nye opslag'
        });
      }
    };

    // Hjælpefunktion til at tjekke superadmin status
    const isSuperAdmin = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) return false;
      
      const db = getFirestore();
      const userDoc = await getDoc(doc(db, "users", user.uid));
      return userDoc.exists() && userDoc.data().role === 2;
    };

    // Tilføj disse refs
    const notificationMessage = ref('');
    const showNotification = ref(false);
    const notificationTitle = ref('');
    const notificationColor = ref('success');

    // Hjælpefunktion til at vise notifikationer
    const showToast = (message, title = 'Notifikation', color = 'success') => {
      notificationMessage.value = message;
      notificationTitle.value = title;
      notificationColor.value = color;
      showNotification.value = true;
      
      // Skjul notifikationen efter 5 sekunder
      setTimeout(() => {
        showNotification.value = false;
      }, 5000);
    };

    // Tilføj computed property til at tjekke om det er et postSend event
    const isPostSend = computed(() => {
      return props.collection === "postSend";
    });

    // Tilføj nye metoder
    const openSocialLink = (url) => {
      if (url) {
        window.open(url, '_blank');
      }
    };

    const deletePostSend = async () => {
      try {
        const result = await Swal.fire({
          title: 'Er du sikker?',
          text: "Dette vil slette opslaget fra både kalenderen og sociale medier",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Ja, slet det!',
          cancelButtonText: 'Annuller'
        });

        if (result.isConfirmed) {
          const db = getFirestore();
          const docRef = doc(db, "postSend", props.eventId);
          const docSnap = await getDoc(docRef);
          
          if (!docSnap.exists()) {
            throw new Error("Opslaget findes ikke");
          }

          const postData = docSnap.data();
          
          // Slet Facebook opslag hvis det findes
          if (postData.facebookPostId && postData.pageAccessToken) {
            const [pageId, postId] = postData.facebookPostId.split('_');
            
            const fbResult = await deleteFacebookPost(
              pageId,
              postId,
              postData.pageAccessToken
            );
            
            
            if (!fbResult.success) {
              console.error('Fejl ved sletning af Facebook-opslag:', fbResult.error);
              // Vis advarsel men fortsæt med at slette fra kalenderen
              await Swal.fire({
                icon: 'warning',
                title: 'Delvis sletning',
                text: 'Kunne ikke slette opslaget fra Facebook, men fortsætter med at slette fra kalenderen.',
                timer: 3000
              });
            }
          } else {
            console.log('Ingen Facebook post ID eller access token fundet i dokumentet');
          }

          // Slet fra Firebase
          await deleteDoc(docRef);
          
          await Swal.fire({
            icon: 'success',
            title: 'Slettet!',
            text: 'Opslaget er blevet slettet fra kalenderen og sociale medier.',
            timer: 2000,
            showConfirmButton: false
          });
          
          // Luk modal
          emit('close');
        }
      } catch (error) {
        console.error("Fejl ved sletning af postSend:", error);
        Swal.fire({
          icon: 'error',
          title: 'Fejl!',
          text: error.message || 'Der skete en fejl under sletning af opslaget.',
          timer: 3000
        });
      }
    };

    // I setup funktionen, tilføj disse refs
    const isLoading = ref(false);

    // Tilføj disse hjælpefunktioner
    const optimizeText = async () => {
      if (!descriptionText.value) {
        showToast('Ingen tekst at optimere', 'Advarsel', 'warning');
        return;
      }

      isLoading.value = true;
      try {
        console.log('EventData:', props.eventData); // Debug log
        
        // Brug hele eventData objektet direkte
        const messages = [
          {
            role: "system",
            content: `Du er en erfaren social media manager med fokus på bilbranchen.`
          },
          {
            role: "user",
            content: descriptionText.value
          }
        ];

        console.log('Sender til correctEventText:', { messages, eventData: props.eventData });
        const response = await correctEventText(messages, props.eventData);

        if (response && response.correctedText) {
          descriptionText.value = response.correctedText;
          showToast('Teksten er blevet optimeret', 'Succes', 'success');
        }
      } catch (error) {
        console.error('Fejl ved tekstoptimering:', error);
        showToast(
          'Der opstod en fejl under optimering af teksten',
          'Fejl',
          'danger'
        );
      } finally {
        isLoading.value = false;
      }
    };

    // Tilføj formatDate funktion i setup
    const formatDate = (dateString) => {
      if (!dateString) return '';
      const date = new Date(dateString);
      return date.toLocaleDateString('da-DK', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    };

    return {
      selectedImage,
      selectImage,
      splitAddress,
      descriptionText,
      fetchPreview,
      creativeId,
      mediaFilesState,
      addressesState,
      deleteThumbnail,
      deletePost,
      savePost,
      // Publish date og time
      publishDate,
      publishTime,
      togglePreviewType,
      changeImage,
      showPreviewModal,
      showRepostModal,
      userData, // Tilføj denne linje
      showToast,
      showModal,
      selectedModalImage,
      openModal,
      closeModal,
      hasVideo,
      isVideoFile,
      isPostSend,
      openSocialLink,
      deletePostSend,
      isLoading,
      optimizeText,
      // Tilføj Notifications relaterede refs
      notificationMessage,
      showNotification,
      notificationTitle,
      notificationColor,
      formatDate,
    };
  },
};
</script>

<style scoped>
/* Styles for the SweetAlert2 popup */
:global(.dark-version .swal2-html-container) {
  opacity: 1 !important;
}

/* Container til Date og Time Pickers */
.datetime-picker-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

/* Individuelle picker-containere */
.picker {
  display: flex;
  flex-direction: column;
  flex: 1; /* Sørg for, at begge pickere tager lige meget plads */
}

/* Juster labels for bedre spacing */
.picker-label {
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 5px;
}

/* Inputfelter for Date og Time Pickers */
.datepicker-input,
.timepicker-input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #555555;
  border-radius: 5px;
  background-color: transparent;
  color: #ffffff;
  width: 80%;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.datepicker-input:focus,
.timepicker-input:focus {
  border-color: #9f7aea;
  outline: none;
}

/* Minimalistisk Flatpickr Styling */
.flatpickr-input {
  background-color: transparent;
  color: #ffffff;
}

.flatpickr-calendar {
  background-color: #2d2d2d;
  color: #ffffff;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.flatpickr-month {
  background-color: #3a3a3a;
  color: #ffffff;
}

.flatpickr-day {
  color: #ffffff;
}

.flatpickr-day.today {
  border: 1px solid #9f7aea;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange {
  background-color: #9f7aea;
  color: #ffffff;
}

.flatpickr-time {
  background-color: #2d2d2d;
  color: #ffffff;
}

.flatpickr-time .flatpickr-hour,
.flatpickr-time .flatpickr-minute,
.flatpickr-time .flatpickr-second {
  background-color: #3a3a3a;
  color: #ffffff;
  border: none;
}

.flatpickr-time .flatpickr-am-pm {
  display: none;
}

/* Gallery Container */
.gallery-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 5px;
}

.thumbnails-wrapper {
  display: flex;
  gap: 5px;
}

.thumbnail-container {
  position: relative;
  width: calc(20% - 4px); /* 5 thumbnails per row, accounting for gap */
  aspect-ratio: 1 / 1;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.thumbnail-container.empty {
  background-color: rgba(255, 255, 255, 0.1);
}

.thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Delete icon styles */
.delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(255, 0, 0, 0.8);
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 14px;
  line-height: 20px; /* Centers the "X" vertically */
  text-align: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 0; /* Ensures no padding misaligns the icon */
}

.delete-icon:hover {
  background-color: rgba(255, 0, 0, 1);
}

.thumbnail-container:hover .delete-icon {
  opacity: 1;
}

/* Button container styles */
.button-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

/* Argon-inspired button styles */
.btn {
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  flex: 1;
}

/* Tilføj denne nye style for at gøre teksten fed */
.btn strong {
  font-weight: 700;
}

.btn-outline-primary {
  color: #5e72e4;
  border-color: #5e72e4;
  background-color: transparent;
}

.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #5e72e4;
  border-color: #5e72e4;
}

.btn-outline-danger {
  color: #f5365c;
  border-color: #f5365c;
  background-color: transparent;
}

.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #f5365c;
  border-color: #f5365c;
}

.btn-outline-success {
  color: #2dce89;
  border-color: #2dce89;
  background-color: transparent;
}

.btn-outline-success:hover {
  color: #ffffff;
  background-color: #2dce89;
  border-color: #2dce89;
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:hover {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.main-content {
  flex-grow: 1;
}

.left-side {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.main-image-container {
  margin-bottom: 10px;
}

.modal-content {
  background-color: #2d2d2d;
  color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main-content {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-grow: 1;
  overflow: hidden;
}

.left-side {
  flex: 1;
  overflow-y: auto;
}

.description-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.description-textarea {
  width: 100%;
  height: 100%;
  resize: none;
  padding: 10px;
  font-size: 14px;
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #555555;
  border-radius: 5px;
}

.bottom-controls {
  margin-top: 20px;
  border-top: 1px solid #555555;
  padding-top: 20px;
}

.main-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-image {
  max-width: 100%;
  max-height: 400px;
  object-fit: contain;
  border-radius: 10px;
  width: 100%;
}

.gallery-container {
  margin-top: 10px;
  display: flex;
  flex-wrap: nowrap;
  gap: 3px;
}

.thumbnail-container {
  flex: 1 1 auto;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.thumbnail-container:hover {
  transform: scale(1.05);
}

.thumbnail-container.selected {
  outline: 2px solid #007bff;
}

.thumbnail-image {
  width: 100%;
  height: auto;
  display: block;
}

.thumbnail-container .thumbnail-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.thumbnail-container:hover .thumbnail-overlay {
  opacity: 1;
}

.address {
  font-size: 10px;
  text-align: center;
  margin-top: 2px;
  color: #ffffff;
}

.description-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.description-textarea {
  width: 100%;
  height: 100%;
  resize: none;
  padding: 10px;
  font-size: 14px;
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #555555;
  border-radius: 5px;
}

.description-textarea::placeholder {
  color: #cccccc;
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
  }

  .left-side,
  .description-container {
    width: 100%;
  }

  .main-image {
    max-height: 300px;
  }

  .gallery-container {
    flex-wrap: wrap;
    gap: 1px;
  }

  .thumbnail-container {
    width: calc(33.333% - 1px);
    flex: none;
  }

  .description-textarea {
    height: 200px;
  }

  .button-container {
    flex-wrap: wrap;
  }

  .btn {
    flex-basis: calc(50% - 5px);
  }
}

.repost-config {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.repost-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.repost-input label {
  margin-bottom: 5px;
}

.repost-input input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

/* Re-post modal styles */
:global(.repost-modal-container) {
  font-family: Arial, sans-serif;
}

:global(.repost-modal-popup) {
  background-color: #2d2d2d;
  border-radius: 10px;
  color: #ffffff;
}

:global(.repost-modal-title) {
  color: #ffffff;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

:global(.repost-config) {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
}

:global(.repost-input) {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

:global(.repost-input label) {
  font-size: 1rem;
  color: #cccccc;
}

:global(.repost-input input) {
  background-color: #3a3a3a;
  border: 1px solid #555555;
  border-radius: 5px;
  color: #ffffff;
  font-size: 1rem;
  padding: 8px 12px;
  width: 100%;
}

:global(.repost-input input:focus) {
  border-color: #9f7aea;
  outline: none;
}

:global(.repost-modal-confirm),
:global(.repost-modal-cancel) {
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
}

:global(.repost-modal-confirm) {
  background-color: #9f7aea;
  color: #ffffff;
}

:global(.repost-modal-confirm:hover) {
  background-color: #8b5cf6;
}

:global(.repost-modal-cancel) {
  background-color: transparent;
  border: 1px solid #9f7aea;
  color: #9f7aea;
}

:global(.repost-modal-cancel:hover) {
  background-color: rgba(159, 122, 234, 0.1);
}

.preview-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 0; /* Fjernet top margin */
}

.control-buttons {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.control-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  transition: all 0.3s ease;
  width: 60px; /* Fast bredde for ensartethed */
}

.control-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.button-text {
  font-size: 11px;
  margin-top: 4px;
  color: #ffffff;
  text-align: center;
}

.close-preview svg {
  color: #dc3545;
}

.toggle-preview-type svg {
  color: #007bff;
}

/* Juster iphone-x containeren */
.iphone-x {
  margin: 40px auto;
}

/* Sørg for at SweetAlert2 modal har korrekt padding */
:global(.swal2-popup.preview-modal) {
  padding: 20px !important;
}

/* Tilføj styling for preview-wrapper */
.preview-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

/* Basis modal styling med glassmorphism */
.modal-content {
  background: #212121;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  border: none !important;  /* Fjerner border */
  background-color: #212121 !important; /* Overskriver Bootstrap's hvide baggrund */

  .main-content {
    min-height: unset !important;
    height: auto !important;
    display: flex;
    flex-direction: row;
    padding: 15px 0;
  }
}

/* Hovedindhold layout */
.main-content {
  gap: 25px;
  padding: 15px 0;
}

/* Image gallery styling */
.main-image-container {
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.2);
}

.main-image {
  border-radius: 20px;
}

/* Thumbnail styling */
.gallery-container {
  margin-top: 15px;
}

.thumbnail-container {
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
}

.thumbnail-container.selected {
  border-color: rgba(159, 122, 234, 0.6);
  box-shadow: 0 0 15px rgba(159, 122, 234, 0.2);
}

.thumbnail-container:hover {
  transform: translateY(-2px);
  border-color: rgba(255, 255, 255, 0.2);
}

/* Description area */
.description-textarea {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 15px;
  color: #ffffff;
  font-size: 15px;
  line-height: 1.6;
  transition: all 0.3s ease;
}

.description-textarea:focus {
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(159, 122, 234, 0.4);
  outline: none;
}

/* Bottom controls */
.bottom-controls {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 20px;
  margin-top: 20px;
}

/* Date/time picker container */
.datetime-picker-container {
  margin-bottom: 20px;
}

.picker {
  margin-bottom: 15px;
}

.picker-label {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 8px;
  font-weight: 500;
}

/* Input fields */
.datepicker-input,
.timepicker-input {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #ffffff;
  padding: 8px 12px;
  width: 100%;
  transition: all 0.3s ease;
}

.datepicker-input:focus,
.timepicker-input:focus {
  border-color: rgba(159, 122, 234, 0.4);
  outline: none;
  background: rgba(255, 255, 255, 0.08);
}

/* Button styling */
.button-container {
  display: flex;
  gap: 12px;
}

.btn {
  backdrop-filter: blur(8px);
  border-radius: 8px;
  padding: 8px 16px;
  font-weight: 500;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  border-width: 1px;
}

.btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Delete icon */
.delete-icon {
  background: rgba(220, 53, 69, 0.8);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.delete-icon:hover {
  background: rgba(220, 53, 69, 0.9);
}

.main-image-container {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}

.expand-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.main-image-container:hover .expand-btn {
  opacity: 1;
}

.expand-btn:hover {
  background: rgba(0, 0, 0, 0.7);
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-preview {
  position: relative;
  width: 90vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  padding: 1rem;
}

.modal-media-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-media {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.modal-media.video {
  width: 100%;
  height: auto;
  max-height: 90vh;
}

.modal-media.image {
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
}

.modal-close {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s ease;
}

.modal-close:hover {
  background: rgba(255, 255, 255, 0.3);
}

.video-container,
.video-player {
  display: none;
}

.btn i {
  margin-right: 8px;
}

.description-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.optimize-btn {
  align-self: flex-end;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #9f7aea;
  border: none;
  border-radius: 6px;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.optimize-btn:hover:not(:disabled) {
  background-color: #805ad5;
}

.optimize-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.loading-spinner {
  width: 16px;
  height: 16px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Tilføj ny styling for text-action-buttons */
.text-action-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

/* Opdater btn styling for at sikre konsistent højde */
.btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 500;
  transition: all 0.3s ease;
  height: 36px; /* Tilføjet fast højde */
}

.btn i {
  font-size: 14px;
}

.btn-outline-primary {
  color: #ffffff;
  border: 1px solid #5e72e4;
  background-color: transparent;
}

.btn-outline-primary:hover:not(:disabled) {
  color: #ffffff;
  background-color: #5e72e4;
}

.btn-outline-primary:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.loading-spinner {
  width: 16px;
  height: 16px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Behold eksisterende styling for main-content og description-container */
.main-content {
  display: flex;
  gap: 20px;
  flex: 1;
}

.description-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.description-textarea {
  flex: 1;
  width: 100%;
  resize: none;
  padding: 12px;
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #555555;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.5;
}

/* Placeholder information styling */
.placeholder-info {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 20px;
  margin: 20px 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.placeholder-title {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 15px;
  font-weight: 500;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
}

.info-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.info-label {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}

.info-value {
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
}
</style>

<style>
/* Global styles for the SweetAlert2 modal and iPhone mockup */

.swal2-popup.preview-modal {
  padding: 0;
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
}

.swal2-close {
  position: fixed;
  top: 20px;
  right: 20px;
  color: #ffffff !important;
  font-size: 28px !important;
}

.iphone-x {
  position: relative;
  margin: 40px auto;
  width: 360px;
  height: 780px;
  background-color: #ffffff; /* Ændret til hvid */
  border-radius: 40px;
  box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919, 0px 0px 0px 20px #111;
  overflow: hidden;
}

.iphone-x:before,
.iphone-x:after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.iphone-x:after {
  bottom: 7px;
  width: 140px;
  height: 4px;
  background-color: #f2f2f2;
  border-radius: 10px;
}

.iphone-x:before {
  top: 0px;
  width: 56%;
  height: 30px;
  background-color: #1f1f1f;
  border-radius: 0px 0px 40px 40px;
}

.iframe-container {
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  height: calc(100% - 30px);
  overflow: hidden;
}

.iframe-scroll-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.top-buttons {
  position: absolute;
  top: 8px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close-preview {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #dc3545;
  margin-bottom: 5px;
}

.close-preview svg {
  width: 20px;
  height: 20px;
}

.toggle-preview-type {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #007bff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toggle-preview-type svg {
  width: 24px;
  height: 24px;
}

.toggle-text {
  font-size: 12px;
  margin-top: 4px;
}
</style>