import { ref, onMounted } from 'vue';
import { db } from '@/firebase';
import { doc, getDoc, setDoc, Timestamp } from 'firebase/firestore';
import { UAParser } from 'ua-parser-js';

export const PREVIEW_LIMIT = 3; // Max antal previews per 24 timer
const PREVIEW_WINDOW = 24 * 60 * 60 * 1000; // 24 timer i millisekunder

// Whitelist af IP-adresser der kan lave ubegrænsede previews
const WHITELISTED_IPS = [
  '87.52.111.66', // Mathias' IP
  '2001:4860:7:60b::ff', // Mathias' IPv6
];

// Generer en unik device fingerprint
const generateDeviceFingerprint = () => {
  const parser = new UAParser();
  const result = parser.getResult();
  
  // Kombiner forskellige browser og enhedsdata
  const fingerprint = {
    browser: result.browser.name + result.browser.version,
    os: result.os.name + result.os.version,
    device: result.device.vendor + result.device.model + result.device.type,
    screen: window.screen.width + 'x' + window.screen.height,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    language: navigator.language,
    platform: navigator.platform
  };
  
  // Lav en hash af fingerprint objektet
  return btoa(JSON.stringify(fingerprint));
};

// Gem dit device fingerprint første gang for at kunne whitelist det
const saveMyDeviceFingerprint = () => {
  const fingerprint = generateDeviceFingerprint();
};

// Kør denne funktion én gang for at få dit device fingerprint
saveMyDeviceFingerprint();

// Whitelist af device fingerprints der kan lave ubegrænsede previews
const WHITELISTED_DEVICES = [
  // Mathias' MacBook
  'eyJicm93c2VyIjoiQ2hyb21lMTMxLjAuMC4wIiwib3MiOiJtYWNPUzEwLjE1LjciLCJkZXZpY2UiOiJBcHBsZU1hY2ludG9zaHVuZGVmaW5lZCIsInNjcmVlbiI6IjM0NDB4MTQ0MCIsInRpbWV6b25lIjoiRXVyb3BlL0NvcGVuaGFnZW4iLCJsYW5ndWFnZSI6ImRhLURLIiwicGxhdGZvcm0iOiJNYWNJbnRlbCJ9'
];

export async function checkPreviewLimit(ipAddress, source = '') {
  // Hvis kilden ikke er PreviewDemo, tillad altid preview
  if (source !== 'PreviewDemo') {
    return {
      allowed: true,
      attemptsLeft: Infinity
    };
  }

  try {
    const deviceFingerprint = generateDeviceFingerprint();

    // Tjek om enten IP eller device er whitelisted
    if (WHITELISTED_IPS.includes(ipAddress) || WHITELISTED_DEVICES.includes(deviceFingerprint)) {
      return {
        allowed: true,
        attemptsLeft: Infinity
      };
    }

    const rateKey = `${ipAddress}_${deviceFingerprint}`;
    const rateRef = doc(db, 'rateLimits', rateKey);
    const rateDoc = await getDoc(rateRef);
    
    const now = Timestamp.now();
    let attempts = [];
    
    if (rateDoc.exists()) {
      attempts = rateDoc.data().attempts || [];
      // Fjern gamle forsøg (ældre end 24 timer)
      attempts = attempts.filter(attempt => 
        now.toMillis() - attempt.toMillis() < PREVIEW_WINDOW
      );
    }
    
    // Tjek også global IP limit (uanset device)
    const globalIPRef = doc(db, 'rateLimits', `global_${ipAddress}`);
    const globalIPDoc = await getDoc(globalIPRef);
    let globalAttempts = [];
    
    if (globalIPDoc.exists()) {
      globalAttempts = globalIPDoc.data().attempts || [];
      globalAttempts = globalAttempts.filter(attempt => 
        now.toMillis() - attempt.toMillis() < PREVIEW_WINDOW
      );
    }

    const GLOBAL_IP_LIMIT = 5; // Max antal forsøg per IP på tværs af devices
    
    if (attempts.length >= PREVIEW_LIMIT || globalAttempts.length >= GLOBAL_IP_LIMIT) {
      // Find den seneste begrænsning
      const deviceTimeUntilReset = attempts.length > 0 
        ? PREVIEW_WINDOW - (now.toMillis() - attempts[0].toMillis())
        : 0;
        
      const globalTimeUntilReset = globalAttempts.length > 0
        ? PREVIEW_WINDOW - (now.toMillis() - globalAttempts[0].toMillis())
        : 0;
        
      const timeUntilReset = Math.max(deviceTimeUntilReset, globalTimeUntilReset);
      
      return {
        allowed: false,
        timeUntilReset: Math.ceil(timeUntilReset / (60 * 1000)) // Konverter til minutter
      };
    }
    
    // Tilføj nyt forsøg til både device-specifik og global IP tracking
    attempts.push(now);
    globalAttempts.push(now);
    
    await Promise.all([
      setDoc(rateRef, { attempts }),
      setDoc(globalIPRef, { attempts: globalAttempts })
    ]);
    
    return {
      allowed: true,
      attemptsLeft: Math.min(
        PREVIEW_LIMIT - attempts.length,
        GLOBAL_IP_LIMIT - globalAttempts.length
      )
    };
  } catch (error) {
    console.error('Fejl ved rate limiting check:', error);
    // Ved fejl, tillad preview for at undgå falske blokeringer
    return { allowed: true, attemptsLeft: 1 };
  }
} 