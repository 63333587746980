<template>
  <div>
    <!-- Ad Set Editor Modal -->
    <b-modal
      v-model="showModal"
      title="Opret Ad Set"
      centered
      hide-footer
      size="lg"
      class="custom-modal"
      scrollable
      @hide="resetForm"
    >
      <form class="p-4" @submit.prevent="submitAdSet">
        <!-- Multi-step Form Progress -->
        <div class="multisteps-form__progress mb-4">
          <button
            v-for="(step, index) in steps"
            :key="index"
            type="button"
            :class="[
              'multisteps-form__progress-btn',
              { 'js-active': activeStep >= index },
            ]"
            @click="goToStep(index)"
          >
            <span>{{ step.title }}</span>
          </button>
        </div>

        <!-- Form Steps -->
        <!-- Step 0: Opsætning -->
        <div v-if="activeStep === 0">
          <hr />
          <div class="mb-3">
            <label class="form-label">Opsætningsmodus</label>
            <select v-model="mode" class="form-select" required>
              <option value="quick">Quick Setup</option>
              <option value="advanced">Udvidet Opsætning</option>
            </select>
          </div>
          <div class="d-flex justify-content-end">
            <ArgonButton
              type="button"
              class="btn bg-gradient-primary"
              @click="nextStep"
            >
              Næste
            </ArgonButton>
          </div>
        </div>

        <!-- Step 1: Campaign Details -->
        <div v-if="activeStep === 1">
          <hr />
          <div class="mb-3">
            <label for="campaignName" class="form-label">Kampagne Navn</label>
            <ArgonInput
              id="campaignName"
              v-model="adSetData.campaignName"
              required
              placeholder="Indtast kampagnenavn"
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Objective</label>
            <select v-model="adSetData.objective" class="form-select" required>
              <option value="" disabled>Vælg et objective</option>
              <option value="REACH">Reach</option>
              <option value="TRAFFIC">Traffic</option>
              <option value="ENGAGEMENT">Engagement</option>
              <option value="CONVERSIONS">Conversions</option>
              <option value="LEAD_GENERATION">Lead Generation</option>
              <option value="APP_INSTALLS">App Installs</option>
              <!-- Tilføj flere options efter behov -->
            </select>
          </div>
          <div class="mb-3">
            <label class="form-label">Spend Cap (i DKK)</label>
            <ArgonInput
              v-model.number="adSetData.spendCapInput"
              type="number"
              min="100"
              required
              placeholder="Minimum 100 DKK"
              @input="formatSpendCap"
            />
            <small class="form-text text-muted">
              Angiv som hele tal i DKK. Værdien vil blive konverteret til øre.
            </small>
          </div>
          <div class="mb-3">
            <label class="form-label">Start Dato</label>
            <ArgonInput v-model="adSetData.startTime" type="date" required />
          </div>
          <div class="mb-3">
            <label class="form-label">Stop Dato</label>
            <ArgonInput v-model="adSetData.stopTime" type="date" required />
          </div>
          <div class="d-flex justify-content-between">
            <ArgonButton type="button" class="btn btn-dark" @click="prevStep">
              Forrige
            </ArgonButton>
            <ArgonButton
              type="button"
              class="btn bg-gradient-primary"
              @click="nextStep"
            >
              Næste
            </ArgonButton>
          </div>
        </div>

        <!-- Step 2: Ad Set Details -->
        <div v-if="activeStep === 2">
          <hr />
          <div class="mb-3">
            <label for="adsetName" class="form-label">Adset Navn</label>
            <ArgonInput
              id="adsetName"
              v-model="adSetData.name"
              required
              placeholder="Indtast adset navn"
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Budget Type</label>
            <select v-model="adSetData.budgetType" class="form-select" required>
              <option value="DAILY">Dagligt Budget</option>
              <option value="LIFETIME">Lifetime Budget</option>
            </select>
          </div>
          <div class="mb-3">
            <label class="form-label">Dagligt Budget (i DKK)</label>
            <ArgonInput
              v-model.number="adSetData.budgetInput"
              type="number"
              min="175"
              required
              placeholder="Minimum 175 DKK"
              @input="formatBudget"
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Billing Event</label>
            <select
              v-model="adSetData.billingEvent"
              class="form-select"
              required
            >
              <option value="IMPRESSIONS">Impressions</option>
              <option value="LINK_CLICKS">Link Clicks</option>
            </select>
          </div>
          <div class="mb-3">
            <label class="form-label">Optimization Goal</label>
            <select
              v-model="adSetData.optimizationGoal"
              class="form-select"
              required
            >
              <option value="" disabled>Vælg et optimization goal</option>
              <option value="APP_INSTALLS">App Installs</option>
              <option value="BRAND_AWARENESS">Brand Awareness</option>
              <option value="CONVERSIONS">Conversions</option>
              <option value="LEAD_GENERATION">Lead Generation</option>
              <option value="LINK_CLICKS">Link Clicks</option>
              <option value="POST_ENGAGEMENT">Post Engagement</option>
              <option value="REACH">Reach</option>
              <option value="VIDEO_VIEWS">Video Views</option>
              <!-- Tilføj flere options efter behov -->
            </select>
          </div>
          <div class="mb-3">
            <label class="form-label">Bid Strategy</label>
            <select
              v-model="adSetData.bidStrategy"
              class="form-select"
              required
            >
              <option value="LOWEST_COST_WITHOUT_CAP">
                Lowest Cost (Without Cap)
              </option>
              <option value="LOWEST_COST_WITH_BID_CAP">
                Lowest Cost (With Bid Cap)
              </option>
              <option value="COST_CAP">Cost Cap</option>
              <!-- Tilføj flere strategier efter behov -->
            </select>
          </div>
          <div class="d-flex justify-content-between">
            <ArgonButton type="button" class="btn btn-dark" @click="prevStep">
              Forrige
            </ArgonButton>
            <ArgonButton
              type="button"
              class="btn bg-gradient-primary"
              @click="nextStep"
            >
              Næste
            </ArgonButton>
          </div>
        </div>

        <!-- Step 3: Targeting (Only show for Advanced Setup) -->
        <div v-if="activeStep === 3 && mode === 'advanced'">
          <hr />
          <!-- Preset or Custom Targeting Selection -->
          <div class="mb-3">
            <label class="form-label">Vælg Målgruppe</label>
            <select
              v-model="selectedPreset"
              class="form-select"
              @change="handlePresetChange"
            >
              <option value="" disabled>Vælg en målgruppe</option>
              <option
                v-for="(group, index) in presetTargetGroups"
                :key="index"
                :value="group"
              >
                {{ group.name }}
              </option>
              <option value="custom">Tilføj ny målgruppe</option>
            </select>
          </div>

          <!-- Display Preset Details -->
          <div v-if="selectedPreset !== 'custom' && selectedPreset">
            <h6>Detaljer for {{ selectedPreset.name }}</h6>
            <ul>
              <li
                v-for="(detail, index) in selectedPreset.details"
                :key="index"
              >
                {{ detail }}
              </li>
            </ul>
          </div>

          <!-- Custom Targeting Options -->
          <div v-if="selectedPreset === 'custom'">
            <!-- Geografisk Targeting -->
            <div class="mb-4">
              <label class="form-label">Geografisk Lokation</label>
              <div v-if="userAddress">
                <p>Nuværende adresse: {{ userAddress }}</p>
                <ArgonButton
                  class="btn bg-gradient-primary use-address-btn"
                  @click="useUserAddress"
                >
                  Brug denne adresse
                </ArgonButton>
              </div>
              <div class="mb-2 position-relative">
                <label class="form-label">Søg efter ny lokation:</label>
                <ArgonInput
                  v-model="locationSearch"
                  placeholder="Indtast adresse"
                  @input="debouncedSearchLocation"
                />
                <ul
                  v-if="locationResults.length > 0"
                  class="list-group position-absolute w-100 mt-1 z-index-dropdown"
                >
                  <li
                    v-for="location in locationResults"
                    :key="location.key"
                    class="list-group-item list-group-item-action"
                    @click="selectLocation(location)"
                  >
                    {{ location.name }}
                  </li>
                </ul>
              </div>
              <div v-if="selectedLocation" class="mt-2">
                <p>
                  <strong>Valgt lokation:</strong> {{ selectedLocation.name }}
                </p>
              </div>
            </div>

            <!-- Interest Targeting -->
            <div class="mb-4">
              <h6>Interesser</h6>
              <ArgonSelect
                v-model="selectedInterests"
                :options="interestOptions"
                label="Vælg Interesser"
                multiple
                placeholder="Søg og vælg interesser"
                class="argon-select"
                @search="debouncedFetchInterests"
              />
            </div>

            <!-- Demographic Targeting -->
            <div class="mb-4">
              <h6>Demografi</h6>
              <!-- Age Range -->
              <div class="mb-3">
                <label class="form-label">Aldersområde</label>
                <div class="d-flex">
                  <ArgonInput
                    v-model.number="selectedDemographics.age.min"
                    type="number"
                    min="18"
                    placeholder="Min alder"
                    class="me-2"
                    required
                  />
                  <ArgonInput
                    v-model.number="selectedDemographics.age.max"
                    type="number"
                    min="18"
                    placeholder="Max alder"
                    required
                  />
                </div>
                <small class="form-text text-muted">
                  Alder skal være mellem 18 og 65+.
                </small>
              </div>

              <!-- Gender Selection -->
              <div class="mb-3">
                <label class="form-label">Køn</label>
                <select
                  v-model="selectedDemographics.gender"
                  class="form-select"
                >
                  <option value="ALL">Alle</option>
                  <option value="MALE">Mænd</option>
                  <option value="FEMALE">Kvinder</option>
                </select>
              </div>

              <!-- Education -->
              <div class="mb-3">
                <label class="form-label">Uddannelse</label>
                <ArgonSelect
                  v-model="selectedDemographics.education"
                  :options="demographicOptions.education"
                  label="Vælg Uddannelser"
                  multiple
                  placeholder="Søg og vælg uddannelser"
                  class="argon-select"
                  @search="
                    (query) => debouncedFetchDemographics('education', query)
                  "
                />
              </div>

              <!-- Job Titles -->
              <div class="mb-3">
                <label class="form-label">Jobtitler</label>
                <ArgonSelect
                  v-model="selectedDemographics.jobTitles"
                  :options="demographicOptions.jobTitles"
                  label="Vælg Jobtitler"
                  multiple
                  placeholder="Søg og vælg jobtitler"
                  class="argon-select"
                  @search="
                    (query) => debouncedFetchDemographics('job_title', query)
                  "
                />
              </div>
            </div>

            <!-- Navigation Buttons -->
            <div class="d-flex justify-content-between">
              <ArgonButton type="button" class="btn btn-dark" @click="prevStep">
                Forrige
              </ArgonButton>
              <ArgonButton
                type="button"
                class="btn bg-gradient-primary"
                @click="nextStep"
              >
                Næste
              </ArgonButton>
            </div>
          </div>
        </div>

        <!-- Step 4: Review & Submit -->
        <div v-if="activeStep === 4">
          <h5>Opret & Gem</h5>
          <hr />
          <div class="mb-3">
            <h6>Kampagne Detaljer</h6>
            <p><strong>Kampagne Navn:</strong> {{ adSetData.campaignName }}</p>
            <p><strong>Objective:</strong> {{ adSetData.objective }}</p>
            <p>
              <strong>Spend Cap:</strong> {{ adSetData.spendCapInput }} DKK ({{
                adSetData.spendCap
              }}
              øre)
            </p>
            <p><strong>Start Dato:</strong> {{ adSetData.startTime }}</p>
            <p><strong>Stop Dato:</strong> {{ adSetData.stopTime }}</p>
            <p>
              <strong>Kampagne Budget Optimering:</strong>
              {{ adSetData.campaignBudgetOptimization }}
            </p>
          </div>
          <div class="mb-3">
            <h6>Ad Set Detaljer</h6>
            <p><strong>Adset Navn:</strong> {{ adSetData.name }}</p>
            <p><strong>Budget Type:</strong> {{ adSetData.budgetType }}</p>
            <p>
              <strong>Budget:</strong>
              {{ adSetData.budgetInput }} DKK ({{ adSetData.budget }} øre)
            </p>
            <p><strong>Billing Event:</strong> {{ adSetData.billingEvent }}</p>
            <p>
              <strong>Optimization Goal:</strong>
              {{ adSetData.optimizationGoal }}
            </p>
            <p><strong>Bid Strategy:</strong> {{ adSetData.bidStrategy }}</p>
          </div>
          <div v-if="mode === 'advanced'" class="mb-3">
            <h6>Målgrupper</h6>
            <div v-if="selectedPreset && selectedPreset !== 'custom'">
              <p><strong>Målgruppe:</strong> {{ selectedPreset.name }}</p>
              <ul>
                <li
                  v-for="(detail, index) in selectedPreset.details"
                  :key="index"
                >
                  {{ detail }}
                </li>
              </ul>
            </div>
            <div v-else-if="selectedPreset === 'custom'">
              <p>
                <strong>Byer:</strong>
                {{ selectedGeographics.cities.map((c) => c.label).join(", ") }}
              </p>
              <p>
                <strong>Postnumre:</strong>
                {{
                  selectedGeographics.zipCodes.map((z) => z.label).join(", ")
                }}
              </p>
              <p>
                <strong>Radius:</strong>
                {{ radiusSuggestions[0]?.suggested_radius }}
                {{ radiusSuggestions[0]?.distance_unit }}
              </p>
              <p>
                <strong>Interesser:</strong>
                {{ selectedInterests.map((i) => i.label).join(", ") }}
              </p>
              <p>
                <strong>Aldersområde:</strong>
                {{ selectedDemographics.age.min }} -
                {{ selectedDemographics.age.max }}
              </p>
              <p><strong>Køn:</strong> {{ selectedDemographics.gender }}</p>
              <p>
                <strong>Uddannelser:</strong>
                {{
                  selectedDemographics.education.map((e) => e.label).join(", ")
                }}
              </p>
              <p>
                <strong>Jobtitler:</strong>
                {{
                  selectedDemographics.jobTitles.map((j) => j.label).join(", ")
                }}
              </p>
            </div>
          </div>
          <div class="mb-3">
            <h6>Øvrige Indstillinger</h6>
            <p>
              <strong>SKAdNetwork Attribution:</strong>
              {{ adSetData.skAdNetwork }}
            </p>
            <p>
              <strong>Brug L3 Schedule:</strong>
              {{ adSetData.isUsingL3Schedule }}
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <ArgonButton type="button" class="btn btn-dark" @click="prevStep">
              Forrige
            </ArgonButton>
            <ArgonButton
              type="submit"
              class="btn bg-gradient-success"
              :disabled="isSubmitting"
            >
              <span
                v-if="isSubmitting"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span v-if="isSubmitting">Opretter...</span>
              <span v-else>Opret Ad Set</span>
            </ArgonButton>
          </div>
        </div>

        <!-- Fejlmeddelelse -->
        <div v-if="errorMessage" class="mb-3 alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script setup>
import {
  ref,
  watch,
  onMounted,
  reactive,
  defineProps,
  defineExpose,
  nextTick,
} from "vue";
import { debounce } from "lodash"; // Importer debounce
import { createAdSet } from "@/services/AdSetService.js"; // Sørg for korrekt sti
import ArgonButton from "@/components/ArgonButton.vue"; // Sørg for korrekt sti
import ArgonInput from "@/components/ArgonInput.vue"; // Sørg for korrekt sti
import ArgonSelect from "@/views/pages/components/ArgonSelect.vue";
import { getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "@/firebase"; // Sørg for, at stien til din firebase konfiguration er korrekt
import { searchLocationTargeting } from "@/services/TargetingService.js";
import {
  searchInterests,
  searchDemographics,
} from "@/services/TargetingService.js";
import { v4 as uuidv4 } from "uuid"; // Tilføj denne import for at generere unikke ID'er

const props = defineProps({
  campaignName: {
    type: String,
    default: '' // Tilføj en tom streng som standardværdi
  },
  startDate: {
    type: String,
    default: () => new Date().toISOString().split('T')[0] // Dagens dato som standardværdi
  },
  endDate: {
    type: String,
    default: () => {
      const date = new Date();
      date.setDate(date.getDate() + 30); // 30 dage fra nu som standard
      return date.toISOString().split('T')[0];
    }
  }
});

// Modal visibility
const showModal = ref(false);

watch(showModal, (newValue) => {
  if (newValue) {
    setDefaultDates();
    generateNames();
    removeCheckbox();
  }
});

// Multi-step form state
const activeStep = ref(0);
const steps = [
  { title: "Opsætning" },
  { title: "Kampagne" },
  { title: "Annonce" },
  { title: "Målgruppe" },
  { title: "Opret & Gem" },
];

// Form data
const adSetData = reactive({
  // Kampagne Data
  campaignName: "",
  objective: "TRAFFIC", // Preset til trafik, men kan ændres
  status: "ACTIVE",
  spendCapInput: 100,
  spendCap: 10000,
  campaignBudgetOptimization: "OFF",
  buyingType: "AUCTION",
  pacingType: "standard",
  specialAdCategories: [],
  specialAdCategoryCountry: "",
  skAdNetwork: "",
  isUsingL3Schedule: "",

  // Ad Set Data
  name: "",
  budgetType: "DAILY",
  budgetInput: 175, // Sat til 175 DKK
  budget: 17500, // 175 DKK i øre
  billingEvent: "IMPRESSIONS",
  optimizationGoal: "REACH",
  bidStrategy: "LOWEST_COST_WITHOUT_CAP",
  targeting: {
    // Dette vil blive struktureret i submit-funktionen
    geo_locations: {},
    age_min: 18,
    age_max: 65,
    genders: [],
    publisher_platforms: [],
  },
  startTime: "",
  stopTime: "",
});

// Preset Target Groups
const presetTargetGroups = [
  {
    name: "Unge Teknologi Enthusiaster",
    details: [
      "Alder: 18-24",
      "Interesse: Teknologi",
      "Platforme: Facebook, Instagram",
    ],
  },
  {
    name: "Familier i Danmark",
    details: ["Alder: 25-45", "Interesse: Familie, Bolig"],
  },
  {
    name: "Professionelle i Finanssektoren",
    details: [
      "Alder: 30-55",
      "Interesse: Finans, Investering",
      "Platforme: LinkedIn, Facebook",
    ],
  },
  {
    name: "Fitness og Sundhed",
    details: [
      "Alder: 20-40",
      "Interesse: Fitness, Sundhed",
      "Platforme: Instagram, Facebook",
    ],
  },
];

// Selected Preset or Custom
const selectedPreset = ref(null);

// Mode Selection (Preset to Advanced)
const mode = ref("advanced");

// Error and Submission State
const isSubmitting = ref(false);
const errorMessage = ref("");

// State for Targeting
const geographicOptions = reactive({
  cities: [],
  zipCodes: [],
});
const interestOptions = ref([]);
const demographicOptions = reactive({
  education: [],
  jobTitles: [],
});
const radiusSuggestions = ref([]);

// Selected Geographics
const selectedGeographics = reactive({
  cities: [],
  zipCodes: [],
});

// Selected Interests and Demographics
const selectedInterests = ref([]);
const selectedDemographics = reactive({
  age: { min: 18, max: 65 },
  gender: "ALL",
  education: [],
  jobTitles: [],
});

const radiusLocation = reactive({
  latitude: null,
  longitude: null,
});
const radiusUnit = ref("mile");

// Helper function to convert DKK to øre
const convertDKKToOre = (value) => {
  return Math.floor(value * 100);
};

// Watchers for auto-conversion
watch(
  () => adSetData.spendCapInput,
  (newVal) => {
    adSetData.spendCap = convertDKKToOre(newVal);
  },
);

watch(
  () => adSetData.budgetInput,
  (newVal) => {
    adSetData.budget = convertDKKToOre(newVal);
  },
);

// Step navigation functions
const nextStep = () => {
  if (activeStep.value < steps.length - 1) {
    activeStep.value += 1;
  }
};

const prevStep = () => {
  if (activeStep.value > 0) {
    activeStep.value -= 1;
  }
};

const goToStep = (step) => {
  if (step >= 0 && step < steps.length) {
    activeStep.value = step;
  }
};

// Handle preset selection
const handlePresetChange = () => {
  if (selectedPreset.value === "custom") {
    // Clear targeting details for custom
    selectedGeographics.cities = [];
    selectedGeographics.zipCodes = [];
    selectedInterests.value = [];
    selectedDemographics.age = { min: 18, max: 65 };
    selectedDemographics.gender = "ALL";
    selectedDemographics.education = [];
    selectedDemographics.jobTitles = [];
  } else if (selectedPreset.value) {
    const selected = presetTargetGroups.find(
      (group) => group.name === selectedPreset.value.name,
    );
    if (selected) {
      // Parse details to extract targeting information
      const ageDetail = selected.details.find((detail) =>
        detail.startsWith("Alder: "),
      );
      const interestDetail = selected.details.find((detail) =>
        detail.startsWith("Interesse: "),
      );
      const platformDetail = selected.details.find((detail) =>
        detail.startsWith("Platforme: "),
      );

      // Populate age
      if (ageDetail) {
        const [min, max] = ageDetail
          .split(": ")[1]
          .split("-")
          .map((age) => parseInt(age));
        selectedDemographics.age.min = min;
        selectedDemographics.age.max = max;
      }

      // Populate interests
      if (interestDetail) {
        const interests = interestDetail
          .split(": ")[1]
          .split(", ")
          .map((interest) => ({
            label: interest,
            value: interest.toLowerCase().replace(/\s+/g, "_"),
          }));
        selectedInterests.value = interests;
      }

      // Populate platforms
      if (platformDetail) {
        const platforms = platformDetail
          .split(": ")[1]
          .split(", ")
          .map((p) => p.toLowerCase());
        adSetData.targeting.publisher_platforms = platforms;
      }
    }
  }
};

// Debounced version af fetchInterests
const debouncedFetchInterests = debounce(async (query) => {
  if (!query) return;
  try {
    const data = await searchInterests(query);
    interestOptions.value = data.data.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  } catch (error) {
    console.error("Fejl ved hentning af interesser:", error);
  }
}, 300);

// Debounced version af fetchDemographics
const debouncedFetchDemographics = debounce(async (classType, query) => {
  if (!query) return;
  try {
    const data = await searchDemographics(query, classType);
    if (classType === "education") {
      demographicOptions.education = data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    } else if (classType === "job_title") {
      demographicOptions.jobTitles = data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }
  } catch (error) {
    console.error(`Fejl ved hentning af ${classType}:`, error);
  }
}, 300);

const userAddress = ref("");
const locationSearch = ref("");
const locationResults = ref([]);
const selectedLocation = ref(null);

const fetchUserLocation = async () => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        if (userData.address) {
          userAddress.value = userData.address;
        }
      }
    }
  } catch (error) {
    console.error("Fejl ved hentning af bruger lokation:", error);
  }
};

const useUserAddress = () => {
  locationSearch.value = userAddress.value;
  debouncedSearchLocation();
};

const searchLocation = async () => {
  try {
    if (locationSearch.value.length < 2) {
      locationResults.value = [];
      return;
    }
    console.log("Searching for location:", locationSearch.value);
    const results = await searchLocationTargeting(locationSearch.value);
    locationResults.value = results;
    console.log("Search results:", results);
  } catch (error) {
    console.error("Fejl ved søgning efter lokation:", error);
    errorMessage.value =
      "Der opstod en fejl ved søgning efter lokation. Prøv igen senere.";
    locationResults.value = [];
  }
};

const debouncedSearchLocation = debounce(searchLocation, 300);

const selectLocation = (location) => {
  selectedLocation.value = location;
  locationSearch.value = location.name;
  locationResults.value = []; // Clear suggestions after selection

  // Update targeting data
  adSetData.targeting.geo_locations = {
    custom_locations: [
      {
        latitude: location.data.latitude,
        longitude: location.data.longitude,
        radius: 10,
        distance_unit: "kilometer",
      },
    ],
  };
};

onMounted(() => {
  fetchUserLocation();
});

// Watchers for debugging
watch(
  () => selectedGeographics.cities,
  (newVal) => {
    console.log("Selected Cities:", newVal);
  },
);

watch(
  () => selectedGeographics.zipCodes,
  (newVal) => {
    console.log("Selected Zip Codes:", newVal);
  },
);

// Submit form
const submitAdSet = async () => {
  isSubmitting.value = true;
  errorMessage.value = "";

  // Validering
  if (selectedDemographics.age.max < selectedDemographics.age.min) {
    errorMessage.value = "Max alder skal være større end min alder.";
    isSubmitting.value = false;
    return;
  }

  if (adSetData.budgetType === "DAILY" && adSetData.budgetInput <= 0) {
    errorMessage.value = "Dagligt budget skal være større end 0.";
    isSubmitting.value = false;
    return;
  }

  if (adSetData.budgetType === "LIFETIME" && adSetData.budgetInput <= 0) {
    errorMessage.value = "Lifetime budget skal være større end 0.";
    isSubmitting.value = false;
    return;
  }

  if (adSetData.spendCapInput < 100) {
    // 100 DKK = 10000 øre
    errorMessage.value = "Spend Cap skal være mindst 100 DKK.";
    isSubmitting.value = false;
    return;
  }

  try {
    // Prepare targeting specs
    const targetingSpecs = {
      geo_locations: {
        cities: selectedGeographics.cities.map((c) => ({
          key: c.value,
          radius:
            radiusSuggestions.value.length > 0
              ? radiusSuggestions.value[0].suggested_radius
              : undefined,
          distance_unit:
            radiusSuggestions.value.length > 0
              ? radiusSuggestions.value[0].distance_unit
              : undefined,
        })),
        zips: selectedGeographics.zipCodes.map((z) => z.value),
      },
      interests: selectedInterests.value.map((i) => ({
        id: i.value,
        name: i.label,
      })),
      demographics: {
        age_min: selectedDemographics.age.min,
        age_max: selectedDemographics.age.max,
        genders:
          selectedDemographics.gender === "ALL"
            ? []
            : selectedDemographics.gender === "MALE"
              ? [1]
              : [2],
        education:
          selectedDemographics.education.length > 0
            ? selectedDemographics.education.map((e) => e.value)
            : undefined,
        job_titles:
          selectedDemographics.jobTitles.length > 0
            ? selectedDemographics.jobTitles.map((j) => j.value)
            : undefined,
      },
      publisher_platforms:
        adSetData.targeting.publisher_platforms.length > 0
          ? adSetData.targeting.publisher_platforms
          : undefined,
    };

    const payload = {
      // Kampagne Data
      campaign_name: adSetData.campaignName,
      objective: adSetData.objective,
      status: adSetData.status,
      spend_cap: adSetData.spendCap, // allerede i øre
      campaign_budget_optimization:
        adSetData.campaignBudgetOptimization === "ON",
      buying_type: adSetData.buyingType,
      pacing_type: adSetData.pacingType,
      special_ad_categories:
        adSetData.specialAdCategories.length > 0
          ? adSetData.specialAdCategories
          : undefined,
      special_ad_category_country:
        adSetData.specialAdCategoryCountry || undefined,
      sk_ad_network_attribution:
        adSetData.skAdNetwork === "true"
          ? true
          : adSetData.skAdNetwork === "false"
            ? false
            : undefined,
      is_using_l3_schedule:
        adSetData.isUsingL3Schedule === "true"
          ? true
          : adSetData.isUsingL3Schedule === "false"
            ? false
            : undefined,

      // Ad Set Data
      name: adSetData.name,
      budget_type: adSetData.budgetType,
      daily_budget:
        adSetData.budgetType === "DAILY" ? adSetData.budget : undefined,
      lifetime_budget:
        adSetData.budgetType === "LIFETIME" ? adSetData.budget : undefined,
      billing_event: adSetData.billingEvent,
      optimization_goal: adSetData.optimizationGoal,
      bid_strategy: adSetData.bidStrategy,
      targeting: targetingSpecs,
      start_time: adSetData.startTime
        ? Math.floor(new Date(adSetData.startTime).getTime() / 1000)
        : undefined,
      end_time: adSetData.stopTime
        ? Math.floor(new Date(adSetData.stopTime).getTime() / 1000)
        : undefined,
    };

    const response = await createAdSet(payload);

    if (response.success) {
      alert("Ad Set oprettet!");
      showModal.value = false;
      resetForm();
    } else {
      throw new Error(response.message || "Ukendt fejl");
    }
  } catch (error) {
    console.error("Fejl ved oprettelse af Ad Set:", error);
    errorMessage.value = error.message || "Fejl ved oprettelse af Ad Set.";
  } finally {
    isSubmitting.value = false;
  }
};

// Reset form
const resetForm = () => {
  Object.assign(adSetData, {
    // Kampagne Data
    campaignName: "",
    objective: "TRAFFIC", // Preset til trafik, men kan ændres
    status: "ACTIVE",
    spendCapInput: 100,
    spendCap: 10000,
    campaignBudgetOptimization: "OFF",
    buyingType: "AUCTION",
    pacingType: "standard",
    specialAdCategories: [],
    specialAdCategoryCountry: "",
    skAdNetwork: "",
    isUsingL3Schedule: "",

    // Ad Set Data
    name: "",
    budgetType: "DAILY",
    budgetInput: 175, // Sat til 175 DKK
    budget: 17500, // 175 DKK i øre
    billingEvent: "IMPRESSIONS",
    optimizationGoal: "REACH",
    bidStrategy: "LOWEST_COST_WITHOUT_CAP",
    targeting: {
      // Reset targeting separately
      geo_locations: {},
      age_min: 18,
      age_max: 65,
      genders: [],
      publisher_platforms: [],
    },
    startTime: "",
    stopTime: "",
  });

  selectedPreset.value = null;
  mode.value = "advanced"; // Sat til "advanced" som standard

  // Reset targeting selections
  Object.assign(selectedGeographics, {
    cities: [],
    zipCodes: [],
  });
  selectedInterests.value = [];
  Object.assign(selectedDemographics, {
    age: { min: 18, max: 65 },
    gender: "ALL",
    education: [],
    jobTitles: [],
  });
  Object.assign(geographicOptions, {
    cities: [],
    zipCodes: [],
  });
  interestOptions.value = [];
  Object.assign(demographicOptions, {
    education: [],
    jobTitles: [],
  });
  radiusSuggestions.value = [];
  Object.assign(radiusLocation, {
    latitude: null,
    longitude: null,
  });
  radiusUnit.value = "mile";
  errorMessage.value = "";
  activeStep.value = 0;
};

watch(
  () => geographicOptions,
  (newVal) => {
    console.log("geographicOptions updated:", newVal);
  },
  { deep: true },
);

const setDefaultDates = () => {
  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];
  adSetData.startTime = formattedToday;

  const endDate = new Date(today.setDate(today.getDate() + 30));
  const formattedEndDate = endDate.toISOString().split("T")[0];
  adSetData.stopTime = formattedEndDate;
};

const generateNames = () => {
  const date = new Date().toISOString().split("T")[0];
  const uniqueId = uuidv4().substring(0, 8);
  adSetData.campaignName = `Kampagne_${date}_${uniqueId}`;
  adSetData.name = `Adset_${date}_${uniqueId}`;
};

const initializeModalData = () => {
  adSetData.campaignName =
    props.campaignName ||
    `Kampagne_${new Date().toISOString().split("T")[0]}_${uuidv4().substring(0, 8)}`;
  adSetData.name = `Adset_${new Date().toISOString().split("T")[0]}_${uuidv4().substring(0, 8)}`;
  adSetData.startTime =
    props.startDate || new Date().toISOString().split("T")[0];
  adSetData.stopTime =
    props.endDate ||
    new Date(new Date().setDate(new Date().getDate() + 30))
      .toISOString()
      .split("T")[0];
  // Initialiser andre felter efter behov
};

const getFormData = () => {
  return { ...adSetData };
};

const removeCheckbox = () => {
  nextTick(() => {
    const removeCheckboxInterval = setInterval(() => {
      const checkbox = document.querySelector(".swal2-checkbox");
      if (checkbox) {
        checkbox.remove();
        clearInterval(removeCheckboxInterval);
      }
    }, 50);

    // Sikkerhedsforanstaltning for at stoppe intervallet efter 5 sekunder
    setTimeout(() => clearInterval(removeCheckboxInterval), 5000);
  });
};

const showAdSetModal = () => {
  initializeModalData();
  showModal.value = true;
  removeCheckbox();
};

// Tilføj en onMounted hook for at fjerne checkboxen, når komponenten monteres
onMounted(() => {
  removeCheckbox();
});

// Kun én defineExpose() er tilladt
defineExpose({ initializeModalData, getFormData, showAdSetModal });
</script>
<style>
.form-label {
  font-weight: bold;
}

.swal2-popup {
  border-radius: 8px !important;
  padding: 20px !important;
  position: relative;
  width: 80% !important;
  max-width: 800px !important;
  text-align: left !important;
}

.swal2-popup .swal2-title {
  font-size: 1.5rem !important;
  font-weight: bold !important;
  text-align: left !important;
  border-bottom: none !important;
}

.swal2-popup .swal2-content,
.swal2-popup .swal2-html-container {
  font-size: 1em !important;
  text-align: left !important;
}

.swal2-popup .swal2-actions .swal2-confirm,
.swal2-popup .swal2-actions .swal2-cancel {
  min-width: 100px !important;
}

.swal2-popup .argon-select-options {
  background-color: rgb(77, 77, 77) !important;
  z-index: 1060;
  position: absolute;
}

.swal2-popup .swal2-content {
  overflow: visible !important;
}

.swal2-popup .argon-select {
  position: relative !important;
}

.swal2-popup .z-index-dropdown {
  z-index: 1000 !important;
  background-color: #541111 !important;
}

.swal2-popup .list-group-item {
  background-color: #583333 !important;
  color: #ffffff !important;
  border: 1px solid #555 !important;
}

.swal2-popup .list-group-item:hover {
  background-color: #5a1717 !important;
  cursor: pointer !important;
}

.swal2-popup input,
.swal2-popup select,
.swal2-popup textarea {
  text-align: left !important;
}

.swal2-popup label {
  text-align: left !important;
  display: block !important;
}

.use-address-btn {
  font-size: 0.9rem; /* Gør teksten lidt mindre */
  padding: 0.375rem 0.75rem; /* Reducer padding for at gøre knappen mindre */
  margin-bottom: 1.5rem; /* Tilføj luft under knappen */
}

/* Hvis du vil have knappen til at være smallere, kan du tilføje denne regel */
.use-address-btn {
  max-width: 200px; /* Juster denne værdi efter behov */
}

/* Hvis du vil centrere knappen, kan du tilføje denne regel */
.use-address-btn {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* Tilføj denne nye style for at skjule checkboxen med CSS */
.swal2-checkbox {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
  position: absolute !important;
  left: -9999px !important;
}
</style>
