import { autoTranslate } from '@/utils/i18nHelper';
import { nextTick, watch } from 'vue';
import i18n from '@/i18n';

export default {
  mounted(el, binding) {
    const updateTranslation = async () => {
      try {
        const text = binding.value || el.textContent.trim();
        if (!text) return;

        const key = await autoTranslate(text);
        if (key) {
          // Vent på næste tick for at sikre i18n er klar
          await nextTick();
          // Brug i18n.global.t direkte i stedet for template syntax
          el.textContent = i18n.global.t(key);
        }
      } catch (error) {
        console.error('Fejl i auto-translate direktiv:', error);
      }
    };

    // Gem original tekst på elementet
    el._originalText = binding.value || el.textContent.trim();

    // Kør oversættelse når elementet er mounted
    updateTranslation();

    // Watch for sprog ændringer
    const stopWatch = watch(() => i18n.global.locale.value, () => {
      console.log('Sprog ændret, opdaterer oversættelse');
      updateTranslation();
    });

    // Gem watch funktionen på elementet så vi kan cleanup
    el._stopWatch = stopWatch;
  },

  updated(el, binding) {
    // Hvis binding value ændrer sig, opdater oversættelsen og original tekst
    if (binding.value !== binding.oldValue) {
      el._originalText = binding.value || el.textContent.trim();
      const text = el._originalText;
      if (text) {
        autoTranslate(text).then(key => {
          if (key) {
            el.textContent = i18n.global.t(key);
          }
        }).catch(error => {
          console.error('Fejl ved opdatering af oversættelse:', error);
        });
      }
    }
  },

  unmounted(el) {
    // Cleanup watch når elementet fjernes
    if (el._stopWatch) {
      el._stopWatch();
    }
  }
}; 