<script setup>
import { ref, computed, reactive, watch } from "vue";
import { auth, db } from "@/firebase";
import { doc, setDoc, collection, addDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { useStore } from "vuex";
import axios from "axios";

const store = useStore();

const emit = defineEmits(["close"]);

const cvrInput = ref("");
const searchResult = ref(null);
const message = ref(null);
const isLoading = ref(false);
const showManualForm = ref(false);
const searchType = ref('normal'); // 'normal' eller 'dealer'

const isAutoChainUser = computed(() => {
  return store.state.activeUser.userType === "AutoChain";
});

const isSuperAdmin = computed(() => {
  return store.state.activeUser.role === 1;
});

const isAdmin = computed(() => {
  return store.state.activeUser.role === 2;
});

const showDealerId = computed(() => {
  return (isAutoChainUser.value || isSuperAdmin.value || isAdmin.value) && 
         manualCompany.companyType === "auto";
});

watch(() => searchResult.value, (newValue) => {
  if (newValue && isAutoChainUser.value) {
    manualCompany.companyType = "auto";
  }
});

const manualCompany = reactive({
  name: "",
  address: "",
  zipcode: "",
  city: "",
  phone: "",
  email: "",
  vat: "",
  dealerId: "",
  companyType: "auto",
  client: "",
});

const dealersList = ref([]);
const showDealersList = ref(false);

const fetchCompanyData = async () => {
  if (!cvrInput.value) {
    message.value = { type: "error", text: "Indtast venligst søgeord." };
    return;
  }

  isLoading.value = true;
  try {
    if (searchType.value === 'normal') {
      const response = await fetch(
        `https://cvrapi.dk/api?country=dk&search=${cvrInput.value}`,
        {
          headers: {
            "User-Agent": "ToftSystem/1.0",
          },
        }
      );
      
      if (!response.ok) throw new Error('CVR API fejl');
      const data = await response.json();
      
      // Udfyld manualCompany med CVR data
      Object.assign(manualCompany, {
        name: data.name || "",
        address: data.address || "",
        zipcode: data.zipcode || "",
        city: data.city || "",
        phone: data.phone || "",
        email: data.email || "",
        vat: data.vat || "",
      });
      
      if (isAutoChainUser.value) {
        manualCompany.companyType = "auto";
      }
      
      message.value = null;
      showManualForm.value = true;
      showDealersList.value = false;
      
    } else {
      const response = await axios.get(
        `https://us-central1-toft-d4f39.cloudfunctions.net/searchDealers?value=${encodeURIComponent(cvrInput.value)}`
      );
      
      if (!response.data.success || !response.data.data.companyList.length) {
        throw new Error('Ingen bilforhandlere fundet');
      }
      
      dealersList.value = response.data.data.companyList;
      showDealersList.value = true;
      showManualForm.value = false;
      message.value = null;
    }
  } catch (error) {
    console.error("Error:", error);
    message.value = { 
      type: "error", 
      text: searchType.value === 'normal' 
        ? "Der opstod en fejl ved søgning i CVR-register." 
        : "Der opstod en fejl ved søgning efter bilforhandler." 
    };
  } finally {
    isLoading.value = false;
  }
};

const fetchCVRData = async (companyName) => {
  try {
    const response = await fetch(
      `https://cvrapi.dk/api?country=dk&name=${encodeURIComponent(companyName)}`,
      {
        headers: {
          "User-Agent": "ToftSystem/1.0", // Husk at ændre dette til jeres system navn
        },
      }
    );

    if (!response.ok) throw new Error('CVR API fejl');
    const data = await response.json();

    return {
      phone: data.phone?.toString() || '',
      email: data.email || '',
      vat: data.vat?.toString() || ''
    };
  } catch (error) {
    console.error("Fejl ved CVR opslag:", error);
    return null;
  }
};

const selectDealer = async (dealer) => {
  // Først sætter vi de grundlæggende data
  manualCompany.name = dealer.name;
  manualCompany.address = dealer.address;
  manualCompany.zipcode = dealer.zipAndCity.split(' ')[0];
  manualCompany.city = dealer.zipAndCity.split(' ').slice(1).join(' ');
  manualCompany.dealerId = dealer.id.toString();
  manualCompany.companyType = 'auto';
  manualCompany.client = 'biltorvet';
  
  // Derefter beriger vi med CVR data
  const cvrData = await fetchCVRData(dealer.name);
  if (cvrData) {
    manualCompany.phone = cvrData.phone;
    manualCompany.email = cvrData.email;
    manualCompany.vat = cvrData.vat;
  }
  
  console.log('manualCompany efter opdatering:', manualCompany);
  
  showDealersList.value = false;
  showManualForm.value = true;
};

const triggerCarUpdate = async (userId) => {
  try {
    console.log('Starter bil-opdatering for bruger:', userId);
    
    // Validér userId
    if (!userId) {
      throw new Error('userId er påkrævet for bil-opdatering');
    }

    const response = await axios.post(
      'https://us-central1-toft-d4f39.cloudfunctions.net/triggerCarUpdate',
      { userId },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.data.success) {
      console.log('Bil-opdatering gennemført:', response.data);
      return response.data;
    } else {
      throw new Error(response.data.error || 'Ukendt fejl ved bil-opdatering');
    }
  } catch (error) {
    console.error('Detaljeret fejl ved bil-opdatering:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status
    });
    
    // Kast fejlen videre så den kan håndteres i handleAddCompany
    throw error;
  }
};

const handleAddCompany = async (isManual = false) => {
  const companyData = isManual ? manualCompany : searchResult.value;

  if (companyData) {
    isLoading.value = true;
    const { name, address, zipcode, city, phone, email, vat, dealerId, companyType, client } = companyData;
    const fullAddress = `${address}, ${zipcode} ${city}`;
    const defaultEmail = email || `${name.replace(/\s+/g, "")}@example.com`;
    const password = "123456789"; // Midlertidig adgangskode

    if (!email && !isManual) {
      message.value = { type: "error", text: "E-mail er påkrævet. Tilføj venligst manuelt." };
      isLoading.value = false;
      return;
    }

    try {
      const functionUrl = "https://us-central1-toft-d4f39.cloudfunctions.net/addUserDataToFirestore";

      const response = await axios.post(functionUrl, {
        email: defaultEmail,
        password: password
      });

      const uid = response.data.data.uid;

      const userData = {
        name: name,
        address: fullAddress,
        phone: phone,
        email: defaultEmail,
        role: 3,
        isOnline: false,
        cvr: vat,
        createdBy: store.state.activeUser.id,
        companyType: companyType || "auto",
        dealerId: dealerId || null,
        active: true,
      };

      if (isSuperAdmin.value && client) {
        userData.client = client;
      }

      await setDoc(doc(db, "users", uid), userData);

      const companyDoc = await addDoc(collection(db, "companies"), {
        name: name,
        address: fullAddress,
        phone: phone,
        email: email || "Ikke oplyst",
        cvr: vat,
        userId: uid,
      });

      await setDoc(
        doc(db, "users", uid),
        { companyId: companyDoc.id },
        { merge: true },
      );

      if (userData.companyType === "auto") {
        try {
          await triggerCarUpdate(uid);
          console.log('Bil-opdatering gennemført for ny virksomhed');
        } catch (updateError) {
          console.error('Fejl ved bil-opdatering:', updateError);
          // Vi fortsætter på trods af fejl i bil-opdateringen
          message.value = {
            type: "warning",
            text: `Virksomhed "${name}" er blevet tilføjet, men der opstod en fejl ved bil-opdateringen. Prøv at opdatere bilerne manuelt senere.`,
          };
        }
      }

      console.log(
        "Bruger og virksomhed oprettet og tilføjet til Firestore:",
        name,
      );
      message.value = {
        type: "success",
        text: `Virksomhed "${name}" er blevet tilføjet succesfuldt.`,
      };
      cvrInput.value = "";
      searchResult.value = null;

      // Vent i 3 sekunder før modalen lukkes
      setTimeout(() => {
        emit("close");
      }, 3000);
    } catch (error) {
      console.error("Error adding company to Firebase:", error);
      console.error("Error code:", error.code);
      console.error("Error message:", error.message);
      message.value = {
        type: "error",
        text: `Fejl ved tilføjelse af virksomhed: ${error.message}`,
      };
    } finally {
      isLoading.value = false;
    }
  }
};

const closeModal = () => {
  emit("close");
};

const toggleManualForm = () => {
  showManualForm.value = !showManualForm.value;
};
</script>

<template>
  <div class="company-add-modal">
    <div class="modal-header">
      <h5 class="modal-title">Tilføj Virksomhed</h5>
      <button class="close-button" @click="closeModal">&times;</button>
    </div>
    <div class="modal-body">
      <div v-if="isSuperAdmin" class="search-type-selector mb-3">
        <div class="btn-group w-100" role="group">
          <ArgonButton
            type="button"
            :color="searchType === 'normal' ? 'primary' : 'secondary'"
            :variant="searchType === 'normal' ? 'gradient' : 'outline'"
            class="w-50"
            @click="searchType = 'normal'"
          >
            Søg cvrnummer
          </ArgonButton>
          <ArgonButton
            type="button"
            :color="searchType === 'dealer' ? 'primary' : 'secondary'"
            :variant="searchType === 'dealer' ? 'gradient' : 'outline'"
            class="w-50"
            @click="searchType = 'dealer'"
          >
            Søg bilforhandler
          </ArgonButton>
        </div>
      </div>

      <form @submit.prevent="fetchCompanyData">
        <ArgonInput
          v-model="cvrInput"
          type="text"
          :placeholder="searchType === 'normal' ? 'CVR-nummer' : 'Søg bilforhandler (navn eller by)'"
          :aria-label="searchType === 'normal' ? 'CVR-nummer' : 'Søg bilforhandler'"
        />
        <ArgonButton
          type="submit"
          full-width
          color="dark"
          variant="gradient"
          class="custom-button mt-3"
          :disabled="isLoading"
        >
          {{ searchType === 'normal' ? 'Søg virksomhed' : 'Søg bilforhandler' }}
        </ArgonButton>
      </form>

      <div class="mt-4 position-relative text-center">
        <p
          class="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3"
        >
          eller
        </p>
      </div>

      <ArgonButton
        full-width
        color="info"
        variant="gradient"
        class="my-4 mb-2"
        @click="toggleManualForm"
      >
        {{ showManualForm ? 'Skjul manuel formular' : 'Vis manuel formular' }}
      </ArgonButton>

      <div v-if="showDealersList" class="dealer-list mt-4">
        <div v-for="dealer in dealersList" :key="dealer.id" 
             class="dealer-item p-3 mb-2 border rounded cursor-pointer"
             @click="selectDealer(dealer)">
          <div class="d-flex justify-content-between align-items-start">
            <div>
              <h6>{{ dealer.name }}</h6>
              <p class="mb-1">{{ dealer.address }}, {{ dealer.zipAndCity }}</p>
              <small class="text-muted">Antal biler: {{ dealer.adCount || 0 }}</small>
            </div>
            <span v-if="dealer.source === 'biltorvet'" 
                  class="badge bg-info">Biltorvet</span>
          </div>
        </div>
      </div>

      <form v-if="showManualForm" class="mt-4" @submit.prevent="handleAddCompany(true)">
        <ArgonInput
          v-model="manualCompany.name"
          type="text"
          placeholder="Virksomhedens navn"
          aria-label="Virksomhedens navn"
        />
        <ArgonInput
          v-model="manualCompany.address"
          type="text"
          placeholder="Adresse"
          aria-label="Adresse"
        />
        <ArgonInput
          v-model="manualCompany.zipcode"
          type="text"
          placeholder="Postnummer"
          aria-label="Postnummer"
        />
        <ArgonInput
          v-model="manualCompany.city"
          type="text"
          placeholder="By"
          aria-label="By"
        />
        <ArgonInput
          v-model="manualCompany.phone"
          type="tel"
          placeholder="Telefon"
          aria-label="Telefon"
        />
        <ArgonInput
          v-model="manualCompany.email"
          type="email"
          placeholder="Email"
          aria-label="Email"
        />
        <ArgonInput
          v-model="manualCompany.vat"
          type="text"
          placeholder="CVR-nummer"
          aria-label="CVR-nummer"
        />
        
        <div v-if="isSuperAdmin || isAdmin" class="mb-3">
          <label class="form-label">Virksomhedstype:</label>
          <select v-model="manualCompany.companyType" class="form-select">
            <option value="auto">Auto</option>
            <option value="client">Klient</option>
          </select>
        </div>

        <ArgonInput
          v-if="showDealerId"
          v-model="manualCompany.dealerId"
          type="text"
          placeholder="Forhandler ID"
          aria-label="Forhandler ID"
        />

        <ArgonInput
          v-if="isSuperAdmin"
          v-model="manualCompany.client"
          type="text"
          placeholder="Klient (valgfrit)"
          aria-label="Klient"
        />
        
        <ArgonButton
          type="submit"
          full-width
          color="dark"
          variant="gradient"
          class="my-4 mb-2"
          :disabled="isLoading"
        >
          Tilføj virksomhed
        </ArgonButton>
      </form>

      <div v-if="isLoading" class="loading-overlay">
        <div class="loading-spinner"></div>
      </div>

      <div
        v-if="message"
        :class="[
          'mt-4 text-center',
          message.type === 'error' ? 'text-danger' : 'text-success',
        ]"
      >
        {{ message.text }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.company-add-modal {
  background: rgba(0, 0, 0, 0.801);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 20px;
  max-width: 500px;
  width: 100%;
  position: relative;
  box-shadow: 
    0 15px 35px rgba(0, 0, 0, 0.2),
    0 0 0 1px rgba(255, 255, 255, 0.1);
  animation: slideUp 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  transform: translateZ(0);
  will-change: transform;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #ffffff;
}

.modal-body {
  max-height: 70vh;
  overflow-y: auto;
}

.text-sm {
  font-size: 0.875rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.text-center {
  text-align: center;
}

.text-secondary {
  color: #6c757d;
}

.bg-white {
  background-color: #ffffff;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.text-danger {
  color: #dc3545;
}

.text-success {
  color: #28a745;
}

.loading-overlay {
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  animation: fadeIn 0.3s cubic-bezier(0.2, 0, 0.2, 1);
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
}

.form-label {
  color: #ffffff;
  margin-bottom: 0.5rem;
  display: block;
}

.form-select {
  background: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  border-radius: 12px;
  padding: 12px 20px;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-select:focus {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
  outline: none;
}

.search-type-selector {
  margin-bottom: 1rem;
}

.btn-group {
  display: flex;
  gap: 8px;
}

.w-50 {
  width: 50%;
}

.mt-3 {
  margin-top: 1rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.dealer-list {
  max-height: 300px;
  overflow-y: auto;
}

.dealer-item {
  background: rgba(255, 255, 255, 0.07);
  transition: background-color 0.2s;
  cursor: pointer;
}

.dealer-item:hover {
  background: rgba(255, 255, 255, 0.15);
}

.badge {
  padding: 0.5em 0.75em;
  border-radius: 6px;
  font-size: 0.75rem;
}

.bg-info {
  background-color: rgba(13, 202, 240, 0.2) !important;
  color: #0dcaf0;
}
</style>
