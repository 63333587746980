<template>
  <div class="privacy-policy-page">
    <div class="privacy-content">
      <div class="company-info">
        <ul>
          <li>MakeMore ApS</li>
          <li>Birkevej 4</li>
          <li>5492 Vissenbjerg</li>
          <li>TLF: +45 31 39 31 94</li>
          <li>CVR: 31393194</li>
        </ul>
      </div>

      <section>
  <h3>Generelle vilkår</h3>
  <p>MakeMore ApS tilbyder en abonnementsbaseret online service via hjemmesiden makemore.dk. Disse betingelser gælder mellem hver enkelt bruger og MakeMore ApS. Kun personer, der er fyldt 18 år og bosiddende i Danmark, kan oprette en aftale om brug af tjenesten.</p>
  <p>For at tilgå tjenesten skal brugeren acceptere disse betingelser. Ved at registrere sig og benytte tjenesten accepterer brugeren automatisk vilkårene.</p>
</section>

<section>
  <h3>Omfang af tjenesten</h3>
  <p>Tjenesten giver brugeren adgang til de ydelser, der er specificeret ved bestilling. For at få adgang skal brugeren oprette en konto på platformen. Ved at acceptere vores samtykke giver brugeren tilladelse til, at vi kan behandle deres data i overensstemmelse med gældende lovgivning for at levere den aftalte service.</p>
</section>

<section>
  <h3>Ansvarsfraskrivelse</h3>
  <p>MakeMore ApS kan ikke holdes ansvarlig for eventuelle fejl, der opstår i forbindelse med behandling af brugerens data. Det anbefales, at brugeren regelmæssigt gennemgår deres data for at undgå fejl og mangler.</p>
</section>

<section>
  <h3>Annoncering med MakeMore</h3>
  <p>Ved brug af vores annonceringstjenester kan der blive pålagt et gebyr. Dette gebyr kan enten være en fast månedlig pris eller en procentdel af de samlede annonceudgifter, afhængigt af den valgte tjeneste. Gebyrer pålægges kun efter aftale med brugeren.</p>
</section>

<section>
  <h3>Oprettelse og adgang</h3>
  <p>Brugeren er ansvarlig for at angive korrekte oplysninger ved registrering. Brugeren skal vælge et unikt brugernavn og adgangskode, som ikke må deles med andre. Ved mistanke om misbrug skal MakeMore ApS straks informeres, og adgangskoden skal ændres.</p>
</section>

<section>
  <h3>Betaling og gebyrer</h3>
  <p>Alle betalinger for tjenesten sker forud i henhold til den gældende prisliste, som er tilgængelig på hjemmesiden. Brugeren skal sørge for, at der er tilstrækkelige midler til rådighed for at gennemføre betalinger.</p>
</section>

<section>
  <h3>Ændringer i vilkår</h3>
  <p>MakeMore ApS forbeholder sig retten til at ændre priser og vilkår. Eventuelle ændringer meddeles mindst 30 dage før ikrafttræden. Brugeren har mulighed for at opsige abonnementet, hvis de ikke ønsker at acceptere de nye betingelser.</p>
</section>

<section>
  <h3>Opsigelse</h3>
  <p>Abonnementet kan opsiges med virkning fra slutningen af den aktuelle betalingsperiode. Opsigelse skal ske skriftligt til we@makemore.dk.</p>
</section>

<section>
  <h3>Privatlivspolitik</h3>
  <p>MakeMore ApS er forpligtet til at beskytte dine personoplysninger i overensstemmelse med gældende databeskyttelseslovgivning, herunder GDPR. Vi indsamler og behandler kun de oplysninger, der er nødvendige for at levere vores tjenester til dig.</p>
  <p>De oplysninger, vi indsamler, kan omfatte:</p>
  <ul>
    <li>Firmanavn</li>
    <li>CVR-nummer</li>
    <li>Adresse</li>
    <li>Telefonnummer</li>
    <li>Email</li>
  </ul>
  <p>Formålet med indsamlingen er at kunne levere vores tjenester til dig, administrere kundeforholdet og overholde lovgivningsmæssige krav.</p>
  <p>Vi opbevarer dine oplysninger så længe, det er nødvendigt for at opfylde de formål, hvortil de er indsamlet, eller for at overholde lovgivningsmæssige krav. Personoplysningerne opbevares hos MakeMore ApS og slettes, når de ikke længere er nødvendige.</p>
  <p>Du har ret til at få indsigt i de oplysninger, vi behandler om dig, få dem rettet eller slettet, gøre indsigelse mod behandlingen og få udleveret en kopi af dine data. Henvendelse herom kan ske til we@makemore.dk.</p>
  <p>Hvis du mener, at vores behandling af dine personoplysninger ikke overholder lovgivningen, har du ret til at klage til Datatilsynet.</p>
</section>

<section>
  <h3>Cookies</h3>
  <p>Vi anvender cookies på makemore.dk for at optimere hjemmesiden og dens funktionalitet og dermed gøre dit besøg så nemt som muligt. Du kan til enhver tid slette cookies fra din computer. Se vejledningen på vores hjemmeside.</p>
</section>

<section>
  <h3>Log-statistik</h3>
  <p>Vi bruger en log-statistik på makemore.dk, hvilket betyder, at et statistiksystem opsamler information, som kan give et statistisk billede af, hvor mange besøgende vi har, hvor de kommer fra, og hvor på websitet det forlades mv. Logstatistikken anvendes kun med det formål at optimere MakeMore ApS's løsninger.</p>
</section>


    </div>
  </div>
</template>

<style scoped>
.privacy-policy-page {
  background-color: #16181b;
  min-height: 100vh;
  padding: 8rem 2rem 4rem;
  font-family: Poppins, sans-serif;
}

.privacy-content {
  max-width: 800px;
  margin: 0 auto;
  background: #1d2025;
  padding: 3rem;
  border-radius: 16px;
  border: 1px solid rgba(45, 154, 206, 0.1);
}

.company-info ul {
  list-style: none;
  padding: 0;
  margin: 0 0 2rem;
  color: #86868b;
}

.company-info li {
  margin-bottom: 0.5rem;
}

section {
  margin-bottom: 2.5rem;
}

h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #fff;
  background: linear-gradient(90deg, #fff, #e7dfd6);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #fff;
  background: linear-gradient(90deg, #fff, #e7dfd6);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

p {
  color: #86868b;
  line-height: 1.6;
  margin-bottom: 1rem;
}

ul {
  color: #86868b;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

li {
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .privacy-policy-page {
    padding: 6rem 1rem 2rem;
  }

  .privacy-content {
    padding: 2rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.25rem;
  }
}

@media (max-width: 480px) {
  .privacy-content {
    padding: 1.5rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.125rem;
  }
}
</style> 