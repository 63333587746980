<template>
  <div class="demo-car-selection">
    <div class="main-content">
      <div class="card-body">
        <div class="row g-4">
          <div
            v-for="car in cars"
            :key="car.id"
            class="car-card"
            :class="{ selected: selectedCarId === car.id }"
            @click="selectCar(car)"
          >
            <div class="car-image">
              <img 
                :src="getFirstImage(car.attachments)" 
                :alt="car.fields?.Mærke"
              />
            </div>
            <div class="card-footer">
              <h6>{{ car.fields?.Mærke }} {{ car.fields?.Model }}</h6>
              <small>ID: {{ car.id }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '@/firebase';

const props = defineProps({
  userId: {
    type: String,
    required: true
  }
});

const emit = defineEmits(['car-selected']);

const cars = ref([]);
const selectedCarId = ref(null);

const fetchCars = async () => {
  try {
    const dealerCarsRef = collection(db, 'users', props.userId, 'dealerCars');
    const querySnapshot = await getDocs(dealerCarsRef);
    
    // Hent al brugerdata
    const userDoc = await getDoc(doc(db, 'users', props.userId));
    const userData = userDoc.data() || {};
    
    cars.value = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      dealerId: props.userId,
      userData: userData, // Inkluder al brugerdata
      dealerName: userData?.name || 'Ukendt forhandler'
    }));
  } catch (error) {
    console.error('Fejl ved hentning af biler:', error);
  }
};

const selectCar = (car) => {
  selectedCarId.value = car.id;
  emit('car-selected', car);
};

const getFirstImage = (attachments) => {
  if (!attachments) return '';
  return attachments['image_1'] || '';
};

onMounted(() => {
  fetchCars();
});
</script>

<style scoped>
.demo-car-selection {
  height: 100%;
  background: #1a1e24;
}

.main-content {
  height: 100%;
  max-height: 700px;
  overflow-y: auto;
}

.card-body {
  padding: 0 !important;
}

.row.g-4 {
  grid-template-columns: 1fr;
  gap: 0;
  margin: 0;
}

.car-card {
  height: auto;
  display: grid;
  grid-template-columns: 80px 1fr;
  padding: 0.25rem 0.5rem;
  gap: 0.5rem;
  background: #1a1e24;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.03);
  border-radius: 0;
  transition: background-color 0.2s ease;
  margin: 0;
  cursor: pointer;
}

.car-card:hover {
  background: #22262d;
}

.car-card.selected {
  background: #22262d;
  border-left: 2px solid #5e72e4;
}

.car-image {
  width: 80px;
  height: 45px;
  border-radius: 3px;
  overflow: hidden;
}

.car-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-footer {
  padding: 0;
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-footer h6 {
  font-size: 0.8rem;
  margin: 0;
  color: #fff;
  line-height: 1.1;
}

.card-footer small {
  font-size: 0.65rem;
  color: rgba(255, 255, 255, 0.4);
  margin-top: 0.1rem;
}
</style> 