<script setup>
import { ref, onMounted, reactive, watch, computed } from "vue";
import * as Choices from "choices.js";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc, updateDoc, addDoc, collection, serverTimestamp } from "firebase/firestore";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { usePasswordManagement } from './components/usePasswordManagement';
import { whiteLabelService } from '@/services/whiteLabelService';
import { domainService } from '@/services/domainService';
import { updateWhiteLabel } from '@/services/whiteLabel';

const auth = getAuth();
const db = getFirestore();
const storage = getStorage();

const userName = ref('');
const uploadedFile = ref(null);
const userRole = ref(null);
const profileImageFile = ref(null);
const profileImageName = ref('');
const coverImageName = ref('');
const existingProfileImage = ref('');
const existingCoverImage = ref('');

// Tilføj disse nye reaktive variabler
const profileImagePreview = ref(null);
const coverImagePreview = ref(null);
const domainSetupInProgress = ref(false);
const domainMessage = ref(null);

// Tilføj disse nye refs
const isTestDomain = ref(false);
const subdomainInput = ref('');
const subdomainValidation = ref({ isValid: true, message: '' });

// Opret en lokal reaktiv kopi af brugerdata
const localUserData = reactive({
  name: '',
  adminSettings: {
    notifyViaEmail: false,
    smsConfirmation: false,
    checkDevices: false,
    findDevice: false,
    lockDevice: false,
    manageApps: false,
    MetaSettings: {
      userToken: '',
      adAccountId: '',
      appId: ''
    },
    InvoiceSettings: {
      companyName: '',
      address: '',
      postalCode: '',
      city: '',
      cvr: '',
      phone: '',
      email: '',
      website: '',
      bankReg: '',
      bankAccount: '',
      footerText: ''
    },
    whiteLabel: {
      theme: {
        primaryColor: '#5e72e4',
        secondaryColor: '#8392ab',
        accentColor: '#2dce89',
        backgroundColor: '#16181b',
        sidenavColor: 'linear-gradient(122deg,#1568db,#05c2ed)',
        sidenavGradient: true,
        sidenavGradientFrom: '#1568db',
        sidenavGradientTo: '#05c2ed',
        cardColor: '#202124',
        textColor: 'rgba(255, 255, 255, 0.8)',
        navWrapperColor: '#2c2c3b',
        textTheme: 'light'
      },
      logo: null,
      favicon: null,
      loginLogo: null,
      customCSS: '',
      typography: {
        primaryFont: 'Inter',
        baseFontSize: '16'
      },
      browserSettings: {
        title: '',
        favicon: null
      }
    }
  }
});

const {
  resetEmail,
  errorMessage,
  successMessage,
  resetPassword,
  loadUserEmail
} = usePasswordManagement();

const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    uploadedFile.value = file;
    coverImageName.value = file.name;
  }
};

const handleProfileImageUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    profileImageFile.value = file;
    profileImageName.value = file.name;
    // Opret en URL til forhåndsvisning af billedet
    profileImagePreview.value = URL.createObjectURL(file);
  }
};

const handleCoverImageUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    uploadedFile.value = file;
    coverImageName.value = file.name;
    // Opret en URL til forhåndsvisning af billedet
    coverImagePreview.value = URL.createObjectURL(file);
  }
};

const updateProfile = async () => {
  if (userRole.value !== 1 && userRole.value !== 2) {
    console.log('Kun brugere med rolle 1 eller 2 kan opdatere profilen');
    return;
  }

  const userId = auth.currentUser.uid;
  console.log('Nuværende bruger ID:', userId);
  console.log('Brugerrolle:', userRole.value);

  const userRef = doc(db, "users", userId);

  let updateData = {
    name: userName.value,
    adminSettings: {
      ...localUserData.adminSettings,
      MetaSettings: {
        userToken: localUserData.adminSettings.MetaSettings.userToken,
        adAccountId: localUserData.adminSettings.MetaSettings.adAccountId,
        appId: localUserData.adminSettings.MetaSettings.appId
      }
    }
  };

  if (uploadedFile.value) {
    try {
      const imagePath = `headerImages/${userId}/${uploadedFile.value.name}`;
      console.log('Forsøger at uploade til:', imagePath);
      const imageRef = storageRef(storage, imagePath);
      await uploadBytes(imageRef, uploadedFile.value);
      const downloadURL = await getDownloadURL(imageRef);
      updateData.adminSettings.headerImage = downloadURL;
      console.log('Coverbillede uploadet succesfuldt');
    } catch (error) {
      console.error('Fejl ved upload af coverbillede:', error);
      alert(`Der opstod en fejl ved upload af coverbilledet: ${error.message}`);
      return;
    }
  }

  if (profileImageFile.value) {
    try {
      const imageRef = storageRef(storage, `profileImages/${userId}/${profileImageFile.value.name}`);
      await uploadBytes(imageRef, profileImageFile.value);
      const downloadURL = await getDownloadURL(imageRef);
      updateData.adminSettings.profileImage = downloadURL;
    } catch (error) {
      console.error('Fejl ved upload af profilbillede:', error);
      alert('Der opstod en fejl ved upload af profilbilledet. Prøv venligst igen.');
      return;
    }
  }

  try {
    console.log('Opdaterer Firebase med:', updateData);
    await updateDoc(userRef, updateData);
    console.log('Profil og indstillinger opdateret i Firebase:', updateData);
    alert('Profil og indstillinger opdateret succesfuldt!');
  } catch (error) {
    console.error('Fejl ved opdatering af profil og indstillinger i Firebase:', error);
    alert('Der opstod en fejl ved opdatering af profilen og indstillingerne. Prøv venligst igen.');
  }
};

const toggleSetting = async (settingKey, event) => {
  const settingValue = event.target.checked;
  console.log(`Toggling ${settingKey} to ${settingValue}`);

  // Opdater lokal tilstand
  localUserData.adminSettings[settingKey] = settingValue;

  // Opdater Firestore
  const user = auth.currentUser;
  if (user) {
    const userDocRef = doc(db, "users", user.uid);
    const updateData = {};
    updateData[`adminSettings.${settingKey}`] = settingValue;

    try {
      await updateDoc(userDocRef, updateData);
      console.log(`Updated ${settingKey} to ${settingValue} in Firestore.`);
    } catch (error) {
      console.error("Error updating settings in Firestore:", error);
    }
  }
};

const loadProfileData = async () => {
  const userId = auth.currentUser.uid;
  const userRef = doc(db, "users", userId);
  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    const data = userSnap.data();
    userRole.value = data.role;
    if (userRole.value === 1 || userRole.value === 2) {
      userName.value = data.name || '';
      if (data.adminSettings) {
        // Initialiser gradient indstillinger hvis de ikke findes
        if (!data.adminSettings.whiteLabel?.theme?.sidenavGradient) {
          if (!data.adminSettings.whiteLabel) {
            data.adminSettings.whiteLabel = { theme: {} };
          }
          if (!data.adminSettings.whiteLabel.theme) {
            data.adminSettings.whiteLabel.theme = {};
          }
          data.adminSettings.whiteLabel.theme.sidenavGradient = true;
          data.adminSettings.whiteLabel.theme.sidenavGradientFrom = '#2d9ace';
          data.adminSettings.whiteLabel.theme.sidenavGradientTo = '#2d5bce';
          data.adminSettings.whiteLabel.theme.sidenavColor = 'linear-gradient(122deg,#1568db,#05c2ed);';
        }
        
        Object.assign(localUserData.adminSettings, data.adminSettings);
        
        // Log de eksisterende white label indstillinger
        console.log('Eksisterende white label settings:', data.adminSettings.whiteLabel);
        
        // Hvis der ikke er nogen eksisterende whiteLabel indstillinger, brug defaults
        if (!data.adminSettings.whiteLabel) {
          data.adminSettings.whiteLabel = {
            theme: {
              ...localUserData.adminSettings.whiteLabel.theme
            }
          };
        } else if (!data.adminSettings.whiteLabel.theme.sidenavGradient) {
          // Hvis der er whiteLabel men ingen gradient indstillinger, initialiser dem
          data.adminSettings.whiteLabel.theme.sidenavGradient = true;
          data.adminSettings.whiteLabel.theme.sidenavGradientFrom = '#1568db';
          data.adminSettings.whiteLabel.theme.sidenavGradientTo = '#05c2ed';
          data.adminSettings.whiteLabel.theme.sidenavColor = 'linear-gradient(122deg,#1568db,#05c2ed);';
        }
        
        Object.assign(localUserData.adminSettings, data.adminSettings);
        
        // Sikr at browserSettings eksisterer og har de korrekte vrdier
        if (data.adminSettings.whiteLabel?.browserSettings) {
          localUserData.adminSettings.whiteLabel.browserSettings = {
            title: data.adminSettings.whiteLabel.browserSettings.title || '',
            favicon: data.adminSettings.whiteLabel.browserSettings.favicon || null
          };
        }
        
        if (data.adminSettings.profileImage) {
          existingProfileImage.value = data.adminSettings.profileImage;
          profileImageName.value = 'Eksisterende profilbillede';
        }
        if (data.adminSettings.headerImage) {
          existingCoverImage.value = data.adminSettings.headerImage;
          coverImageName.value = 'Eksisterende coverbillede';
        }
        
        if (data.adminSettings.MetaSettings) {
          localUserData.adminSettings.MetaSettings.userToken = data.adminSettings.MetaSettings.userToken || '';
          localUserData.adminSettings.MetaSettings.adAccountId = data.adminSettings.MetaSettings.adAccountId || '';
          localUserData.adminSettings.MetaSettings.appId = data.adminSettings.MetaSettings.appId || '';
        }
        
        // Initialiser browserSettings hvis det ikke findes
        if (!data.adminSettings.whiteLabel?.browserSettings) {
          localUserData.adminSettings.whiteLabel.browserSettings = {
            title: '',
            favicon: null
          };
        }
      }
      
      if (data.adminSettings?.InvoiceSettings) {
        Object.assign(localUserData.adminSettings.InvoiceSettings, data.adminSettings.InvoiceSettings);
      }
    } else {
      console.log('Brugeren har ikke tilladelse til at redigere profilen');
    }
  }
};

onMounted(async () => {
  if (document.getElementById("choices-questions")) {
    var element = document.getElementById("choices-questions");
    new Choices(element, { allowHTML: true });
  }
  
  await loadProfileData();
  loadUserEmail();
});

// Tilføj menuItems og activeSection
const activeSection = ref('security');

const menuItems = [
  { id: 'security', label: 'Sikkerhedsindstillinger', icon: 'fas fa-shield-alt' },
  { id: 'password', label: 'Adgangskode', icon: 'fas fa-key' },
  { id: 'profile', label: 'Profil', icon: 'fas fa-user' },
  { id: 'meta', label: 'Meta Indstillinger', icon: 'fab fa-facebook' },
  { id: 'invoice', label: 'Faktura Indstillinger', icon: 'fas fa-file-invoice' },
  { id: 'whitelabel', label: 'White Label', icon: 'fas fa-palette', roleRequired: 2 }
];

// Tilføj metoder til at håndtere white label uploads og opdateringer
const handleLogoUpload = async (event) => {
  const file = event.target.files[0];
  if (file) {
    try {
      const logoRef = storageRef(storage, `whitelabel/${auth.currentUser.uid}/logo`);
      await uploadBytes(logoRef, file);
      const logoUrl = await getDownloadURL(logoRef);
      localUserData.adminSettings.whiteLabel.logo = logoUrl;
      successMessage.value = 'Logo uploadet!';
    } catch (error) {
      console.error('Fejl ved upload af logo:', error);
      errorMessage.value = 'Kunne ikke uploade logo';
    }
  }
};

const handleFaviconUpload = async (event) => {
  const file = event.target.files[0];
  if (file) {
    try {
      const faviconRef = storageRef(storage, `whitelabel/${auth.currentUser.uid}/favicon`);
      await uploadBytes(faviconRef, file);
      const faviconUrl = await getDownloadURL(faviconRef);
      console.log('Favicon URL:', faviconUrl);
      localUserData.adminSettings.whiteLabel.browserSettings.favicon = faviconUrl;
      
      // Gem ændringerne med det samme
      await saveWhiteLabelSettings();
      
      successMessage.value = 'Favicon uploadet!';
    } catch (error) {
      console.error('Fejl ved upload af favicon:', error);
      errorMessage.value = 'Kunne ikke uploade favicon';
    }
  }
};

const handleHeaderImageUpload = async (event) => {
  const file = event.target.files[0];
  if (file) {
    const storageRef = ref(storage, `whitelabel/${auth.currentUser.uid}/header`);
    await uploadBytes(storageRef, file);
    const headerUrl = await getDownloadURL(storageRef);
    localUserData.adminSettings.whiteLabel.headerImage = headerUrl;
  }
};

const handleLoginLogoUpload = async (event) => {
  const file = event.target.files[0];
  if (file) {
    const loginLogoRef = storageRef(storage, `whitelabel/${auth.currentUser.uid}/login-logo`);
    await uploadBytes(loginLogoRef, file);
    const loginLogoUrl = await getDownloadURL(loginLogoRef);
    localUserData.adminSettings.whiteLabel.loginLogo = loginLogoUrl;
  }
};

const saveWhiteLabelSettings = async () => {
  try {
    const userId = auth.currentUser.uid;
    const userRef = doc(db, 'users', userId);
    
    // Opret et updateData objekt med alle white label indstillinger
    const updateData = {
      'adminSettings.whiteLabel': {
        theme: localUserData.adminSettings.whiteLabel.theme,
        browserSettings: {
          title: localUserData.adminSettings.whiteLabel.browserSettings.title,
          favicon: localUserData.adminSettings.whiteLabel.browserSettings.favicon
        }
      }
    };

    // Gem ændringerne i Firestore
    await updateDoc(userRef, updateData);
    
    // Opdater white label indstillinger med force = true for at ignorere cache
    await updateWhiteLabel(true);

  } catch (error) {
    console.error('Fejl ved gem af white label indstillinger:', error);
    errorMessage.value = 'Der opstod en fejl ved gemning af indstillingerne';
    
    setTimeout(() => {
      errorMessage.value = '';
    }, 3000);
  }
};

const resetTheme = () => {
  if (confirm('Er du sikker på at du vil nulstille alle farver til standard?')) {
    const defaultTheme = {
      backgroundColor: '#16181b',
      sidenavColor: 'linear-gradient(122deg,#1568db,#05c2ed);',  // Original gradient-success
      cardColor: '#202124',
      textColor: 'rgba(255, 255, 255, 0.8)',
      navWrapperColor: '#2c2c3b'
    };

    Object.assign(localUserData.adminSettings.whiteLabel.theme, defaultTheme);
    updateProfile();
    
    // Opdater også CSS variablen direkte
    document.documentElement.style.setProperty('--sidenav-color', defaultTheme.sidenavColor);
    
    successMessage.value = 'Farverne er blevet nulstillet til standard';
    
    setTimeout(() => {
      successMessage.value = '';
    }, 3000);
  }
};

// Tilføj watch på theme ændringer
watch(() => localUserData.adminSettings.whiteLabel.theme, async (newTheme) => {
  console.log('Theme changed in Settings:', newTheme);
  try {
    const userRef = doc(db, 'users', auth.currentUser.uid);
    await updateDoc(userRef, {
      'adminSettings.whiteLabel.theme': newTheme
    });
    
    // Opdater cache i whiteLabelService
    whiteLabelService.clearCache(auth.currentUser.uid);
    
    // Trigger en manuel opdatering af temaet
    const updatedSettings = await whiteLabelService.getAdminSettings(auth.currentUser.uid);
    if (updatedSettings?.theme) {
      document.documentElement.style.setProperty('--background-color', updatedSettings.theme.backgroundColor);
      document.documentElement.style.setProperty('--sidenav-color', updatedSettings.theme.sidenavColor);
      document.documentElement.style.setProperty('--card-color', updatedSettings.theme.cardColor);
      document.documentElement.style.setProperty('--text-color', updatedSettings.theme.textColor);
      document.documentElement.style.setProperty('--nav-wrapper-color', updatedSettings.theme.navWrapperColor);
    }
  } catch (error) {
    console.error('Fejl ved opdatering af tema:', error);
    errorMessage.value = 'Kunne ikke gemme farveændringer';
  }
}, { deep: true });

// Tilføj watch på browserSettings
watch(() => localUserData.adminSettings.whiteLabel.browserSettings, (newSettings) => {
  console.log('Browser settings changed:', newSettings);
  if (newSettings.title) {
    document.title = newSettings.title;
  }
  
  const favicon = document.querySelector('link[rel="icon"]');
  if (newSettings.favicon) {
    if (!favicon) {
      const newFavicon = document.createElement('link');
      newFavicon.rel = 'icon';
      newFavicon.type = 'image/png';
      newFavicon.href = newSettings.favicon;
      document.head.appendChild(newFavicon);
    } else {
      favicon.href = newSettings.favicon;
    }
  }
}, { deep: true });

// Opdater isValidSubdomain computed property
const isValidSubdomain = computed(() => {
  const subdomain = localUserData.adminSettings.whiteLabel.browserSettings.subdomain;
  if (!subdomain) return false;
  const subdomainRegex = /^[a-z0-9][a-z0-9-]{4,61}[a-z0-9]$/;  // Minimum 6 tegn (første + 4 + sidste)
  return subdomainRegex.test(subdomain);
});

// Tilføj denne funktion til at generere et unikt subdomæne
const generateUniqueSubdomain = (baseName) => {
  const timestamp = Date.now().toString(36);
  const random = Math.random().toString(36).substring(2, 6);
  
  // Sørg for at subdomænet starter med 'fir-'
  const prefix = 'fir';
  const cleanBaseName = baseName === 'test' ? 'demo' : baseName;
  
  // Fjern 'fir-' fra baseNamet hvis det allerede findes
  const cleanedBaseName = cleanBaseName.replace(/^fir-/, '');
  
  return `${prefix}-${cleanedBaseName}-${timestamp}-${random}`.toLowerCase();
};

// Opdater testDomain funktionen
const testDomain = async () => {
  try {
    domainMessage.value = {
      type: 'info',
      text: 'Tester domain setup...'
    };

    // Generer et unikt demo-domæne
    const testSubdomain = generateUniqueSubdomain('demo');
    console.log('Testing with subdomain:', testSubdomain);

    const result = await domainService.setupSubdomain(testSubdomain);
    console.log('Domain setup resultat:', result);
    
    domainMessage.value = {
      type: 'success',
      text: `Test gennemført: ${result.message}`
    };
  } catch (error) {
    console.error('Fejl ved domain setup:', error);
    domainMessage.value = {
      type: 'error',
      text: error.message
    };
  }
};

// Opdater setupSubdomain funktionen
const setupSubdomain = async () => {
  if (!isValidSubdomain.value) {
    domainMessage.value = {
      type: 'error',
      text: 'Ugyldigt subdomæne format. Subdomænet skal starte med "fir-" efterfulgt af minimum 6 tegn.'
    };
    return;
  }

  try {
    domainSetupInProgress.value = true;
    domainMessage.value = null;

    const baseSubdomain = localUserData.adminSettings.whiteLabel.browserSettings.subdomain;
    // Fjern 'fir-' prefix hvis det allerede findes
    const cleanedSubdomain = baseSubdomain.replace(/^fir-/, '');
    const uniqueSubdomain = generateUniqueSubdomain(cleanedSubdomain);

    console.log('Attempting to create subdomain:', uniqueSubdomain);

    const result = await domainService.setupSubdomain(uniqueSubdomain);

    if (result.success) {
      // Gem domænet i domains collection
      await addDoc(collection(db, 'domains'), {
        domain: result.domain,
        subdomain: uniqueSubdomain,
        userId: auth.currentUser.uid,
        createdAt: serverTimestamp(),
        status: 'active'
      });

      domainMessage.value = {
        type: 'success',
        text: `Subdomæne ${result.domain} er nu opsat!`
      };
      
      // Opdater browserSettings i Firestore med det faktiske tildelte domæne
      localUserData.adminSettings.whiteLabel.browserSettings.subdomain = uniqueSubdomain;
      await updateProfile();
      
      // Opdater browser titel hvis den ikke allerede er sat
      if (!localUserData.adminSettings.whiteLabel.browserSettings.title) {
        localUserData.adminSettings.whiteLabel.browserSettings.title = uniqueSubdomain;
      }
    }
  } catch (error) {
    console.error('Fejl ved opsætning af subdomæne:', error);
    domainMessage.value = {
      type: 'error',
      text: error.message
    };
  } finally {
    domainSetupInProgress.value = false;
  }
};

// Tilføj denne metode
const validateSubdomain = () => {
  if (!subdomainInput.value) {
    subdomainValidation.value = { isValid: false, message: 'Subdomæne er påkrævet' };
    return;
  }
  subdomainValidation.value = domainService.validateSubdomain(subdomainInput.value, isTestDomain.value);
};

// Opdater setupDomain metoden
const setupDomain = async () => {
  try {
    domainSetupInProgress.value = true;
    domainMessage.value = null;

    // Valider subdomænet først
    validateSubdomain();
    if (!subdomainValidation.value.isValid) {
      throw new Error(subdomainValidation.value.message);
    }

    const result = await domainService.setupSubdomain(subdomainInput.value, isTestDomain.value);
    domainMessage.value = {
      type: 'success',
      text: `Domæne oprettet: ${result.domain}`
    };
  } catch (error) {
    console.error('Fejl ved oprettelse af domæne:', error);
    domainMessage.value = {
      type: 'error',
      text: error.message
    };
  } finally {
    domainSetupInProgress.value = false;
  }
};

const validateHexColor = (event, key) => {
  let hex = event.target.value;
  
  // Tilføj # hvis det mangler
  if (hex && !hex.startsWith('#')) {
    hex = '#' + hex;
    event.target.value = hex;
  }
  
  // Tillad indtastning mens brugeren skriver
  if (hex.length <= 7) {
    localUserData.adminSettings.whiteLabel.theme[key] = hex;
    return;
  }
  
  // Valider det endelige format når længden er korrekt
  const isValid = /^#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$/.test(hex);
  if (!isValid) {
    console.warn(`Ugyldigt hex-format for ${key}: ${hex}`);
    // Behold den gamle værdi hvis den nye er ugyldig
    event.target.value = localUserData.adminSettings.whiteLabel.theme[key];
  }
};

const removeFavicon = () => {
  localUserData.adminSettings.whiteLabel.browserSettings.favicon = null;
  // Fjern også favicon fra browser hvis det er sat
  const favicon = document.querySelector('link[rel="icon"]');
  if (favicon) {
    favicon.href = '';
  }
};

// Tilføj disse nye funktioner
const setLightTheme = async () => {
  try {
    // Opdater kun tekstfarver til lyst tema (sort tekst)
    const textSettings = {
      textColor: '#000000'
    };

    // Opdater lokale tema indstillinger
    localUserData.adminSettings.whiteLabel.theme.textColor = textSettings.textColor;
    localUserData.adminSettings.whiteLabel.theme.textTheme = 'dark';

    // Opdater CSS variabler
    document.documentElement.style.setProperty('--text-color', textSettings.textColor);

    // Tilføj light-text class til body
    document.body.classList.remove('dark-text');
    document.body.classList.add('light-text');

    // Gem ændringerne
    await saveWhiteLabelSettings();
    
    successMessage.value = 'Lys tekstfarve aktiveret!';
    setTimeout(() => {
      successMessage.value = '';
    }, 3000);
  } catch (error) {
    console.error('Fejl ved aktivering af lys tekstfarve:', error);
    errorMessage.value = 'Kunne ikke aktivere lys tekstfarve';
  }
};

const setDarkTheme = async () => {
  try {
    // Opdater kun tekstfarver til mørkt tema (hvid tekst)
    const textSettings = {
      textColor: '#ffffff'
    };

    // Opdater lokale tema indstillinger
    localUserData.adminSettings.whiteLabel.theme.textColor = textSettings.textColor;
    localUserData.adminSettings.whiteLabel.theme.textTheme = 'light';

    // Opdater CSS variabler
    document.documentElement.style.setProperty('--text-color', textSettings.textColor);

    // Tilføj dark-text class til body
    document.body.classList.remove('light-text');
    document.body.classList.add('dark-text');

    // Gem ændringerne
    await saveWhiteLabelSettings();
    
    successMessage.value = 'Mørk tekstfarve aktiveret!';
    setTimeout(() => {
      successMessage.value = '';
    }, 3000);
  } catch (error) {
    console.error('Fejl ved aktivering af mørk tekstfarve:', error);
    errorMessage.value = 'Kunne ikke aktivere mørk tekstfarve';
  }
};

// Opdater DEFAULT_GRADIENT konstanten
const DEFAULT_GRADIENT = {
  from: '#05c2ed',  // turkis
  to: '#1568db'     // blå
};

// Opdater toggleGradient funktionen
const toggleGradient = async () => {
  const theme = localUserData.adminSettings.whiteLabel.theme;
  theme.sidenavGradient = !theme.sidenavGradient;
  
  if (theme.sidenavGradient) {
    theme.sidenavGradientFrom = DEFAULT_GRADIENT.from;  // turkis
    theme.sidenavGradientTo = DEFAULT_GRADIENT.to;      // blå
    theme.sidenavColor = `linear-gradient(122deg, ${DEFAULT_GRADIENT.from}, ${DEFAULT_GRADIENT.to})`;
  } else {
    theme.sidenavColor = '#4b4b4b';
  }
  
  await saveWhiteLabelSettings();
  await updateWhiteLabel(true);
};

// Opdater updateGradient funktionen
const updateGradient = async () => {
  const theme = localUserData.adminSettings.whiteLabel.theme;
  if (theme.sidenavGradient) {
    theme.sidenavColor = `linear-gradient(122deg, ${theme.sidenavGradientFrom}, ${theme.sidenavGradientTo})`;
    await saveWhiteLabelSettings();
    await updateWhiteLabel(true);
  }
};

// Tilføj watch på gradient farver
watch(() => [
  localUserData.adminSettings.whiteLabel.theme.sidenavGradientFrom,
  localUserData.adminSettings.whiteLabel.theme.sidenavGradientTo
], () => {
  if (localUserData.adminSettings.whiteLabel.theme.sidenavGradient) {
    updateGradient();
  }
}, { deep: true });

</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <!-- Venstre kolonne med navigation -->
      <div class="col-md-3">
        <div class="card">
          <div class="card-body p-3">
            <ul class="nav flex-column bg-white border-radius-lg">
              <li class="nav-item" v-for="item in menuItems" :key="item.id">
                <a 
                  class="nav-link text-body d-flex align-items-center" 
                  :class="{ 'active': activeSection === item.id }"
                  href="#" 
                  @click.prevent="activeSection = item.id"
                >
                  <i :class="item.icon + ' me-2 text-dark opacity-6'"></i>
                  <span class="text-sm">{{ item.label }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Midterste kolonne med indhold -->
      <div class="col-md-6">
        <div class="card">
          <div class="card-body p-3">
              <!-- Sikkerhedsindstillinger -->
              <div v-if="activeSection === 'security'" class="tab-content">
              <div class="card-header pb-0 p-3">
                <h6 class="mb-1">Sikkerhedsindstillinger</h6>
                <br>
                <br>
              </div>
              <div class="form-group d-flex align-items-center justify-content-between">
                <span class="text-sm">Giv mig besked via e-mail ved login</span>
                <ArgonSwitch 
                  :checked="localUserData.adminSettings.notifyViaEmail" 
                  id="notify-via-email" 
                  name="Email"
                  @change="toggleSetting('notifyViaEmail', $event)"
                />
              </div>
              <div class="form-group d-flex align-items-center justify-content-between">
                <span class="text-sm">Send SMS-bekræftelse for alle online betalinger</span>
                <ArgonSwitch 
                  :checked="localUserData.adminSettings.smsConfirmation" 
                  id="sms-confirmation" 
                  name="SMS"
                  @change="toggleSetting('smsConfirmation', $event)"
                />
              </div>
              <div class="form-group d-flex align-items-center justify-content-between">
                <span class="text-sm">Kontroller hvilke enheder der har adgang til din konto</span>
                <ArgonSwitch 
                  :checked="localUserData.adminSettings.checkDevices" 
                  id="check-devices" 
                  name="Devices"
                  @change="toggleSetting('checkDevices', $event)"
                />
              </div>
              <div class="form-group d-flex align-items-center justify-content-between">
                <span class="text-sm">Find min enhed, sørg for at din enhed kan findes, hvis den bliver væk</span>
                <ArgonSwitch 
                  :checked="localUserData.adminSettings.findDevice" 
                  id="find-device" 
                  name="Find Device"
                  @change="toggleSetting('findDevice', $event)"
                />
              </div>
              <div class="form-group d-flex align-items-center justify-content-between">
                <span class="text-sm">Lås din enhed med en PIN, mønster eller adgangskode</span>
                <ArgonSwitch 
                  :checked="localUserData.adminSettings.lockDevice" 
                  id="lock-device" 
                  name="Lock Device"
                  @change="toggleSetting('lockDevice', $event)"
                />
              </div>
              <div class="form-group d-flex align-items-center justify-content-between">
                <span class="text-sm">Administrer hvilke apps der har adgang til app-brugsdata på din enhed</span>
                <ArgonSwitch 
                  :checked="localUserData.adminSettings.manageApps" 
                  id="manage-apps" 
                  name="Apps"
                  @change="toggleSetting('manageApps', $event)"
                />
              </div>
              <div class="d-flex justify-content-end mt-4">
                <ArgonButton 
                  @click="updateProfile" 
                  color="success" 
                  size="sm"
                  variant="gradient"
                >
                  Gem ændringer
                </ArgonButton>
              </div>
            </div>

            <!-- Password sektion -->
            <div v-if="activeSection === 'password'" class="tab-content">
              <div class="card-header pb-0 p-3">
                <h6 class="mb-1">Adgangskode</h6>
                <p class="text-sm mb-0">Nulstil din adgangskode her.</p>
              </div>
              <div class="card-body">
                <ArgonInput v-model="resetEmail" id="reset-email" type="email" placeholder="Din e-mailadresse" label="E-mail" />
                <ArgonButton @click="resetPassword" color="info" class="w-100">
                  Send nulstillingslink
                </ArgonButton>
                <p v-if="errorMessage" class="text-danger mt-3">{{ errorMessage }}</p>
                <p v-if="successMessage" class="text-success mt-3">{{ successMessage }}</p>
                <div class="d-flex justify-content-end mt-4">
                  <ArgonButton 
                    @click="updateProfile" 
                    color="success" 
                    size="sm"
                    variant="gradient"
                  >
                    Gem ændringer
                  </ArgonButton>
                </div>
              </div>
            </div>

            <!-- Profil sektion -->
            <div v-if="activeSection === 'profile'" class="tab-content">
              <div class="card-header pb-0 p-3">
                <h6 class="mb-1">Rediger profil</h6>
                <p class="text-sm mb-0">Opdater dit profilbillede, coverbillede og navn her.</p>
              </div>
              <div class="card-body">
                <div class="row mb-4">
                  <div class="col-12 d-flex align-items-center">
                    <div class="me-4">
                      <div class="image-upload-container profile-container mb-2">
                        <input type="file" id="profileImageUpload" @change="handleProfileImageUpload" accept="image/*" class="image-upload-input">
                        <label for="profileImageUpload" class="image-upload-label">
                          <img v-if="profileImagePreview" :src="profileImagePreview" alt="Profilbillede" class="preview-image">
                          <img v-else-if="existingProfileImage" :src="existingProfileImage" alt="Profilbillede" class="preview-image">
                          <div v-else class="upload-icon-wrapper">
                            <i class="fas fa-user-circle"></i>
                          </div>
                        </label>
                      </div>
                      <label class="form-label text-center d-block small">Profilbillede</label>
                    </div>
                    <div>
                      <div class="image-upload-container cover-container mb-2">
                        <input type="file" id="coverImageUpload" @change="handleCoverImageUpload" accept="image/*" class="image-upload-input">
                        <label for="coverImageUpload" class="image-upload-label">
                          <img v-if="coverImagePreview" :src="coverImagePreview" alt="Coverbillede" class="preview-image cover-image">
                          <img v-else-if="existingCoverImage" :src="existingCoverImage" alt="Coverbillede" class="preview-image cover-image">
                          <div v-else class="upload-icon-wrapper">
                            <i class="fas fa-image"></i>
                          </div>
                        </label>
                      </div>
                      <label class="form-label text-center d-block small">Coverbillede</label>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-label">Navn</label>
                  <input v-model="userName" type="text" class="form-control" placeholder="Dit navn">
                </div>
                <div class="d-flex justify-content-end mt-4">
                  <ArgonButton 
                    @click="updateProfile" 
                    color="success" 
                    size="sm"
                    variant="gradient"
                  >
                    Gem ændringer
                  </ArgonButton>
                </div>
              </div>
            </div>

            <!-- Meta indstillinger -->
            <div v-if="activeSection === 'meta'" class="tab-content">
              <div class="card-header pb-0 p-3">
                <h6 class="mb-1">Meta-indstillinger</h6>
                <p class="text-sm mb-0">Indtast dine Meta-indstillinger her.</p>
              </div>
              <div class="card-body">
                <div class="form-group">
                  <label class="form-label">User Token</label>
                  <input 
                    v-model="localUserData.adminSettings.MetaSettings.userToken" 
                    type="text" 
                    class="form-control" 
                    placeholder="Indtast User Token"
                  >
                </div>
                <div class="form-group mt-3">
                  <label class="form-label">Ad Account ID</label>
                  <input 
                    v-model="localUserData.adminSettings.MetaSettings.adAccountId" 
                    type="text" 
                    class="form-control" 
                    placeholder="Indtast Ad Account ID"
                  >
                </div>
                <div class="form-group mt-3">
                  <label class="form-label">App ID</label>
                  <input 
                    v-model="localUserData.adminSettings.MetaSettings.appId" 
                    type="text" 
                    class="form-control" 
                    placeholder="Indtast App ID"
                  >
                </div>
                <div class="d-flex justify-content-end mt-4">
                  <ArgonButton 
                    @click="updateProfile" 
                    color="success" 
                    size="sm"
                    variant="gradient"
                  >
                    Gem ændringer
                  </ArgonButton>
                </div>
              </div>
            </div>

            <!-- Faktura indstillinger -->
            <div v-if="activeSection === 'invoice'" class="tab-content">
              <div class="card-header pb-0 p-3">
                <h6 class="mb-1">Faktura Indstillinger</h6>
                <p class="text-sm mb-0">Indtast dine faktureringsoplysninger her.</p>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label">Firmanavn</label>
                      <input 
                        v-model="localUserData.adminSettings.InvoiceSettings.companyName" 
                        type="text" 
                        class="form-control" 
                        placeholder="F.eks. MakeMore ApS"
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label">CVR-nummer</label>
                      <input 
                        v-model="localUserData.adminSettings.InvoiceSettings.cvr" 
                        type="text" 
                        class="form-control" 
                        placeholder="F.eks. 45454545"
                      >
                    </div>
                  </div>
                </div>

                <div class="form-group mt-3">
                  <label class="form-label">Adresse</label>
                  <input 
                    v-model="localUserData.adminSettings.InvoiceSettings.address" 
                    type="text" 
                    class="form-control" 
                    placeholder="F.eks. Birkevej 4"
                  >
                </div>

                <div class="row mt-3">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label">Postnummer</label>
                      <input 
                        v-model="localUserData.adminSettings.InvoiceSettings.postalCode" 
                        type="text" 
                        class="form-control" 
                        placeholder="F.eks. 5492"
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label">By</label>
                      <input 
                        v-model="localUserData.adminSettings.InvoiceSettings.city" 
                        type="text" 
                        class="form-control" 
                        placeholder="F.eks. Vissenbjerg"
                      >
                    </div>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label">Telefon</label>
                      <input 
                        v-model="localUserData.adminSettings.InvoiceSettings.phone" 
                        type="text" 
                        class="form-control" 
                        placeholder="F.eks. +45 31 39 31 94"
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label">Email</label>
                      <input 
                        v-model="localUserData.adminSettings.InvoiceSettings.email" 
                        type="email" 
                        class="form-control" 
                        placeholder="F.eks. we@makemore.dk"
                      >
                    </div>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label">Reg.nr.</label>
                      <input 
                        v-model="localUserData.adminSettings.InvoiceSettings.bankReg" 
                        type="text" 
                        class="form-control" 
                        placeholder="F.eks. 9277"
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label">Kontonummer</label>
                      <input 
                        v-model="localUserData.adminSettings.InvoiceSettings.bankAccount" 
                        type="text" 
                        class="form-control" 
                        placeholder="F.eks. 1910555738"
                      >
                    </div>
                  </div>
                </div>

                <div class="form-group mt-3">
                  <label class="form-label">Website</label>
                  <input 
                    v-model="localUserData.adminSettings.InvoiceSettings.website" 
                    type="text" 
                    class="form-control" 
                    placeholder="F.eks. www.makemore.dk"
                  >
                </div>

                <div class="form-group mt-3">
                  <label class="form-label">Faktura footer tekst</label>
                  <input 
                    v-model="localUserData.adminSettings.InvoiceSettings.footerText" 
                    type="text" 
                    class="form-control" 
                    placeholder="F.eks. MakeMore ApS / Birkevej 4 / 5492 Vissenbjerg"
                  >
                </div>
                <div class="d-flex justify-content-end mt-4">
                  <ArgonButton 
                    @click="updateProfile" 
                    color="success" 
                    size="sm"
                    variant="gradient"
                  >
                    Gem ændringer
                  </ArgonButton>
                </div>
              </div>
            </div>

            <!-- White Label sektion -->
            <div v-if="activeSection === 'whitelabel'" class="tab-content white-label-section">
              <div class="section-header mb-5">
                <h6 class="mb-1">White Label Indstillinger</h6>
                <p class="text-sm text-muted">Tilpas platformens udseende til dit brand, alle ændringer sker live.</p>
              </div>
              
              <div class="white-label-content">
                <!-- Farver -->
                <div class="settings-group mb-5">
                  <div class="d-flex justify-content-between align-items-center mb-4">
                    <div>
                      <h6 class="mb-1">Farvetema</h6>
                      <p class="text-sm text-muted mb-0">Definer dit brands farveidentitet her.</p>
                    </div>
                    <ArgonButton
                      @click="resetTheme"
                      color="secondary"
                      size="sm"
                      class="reset-button"
                      variant="outline"
                    >
                      <i class="fas fa-undo me-2"></i>
                      Nulstil
                    </ArgonButton>
                  </div>
                  
                  <div v-if="successMessage" class="alert alert-success-subtle mb-4 fade-in">
                    {{ successMessage }}
                  </div>
                  
                  <div class="color-theme-grid">
                    <div class="row g-3">
                      <!-- Baggrund -->
                      <div class="col-md-6">
                        <div class="theme-card">
                          <div class="theme-card-content">
                            <h6>Baggrund</h6>
                            <div class="color-picker">
                              <div class="color-input-wrapper">
                                <input 
                                  type="color"
                                  :value="localUserData.adminSettings.whiteLabel.theme.backgroundColor"
                                  @input="e => localUserData.adminSettings.whiteLabel.theme.backgroundColor = e.target.value"
                                  class="color-input"
                                />
                                <div class="color-preview" :style="{ backgroundColor: localUserData.adminSettings.whiteLabel.theme.backgroundColor }">
                                  <i class="fas fa-fill-drip"></i>
                                </div>
                              </div>
                              <input 
                                type="text" 
                                class="hex-input"
                                :value="localUserData.adminSettings.whiteLabel.theme.backgroundColor"
                                @input="validateHexColor($event, 'backgroundColor')"
                                maxlength="7"
                                spellcheck="false"
                                autocomplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Header -->
                      <div class="col-md-6">
                        <div class="theme-card">
                          <div class="theme-card-content">
                            <div class="d-flex justify-content-between align-items-center mb-2">
                              <h6>Header</h6>
                              <div class="gradient-toggle">
                                <ArgonSwitch
                                  :checked="localUserData.adminSettings.whiteLabel.theme.sidenavGradient"
                                  @change="toggleGradient"
                                />
                                <span class="gradient-label">Gradient</span>
                              </div>
                            </div>
                            <div v-if="localUserData.adminSettings.whiteLabel.theme.sidenavGradient" class="gradient-controls">
                              <div class="gradient-color">
                                <label>Fra</label>
                                <div class="color-input-wrapper">
                                  <input
                                    type="color"
                                    v-model="localUserData.adminSettings.whiteLabel.theme.sidenavGradientFrom"
                                    class="color-input"
                                  />
                                  <div class="color-preview" :style="{ backgroundColor: localUserData.adminSettings.whiteLabel.theme.sidenavGradientFrom }">
                                    <i class="fas fa-paint-brush"></i>
                                  </div>
                                </div>
                              </div>
                              <div class="gradient-color">
                                <label>Til</label>
                                <div class="color-input-wrapper">
                                  <input
                                    type="color"
                                    v-model="localUserData.adminSettings.whiteLabel.theme.sidenavGradientTo"
                                    class="color-input"
                                  />
                                  <div class="color-preview" :style="{ backgroundColor: localUserData.adminSettings.whiteLabel.theme.sidenavGradientTo }">
                                    <i class="fas fa-paint-brush"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-else class="color-picker">
                              <div class="color-input-wrapper">
                                <input 
                                  type="color"
                                  :value="localUserData.adminSettings.whiteLabel.theme.sidenavColor"
                                  @input="e => localUserData.adminSettings.whiteLabel.theme.sidenavColor = e.target.value"
                                  class="color-input"
                                />
                                <div class="color-preview" :style="{ backgroundColor: localUserData.adminSettings.whiteLabel.theme.sidenavColor }">
                                  <i class="fas fa-paint-brush"></i>
                                </div>
                              </div>
                              <input 
                                type="text" 
                                class="hex-input"
                                :value="localUserData.adminSettings.whiteLabel.theme.sidenavColor"
                                @input="validateHexColor($event, 'sidenavColor')"
                                maxlength="7"
                                spellcheck="false"
                                autocomplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Kort -->
                      <div class="col-md-6">
                        <div class="theme-card">
                          <div class="theme-card-content">
                            <h6>Kort</h6>
                            <div class="color-picker">
                              <div class="color-input-wrapper">
                                <input 
                                  type="color"
                                  :value="localUserData.adminSettings.whiteLabel.theme.cardColor"
                                  @input="e => localUserData.adminSettings.whiteLabel.theme.cardColor = e.target.value"
                                  class="color-input"
                                />
                                <div class="color-preview" :style="{ backgroundColor: localUserData.adminSettings.whiteLabel.theme.cardColor }">
                                  <i class="fas fa-layer-group"></i>
                                </div>
                              </div>
                              <input 
                                type="text" 
                                class="hex-input"
                                :value="localUserData.adminSettings.whiteLabel.theme.cardColor"
                                @input="validateHexColor($event, 'cardColor')"
                                maxlength="7"
                                spellcheck="false"
                                autocomplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Browser Indstillinger -->
                <div class="settings-group mb-5">
                  <div class="d-flex justify-content-between align-items-center mb-4">
                    <div>
                      <h6 class="mb-1">Browser Tilpasning</h6>
                      <p class="text-sm text-muted mb-0">Tilpas hvordan din applikation vises i browseren</p>
                    </div>
                  </div>
                  
                  <div class="browser-settings-container">
                    <div class="browser-settings-row">
                      <!-- Browser Titel -->
                      <div class="browser-setting-field">
                        <div class="setting-header">
                          <i class="fas fa-heading setting-icon"></i>
                          <div class="setting-title">
                            <label class="form-label mb-0">Browser Titel</label>
                            <small class="text-muted d-block">Titlen der vises i browser fanen</small>
                          </div>
                        </div>
                        <div class="setting-content">
                          <input
                            type="text"
                            class="form-control modern-input"
                            v-model="localUserData.adminSettings.whiteLabel.browserSettings.title"
                            placeholder="F.eks. Min Awesome App"
                          />
                        </div>
                      </div>

                      <!-- Favicon Upload -->
                      <div class="browser-setting-field">
                        <div class="setting-header">
                          <i class="fas fa-image setting-icon"></i>
                          <div class="setting-title">
                            <label class="form-label mb-0">Favicon</label>
                            <small class="text-muted d-block">Det lille ikon der vises i browser fanen</small>
                          </div>
                        </div>
                        <div class="setting-content">
                          <div class="favicon-upload-container">
                            <div class="preview-section" v-if="localUserData.adminSettings.whiteLabel.browserSettings.favicon">
                              <img 
                                :src="localUserData.adminSettings.whiteLabel.browserSettings.favicon" 
                                alt="Favicon" 
                                class="favicon-preview"
                              />
                              <button class="remove-favicon" @click="removeFavicon">
                                <i class="fas fa-times"></i>
                              </button>
                            </div>
                            <div class="upload-section">
                              <input
                                type="file"
                                class="d-none"
                                id="faviconUpload"
                                accept="image/*"
                                @change="handleFaviconUpload"
                              />
                              <label for="faviconUpload" class="upload-label">
                                <i class="fas fa-cloud-upload-alt me-2"></i>
                                <span>{{ localUserData.adminSettings.whiteLabel.browserSettings.favicon ? 'Skift favicon' : 'Upload favicon' }}</span>
                              </label>
                            </div>
                          </div>
                          <small class="text-muted d-block mt-2">
                            Anbefalet størrelse: 32x32 pixels, format: PNG
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Domæne Opsætning -->
                <div class="settings-group mb-5">
                  <h6 class="mb-4">Domæne Opsætning</h6>
                  
                  <div class="domain-settings">
                    <div class="form-group">
                      <label class="form-label">Subdomæne</label>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control modern-input"
                          v-model="subdomainInput"
                          @input="validateSubdomain"
                          :class="{ 'is-invalid': !subdomainValidation.isValid }"
                          placeholder="Indtast ønsket subdomæne"
                        >
                        <div class="input-group-append">
                          <span class="input-group-text">.web.app</span>
                        </div>
                      </div>
                      <small 
                        :class="subdomainValidation.isValid ? 'text-success' : 'text-danger'"
                      >
                        {{ subdomainValidation.message }}
                      </small>
                      <small class="form-text text-muted">
                        Vælg et unikt subdomæne for din applikation (minimum 6 tegn).
                      </small>
                    </div>

                    <div class="mt-4">
                      <ArgonButton
                        color="primary"
                        @click="setupDomain"
                        :disabled="domainSetupInProgress || !subdomainValidation.isValid"
                        class="me-2"
                      >
                        <span v-if="domainSetupInProgress" class="spinner-border spinner-border-sm me-2" role="status"></span>
                        {{ domainSetupInProgress ? 'Opretter domæne...' : 'Opret Domæne' }}
                      </ArgonButton>
                    </div>

                    <div v-if="domainMessage" class="mt-3">
                      <div 
                        :class="`alert alert-${domainMessage.type === 'success' ? 'success' : 'danger'}`"
                        role="alert"
                      >
                        {{ domainMessage.text }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Højre kolonne (hvis nødvendig) -->
      <div class="col-md-3">
        <!-- Tilføj eventuelt indhold her -->
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.image-upload-container {
  position: relative;
  border: 2px solid #3a416f;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.profile-container {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  right: -10px;
}

.cover-container {
  width: 200px;
  height: 60px;
}

.image-upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.image-upload-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative; /* Tilføj denne */
  left: -5px; /* Flyt labelen 5px til venstre */
}

/* Hvis du vil justere billedet specifikt */
.image-upload-label img {
  object-position: left; /* Dette vil justere billedet til venstre inden for dets container */
}

/* Hvis du vil justere ikonet specifikt */
.image-upload-label i {
  margin-left: 0px; /* Tilføj lidt margin til venstre af ikonet */
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cover-image {
  object-position: center;
}

.upload-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.upload-icon-wrapper i {
  font-size: 1.5rem;
  color: #3a416f;
}

.form-label.small {
  font-size: 0.75rem;
  margin-top: 0.25rem;
  text-align: center;
  width: 100%;
}

/* Tilføj disse nye styles baseret på design guiden */

/* Card styling */
.card {
  background: var(--card-color) !important;
  border-radius: 1rem !important;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  transition: none !important;
  overflow: hidden;
}

.card:hover {
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
  transform: none !important;
  transition: none !important;
  background: var(--card-color) !important;
}

/* Fjern transitions og hover effekter på card elementer */
.card, 
.card-body,
.card-header,
.tab-content,
.form-group {
  transition: none !important;
  transform: none !important;
  animation: none !important;
  will-change: auto !important;
  background: var(--card-color) !important;
}

.card-body {
  border-radius: 1rem !important;
}

.card-header {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.card:hover,
.card-body:hover,
.card-header:hover,
.tab-content:hover,
.form-group:hover {
  transform: none !important;
  transition: none !important;
  box-shadow: none !important;
  background: var(--card-color) !important;
}

/* Input styling */
.form-control {
  background: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  border-radius: 12px;
  padding: 12px 20px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-control:focus {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
  outline: none;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Image upload containers */
.image-upload-container {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 16px rgba(31, 38, 135, 0.2);
}

.image-upload-container:hover {
  background: rgba(255, 255, 255, 0.08);
  border-color: rgba(255, 255, 255, 0.2);
}

/* Text styling */
h6 {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 600;
  letter-spacing: 0.5px;
}

.text-sm {
  color: rgba(255, 255, 255, 0.7);
}

/* Custom button styling (tilføj :deep for at ramme child komponenter) */
:deep(.custom-button) {
  background: rgba(255, 255, 255, 0.08) !important;
  backdrop-filter: blur(8px) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 12px !important;
  padding: 8px 16px !important;
  color: white !important;
  font-weight: 500 !important;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1) !important;
  transform: translateZ(0);
  will-change: transform, background-color;
  box-shadow: 
    0 2px 5px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(255, 255, 255, 0.05) !important;
}

/* Animation keyframes */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
}

/* Tilføj animation til cards */
.card {
  animation: slideUp 0.3s cubic-bezier(0.2, 0, 0.2, 1);
}

/* Switch component styling */
:deep(.argon-switch) {
  background: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

:deep(.argon-switch.checked) {
  background: linear-gradient(
    135deg, 
    rgba(66, 186, 150, 0.15), 
    rgba(66, 186, 150, 0.25)
  ) !important;
}

/* Button styling baseret på design guiden */
:deep(.argon-button) {
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 8px 16px;
  color: white;
  font-weight: 500;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

:deep(.argon-button:hover) {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Success button variant */
:deep(.argon-button.btn-success) {
  background: linear-gradient(
    135deg, 
    rgba(66, 186, 150, 0.15), 
    rgba(66, 186, 150, 0.25)
  );
  border-color: rgba(66, 186, 150, 0.3);
}

/* Outline success variant */
:deep(.argon-button.btn-outline-success) {
  background: transparent;
  border: 1px solid rgba(66, 186, 150, 0.5);
  color: rgba(66, 186, 150, 0.9);
}

/* Info button variant */
:deep(.argon-button.btn-info) {
  background: linear-gradient(
    135deg, 
    rgba(33, 150, 243, 0.15), 
    rgba(33, 150, 243, 0.25)
  );
  border-color: rgba(33, 150, 243, 0.3);
}

/* Small size variant */
:deep(.argon-button.btn-sm) {
  padding: 6px 12px;
  font-size: 0.875rem;
}

/* Full width variant */
:deep(.argon-button.w-100) {
  width: 100%;
  justify-content: center;
}

/* Hover effects */
:deep(.argon-button:hover) {
  transform: translateY(-1px) translateZ(0);
  box-shadow: 
    0 4px 12px rgba(0, 0, 0, 0.15),
    0 0 0 1px rgba(255, 255, 255, 0.1);
}

:deep(.argon-button.btn-success:hover) {
  background: linear-gradient(
    135deg, 
    rgba(66, 186, 150, 0.25), 
    rgba(66, 186, 150, 0.35)
  );
}

:deep(.argon-button.btn-outline-success:hover) {
  background: rgba(66, 186, 150, 0.1);
}

:deep(.argon-button.btn-info:hover) {
  background: linear-gradient(
    135deg, 
    rgba(33, 150, 243, 0.25), 
    rgba(33, 150, 243, 0.35)
  );
}

/* Active state */
:deep(.argon-button:active) {
  transform: translateY(0) translateZ(0);
  box-shadow: 
    0 2px 4px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(255, 255, 255, 0.05);
}

/* Disabled state */
:deep(.argon-button:disabled) {
  opacity: 0.9;
  cursor: not-allowed;
  transform: none;
}

/* Loading state (hvis du har en loading state) */
:deep(.argon-button.loading) {
  position: relative;
  pointer-events: none;
}

:deep(.argon-button.loading::after) {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top-color: white;
  border-radius: 50%;
  animation: button-loading-spinner 0.6s linear infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

/* Spacing mellem knapper */
:deep(.argon-button + .argon-button) {
  margin-left: 8px;
}

/* Tilføj disse nye styles */
.nav-item {
  margin-bottom: 0.5rem;
}

.nav-link {
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  transition: all 0.15s ease;
}

.nav-link:hover {
  background-color: #717171;
}

.nav-link.active {
  background-color: #5e72e4;
  color: white !important;
}

.nav-link.active i,
.nav-link.active span {
  color: white !important;
}

.tab-content {
  min-height: 300px;
}

/* Opdater nav styling */
.nav.bg-white {
  background-color: #202124 !important;
  box-shadow: none !important;
}

.nav.flex-column.bg-white.border-radius-lg {
  box-shadow: none !important;
  background: transparent !important;
}

.form-control[type="color"] {
  height: 40px;
  padding: 2px;
}

.img-thumbnail {
  object-fit: contain;
  width: 100%;
  max-height: 100px;
}

/* Tilføj i style sektionen */
.favicon-preview {
  width: 32px;
  height: 32px;
  object-fit: contain;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  padding: 2px;
}

.upload-button-container {
  flex: 1;
}

.gap-3 {
  gap: 1rem;
}

.white-label-section {
  .section-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 1.5rem;
  }

  .settings-group {
    background: rgba(255, 255, 255, 0.02);
    border-radius: 1rem;
    padding: 2rem;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.05);
  }

  .color-theme-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 1.5rem;
  }

  .color-theme-item {
    .color-picker-wrapper {
      background: rgba(255, 255, 255, 0.03);
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 12px;
      padding: 1rem;
      transition: all 0.3s ease;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      &:hover {
        background: rgba(255, 255, 255, 0.05);
        transform: translateY(-2px);
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
      }

      .color-preview-container {
        display: flex;
        align-items: center;
        gap: 1rem;
      }

      .color-preview-section {
        position: relative;
        width: 48px;
        height: 48px;
      }

      .color-preview {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 
          0 2px 10px rgba(0, 0, 0, 0.1),
          inset 0 0 0 1px rgba(255, 255, 255, 0.1);
        
        i {
          color: rgba(255, 255, 255, 0.7);
          font-size: 1.25rem;
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
        }
      }

      .color-input {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        
        &:focus + .color-preview {
          outline: 2px solid rgba(94, 114, 228, 0.5);
          border-radius: 12px;
        }
      }

      .color-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
      }

      .color-label {
        font-weight: 500;
        color: rgba(255, 255, 255, 0.9);
        font-size: 0.9rem;
      }

      .color-value-container {
        position: relative;
        width: 100%;
        
        .hex-input {
          width: 100%;
          background: rgba(255, 255, 255, 0.05);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 6px;
          padding: 4px 8px;
          color: rgba(255, 255, 255, 0.8);
          font-family: monospace;
          font-size: 0.8rem;
          transition: all 0.2s ease;
          
          &:hover {
            background: rgba(255, 255, 255, 0.08);
            border-color: rgba(255, 255, 255, 0.2);
          }
          
          &:focus {
            outline: none;
            background: rgba(255, 255, 255, 0.1);
            border-color: rgba(94, 114, 228, 0.5);
            box-shadow: 0 0 0 2px rgba(94, 114, 228, 0.2);
          }
          
          &::placeholder {
            color: rgba(255, 255, 255, 0.4);
          }
        }
      }
    }
  }

  .alert-success-subtle {
    background: rgba(45, 206, 137, 0.1);
    border: 1px solid rgba(45, 206, 137, 0.2);
    color: #2dce89;
    border-radius: 12px;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &::before {
      content: '✓';
      font-weight: bold;
    }
  }
}

.domain-settings {
  .custom-control {
    background: rgba(255, 255, 255, 0.02);
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    margin-bottom: 0.5rem;
    
    &:hover {
      background: rgba(255, 255, 255, 0.05);
    }
  }

  .input-group {
    .modern-input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .input-group-text {
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.7);
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }

  .alert {
    background: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.9);
    
    &.alert-success {
      background: rgba(45, 206, 137, 0.1);
      border-color: rgba(45, 206, 137, 0.2);
      color: #2dce89;
    }
    
    &.alert-danger {
      background: rgba(251, 99, 64, 0.1);
      border-color: rgba(251, 99, 64, 0.2);
      color: #fb6340;
    }
  }
}

.custom-radio {
  padding-left: 1.5rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1rem;
  opacity: 0;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  cursor: pointer;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #5e72e4;
  border-color: #5e72e4;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
  border-radius: 50%;
}

.custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.color-value-container {
  position: relative;
  width: 100%;
  
  .hex-input {
    width: 100%;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    padding: 4px 8px;
    color: rgba(255, 255, 255, 0.8);
    font-family: monospace;
    font-size: 0.8rem;
    transition: all 0.2s ease;
    
    &:hover {
      background: rgba(255, 255, 255, 0.08);
      border-color: rgba(255, 255, 255, 0.2);
    }
    
    &:focus {
      outline: none;
      background: rgba(255, 255, 255, 0.1);
      border-color: rgba(94, 114, 228, 0.5);
      box-shadow: 0 0 0 2px rgba(94, 114, 228, 0.2);
    }
    
    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
    }
  }
}

.color-input {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  
  &:focus + .color-preview {
    outline: 2px solid rgba(94, 114, 228, 0.5);
    border-radius: 12px;
  }
}

.browser-settings-container {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 1.5rem;
}

.browser-settings-row {
  display: flex;
  gap: 2rem;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.browser-setting-field {
  flex: 1;
  min-width: 0; // Forhindrer flex-overflow

  .setting-header {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;

    .setting-icon {
      font-size: 1.25rem;
      color: rgba(255, 255, 255, 0.7);
      background: rgba(94, 114, 228, 0.1);
      padding: 0.75rem;
      border-radius: 10px;
      min-width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .setting-title {
      flex: 1;
      min-width: 0;

      .form-label {
        font-weight: 500;
        color: rgba(255, 255, 255, 0.9);
        margin-bottom: 0.25rem;
      }

      small {
        font-size: 0.8rem;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .setting-content {
    .modern-input {
      width: 100%;
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      padding: 0.75rem 1rem;
      color: rgba(255, 255, 255, 0.9);
      transition: all 0.2s ease;

      &:hover {
        background: rgba(255, 255, 255, 0.08);
        border-color: rgba(255, 255, 255, 0.2);
      }

      &:focus {
        outline: none;
        background: rgba(255, 255, 255, 0.1);
        border-color: rgba(94, 114, 228, 0.5);
        box-shadow: 0 0 0 2px rgba(94, 114, 228, 0.2);
      }

      &::placeholder {
        color: rgba(255, 255, 255, 0.4);
      }
    }

    .favicon-upload-container {
      display: flex;
      align-items: center;
      gap: 1rem;

      .preview-section {
        position: relative;
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        padding: 0.5rem;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;

        .favicon-preview {
          max-width: 32px;
          max-height: 32px;
          object-fit: contain;
        }

        .remove-favicon {
          position: absolute;
          top: -8px;
          right: -8px;
          background: rgba(251, 99, 64, 0.9);
          border: none;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 0.75rem;
          cursor: pointer;
          transition: all 0.2s ease;

          &:hover {
            background: #fb6340;
            transform: scale(1.1);
          }
        }
      }

      .upload-section {
        flex: 1;

        .upload-label {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          background: rgba(255, 255, 255, 0.05);
          border: 1px dashed rgba(255, 255, 255, 0.2);
          border-radius: 8px;
          padding: 0.75rem 1rem;
          color: rgba(255, 255, 255, 0.7);
          cursor: pointer;
          transition: all 0.2s ease;

          &:hover {
            background: rgba(255, 255, 255, 0.08);
            border-color: rgba(94, 114, 228, 0.5);
            color: rgba(255, 255, 255, 0.9);
          }

          i {
            font-size: 1.25rem;
          }
        }
      }
    }
  }
}

/* Tilføj nye styles til tema knapper */
/* .system-theme-buttons {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  padding: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);

  .theme-button {
    flex: 1;
    justify-content: center;
    min-width: 120px;
    
    &:hover {
      transform: translateY(-2px);
    }
  }
} */

/* Tilføj globale tema styles */
:deep(.light-theme) {
  .card,
  .nav-link,
  .form-control,
  .input-group-text,
  .dropdown-menu {
    background-color: var(--card-color) !important;
    color: var(--text-color) !important;
  }

  .text-white,
  .text-light,
  .nav-link i,
  .nav-link span {
    color: var(--text-color) !important;
  }

  .bg-gradient-dark,
  .bg-gradient-primary {
    background: var(--background-color) !important;
  }
}

:deep(.dark-theme) {
  .card,
  .nav-link,
  .form-control,
  .input-group-text,
  .dropdown-menu {
    background-color: var(--card-color) !important;
    color: var(--text-color) !important;
  }

  .text-dark,
  .text-black,
  .nav-link i,
  .nav-link span {
    color: var(--text-color) !important;
  }

  .bg-gradient-light,
  .bg-gradient-white {
    background: var(--background-color) !important;
  }
}

/* Opdater tema styles til kun at fokusere på tekst */
:deep(.light-text) {
  *, 
  *::before, 
  *::after {
    color: var(--text-color) !important;
  }

  /* Grundlæggende tekstelementer */
  p, span, div, a, label, input, textarea, button,
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    color: var(--text-color) !important;
  }

  /* Specifikke klasser */
  .text-white, .text-light, .text-dark, .text-black,
  .text-primary, .text-secondary, .text-success,
  .text-info, .text-warning, .text-danger,
  .text-body, .text-muted, .text-white-50, .text-black-50 {
    color: var(--text-color) !important;
  }

  /* Navigation og UI elementer */
  .nav-link, .nav-item, .navbar-brand,
  .dropdown-item, .dropdown-toggle,
  .btn, .btn-link {
    color: var(--text-color) !important;
  }

  /* Form elementer */
  .form-control, .input-group-text,
  .form-label, .form-check-label {
    color: var(--text-color) !important;
  }

  /* Ikoner */
  i[class*="fa"], 
  i[class^="fas"],
  i[class^="far"],
  i[class^="fab"],
  i[class^="fa-"],
  .material-icons,
  .icon {
    color: var(--text-color) !important;
  }

  /* Bootstrap specifikke klasser */
  [class*="text-"] {
    color: var(--text-color) !important;
  }

  /* Argon Dashboard specifikke klasser */
  .sidenav-normal,
  .navbar-main,
  .breadcrumb-item,
  .card-title,
  .card-header,
  .description {
    color: var(--text-color) !important;
  }
}

:deep(.dark-text) {
  *, 
  *::before, 
  *::after {
    color: var(--text-color) !important;
  }

  /* Grundlæggende tekstelementer */
  p, span, div, a, label, input, textarea, button,
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    color: var(--text-color) !important;
  }

  /* Specifikke klasser */
  .text-white, .text-light, .text-dark, .text-black,
  .text-primary, .text-secondary, .text-success,
  .text-info, .text-warning, .text-danger,
  .text-body, .text-muted, .text-white-50, .text-black-50 {
    color: var(--text-color) !important;
  }

  /* Navigation og UI elementer */
  .nav-link, .nav-item, .navbar-brand,
  .dropdown-item, .dropdown-toggle,
  .btn, .btn-link {
    color: var(--text-color) !important;
  }

  /* Form elementer */
  .form-control, .input-group-text,
  .form-label, .form-check-label {
    color: var(--text-color) !important;
  }

  /* Ikoner */
  i[class*="fa"], 
  i[class^="fas"],
  i[class^="far"],
  i[class^="fab"],
  i[class^="fa-"],
  .material-icons,
  .icon {
    color: var(--text-color) !important;
  }

  /* Bootstrap specifikke klasser */
  [class*="text-"] {
    color: var(--text-color) !important;
  }

  /* Argon Dashboard specifikke klasser */
  .sidenav-normal,
  .navbar-main,
  .breadcrumb-item,
  .card-title,
  .card-header,
  .description {
    color: var(--text-color) !important;
  }
}

.gradient-controls {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  
  .gradient-color {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    
    label {
      font-size: 0.75rem;
      color: rgba(255, 255, 255, 0.7);
    }
    
    .color-input-wrapper {
      width: 100%;
      height: 40px;
    }
  }
}

.gradient-toggle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  
  .gradient-label {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.7);
  }
}

.color-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  
  i {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.25rem;
    mix-blend-mode: difference; // Dette sikrer at ikonet altid er synligt uanset baggrundsfarven
  }
}

.theme-card {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 1.5rem;
  height: 100%;
}

.theme-card:hover {
  background: rgba(255, 255, 255, 0.05);
}

.color-input-wrapper {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.color-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.color-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  pointer-events: none;
}

.color-picker {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.hex-input {
  flex: 1;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 0.5rem 1rem;
  color: rgba(255, 255, 255, 0.9);
  font-family: monospace;
  font-size: 0.9rem;
}

.white-label-content {
  /* Fjern transitions og animations */
  * {
    transition: none !important;
    animation: none !important;
  }
}

/* Fjern transitions fra alle relevante elementer */
.nav-link,
.form-control,
.btn,
.card,
.theme-card,
.color-picker,
.browser-settings-container,
.domain-settings,
.settings-group {
  transition: none !important;
  animation: none !important;
  transform: none !important;
  will-change: auto !important;
}

/* Fjern hover animations */
.nav-link:hover,
.form-control:hover,
.btn:hover,
.card:hover,
.theme-card:hover {
  transition: none !important;
  animation: none !important;
  transform: none !important;
}
</style>