<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import { 
  collection, 
  onSnapshot, 
  query, 
  where, 
  deleteDoc, 
  addDoc, 
  getDocs, 
  orderBy,
  getFirestore,
  doc,
  getDoc,
  setDoc,
  limit,
  startAfter,
  endBefore
} from "firebase/firestore";
import { db, auth } from "@/firebase";
import { deleteUser } from "firebase/auth";
import CompanyAddComponent from "./CompanyAddComponent.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { updateDoc } from "firebase/firestore";
import { Timestamp } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from "@/firebase";
import { usePasswordManagement } from '../components/usePasswordManagement';
import OrderList from "./OrderList.vue";
import { Teleport } from 'vue';
import InvoiceAdminModal from './InvoiceAdminModal.vue';
import { useI18n } from "vue-i18n";
import { calendarService } from '@/services/calendarService';

// Definer roller som konstanter
const ROLE_ADMIN = 1;
const ROLE_MANAGER = 2;
const ROLE_USER = 3;

const store = useStore();
const router = useRouter();

const showAddCompanyModal = ref(false);
const users = ref([]);
const showAllUserListButton = ref("false")
const searchQuery = ref("");
const isLoading = ref({});

const userAutoPosts = ref(new Set());

const unsubscribeMetaWatch = ref(null);

const cleanupMetaWatch = () => {
  if (unsubscribeMetaWatch.value) {
    unsubscribeMetaWatch.value();
    unsubscribeMetaWatch.value = null;
  }
};

const openAddCompanyModal = () => {
  showAddCompanyModal.value = true;
};

const closeAddCompanyModal = () => {
  showAddCompanyModal.value = false;
};

const unsubscribe = ref(null);

const BATCH_SIZE = 50; // Juster dette tal efter behov
const lastDoc = ref(null);
const firstDoc = ref(null);
const hasMore = ref(true);

// Tilføj disse konstanter øverst i script-sektionen
const SEARCH_DEBOUNCE_TIME = 300;
const searchTimeout = ref(null);
const debouncedSearchTerm = ref('');

// Tilføj nye refs for forskellige loading states
const tableLoading = ref(false); // For hele tabellen
const actionLoading = ref({}); // For individuelle bruger-handlinger

// Tilføj disse nye refs
const selectedFilter = ref('all');
const { t } = useI18n();

// Opdater filterOptions computed property
const filterOptions = computed(() => {
  const options = [
    { value: 'all', label: t('common.vis_alle') },
    { value: 'role_2', label: t('common.vis_kun_admins') },
    { value: 'role_3', label: t('common.vis_kun_brugere') }
  ];

  // Tilføj filter for hver admin (createdBy), men kun for superAdmin
  if (currentUser.value?.role === 1) {
    const adminUsers = users.value.filter(user => user.role === 2);
    adminUsers.forEach(admin => {
      options.push({
        value: `created_by_${admin.id}`,
        label: t('common.oprettet_af', { name: admin.name })
      });
    });
  }

  return options;
});

// Tilføj denne ref til at holde styr på brugere med autoposts
const usersWithAutoPosts = ref(new Set());

// Funktion til at tjekke om en bruger har autoposts
const checkUserAutoPosts = async (userId) => {
  try {
    const autoPostsRef = collection(db, 'users', userId, 'autoPosts');
    const q = query(autoPostsRef, limit(1));
    const snapshot = await getDocs(q);
    
    if (!snapshot.empty) {
      usersWithAutoPosts.value.add(userId);
      return true;
    }
    return false;
  } catch (error) {
    console.error('Fejl ved tjek af autoposts:', error);
    return false;
  }
};

// Opdater watch på users for at tjekke autoposts
watch(() => users.value, async (newUsers) => {
  for (const user of newUsers) {
    if (user.role === 3 && !usersWithAutoPosts.value.has(user.id)) {
      await checkUserAutoPosts(user.id);
    }
  }
}, { immediate: true });

// Opdater canSetupAutoPosts computed property
const canSetupAutoPosts = computed(() => {
  return (user) => {
    return user.role === 3 && 
           !usersWithAutoPosts.value.has(user.id) && 
           user.MetaSettings?.facebookPageId && 
           user.dealerId;
  };
});

// Funktion til at hente første batch af brugere
const loadInitialUsers = async () => {
  try {
    tableLoading.value = true;
    const usersCollection = collection(db, "users");
    
    let baseQuery;
    if (store.state.activeUser.role === 2) {
      baseQuery = query(
        usersCollection, 
        where("createdBy", "==", store.state.activeUser.id),
        limit(BATCH_SIZE)
      );
    } else {
      baseQuery = query(
        usersCollection, 
        where("role", "!=", 1),
        limit(BATCH_SIZE)
      );
    }

    const snapshot = await getDocs(baseQuery);
    
    if (!snapshot.empty) {
      const usersArray = [];
      snapshot.forEach(doc => {
        usersArray.push({ id: doc.id, ...doc.data() });
      });
      users.value = usersArray;
      
      // Pre-load kalender data for hver bruger
      usersArray.forEach(user => {
        if (user.role === 3) { // Kun for almindelige brugere
          calendarService.preloadCalendarData(user.id);
        }
      });
      
      lastDoc.value = snapshot.docs[snapshot.docs.length - 1];
      firstDoc.value = snapshot.docs[0];
      hasMore.value = snapshot.docs.length === BATCH_SIZE;
    }
  } catch (error) {
    console.error('Fejl ved indlæsning af brugere:', error);
  } finally {
    tableLoading.value = false;
  }
};

// Funktion til at hente næste batch
const loadMoreUsers = async () => {
  if (!hasMore.value || isLoading.value) return;
  
  try {
    isLoading.value = true;
    const usersCollection = collection(db, "users");
    
    let nextQuery;
    if (store.state.activeUser.role === 2) {
      nextQuery = query(
        usersCollection,
        where("createdBy", "==", store.state.activeUser.id),
        startAfter(lastDoc.value),
        limit(BATCH_SIZE)
      );
    } else {
      nextQuery = query(
        usersCollection,
        where("role", "!=", 1),
        startAfter(lastDoc.value),
        limit(BATCH_SIZE)
      );
    }

    const snapshot = await getDocs(nextQuery);
    
    if (!snapshot.empty) {
      const newUsers = [];
      snapshot.forEach(doc => {
        newUsers.push({ id: doc.id, ...doc.data() });
      });
      users.value = [...users.value, ...newUsers];
      
      // Pre-load kalender data for nye brugere
      newUsers.forEach(user => {
        if (user.role === 3) { // Kun for almindelige brugere
          calendarService.preloadCalendarData(user.id);
        }
      });
      
      lastDoc.value = snapshot.docs[snapshot.docs.length - 1];
      hasMore.value = snapshot.docs.length === BATCH_SIZE;
    } else {
      hasMore.value = false;
    }
  } catch (error) {
    console.error('Fejl ved indlæsning af flere brugere:', error);
  } finally {
    isLoading.value = false;
  }
};

// Implementer infinite scroll
const handleScroll = () => {
  const table = document.querySelector('.table-responsive');
  if (!table) return;
  
  const bottomOfTable = table.scrollHeight - table.scrollTop <= table.clientHeight + 100;
  if (bottomOfTable && hasMore.value && !isLoading.value) {
    loadMoreUsers();
  }
};

// Opdater mounted hook
onMounted(() => {
  loadInitialUsers();
  const table = document.querySelector('.table-responsive');
  if (table) {
    table.addEventListener('scroll', handleScroll);
  }

  // Reference til users collection
  const usersRef = collection(db, "users");

  // Lyt efter ændringer i online status
  let usersQuery;
  if (store.state.user?.role === 2) {
    // For admin (rolle 2) - vis kun deres egne brugere
    usersQuery = query(usersRef, where("createdBy", "==", store.state.user.id));
  } else {
    // For superAdmin (rolle 1) - vis alle undtagen rolle 1
    usersQuery = query(usersRef, where("role", "!=", 1));
  }

  // Start realtids-lytning
  const unsubscribe = onSnapshot(usersQuery, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      const userData = { id: change.doc.id, ...change.doc.data() };
      
      if (change.type === "modified") {
        // Find og opdater den eksisterende bruger i users array
        const index = users.value.findIndex(user => user.id === userData.id);
        if (index !== -1) {
          users.value[index] = { ...users.value[index], ...userData };
        }
      }
    });
  });

  // Cleanup ved component unmount
  onUnmounted(() => {
    unsubscribe();
  });
});

// Cleanup i onUnmounted
onUnmounted(() => {
  const table = document.querySelector('.table-responsive');
  if (table) {
    table.removeEventListener('scroll', handleScroll);
  }
});

// Erstat den eksisterende watch på searchQuery med denne opdaterede version
watch(searchQuery, (newValue) => {
  if (searchTimeout.value) {
    clearTimeout(searchTimeout.value);
  }
  
  searchTimeout.value = setTimeout(() => {
    debouncedSearchTerm.value = newValue.toLowerCase().trim();
  }, SEARCH_DEBOUNCE_TIME);
});

// Opdater filteredUsers computed property
const filteredUsers = computed(() => {
  let filtered = users.value;

  // Først anvend filter
  if (selectedFilter.value !== 'all') {
    if (selectedFilter.value === 'role_2') {
      filtered = filtered.filter(user => user.role === 2);
    } else if (selectedFilter.value === 'role_3') {
      filtered = filtered.filter(user => user.role === 3);
    } else if (selectedFilter.value.startsWith('created_by_')) {
      const creatorId = selectedFilter.value.replace('created_by_', '');
      filtered = filtered.filter(user => user.createdBy === creatorId);
    }
  }

  // Derefter anvend søgning
  if (debouncedSearchTerm.value) {
    filtered = filtered.filter(user => {
      const searchFields = [
        user.name,
        user.email,
        user.company,
        user.id,
        user.dealerId
      ].filter(Boolean);

      return searchFields.some(field => 
        field.toString().toLowerCase().includes(debouncedSearchTerm.value)
      );
    });
  }

  return filtered;
});

// Implementer pagination
const itemsPerPage = 10;
const currentPage = ref(1);

const paginatedUsers = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  return filteredUsers.value.slice(start, end);
});

const totalPages = computed(() => {
  return Math.ceil(filteredUsers.value.length / itemsPerPage);
});

// Tilføj en watch på filteredUsers for at nulstille pagination når søgeresultaterne ændres
watch(filteredUsers, () => {
  currentPage.value = 1;
});

// Opdater cleanup i onUnmounted
onUnmounted(() => {
  if (searchTimeout.value) {
    clearTimeout(searchTimeout.value);
  }
  if (unsubscribe.value) {
    unsubscribe.value();
  }
});

// Brug getters til at få den aktive bruger
const currentUser = computed(() => {
  const user = store.getters.activeUser;
  // Tjek om vi har et gyldigt user objekt med rolle
  if (!user) return null;
  
  // Hvis role findes direkte på objektet
  if (user.role) return user;
  
  // Hvis role findes i reloadUserInfo
  if (user.reloadUserInfo?.role) return { ...user, role: user.reloadUserInfo.role };
  
  // Hvis vi har uid/id, men mangler role, redirect til signin
  if (user.uid || user.id) {
    router.push('/authentication/signin');
    return null;
  }
  
  return null;
});

// Tilføj en watch på currentUser hvis nødvendigt
watch(currentUser, (newValue) => {
  console.log('currentUser ændret:', newValue)
  if (!newValue) {
    // Håndter situationen hvor bruger er null
    router.push('/authentication/signin')
  }
})

// Funktion til at få rolle navn
const getRoleName = (role) => {
  switch (role) {
    case ROLE_ADMIN:
      return "Admin";
    case ROLE_MANAGER:
      return "Manager";
    case ROLE_USER:
      return "Bruger";
    default:
      return "Ukendt";
  }
};

const handleUserRowClick = (userId) => {
  if (!userId) return;
  
  const currentUser = store.state.activeUser;
  const isAdminOrSubAdmin = currentUser?.role === 1 || currentUser?.role === 2;

  if (isAdminOrSubAdmin) {
    router.push(`/profile/${userId}`);
  } else {
    router.push('/profile');
  }
};

const markUserRole = async(user, role) => {
  console.log(user, user.id)
  if (user && user.id) {
    try {
      const userDocRef = doc(db, "users", user.id);
      await updateDoc(userDocRef, {
        role: role
      });
      // alert("Profil opdateret!");

    } catch (error) {
      console.error("Fejl ved opdatering af profil: ", error);
      alert(`Fejl ved opdatering af profil: ${error.message}`);
    }
  } else {
    console.log("Ingen bruger er logget ind eller bruger-ID mangler.");
  }
}

const allUsers = async(user) => {
  showAllUserListButton.value = true
  const usersCollection = collection(db, "users");
  const q = query(usersCollection, where("createdBy", "==", user.id));
  unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const usersArray = [];
      querySnapshot.forEach((doc) => {
        usersArray.push({ id: doc.id, ...doc.data() });
      });
      console.log("Hentede brugere:", usersArray);
      users.value = usersArray;
      isLoading.value = false;
    },
    (error) => {
      console.error("Fejl ved hentning af brugere:", error);
      isLoading.value = false;
    },
  );
}

// Opdater allUserLists funktionen
const allUserLists = async(user) => {
  showAllUserListButton.value = false;
  const usersCollection = collection(db, "users");
  
  // Tilføj tjek for brugerrolle
  let q;
  if (store.state.activeUser.role === 2) {
    // For admin (rolle 2) - vis kun deres egne brugere
    q = query(usersCollection, where("createdBy", "==", store.state.activeUser.id));
  } else {
    // For superAdmin (rolle 1) - vis alle undtagen rolle 1
    q = query(usersCollection, where("role", "!=", 1));
  }
  
  unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const usersArray = [];
      querySnapshot.forEach((doc) => {
        usersArray.push({ id: doc.id, ...doc.data() });
      });
      users.value = usersArray;
      isLoading.value = false;
    },
    (error) => {
      console.error("Fejl ved hentning af brugere:", error);
      isLoading.value = false;
    },
  );
}

const deleteUserConfirmation = async (user) => {
  try {
    if (!confirm(`Er du sikker på, at du vil slette ${user.name}?`)) {
      return;
    }

    // Initialiser isLoading som et objekt hvis det ikke allerede er det
    if (!isLoading.value || typeof isLoading.value !== 'object') {
      isLoading.value = {};
    }
    
    // Sæt loading state for denne specifikke bruger
    isLoading.value[`delete-${user.id}`] = true;

    // Kald backend via HTTP for at slette bruger fra Authentication
    const response = await fetch('https://us-central1-toft-d4f39.cloudfunctions.net/deleteUser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uid: user.id
      })
    });

    if (!response.ok) {
      throw new Error('Kunne ikke slette bruger fra Authentication');
    }

    // Slet bruger fra Firestore
    const userRef = doc(db, "users", user.id);
    await deleteDoc(userRef);

    // Fjern bruger fra den lokale liste
    users.value = users.value.filter(u => u.id !== user.id);

    console.log('Bruger slettet succesfuldt');
    alert('Bruger er blevet slettet');

  } catch (error) {
    console.error('Fejl ved sletning af bruger:', error);
    alert(`Fejl: ${error.message}`);
  } finally {
    // Fjern loading state for denne specifikke bruger
    if (isLoading.value && typeof isLoading.value === 'object') {
      isLoading.value[`delete-${user.id}`] = false;
    }
  }
};

// Opdater setupAutoPosts funktionen med ekstra validering
const setupAutoPosts = async (user) => {
  try {
    if (!user.MetaSettings?.facebookPageId) {
      alert('Facebook skal være tilknyttet før AutoPosts kan opsættes.');
      return;
    }

    if (!user.dealerId) {
      alert('Bruger mangler dealer ID. Opdater venligst dette først.');
      return;
    }

    // Tjek om brugeren allerede har autoposts
    const hasExistingAutoPosts = await checkUserAutoPosts(user.id);
    if (hasExistingAutoPosts) {
      alert('Brugeren har allerede autoposts opsat.');
      return;
    }

    const userDocRef = doc(db, "users", user.id);
    const userDocSnap = await getDoc(userDocRef);
    const userData = userDocSnap.data();
    
    const defaultSetups = [
      {
        status: 'Ny bil på lager',
        daysToSale: null,
        useAutoInterval: false
      },
      {
        status: 'Prisjustering',
        daysToSale: null,
        useAutoInterval: false
      },
      {
        status: 'Dage til salg',
        daysToSale: '17',
        useAutoInterval: true
      },
      {
        status: 'Netop solgt',
        daysToSale: null,
        useAutoInterval: false
      }
    ];

    // Hent alle tilgængelige designs
    const designsSnapshot = await getDocs(collection(db, 'designImages'));
    const allDesigns = designsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    let createdCount = 0;
    const autoPostsRef = collection(db, 'users', user.id, 'autoPosts');

    for (const setup of defaultSetups) {
      // Find matchende designs for denne status
      const matchingDesigns = allDesigns.filter(design => 
        design.emner?.some(emne => 
          emne.toLowerCase().includes(setup.status.toLowerCase())
        )
      );

      if (matchingDesigns.length === 0) {
        console.warn(`Ingen designs fundet for status: ${setup.status}`);
        continue;
      }

      // Tjek om der allerede findes en autoPost med denne status
      const existingQuery = query(autoPostsRef, where('status', '==', setup.status));
      const existingDocs = await getDocs(existingQuery);
      
      if (!existingDocs.empty) {
        console.log(`AutoPost for ${setup.status} findes allerede - springer over`);
        continue;
      }

      const autoPostData = {
        status: setup.status,
        channels: ['facebook'],
        types: ['image'],
        designUuids: matchingDesigns.map(design => design.uuid),
        createdAt: new Date(),
        active: !!userData.MetaSettings?.facebookPageId,
        userId: user.id,
        settings: {
          allowEmptyCollection: true,
          autoActivate: true,
          daysToSale: setup.daysToSale,
          useAutoInterval: setup.useAutoInterval,
          manualInterval: setup.useAutoInterval ? null : setup.daysToSale,
          dealerId: userData.dealerId,
          facebookPageId: userData.MetaSettings?.facebookPageId || null
        },
        lastUpdated: new Date()
      };

      // Opret autoPost i Firestore
      await addDoc(autoPostsRef, autoPostData);
      createdCount++;
      console.log(`AutoPost oprettet for: ${setup.status}`);
    }

    // Vis success besked
    if (createdCount > 0) {
      alert(`Quick Setup gennemført! ${createdCount} autoposts er blevet oprettet.`);
    } else {
      alert('Ingen nye autoposts blev oprettet. Alle opsætninger findes allerede.');
    }
    
    // Efter vellykket oprettelse af AutoPosts, marker at setup er udført
    await updateDoc(userDocRef, {
      hasSetupAutoPosts: true,
      lastAutoPostSetup: new Date()
    });

    // Opdater lokalt user objekt
    user.hasSetupAutoPosts = true;
    
  } catch (error) {
    console.error('Fejl ved Quick Setup:', error);
    alert(`Fejl ved Quick Setup: ${error.message}`);
  }
};

// Hjælpefunktion til at tjekke om en bruger har AutoPost
const hasAutoPost = (userId) => {
  return userAutoPosts.value.has(userId);
};

// Brug process.env for access tokens
const META_USER_ACCESS_TOKEN = process.env.VUE_APP_META_USER_ACCESS_TOKEN;
const META_MAKEMORE_PAGE_ACCESS_TOKEN = process.env.VUE_APP_META_MAKEMORE_PAGE_ACCESS_TOKEN;

// Tilføj loading state
const metaSettingsLoading = ref(new Set());

const FALLBACK_META_SETTINGS = {
  about: "A simpel demo page",
  address: null,
  facebookPageId: "502196652966363",
  instagramBusinessAccountId: "17841469464394127",
  lastFacebookConnection: new Date().toISOString(),
  name: "Makemoredemopage",
  page_access_token: META_MAKEMORE_PAGE_ACCESS_TOKEN,
  phone: null,
  website: "http://www.makemore.dk/"
};

// Tilføj en ref til at holde styr på alle aktive listeners
const metaSettingsListeners = ref(new Map());

// Opdater setupMetaSettingsListener funktionen
const setupMetaSettingsListener = (userId) => {
  if (metaSettingsListeners.value.has(userId)) return;

  const userDocRef = doc(db, "users", userId);
  const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
    if (docSnapshot.exists()) {
      const userData = docSnapshot.data();
      // Find og opdater brugeren i users array
      const userIndex = users.value.findIndex(u => u.id === userId);
      if (userIndex !== -1) {
        users.value[userIndex] = {
          ...users.value[userIndex],
          MetaSettings: userData.MetaSettings,
          trialPeriod: userData.trialPeriod,
          active: userData.active
        };
      }
    }
  });

  metaSettingsListeners.value.set(userId, unsubscribe);
};

// Tilføj cleanup funktion
const cleanupMetaSettingsListeners = () => {
  metaSettingsListeners.value.forEach((unsubscribe) => {
    unsubscribe();
  });
  metaSettingsListeners.value.clear();
};

// Opdater watch på users
watch(() => users.value, (newUsers) => {
  newUsers.forEach(user => {
    if (user.role === 3) { // Kun for almindelige brugere
      setupMetaSettingsListener(user.id);
    }
  });
}, { immediate: true });

// Tilføj til onUnmounted
onUnmounted(() => {
  cleanupMetaSettingsListeners();
  // ... eksisterende cleanup kode ...
});

// Opdater connectMetaSettings funktionen
const connectMetaSettings = async (user) => {
  try {
    metaSettingsLoading.value.add(user.id);
    
    // Normaliser og rens brugernavnet
    const normalizeString = (s) => {
      const normalized = s.normalize('NFKD')
        .replace(/Å/g, 'Aa').replace(/å/g, 'aa')
        .replace(/Æ/g, 'Ae').replace(/æ/g, 'ae')
        .replace(/Ø/g, 'Oe').replace(/ø/g, 'oe');
      
      return normalized
        .replace(/[^\x00-\x7F]/g, '')
        .replace(/\s*-\s*/g, ' ')
        .toLowerCase()
        .trim();
    };

    const searchName = normalizeString(user.name);
    console.log('Søger efter:', searchName);
    
    try {
      // Forsøg at hente Facebook sider med det korrekte access token
      const response = await fetch(
        `https://graph.facebook.com/v20.0/me/accounts?fields=name,id,instagram_business_account&limit=500&access_token=${META_USER_ACCESS_TOKEN}`
      );
      
      if (!response.ok) {
        throw new Error('Kunne ikke hente Facebook sider');
      }

      const data = await response.json();
      console.log('Facebook sider:', data);

      // Find den matchende side
      let matchingPage = data.data.find(page => {
        const pageName = normalizeString(page.name);
        return pageName.includes(searchName);
      });

      if (!matchingPage) {
        throw new Error('Ingen matchende Facebook side fundet');
      }

      // Opret MetaSettings objekt
      const metaSettings = {
        facebookPageId: matchingPage.id,
        instagramBusinessAccountId: matchingPage.instagram_business_account?.id || null,
        name: matchingPage.name,
        page_access_token: META_MAKEMORE_PAGE_ACCESS_TOKEN, // Brug det korrekte token
        lastFacebookConnection: new Date().toISOString()
      };

      // Opdater bruger i Firestore
      const userRef = doc(db, 'users', user.id);
      await updateDoc(userRef, {
        MetaSettings: metaSettings
      });

      // Opdater lokalt user objekt
      const userIndex = users.value.findIndex(u => u.id === user.id);
      if (userIndex !== -1) {
        users.value[userIndex] = {
          ...users.value[userIndex],
          MetaSettings: metaSettings
        };
      }

      console.log('Meta indstillinger opdateret:', metaSettings);
    } catch (error) {
      console.error('Fejl ved tilknytning af Meta:', error);
      throw error;
    }
  } catch (error) {
    console.error('Fejl i connectMetaSettings:', error);
    alert(`Fejl: ${error.message}`);
  } finally {
    metaSettingsLoading.value.delete(user.id);
  }
};

// Hjælpefunktion til at opdatere MetaSettings
const updateMetaSettings = async (user, settings) => {
  const userDocRef = doc(db, "users", user.id);
  await updateDoc(userDocRef, {
    MetaSettings: settings
  });
  console.log('MetaSettings opdateret:', settings);
};

// Tilføj ny hjælpefunktion til at oprette metaUserData
const createMetaUserData = async (user, settings) => {
  const metaUserData = {
    data: {
      facebook_id: settings.facebookPageId,
      instagram_id: settings.instagramBusinessAccountId,
      pageAccessToken: settings.page_access_token
    },
    facebookPageId: settings.facebookPageId,
    key: user.id,
    lastUpdated: new Date().toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short'
    }),
    pageAccessToken: settings.page_access_token,
    userId: user.id
  };

  const metaUserDataRef = doc(db, "metaUserData", user.id);
  await setDoc(metaUserDataRef, metaUserData);
  console.log('MetaUserData oprettet:', metaUserData);
};

const trialLoading = ref(new Set());

const toggleTrialPeriod = async (user) => {
  try {
    trialLoading.value.add(user.id);
    const userDocRef = doc(db, "users", user.id);
    
    if (!user.trialPeriod || !user.trialPeriod.active) {
      // Start prøveperiode
      const trialEnd = new Date();
      trialEnd.setDate(trialEnd.getDate() + 14); // 14 dages prøveperiode
      
      await updateDoc(userDocRef, {
        active: true, // Sørg for at brugeren forbliver aktiv
        trialPeriod: {
          active: true,
          startDate: Timestamp.now(),
          endDate: Timestamp.fromDate(trialEnd),
          activated: true
        }
      });
    } else {
      // Fjern kun prøveperioden, bevar aktiv status
      await updateDoc(userDocRef, {
        'trialPeriod.active': false
      });
    }
  } catch (error) {
    console.error('Fejl ved håndtering af prøveperiode:', error);
    alert(`Fejl: ${error.message}`);
  } finally {
    trialLoading.value.delete(user.id);
  }
};

const isTrialExpired = (user) => {
  if (!user.trialPeriod?.endDate) return false;
  const endDate = user.trialPeriod.endDate.toDate();
  return endDate < new Date();
};

// Opdater badge logikken
const getBadgeColor = (user) => {
  if (!user.active) return 'danger'; // Helt inaktiv bruger
  if (user.trialPeriod?.active) return 'warning'; // I prøveperiode
  return 'success'; // Normal aktiv bruger
};

const getBadgeText = (user) => {
  if (!user.active) return 'Inaktiv';
  if (user.trialPeriod?.active) {
    const endDate = user.trialPeriod.endDate.toDate();
    return `Prøveperiode (${endDate.toLocaleDateString('da-DK')})`;
  }
  return 'Aktiv';
};

// Definér toggleUserAccount funktionen
const toggleUserAccount = async (user) => {
  try {
    // Brug actionLoading i stedet for isLoading
    actionLoading.value[`toggle-${user.id}`] = true;

    const response = await fetch('https://us-central1-toft-d4f39.cloudfunctions.net/toggleUserStatus', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uid: user.id,
        disabled: !user.disabled
      })
    });

    if (!response.ok) {
      throw new Error('Kunne ikke opdatere bruger status');
    }

    const result = await response.json();

    // Opdater kun den specifikke bruger i users array
    const userIndex = users.value.findIndex(u => u.id === user.id);
    if (userIndex !== -1) {
      users.value[userIndex] = {
        ...users.value[userIndex],
        active: !user.active,
        disabled: !user.disabled
      };
    }

    console.log('Brugerstatus opdateret:', result);

  } catch (error) {
    console.error('Fejl ved ændring af brugerstatus:', error);
    alert(`Fejl: ${error.message}`);
  } finally {
    actionLoading.value[`toggle-${user.id}`] = false;
  }
};

const { sendPasswordResetForUser } = usePasswordManagement();
const passwordResetLoading = ref(new Set());

const handlePasswordReset = async (user) => {
  try {
    passwordResetLoading.value.add(user.id);
    
    // Verificer at brugeren har en email
    if (!user.email) {
      throw new Error('Bruger har ingen email-adresse');
    }
    
    // Verificer at den aktive bruger har tilladelse (rolle 1 eller 2)
    const currentUserRole = store.state.activeUser.role;
    if (currentUserRole > 2) {
      throw new Error('Du har ikke tilladelse til at nulstille adgangskoder');
    }
    
    // For rolle 2 (admin), verificer at de kun nulstiller for deres egne brugere
    if (currentUserRole === 2 && user.createdBy !== store.state.activeUser.id) {
      throw new Error('Du kan kun nulstille adgangskoder for dine egne brugere');
    }

    await sendPasswordResetForUser(user.email);
    alert('Nulstillingslink er sendt til brugerens email');
    
  } catch (error) {
    console.error('Fejl ved nulstilling af adgangskode:', error);
    alert(`Fejl: ${error.message}`);
  } finally {
    passwordResetLoading.value.delete(user.id);
  }
};

// Tilføj denne computed property
const getUserCustomerCount = computed(() => {
  const counts = {};
  users.value.forEach(user => {
    if (user.role === ROLE_USER) { // Rolle 3
      const managerId = user.createdBy;
      counts[managerId] = (counts[managerId] || 0) + 1;
    }
  });
  return counts;
});

const showScheduledPostsModal = ref(false);
const selectedUserId = ref(null);
const scheduledPosts = ref([]);
const scheduledPostsSend = ref([]);

const openScheduledPostsModal = async (userId) => {
  try {
    console.log('Åbner modal for bruger:', userId);
    selectedUserId.value = userId;
    
    const db = getFirestore();
    
    // Tjek om currentUser er tilgængelig
    if (!auth.currentUser) {
      throw new Error('Ingen bruger er logget ind');
    }
    
    console.log('Henter data for currentUser:', auth.currentUser.uid);
    
    // Hent brugerens rolle med fejlhåndtering
    let userRole;
    try {
      const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
      if (!userDoc.exists()) {
        throw new Error('Bruger dokument ikke fundet');
      }
      userRole = userDoc.data()?.role;
      console.log('Bruger rolle:', userRole);
    } catch (error) {
      console.error('Fejl ved hentning af brugerrolle:', error);
      throw new Error('Kunne ikke hente brugerrolle: ' + error.message);
    }
    
    // Opret queries baseret på rolle
    let postsQuery, postSendQuery;
    
    if (userRole === 3) {
      console.log('Henter opslag for almindelig bruger');
      postsQuery = query(collection(db, "posts"), 
        where("userId", "==", auth.currentUser.uid));
      postSendQuery = query(collection(db, "postSend"), 
        where("userId", "==", auth.currentUser.uid));
    } else {
      console.log('Henter opslag for admin/superadmin');
      postsQuery = query(collection(db, "posts"), 
        where("id", "==", userId));
      postSendQuery = query(collection(db, "postSend"), 
        where("id", "==", userId));
    }

    console.log('Starter datahentning...');
    
    // Hent data fra begge collections med fejlhåndtering
    try {
      const [postsSnapshot, postSendSnapshot] = await Promise.all([
        getDocs(postsQuery),
        getDocs(postSendQuery)
      ]);

      console.log('Rå posts data:', postsSnapshot.docs.map(doc => doc.data()));
      console.log('Rå postSend data:', postSendSnapshot.docs.map(doc => doc.data()));

      // Behandl planlagte opslag (posts)
      scheduledPosts.value = postsSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          collection: 'posts',
          createdAt: data.createdAt?.toDate?.() || new Date(),
          scheduledTime: data.publishTime && data.publishDate ? 
            `${data.publishDate}T${data.publishTime.HH}:${data.publishTime.MM}:00` : 
            null,
          status: 'scheduled',
          mediaUrls: data.children?.[0]?.images || [],
          headline: data.children?.[0]?.headline || '',
          caseUrl: data.caseUrl || '',
          postText: data.text || ''
        };
      });

      // Behandl publicerede opslag (postSend)
      scheduledPostsSend.value = postSendSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          collection: 'postSend',
          publishedAt: data.publishedAt?.toDate?.() || null,
          status: 'published',
          mediaUrls: data.children?.[0]?.images || [],
          headline: data.children?.[0]?.headline || '',
          caseUrl: data.caseUrl || '',
          postText: data.text || '',
          analytics: data.analytics || {
            facebook: {
              post_clicks: 0,
              post_engagements: 0,
              post_impressions_unique: 0,
              post_reactions_like_total: 0
            }
          },
          facebookLink: data.facebookLink || null
        };
      });

      console.log('Behandlede opslag:', {
        planlagte: scheduledPosts.value.length,
        publicerede: scheduledPostsSend.value.length,
        planlagteData: scheduledPosts.value,
        publiceredeData: scheduledPostsSend.value
      });

      showScheduledPostsModal.value = true;
      
    } catch (error) {
      console.error('Fejl ved datahentning:', error);
      throw new Error('Kunne ikke hente opslag: ' + error.message);
    }
    
  } catch (error) {
    console.error('Detaljeret fejl:', error);
    console.error('Fejl stack:', error.stack);
    alert('Der opstod en fejl ved hentning af opslag: ' + error.message);
  } finally {
    isLoading.value = false;
  }
};

// Tilføj hjælpefunktion til at konvertere Firestore timestamps
const convertTimestamps = (data) => {
  if (!data) return data;
  
  const converted = { ...data };
  
  // Liste over felter der skal konverteres
  const timestampFields = ['createdAt', 'publishedAt', 'lastUpdated', 'processingStartTime', 'processingEndTime'];
  
  timestampFields.forEach(field => {
    if (converted[field] && typeof converted[field].toDate === 'function') {
      converted[field] = converted[field].toDate();
    }
  });
  
  return converted;
};

// Opdater deletePost funktionen med bedre fejlhåndtering
const deletePost = async (post) => {
  try {
    console.log('Forsøger at slette opslag:', post);
    
    if (!post.id || !selectedUserId.value) {
      throw new Error('Mangler post ID eller bruger ID');
    }

    const collectionName = post.collection || 'posts';
    const postRef = doc(db, 'users', selectedUserId.value, collectionName, post.id);
    
    await deleteDoc(postRef);
    console.log('Opslag slettet:', post.id);
    
    // Opdater lokale arrays
    if (collectionName === 'posts') {
      scheduledPosts.value = scheduledPosts.value.filter(p => p.id !== post.id);
    } else {
      scheduledPostsSend.value = scheduledPostsSend.value.filter(p => p.id !== post.id);
    }

    console.log('Lokale arrays opdateret');

  } catch (error) {
    console.error('Fejl ved sletning af opslag:', error);
    alert('Der opstod en fejl ved sletning af opslaget: ' + error.message);
  }
};

// Tilføj metode til at se detaljer for et opslag
const viewPost = (post) => {
  console.log('Vis detaljer for opslag:', post);
  // Implementer visning af detaljer her
};

const closeScheduledPostsModal = () => {
  showScheduledPostsModal.value = false;
  selectedUserId.value = null;
  scheduledPosts.value = [];
  scheduledPostsSend.value = [];
};

// Implementer virtuel scrolling for bruger listen
const itemSize = 60; // Højde per række
const visibleUsers = computed(() => {
  return users.value.slice(startIndex, endIndex);
});

// Tilføj denne computed property
const hasSubUsers = computed(() => {
  if (currentUser.value?.role !== 2) return false;
  return users.value.some(user => user.createdBy === currentUser.value.id);
});

// Tilføj denne computed property
const hasCreatedUsers = computed(() => {
  return (userId) => {
    return users.value.some(user => user.createdBy === userId);
  };
});

// Tilføj funktion til at oprette tilbagevendende faktura
const createRecurringInvoice = async (user) => {
  if (!confirm(`Er du sikker på at du vil oprette månedlig fakturering for ${user.name}?`)) {
    return;
  }

  try {
    isLoading.value[user.id] = true;

    // Opret første faktura direkte i brugerens subcollection
    const invoiceData = {
      userId: user.id,  // Ikke nødvendigt længere da det er i subcollection
      type: 'system',
      createdAt: new Date(),
      dueDate: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000),
      invoiceNumber: generateInvoiceNumber(),
      status: 'udstedt',
      items: [
        {
          description: 'System abonnement - månedlig licens',
          quantity: 1,
          rate: 2995,
          amount: 2995
        }
      ],
      subtotal: 2995,
      tax: 2995 * 0.25,
      total: 2995 * 1.25,
      customerInfo: {
        name: user.name || '',
        address: user.address || '',
        phone: user.phone || '',
        email: user.email || '',
        cvr: user.cvr || ''
      },
      recurring: true,
      nextInvoiceDate: new Date(new Date().setMonth(new Date().getMonth() + 1))
    };

    // Gem faktura direkte i brugerens subcollection
    await addDoc(collection(db, 'users', user.id, 'invoices'), invoiceData);

    // Opdater bruger med faktureringsinfo
    await updateDoc(doc(db, 'users', user.id), {
      hasRecurringInvoice: true,
      billingStartDate: new Date(),
      nextInvoiceDate: invoiceData.nextInvoiceDate
    });

    // Opdater userInvoiceStatus
    userInvoiceStatus.value[user.id] = true;

    alert('Fakturering er oprettet og første faktura er genereret');
  } catch (error) {
    console.error('Fejl ved oprettelse af fakturering:', error);
    alert('Der opstod en fejl: ' + error.message);
  } finally {
    isLoading.value[user.id] = false;
  }
};

// Hjælpefunktion til at generere fakturanummer
const generateInvoiceNumber = () => {
  const date = new Date();
  const year = date.getFullYear().toString().substr(-2);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const random = Math.floor(Math.random() * 10000).toString().padStart(4, '0');
  return `INV-${year}${month}-${random}`;
};

// Tilføj disse refs
const showInvoiceAdminModal = ref(false);
const selectedUserForInvoices = ref(null);
const userInvoiceStatus = ref({});  // Tilføj denne ref til at gemme invoice status

// Tilføj denne metode
const openInvoiceAdmin = (user) => {
  console.log('Åbner faktura admin for bruger:', user);
  console.log('Brugerens rolle:', user.role);
  console.log('Antal brugere:', users.value.length);
  
  selectedUserForInvoices.value = user;
  showInvoiceAdminModal.value = true;
};

// Tilføj denne metode
const closeInvoiceAdmin = () => {
  showInvoiceAdminModal.value = false;
  selectedUserForInvoices.value = null;
};

// Tjek om bruger har fakturaer
const checkUserInvoices = async (userId) => {
  try {
    console.log('Tjekker fakturaer for bruger:', userId);
    const invoicesRef = collection(db, 'users', userId, 'invoices');
    const q = query(invoicesRef, limit(1));
    const snapshot = await getDocs(q);
    const hasInvoices = !snapshot.empty;
    console.log('Bruger har fakturaer:', hasInvoices);
    userInvoiceStatus.value[userId] = hasInvoices;
  } catch (error) {
    console.error('Fejl ved tjek af fakturaer:', error);
    userInvoiceStatus.value[userId] = false;
  }
};

// Opdater watch på users for at tjekke invoice status
watch(users, async (newUsers) => {
  console.log('Tjekker fakturastatus for alle brugere');
  for (const user of newUsers) {
    await checkUserInvoices(user.id);
  }
}, { immediate: true });

// Tilføj denne ref
const showSecurityActions = ref({});

// Tilføj denne metode
const toggleSecurityActions = (userId) => {
  showSecurityActions.value[userId] = !showSecurityActions.value[userId];
};

// Tilføj denne computed property
const creatorNames = computed(() => {
  const names = {};
  users.value.forEach(user => {
    if (user.role === 2) { // Kun gem navne på admins
      names[user.id] = user.name;
    }
  });
  return names;
});

// Tilføj denne metode
const getCreatorName = (creatorId) => {
  return creatorNames.value[creatorId] || 'MakeMore';
};

// Tilføj denne computed property
const canManageInvoices = computed(() => {
  return (user) => {
    // SuperAdmin kan kun håndtere fakturaer for brugere de har oprettet
    if (currentUser.value?.role === 1) {
      return user.createdBy === currentUser.value.id;
    }
    // Admin kan kun håndtere fakturaer for deres egne brugere
    if (currentUser.value?.role === 2) {
      return user.createdBy === currentUser.value.id;
    }
    return false;
  };
});

// Tilføj denne computed property
const visibleColumns = computed(() => {
  const width = window.innerWidth;
  if (width > 1200) return 'all';
  if (width > 992) return 'medium';
  if (width > 768) return 'small';
  return 'minimal';
});

// Tilføj denne metode
const updateScrollIndicator = () => {
  const table = document.querySelector('.table-responsive');
  if (!table) return;
  
  const percent = (table.scrollLeft / (table.scrollWidth - table.clientWidth)) * 100; // Fjern den ekstra parentes
  table.style.setProperty('--scroll-percent', `${percent}%`);
};

// Tilføj denne utility funktion
const debounce = (fn, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(...args), delay);
  };
};

// Opdater scroll handler med debounce
const debouncedUpdateScrollIndicator = debounce(updateScrollIndicator, 16); // ~60fps

// Opdater mounted hook
onMounted(() => {
  const table = document.querySelector('.table-responsive');
  if (table) {
    table.addEventListener('scroll', debouncedUpdateScrollIndicator);
    window.addEventListener('resize', debouncedUpdateScrollIndicator);
    // Initial update
    updateScrollIndicator();
  }
});

// Opdater onUnmounted hook
onUnmounted(() => {
  const table = document.querySelector('.table-responsive');
  if (table) {
    table.removeEventListener('scroll', debouncedUpdateScrollIndicator);
    window.removeEventListener('resize', debouncedUpdateScrollIndicator);
  }
});

// Hjælpefunktion til at formatere lastSeen timestamp
const formatLastSeen = (timestamp) => {
  if (!timestamp || !timestamp.seconds) return 'Ukendt';
  
  const date = new Date(timestamp.seconds * 1000);
  const now = new Date();
  const diffMs = now - date;
  const diffMinutes = Math.floor(diffMs / (1000 * 60));
  
  if (diffMinutes < 1) return 'Lige nu';
  if (diffMinutes < 60) return `${diffMinutes} min. siden`;
  
  const diffHours = Math.floor(diffMinutes / 60);
  if (diffHours < 24) return `${diffHours} ${diffHours === 1 ? 'time' : 'timer'} siden`;
  
  const diffDays = Math.floor(diffHours / 24);
  if (diffDays < 7) return `${diffDays} ${diffDays === 1 ? 'dag' : 'dage'} siden`;
  
  // For mere end 7 dage, vis dato
  return date.toLocaleDateString('da-DK', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });
};
</script>

<template>
  <div class="table-wrapper">
    <div v-if="tableLoading">loading...</div>
    <div v-else-if="!currentUser">
      <p>Omdirigerer til login...</p>
    </div>
    <div v-else>
      <!-- Søgebjælke og knapper -->
      <div class="d-flex justify-content-between align-items-center px-3 py-2">
        <div class="d-flex gap-3 flex-grow-1">
          <!-- Eksisterende søgefelt -->
          <div class="search-bar flex-grow-1">
            <input
              v-model="searchQuery"
              type="text"
              :placeholder="$t('common.search_user')"
              class="form-control search-input"
            />
          </div>

          <!-- Nyt filter dropdown -->
          <div class="filter-dropdown" v-if="currentUser.role <= 2">
            <select 
              v-model="selectedFilter"
              class="form-select filter-select"
            >
              <option 
                v-for="option in filterOptions" 
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
        </div>

        <div class="d-flex gap-2">
          <!-- Ændrer betingelsen for hvornår knappen vises -->
          <argon-button
            color="secondary"
            size="sm"
            variant="outline"
            class="custom-button"
            v-if="currentUser.role === 1 && showAllUserListButton"
            @click="allUserLists()"
          >
            <span v-auto-translate>Vis alle brugere</span>
          </argon-button>

          <argon-button
            color="success"
            size="sm"
            variant="outline"
            class="custom-button"
            @click="openAddCompanyModal"
          >
            <span v-auto-translate>Tilføj virksomhed</span>
          </argon-button>
        </div>
      </div>

      <div class="table-container">
        <!-- Tabel indhold -->
        <div class="card-body">
          <div class="table-responsive">
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <!-- Bevar rækkefølgen af disse kolonner -->
                  <th v-if="currentUser.role <= 2" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                    <span v-auto-translate>Kalender</span>
                  </th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    <span v-auto-translate>On/Off</span>
                  </th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    <span v-auto-translate>Bruger</span>
                  </th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                    <span v-auto-translate>Actions</span>
                  </th>
                  <th v-if="currentUser.role <= 2" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                    <span v-auto-translate>Quick Setup</span>
                  </th>
                  <th v-if="currentUser.role <= 2" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                    <span v-auto-translate>Tilknyt Meta</span>
                  </th>
                  <th v-if="currentUser.role <= 2" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                    <span v-auto-translate>Prøveperiode (14 dage)</span>
                  </th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                    <span v-auto-translate>Fakturering</span>
                  </th>
                  <!-- Flyttet Sikkerhedshandlinger til sidst -->
                  <th v-if="currentUser.role <= 2" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                    <span v-auto-translate>Sikkerhedshandlinger</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="filteredUsers.length === 0">
                  <td colspan="10" class="text-center py-4">
                    <p class="text-secondary mb-0">
                      <span v-auto-translate>{{ searchQuery ? 'Ingen brugere matcher søgningen' : 'Ingen brugere fundet' }}</span>
                    </p>
                  </td>
                </tr>
                <tr 
                  v-for="user in paginatedUsers" 
                  :key="user.id"
                  @click="handleUserRowClick(user.id)"
                  class="user-row"
                >
                  <!-- Flyt denne celle op foran On/Off -->
                  <td class="align-middle">
                    <argon-button
                      v-if="user.role === 3"
                      color="info"
                      size="sm"
                      variant="gradient"
                      class="custom-button"
                      @click.stop="openScheduledPostsModal(user.id)"
                    >
                      {{ $t('common.opslag') }}
                    </argon-button>
                  </td>
                  <td class="align-middle text-center">
                    <div class="d-flex flex-column align-items-center">
                      <span :class="[
                        'badge',
                        'badge-sm',
                        user.isOnline ? 'bg-gradient-success' : 'bg-gradient-secondary',
                        'mb-1'
                      ]">
                        {{ user.isOnline ? 'Online' : 'Offline' }}
                      </span>
                      <span v-if="!user.isOnline && user.lastSeen" class="text-xs text-secondary">
                        {{ formatLastSeen(user.lastSeen) }}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex px-2 py-1">
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">{{ user.name }}</h6>
                        <p class="text-xs text-secondary mb-0">{{ user.email }}</p>
                        <p class="text-xxs text-secondary mb-0 opacity-7">ID: {{ user.id }}</p>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle">
                    <div class="d-flex gap-2">
                      <argon-button
                        v-if="(currentUser.role === 1 || 
                              (currentUser.role === 2 && hasCreatedUsers(user.id))) && 
                              getUserCustomerCount[user.id] > 0"
                        color="navy"
                        size="sm"
                        variant="gradient"
                        class="custom-button"
                        @click.stop="allUsers(user)"
                      >
                        {{ $t('common.brugere_antal', { count: getUserCustomerCount[user.id] }) }}
                      </argon-button>
                    </div>
                  </td>
                  <td v-if="currentUser.role <= 2" class="align-middle">
                    <div class="d-flex flex-column gap-2">
                      <!-- Quick Setup knap -->
                      <argon-button
                        v-if="canSetupAutoPosts(user)"
                        color="info"
                        size="sm"
                        variant="gradient"
                        class="custom-button"
                        @click.stop="setupAutoPosts(user)"
                      >
                        <i class="fas fa-magic me-2"></i>
                        <span v-auto-translate>Opsæt AutoPosts</span>
                      </argon-button>

                      <!-- Forskellige status beskeder -->
                      <template v-else-if="user.role === 3">
                        <!-- Hvis allerede opsat -->
                        <div v-if="usersWithAutoPosts.has(user.id)" class="d-flex gap-2">
                          <argon-badge
                            color="success"
                            variant="gradient"
                            size="sm"
                            class="badge-sm font-weight-bold"
                          >
                            <span v-auto-translate>AutoPosts opsat</span>
                          </argon-badge>
                        </div>
                        
                        <!-- Hvis Facebook mangler -->
                        <div v-else-if="!user.MetaSettings?.facebookPageId" class="d-flex gap-2">
                          <argon-badge
                            color="warning"
                            variant="gradient"
                            size="sm"
                            class="badge-sm font-weight-bold"
                          >
                            <span v-auto-translate>Tilknyt Facebook</span>
                          </argon-badge>
                        </div>
                        
                        <!-- Hvis dealer ID mangler -->
                        <div v-else-if="!user.dealerId" class="d-flex gap-2">
                          <argon-badge
                            color="warning"
                            variant="gradient"
                            size="sm"
                            class="badge-sm font-weight-bold"
                          >
                            <span v-auto-translate>Mangler dealer ID</span>
                          </argon-badge>
                        </div>
                      </template>
                    </div>
                  </td>
                  <td v-if="currentUser.role <= 2" class="align-middle">
                    <!-- Meta Settings knap -->
                    <argon-button
                      v-if="user.role === 3 && 
                            user.dealerId && 
                            !user.MetaSettings?.facebookPageId"
                      color="info"
                      size="sm"
                      variant="gradient"
                      class="custom-button"
                      @click.stop="connectMetaSettings(user)"
                      :disabled="metaSettingsLoading.has(user.id)"
                    >
                      <template v-if="metaSettingsLoading.has(user.id)">
                        <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        <span v-auto-translate>Tilknytter...</span>
                      </template>
                      <template v-else>
                        <span v-auto-translate>Tilknyt Facebook</span>
                      </template>
                    </argon-button>

                    <!-- Status tags -->
                    <div v-if="user.MetaSettings?.facebookPageId" class="d-flex gap-2">
                      <argon-badge
                        color="success"
                        variant="gradient"
                        size="sm"
                        class="badge-sm font-weight-bold"
                      >
                        <span v-auto-translate>Tilknyttet</span>
                      </argon-badge>
                    </div>
                  </td>
                  <td v-if="currentUser.role <= 2" class="align-middle">
                    <div v-if="user.role === 3" class="d-flex align-items-center gap-3">
                      <!-- Switch with tooltip -->
                      <div class="form-check form-switch" @click.stop v-tooltip="'Aktiver eller deaktiver prøveperiode'">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :id="'trialSwitch_' + user.id"
                          :checked="user.trialPeriod?.active"
                          @change="toggleTrialPeriod(user)"
                          :disabled="trialLoading.has(user.id)"
                        >
                      </div>

                      <!-- Badge med status og dato -->
                      <argon-badge
                        :color="getBadgeColor(user)"
                        variant="gradient"
                        size="sm"
                        class="badge-sm font-weight-bold"
                      >
                        {{ getBadgeText(user) }}
                      </argon-badge>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex gap-2">
                      <argon-button
                        v-if="canManageInvoices(user)"
                        color="success"
                        size="sm"
                        variant="gradient"
                        @click.stop="openInvoiceAdmin(user)"
                      >
                        <i class="fas fa-file-invoice me-2"></i>
                        {{ userInvoiceStatus[user.id] ? $t('common.administrer') : $t('common.opret_faktura') }}
                      </argon-button>
                      <div v-else class="text-muted small">
                        {{ getCreatorName(user.createdBy) }}
                      </div>
                    </div>
                  </td>
                  <td v-if="currentUser.role <= 2" class="align-middle">
                    <div class="d-flex flex-column gap-2">
                      <!-- Sikkerhedshandlinger knap -->
                      <argon-button
                        color="warning"
                        size="sm"
                        variant="gradient"
                        class="custom-button"
                        @click.stop="toggleSecurityActions(user.id)"
                      >
                        <i class="fas fa-shield-alt me-2"></i>
                        <span v-auto-translate>Sikkerhedshandlinger</span>
                      </argon-button>

                      <!-- Sikkerhedshandlinger der vises/skjules -->
                      <div 
                        v-if="showSecurityActions[user.id]"
                        class="security-actions-container"
                        @click.stop
                      >
                        <!-- Brugerrolle sektion - Kun for superadmin -->
                        <div v-if="currentUser.role === 1" class="mb-3">
                          <div class="d-flex gap-2 align-items-center">
                            <div class="d-flex gap-1">
                              <argon-button
                                :color="user.role === 2 ? 'primary' : 'secondary'"
                                size="sm"
                                variant="gradient"
                                class="custom-button"
                                v-if="user.role !== 2"
                                @click.stop="markUserRole(user, 2)"
                              >
                                <span v-auto-translate>Bruger</span>
                              </argon-button>
                              <argon-button
                                :color="user.role === 3 ? 'primary' : 'secondary'"
                                size="sm"
                                variant="gradient"
                                class="custom-button"
                                v-if="user.role !== 3"
                                @click.stop="markUserRole(user, 3)"
                              >
                                <span v-auto-translate>Manager</span>
                              </argon-button>
                            </div>
                          </div>
                        </div>

                        <!-- Password Reset -->
                        <argon-button
                          color="info"
                          size="sm"
                          variant="gradient"
                          class="custom-button mb-2"
                          @click.stop="handlePasswordReset(user)"
                          :disabled="passwordResetLoading.has(user.id)"
                        >
                          <template v-if="passwordResetLoading.has(user.id)">
                            <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            <span v-auto-translate>Sender...</span>
                          </template>
                          <template v-else>
                            <i class="fas fa-key me-2"></i>
                            <span v-auto-translate>Nulstil kode</span>
                          </template>
                        </argon-button>

                        <!-- Deaktiver/Aktiver og Slet -->
                        <div class="d-flex gap-2">
                          <argon-button
                            :color="user.disabled ? 'success' : 'warning'"
                            size="sm"
                            variant="gradient"
                            class="custom-button"
                            @click.stop="toggleUserAccount(user)"
                            :disabled="actionLoading[`toggle-${user.id}`]"
                          >
                            <template v-if="actionLoading[`toggle-${user.id}`]">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </template>
                            <template v-else>
                              <i class="fas fa-power-off me-2"></i>
                              <span v-auto-translate>{{ user.disabled ? 'Aktiver' : 'Deaktiver' }}</span>
                            </template>
                          </argon-button>

                          <argon-button
                            color="danger"
                            size="sm"
                            variant="gradient"
                            class="custom-button"
                            @click.stop="deleteUserConfirmation(user)"
                            :disabled="isLoading[`delete-${user.id}`]"
                          >
                            <template v-if="isLoading[`delete-${user.id}`]">
                              <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                              <span v-auto-translate>Sletter...</span>
                            </template>
                            <template v-else>
                              <i class="fas fa-trash-alt me-2"></i>
                              <span v-auto-translate>Slet</span>
                            </template>
                          </argon-button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="table-scroll-indicator"></div>
        </div>

        <!-- Pagination -->
        <div 
          class="d-flex justify-content-between align-items-center px-3 py-2"
          v-if="filteredUsers.length > itemsPerPage"
        >
          <div class="text-secondary text-sm">
            <span v-auto-translate>
              Viser {{ (currentPage - 1) * itemsPerPage + 1 }} - 
              {{ Math.min(currentPage * itemsPerPage, filteredUsers.length) }} 
              af {{ filteredUsers.length }} brugere
            </span>
          </div>
          <nav aria-label="Page navigation">
            <ul class="pagination mb-0">
              <li 
                class="page-item" 
                :class="{ disabled: currentPage === 1 }"
              >
                <a 
                  class="page-link" 
                  href="#" 
                  @click.prevent="currentPage > 1 && currentPage--"
                >
                  <span v-auto-translate>Forrige</span>
                </a>
              </li>
              <li 
                v-for="page in totalPages" 
                :key="page"
                class="page-item"
                :class="{ active: currentPage === page }"
              >
                <a 
                  class="page-link" 
                  href="#" 
                  @click.prevent="currentPage = page"
                >
                  {{ page }}
                </a>
              </li>
              <li 
                class="page-item" 
                :class="{ disabled: currentPage === totalPages }"
              >
                <a 
                  class="page-link" 
                  href="#" 
                  @click.prevent="currentPage < totalPages && currentPage++"
                >
                  <span v-auto-translate>Næste</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <!-- Modal -->
      <div v-if="showAddCompanyModal" class="modal-overlay">
        <div class="modal-container">
          <CompanyAddComponent @close="closeAddCompanyModal" />
        </div>
      </div>

      <Teleport to="body">
        <OrderList
          v-if="showScheduledPostsModal"
          :posts="scheduledPosts"
          :postsSend="scheduledPostsSend"
          :userId="selectedUserId"
          :is-open="showScheduledPostsModal"
          @close="closeScheduledPostsModal"
          @delete-post="deletePost"
          @view-post="viewPost"
        />
      </Teleport>

      <Teleport to="body">
        <InvoiceAdminModal
          v-if="showInvoiceAdminModal"
          :userId="selectedUserForInvoices?.id"
          :userData="selectedUserForInvoices"
          :users="users"
          @close="closeInvoiceAdmin"
        />
      </Teleport>
    </div>
  </div>
</template>

<style scoped>
/* Fjern card styling og tilføj ny wrapper styling */
.table-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  backdrop-filter: blur(10px);
}

/* Opdater table-container styling */
.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  padding: 0;
}

/* Fjern card margin */
.card {
  margin: 0;
}

/* Opdater table styling for direkte på baggrund */
.table {
  background: transparent;
  color: #ffffff;
  border-collapse: separate;
  border-spacing: 0 8px;
  margin: 0;
}

/* Opdater table header styling */
.table thead th {
  background: rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* Opdater table row styling */
.table tbody tr {
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}

.table tbody tr:hover {
  background: rgba(0, 0, 0, 0.2);
}

/* Søgefelt */
.search-input {
  background: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  border-radius: 12px;
  padding: 12px 20px;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 400px;
}

.search-input:focus {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
}

.search-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

/* Fælles knap styling */
:deep(.custom-button) {
  background: rgba(255, 255, 255, 0.08) !important;
  backdrop-filter: blur(8px) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 12px !important;
  padding: 8px 16px !important;
  color: white !important;
  font-weight: 500 !important;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1) !important;
  transform: translateZ(0);
  will-change: transform, background-color;
  box-shadow: 
    0 2px 5px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(255, 255, 255, 0.05) !important;
}

:deep(.custom-button:hover) {
  background: rgba(255, 255, 255, 0.15) !important;
}

/* Farve varianter med subtil gradient */
:deep(.custom-button.btn-azure) {
  background: linear-gradient(
    135deg, 
    rgba(33, 150, 243, 0.15), 
    rgba(33, 150, 243, 0.25)
  ) !important;
}

:deep(.custom-button.btn-success) {
  background: linear-gradient(
    135deg, 
    rgba(66, 186, 150, 0.15), 
    rgba(66, 186, 150, 0.25)
  ) !important;
}

:deep(.custom-button.btn-danger) {
  background: linear-gradient(
    135deg, 
    rgba(245, 54, 92, 0.15), 
    rgba(245, 54, 92, 0.25)
  ) !important;
}

:deep(.custom-button.btn-secondary) {
  background: linear-gradient(
    135deg, 
    rgba(130, 214, 255, 0.15), 
    rgba(130, 214, 255, 0.25)
  ) !important;
}

/* Hover effekter for farve varianter */
:deep(.custom-button.btn-azure:hover),
:deep(.custom-button.btn-success:hover),
:deep(.custom-button.btn-danger:hover),
:deep(.custom-button.btn-secondary:hover) {
  opacity: 0.9 !important;
}

/* Badge */
.badge-sm {
  backdrop-filter: blur(4px);
  border-radius: 20px;
  padding: 5px 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.badge-sm.font-weight-bold {
  font-weight: 700 !important;
}

/* Modal styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  z-index: 1000;
  animation: fadeIn 0.3s cubic-bezier(0.2, 0, 0.2, 1);
}

.modal-container {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 
    0 15px 35px rgba(0, 0, 0, 0.2),
    0 0 0 1px rgba(255, 255, 255, 0.1);
  animation: slideUp 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  padding: 20px;
  position: relative;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
}

/* Tilføj styling for badge container */
.d-flex.gap-2 {
  display: flex;
  gap: 0.5rem;
}

/* Opdater badge styling */
.badge-sm {
  backdrop-filter: blur(4px);
  border-radius: 20px;
  padding: 5px 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Success badge styling */
:deep(.badge-sm.badge-success) {
  background: linear-gradient(
    135deg, 
    rgba(46, 204, 113, 0.1), 
    rgba(46, 204, 113, 0.2)
  ) !important;
  border: 1px solid rgba(46, 204, 113, 0.15) !important;
}

/* Danger badge styling */
:deep(.badge-sm.badge-danger) {
  background: linear-gradient(
    135deg, 
    rgba(231, 76, 60, 0.1), 
    rgba(231, 76, 60, 0.2)
  ) !important;
  border: 1px solid rgba(231, 76, 60, 0.15) !important;
}

/* Tilføj spinner styling */
.spinner-border {
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
  border: 0.2em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

/* Switch styling */
.form-switch {
  padding-left: 2.5em;
}

.form-check-input {
  cursor: pointer;
  width: 40px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
}

.form-check-input:checked {
  background-color: #2ecc71;
  border-color: #27ae60;
}

.form-check-input:focus {
  border-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 0 0.2rem rgba(46, 204, 113, 0.25);
}

.form-check-label {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.875rem;
  cursor: pointer;
}

/* Tilføj smooth transitions */
.table-row-enter-active,
.table-row-leave-active {
  transition: all 0.3s ease;
}

.table-row-enter-from,
.table-row-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

/* Optimér rendering med will-change */
.table-responsive {
  will-change: transform;
  transform: translateZ(0);
}

/* Tilføj loading skeleton styles */
.skeleton-loading {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

/* Pagination styling */
.pagination {
  margin-bottom: 0;
}

.page-link {
  background: rgba(255, 255, 255, 0.07);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
}

.page-link:hover {
  background: rgba(255, 255, 255, 0.15);
  color: white;
}

.page-item.active .page-link {
  background: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.3);
  color: white;
}

.page-item.disabled .page-link {
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
}

/* Tilføj cursor og hover effekt til brugerrækker */
.user-row {
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
}

.user-row::after {
  content: '';
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.1);
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.user-row:hover::after {
  opacity: 1;
}

/* Tilføj active state for bedre feedback */
.user-row:active::after {
  background: rgba(255, 255, 255, 0.15);
}

/* Sørg for at knapper og andre interaktive elementer stadig virker */
.user-row button,
.user-row a,
.user-row .form-switch {
  position: relative;
  z-index: 1;
}

/* Stop propagation på interaktive elementer */
.user-row button,
.user-row a,
.user-row .form-switch {
  cursor: pointer;
}

.security-actions-container {
  background: rgba(255, 0, 0, 0.05);
  border: 1px solid rgba(255, 0, 0, 0.1);
  border-radius: 12px;
  padding: 12px;
  margin-top: 8px;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Tilføj hover effekt på container */
.security-actions-container:hover {
  background: rgba(255, 0, 0, 0.08);
}

/* Tilføj dette til din eksisterende styling */
.created-by-info {
  background: rgba(255, 255, 255, 0.05);
  padding: 4px 8px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.created-by-info i {
  color: rgba(255, 255, 255, 0.6);
}

/* Tilføj denne nye styling */
.filter-dropdown {
  min-width: 200px;
}

.filter-select {
  background: rgba(255, 255, 255, 0.07);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  border-radius: 12px;
  padding: 8px 12px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.filter-select:hover {
  background: rgba(255, 255, 255, 0.1);
}

.filter-select:focus {
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.3);
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
}

.filter-select option {
  background: #2c3e50;
  color: white;
}

/* Opdater eksisterende search-bar styling */
.search-bar {
  max-width: 400px;
}

/* Quick Setup badge styling */
.badge.bg-success {
  background: rgba(46, 204, 113, 0.2) !important;
  border: 1px solid rgba(46, 204, 113, 0.3);
  color: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 0.8rem;
  font-weight: 500;
  backdrop-filter: blur(4px);
}

/* Quick Setup button hover effect */
:deep(.custom-button.btn-success:hover) {
  background: linear-gradient(
    135deg, 
    rgba(46, 204, 113, 0.25), 
    rgba(46, 204, 113, 0.35)
  ) !important;
  transform: translateY(-1px);
}

/* Genopsæt button styling */
:deep(.custom-button.btn-outline-info) {
  border: 1px solid rgba(52, 152, 219, 0.3) !important;
  background: transparent !important;
}

:deep(.custom-button.btn-outline-info:hover) {
  background: rgba(52, 152, 219, 0.1) !important;
}

/* Opdater eksisterende table-responsive styling */
.table-responsive {
  max-height: calc(100vh - 300px); /* Juster dette tal baseret på header/footer højde */
  overflow-y: auto;
  position: relative;
  margin-bottom: 0; /* Fjern margin bottom */
}

/* Tilføj sticky header */
.table thead th {
  position: sticky;
  top: 0;
  z-index: 10;
  background: #1a1e2d; /* Match din baggrund */
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* Tilføj container class */
.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0; /* Vigtigt for flex-shrink */
}

/* Opdater card styling */
.card {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 20px;
  overflow: hidden;
}

/* Opdater table-container styling */
.table-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

/* Opdater card-body styling */
.card-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

/* Opdater table-responsive styling */
.table-responsive {
  flex: 1;
  overflow-y: auto;
  min-height: 0;
}

/* Opdater table styling */
.table {
  height: 100%;
  margin-bottom: 0;
}

/* Opdater tbody styling */
.table tbody {
  height: 100%;
}

/* Opdater tr styling */
.table tbody tr {
  height: 60px; /* Juster denne værdi efter behov */
}

/* Tilføj denne nye styling for at sikre konsistent række-højde */
.table tbody tr td {
  padding: 15px;
  vertical-align: middle;
}

/* Fjern max-height fra table-responsive */
.table-responsive {
  max-height: none;
}

/* Tilføj minimum højde til table container når der er få rækker */
.table-container {
  min-height: 400px; /* Juster denne værdi efter behov */
}

/* Tilføj smooth scrolling */
.table-responsive {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

/* Optimér scroll performance */
.table-responsive::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.table-responsive::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

/* Tilføj disse nye responsive styles */
@media (max-width: 1200px) {
  /* Skjul mindre vigtige kolonner på mindre skærme */
  .table th:nth-child(8),
  .table td:nth-child(8),
  .table th:nth-child(7),
  .table td:nth-child(7) {
    display: none;
  }
  
  /* Gør tekst mindre */
  .table {
    font-size: 0.875rem;
  }
}

@media (max-width: 992px) {
  /* Skjul flere kolonner på endnu mindre skærme */
  .table th:nth-child(6),
  .table td:nth-child(6),
  .table th:nth-child(5),
  .table td:nth-child(5) {
    display: none;
  }

  /* Komprimér brugerinfo */
  .d-flex.px-2.py-1 {
    padding: 4px !important;
  }

  /* Gør badges mindre */
  .badge-sm {
    font-size: 0.75rem;
    padding: 3px 8px;
  }
}

@media (max-width: 768px) {
  /* Grundlæggende mobile tilpasninger */
  .table-responsive {
    margin: 0 -10px;
  }

  .table {
    font-size: 0.8rem;
  }

  /* Komprimér celle padding */
  .table td,
  .table th {
    padding: 6px 4px;
  }

  /* Tilpas brugerinfo */
  .text-sm { font-size: 0.8rem !important; }
  .text-xs { font-size: 0.7rem !important; }
  .text-xxs { font-size: 0.65rem !important; }

  /* Gør knapper mere kompakte */
  :deep(.custom-button) {
    padding: 4px 8px !important;
    font-size: 0.75rem !important;
    
    i {
      font-size: 0.8rem;
      margin-right: 4px !important;
    }
  }

  /* Tilpas security actions container */
  .security-actions-container {
    padding: 8px;
  }

  /* Optimér plads i actions kolonne */
  .d-flex.gap-2 {
    gap: 0.25rem !important;
  }
}

@media (max-width: 576px) {
  /* Ekstrem mobil tilpasning */
  .table {
    font-size: 0.75rem;
  }

  /* Skjul ID og mindre vigtige informationer */
  .text-xxs {
    display: none;
  }

  /* Minimer padding yderligere */
  .table td,
  .table th {
    padding: 4px 2px;
  }

  /* Gør badges endnu mindre */
  .badge-sm {
    font-size: 0.7rem;
    padding: 2px 6px;
  }

  /* Komprimér knapper maksimalt */
  :deep(.custom-button) {
    padding: 2px 6px !important;
    font-size: 0.7rem !important;
  }

  /* Tilpas søgefelt og filter */
  .search-input,
  .filter-select {
    padding: 6px 10px;
    font-size: 0.875rem;
  }
}

/* Tilføj horizontal scroll indikator */
.table-scroll-indicator {
  height: 2px;
  background: linear-gradient(90deg, 
    rgba(255, 255, 255, 0.2) var(--scroll-percent, 0%), 
    transparent var(--scroll-percent, 0%));
  position: sticky;
  bottom: 0;
  z-index: 10;
}

/* Tilføj disse styles efter de eksisterende table styles */
.table thead th {
  height: 50px; /* Fast højde for header */
  vertical-align: middle;
  background: rgba(255, 255, 255, 0.05);
  position: sticky;
  top: 0;
  z-index: 10;
}

/* Opdater empty state styling */
.table tbody tr.empty-state {
  height: 200px;
}

.table tbody tr.empty-state td {
  vertical-align: middle;
}

/* Tilføj responsive media queries tilbage */
@media (max-width: 768px) {
  .card {
    margin: 10px;
  }

  /* Gør søgefelt og filter mere kompakte */
  .search-bar,
  .filter-dropdown {
    flex: 1;
    min-width: 0;
  }

  .d-flex.gap-3 {
    gap: 0.5rem !important;
  }

  /* Juster padding for bedre pladsudnyttelse */
  .table td,
  .table th {
    padding: 8px;
  }

  /* Gør knapper mindre på mobile enheder */
  :deep(.custom-button) {
    padding: 4px 8px !important;
    font-size: 0.875rem !important;
  }
}

.badge {
  min-width: 65px;
  text-align: center;
  padding: 4px 8px;
  font-size: 0.75rem;
}

.text-secondary {
  opacity: 0.7;
  font-size: 0.7rem;
}

.mb-1 {
  margin-bottom: 2px !important;
}
</style>