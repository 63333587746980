<template>
  <div class="blog-admin">
    <!-- Editor Section -->
    <div class="blog-section">
      <div class="blog-header">
      </div>
      <div class="blog-content">
        <div class="row">
          <!-- Editor Column -->
          <div class="col-md-6 border-end">
            <form @submit.prevent="createBlogPost">
              <!-- Title input -->
              <div class="mb-4">
                <label for="title" class="form-label">Overskrift</label>
                <div class="input-group">
                  <span class="input-group-text">
                    <i class="fas fa-heading"></i>
                  </span>
                  <input 
                    v-model="blogPost.title" 
                    type="text" 
                    class="form-control" 
                    :class="{ 'is-invalid': errors.title }"
                    id="title" 
                    placeholder="Skriv en fængende overskrift..."
                  >
                </div>
                <div class="invalid-feedback" v-if="errors.title">
                  {{ errors.title }}
                </div>
              </div>

              <!-- Category select -->
              <div class="mb-4">
                <label class="form-label">Kategori</label>
                <div class="input-group">
                  <span class="input-group-text">
                    <i class="fas fa-tag"></i>
                  </span>
                  <select 
                    v-model="blogPost.category" 
                    class="form-select"
                    :class="{ 'is-invalid': errors.category }"
                  >
                    <option value="">Vælg kategori</option>
                    <option value="Nyhed">Nyhed</option>
                    <option value="Feature">Feature</option>
                    <option value="Profil">Profil</option>
                    <option value="Kalender">Kalender</option>
                  </select>
                </div>
                <div class="invalid-feedback" v-if="errors.category">
                  {{ errors.category }}
                </div>
              </div>

              <!-- Rich text editor -->
              <div class="mb-4">
                <label class="form-label d-flex justify-content-between align-items-center">
                  <span>Indhold</span>
                  <div class="d-flex align-items-center gap-2">
                    <small class="text-muted" v-if="contentWordCount > 0">
                      {{ contentWordCount }} ord
                    </small>
                    <button 
                      @click="correctGrammar" 
                      class="btn btn-sm btn-secondary"
                      :disabled="isCorrectingText"
                    >
                      <i class="fas fa-spell-check me-1"></i>
                      {{ isCorrectingText ? 'Retter...' : 'Ret stavefejl' }}
                    </button>
                    <button 
                      @click="aiAssistedText" 
                      class="btn btn-sm btn-primary"
                      :disabled="isGeneratingText"
                    >
                      <i class="fas fa-magic me-1"></i>
                      {{ isGeneratingText ? 'Genererer...' : 'AI Assist' }}
                    </button>
                  </div>
                </label>
                <div 
                  ref="quillEditor"
                  :class="{ 'border border-danger rounded': errors.content }"
                  class="quill-editor"
                ></div>
                <div class="text-danger small mt-1" v-if="errors.content">
                  {{ errors.content }}
                </div>
              </div>

              <!-- Image upload -->
              <div class="mb-4">
                <label class="form-label">Banner billede</label>
                <div class="image-upload-container">
                  <div class="image-upload-area" 
                       :class="{ 'has-image': imagePreview }"
                       @click="triggerFileInput"
                       @dragover.prevent
                       @drop.prevent="handleFileDrop">
                    
                    <input 
                      type="file" 
                      ref="fileInput"
                      @change="handleImageUpload" 
                      accept="image/jpeg,image/png,image/webp"
                      class="file-input"
                    >
                    
                    <div v-if="!imagePreview" class="upload-placeholder">
                      <i class="fas fa-cloud-upload-alt"></i>
                      <p>Træk dit billede hertil eller klik for at vælge</p>
                      <span class="upload-info">
                        <i class="fas fa-info-circle"></i>
                        Maksimal størrelse: 5MB. Formater: JPEG, PNG, WEBP
                      </span>
                    </div>
                    
                    <div v-else class="image-preview">
                      <img :src="imagePreview" alt="Banner preview">
                      <div class="image-preview-overlay">
                        <button @click.stop="removeImage" class="btn btn-danger btn-sm">
                          <i class="fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Notification toggle -->
              <div class="form-check form-switch mb-4">
                <input 
                  v-model="blogPost.sendNotification" 
                  class="form-check-input" 
                  type="checkbox"
                  id="notificationToggle"
                  :disabled="isSubmitting"
                >
                <label class="form-check-label" for="notificationToggle">
                  Send notifikation til brugere
                </label>
              </div>

              <!-- LinkedIn toggle -->
              <div class="form-check form-switch mb-4">
                <input 
                  v-model="blogPost.shareToLinkedIn" 
                  class="form-check-input" 
                  type="checkbox"
                  id="linkedinToggle"
                  :disabled="isSubmitting"
                >
                <label class="form-check-label" for="linkedinToggle">
                  Del på LinkedIn
                </label>
              </div>

              <!-- Submit button -->
              <div class="d-flex justify-content-end">
                <button 
                  type="submit"
                  class="btn btn-primary"
                  :disabled="isSubmitting"
                >
                  <span v-if="isSubmitting" class="spinner-border spinner-border-sm me-2"></span>
                  {{ isSubmitting ? 'Opretter...' : 'Opret indlæg' }}
                </button>
              </div>
            </form>
          </div>

          <!-- Preview Column -->
          <div class="col-md-6">
            <div class="preview-container">
              
              <!-- Preview af blog post -->
              <div class="blog-preview">
                <!-- Banner Image Preview -->
                <div 
                  class="preview-banner" 
                  :style="previewBannerStyle"
                >
                  <div class="preview-banner-overlay">
                    <div class="preview-banner-content">
                      <div class="preview-category">{{ blogPost.category || 'Kategori' }}</div>
                      <h1 class="preview-title">{{ blogPost.title || 'Din overskrift her' }}</h1>
                      <div class="preview-date">{{ formatDate(new Date()) }}</div>
                    </div>
                  </div>
                </div>

                <!-- Content Preview -->
                <div class="preview-content">
                  <div class="preview-body" v-html="blogPost.content || 'Dit indhold her...'"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Blog Posts List Section -->
    <div class="blog-section">
      <div class="blog-header">
        <h3>Blog Indlæg</h3>
      </div>
      <div class="blog-content">
        <div class="table-responsive">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Titel</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Kategori</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Forfatter</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Oprettet</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Handlinger</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="isLoading">
                <td colspan="5" class="text-center py-4">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Indlæser...</span>
                  </div>
                </td>
              </tr>
              <tr v-else-if="blogPosts.length === 0">
                <td colspan="5" class="text-center py-4">
                  Ingen blog indlæg fundet
                </td>
              </tr>
              <tr v-for="post in blogPosts" :key="post.id">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div v-if="post.bannerImageUrl" class="me-3">
                      <img 
                        :src="post.bannerImageUrl" 
                        class="avatar avatar-sm rounded-circle" 
                        :alt="post.title"
                      >
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ post.title }}</h6>
                    </div>
                  </div>
                </td>
                <td>
                  <span class="badge bg-gradient-info">{{ post.category }}</span>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ post.authorName }}</p>
                  <p class="text-xs text-secondary mb-0">{{ post.authorEmail }}</p>
                </td>
                <td>
                  <span class="text-secondary text-xs font-weight-bold">
                    {{ formatDate(post.createdAt) }}
                  </span>
                </td>
                <td>
                  <button 
                    @click="editBlogPost(post)" 
                    class="btn btn-link text-secondary mb-0"
                  >
                    <i class="fas fa-pencil-alt text-xs"></i>
                  </button>
                  <button 
                    @click="deleteBlogPost(post)" 
                    class="btn btn-link text-danger mb-0"
                  >
                    <i class="fas fa-trash text-xs"></i>
                  </button>
                  <button 
                    @click="shareToLinkedIn(post)"
                    class="btn btn-link text-primary mb-0"
                    :disabled="post.isSharing"
                  >
                    <i class="fab fa-linkedin text-xs"></i>
                    <span v-if="post.isSharing" class="spinner-border spinner-border-sm ms-1"></span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, nextTick, watch } from 'vue';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { createBlogPost as createBlogPostService } from '@/services/blogService'; // Du skal oprette denne service
import { getFirestore, collection, addDoc, getDoc, doc, serverTimestamp, updateDoc, query, orderBy, getDocs, deleteDoc } from 'firebase/firestore';
import { getStorage, ref as fbStorageRef, uploadBytes, getDownloadURL, ref as fbRef, deleteObject } from 'firebase/storage';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';
import { formatDate } from '@/utils/dateFormatter'; // Create this utility
import axios from 'axios';
import { useUserContext } from '@/composables/useUserContext';
import { auth, db } from '@/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import DOMPurify from 'dompurify';
import { API_URLS } from '@/config/api';
import { useStore } from 'vuex';

const quillEditor = ref(null);
const quillInstance = ref(null);
const blogPost = ref({
  title: '',
  category: '',
  content: '',
  bannerImage: null,
  sendNotification: false,
  shareToLinkedIn: false
});

const router = useRouter();
const isSubmitting = ref(false);
const errors = ref({});
const imagePreview = ref(null);
const { contextUserId } = useUserContext();
const userId = computed(() => contextUserId.value);
const isGeneratingText = ref(false);
const isCorrectingText = ref(false);
const isEditing = ref(false);
const editingPostId = ref(null);
const blogPosts = ref([]);
const isLoading = ref(false);
const fileInput = ref(null);

const contentWordCount = computed(() => {
  if (!blogPost.value.content) return 0;
  const stripHtml = blogPost.value.content.replace(/<[^>]*>/g, '');
  return stripHtml.trim().split(/\s+/).length;
});

const store = useStore();

onMounted(async () => {
  await nextTick();
  
  if (quillEditor.value) {
    try {
      quillInstance.value = new Quill(quillEditor.value, {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'header': [1, 2, false] }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'align': [] }],
            ['link', 'image', 'video'],
            ['clean']
          ],
          keyboard: {
            bindings: {}
          },
          clipboard: {
            matchVisual: false
          }
        },
        placeholder: 'Skriv dit indhold her...'
      });

      // Forenklet text-change event handler
      quillInstance.value.on('text-change', () => {
        try {
          if (quillInstance.value && quillInstance.value.root) {
            blogPost.value.content = quillInstance.value.root.innerHTML;
          }
        } catch (error) {
          console.warn('Fejl ved opdatering af content:', error);
        }
      });

      // Tilføj image handler efter Quill initialisering
      quillInstance.value.getModule('toolbar').addHandler('image', () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
          const file = input.files[0];
          if (file) {
            try {
              // Valider filstørrelse (5MB max)
              if (file.size > 5 * 1024 * 1024) {
                throw new Error('Billedet må maksimalt være 5MB');
              }

              // Valider filtype
              if (!file.type.match(/^image\/(jpeg|png|gif|webp)$/)) {
                throw new Error('Kun JPEG, PNG, GIF og WEBP billeder er tilladt');
              }

              // Upload til Firebase Storage
              const storage = getStorage();
              const fileName = `blog-images/${Date.now()}_${file.name}`;
              const storageRef = fbStorageRef(storage, fileName);
              
              // Tjek brugerens rolle før upload
              const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
              if (!userDoc.exists() || userDoc.data().role > 2) {
                throw new Error('Du har ikke tilladelse til at uploade billeder');
              }
              
              // Vis loading indikator
              Swal.fire({
                title: 'Upload billede...',
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading();
                }
              });

              // Upload fil
              await uploadBytes(storageRef, file);
              const imageUrl = await getDownloadURL(storageRef);

              // Indsæt billede i editoren på en sikker måde
              try {
                // Få den nuværende cursor position
                const range = quillInstance.value.getSelection(true);
                if (range) {
                  // Indsæt billede ved cursor position
                  quillInstance.value.insertEmbed(range.index, 'image', imageUrl);
                  
                  // Flyt cursor efter billedet
                  setTimeout(() => {
                    try {
                      quillInstance.value.setSelection(range.index + 1, 0);
                    } catch (e) {
                      console.warn('Kunne ikke sætte cursor position, men billedet er indsat');
                    }
                  }, 0);
                } else {
                  // Hvis ingen cursor position, indsæt i slutningen
                  quillInstance.value.insertEmbed(quillInstance.value.getLength() - 1, 'image', imageUrl);
                }
              } catch (insertError) {
                console.warn('Fejl ved indsættelse af billede i editor:', insertError);
                // Fallback: Indsæt i slutningen af dokumentet
                quillInstance.value.insertEmbed(quillInstance.value.getLength() - 1, 'image', imageUrl);
              }

              await Swal.fire({
                icon: 'success',
                title: 'Billede uploadet',
                timer: 1500
              });

            } catch (error) {
              console.error('Fejl ved upload af billede:', error);
              Swal.fire({
                icon: 'error',
                title: 'Upload fejlede',
                text: error.message || 'Der skete en fejl ved upload af billedet'
              });
            }
          }
        };
      });

      // Tilføj video handler
      quillInstance.value.getModule('toolbar').addHandler('video', () => {
        const url = prompt('Indsæt video URL (YouTube, Vimeo)');
        if (url) {
          try {
            // Valider og konverter URL til embed format
            let embedUrl = url;
            
            // YouTube URL konvertering
            if (url.includes('youtube.com') || url.includes('youtu.be')) {
              const videoId = url.includes('youtube.com') 
                ? url.split('v=')[1]?.split('&')[0]
                : url.split('youtu.be/')[1]?.split('?')[0];
                
              if (videoId) {
                embedUrl = `https://www.youtube.com/embed/${videoId}`;
              }
            }
            // Vimeo URL konvertering
            else if (url.includes('vimeo.com')) {
              const videoId = url.split('vimeo.com/')[1]?.split('?')[0];
              if (videoId) {
                embedUrl = `https://player.vimeo.com/video/${videoId}`;
              }
            }

            // Indsæt video i editoren
            const range = quillInstance.value.getSelection(true);
            quillInstance.value.insertEmbed(range.index, 'video', embedUrl, 'user');
            quillInstance.value.setSelection(range.index + 1);

          } catch (error) {
            console.error('Fejl ved indsættelse af video:', error);
            Swal.fire({
              icon: 'error',
              title: 'Fejl',
              text: 'Kunne ikke indsætte video. Kontroller at URL\'en er korrekt.'
            });
          }
        }
      });

    } catch (error) {
      console.error('Fejl ved initialisering af Quill:', error);
    }
  }
  
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (!user) {
      console.log('Bruger er ikke længere logget ind');
      router.push('/login');
    }
  });

  await fetchBlogPosts();
});

const validateForm = () => {
  errors.value = {};
  
  if (!blogPost.value.title?.trim()) {
    errors.value.title = 'Overskrift er påkrævet';
  }
  if (!blogPost.value.category) {
    errors.value.category = 'Kategori skal vælges';
  }
  if (!blogPost.value.content?.trim()) {
    errors.value.content = 'Indhold er påkrævet';
  }
  
  return Object.keys(errors.value).length === 0;
};

const createBlogPost = async () => {
  if (isSubmitting.value) return;
  
  if (!validateForm()) {
    Swal.fire({
      icon: 'error',
      title: 'Validering fejlede',
      text: 'Udfyld venligst alle påkrævede felter'
    });
    return;
  }
  
  try {
    isSubmitting.value = true;
    
    // Tjek authentication
    if (!auth.currentUser) {
      throw new Error('Du skal være logget ind');
    }

    // Tjek brugerens rolle
    const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
    if (!userDoc.exists() || userDoc.data().role > 2) {
      throw new Error('Du har ikke tilladelse til dette');
    }

    // Upload banner billede hvis det findes
    let bannerImageUrl = null;
    if (blogPost.value.bannerImage) {
      const storage = getStorage();
      const fileName = `blog-images/${Date.now()}_${blogPost.value.bannerImage.name}`;
      const storageRef = fbStorageRef(storage, fileName);
      await uploadBytes(storageRef, blogPost.value.bannerImage);
      bannerImageUrl = await getDownloadURL(storageRef);
    }

    // Forbered blog data
    const blogData = {
      title: blogPost.value.title.trim(),
      content: blogPost.value.content.trim(),
      category: blogPost.value.category,
      updatedAt: serverTimestamp(),
      sendNotification: blogPost.value.sendNotification || false
    };

    // Hvis der er et nyt banner billede, tilføj URL'en
    if (bannerImageUrl) {
      blogData.bannerImageUrl = bannerImageUrl;
    }

    // Hvis vi redigerer et eksisterende indlæg
    if (isEditing.value && editingPostId.value) {
      const docRef = doc(db, 'blogPosts', editingPostId.value);
      await updateDoc(docRef, blogData);
      
      await Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Blogindlæg opdateret succesfuldt',
        timer: 2000
      });
    } 
    // Hvis vi opretter et nyt indlæg
    else {
      // Tilføj ekstra felter for nyt indlæg
      blogData.createdAt = serverTimestamp();
      blogData.author = auth.currentUser.uid;
      blogData.authorEmail = auth.currentUser.email;
      blogData.authorName = userDoc.data().name || auth.currentUser.email;
      blogData.status = 'published';
      
      const docRef = await addDoc(collection(db, 'blogPosts'), blogData);
      
      await Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Blogindlæg oprettet succesfuldt',
        timer: 2000
      });
    }

    // Reset form
    resetForm();
    
    // Emit event for at opdatere liste
    emit('blogCreated');

    await fetchBlogPosts();

    // Hvis brugeren har valgt at dele på LinkedIn
    if (blogPost.value.shareToLinkedIn) {
      try {
        await linkedinService.createPost({
          id: docRef.id, // Tilføj ID fra det nyoprettede indlæg
          title: blogPost.value.title,
          content: blogPost.value.content,
          bannerImageUrl: bannerImageUrl
        });

        await Swal.fire({
          icon: 'success',
          title: 'Blog indlæg oprettet og delt!',
          text: 'Dit indlæg er blevet oprettet og delt på LinkedIn',
          timer: 2000
        });
      } catch (linkedInError) {
        console.error('LinkedIn deling fejlede:', linkedInError);
        
        await Swal.fire({
          icon: 'warning',
          title: 'Indlæg oprettet, men deling fejlede',
          text: linkedInError.message || 'Kunne ikke dele på LinkedIn',
          showConfirmButton: true
        });
      }
    }

  } catch (error) {
    console.error('Fejl ved håndtering af blogindlæg:', error);
    Swal.fire({
      icon: 'error',
      title: 'Fejl',
      text: error.message || 'Der skete en fejl ved håndtering af blogindlægget'
    });
  } finally {
    isSubmitting.value = false;
  }
};

// Tilføj denne nye funktion til at resette formen
const resetForm = () => {
  blogPost.value = {
    title: '',
    content: '',
    category: '',
    bannerImage: null,
    sendNotification: false,
    shareToLinkedIn: false
  };
  
  if (quillInstance.value) {
    quillInstance.value.setText('');
  }
  
  imagePreview.value = null;
  isEditing.value = false;
  editingPostId.value = null;
  errors.value = {};
};

// Tilføj denne nye funktion til at loade et eksisterende blogindlæg
const loadBlogPost = async (postId) => {
  try {
    const docRef = doc(db, 'blogPosts', postId);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const post = docSnap.data();
      
      // Opdater form data
      blogPost.value = {
        title: post.title,
        category: post.category,
        content: post.content,
        bannerImage: null,
        sendNotification: false,
        shareToLinkedIn: false
      };
      
      // Opdater Quill editor
      if (quillInstance.value) {
        quillInstance.value.root.innerHTML = post.content;
      }
      
      // Vis eksisterende banner billede
      if (post.bannerImageUrl) {
        imagePreview.value = post.bannerImageUrl;
      }
      
      isEditing.value = true;
      editingPostId.value = postId;
      
    } else {
      throw new Error('Blogindlæg ikke fundet');
    }
  } catch (error) {
    console.error('Fejl ved indlæsning af blogindlæg:', error);
    Swal.fire({
      icon: 'error',
      title: 'Fejl',
      text: 'Kunne ikke indlæse blogindlægget'
    });
  }
};

// Computed property for banner preview
const previewBannerStyle = computed(() => {
  if (imagePreview.value) {
    return {
      backgroundImage: `url(${imagePreview.value})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    };
  }
  return {
    background: 'linear-gradient(122deg, #05c2ed, #1568db)' // Default background if no image
  };
});

const aiAssistedText = async () => {
  if (isGeneratingText.value) return;
  
  isGeneratingText.value = true;
  try {
    Swal.fire({
      title: 'Genererer tekst...',
      text: 'Vent venligst mens AI assistenten arbejder',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    // Split anmodningen i flere dele hvis nødvendigt
    const generateFullContent = async () => {
      let fullContent = '';
      let attempts = 0;
      const maxAttempts = 3;

      while (attempts < maxAttempts) {
        try {
          const response = await fetch(API_URLS.correctText, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              text: blogPost.value.content || '',
              emne: blogPost.value.title,
              platform: 'blog',
              messages: [
                {
                  role: "system",
                  content: `Du er en professionel dansk content writer med ekspertise i at skrive engagerende blogindlæg.
                           Følg disse retningslinjer:
                           - Skriv komplette blogindlæg på 800-1200 ord
                           - Brug en journalistisk skrivestil med fokus på læsevenlighed
                           - Inkluder:
                             * En fængende indledning
                             * 3-4 hovedafsnit med relevante underoverskrifter
                             * Faktabokse eller punktlister hvor relevant
                             * En konkluderende afslutning
                           - Brug korrekte HTML-tags (<h2>, <h3>, <p>, <ul>, <li>, <strong>)
                           - Tilføj relevante eksempler og referencer
                           - Hold en professionel men conversational tone
                           - Optimer for SEO med naturlig brug af nøgleord
                           - Afslut ALTID med en konklusion eller opsummering
                           ${attempts > 0 ? '- Fortsæt fra tidligere genereret indhold' : ''}`
                },
                {
                  role: "user",
                  content: `Skriv et komplet og detaljeret blogindlæg om:
                           Titel: ${blogPost.value.title}
                           Kategori: ${blogPost.value.category}
                           
                           Krav:
                           - Minimum 800 ord
                           - Velstruktureret med klare afsnit
                           - Inkluder relevante eksempler og data
                           - Brug HTML formatering
                           - Målgruppe: ${blogPost.value.category === 'Nyhed' ? 'Generel læser' : 'Fagligt interesserede'}
                           
                           ${attempts > 0 ? 'Fortsæt fra dette indhold:\n' + fullContent : ''}`
                }
              ]
            })
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          
          if (!data.correctedText) {
            throw new Error('Ingen gyldig tekst modtaget');
          }

          // Tilføj nyt indhold til det eksisterende
          fullContent += data.correctedText;

          // Tjek om indholdet er komplet
          if (fullContent.includes('</article>') || fullContent.includes('</div>')) {
            break;
          }

          attempts++;
        } catch (error) {
          console.error(`Forsøg ${attempts + 1} fejlede:`, error);
          attempts++;
          if (attempts === maxAttempts) {
            throw error;
          }
        }
      }

      return fullContent;
    };

    const generatedContent = await generateFullContent();
    const formattedContent = formatGeneratedContent(generatedContent);
    
    if (quillInstance.value) {
      quillInstance.value.root.innerHTML = formattedContent;
      blogPost.value.content = formattedContent;
    }

    await Swal.fire({
      icon: 'success',
      title: 'Indhold genereret',
      text: 'AI assistenten har genereret nyt indhold til dit blogindlæg',
      timer: 2000
    });

  } catch (error) {
    console.error('AI Assist fejl:', error);
    await Swal.fire({
      icon: 'error',
      title: 'AI Assist Fejl',
      text: error.message || 'Der opstod en fejl ved generering af indhold'
    });
  } finally {
    isGeneratingText.value = false;
  }
};

const correctGrammar = async () => {
  if (isCorrectingText.value || !blogPost.value.content.trim()) return;
  
  isCorrectingText.value = true;
  try {
    Swal.fire({
      title: 'Retter tekst...',
      text: 'Vent venligst mens teksten gennemgås for fejl',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    const response = await fetch(API_URLS.correctText, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        text: blogPost.value.content,
        action: 'grammar',
        messages: [
          {
            role: "system",
            content: `Du er en professionel korrekturlæser og sprogekspert.
                     Din opgave er KUN at:
                     - Rette stavefejl
                     - Rette grammatiske fejl
                     - Forbedre tegnsætning
                     - Sikre korrekt bøjning af ord
                     
                     Regler:
                     - Bevar den originale betydning og tone
                     - Bevar alle HTML tags og formatering
                     - Lav IKKE om på indholdet eller strukturen
                     - Fokuser KUN på sproglige fejl
                     - Bevar alle links og referencer`
          },
          {
            role: "user",
            content: `Ret venligst følgende tekst for stavefejl og grammatik.
                     Bevar al HTML formatering og struktur.
                     
                     Tekst til korrektur:
                     ${blogPost.value.content}`
          }
        ]
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    
    if (!data.correctedText) {
      throw new Error('Ingen korrigeret tekst modtaget');
    }

    // Opdater editor med den korrigerede tekst
    if (quillInstance.value) {
      const correctedContent = sanitizeQuillContent(data.correctedText);
      quillInstance.value.root.innerHTML = correctedContent;
      blogPost.value.content = correctedContent;
    }

    // Vis forskelle mellem original og korrigeret tekst
    const changes = compareTexts(
      stripHtml(blogPost.value.content), 
      stripHtml(data.correctedText)
    );

    if (changes.length > 0) {
      await Swal.fire({
        icon: 'success',
        title: 'Tekst rettet',
        html: `
          <div class="text-start">
            <p>Følgende rettelser blev foretaget:</p>
            <ul class="list-unstyled">
              ${changes.map(change => `
                <li class="mb-2">
                  <small class="text-danger"><del>${change.original}</del></small>
                  <i class="fas fa-arrow-right mx-2"></i>
                  <small class="text-success">${change.corrected}</small>
                </li>
              `).join('')}
            </ul>
          </div>
        `,
        confirmButtonText: 'OK'
      });
    } else {
      await Swal.fire({
        icon: 'info',
        title: 'Tekst gennemgået',
        text: 'Ingen sproglige fejl blev fundet i teksten',
        timer: 2000
      });
    }

  } catch (error) {
    console.error('Fejl ved tekstrettelse:', error);
    await Swal.fire({
      icon: 'error',
      title: 'Fejl ved tekstrettelse',
      text: error.message || 'Der opstod en fejl ved rettelse af teksten'
    });
  } finally {
    isCorrectingText.value = false;
  }
};

// Hjælpefunktion til at sammenligne tekster
const compareTexts = (original, corrected) => {
  const changes = [];
  const words1 = original.split(/\s+/);
  const words2 = corrected.split(/\s+/);
  
  let i = 0, j = 0;
  while (i < words1.length && j < words2.length) {
    if (words1[i] !== words2[j]) {
      // Find næste match
      let nextMatch = -1;
      for (let k = j; k < j + 3 && k < words2.length; k++) {
        if (words1[i] === words2[k]) {
          nextMatch = k;
          break;
        }
      }
      
      if (nextMatch !== -1) {
        // Ord blev tilføjet
        changes.push({
          original: '',
          corrected: words2.slice(j, nextMatch).join(' ')
        });
        j = nextMatch;
      } else {
        // Ord blev ændret
        changes.push({
          original: words1[i],
          corrected: words2[j]
        });
        i++;
        j++;
      }
    } else {
      i++;
      j++;
    }
  }
  
  return changes;
};

// Hjælpefunktion til at fjerne HTML tags
const stripHtml = (html) => {
  const tmp = document.createElement('div');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

const emit = defineEmits(['blogCreated']);

const sanitizeQuillContent = (html) => {
  // Fjern HTML kommentarer og whitespace
  let clean = html.replace(/<!--[\s\S]*?-->/g, '')
                 .replace(/>\s+</g, '><')
                 .trim();
  
  // Konverter til DOM element for sikker parsing
  const div = document.createElement('div');
  div.innerHTML = clean;
  
  // Fjern uønskede attributter og styles
  const elements = div.getElementsByTagName('*');
  for (let el of elements) {
    el.removeAttribute('style');
    el.removeAttribute('class');
  }
  
  // Konverter tilbage til string
  clean = div.innerHTML;
  
  // Sikr korrekt formatering af lister
  clean = clean.replace(/<\/li><li>/g, '</li>\n<li>');
  
  // Tilføj support for faktabokse og citater
  clean = clean.replace(/<div class="faktaboks">/g, '<div class="ql-faktaboks">');
  clean = clean.replace(/<blockquote class="blog-quote">/g, '<blockquote class="ql-quote">');
  
  return clean;
};

const formatGeneratedContent = (content) => {
  // Tilføj ekstra formatering og struktur
  let formatted = content
    // Sikr korrekt spacing omkring overskrifter
    .replace(/(<h[23]>.*?<\/h[23]>)/g, '\n$1\n')
    
    // Tilføj luft omkring lister
    .replace(/(<ul>)/g, '\n$1\n')
    .replace(/(<\/ul>)/g, '\n$1\n')
    
    // Formater faktabokse
    .replace(/<faktaboks>(.*?)<\/faktaboks>/gs, (match, content) => {
      return `<div class="faktaboks">
                <h4>Fakta</h4>
                ${content}
              </div>`;
    })
    
    // Tilføj styling til citater
    .replace(/<citat>(.*?)<\/citat>/gs, '<blockquote class="blog-quote">$1</blockquote>')
    
    // Konverter simple * til bullet points
    .replace(/^\s*\*\s+(.+)$/gm, '<li>$1</li>')
    
    // Wrap enkeltstående list items i ul tags
    .replace(/(<li>(?:.*?<\/li>)+)/g, '<ul>$1</ul>');

  return formatted;
};

const fetchBlogPosts = async () => {
  isLoading.value = true;
  try {
    const q = query(collection(db, 'blogPosts'), orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(q);
    
    blogPosts.value = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt?.toDate(),
      updatedAt: doc.data().updatedAt?.toDate()
    }));
  } catch (error) {
    console.error('Fejl ved hentning af blog indlæg:', error);
    Swal.fire({
      icon: 'error',
      title: 'Fejl',
      text: 'Kunne ikke hente blog indlæg'
    });
  } finally {
    isLoading.value = false;
  }
};

const deleteBlogPost = async (post) => {
  try {
    const result = await Swal.fire({
      title: 'Er du sikker?',
      text: "Dette kan ikke fortrydes!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Ja, slet det!',
      cancelButtonText: 'Annuller'
    });

    if (result.isConfirmed) {
      // Slet banner billede fra storage hvis det findes
      if (post.bannerImageUrl) {
        const storage = getStorage();
        const imageRef = fbRef(storage, post.bannerImageUrl);
        try {
          await deleteObject(imageRef);
        } catch (error) {
          console.warn('Kunne ikke slette banner billede:', error);
        }
      }

      // Slet dokumentet fra Firestore
      await deleteDoc(doc(db, 'blogPosts', post.id));
      
      // Opdater listen
      await fetchBlogPosts();
      
      Swal.fire(
        'Slettet!',
        'Blog indlægget er blevet slettet.',
        'success'
      );
    }
  } catch (error) {
    console.error('Fejl ved sletning af blog indlæg:', error);
    Swal.fire({
      icon: 'error',
      title: 'Fejl',
      text: 'Kunne ikke slette blog indlægget'
    });
  }
};

const editBlogPost = async (post) => {
  try {
    // Indlæs post data i form
    blogPost.value = {
      title: post.title,
      category: post.category,
      content: post.content,
      bannerImage: null,
      sendNotification: false,
      shareToLinkedIn: false
    };
    
    if (post.bannerImageUrl) {
      imagePreview.value = post.bannerImageUrl;
    }
    
    // Opdater Quill editor
    if (quillInstance.value) {
      quillInstance.value.root.innerHTML = post.content;
    }
    
    isEditing.value = true;
    editingPostId.value = post.id;
    
    // Scroll til toppen af formen
    window.scrollTo({ top: 0, behavior: 'smooth' });
  } catch (error) {
    console.error('Fejl ved indlæsning af blog indlæg:', error);
    Swal.fire({
      icon: 'error',
      title: 'Fejl',
      text: 'Kunne ikke indlæse blog indlægget til redigering'
    });
  }
};

const formatContentForLinkedIn = (content, postId) => {
  // Fjern HTML tags men bevar linjeskift
  let text = content
    // Konverter <p> og <br> til linjeskift
    .replace(/<p>/g, '')
    .replace(/<\/p>/g, '\n\n')
    .replace(/<br\s*\/?>/g, '\n')
    // Konverter <ul> og <li> til punktlister
    .replace(/<ul>/g, '\n')
    .replace(/<\/ul>/g, '\n')
    .replace(/<li>/g, '• ')
    .replace(/<\/li>/g, '\n')
    // Bevar fed tekst med asterisker
    .replace(/<strong>(.*?)<\/strong>/g, '*$1*')
    // Fjern alle andre HTML tags
    .replace(/<[^>]*>/g, '')
    // Fjern billede URLs
    .replace(/https:\/\/firebasestorage\.googleapis\.com\S+/g, '')
    // Fjern dobbelte linjeskift
    .replace(/\n\s*\n\s*\n/g, '\n\n')
    // Trim whitespace
    .trim();

  // Tilføj hashtags baseret på relevante ord
  const hashtags = generateHashtags(text);
  
  // Formatér den endelige tekst
  return `${text}\n\n${hashtags}\n\nLæs mere på: https://makemore.dk/blog/${postId}`;
};

const generateHashtags = (text) => {
  // Liste over relevante ord at lave til hashtags
  const relevantWords = ['bilforhandler', 'markedsføring', 'automatisering', 'bil', 'auto'];
  
  // Find ord i teksten der matcher og lav dem til hashtags
  const hashtags = relevantWords
    .filter(word => text.toLowerCase().includes(word.toLowerCase()))
    .map(word => `#${word}`);
  
  // Tilføj altid disse hashtags
  hashtags.push('#makemore', '#marketing');
  
  return hashtags.join(' ');
};

const shareToLinkedIn = async (post) => {
  try {
    console.log('Starter LinkedIn deling proces...');
    post.isSharing = true;

    const shareData = {
      id: post.id,
      title: post.title,
      content: formatContentForLinkedIn(post.content, post.id),
      bannerImageUrl: post.bannerImageUrl,
      authorId: '105430136',
      url: `https://makemore.dk/blog/${post.id}`
    };

    console.log('Formateret indhold:', shareData.content);

    const response = await fetch('https://hook.eu2.make.com/3zbcu46szbucgx25uj54icgndsj9xb9g', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(shareData)
    });

    if (!response.ok) {
      throw new Error('Webhook kald fejlede');
    }
    
    await Swal.fire({
      icon: 'success',
      title: 'Sendt til LinkedIn!',
      text: 'Dit indlæg er blevet sendt til deling på LinkedIn',
      timer: 3000
    });

  } catch (error) {
    console.error('Fejl ved deling på LinkedIn:', error);
    await Swal.fire({
      icon: 'error',
      title: 'Deling fejlede',
      text: error.message || 'Kunne ikke dele på LinkedIn',
      showConfirmButton: true
    });
  } finally {
    post.isSharing = false;
  }
};

// Tilføj en watch for at nulstille isSharing status når blogPosts opdateres
watch(blogPosts, (newPosts) => {
  newPosts.forEach(post => {
    if (!post.hasOwnProperty('isSharing')) {
      post.isSharing = false;
    }
  });
}, { immediate: true });

// Tilføj disse nye funktioner
const triggerFileInput = () => {
  fileInput.value.click();
};

const handleFileDrop = (event) => {
  const file = event.dataTransfer.files[0];
  if (file) {
    validateAndProcessFile(file);
  }
};

const removeImage = () => {
  blogPost.value.bannerImage = null;
  imagePreview.value = null;
  if (fileInput.value) {
    fileInput.value.value = '';
  }
};

const validateAndProcessFile = (file) => {
  if (file.size > 5 * 1024 * 1024) {
    Swal.fire({
      icon: 'error',
      title: 'Fejl',
      text: 'Billedet må maksimalt være 5MB'
    });
    return false;
  }
  
  if (!['image/jpeg', 'image/png', 'image/webp'].includes(file.type)) {
    Swal.fire({
      icon: 'error',
      title: 'Fejl',
      text: 'Kun JPEG, PNG og WEBP billeder er tilladt'
    });
    return false;
  }

  blogPost.value.bannerImage = file;
  
  const reader = new FileReader();
  reader.onload = (e) => {
    imagePreview.value = e.target.result;
  };
  reader.readAsDataURL(file);
  return true;
};

const handleImageUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    validateAndProcessFile(file);
  }
};
</script>

<style scoped>
.preview-container {
  height: 100%;
  overflow-y: auto;
}

.blog-preview {
  background-color: #2a2a2a;
  border-radius: 8px;
  overflow: hidden;
}

.preview-banner {
  height: 300px;
  position: relative;
}

.preview-banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,0.8));
  display: flex;
  align-items: flex-end;
  padding: 2rem;
}

.preview-banner-content {
  width: 100%;
}

.preview-category {
  font-size: 0.9rem;
  color: #05c2ed;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.preview-title {
  font-size: 2rem;
  color: #ffffff;
  margin-bottom: 0.5rem;
}

.preview-date {
  font-size: 0.9rem;
  color: #cccccc;
}

.preview-content {
  padding: 2rem;
  color: #ffffff;
}

.preview-body {
  line-height: 1.6;
}

/* Tilføj responsive styles */
@media (max-width: 768px) {
  .col-md-6 {
    margin-bottom: 2rem;
  }
  
  .border-end {
    border-right: none !important;
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 2rem;
  }
}

.quill-editor {
  height: 300px;
  width: 100%;
  margin-bottom: 1rem;
}

/* Styling for Quill toolbar */
.ql-toolbar.ql-snow {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px
}

/* Styling for Quill editor container */
.ql-container.ql-snow {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: calc(300px - 42px); /* 42px er standard højden på Quill toolbar */
}

/* Tilføj nye styles i style section */
.ql-editor .ql-faktaboks {
  background: #f8f9fa;
  border-left: 4px solid #1568db;
  padding: 1rem;
  margin: 1.5rem 0;
}

.ql-editor .ql-quote {
  font-style: italic;
  border-left: 3px solid #05c2ed;
  padding-left: 1rem;
  margin: 1.5rem 0;
}

.ql-editor h2 {
  font-size: 1.75rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #32325d;
}

.ql-editor h3 {
  font-size: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
  color: #525f7f;
}

.ql-editor ul {
  padding-left: 1.5rem;
  margin: 1rem 0;
}

.ql-editor li {
  margin-bottom: 0.5rem;
}

/* Tilføj styles til rettelser dialog */
.swal2-html-container ul {
  max-height: 300px;
  overflow-y: auto;
}

.swal2-html-container li {
  padding: 4px 0;
  border-bottom: 1px solid #eee;
}

.swal2-html-container li:last-child {
  border-bottom: none;
}

.table img.avatar-sm {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.badge {
  font-size: 0.75rem;
  padding: 0.35em 0.65em;
}

.table td {
  white-space: nowrap;
  vertical-align: middle;
}

.blog-admin {
  padding: 20px;
  color: white;
  width: 100%;
  height: 100%;
}

.blog-section {
  margin-bottom: 2rem;
}

.blog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.blog-header h3 {
  color: white;
  margin: 0;
}

.blog-content {
  padding: 2rem;
}

.form-control, .input-group-text, .form-select {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
}

.form-control:focus, .form-select:focus {
  background: rgba(255, 255, 255, 0.08);
  border-color: rgba(255, 255, 255, 0.2);
  color: white;
  box-shadow: none;
}

.form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.form-label {
  color: rgba(255, 255, 255, 0.8);
}

.input-group-text {
  color: rgba(255, 255, 255, 0.7);
}

.form-check-label {
  color: rgba(255, 255, 255, 0.8);
}

.form-check-input {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
}

.form-check-input:checked {
  background: linear-gradient(122deg, #05c2ed, #1568db);
  border-color: #1568db;
}

/* Quill editor styling */
.ql-toolbar.ql-snow {
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.1);
}

.ql-container.ql-snow {
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.1);
}

.ql-snow .ql-stroke {
  stroke: rgba(255, 255, 255, 0.7);
}

.ql-snow .ql-fill {
  fill: rgba(255, 255, 255, 0.7);
}

.ql-editor {
  color: white;
}

.ql-editor.ql-blank::before {
  color: rgba(255, 255, 255, 0.5);
}

.table {
  color: white;
}

.table thead th {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.table tbody td {
  color: white;
  border-color: rgba(255, 255, 255, 0.1);
}

/* Image upload styling */
.image-upload-container {
  width: 100%;
  margin-top: 0.5rem;
}

.image-upload-area {
  position: relative;
  width: 100%;
  min-height: 200px;
  border: 2px dashed rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.02);
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;
}

.image-upload-area:hover {
  border-color: rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.04);
}

.image-upload-area.has-image {
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.3);
}

.file-input {
  display: none;
}

.upload-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  padding: 20px;
}

.upload-placeholder i {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.4);
}

.upload-placeholder p {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.upload-info {
  display: block;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.5);
}

.upload-info i {
  font-size: 0.8rem;
  margin-right: 0.5rem;
}

.image-preview {
  width: 100%;
  height: 100%;
  position: relative;
}

.image-preview img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.image-preview-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-preview:hover .image-preview-overlay {
  opacity: 1;
}

.image-preview-overlay .btn {
  padding: 0.5rem 1rem;
  border-radius: 20px;
}
</style>