<script setup>
import { ref, computed } from 'vue';
import ArgonButton from "@/components/ArgonButton.vue";
import { getFirestore, collection, getDocs, doc, updateDoc, query, where, deleteDoc } from 'firebase/firestore';
import { useUserContext } from '@/composables/useUserContext';

// Konstanter for validering
const MIN_INTERVAL = 0;
const MAX_INTERVAL = 60;
const DEFAULT_INTERVAL = 22;

const props = defineProps({
  defaultInterval: {
    type: Number,
    default: DEFAULT_INTERVAL
  },
  isGenerating: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['update-interval', 'generate-placeholders']);
const { contextUserId } = useUserContext();
const db = getFirestore();
const dageInterval = ref(props.defaultInterval);
const isSaving = ref(false);
const isDeleting = ref(false);

const erGyldigtInterval = computed(() => {
  const værdi = parseInt(dageInterval.value);
  // Tillad tom værdi under redigering
  if (dageInterval.value === '') return false;
  return !isNaN(værdi) && værdi >= MIN_INTERVAL && værdi <= MAX_INTERVAL;
});

const validerInterval = () => {
  // Tillad tom værdi eller delvis indtastning
  if (dageInterval.value === '' || dageInterval.value === '-') return;
  
  let værdi = parseInt(dageInterval.value);
  if (isNaN(værdi)) return;
  
  // Kun begræns værdierne når de er udenfor intervallet
  if (værdi < MIN_INTERVAL) dageInterval.value = MIN_INTERVAL;
  if (værdi > MAX_INTERVAL) dageInterval.value = MAX_INTERVAL;
};

const opdaterInterval = () => {
  if (erGyldigtInterval.value) {
    emit('update-interval', parseInt(dageInterval.value));
  }
};

const genererPlaceholders = () => {
  emit('generate-placeholders');
};

// Tilføj blur handler for at sikre gyldig værdi når input mister fokus
const onBlur = () => {
  if (dageInterval.value === '' || !erGyldigtInterval.value) {
    dageInterval.value = MIN_INTERVAL;
  }
};

const gemSomAutoPostInterval = async () => {
  if (!erGyldigtInterval.value) return;
  
  try {
    isSaving.value = true;
    const userId = contextUserId.value;
    
    if (!userId) {
      throw new Error('Ingen bruger kontekst fundet');
    }

    // Gem intervallet i brugerens indstillinger
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      'settings.daysForSaleInterval': parseInt(dageInterval.value),
      'settings.autoActivate': false
    });

    // Find alle daysForSale autoposts for brugeren
    const autoPostsRef = collection(db, 'users', userId, 'autoPosts');
    const q = query(autoPostsRef, where('status', '==', 'Dage til salg'));
    const querySnapshot = await getDocs(q);

    // Opdater hver autopost med det nye interval og deaktiver autoActivate
    const updatePromises = querySnapshot.docs.map(autoPost => {
      return updateDoc(doc(autoPostsRef, autoPost.id), {
        'settings.useAutoInterval': false,
        'settings.manualInterval': parseInt(dageInterval.value),
        'settings.autoActivate': false,
        lastUpdated: new Date()
      });
    });

    await Promise.all(updatePromises);

    // Vis success besked
    alert('Intervallet er blevet gemt og autoaktivering er blevet deaktiveret');
  } catch (error) {
    console.error('Fejl ved gemning af interval:', error);
    alert('Der skete en fejl ved gemning af intervallet');
  } finally {
    isSaving.value = false;
  }
};

const sletPlaceholders = async () => {
  if (!confirm('Er du sikker på at du vil slette alle placeholders? Dette kan ikke fortrydes.')) {
    return;
  }
  
  try {
    isDeleting.value = true;
    const userId = contextUserId.value;
    
    if (!userId) {
      throw new Error('Ingen bruger kontekst fundet');
    }

    // Hent alle placeholders fra user.posts collection
    const postsRef = collection(db, 'users', userId, 'posts');
    const querySnapshot = await getDocs(postsRef);
    
    // Slet hver placeholder
    const deletePromises = querySnapshot.docs.map(doc => {
      return deleteDoc(doc.ref);
    });

    await Promise.all(deletePromises);
    alert('Alle placeholders er blevet slettet');
    
  } catch (error) {
    console.error('Fejl ved sletning af placeholders:', error);
    alert('Der skete en fejl ved sletning af placeholders');
  } finally {
    isDeleting.value = false;
  }
};
</script>

<template>
  <div class="interval-kontrol card">
    <div class="card-body">
      <div class="d-flex align-items-center justify-content-between flex-wrap">
        <div class="interval-info mb-3 mb-md-0">
          <h6 class="mb-0">Rotationsinterval for dage til salg</h6>
          <small class="text-muted">Se og justere hvornår dine biler publiceres, baseret på dit nuværende lager. <br>Tryk på "Hent" for at generere et overblik.</small>
        </div>
        <div class="interval-actions d-flex align-items-center flex-wrap gap-2">
          <div class="interval-input d-flex align-items-center">
            <div class="input-group">
              <input 
                type="number" 
                class="form-control text-center"
                :min="MIN_INTERVAL"
                :max="MAX_INTERVAL"
                v-model="dageInterval"
                @input="validerInterval"
                @blur="onBlur"
                :placeholder="`${MIN_INTERVAL}-${MAX_INTERVAL}`"
              >
            </div>
            <div class="button-group d-flex gap-2">
              <argon-button
                color="success"
                size="md"
                variant="gradient"
                @click="opdaterInterval"
                :disabled="!erGyldigtInterval"
              >
                Opdater
              </argon-button>
              <argon-button
                color="warning"
                size="md"
                variant="gradient"
                class="save-interval-btn"
                @click="gemSomAutoPostInterval"
                :disabled="!erGyldigtInterval || isSaving"
              >
                <i class="fas fa-save me-2"></i>
                {{ isSaving ? 'Gemmer...' : 'Gem Interval' }}
              </argon-button>
            </div>
          </div>
          <div class="action-buttons d-flex gap-2">
            <argon-button
              color="info"
              size="md"
              variant="gradient"
              class="generate-btn"
              @click="genererPlaceholders"
              :disabled="isGenerating"
            >
              <i class="fas fa-sync-alt me-2" :class="{ 'fa-spin': isGenerating }"></i>
              {{ isGenerating ? 'Henter...' : 'Hent' }}
            </argon-button>
            <argon-button
              color="danger"
              size="md"
              variant="gradient"
              class="delete-btn"
              @click="sletPlaceholders"
              :disabled="isDeleting"
            >
              <i class="fas fa-trash me-2"></i>
              {{ isDeleting ? 'Sletter...' : 'Slet' }}
            </argon-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.interval-kontrol .interval-input {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.button-group {
  display: flex;
  gap: 0.5rem;
  flex-wrap: nowrap;
}

.interval-kontrol .input-group {
  width: 100px;
}

.interval-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.save-interval-btn {
  background: linear-gradient(310deg, #fb6340 0%, #fbb140 100%);
  border: none;
  transition: all 0.3s ease;
  font-weight: 500;
  padding: 0.55rem 1.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.save-interval-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.save-interval-btn:disabled {
  opacity: 0.65;
  cursor: not-allowed;
  transform: none;
}

.generate-btn {
  background: linear-gradient(310deg, #11cdef 0%, #1171ef 100%);
  border: none;
  transition: all 0.3s ease;
  font-weight: 500;
  padding: 0.55rem 1.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.interval-kontrol .form-control {
  height: 38px;
  border-radius: 0.5rem;
  border: 1px solid #e9ecef;
  text-align: center;
  font-weight: 600;
  color: #5e72e4;
  transition: all 0.2s ease;
}

.interval-kontrol .form-control:focus {
  border-color: #5e72e4;
  box-shadow: 0 3px 9px rgba(50, 50, 93, 0.08), 0 1px 3px rgba(0, 0, 0, 0.04);
}

.interval-kontrol .input-group {
  width: 100px;
  margin-right: 0.5rem;
}

/* Opdateret styling for Opdater knap */
:deep(.btn.bg-gradient-success) {
  background: linear-gradient(310deg, #2dce89 0%, #2dcecc 100%);
  border: none;
  padding: 0.55rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

:deep(.btn.bg-gradient-success:hover) {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

:deep(.btn.bg-gradient-success:disabled) {
  opacity: 0.65;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.action-buttons {
  display: flex;
  gap: 0.5rem;
}

.delete-btn {
  background: linear-gradient(310deg, #f5365c 0%, #f56036 100%);
  border: none;
  transition: all 0.3s ease;
  font-weight: 500;
  padding: 0.55rem 1.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.delete-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.delete-btn:disabled {
  opacity: 0.65;
  cursor: not-allowed;
  transform: none;
}
</style> 