<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';

const props = defineProps({
  squareSize: {
    type: Number,
    default: 4
  },
  gridGap: {
    type: Number,
    default: 6
  },
  flickerChance: {
    type: Number,
    default: 0.3
  },
  color: {
    type: String,
    default: 'rgb(0, 0, 0)'
  },
  width: {
    type: Number,
    default: undefined
  },
  height: {
    type: Number,
    default: undefined
  },
  maxOpacity: {
    type: Number,
    default: 0.3
  }
});

const canvasRef = ref(null);
const containerRef = ref(null);
const isInView = ref(false);
const canvasSize = ref({ width: 0, height: 0 });

const memoizedColor = computed(() => {
  const toRGBA = (color) => {
    if (typeof window === 'undefined') {
      return `rgba(0, 0, 0,`;
    }
    const canvas = document.createElement('canvas');
    canvas.width = canvas.height = 1;
    const ctx = canvas.getContext('2d');
    if (!ctx) return 'rgba(255, 0, 0,';
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, 1, 1);
    const [r, g, b] = Array.from(ctx.getImageData(0, 0, 1, 1).data);
    return `rgba(${r}, ${g}, ${b},`;
  };
  return toRGBA(props.color);
});

const setupCanvas = (canvas, width, height) => {
  const dpr = window.devicePixelRatio || 1;
  canvas.width = width * dpr;
  canvas.height = height * dpr;
  canvas.style.width = `${width}px`;
  canvas.style.height = `${height}px`;
  const cols = Math.floor(width / (props.squareSize + props.gridGap));
  const rows = Math.floor(height / (props.squareSize + props.gridGap));

  const squares = new Float32Array(cols * rows);
  for (let i = 0; i < squares.length; i++) {
    squares[i] = Math.random() * props.maxOpacity;
  }

  return { cols, rows, squares, dpr };
};

const updateSquares = (squares, deltaTime) => {
  for (let i = 0; i < squares.length; i++) {
    if (Math.random() < props.flickerChance * deltaTime) {
      squares[i] = Math.random() * props.maxOpacity;
    }
  }
};

const drawGrid = (ctx, width, height, cols, rows, squares, dpr) => {
  ctx.clearRect(0, 0, width, height);
  ctx.fillStyle = 'transparent';
  ctx.fillRect(0, 0, width, height);

  for (let i = 0; i < cols; i++) {
    for (let j = 0; j < rows; j++) {
      const opacity = squares[i * rows + j];
      ctx.fillStyle = `${memoizedColor.value}${opacity})`;
      ctx.fillRect(
        i * (props.squareSize + props.gridGap) * dpr,
        j * (props.squareSize + props.gridGap) * dpr,
        props.squareSize * dpr,
        props.squareSize * dpr
      );
    }
  }
};

let animationFrameId;
let resizeObserver;
let intersectionObserver;

onMounted(() => {
  const canvas = canvasRef.value;
  const container = containerRef.value;
  if (!canvas || !container) return;

  const ctx = canvas.getContext('2d');
  if (!ctx) return;

  let gridParams;

  const updateCanvasSize = () => {
    const newWidth = props.width || container.clientWidth;
    const newHeight = props.height || container.clientHeight;
    canvasSize.value = { width: newWidth, height: newHeight };
    gridParams = setupCanvas(canvas, newWidth, newHeight);
  };

  updateCanvasSize();

  let lastTime = 0;
  const animate = (time) => {
    if (!isInView.value) return;

    const deltaTime = (time - lastTime) / 1000;
    lastTime = time;

    updateSquares(gridParams.squares, deltaTime);
    drawGrid(
      ctx,
      canvas.width,
      canvas.height,
      gridParams.cols,
      gridParams.rows,
      gridParams.squares,
      gridParams.dpr
    );
    animationFrameId = requestAnimationFrame(animate);
  };

  resizeObserver = new ResizeObserver(() => {
    updateCanvasSize();
  });

  resizeObserver.observe(container);

  intersectionObserver = new IntersectionObserver(
    ([entry]) => {
      isInView.value = entry.isIntersecting;
      if (entry.isIntersecting) {
        animationFrameId = requestAnimationFrame(animate);
      }
    },
    { threshold: 0 }
  );

  intersectionObserver.observe(canvas);
});

onUnmounted(() => {
  if (animationFrameId) {
    cancelAnimationFrame(animationFrameId);
  }
  if (resizeObserver) {
    resizeObserver.disconnect();
  }
  if (intersectionObserver) {
    intersectionObserver.disconnect();
  }
});
</script>

<template>
  <div ref="containerRef" class="w-full h-full">
    <canvas
      ref="canvasRef"
      class="pointer-events-none"
      :style="{
        width: `${canvasSize.width}px`,
        height: `${canvasSize.height}px`
      }"
    />
  </div>
</template>

<style scoped>
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.pointer-events-none {
  pointer-events: none;
}
</style> 