export function setupConsoleOverride() {
  if (process.env.NODE_ENV === 'production') {
    const originalConsole = { ...console };
    
    // Liste over mønstre vi vil filtrere fra
    const filterPatterns = [
      'background.js',
      'The item cache has not been initialized',
      'Could not collect all frames',
      'Unable to inject for a URL',
      'PortManager:'
    ];

    // Hjælpefunktion til at checke om en besked skal filtreres
    const shouldFilter = (args) => {
      if (!args || !args.length) return false;
      const message = args[0]?.toString() || '';
      return filterPatterns.some(pattern => message.includes(pattern));
    };

    window.console = {
      ...console,
      log: (...args) => {
        if (!shouldFilter(args)) originalConsole.log(...args);
      },
      warn: (...args) => {
        if (!shouldFilter(args)) originalConsole.warn(...args);
      },
      error: (...args) => {
        if (!shouldFilter(args)) originalConsole.error(...args);
      }
    };
  }
} 