<template>
  <div class="chart-container">
    <div class="chart-header">
      <h6>{{ title }}</h6>
    </div>
    <div class="chart-canvas">
      <canvas :id="chartId" :height="height"></canvas>
    </div>
  </div>
</template>

<script setup>
import { onMounted, watch } from 'vue';
import Chart from 'chart.js/auto';

const props = defineProps({
  id: {
    type: String,
    default: 'thin-bar-chart'
  },
  title: {
    type: String,
    default: 'Omsætning og udgifter'
  },
  height: {
    type: [String, Number],
    default: 300
  },
  chart: {
    type: Object,
    required: true
  }
});

let chartInstance = null;

const chartId = props.id;

const initChart = () => {
  const ctx = document.getElementById(chartId);
  
  if (chartInstance) {
    chartInstance.destroy();
  }

  chartInstance = new Chart(ctx, {
    type: 'bar',
    data: {
      labels: props.chart.labels,
      datasets: [
        {
          label: 'Omsætning',
          data: props.chart.datasets.revenue,
          backgroundColor: 'rgba(46, 204, 113, 0.8)',
          borderColor: 'rgba(46, 204, 113, 1)',
          borderWidth: 1
        },
        {
          label: 'Udgifter',
          data: props.chart.datasets.expenses,
          backgroundColor: 'rgba(231, 76, 60, 0.8)',
          borderColor: 'rgba(231, 76, 60, 1)',
          borderWidth: 1
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            color: 'rgba(255, 255, 255, 0.1)'
          },
          ticks: {
            color: 'rgba(255, 255, 255, 0.7)'
          }
        },
        x: {
          grid: {
            display: false
          },
          ticks: {
            color: 'rgba(255, 255, 255, 0.7)'
          }
        }
      },
      plugins: {
        legend: {
          display: true,
          position: 'top',
          labels: {
            color: 'rgba(255, 255, 255, 0.7)'
          }
        }
      }
    }
  });
};

watch(() => props.chart, () => {
  initChart();
}, { deep: true });

onMounted(() => {
  initChart();
});
</script>

<style scoped>
.chart-container {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 15px;
  padding: 20px;
  margin: 30px 0;
}

.chart-header {
  margin-bottom: 20px;
}

.chart-header h6 {
  color: white;
  font-size: 1rem;
  margin: 0;
}

.chart-canvas {
  position: relative;
  height: v-bind(height + 'px');
}
</style> 