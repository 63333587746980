const config = {
  t2mio: {
    postUrl: "https://t2mio.com/api/v1/urls",
    apiKey: "nu0OrnJXnyXVsdpM0NgcERczD7rqeU",
    apiSecret: "tF2bDo3mf8mbmgbJepXV",
  },
  dub: {
    apiKey: "sk_toft_d4f39_e0c0b8f9f9b8f9b8f9b8f9b8f9b8f9b8"
  },
  meta: {
    userToken: "EAAHnMdwdoQUBO3KuvZALpOfPVd1QE3tycJVT2SALWuI91AJaLuC1Ak7anzyLDjd1pBnVAvd5ZCSK03SZCzEzRlxdBMKxW07owqTHd67ak4f3pFHaaM30s6stmtioFtwJjs8WWDmzZB1GZAk6mVEZCWU74sQ2zDoDBiHmeOjXChLMCHGpiNRzLMJgZDZD",
    adAccountId: "414345048364429",
    appId: "535676309053701",
    version: "v21.0"
  },
  firebase: {
    apiKey: "AIzaSyAx_5ZnR__8sizS5_1k3uP-gilviDiGO6Q",
    authDomain: "toft-d4f39.firebaseapp.com",
    projectId: "toft-d4f39",
  },
  googleSheets: {
    clientId: "277892110082-hcbr0ano2sf6o527hj5e4voase2162pf.apps.googleusercontent.com",
    clientSecret: "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",
    redirectUri: "http://localhost",
    spreadsheetId: "1ejZZDqjMmeIUObT4-swhhUR8yMJ3kegZGN7T9KljqsA",
    serviceAccount: {
      type: "service_account",
      project_id: "toft-d4f39",
      private_key_id: "0503dffecbbecc2a0363180064caa81723b49cdc",
      private_key: "-----BEGIN PRIVATE KEY-----\nMIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDNHY0jqabR4ZYV\n7XK96lrOe4Z0oo1WX2bNlRvNBQUfbrh6Ah3qd6DAYyu1/d4S8iw+6adRxKDVyDM+\nK2lJqPrQkK8fFDib2HxN2Mqo22XSbIavicFy9+LJRCAykqYRHQzdh+jGli0uxysQ\n5m6qaAiduOPbcp8ffI9ZEzBJzyjEXndMLMeQnDoFeU6QLWLkKbnQkDlft2ApQOa8\ntZmtrHZWXy+Ey38tX5BpBaQhm4YopGw8bALgLQulSIVXihKpzNo5/qmJXCT76veI\n+XCfv7J7kJLsq/iWcagKtehoxWPESk4/KjZPEoo3d9cRV354MZFlmc0wDHaOwgRf\nqzektwWZAgMBAAECggEABLDttNB5HiD7jKQV/Rm2arVIx5Ndqelr6Z0EyiE4oyXG\nPpUcj8udX7UM74A/Q13qTOSNZPl7J7SI1n3J7ks49Erw1GRDIK5YdnPUO70r1J6S\njIJ5LArXEx0Vou53cZoBXLIybNNLtH+N4LiydH+FXe0X+gGCuebAhQwjLTb5ktcf\nPowiokgOfnCaU1Bb/byRip6hzaNcY4hmWo0s/V1YdSkEd+IU4p2ngMjK+GgMZFwE\n4FIy7i1R5cm+8O6p5Xe0ox6XTriaGrRZEvgsRcrpvo1Wu6NAe2ZAz1CkWEgVoXPU\nY2UY2G+XwRJ/ZbXqGgSZeVoN4AGoBas47wiTRfI4QQKBgQD3N+5O+3O9WwFzZ+f6\nz/GKlmKzP2fYIWpGTAHvJrIXcB//9jWQePwqCQLZ2+DcPYn4sbbaUrRkmzd1W2kf\nAnPwZN5/sX2pwmz07HqsKPCpGErLOSppFlow7TRPyl33s7sI7CT/QiX2+Mo9wszL\nmmat163ZbeQdWAm0LSNZcBrtQQKBgQDUZsIqX3QPL+KUL1lQ637+1r7iKOhjWWha\n+lCOMJWBCTim+xiLavYMsmlaATy8rcgm7MuIQvZH2HKW/TMbTGG2eXoDkxjQ9DNN\n0ESSCvZ74+jXJAqEesc4Vid++Ev7Wu0bZgYXjfhZUTWDlI6dyOZd6nHm5VCLXzMD\nBLwfGpYKWQKBgQCQ4PdI1DXc56NvBZq+svSD2mQXVfY+hD6q2cpcY9oEwFIQiDKV\n60EkRUmlcSVTDMF5vHRDeCp9tzUGEr8FTEOzwvld7h+NyAJgeuHdWNTI1BKpDGRd\nV5sdhzWb+SWej3bx4KpB6XskRGMJVMr+GwtHn9kjTjJcPAbbz4Qi2v78wQKBgQCI\nW/gnb+R4o4tZCxTSKH39aSxe0Vhv/8RJNA5nWrWAg8UFHorhZqfT+u47xDOUBhVL\nOBZqwmKOOV1HpgIbcmwA0/gLN3JlI4GUYTLEd5lKbETdadv+tTwU5OqyTwGouvUh\n89vRX2mjHcm3UbZFgeazQIIjc/DDqTh8pZuIdG8C8QKBgQC0pmscwA9EKwlcLr1w\nDEGDaMfctwD2i0jZLnvXWS3fCBWBadJbgUaMdDG3eFR4K51k/BnvueX3hzFBQN/Y\nnQE8VWey8DlrI/MWOlJHb154LyvZ+b9o9ZKWfsljU1nVEW5AQ55re2jWwwsFy9u5\naq2ODIVKlb2d+RW6vweDjbRYrw==\n-----END PRIVATE KEY-----\n",
      client_email: "firebase-adminsdk-kohtm@toft-d4f39.iam.gserviceaccount.com",
      client_id: "116421474228526965696",
    }
  },
  openAi: {
    apiKey: "sk-6yAWLCJtuSu1c_tECu1CEy4sLUpvKTb1nHjlxGpdjpT3BlbkFJSrjbouvfDe04gWRWE8PRXhf1Bu1riKCEpik15G548A", // OpenAI API-nøgle
    organizationId: "org-fD6XEuHbsezL69s1b5qhf0Yj", // OpenAI Organization ID
    assistantId: "asst_JJinjm2IHhheHeQvq9jfUfm5" // Din OpenAI assistant ID
  },
  placidApi: {
    bearerToken: "placid-uojznubulbtyxc9i-bpmgxi2pkaoqrn9k",
    imageUrl: "https://api.placid.app/api/rest/images",
    pdfUrl: "https://api.placid.app/api/rest/pdfs",
  },
  webhookUrl: "https://hook.eu1.make.com/bl3gs3q8wafn7t7pg7n3ga9qs6817dby",
  googleVision: {
    credentials: {
      type: "service_account",
      project_id: "toft-d4f39",
      private_key_id: "e8da375c5e384dc6bab4febb24c60f9de89c9209",
      private_key: "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDQYcdLJcMYEAzD\nRqntvRqK66Q5TW/8yR9y089AKRhApYLaS6saQxzvj1s3rzzWvC9oUIXc6Gspcm0q\nz/clMGLVCNFhNbWq9iTEtwicroGgT4WtJUD1bKcwV+8ZFFB8OgdGD/EIPpJZy180\nQkTQS/initwGnsLPSUKBDCFSUndauWlZIhkLWKvFWiiguLnA3K0W49JbceK84ww9\n4pbLpKmIUzSnnotY6GBt3vLaDv62+qcPmfTEccGSuDQRqi4jaOfZ/yyqw3dYf28F\nNT9YVTs5ZP7ySwnyR7pMkYhyJ+RBXYXLxNIE79QCeSTw3VOk+jQIitp6pTgbCrdg\nehi+q5aLAgMBAAECggEAPY1sX3u3jZ+MDZCNeE6gDQH+//gqSLqmAfaj5PlekxXw\nuYeZcof5f5/SW3f1Wh67SC5k5DR5FwSYT4mfoMoSn4fbxUGmuGm9waepMRLw8kgb\nHeJ/th8SoZqP41IXViKT7HOgCIXnGAQVSqv6hKa0O9UuGfyCYdVrgg/xtc3rOI48\nD6mNZZYkk+9HGsKgyQWwxhciKCafB3X/XtMe2xABqztArzqIeRNuBS173tX3Zvc/\n6lh/878pOUwpqiqsIHAm5+1IDU8qIhcl1Hp243o3q69ZJyOi0w2Mt+GcYpOJQY/U\naMRtWukIxMSokK1P47aknmu+RgLYTEmma8u2w8XcTQKBgQD9iHVgboa5/wIbf95O\nqbJimGcOuY3r+GzQ2E+uT2HPTANy3So9l8Gk0hStTwUQxyvKzilqYRvRefSfJ7L5\nLono3eKbjteqQOt9IZw8krnW2i/pkiUgjnE1R77/PkDsLXboCfCIPrwKXBfZybYS\nWhBTLGSOeJtZTiVd6FojY6CZpQKBgQDSaNmrVx1wY3ZQc7nYuuTXIs/jVWRBe529\nzymOMeqtY6k9SqPPEnl+uRuovW5+/lbivaYRbF93XgU7yrt53b60ZkQe3vRv8b8Y\nrIIwC6yAj30QlPzv5M7s45KPfrZdqGnn+Enc1D9xQyCGGGCAZ5VJHKA26y3UT9Xt\nZu5/zmOYbwKBgEubXiWb0GG3QpGg2FLtGF1+xX0GqOQt+YVufJSm56xCJuUiP26O\nyxiz7aahYglW3CBmysMGop0dmW29EQpuboIbaXCqcHY9VfzDASyvNOx1tMQa6SeE\nUw5ojvTfQ80q4cLg8/ItgzYzsIkqNjnS0ft7yaETJoDDZTFUEU1scIBhAoGAbiNG\nNUMPfOIDb490K7eAzABD84qiRQixBEcpSG5mmkY9s4CbiGvyPtwkV0p5SW5XCq8c\n+6fPvQrxEGBJjDv/dCzH4aTxLJvNXNwH9aBWtqDlZUWntOb/Tc69y/EXAqWCclNs\nTnSnlOD/aFHgSbZu1Wtvje/C+IylvXQQycO+LG8CgYEA1aO4ulaJVZUNSGrTN5j+\no939iAXNthPi3ajbwPEFjmQj0VN8Js3ev37yHNrZPQvbHL1k0gwqz42utzkBcJND\n8bCn+ap9HMClgvywRApq6cEtDpFh96kFkQuXEBW99b7mLe0vZYoz5V0JSZtIbwSy\nwm+1QXkgK2io6/5l3qHMtmw=\n-----END PRIVATE KEY-----\n",
      client_email: "makemore@toft-d4f39.iam.gserviceaccount.com",
      client_id: "105075760024029258064",
      auth_uri: "https://accounts.google.com/o/oauth2/auth",
      token_uri: "https://oauth2.googleapis.com/token",
      auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
      client_x509_cert_url: "https://www.googleapis.com/robot/v1/metadata/x509/makemore%40toft-d4f39.iam.gserviceaccount.com",
      universe_domain: "googleapis.com"
    }
  },
  robolly: {
    apiKey: "b7PAfIZTWFRI0C7FkKZPYkeM2jpCm1KPCxEFR7aW"
  },
};

module.exports = config;
