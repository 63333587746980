export default {
  common: {
    settings: 'Indstillinger',
    profile: 'Profil',
    save: 'Gem',
    cancel: 'Annuller',
    delete: 'Slet',
    edit: 'Rediger',
    loading: 'Indlæser...',
    error: 'Der opstod en fejl',
    success: 'Handlingen blev gennemført',
    confirm: 'Bekræft',
    language: 'Sprog',
    yes: 'Ja',
    no: 'Nej',
    upload: 'Upload',
    download: 'Download',
    close: 'Luk',
    search_user: 'Søg bruger...',
    vis_alle: 'Vis alle',
    vis_kun_admins: 'Vis kun Admins',
    vis_kun_brugere: 'Vis kun Brugere',
    administrer: 'Administrer',
    opret_faktura: 'Opret faktura',
    oprettet_af: 'Oprettet af {name}',
    brugere_antal: 'Brugere ({count})',
    online: 'Online',
    offline: 'Offline',
    omdirigerer_til_login: 'Omdirigerer til login...',
    opslag: 'Opslag'
  },

  // Auto-genererede oversættelser
  auto: {
    log_ind: 'Log ind',
    markedets_mest_intelligente: 'Markedets mest intelligente',
    markedsføringssystem_til_bilbranchen: 'markedsføringssystem til bilbranchen!',
    en_fremtidssikret_løsning_for_bilbranchen_med_vores_intelligente_markedsføringssystem_kan_du_let_automatisere_dine_salgsemner_til_sociale_medier_nemt_hurtigt_og_helt_uden_at_røre_en_finger: 'En fremtidssikret løsning for bilbranchen. Med vores intelligente markedsføringssystem kan du let automatisere dine salgsemner til sociale medier nemt, hurtigt og helt uden at røre en finger.',
    book_en_demo: 'Book en demo',
    logger_ind: 'Logger ind...',
    automatis_r_din_markedsføring: 'Automatisér din markedsføring',
    slip_for_tidskrævende_manuelt_arbejde_og_lad_vores_system_håndtere_alt_det_tekniske_med_vores_løsning_kan_du_fokusere_på_det_vigtigste_at_drive_din_forretning: 'Slip for tidskrævende manuelt arbejde og lad vores system håndtere alt det tekniske. Med vores løsning kan du fokusere på det vigtigste - at drive din forretning.',
    udforsk_mulighederne_med_auto_family: 'Udforsk mulighederne med Auto Family',
    kalender: 'Kalender',
    preview: 'Preview',
    biler: 'Biler',
    få_et_overblik_over_dine_kampagner_og_planlægning_slet_rediger_eller_ryk_rundt_på_dine_opslg_med_1_klik: 'Få et overblik over dine kampagner og planlægning. Slet rediger eller ryk rundt på dine opslg med 1-klik.',
    se_dit_indhold_før_det_bliver_offentliggjort_få_en_forhåndsvisning_af_dit_indhold_så_du_kan_se_hvordan_det_vil_se_ud_på_dine_sociale_medier: 'Se dit indhold før det bliver offentliggjort. Få en forhåndsvisning af dit indhold, så du kan se, hvordan det vil se ud på dine sociale medier.',
    fuld_kontrol_over_dine_biler_og_lagerlister_i_auto_family_ekskluder_biler_fra_auto_family_så_de_ikke_bliver_offentliggjort: 'Fuld kontrol over dine biler og lagerlister i Auto Family. Ekskluder biler fra Auto Family, så de ikke bliver offentliggjort.',
    det_får_du_blandt_andet: 'Det får du blandt andet',
    med_auto_family: 'Med Auto Family',
    fuldautomatisk: 'Fuldautomatisk',
    publicering: 'Publicering',
    med_auto_family_kan_du_let_automatisere_dine_biler_og_lagerlister_til_sociale_medier_nemt_hurtigt_og_100_automatisk_helt_uden_din_indflydelse: 'Med Auto Family kan du let automatisere dine biler og lagerlister til sociale medier – nemt, hurtigt og 100% automatisk. Helt uden din indflydelse.',
    billede_skabeloner: 'Billede skabeloner',
    grafisk_design: 'Grafisk design',
    få_din_virksomhed_til_at_se_mere_professionel_ud_med_vores_templates_alle_designs_tilpasser_sig_automatisk_din_forretning_og_emner: 'Få din virksomhed til at se mere professionel ud med vores templates. Alle designs tilpasser sig automatisk din forretning og emner.',
    dit_eget_dashboard: 'Dit eget dashboard',
    statistik: 'Statistik',
    få_en_bedre_forståelse_af_hvordan_dit_publikum_interagerer_med_dit_indhold_vi_holder_styr_på_dine_resultater_og_samler_dem_i_et_nemt_og_overskueligt_overblik: 'Få en bedre forståelse af, hvordan dit publikum interagerer med dit indhold. Vi holder styr på dine resultater og samler dem i et nemt og overskueligt overblik.',
    video_skabeloner: 'Video skabeloner',
    videoindhold: 'Videoindhold',
    er_statiske_opslag_ikke_nok_kan_du_vælge_at_gøre_brug_af_vores_build_in_video_templates_vi_laver_dine_videoer_fuldt_automatiserede_og_professionelt: 'Er statiske opslag ikke nok, kan du vælge at gøre brug af vores build-in video templates. Vi laver dine videoer fuldt automatiserede og professionelt.',
    maskingenererede: 'Maskingenererede',
    tekster: 'Tekster',
    i_auto_family_behøver_du_aldrig_skrive_en_tekst_mere_vi_sørger_for_at_alt_indhold_skrives_automatisk_for_dig_ved_hjælp_af_vores_tekstkombinationer_kombineret_med_vores_ai: 'I Auto Family behøver du aldrig skrive en tekst mere. Vi sørger for, at alt indhold skrives automatisk for dig, ved hjælp af vores tekstkombinationer, kombineret med vores ai.',
    multifunktionelle: 'Multifunktionelle',
    vores_multifunktionelle_kalender_samler_alt_i_et_og_giver_dig_1_click_indstillinger_til_at_styre_alt_et_sted_fra_så_du_kan_fokusere_på_at_drive_din_forretning: 'Vores multifunktionelle kalender, samler alt i et og giver dig 1-click indstillinger, til at styre alt et sted fra. så du kan fokusere på at drive din forretning.',
    fuldautomatiske_funktioner_er_inkluderet_til_planlægning_og_publicering_af_billeder_videoer_og_tekster_derudover_tilbyder_vi_ai_assisterede_tekstmoduler_som_kan_hjælpe_dig_med_at_skrive_eller_redigere_indhold_vi_tilbyder_også_semi_automatisk_publicering_af_alle_dine_biler_via_vores_indbyggede_publiceringsværktøj_dette_værktøj_forstår_konteksten_af_både_videoer_og_billeder_og_leverer_en_hjælp_du_aldrig_har_oplevet_før: 'Fuldautomatiske funktioner er inkluderet til planlægning og publicering af billeder, videoer og tekster. Derudover tilbyder vi AI-assisterede tekstmoduler, som kan hjælpe dig med at skrive eller redigere indhold. Vi tilbyder også semi-automatisk publicering af alle dine biler via vores indbyggede publiceringsværktøj. Dette værktøj forstår konteksten af både videoer og billeder og leverer en hjælp, du aldrig har oplevet før.',
    effektivitet_skræddersyede_løsninger: 'Effektivitet & skræddersyede løsninger',
    auto_family_er_det_eneste_system_der_automatiserer_gennem_programmer_og_algoritmer_for_at_spare_tid_uden_at_gå_ned_på_kvaliteten: 'Auto Family er det eneste system, der automatiserer gennem programmer og algoritmer for at spare tid uden at gå ned på kvaliteten.',
    book_en_30_minutters_demo: 'Book en 30-minutters demo',
    oplev_hvordan_vores_system_kan_hjælpe_din_forretning_og_markedsføring_og_spare_dig_for_værdifuld_tid: 'Oplev hvordan vores system kan hjælpe din forretning og markedsføring, og spare dig for værdifuld tid!',
    prøv_en_live_preview_for_dig_selv: 'Prøv en live preview for dig selv!',
    vælg_en_bil_vælg_et_ønsket_design_og_tryk_på_se_preview: '1. Vælg en bil, 2. vælg et ønsket design og 3. tryk på se preview.',
    vælg_en_bil_fra_listen_til_venstre_for_at_se_preview: 'Vælg en bil fra listen til venstre for at se preview',
    se_preview: 'Se preview',
    en_af_vores_store_fordele_er_at_vi_kan_implementere_skræddersyede_designs_fra_dag_til_dag_præcis_efter_dine_ønsker_så_du_får_en_unik_løsning_der_kun_er_tilpasset_dig: 'En af vores store fordele er, at vi kan implementere skræddersyede designs fra dag til dag, præcis efter dine ønsker, så du får en unik løsning, der kun er tilpasset dig',
    hjem: 'Hjem',
    admin_panel: 'Admin Panel',
    indstillinger: 'Indstillinger',
    opret_designs: 'Opret Designs',
    blog: 'Blog',
    økonomi: 'Økonomi',
    log_ud: 'Log ud',
    vis_alle_brugere: 'Vis alle brugere',
    tilføj_virksomhed: 'Tilføj virksomhed',
    on_off: 'On/Off',
    bruger: 'Bruger',
    actions: 'Actions',
    quick_setup: 'Quick Setup',
    tilknyt_meta: 'Tilknyt Meta',
    prøveperiode_14_dage: 'Prøveperiode (14 dage)',
    fakturering: 'Fakturering',
    sikkerhedshandlinger: 'Sikkerhedshandlinger',
    searchquery_ingen_brugere_matcher_søgningen_ingen_brugere_fundet: '{{ searchQuery ? "Ingen brugere matcher søgningen" : "Ingen brugere fundet" }}',
    opsæt_autoposts: 'Opsæt AutoPosts',
    autoposts_opsat: 'AutoPosts opsat',
    tilknyt_facebook: 'Tilknyt Facebook',
    mangler_dealer_id: 'Mangler dealer ID',
    tilknytter: 'Tilknytter...',
    tilknyttet: 'Tilknyttet',
    manager: 'Manager',
    sender: 'Sender...',
    nulstil_kode: 'Nulstil kode',
    user_disabled_aktiver_deaktiver: '{{ user.disabled ? "Aktiver" : "Deaktiver" }}',
    sletter: 'Sletter...',
    slet: 'Slet',
    viser_currentpage_1_itemsperpage_1_math_min_currentpage_itemsperpage_filteredusers_length_af_filteredusers_length_brugere: 'Viser {{ (currentPage - 1) * itemsPerPage + 1 }} - {{ Math.min(currentPage * itemsPerPage, filteredUsers.length) }} af {{ filteredUsers.length }} brugere',
    forrige: 'Forrige',
    næste: 'Næste',
    feature: '{{ feature }}',
    det_med_småt: '"Det med småt"',
    term: '{{ term }}',
    et_system_der_gør_det_nemt_for_dig: 'Et system der gør det nemt for dig',
    autopilot: 'Autopilot',
    printmateriale: 'Printmateriale',
    opsæt_en_autopilot_på_din_lagerliste_med_4_klik_når_den_er_opsat_holder_auto_family_øje_med_alle_ændringer_og_opretter_dine_opslag_automatisk_alt_fra_nyhed_prisændringer_til_alle_dine_biler_i_lagerlisten: 'Opsæt en autopilot på din lagerliste med 4-klik. Når den er opsat holder Auto family øje med alle ændringer og opretter dine opslag automatisk. alt fra Nyhed, prisændringer, til alle dine biler i lagerlisten.',
    ønsker_du_kontrol_over_dine_opslag_auto_family_giver_dig_muligheden_for_vælge_specifikke_biler_fra_din_lagerliste_vælge_unikke_designs_og_vi_laver_dine_opslag_for_dig: 'Ønsker du kontrol over dine opslag? Auto Family giver dig muligheden for vælge specifikke biler fra din lagerliste, vælge unikke designs og vi laver dine opslag for dig.',
    med_vores_live_editor_kan_du_redigere_dine_printmateriale_live_og_se_ændringerne_i_real_time_før_du_printer_dem_ud: 'Med vores live editor, kan du redigere dine printmateriale live, og se ændringerne i real-time. før du printer dem ud.',
    kontakt: 'Kontakt:',
    tlf: 'Tlf:',
    hurtig_link: 'Hurtig link',
    andet: 'Andet',
    privatlivspolitik: 'Privatlivspolitik'
  }
}; 
