<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12 col-md-6 col-xl-3">
        <div class="card">
          <div class="card-header p-3 pb-0">
            <h4 style="font-weight: bolder !important;">Gruppenavne</h4>
          </div>
          <div class="p-3">
            <div v-for="designType in availableDesignTypes" :key="designType" class="row mb-2 align-items-center">
              <div class="col">
                <div class="designGroup" 
                     @click="selectDesignType(designType)" 
                     :class="{ active: designType === activeDesignType }" 
                     style="cursor: pointer;">
                  {{ designType }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="col">
        <div class="card">
          <div v-if="!hideHeader" class="card-header">
            <div class="row align-items-center">
              <div class="col-2">
                <h4 style="font-weight: bolder !important;">
                  {{ Array.isArray(selectedStatus) ? selectedStatus[0] : selectedStatus }}
                </h4>
              </div>
              <div class="col-6">
                <!-- Format vælger fjernet -->
              </div>
              <div class="col-4 text-end">
                <button class="btn mb-0 bg-gradient-dark btn-md me-2" @click="$emit('back')">Gå tilbage</button>
                <button class="btn mb-0 bg-gradient-success btn-md" @click="$emit('next')" :disabled="!selectedDesign">Næste</button>
              </div>
            </div>
          </div>
        </div>
        
        <div class="designs-container" :class="{ 'no-padding': noPadding }">
          <div v-for="design in filteredDesigns" :key="design.uuid" 
               class="design-card" 
               @click="selectDesign(design)" 
               :class="{ 'selected': isSelected(design) }">
            <div class="image-container">
              <template v-if="isVideo(design.file)">
                <video 
                  controls 
                  class="design-video" 
                  ref="videoPlayer" 
                  @loadedmetadata="setVideoVolume"
                  :poster="design.thumbnail || ''"
                >
                  <source :src="design.file" type="video/mp4">
                  Din browser understøtter ikke videoafspilning.
                </video>
              </template>
              <template v-else>
                <img :src="design.file" alt="" class="design-image">
              </template>
              <button class="expand-btn" @click.stop="openModal(design)">
                <i class="fas fa-expand"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div v-if="showModal" class="modal-overlay" @click="closeModal">
    <div class="modal-content" @click.stop>
      <button class="modal-close" @click="closeModal">×</button>
      <img :src="selectedModalImage" alt="" class="modal-image">
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watchEffect, watch } from 'vue';
import { useUserContext } from '@/composables/useUserContext';
import { createTemplateReader } from '@/utils/readTemplate';

const props = defineProps({
  designTypes: {
    type: Array,
    required: true
  },
  designImages: {
    type: Array,
    required: true
  },
  selectedStatus: {
    type: [String, Array, Object],
    default: ''
  },
  selectedDesign: {
    type: Array,
    default: () => []
  },
  selectedTypes: {
    type: Array,
    default: () => []
  },
  selectedChannels: {
    type: Array,
    default: () => []
  },
  hideHeader: {
    type: Boolean,
    default: false
  },
  noPadding: {
    type: Boolean,
    default: false
  },
  allowMultiSelect: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['update:selectedDesign', 'back', 'next']);

const { contextUserId, contextUserRole, contextUserAdminId } = useUserContext();
const templateReader = ref(null);

// Initialiser templateReader når brugerdata er tilgængelig
watch([contextUserId, contextUserRole, contextUserAdminId], ([userId, userRole, adminId]) => {
  if (userId && userRole) {
    const userData = {
      uid: userId,
      role: userRole,
      // Tilføj adminId hvis brugeren er en almindelig bruger (role 3)
      ...(userRole === 3 && { adminId })
    };
    
    if (userRole === 3 && !adminId) {
      console.warn('Bruger med rolle 3 mangler adminId');
    }
    
    templateReader.value = createTemplateReader(userData);
    console.log('TemplateReader initialized with:', userData);
  }
}, { immediate: true });

const availableDesignTypes = computed(() => {
  // Først filtrer designs baseret på adgang
  const accessibleDesigns = props.designImages.filter(design => {
    if (!design?.file || !templateReader.value) return false;
    return templateReader.value.checkDesignAccess(design);
  });

  // Derefter filtrer på de øvrige kriterier
  const matchingDesigns = accessibleDesigns.filter(design => {
    const matchesStatus = Array.isArray(design.emner) && 
      (Array.isArray(props.selectedStatus) 
        ? props.selectedStatus.some(status => 
            design.emner.some(emne => 
              emne.toLowerCase().includes(status.toLowerCase())
            )
          )
        : design.emner.some(emne => 
            emne.toLowerCase().includes(String(props.selectedStatus).toLowerCase())
          )
      );

    const matchesChannel = Array.isArray(design.channels) && 
      (props.selectedChannels.length === 0 || 
       props.selectedChannels.some(channel => 
         design.channels.some(dc => 
           dc.toLowerCase() === channel.toLowerCase()
         )
       ));

    const isVideoFile = /\.mp4$/i.test(design.file.split('?')[0]);
    const isImageFile = /\.(jpg|jpeg|png)$/i.test(design.file.split('?')[0]);
    
    const matchesType = 
      props.selectedTypes.length === 0 || 
      (props.selectedTypes.includes('video') && isVideoFile) ||
      (props.selectedTypes.includes('image') && isImageFile);

    return matchesStatus && matchesChannel && matchesType;
  });

  // Udtræk unikke gruppenavne fra de tilgængelige designs
  const uniqueGroups = new Set();
  matchingDesigns.forEach(design => {
    if (Array.isArray(design.groups)) {
      design.groups.forEach(group => {
        if (group) uniqueGroups.add(group);
      });
    }
  });

  return Array.from(uniqueGroups).sort();
});

const activeDesignType = ref('');
watchEffect(() => {
  if (availableDesignTypes.value.length > 0 && 
      (!activeDesignType.value || !availableDesignTypes.value.includes(activeDesignType.value))) {
    activeDesignType.value = availableDesignTypes.value[0];
  }
});

// Hjælpefunktion til at normalisere status
const normalizeStatus = (status) => {
  if (!status) return '';
  
  // Hvis det er et Proxy array
  if (Array.isArray(status)) {
    const firstStatus = status[0];
    return firstStatus ? String(firstStatus).toLowerCase() : '';
  }
  
  // Hvis det er et Proxy objekt
  if (typeof status === 'object' && status !== null) {
    return String(Object.values(status)[0] || '').toLowerCase();
  }
  
  return String(status).toLowerCase();
};

const matchesStatus = (design) => {
  if (!Array.isArray(design.emner)) return false;
  if (!props.selectedStatus) return false;
  
  const normalizedSelectedStatus = normalizeStatus(props.selectedStatus);
  if (!normalizedSelectedStatus) return false;

  // Debug logging
  console.log('Matching status:', {
    normalizedSelectedStatus,
    designEmner: design.emner,
    selectedStatus: props.selectedStatus
  });

  return design.emner.some(emne => {
    if (!emne) return false;
    const normalizedEmne = String(emne).toLowerCase();
    return (
      normalizedEmne === normalizedSelectedStatus ||
      normalizedSelectedStatus.includes(normalizedEmne) ||
      normalizedEmne.includes(normalizedSelectedStatus)
    );
  });
};

const matchesChannel = (design) => {
  if (!Array.isArray(design.channels)) return false;
  if (!Array.isArray(props.selectedChannels)) return false;
  if (props.selectedChannels.length === 0) return true;
  
  const normalizedSelectedChannels = props.selectedChannels.map(c => 
    String(c).toLowerCase()
  );
  
  return design.channels.some(channel => 
    normalizedSelectedChannels.includes(String(channel).toLowerCase())
  );
};

const filteredDesigns = computed(() => {
  if (!templateReader.value || !contextUserId.value) return [];

  // Brug templateReader til at filtrere designs
  return props.designImages.filter(design => {
    // Tjek først om brugeren har adgang til dette design
    const hasAccess = templateReader.value.checkDesignAccess(design);
    if (!hasAccess) return false;

    // Fortsæt med de øvrige filtreringer hvis brugeren har adgang
    const matchesDesignType = !activeDesignType.value || 
                            (design.groups && design.groups.includes(activeDesignType.value));

    const matchesStatus = Array.isArray(design.emner) && 
      (Array.isArray(props.selectedStatus) 
        ? props.selectedStatus.some(status => 
            design.emner.some(emne => 
              emne.toLowerCase().includes(status.toLowerCase())
            )
          )
        : design.emner.some(emne => 
            emne.toLowerCase().includes(String(props.selectedStatus).toLowerCase())
          )
      );

    const matchesChannel = Array.isArray(design.channels) && 
      (props.selectedChannels.length === 0 || 
       props.selectedChannels.some(channel => 
         design.channels.some(dc => 
           dc.toLowerCase() === channel.toLowerCase()
         )
       ));

    const isVideoFile = /\.mp4$/i.test(design.file?.split('?')[0] || '');
    const isImageFile = /\.(jpg|jpeg|png)$/i.test(design.file?.split('?')[0] || '');
    
    const matchesType = 
      props.selectedTypes.length === 0 || 
      (props.selectedTypes.includes('video') && isVideoFile) ||
      (props.selectedTypes.includes('image') && isImageFile);

    return matchesDesignType && matchesStatus && matchesChannel && matchesType;
  });
});

const selectDesignType = (designType) => {
  if (activeDesignType.value === designType) {
    activeDesignType.value = ''; // Nulstil for at vise alle designs
  } else {
    activeDesignType.value = designType;
  }
};

const selectDesign = (design) => {
  if (props.allowMultiSelect) {
    // Multi-select logik
    const currentSelected = Array.isArray(props.selectedDesign) ? props.selectedDesign : [];
    const designIndex = currentSelected.findIndex(d => d.uuid === design.uuid);
    
    let newSelection;
    if (designIndex === -1) {
      newSelection = [...currentSelected, design];
    } else {
      newSelection = currentSelected.filter(d => d.uuid !== design.uuid);
    }
    
    emit('update:selectedDesign', newSelection);
    console.log('Selected design (multi):', newSelection);
  } else {
    // Single-select logik - her gemmer vi hele design objektet
    const newSelection = design.uuid === props.selectedDesign?.[0]?.uuid ? [] : [design];
    emit('update:selectedDesign', newSelection);
    console.log('Selected design (single):', newSelection, 'Template ID:', design.templateId || design.uuid);
  }
};

const isSelected = (design) => {
  return Array.isArray(props.selectedDesign) && 
         props.selectedDesign.some(d => d.uuid === design.uuid);
};

const showModal = ref(false);
const selectedModalImage = ref('');

const openModal = (design) => {
  selectedModalImage.value = design.file;
  showModal.value = true;
};

const closeModal = () => {
  showModal.value = false;
  selectedModalImage.value = '';
};

// Funktion til at tjekke om filen er en video
const isVideo = (fileUrl) => {
  return fileUrl.includes('.mp4');
};

// Tilføj denne funktion til at indstille lydstyrken
const setVideoVolume = (event) => {
  const video = event.target;
  video.volume = 0.05; // Sætter lydstyrken til 5%
};
</script>

<style scoped>
.design-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  border-radius: 20px;
  overflow: hidden;
  padding: 0.3rem;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  box-shadow: 
    0 15px 35px rgba(0, 0, 0, 0.2),
    0 0 0 1px rgba(255, 255, 255, 0.1);
}

.design-card:hover {
  transform: translateY(-5px);
}

.design-card.selected {
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
}

.designs-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  padding: 1rem;
}

.designs-container.no-padding {
  padding: 0;
}

.design-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.designGroup {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}

.designGroup:hover {
  background: rgba(255, 255, 255, 0.1);
}

.designGroup.active {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

.image-container {
  position: relative;
}

.expand-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container:hover .expand-btn {
  opacity: 1;
}

.expand-btn:hover {
  background: rgba(0, 0, 0, 0.7);
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  border-radius: 20px;
  padding: 1rem;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
}

.modal-image {
  max-width: 100%;
  max-height: calc(90vh - 2rem);
  object-fit: contain;
  border-radius: 15px;
}

.modal-close {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s ease;
}

.modal-close:hover {
  background: rgba(255, 255, 255, 0.3);
}

.design-video {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
</style>