import { collection, getDocs } from 'firebase/firestore';
import { db } from '@/firebase';

const DEBUG = true;

const log = (message, data) => {
  if (DEBUG) {
    console.log(`[TemplateReader] ${message}:`, data);
  }
};

export class TemplateReader {
  constructor(userData) {
    this.userData = userData;
    log('Initialized with userData', userData);
  }

  /**
   * Tjekker om en bruger har adgang til et specifikt design
   */
  checkDesignAccess(design) {
    if (!this.userData || !design) {
      console.log('Missing userData or design:', { userData: this.userData, design });
      return false;
    }

    const userRole = this.userData.role;
    const userId = this.userData.uid;
    const adminId = this.userData.adminId;

    console.log('Checking design access:', {
      design,
      userRole,
      userId,
      adminId,
      isDefault: design.isDefault,
      assignedTo: design.assignedTo
    });

    // System default designs er tilgængelige for alle
    if (design.isDefault) {
      console.log('Design is default, access granted');
      return true;
    }

    // SuperAdmin (role 1) har adgang til alle designs
    if (userRole === 1) {
      console.log('User is SuperAdmin, access granted');
      return true;
    }

    // Admin (role 2) har adgang til egne designs
    if (userRole === 2) {
      const hasAccess = design.assignedTo?.type === 'admin' && 
                       design.assignedTo?.id === userId;
      console.log('Admin access check:', { hasAccess });
      return hasAccess;
    }

    // Almindelige brugere (role 3) har adgang til:
    if (userRole === 3) {
      // 1. Designs tildelt direkte til brugeren
      const isAssignedToUser = design.assignedTo?.type === 'user' && 
                              design.assignedTo?.id === userId;
      
      // 2. Designs tildelt til brugerens admin
      const isAssignedToAdmin = design.assignedTo?.type === 'admin' && 
                               design.assignedTo?.id === adminId;
      
      const hasAccess = isAssignedToUser || isAssignedToAdmin;
      console.log('User access check:', { 
        isAssignedToUser,
        isAssignedToAdmin,
        userId,
        adminId,
        hasAccess 
      });
      return hasAccess;
    }

    console.log('No access conditions met');
    return false;
  }

  /**
   * Filtrerer designs baseret på kriterier og brugerrettigheder
   */
  filterDesigns(designs, filters = {}) {
    log('Starting design filtering with', { filters, designCount: designs.length });
    
    return designs.filter(design => {
      // Tjek adgang først
      const hasAccess = this.checkDesignAccess(design);
      if (!hasAccess) {
        log('Access denied for design', design.id);
        return false;
      }

      // Tjek filtype
      const isVideoFile = /\.mp4$/i.test(design.file?.split('?')[0] || '');
      const isImageFile = /\.(jpg|jpeg|png)$/i.test(design.file?.split('?')[0] || '');
      
      const matchesMediaType = filters.mediaTypes.some(type => {
        const matches = (type === 'video' && isVideoFile) || (type === 'image' && isImageFile);
        log(`Media type check for ${design.id}`, { type, isVideoFile, isImageFile, matches });
        return matches;
      });

      // Tjek status
      const matchesStatus = Array.isArray(design.emner) && 
                          design.emner.includes(filters.status);
      
      // Tjek kanal
      const matchesChannel = Array.isArray(design.channels) && 
                           filters.channels.some(channel => 
                             design.channels.includes(channel.toLowerCase()));

      // Tjek designtype
      const matchesDesignType = design.groups && 
                               design.groups.includes(filters.designType);

      const result = {
        id: design.id,
        matchesMediaType,
        matchesStatus,
        matchesChannel,
        matchesDesignType,
        isDefault: design.isDefault
      };

      log(`Filter results for design ${design.id}`, result);

      return matchesMediaType && matchesStatus && matchesChannel && 
             (matchesDesignType || design.isDefault);
    });
  }

  /**
   * Henter alle designs fra Firebase
   */
  async fetchDesigns() {
    try {
      const querySnapshot = await getDocs(collection(db, 'designImages'));
      return querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      }));
    } catch (error) {
      console.error('Fejl ved hentning af designs:', error);
      throw error;
    }
  }

  /**
   * Henter unikke designtyper fra en liste af designs
   */
  getUniqueDesignTypes(designs) {
    const types = new Set(
      designs.flatMap(design => design.groups || []).filter(Boolean)
    );
    return Array.from(types);
  }

  /**
   * Tjekker filtype for et design
   */
  static getFileType(fileUrl) {
    if (!fileUrl) return null;
    if (/\.mp4$/i.test(fileUrl)) return 'video';
    if (/\.(jpg|jpeg|png|gif)$/i.test(fileUrl)) return 'image';
    return null;
  }
}

// Eksporter en factory funktion for nemmere instantiering
export function createTemplateReader(userData) {
  return new TemplateReader(userData);
} 