import { doc, getDoc } from 'firebase/firestore';
// src/utils/imageGenerator.js
import CryptoJS from 'crypto-js';
import { db } from '@/firebase';
import { brandLogoMapping } from './brandLogoMappings';
import { getCarLogoUrl } from '@/services/firebaseCarLogoService';

// Funktion til at normalisere bilmærke
function normalizeBrand(brand) {
  if (!brand) return '';
  
  // Konverter til lowercase og fjern alle accenter/diakritiske tegn
  const normalized = brand.toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-z0-9]/g, '');
  
  return normalized;
}

// Funktion til at hente logo URL
async function getCarLogo(brand) {
  if (!brand) return '';
  
  // Normaliser mærkenavnet
  const normalizedBrand = normalizeBrand(brand);
  
  // Find det korrekte brand mapping
  const brandMapping = brandLogoMapping[normalizedBrand];
  
  if (!brandMapping) {
    console.warn(`Ingen brand mapping fundet for: ${brand}`);
    return '';
  }

  try {
    // Prøv først at hente fra Firebase
    console.log('Prøver at hente logo fra Firebase...');
    const firebaseUrl = await getCarLogoUrl(brandMapping.slug || normalizedBrand);
    if (firebaseUrl) {
      console.log('Logo fundet i Firebase:', firebaseUrl);
      return firebaseUrl;
    }

    // Hvis Firebase fejler, prøv den hardcodede URL
    console.log('Prøver hardcodet URL:', brandMapping.logoUrl);
    const response = await fetch(brandMapping.logoUrl, { method: 'HEAD' });
    if (response.ok) {
      return brandMapping.logoUrl;
    }

    console.warn('Kunne ikke finde logo for:', brand);
    return '';
  } catch (error) {
    console.error('Fejl ved hentning af logo:', error);
    return '';
  }
}

// Tilføj formatDate funktion
function formatDate(dateString) {
  if (!dateString) return null;
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

// Funktion til at formatere headline med conditional styling
function formatHeadline(car, templateId) {
  if (!car?.headline) return '';
  
  const variant = car.fields?.Variant;
  if (!variant) return car.headline;
  
  // Find positionen af varianten i headline
  const variantIndex = car.headline.indexOf(variant);
  if (variantIndex === -1) return car.headline;
  
  // Del headline op i før og efter variant
  const beforeVariant = car.headline.substring(0, variantIndex).trim();
  
  // Special case for specifikt template
  if (templateId === '678620e32851c202470160c2') {
    return `${beforeVariant} **${variant}**`;
  }
  
  // Standard formatering for andre templates
  return `${beforeVariant}\n**${variant}**`;
}

// Funktion til at hente brugerens adresse
async function getUserAddress(userId) {
  if (!userId) return '';
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return userData.address || '';
    }
    return '';
  } catch (error) {
    console.error('Fejl ved hentning af brugeradresse:', error);
    return '';
  }
}

// Tilføj denne nye funktion før generateImage
async function getTemplateParameters(templateId) {
  try {
    const response = await fetch(`https://us-central1-toft-d4f39.cloudfunctions.net/getTemplateEditor?id=${templateId}`);
    if (!response.ok) {
      throw new Error('Fejl ved hentning af template');
    }
    
    const data = await response.json();
    if (!data.value || !data.value[0] || !data.value[0].nodes) {
      throw new Error('Ugyldigt template format');
    }

    // Find alle api_ parametre fra template nodes
    const apiParams = data.value[0].nodes
      .filter(node => node.name?.startsWith('api_'))
      .map(node => node.name);

    return apiParams;
  } catch (error) {
    console.error('Fejl ved hentning af template parametre:', error);
    throw error;
  }
}

// Tilføj funktion til at hente API nøgle
async function getRobolyApiKey() {
  try {
    const response = await fetch('https://us-central1-toft-d4f39.cloudfunctions.net/getRobolyApiKey');
    if (!response.ok) {
      throw new Error('Kunne ikke hente Roboly API nøgle');
    }
    const data = await response.json();
    return data.apiKey;
  } catch (error) {
    console.error('Fejl ved hentning af Roboly API nøgle:', error);
    throw error;
  }
}

export async function generateImage(car, templateId) {

  // Hent brugerens adresse hvis userId findes
  const userAddress = car.userData?.address || await getUserAddress(car.userId);
  
  // Hent logo URL baseret på bilmærke
  const brand = car.fields?.['Mærke'] || '';
  console.log('Bilmærke:', brand);
  
  const logoUrl = await getCarLogo(brand);
  console.log('Logo URL:', logoUrl);

  // Hent template parametre
  const templateParams = await getTemplateParameters(templateId);

  // Hjælpefunktion til at sikre en værdi eller returnere en default
  const ensureValue = (value, defaultValue = '') => value || defaultValue;

  // Hjælpefunktion til at formatere pris
  const formatPriceDisplay = (price) => {
    if (!price || price === 0) {
      // Tjek om der er en priceText
      if (car.priceText) return car.priceText;
      if (car.isLeasingPrice) return 'Ring for leasingpris';
      return 'Ring for pris';
    }
    // Hvis det er en leasingpris, tilføj /md
    if (car.isLeasingPrice) {
      return `${formatNumber(price)} kr/md`;
    }
    return `${formatNumber(price)} kr.`;
  };

  // Hjælpefunktion til at hente feltværdi fra forskellige placeringer
  const getFieldValue = (fieldName, alternativeNames = []) => {
    // Tjek først i fields objektet
    if (car.fields?.[fieldName]) return car.fields[fieldName];
    
    // Tjek alternative navne i fields
    for (const altName of alternativeNames) {
      if (car.fields?.[altName]) return car.fields[altName];
    }
    
    // Tjek direkte på bil objektet
    if (car[fieldName.toLowerCase()]) return car[fieldName.toLowerCase()];
    
    // Tjek alternative navne direkte på bil objektet
    for (const altName of alternativeNames) {
      if (car[altName.toLowerCase()]) return car[altName.toLowerCase()];
    }
    
    return '';
  };

  // Opret et objekt med alle mulige modifications
  const allModifications = {
    scale: 1,
    // Basis information
    api_id: car.id || '',
    api_categoryName: car.categoryName || car.category?.name || '',
    api_headline: formatHeadline(car, templateId),
    api_description: car.description || '',
    api_dealerName: car.userData?.name || car.dealerName || 'Ukendt forhandler',
    api_userAddress: userAddress || car.userData?.address || '',
    api_phone: car.userData?.phone ? `Tlf. ${car.userData.phone}` : '',
    api_email: car.userData?.email || '',
    api_carLogo: logoUrl,
    api_carLocation: car.location || car.userData?.city || '',
    
    // Datoer
    api_createdDate: formatDate(car.createdDate) || formatDate(car.lastScraped) || 'Ny',
    api_lastEditedDate: formatDate(car.lastEditedDate) || '',
    api_lastListedDate: formatDate(car.lastListedDate) || '',
    
    // Pris og visninger
    api_price: formatPriceDisplay(car.priceInt || car.price),
    api_priceInt: car.priceInt || car.price || 0,
    api_viewCount: formatNumber(car.viewCount || 0),
    api_weeklyViewCount: formatNumber(car.weeklyViewCount || 0),
    api_dailyViewCount: formatNumber(car.dailyViewCount || 0),
    
    // Salgsdetaljer
    api_salesType: car.salesType || '',
    api_paymentTypes: Array.isArray(car.paymentTypes) ? car.paymentTypes.join(', ') : '',
    api_url: car.url || '',
    
    // Billeder - Sikrer at alle billeder har en værdi eller er tomme strenge
    ...Array.from({ length: 14 }).reduce((acc, _, index) => ({
      ...acc,
      [`api_image${index + 1}`]: car.attachments?.[`image_${index + 1}`] || ''
    }), {}),
    
    // Køretøjsspecifikationer
    api_color: getFieldValue('Farve'),
    api_mærke: getFieldValue('Mærke'),
    api_model: getFieldValue('Model'),
    api_variant: car.variant || getFieldValue('Variant'),
    api_makeModel: (() => {
      const make = getFieldValue('Mærke');
      const model = getFieldValue('Model');
      return make && model ? `${make} ${model}` : make || model || '';
    })(),
    api_greenOwnershipTaxAnnual: formatNumber(getFieldValue('Grøn Ejerafgift helårlig', ['Grøn ejerafgift']) || 0),
    api_gearbox: getFieldValue('Gearkasse'),
    api_fuelType: getFieldValue('Brændstoftype'),
    api_horsepower: getFieldValue('Hestekræfter') ? `${getFieldValue('Hestekræfter')}` : '',
    api_year: car.årgang || getFieldValue('Årgang'),
    api_kilometers: getFieldValue('Kilometer') ? `${formatNumber(getFieldValue('Kilometer'))} km` : '0 km',
    api_registrationDate: formatRegistrationDate(getFieldValue('Registreringsdato')) || '',
    
    // Tekniske specifikationer
    api_numberOfDoors: getFieldValue('Antal døre', ['Døre']),
    api_newPrice: formatNumber(getFieldValue('Nypris', ['Ny pris']) || 0),
    api_numberOfGears: getFieldValue('Antal gear'),
    api_cylinders: getFieldValue('Cylindre'),
    api_driveWheels: getFieldValue('Trækhjul', ['Drivhjul']),
    api_fuelTank: getFieldValue('Tankstørrelse'),
    api_kmPerLiter: getFieldValue('Km/l'),
    api_zeroToHundredKmH: getFieldValue('0 - 100 km/t', ['0-100 km/t']),
    api_topSpeed: getFieldValue('Tophastighed'),
    api_weight: formatNumber(getFieldValue('Totalvægt', ['Vægt']) || 0),
    api_payload: formatNumber(getFieldValue('Lasteevne') || 0),
    api_airbags: getFieldValue('Airbags'),
    
    // Forbrugsvariabel
    api_consumption: (() => {
      const fuelType = getFieldValue('Brændstoftype')?.toLowerCase();
      if (fuelType === 'el') {
        const energiForbrug = getFieldValue('Energiforbrug');
        return energiForbrug ? (energiForbrug.includes('Wh/km') ? energiForbrug : `${energiForbrug} Wh/km`) : '';
      }
      const kmPerLiter = getFieldValue('Km/l');
      return kmPerLiter ? (kmPerLiter.includes('km/l') ? kmPerLiter : `${kmPerLiter} km/l`) : '';
    })(),
    
    // El-bil specifikke felter
    api_range: getFieldValue('Rækkevidde'),
    api_batteryCapacity: getFieldValue('Batterikapacitet'),
    api_energyConsumption: getFieldValue('Energiforbrug'),
    
    // Udstyr
    api_carType: getFieldValue('Biltype', ['Karrosseri'])
  };

  // Filtrer modifications baseret på template parametre
  const modifications = Object.keys(allModifications)
    .filter(key => templateParams.includes(key))
    .reduce((obj, key) => {
      obj[key] = allModifications[key];
      return obj;
    }, {});

  try {
    const apiKey = await getRobolyApiKey();
    const signedUrl = getSignedRenderLink({
      apiKey,
      format: "jpg",
      templateId: templateId,
      modifications: modifications,
    });
    return signedUrl;
  } catch (error) {
    console.error('Fejl ved generering af signeret URL:', error);
    throw error;
  }
}

function getSignedRenderLink({ templateId, modifications, apiKey, format = "jpg" }) {
  const query = new URLSearchParams(modifications).toString();
  const message = [templateId, format, query].join(":");
  
  // Konverter apiKey til WordArray
  const keyWordArray = CryptoJS.enc.Utf8.parse(apiKey);
  
  // Generer HMAC-SHA256 signatur
  const signature = CryptoJS.HmacSHA256(message, keyWordArray).toString(CryptoJS.enc.Hex);
  
  // Byg den endelige URL med signatur
  const url = `https://api.robolly.com/templates/${templateId}/render.${format}?${query}&sig=${signature}`;
  return url;
}

async function handleErrors(response) {
  if (!response.ok && response.headers?.get("content-type")?.includes("application/json")) {
    const error = await response.json();
    throw new Error(`Robolly request error (${error.errorCode}) --> ${error.developerMessage}`);
  }
}

// Hjælpefunktioner til formatering
function formatRegistrationDate(dateString) {
  if (!dateString) return null;
  const [day, month, year] = dateString.split('-');
  const months = [
    'jan.', 'feb.', 'mar.', 'apr.', 'maj', 'jun.',
    'jul.', 'aug.', 'sep.', 'okt.', 'nov.', 'dec.'
  ];
  const monthIndex = parseInt(month, 10) - 1; // Konverter måned til 0-baseret indeks
  return `${months[monthIndex]} ${year}`;
}

function formatNumber(number) {
  // Returner 0 eller tom streng hvis number er undefined eller null
  if (number == null) return '0';
  
  // Konverter til string og formater med tusindtalsseparator
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}