import { createI18n } from 'vue-i18n';
import da from './locales/da';
import en from './locales/en';

// Funktion til at gemme det valgte sprog i localStorage
const saveLocale = (locale) => {
  try {
    localStorage.setItem('userLocale', locale);
    document.documentElement.setAttribute('lang', locale);
  } catch (error) {
    console.error('Fejl ved gemning af sprog:', error);
  }
};

// Funktion til at hente det gemte sprog fra localStorage
const getSavedLocale = () => {
  try {
    const savedLocale = localStorage.getItem('userLocale');
    return savedLocale && ['da', 'en'].includes(savedLocale) ? savedLocale : 'da';
  } catch (error) {
    console.error('Fejl ved hentning af gemt sprog:', error);
    return 'da';
  }
};

const i18n = createI18n({
  legacy: false, // Brug Composition API
  locale: getSavedLocale(), // Brug gemt sprog eller dansk som standard
  fallbackLocale: 'da', // Fallback til dansk hvis oversættelse mangler
  messages: {
    da,
    en
  },
  // Tilføj missing handler for at logge manglende oversættelser
  missing: (locale, key) => {
    console.warn(`Manglende oversættelse for nøgle "${key}" i sprog "${locale}"`);
    // Returner nøglen som fallback
    return key;
  },
  // Tilføj silentTranslationWarn for at undgå advarsler om manglende oversættelser
  silentTranslationWarn: true,
  // Tilføj sync flag for at sikre synkron opdatering
  sync: true,
  // Tilføj pluralizationRules for at håndtere flertalsformer korrekt
  pluralizationRules: {
    'da': (choice) => choice === 1 ? 0 : 1,
    'en': (choice) => choice === 1 ? 0 : 1
  }
});

// Eksporter hjælpefunktioner til at håndtere sprog
export const setLocale = (locale) => {
  if (!['da', 'en'].includes(locale)) {
    console.warn('Ugyldigt sprog:', locale);
    locale = 'da';
  }
  i18n.global.locale.value = locale;
  saveLocale(locale);
};

export const getAvailableLocales = () => {
  return Object.keys(i18n.global.messages.value);
};

// Initialiser sproget ved opstart
setLocale(getSavedLocale());

export default i18n; 