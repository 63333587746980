// src/utils/videoGenerator.js
import axios from 'axios';
import { API_CONFIG } from '@/firebase';
import { getCarLogo } from '@/services/firebaseCarLogoService';

const PLAINLY_CONFIG = {
  API_BASE_URL: 'https://api.plainlyvideos.com/api/v2'
};

// Funktion til at hente Plainly API nøgle
async function getPlainlyApiKey() {
  try {
    const response = await fetch('https://us-central1-toft-d4f39.cloudfunctions.net/getPlainlyApiKey');
    if (!response.ok) {
      throw new Error('Kunne ikke hente Plainly API nøgle');
    }
    const data = await response.json();
    return data.apiKey;
  } catch (error) {
    console.error('Fejl ved hentning af Plainly API nøgle:', error);
    throw error;
  }
}

const POLLING_INTERVAL = 2000; // 2 sekunder mellem hver status check
const MAX_DURATION = 3 * 60 * 1000; // 3 minutter i millisekunder

// Hjælpefunktion til at splitte UUID'er
function parseDesignUuid(combinedUuid) {
  const [templateId, projectId] = combinedUuid.split('==');
  return { templateId, projectId };
}

// Konverter api_ parametre til Plainly parametre
export const convertToPlainlyParams = (carData) => {
  // Formatér prisen korrekt
  const formattedPrice = (() => {
    if (!carData.price || carData.price === 0) {
      if (carData.priceText) return carData.priceText;
      if (carData.isLeasingPrice) return 'Ring for leasingpris';
      return 'Ring for pris';
    }
    if (carData.isLeasingPrice) {
      return `${formatNumber(carData.price)} kr/md`;
    }
    return `${formatNumber(carData.price)} kr.`;
  })();

  const params = {
    scale: 1,
    // Basis information
    api_id: carData.id,
    api_categoryName: carData.category?.name,
    api_headline: formatHeadline(carData),
    api_description: carData.description,
    api_dealerName: carData.userData?.name || 'Ukendt forhandler',
    api_userAddress: carData.userData?.address || '',
    api_phone: carData.userData?.phone ? `Tlf. ${carData.userData.phone}` : '',
    api_email: carData.userData?.email || '',
    api_carLogo: carData.carLogo?.logoUrl || null,
    api_carLocation: carData.location,
    
    // Datoer
    api_createdDate: formatDate(carData.createdDate),
    api_lastEditedDate: formatDate(carData.lastEditedDate),
    api_lastListedDate: formatDate(carData.lastListedDate),
    
    // Pris og visninger
    api_price: formattedPrice,
    api_priceInt: carData.price || 0,
    api_viewCount: formatNumber(carData.viewCount),
    api_weeklyViewCount: formatNumber(carData.weeklyViewCount),
    api_dailyViewCount: formatNumber(carData.dailyViewCount),
    
    // Salgsdetaljer
    api_salesType: carData.salesType,
    api_paymentTypes: Array.isArray(carData.paymentTypes) ? carData.paymentTypes.join(', ') : '',
    api_url: carData.url,
    
    // Billeder - håndter både images array og attachments
    ...(carData.images ? 
      Object.fromEntries(
        carData.images.slice(0, 14).map((url, index) => [`api_image${index + 1}`, url])
      ) :
      Object.fromEntries(
        Object.entries(carData.attachments || {})
          .filter(([key]) => key.startsWith('image_'))
          .sort()
          .slice(0, 14)
          .map(([_, url], index) => [`api_image${index + 1}`, url])
      )),
    
    // Køretøjsspecifikationer
    api_color: carData.fields?.['Farve'],
    api_mærke: carData.fields?.['Mærke'],
    api_model: carData.fields?.['Model'],
    api_variant: carData.fields?.['Variant'],
    api_makeModel: (() => {
      const make = carData.fields?.['Mærke'];
      const model = carData.fields?.['Model'];
      if (make && model) {
        return `${make} ${model}`;
      }
      return make || model || '';
    })(),
    api_greenOwnershipTaxAnnual: formatNumber(carData.fields?.['Grøn Ejerafgift helårlig'] || carData.fields?.['Grøn ejerafgift']),
    api_gearbox: carData.fields?.['Gearkasse'],
    api_fuelType: carData.fields?.['Brændstoftype'],
    api_horsepower: carData.fields?.['Hestekræfter'] ? `${carData.fields['Hestekræfter']}` : null,
    api_year: carData.fields?.['Årgang'],
    api_kilometers: carData.fields?.['Kilometer'] ? `${formatNumber(carData.fields['Kilometer'])}` : null,
    api_registrationDate: formatRegistrationDate(carData.fields?.['Registreringsdato']),
    
    // Tekniske specifikationer
    api_numberOfDoors: carData.fields?.['Døre'],
    api_newPrice: formatNumber(carData.fields?.['Nypris'] || carData.fields?.['Ny pris']),
    api_numberOfGears: carData.fields?.['Antal gear'],
    api_cylinders: carData.fields?.['Cylindre'],
    api_driveWheels: carData.fields?.['Trækhjul'] || carData.fields?.['Drivhjul'],
    api_fuelTank: carData.fields?.['Tankstørrelse'],
    api_kmPerLiter: carData.fields?.['Km/l'],
    api_zeroToHundredKmH: carData.fields?.['0 - 100 km/t'] || carData.fields?.['0-100 km/t'],
    api_topSpeed: carData.fields?.['Tophastighed'],
    api_weight: formatNumber(carData.fields?.['Vægt']),
    api_payload: formatNumber(carData.fields?.['Lasteevne']),
    api_airbags: carData.fields?.['Airbags'],
    
    // Forbrugsdata
    api_consumption: (() => {
      const fuelType = carData.fields?.['Brændstoftype']?.toLowerCase();
      if (fuelType === 'el') {
        const energiForbrug = carData.fields?.['Energiforbrug'];
        return energiForbrug ? (energiForbrug.includes('Wh/km') ? energiForbrug : `${energiForbrug} Wh/km`) : null;
      } else {
        const kmPerLiter = carData.fields?.['Km/l'];
        return kmPerLiter ? (kmPerLiter.includes('km/l') ? kmPerLiter : `${kmPerLiter} km/l`) : null;
      }
    })(),
    
    // El-bil specifikke felter
    api_range: carData.fields?.['Rækkevidde'],
    api_batteryCapacity: carData.fields?.['Batterikapacitet'],
    api_energyConsumption: carData.fields?.['Energiforbrug'],
    
    // Karrosseri
    api_carType: carData.fields?.['Biltype'] || carData.fields?.['Karrosseri']
  };

  return params;
};

// Hjælpefunktioner til formatering
function formatPrice(price) {
  if (!price) return '0';
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function formatDate(dateString) {
  if (!dateString) return null;
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

function formatRegistrationDate(dateString) {
  if (!dateString) return null;
  const [day, month, year] = dateString.split('-');
  const months = [
    'jan', 'feb', 'mar', 'apr', 'maj', 'jun',
    'jul', 'aug', 'sep', 'okt', 'nov', 'dec'
  ];
  const monthIndex = parseInt(month, 10) - 1;
  return `${months[monthIndex]} ${year}`;
}

function formatNumber(number) {
  if (number == null) return '0';
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function formatHeadline(car) {
  if (!car?.headline) return '';
  
  const variant = car.fields?.Variant;
  if (!variant) return car.headline;
  
  const variantIndex = car.headline.indexOf(variant);
  if (variantIndex === -1) return car.headline;
  
  const beforeVariant = car.headline.substring(0, variantIndex).trim();
  return `${beforeVariant}\n**${variant}**`;
}

// Opdater status baseret på Plainly's API response
const handlePlainlyStatus = (status, elapsedTime, statusCallback) => {
  switch (status) {
    case 'QUEUED':
      statusCallback({
        status: 'QUEUED',
        message: 'Video er i kø...',
        progress: 10
      });
      break;
    case 'VALIDATING':
      statusCallback({
        status: 'PROCESSING',
        message: 'Validerer video parametre...',
        progress: 20
      });
      break;
    case 'DOWNLOADING_ASSETS':
      statusCallback({
        status: 'PROCESSING',
        message: 'Henter video assets...',
        progress: 30
      });
      break;
    case 'RENDERING':
      statusCallback({
        status: 'PROCESSING',
        message: 'Genererer video...',
        progress: Math.min(80, 40 + (elapsedTime / (60 * 1000)) * 40)
      });
      break;
    case 'UPLOADING':
      statusCallback({
        status: 'PROCESSING',
        message: 'Uploader video...',
        progress: 90
      });
      break;
    case 'DONE':
      statusCallback({
        status: 'COMPLETED',
        message: 'Video er klar!',
        progress: 100
      });
      break;
    case 'FAILED':
      statusCallback({
        status: 'ERROR',
        message: 'Video generering fejlede',
        progress: 0
      });
      break;
    default:
      statusCallback({
        status: 'PROCESSING',
        message: 'Behandler video...',
        progress: 50
      });
  }
};

export async function generateVideo(car, designUuid, statusCallback) {
  try {
    console.log('generateVideo kaldt med:', { car, designUuid });

    // Hent API nøgle
    const apiKey = await getPlainlyApiKey();

    statusCallback?.({
      status: 'INITIATING',
      message: 'Starter video generering...',
      progress: 0
    });

    // Parse UUID'er
    const { templateId, projectId } = parseDesignUuid(designUuid);

    // Hent car logo hvis det ikke allerede findes
    if (!car.carLogo?.logoUrl && car.fields?.['Mærke']) {
      try {
        car.carLogo = await getCarLogo(car.fields['Mærke']);
        console.log('Hentet billogo:', car.carLogo);
      } catch (error) {
        console.warn('Kunne ikke hente billogo:', error);
      }
    }

    // Konverter attachments til images array hvis det ikke allerede er gjort
    if (!car.images && car.attachments) {
      car.images = Object.entries(car.attachments)
        .filter(([key]) => key.startsWith('image_'))
        .map(([_, value]) => value)
        .filter(Boolean);
    }

    // Tilføj category hvis den mangler
    if (!car.category?.name) {
      car.category = { name: 'Brugte biler' };
    }

    // Tilføj manglende felter og formater data
    const enhancedCar = {
      ...car,
      fields: {
        ...car.fields,
        'Registreringsdato': car.fields?.['1. registrering'] || car.fields?.['Registreringsdato'],
        'Brændstoftype': car.fields?.['Drivkraft'] || car.fields?.['Brændstoftype'],
        'Hestekræfter': car.fields?.['HK'] || car.fields?.['Hestekræfter'],
        'Døre': car.fields?.['Antal døre'] || car.fields?.['Døre'],
        'Vægt': car.fields?.['Egenvægt'] || car.fields?.['Vægt'],
        'Lasteevne': car.fields?.['Lastevne'] || car.fields?.['Lasteevne'],
        'Karrosseri': car.fields?.['Biltype'] || car.fields?.['Karrosseri'],
        'Grøn ejerafgift': car.fields?.['Grøn ejerafgift - Halvårlig'] || car.fields?.['Grøn Ejerafgift helårlig']
      }
    };

    // Opret video request payload med konverterede parametre
    const videoParams = {
      projectId,
      templateId,
      parameters: convertToPlainlyParams(enhancedCar),
      options: {
        thumbnails: {
          frequencySeconds: 7.5
        }
      },
      outputFormat: {
        encodingParams: {
          "-r": 60
        },
        format: "MP4"
      }
    };

    console.log('Request payload til Plainly:', JSON.stringify(videoParams, null, 2));

    try {
      // Send request til Plainly API
      const response = await axios.post(
        `${PLAINLY_CONFIG.API_BASE_URL}/renders`,
        videoParams,
        {
          headers: {
            'Authorization': `Basic ${btoa(`${apiKey}:`)}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          validateStatus: function (status) {
            return status < 500;
          }
        }
      );

      if (response.status === 400) {
        console.error('Plainly API Validation Error:', {
          status: response.status,
          data: response.data,
          requestPayload: videoParams
        });
        throw new Error(`Plainly API Validation Error: ${JSON.stringify(response.data)}`);
      }

      if (!response.data?.id) {
        console.error('Uventet svar fra Plainly:', response.data);
        throw new Error('Ingen render ID modtaget fra Plainly');
      }

      const renderId = response.data.id;
    console.log('Modtaget renderId:', renderId);
    
    // Start polling for status
    const startTime = Date.now();
    let elapsedTime = 0;
    
    while (elapsedTime < MAX_DURATION) {
      elapsedTime = Date.now() - startTime;

      try {
        const statusResponse = await axios.get(
          `${PLAINLY_CONFIG.API_BASE_URL}/renders/${renderId}`,
          {
            headers: {
              'Authorization': `Basic ${btoa(`${apiKey}:`)}`,
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          }
        );

        const status = statusResponse.data.state;
        handlePlainlyStatus(status, elapsedTime, statusCallback);
        
        if (status === 'DONE') {
          return {
            videoUrl: statusResponse.data.output,
            thumbnailUrl: statusResponse.data.thumbnailUris?.[0] || statusResponse.data.output,
            renderId: renderId
          };
        }
        
        if (status === 'FAILED') {
          throw new Error('Video generering fejlede: ' + (statusResponse.data?.error || 'Ukendt fejl'));
        }

        await new Promise(resolve => setTimeout(resolve, POLLING_INTERVAL));
      } catch (error) {
        console.error('Fejl ved status check:', error.response?.data || error);
        if (elapsedTime >= MAX_DURATION - POLLING_INTERVAL) {
          throw new Error('Kunne ikke hente video status: ' + error.message);
        }
        await new Promise(resolve => setTimeout(resolve, POLLING_INTERVAL));
      }
    }

    throw new Error('Timeout: Video generering tog længere end 3 minutter');

    } catch (error) {
      console.error('Detaljeret fejl i generateVideo:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        requestData: videoParams
      });
      throw error;
    }
  } catch (error) {
    console.error('Fejl i generateVideo:', error);
    statusCallback?.({
      status: 'ERROR',
      message: `Fejl: ${error.message}`,
      error
    });
    throw error;
  }
}