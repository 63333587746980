<script setup>
import { ref, onMounted, watch } from "vue";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "@/firebase";
import setNavPills from "@/assets/js/nav-pills.js";
import { signOut } from "firebase/auth";
import { useRoute, useRouter } from 'vue-router';
import { inject } from 'vue';

const router = useRouter();
const route = useRoute();
const activeTab = ref("Hjem");
const isSuperAdmin = ref(false);
const isAdminOrSuperAdmin = ref(false);
const userRole = ref(0);
const emit = defineEmits(['update:activeTab']);

// Forbedret error handling for user authentication
const getUserData = async (userId) => {
  try {
    const userDocRef = doc(db, "users", userId);
    const userDoc = await getDoc(userDocRef);
    
    if (!userDoc.exists()) {
      console.error("Ingen brugerdata fundet");
      return null;
    }
    
    return userDoc.data();
  } catch (error) {
    console.error("Fejl ved hentning af brugerdata:", error);
    return null;
  }
};

// Forbedret authentication state handling
const setupUserRole = async () => {
  try {
    const user = auth.currentUser;
    if (!user) {
      console.log("Ingen bruger er logget ind");
      return;
    }

    const userData = await getUserData(user.uid);
    if (!userData) return;

    userRole.value = userData.role;
    isAdminOrSuperAdmin.value = [1, 2].includes(userData.role);
    isSuperAdmin.value = userData.role === 1;
  } catch (error) {
    console.error("Fejl i setupUserRole:", error);
  }
};

// Forbedret logout handling
const handleLogout = async () => {
  try {
    // Fjern alle aktive listeners før logout
    const authComponent = inject('authComponent');
    if (authComponent && authComponent.value?.removeAllListeners) {
      authComponent.value.removeAllListeners();
    }

    // Opdater online status til false før logud
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, {
        isOnline: false,
        lastSeen: new Date()
      });
    }

    // Log ud
    await signOut(auth);
    await router.push('/'); // Redirect til Home.vue i stedet for signin
  } catch (error) {
    console.error('Fejl ved log ud:', error);
  }
};

onMounted(async () => {
  try {
    await setupUserRole();
    setNavPills();
  } catch (error) {
    console.error("Fejl i onMounted:", error);
  }
});

// Definer setActiveTab først
const setActiveTab = (tabName) => {
  activeTab.value = tabName;
  emit('update:activeTab', tabName);
};

// Flyt watch efter setActiveTab er defineret
watch(() => route.name, (newRoute) => {
  if (newRoute) {
    switch(newRoute) {
      case 'Admin Panel':
        setActiveTab('AdminPanel');
        break;
      case 'Økonomi':
        setActiveTab('Okonomi');
        break;
      // ... tilføj flere cases efter behov
      default:
        setActiveTab('Hjem');
    }
  }
}, { immediate: true });
</script>

<template>
  <div class="nav-wrapper">
    <ul class="nav nav-pills nav-fill" role="tablist">
      <li class="nav-item" v-if="!isAdminOrSuperAdmin">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'Hjem' }"
          @click="setActiveTab('Hjem')"
          href="#"
          role="tab"
          aria-selected="true"
        >
          <i class="fas fa-home" style="margin-right: 8px;"></i>
          <span v-auto-translate>Hjem</span>
        </a>
      </li>
      <li class="nav-item" v-if="isAdminOrSuperAdmin">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'AdminPanel' }"
          @click="setActiveTab('AdminPanel')"
          href="#"
          role="tab"
          aria-selected="false"
        >
          <i class="fas fa-cog" style="margin-right: 8px;"></i>
          <span v-auto-translate>Admin Panel</span>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'Indstillinger' }"
          @click="setActiveTab('Indstillinger')"
          href="#"
          role="tab"
          aria-selected="false"
        >
          <i class="fas fa-user" style="margin-right: 8px;"></i>
          <span v-auto-translate>Indstillinger</span>
        </a>
      </li>
      <li class="nav-item" v-if="isSuperAdmin">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'OpretDesigns' }"
          @click="setActiveTab('OpretDesigns')"
          href="#"
          role="tab"
          aria-selected="false"
        >
          <i class="fas fa-plus" style="margin-right: 8px;"></i>
          <span v-auto-translate>Opret Designs</span>
        </a>
      </li>
      <li class="nav-item" v-if="isSuperAdmin">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'BlogAdmin' }"
          @click="setActiveTab('BlogAdmin')"
          href="#"
          role="tab"
          aria-selected="false"
        >
          <i class="fas fa-blog" style="margin-right: 8px;"></i>
          <span v-auto-translate>Blog</span>
        </a>
      </li>
      <li class="nav-item" v-if="isAdminOrSuperAdmin">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'Okonomi' }"
          @click="setActiveTab('Okonomi')"
          href="#"
          role="tab"
          aria-selected="false"
        >
          <i class="fas fa-chart-line" style="margin-right: 8px;"></i>
          <span v-auto-translate>Økonomi</span>
        </a>
      </li>
      <li class="nav-item align-items-center">
        <a
          class="nav-link"
          style="cursor: pointer;"
          @click="handleLogout"
          role="button"
        >
          <i class="fas fa-right-from-bracket" style="margin-right: 8px;"></i>
          <span v-auto-translate>Log ud</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.nav-wrapper {
  background-color: var(--card-color) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.nav-pills {
  background-color: var(--card-color) !important;
}

.nav-item {
  margin: 0 0.25rem;
}

.nav-link {
  padding: 0.5rem 1rem !important;
  font-size: 0.875rem;
  font-weight: 500;
  color: #344767;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  
  &.active {
    background: var(--sidenav-color) !important;
    color: #ffffff !important;
  }
}

.nav-link i {
  font-size: 0.875rem;
  margin-right: 8px;
}

.nav-link span {
  display: inline-block;
}

@media (max-width: 768px) {
  .nav-link {
    padding: 0.5rem !important;
  }
  
  .nav-link span {
    display: none;
  }
  
  .nav-link i {
    margin: 0;
    font-size: 1rem;
  }
}

@media (max-width: 992px) {
  .nav-pills {
    min-width: auto;
    width: 100%;
  }
}

.breadcrumb-wrapper {
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  background-color: transparent;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0.875rem;
  color: #8392ab;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "/";
  padding-right: 0.5rem;
  color: #8392ab;
}

.breadcrumb-item.active {
  color: #344767;
  font-weight: 600;
}

.breadcrumb-item i {
  font-size: 0.875rem;
  margin-right: 0.25rem;
}

@media (max-width: 768px) {
  .breadcrumb-wrapper {
    padding: 0.25rem 0.5rem;
  }
  
  .breadcrumb {
    font-size: 0.75rem;
  }
}
</style>
